import React, { createContext, useEffect, useState, useContext } from "react";
import { Asset, Icon, RadioButton } from "components/common";
import { History } from "history";
import { sequentialMoveOpts as moveOpts } from "../keyMappings";
import { nosequentialMoveOpts as nomoveOpts } from "../keyMappings";
import { ContactMobilityKnockout as WorkingWithRecKnockout } from "../knockoutScreens";
import PayGradeSelect from "../PayGrade";
import { IsEmpGlobContext } from "../index2";
import { INFO_CARD_COLORS } from "consts";
import { InfoKnockOutCard } from "components/common";

type movOptionType = string;
export const MovOptionContext = createContext<movOptionType>('');

const WorkingWithRecruiter = (props: {
    navigateTo: (to: string, from: string) => void;
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    const [moveOption, setMoveOption] = useState("");
    const [movOption, setMovOption] = useState<movOptionType>('');
    const IsEmpGlobAssign = useContext(IsEmpGlobContext);

    const clickHandler = (moveOptionKey: string, value: string): void => {
        props.history.push({
            state: {
                ...props.history.location.state,
                "working-with-recruiter": value,
            },
        });
        setMoveOption(moveOptionKey);
        setMovOption(moveOptionKey);
    };

    return (
        <div className="primary-reason-global">
            {IsEmpGlobAssign === "yes" && <div className="top">
                <div className="title">Are you currently working with a recruiter for this position?</div>
            </div>
            }
            {IsEmpGlobAssign === "yes" && <div className="options">
                {Object.keys(moveOpts).map(key => {
                    return (
                        <div className="option" key={key}>
                            <RadioButton
                                checked={moveOption === moveOpts[key].text}
                                onChange={(): void =>
                                    clickHandler(moveOpts[key].text, moveOpts[key].value)
                                }
                            />
                            <div className="titles">
                                <p className="title">{moveOpts[key].displayText}</p>
                                <p className="sub-title">
                                    {moveOpts[key].description}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>}
            {IsEmpGlobAssign === "no" && <div className="contact-mobility">
                <InfoKnockOutCard
                    backgroundColor={INFO_CARD_COLORS.first}
                    headerIsLink
                    // title="Great, this position qualifies for Executive level support"
                    content={<>
                        For US Domestic Relocation initiations in progress prior to February 21st, please contact the SIRVA team at
                        <a href="mailto:mobilityconnect@sirva.com" style={{ color: "#cc0099" }}> Mobility Connect. </a><br /><br />
                        On February 22nd and February 23rd, the move.jnj.com site will be unable to accept any US Domestic Relocation initiations.<br /><br />
                        For US Domestic Relocation new initiations as of February 26th, please go to Workday to create a Job Requisition to initiate a relocation request <a href="https://wd5.myworkday.com/jj/d/home.htmld" style={{ color: "#cc0099" }}>https://wd5.myworkday.com/jj/d/home.htmld.</a>
                    </>}
                />
            </div>
            }

            {moveOption === moveOpts.willMakeOneWay.text && (
                <WorkingWithRecKnockout disableContinueBtn={props.disableContinueBtn} />
            )}
            {[moveOpts.empOnAssignment.text, moveOpts.notWorkingWithRec.text, nomoveOpts.notWorkingWithRec.text, nomoveOpts.yesWorkingWithRec.text].includes(moveOption) && (
                <MovOptionContext.Provider value={movOption}>
                    <PayGradeSelect
                        navigateTo={props.navigateTo}
                        disableContinueBtn={props.disableContinueBtn}
                        history={props.history}
                    />
                </MovOptionContext.Provider>
            )}
        </div>
        // <div className="primary-reason-global">
        //     <div className="top">
        //         <div className="title">Are you currently working with a recruiter for this position?</div>
        //     </div>
        //     {IsEmpGlobAssign === "yes" && <div className="options">
        //         {Object.keys(moveOpts).map(key => {
        //             return (
        //                 <div className="option" key={key}>
        //                     <RadioButton
        //                         checked={moveOption === moveOpts[key].text}
        //                         onChange={(): void =>
        //                             clickHandler(moveOpts[key].text, moveOpts[key].value)
        //                         }
        //                     />
        //                     <div className="titles">
        //                         <p className="title">{moveOpts[key].displayText}</p>
        //                         <p className="sub-title">
        //                             {moveOpts[key].description}
        //                         </p>
        //                     </div>
        //                 </div>
        //             )
        //         })}
        //     </div>}
        //     {IsEmpGlobAssign === "no" && <div className="options">
        //         {Object.keys(nomoveOpts).map(key => {
        //             return (
        //                 <div className="option" key={key}>
        //                     <RadioButton
        //                         checked={moveOption === nomoveOpts[key].text}
        //                         onChange={(): void =>
        //                             clickHandler(nomoveOpts[key].text, nomoveOpts[key].value)
        //                         }
        //                     />
        //                     <div className="titles">
        //                         <p className="title">{nomoveOpts[key].displayText}</p>
        //                         <p className="sub-title">
        //                             {nomoveOpts[key].description}
        //                         </p>
        //                     </div>
        //                 </div>
        //             )
        //         })}
        //     </div>}

        //     {moveOption === moveOpts.willMakeOneWay.text && (
        //         <WorkingWithRecKnockout disableContinueBtn={props.disableContinueBtn} />
        //     )}
        //     {[moveOpts.empOnAssignment.text, moveOpts.notWorkingWithRec.text, nomoveOpts.notWorkingWithRec.text, nomoveOpts.yesWorkingWithRec.text].includes(moveOption) && (
        //         <MovOptionContext.Provider value={movOption}>
        //             <PayGradeSelect
        //                 navigateTo={props.navigateTo}
        //                 disableContinueBtn={props.disableContinueBtn}
        //                 history={props.history}
        //             />
        //         </MovOptionContext.Provider>
        //     )}
        // </div> 
    );
};

export default WorkingWithRecruiter;
