import React from 'react';

export default (
    <div className="article-info">
        <div className="article-info-item">
            <h2 className="title">Give Yourself Some Lead Time</h2>
            <p className="description">
                As soon as you’re able, start looking at the immigration website of the country you’re moving to. Read carefully, making note of what’s needed for each member of your family. As a rule of thumb, you should start prepping for the move &nbsp;
                    <a href="https://www.internations.org/guide/global/prepare-for-moving-abroad-15266" rel="noopener noreferrer" target="_blank" className="link">at least 90 days beforehand</a> &nbsp; to ensure that the host country’s embassy has enough time to process your visa. 
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Learn About Your Legal Options</h2>
            <p className="description">
                Not sure where to start when filling out forms? When you relocate, the support of an immigration attorney can help you and your family with relevant paperwork. More broadly, your immigration attorney can provide you with valuable advice and assistance, especially if you’re not fluent in the host country’s language.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Get Your Whole Family Involved</h2>
            <div>
                <p className="description">
                    Once you have a specific move date set, start preparing your family for the move as early as possible. That might mean starting to learn a second language together or reading about cultural customs in the new country as a family. It also might mean working with your kids to decide which toys to take with and which can be thrown out or donated. When it comes time to pack, make sure everyone is on the same page about what’s coming with during the move.
                </p>
            </div>
        </div>
        <div className="article-info-item">
            <h2 className="title">Research Potential Schools Beforehand</h2>
            <p className="description">
                Research the country you plan to relocate to and learn as much as possible about the local school system. You should take into consideration, too, how far the school is from your place of home and work and what the daily commute will look like for you and your children.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Find Ways to Be Active in Your New Community</h2>
            <p className="description">
                Once you’ve made the move, it’s time to find activities that help your family integrate and feel at home in your new city. This could mean mapping out nearby hiking trails to do together or visiting a local museum to learn more about your new country’s culture. It can also mean attending family-oriented concerts or finding local clubs to join. At the end of the day, you never know who you’ll meet or what your kids might learn from the experience.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Make A Plan For Staying in Touch </h2>
            <p className="description">
                If you’re leaving relatives or friends in your current country, it can be a difficult transition. But thanks to the internet, widely available Wi-Fi around the world and advanced smartphone technology, you can Skype and FaceTime to your heart’s content—which should help make the transition easier.
            </p>
        </div>
    </div>
)
