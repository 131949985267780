export const CAROUSEL_NAMES = {
    MAIN: 'MAIN',
    LEARN_ABOUT: 'LEARN_ABOUT',
    READY_TO_MOVE: 'ReadyToMove',
    CONTINUE: 'CONTINUE',
    TALK: 'TALK',
    READY_TO_MOVE_GLOBAL: 'ReadyToMove_Global',
    READY_TO_MOVE_DOMESTIC: 'ReadyToMove_Domestic',
};
export const CAROUSEL_URLS = {
    LEARN_ABOUT: 'https://home.jnj.com/sites/human-resources/sitepagemodern/1183822/talent-mobility-people-leaders-global',
    READY_TO_MOVE: '/non-competitive-initiation',
    CONTINUE: '/move-dashboard',
    TALK: 'mailto:mobilityconnect@sirva.com',
};

export const CAROUSEL_SETTINGS = {
    infinite: false,
    centerPadding: "40px",
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1110,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            }
        },
    ],
};
