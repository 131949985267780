import React, { PureComponent } from 'react';

import './style.scss';

export default class Input extends PureComponent{

    state = {
        value: ''
    };

    componentWillMount(){
        this.setState({ value: this.props.value })
    }

    setValue = ({ target }) => {
        this.props.onChange && this.props.onChange({target})
        this.setState({ value: target.value });
    }

    render(){
        const {style} = this.props;
        return(
            <div className="Input">
                <input
                    type="text"
                    required
                    maxlength={this.props.maxlength}
                    data-key={this.props.dataKey}
                    value={this.state.value}
                    onChange={this.setValue}
                    style={style}
                />
                <span className="placeholder">{this.props.placeholder}</span>
            </div>
        )
    }
}
