import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Stepper,
    EmployeeHero,
    Articles
} from 'components/sections';
import { getUserProfile, getRelocations, getTasks } from 'selectors';
import { relocationsService } from 'services';
import steps from './steps';
import type { StepObj } from 'components/sections/Stepper';
import './style.scss';
import { StepLayout } from 'components/stepLayouts';
import { Icon, Link } from "components/common";
import { getTaxMilestones } from "../../selectors/dataSelectors";

const mapStateToProps = ( state ) => ({
    userProfile: getUserProfile(state),
    relocations: getRelocations(state),
    tasks: getTasks(state),
    taxMilestones: getTaxMilestones(state)
});

const mapDispatchToProps = {
    fetchRelocations: relocationsService.actions.getRelocationsWithHeaders
};

class Profil extends PureComponent {
    state = {
        closedNotifications: false
    };

    componentWillMount() {
        const { userProfile } = this.props;
        if(userProfile && userProfile.email) {
            this.props.fetchRelocations(userProfile.email);
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            userProfile: nextUserProfile,
            relocations
        } = this.props;

        if(!this.props.userProfile && nextUserProfile && nextUserProfile.email && !relocations) {
            this.props.fetchRelocations(nextUserProfile.email);
        }
    }

    handleStepChange = (stepInfo: StepObj): void => {
        const { params } = this.props.match;
        this.props.history.push(`/profile/${params.move}/${stepInfo.key}`);
    };

    geTitleBy = (stepsArray: Array, move:string, step:string): string => {
        return this.props.userProfile.currentRelocationId
            ? stepsArray[move].find(element => element.key === step).title
            : "What type of journey are you here to explore?";
    };
    getAssetIdBy = (stepsArray: Array, move:string, step:string): string => {
        return this.props.userProfile.currentRelocationId
            ? stepsArray[move].find(element => element.key === step).assetid
            : (this.props.userProfile.email ? "2" : null);
    };
    getLinkTextBy = (stepsArray: Array, move:string, step:string): string => {
        return this.props.userProfile.currentRelocationId
            ? stepsArray[move].find(element => element.key === step).linkTest
            : null;
    };
    getLinkUrlBy = (stepsArray: Array, move:string, step:string): string => {
        return this.props.userProfile.currentRelocationId
            ? stepsArray[move].find(element => element.key === step).linkUrl
            : null;
    };
    getEmployeeStepItems = (move: string) => {
        if (this.props.userProfile.isOneWayCurrentRelocation) {
            return steps.assignee[move].filter(it => it.key !== 'in-host-country' && it.key !== 'journey-back');
        }
        return steps.assignee[move];
    };

    navigateToArticle = (articleIndex) => {
        this.props.history.push(`/article-detail/${articleIndex}`)
    };

    render() {
        const { params } = this.props.match;
        const isUnassignedUser = (this.props.userProfile.isJibeUser && this.props.userProfile.isJibeUnassignedUser)
            || !this.props.relocations
            || (Array.isArray(this.props.relocations) && !this.props.relocations.length);

        return (
            <div className="Profil flexible vertical ">
                {this.props.tasks && this.props.tasks.length && !this.state.closedNotifications ? (
                    <div className="tasks-notification flexible jCenter">
                        <Link
                            text={'You have relocation tasks you need to complete'}
                            action='click-tasks'
                            category='profile'
                            label="taks-notification"
                            onClick={() => this.props.history.push('/tasks')}
                            className={'task-notification-link'}
                        />
                        <div className="close-button">
                            <Icon
                                width={14}
                                height={14}
                                name="closeWhite"
                                onClick={() => this.setState({ closedNotifications: true })}
                            />
                        </div>
                    </div>
                ) : null}
                <EmployeeHero
                    assetType="EMPLOYEE_PROFILE_HERO_IMG"
                    assetId={this.getAssetIdBy(steps.assignee, params.move, params.step)}
                    userName={this.props.userProfile.shortName}
                    move={this.props.userProfile.currentRelocationId ? params.move : null}
                    title={this.geTitleBy(steps.assignee, params.move, params.step) }
                    linkText={this.getLinkTextBy(steps.assignee, params.move, params.step) }
                    linkUrl={this.getLinkUrlBy(steps.assignee, params.move, params.step)}
                />
                <div className="employee-stepper">
                    <Stepper
                        key={params.move}
                        onStepChange={this.handleStepChange}
                        activeStepKey={params.step}
                        steps={this.getEmployeeStepItems(params.move)}
                    />
                </div>
                <StepLayout
                    stepName={params.step}
                    move={params.move}
                    activeRelocation={!!this.props.userProfile.currentRelocationId}
                    unassigned={isUnassignedUser}
                    taxMilestones={this.props.taxMilestones}
                    currentRelocationId={this.props.userProfile.currentRelocationId}
                />

                <section className="CTA-components">
                    <h2 className="flexible vertical aCenter">
                        Support & Guidance for Talent Moves<span/>
                    </h2>
                    <Articles
                        navigateToArticle={this.navigateToArticle}
                        moveType={params.move}
                    />
                </section>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profil))
