import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Icon, CountryFlags } from "components/common";
// import { getMoveDashboardMemberList } from "selectors";
import './styles.scss';

import { moveInitiationService } from "services";

interface IMoveDetails {
    readonly to: string;
    readonly from: string;
    readonly package: string;
    readonly newJob: string;
    readonly jobId: number;
    readonly startDate: string;
    readonly toCounrtryCode: string;
}

interface IProps {
    readonly name: string;
    readonly status: 'pre-initiation' | 'draft' | 'submitted';
    readonly services?: string;
    readonly move?: string;
    readonly id?: any;
    readonly accepted?: boolean;
    readonly moveDetails?: IMoveDetails;
    readonly onClick: Function;
    readonly isCompetitive?: boolean;
}

const mapStateToProps = (state: any) => ({
    // moveList: getMoveDashboardMemberList(state),
});

const mapDispatchToProps = {
    deleteMoveJobCard: moveInitiationService.actions.deleteMoveJobCard,
    fetchMoveDashboardMemberList: moveInitiationService.actions.fetchMoveDashboardMemberList
}

class YourTalentMove extends PureComponent<RouteComponentProps & IProps & {
                            fetchMoveDashboardMemberList, deleteMoveJobCard
                        }
    > {
    private readonly statuses = {
        preInitiation: 'Pre-initiation',
        draft: 'Draft',
        submitted: 'Request submitted'
    };

    public readonly state = {
        isOpened: false,
        dashboardList: [],
        isActive: true,
    };

    private renderServices() {
        if (!this.props.services) {
            return null;
        }

        return (
            <div className={'services flexible vertical'}>
                <span className="label">Services:</span>
                <span className="info-title">{this.props.services}</span>
            </div>
        )
    }

    private renderMoves() {
        if (!this.props.move) {
            return null;
        }

        return (
            <div className="moves flexible vertical">
                <span className="label">Move:</span>
                <span className="info-title">{this.props.move}</span>
            </div>
        );
    }

    private renderStatus() {
        const status = this.statuses[this.props.status];

        if (!status) {
            return null;
        }

        return (
            <div className="move-status flexible vertical">
                <span className="label">Status:</span>
                <span className="info-title bold">{status}</span>
            </div>
        )
    }

    private deleteCard(initiationId: string) {
        this.setState({
            isLoading: true
        });
        this.props
            .deleteMoveJobCard(initiationId)
            .then(data => {
                this.setState({
                    isActive: false,
                    isLoading: false
                })
            })
            .catch(e => {
                console.log(e)
            });
    }

    private renderIncomplete() {
        const {onClick, isCompetitive} = this.props;
        return (
            <React.Fragment>
                {this.renderServices()}
                {this.renderMoves()}
                {this.renderStatus()}
                <div className={'additional-info flexible jBetween aCenter'}>
                    <span className="notice">
                    Come back once you've received your Requisition ID or Position ID to complete your Initiation.
                </span>
                <Button
                    title="Add Move Details"
                    dataKey="add-move-details"
                    submit={true}
                    className={"add-move-details"}
                    onClick={() => onClick("/move-details")}
                />
                </div>
            </React.Fragment>
        )
    }

    private renderDraft() {
        const {onClick, isCompetitive, id} = this.props;
        return (
            <React.Fragment>
                {this.renderServices()}
                {this.renderMoves()}
                {this.renderStatus()}
                <div className={'additional-info flexible jBetween aCenter'}>
                    <span className="notice">This request is still incomplete. Are you ready to finish it up?</span>
                    <div>
                        <Button
                            title="Delete"
                            dataKey="delete-move"
                            className={"delete-move"}
                            onClick={() => { if (window.confirm('Are you sure you want to delete this record?')) this.deleteCard(id) } }
                            simple={true}
                        />
                        <Button
                            title="Complete Initiation"
                            dataKey="continue-move"
                            submit={true}
                            className={"continue-move"}
                            onClick={() => onClick("/initiate-move")}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderSubmitted() {
        const { id } = this.props;
        return (
            <React.Fragment>
                {this.renderServices()}
                {this.renderMoves()}
                {this.renderStatus()}
                {id ? (
                    <div className="move-id flexible vertical">
                        <span className="label">ID:</span>
                        <span className="info-title">{id}</span>
                    </div>
                ) : null}
            </React.Fragment>
        )
    }

    private onArrowClick = () => {
        if (typeof this.props.moveDetails !== 'object') {
            return;
        }

        this.setState({ isOpened: !this.state.isOpened });
    };


    public render() {
        const { moveDetails, status, name, accepted, isCompetitive } = this.props;
        const { isOpened, isActive } = this.state;

        const Layout = {
            'preInitiation': this.renderIncomplete(),
            'submitted': this.renderSubmitted(),
            'draft': this.renderDraft()
        };

        const iconStyle: React.CSSProperties = {
            cursor: typeof moveDetails === 'object' ? 'pointer' : 'default'
        };

        if (isOpened) {
            iconStyle.transform = 'rotate(180deg)'
        }

        return (
                <>
                    {isActive && (
                        <div className="your-talent-move flexible wrap">               
                            <div className="flexible horizontal aCenter">
                                {typeof moveDetails === 'object' && (
                                    <Icon name="chevronBlue" width={15} onClick={this.onArrowClick} style={iconStyle}/>
                                )}
                                <div className={'title-container'}>
                                    <h3>{name}</h3>
                                </div>
                            </div>
            
                            {Layout[status]}
                            {typeof moveDetails === 'object' && isOpened && (
                                <div className={'more-details flexible'}>                                    
                                    <CountryFlags countryCode={moveDetails.toCounrtryCode} countryName={null} />                                 
                                    <div className={'flexible vertical detail-titles'}>
                                        {moveDetails.to != null && (
                                            <span>Moving to:</span>
                                        )}
                                        <span>Moving from:</span>
                                        <span>Relocation Package/Policy:</span>
                                        <span>New Job Title:</span>
                                        <span>Job ID:</span>
                                    </div>
                                    <div className={'flexible vertical detail-values'}>
                                        {moveDetails.to !== null && (
                                            <span>{moveDetails.to}&nbsp;</span>
                                        )}
                                        <span>{moveDetails.from}&nbsp;</span>
                                        <span>{moveDetails.package}&nbsp;</span>
                                        <span>{moveDetails.newJob}&nbsp;</span>
                                        <span>{moveDetails.jobId}&nbsp;</span>
                                    </div>
                                </div>
                            )}
                            {accepted && (
                                <div className={'additional-info accepted flexible jBetween aCenter'}>
                                    <span className="notice">
                                        Has this employee accepted? Notify SIRVA so they can initiated relocation services.
                                    </span>
                                    <Button
                                        title="Mark as employee accepted new role"
                                        dataKey="employee-new-role"
                                        submit={true}
                                        className={"new-role-accepted"}
                                        onClick={() => null}
                                    />
                                </div>
                            )}
                        </div>  
                    )}
                </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(YourTalentMove));
