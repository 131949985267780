import React from 'react';

import { withFormsy } from 'formsy-react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider} from "material-ui-pickers";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import Indigo from "@material-ui/core/colors/indigo";

import { Customized } from '../elements'
import { ValidationContext } from '../ValidatableForm';
import { getRequiredLabel, getLabel } from '../utils';


const materialTheme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                width: "400px",
            }
        },
        MuiFormControl: {
            root: {
                width: "90%",
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                display: "none",
                backgroundColor: Indigo["200"],
            },
        },
        MuiPopover: {
            paper:{
                backgroundColor: "#F5F2F2",
            }
        },
        MuiPickersCalendarHeader:{
            switchHeader:{
                backgroundColor: "white",
                marginBottom: "2px",
            },
            daysHeader:{
                backgroundColor: "white",
                minHeight: "28px",
                justifyContent: "space-around"
            }
        },
        MuiPickersCalendar: {
            transitionContainer: {
                width: "100%",
                backgroundColor: "#e0e0e0",
                minHeight: "182px",
                marginTop: "2px"
            },
            week: {
                width: "100%",
                '& > div': {
                    width: "44px",
                },
             }
        },
        MuiPickersDay: {
            day: {
                margin: "0",
                borderRadius: "0",
                backgroundColor: "white",
                width: "100%",
                '&:hover': {
                    backgroundColor: "#ca001b",
                    "& span:first-child":{
                        color: "white"
                    }
                },
            },
            isDisabled: {
                backgroundColor: "#e0e0e0",
                '& > span:first-child': {
                    color: "#b0b0b0"
                },
            },
            isSelected: {
                '& > span:first-child': {
                    color: "white"
                },
                '&:hover': {
                    backgroundColor: "#ca001b",
                    color: "white"
                },
                backgroundColor: "#ca001b",
            },
        },
        MuiInputBase: {
            input: {
                '&:last-letter': {
                    color: "red"
                },
            }
        },
        MuiInput: {
            underline: {
                width: "250px",
                borderBottom: "2.5px solid black",
            }
        }
    },
});

class Picker extends React.Component {

    handleChange = (date) => {
        const value = date.format(this.props.format || 'L');
        this.props.setValue(value);
        this.props.onChange && this.props.onChange(value);
    };

    componentWillMount() {
        if(this.props.defaultValue) {
            this.props.setValue(this.props.defaultValue);
        }
    }


    render() {
        const { props } = this;
        const {
            inline = true,
            nativeProps
        } = props;

        const errorMessages = this.props.getErrorMessages();
        const label = props._required ? getRequiredLabel(props.label) : getLabel(props.label);
        const value = props.value ? moment(props.value, props.format).format("YYYY-MM-DD") : null;
        const { labelFunc }= this.props;

        return (
            <ValidationContext.Consumer>
                {(showValidationMessages) => (
                    <MuiThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Customized
                                onChange={this.handleChange}
                                errorMessages={errorMessages}
                                value={value}
                                minDate={props.minDate}
                                label={label}
                                labelFunc={labelFunc}
                                format={this.props.formatToShow}
                                shouldDisableDate={props.shouldDisableDate}
                                dataKey={props.dataKey}
                                helperText={props.helperText}
                                showValidationMessages={showValidationMessages}
                                inline={inline}
                                nativeProps={nativeProps}
                            />
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                )}
            </ValidationContext.Consumer>
        );
    }
}

export default withFormsy(Picker);
