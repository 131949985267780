import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { OidcProvider } from "redux-oidc";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { browserDetecter } from "utils/IESupport";
import history from './history';

import App from "App";
import { userService } from "services";
import store from "stores";
import JnJThemeProvider from "./components/common/JnJThemeProvider";

import registerServiceWorker from "registerServiceWorker";
import "./utils/IESupport.js";
import registercookietest from "./utils/3rdCookieSupport.js";

ReactDOM.render(
    <JnJThemeProvider>
        <Provider store={store}>
            <OidcProvider store={store} userManager={userService.userManager}>
                <Router history={history}>
                    <App />
                </Router>
            </OidcProvider>
        </Provider>
    </JnJThemeProvider>,
    document.getElementById("root")
);

browserDetecter();
registerServiceWorker();
registercookietest();

export {store}
