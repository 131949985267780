import React, { useState, useEffect } from "react";
import { RadioButton, Asset, Icon } from "components/common";
import MBA from "./MBA";
import FirstRotation from "./None";
import { History } from "history";
import GetPolicy from "../../Policy";
import { policyEnum } from "../../../policyMapping";
import { LDPScenario } from "../../keyMappings";
import Checkbox from '@material-ui/core/Checkbox';

const LDP = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    const { state: locationState } = props.history.location;
    const [state, setState] = useState<{
        [key: string]: string;
    }>({
        scenario: "",
    });
    const clickHandler = (procurementKey: string, empProcurementType: string, procurementTitle: string, policy: string): void => {
        props.history.push({
            state: {
                ...locationState,
                empProcurementType,
                policy,
                policyTier: policy,
                procurementKey,
                procurementTitle,
                eligibleTitle: policy ? policyEnum[policy].displayText : "NotSet",
                lumpSumPolicy: "NotSet",
                payGrade: "NotSet",
                homeownerOrRenter: "NotSet"
            },
        });
    };
    const [isChecked, setIsChecked] = useState(false);

    const checkedHandler = () => {
        //setIsChecked(!isChecked);
        if(isChecked) {
            setIsChecked(false);
            props.history.push({
                state: {
                    ...locationState,
                    "lumpSumPolicy": "No",
                    policy: locationState.policyTier
                },
            });
        } else {
            setIsChecked(true);
            props.history.push({
                state: {
                    ...locationState,
                    "lumpSumPolicy": "Yes",
                    policy: policyEnum.LumpSum.key
                },
            });
        }
        
    }
    // useEffect(() => {   //  back handler
    //     props.history.push({
    //         state: {
    //             ...locationState,
    //             empProcurementType: "NotSet",
    //             procurementKey: "NotSet",
    //             procurementTitle: "NotSet"
    //         }
    //     })
    // }, [locationState["policyType"]]);

    useEffect(() => {
        if (state["scenario"] === LDPScenario.InProcurement.key) {
            props.disableContinueBtn(false);
        } else {
            props.disableContinueBtn(true);
        }
    }, [state["scenario"]]);

    return (
        <>
            <div className="top">
                <div className="title">
                    Which of the following applies to the employee?
                </div>
            </div>
            <div className="options vertical-options">
                <div className="option">
                    <RadioButton
                        checked={state["scenario"] === LDPScenario.InProcurement.key}
                        onChange={e => { 
                            setState({
                                ...state,
                                scenario: LDPScenario.InProcurement.key,
                            })
                            clickHandler(LDPScenario.InProcurement.key,
                                LDPScenario.InProcurement.value, LDPScenario.InProcurement.title,
                                "USDomesticLDPFinalMove")
                        }}
                    />
                    <p className="title">The employee is in procurement.</p>
                </div>
                <div className="option">
                    <RadioButton
                        checked={state["scenario"] === "FLDP"}
                        onChange={e => { 
                            setState({
                                ...state,
                                scenario: "FLDP",
                            })
                            clickHandler("FLDP","FLDP", LDPScenario.FLDP.title, null)
                        }}
                    />
                    <p className="title">
                        The employee is MBA Finance Leadership Development
                        Program (FLDP).
                    </p>
                </div>
                <div className="option">
                    <RadioButton
                        checked={state["scenario"] === LDPScenario.NonProcurNonFLDP.key}
                        onChange={e => { 
                            setState({
                                ...state,
                                scenario: LDPScenario.NonProcurNonFLDP.key,
                            })
                            clickHandler(LDPScenario.NonProcurNonFLDP.key,
                                LDPScenario.NonProcurNonFLDP.value, LDPScenario.NonProcurNonFLDP.title, null)
                        }}
                    />
                    <p className="title">
                        The employee is NOT in procurement and is NOT an MBA
                        FLDP.
                    </p>
                </div>
            </div>
            {state["scenario"] === LDPScenario.InProcurement.key && (
                <div className="download-policy-section">
                    <p className="title">What's included?</p>
                    <div className="horizontal-section download" >
                        <Icon name="linkIcon" className="linkIconCls" />
                        <p>
                            <a target="_blank" href={process.env.REACT_APP_EXT_LINK_US_DOMESTIC_RESOURCES} >Policy for {policyEnum.USDomesticLDPFinalMove.displayText}</a>
                        </p>
                    </div>
                    <>
                        <p className="lum-sum-policy-header-title" >{policyEnum.LumpSum.title}</p>
                        <p className="lum-sum-policy-checkbox-area" ><Checkbox checked={isChecked} onChange={() => {checkedHandler()}} /> &nbsp;Yes</p>
                        <br />
                    </>
                </div>
            )}
            {state["scenario"] === "FLDP" && (
                <div className="FLDP">
                    <MBA disableContinueBtn={props.disableContinueBtn} history={props.history}/>
                </div>
            )}
            {state["scenario"] === LDPScenario.NonProcurNonFLDP.key && (
                <div className="None">
                    <FirstRotation
                        disableContinueBtn={props.disableContinueBtn} history={props.history}
                    />
                </div>
            )}
        </>
    );
};

export default LDP;
