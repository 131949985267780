// @flow

import React from 'react';
import './styles.scss';

type TextWithLabelProps = {
    label: string,
    text: string,
    className: string
};

export default class TextWithLabel extends React.PureComponent<TextWithLabelProps> {
    render() {
        return (
            <div className={`TextWithLabel flexible vertical jCenter ${this.props.className || ''}`}>
                <p className="label">
                    {this.props.label}
                </p>
                <p className="text">
                    {this.props.text}
                </p>
            </div>
        );
    }
}