import React, { useState,useEffect } from "react";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Icon, InputRequired } from "components/common";
import { EmppData, MoveDetailsAutoComplete } from "types";
import "./styles.scss";

function renderInputComponent(inputProps: any) {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;

    return (
        <div className="Input ">
            <TextField
                fullWidth
                InputProps={{
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: {
                        input: classes.input,
                    },
                }}
                {...other}
            />
        </div>
    );
}

function renderSuggestion(
    suggestion: EmppData,
    { query, isHighlighted }: Autosuggest.RenderSuggestionParams,
) {
    const matches = match(suggestion.displayName, query);
    const parts = parse(suggestion.displayName, matches);

    return (
        <MenuItem
            selected={isHighlighted}
            component="div"
            classes={{ selected: "selected-menu", root: "combobox-item" }}
        >
            <span>
                {parts.map(part => (
                    <span
                        key={part.text}
                        style={{ fontWeight: part.highlight ? 900 : 300 }}
                    >
                        {part.text}
                    </span>
                ))}
            </span>
            {suggestion.jobTitle && (
                <span className={"combobox-item-position"}>
                    {suggestion.jobTitle}
                </span>
            )}
        </MenuItem>
    );
}

function getSuggestionValue(
    suggestion: EmppData,
    props: MoveDetailsAutoComplete,
) {
    props.handleClick && props.handleClick(suggestion);
    return suggestion.displayName;
}

const InitiateNameAutoComplete = (props: MoveDetailsAutoComplete) => {
    const classes = useStyles();
    const [value, setValue] = useState(props.value);
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (val?: string) => (
        event?: React.ChangeEvent<{}>,
        option?: any,
    ) => {
        if (val === "") {
            props.onChange("");
            setValue(val);
            setIsFocused(false);
        }
        if (val !== "" && typeof props.onChange === "function") {
            if (option.method !== "click") {
                props.onChange(option.newValue);
            }
            setValue(option.newValue);
        }
    };

    let selectedSuggestion = props.suggestions.find(
        (el: EmppData): boolean => value === el.displayName,
    );

    let handleFocus = (val?: boolean) => () => {
       setIsFocused(true);
    } 
    let handleBlur = (val?: boolean) => () => {
        if(value !== '') {
            setIsFocused(true);
        } else {
            setIsFocused(false); 
        }
    }  

    useEffect(() => {
        if(props.value !== null) {
            setValue(props.value);
        }
    });

    return (
        <div className="autosuggest-wrapper-container" style={props.style}>
            <h6 className={classes.title}>{props.title}</h6>
            {isFocused && props.inputTitle  && (
                <h6 className={classes.subtitle}>
                    {props.inputTitle}
                    <span className={classes.required}>*</span>
                </h6>
            )}
            <div className="autosuggest-container">
                <Autosuggest
                    renderInputComponent={renderInputComponent}
                    suggestions={props.suggestions}
                    onSuggestionsClearRequested={() => {}}
                    getSuggestionValue={value =>
                        getSuggestionValue(value, props)
                    }
                    onSuggestionsFetchRequested={() => {}}
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                        classes,
                        labelPlaceholder: `${props.placeholder}`,
                        value,
                        onChange: handleChange(),
                        onFocus: handleFocus(),
                        onBlur: handleBlur()
                    }}
                    theme={{
                        container: "combo-box-container",
                        suggestionsContainerOpen:
                            classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                        input: "input-top-container",
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                />
                {!!value.length && (
                    <Icon
                        name={"close"}
                        className={"close-icon"}
                        width={15}
                        onClick={(): void => handleChange("")()}
                    />
                )}
                {props.isLoading && (
                    <span className="select-field-spinner">
                        <div className="Spinner " />
                    </span>
                )}
            </div>
            { value.length == 0 && !isFocused && !selectedSuggestion && (
                <span className="placeholder">{props.inputTitle}<span className={classes.required}>*</span></span>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 18,
            fontWeight: 900,
            lineHeight: "36px",
            color: theme.palette.text.primary,
        },
        subtitle: {
            color: "#63666A",
            fontSize: 12,
            fontWeight: 300,
            lineHeight: "18px",
            marginTop: 9,
        },
        required: {
            color: "#ca001b",
            marginLeft: 2,
        },
        suggestionsContainerOpen: {
            position: "absolute",
            zIndex: 1,
            left: 0,
            right: 0,
        },
        suggestion: {
            display: "block",
        },
        suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: "none",
        },
        input: {
            paddingTop: 7,
            paddingBottom: 12,
            fontSize: 16,
            fontWeight: 300,
        },
    }),
);

export default InitiateNameAutoComplete;
