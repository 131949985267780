import React, { PureComponent, useEffect, useState } from "react";
import { Button, Link, ApprovalAutoComplete, FinanceInputAutoComplete, SpinnerWrapper } from "components/common";
// import { generalMessages } from "./mockData";
import { OptionType } from "../AutoComplete";
// import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { moveInitiationService } from "services";
import { History } from "history";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { useMediaQuery } from "@material-ui/core";
// import "../../InitiateMove/FinanceAndAdmin/style.scss";

const isObj = (obj: object) => obj !== undefined && Object.values(obj).length > 0;

const isObjParam = (obj: object, key: string) => isObj(obj) 
    ? obj[key] != null && typeof obj[key] !== undefined  
        ? obj[key] : null 
            : null;

const mapDispatchToProps = {
    fetchFinancePartner: moveInitiationService.actions.fetchFinancePartner,
    setCompFinanceDetails: moveInitiationService.actions.setCompFinanceDetails,
};

const FinanceAdmin = (props: {
    navigateTo: (to: string, from: string) => void;
    history: History;
    location: Location;
}) => {

    const { state: locationState } = props.history.location;
    const dispatch = useDispatch();

    const [state, setState] = useState({
        currentMessage: -1,
        showContent: false,
        manager: isObjParam(locationState, "manager"),
        derivedSecondManager: isObjParam(locationState, "derivedSecondManager"),
        partnersOne: [],
        partnersTwo: [],
        isLoadingFieldOne: false,
        isLoadingFieldTwo: false,
        selectedFirstManager: null,
        selectedSecondManager: null,
        disableStyle: "disabled", 
        disableButton: true,
        isLoading: false,
        partnerName: isObjParam(locationState, "partnerName"),
        partnerNames: [],
        isLoadingHr: false,
        partnerHrName: isObjParam(locationState, "partnerHrName"),
        partnerHrNames: [],
        isLoadingSave: false,
    });

    const {manager, derivedSecondManager, partnerName, partnerNames, partnersTwo, partnersOne, isLoading,
        isLoadingHr, isLoadingFieldOne, partnerHrName, isLoadingFieldTwo, partnerHrNames, isLoadingSave, disableButton } = state;

    useEffect(() => { //  Idle handler
        if(!isObj(locationState)) {
            props.history.replace("/move-dashboard");
        }
    }, [locationState])
    
    // interval: any;
    // generatedMessages: Array<JSX.Element> = [];
    // state = {
    //     currentMessage: 0,
    //     showContent: false,
    //     partner: "",
    //     selectedPartner: (props.history.location.state.selectedPartner != null && typeof props.history.location.state.selectedPartner !== 'undefined') ? 
    //                     props.history.location.state.selectedPartner : null,
    //     partners: [],
    //     isLoading: false,
    //     messages: [],
    // };

    const clearAutoComplete = () => [];

    const handleClickFirstManager = (manager: OptionType) => {
        setState(prevState => ({
            ...prevState,
            manager,
            isLoadingFieldOne: false,
        }));
    }    

    const handleClickSecondManager = (derivedSecondManager: OptionType) =>
        setState(prevState => ({
        ...prevState,
            derivedSecondManager,
            isLoadingFieldTwo: false,
        }));


    const onManagerChange = (value: string) => {
        if (value === "") {
            const clearSecond = {
                label: "clear",
                subLabel: "",
                displayName: "",
                firstName: "",
                jobTitle: "",
                lastName: "",
                email: "",
                wwid: "",
            };
            setState(prevState => ({
                ...prevState,
                manager: null,
                derivedSecondManager: clearSecond,
                disableStyle: "disabled",
            }));
        } else {
            if (value.length > 2) {
                setState(prevState => ({
                    ...prevState,
                    isLoadingFieldOne: true,
                }));
                let secondManager = undefined;
                dispatch(moveInitiationService.actions.fetchFinancePartner(value))
                .then(partnersOne => {
                    if( partnersOne.result.length > 0) {
                        // if(partnersOne.result[0].manager !== undefined && partnersOne.result[0].manager.length > 0) {
                        //     secondManager = partnersOne.result[0].manager;    
                        // }
                        setState(prevState => ({
                            ...prevState,
                            partnersOne: partnersOne.result,
                            derivedSecondManager: secondManager,
                            isLoadingFieldOne: false,
                        }));
                    }
                })
                .catch(err => console.log(err));
            } else {
                if (state.partnersOne.length) {
                    setState(prevState => ({
                        ...prevState,
                        partnersOne: [],
                    }));
                }
            }
        }
    };

    const onSecondManagerChange = value => {
        if (value === "") {
            setState(prevState => ({
                ...prevState,
                derivedSecondManager: null,
            }));
        } else {
            if (value.length > 2) {
                setState(prevState => ({
                    ...prevState,
                    isLoadingFieldTwo: true,
                }));
                dispatch(moveInitiationService.actions.fetchFinancePartner(value))
                .then(partnersTwo => {
                    if( partnersTwo.result.length > 0) {
                        setState(prevState => ({
                            ...prevState,
                            partnersTwo: partnersTwo.result,
                            isLoadingFieldTwo: false,
                        }));
                    }    
                })
                .catch(err => console.log(err));
            } else {
                if (state.partnersTwo.length) {
                    setState(prevState => ({
                        ...prevState,
                        partnersTwo: [],
                    }));
                }
            }
        }
    };
  
    const handleClick = (partnerName: OptionType) =>
        setState(prevState => ({
        ...prevState,
            partnerName,
            isLoading: false,
        }));

    const changeHandler = (partner: string) => {
        if (
            state.partnerName &&
            state.partnerName.label !== partner
        ) {
            setState(prevState => ({
                ...prevState,
              partnerName: null,
            }));
        }
        if (partner.length > 2) {
            setState(prevState => ({
                ...prevState,
                isLoading: true,
            }));
            dispatch(moveInitiationService.actions.fetchFinancePartner(partner))
            .then(partners => {
                setState(prevState => ({
                    ...prevState,
                    partnerNames: partners.result,
                    isLoading: false,
                }));
            })
            .catch(err => console.log(err));
        } else {
            if (state.partnerNames.length) {
                setState(prevState => ({
                    ...prevState,
                  partnerNames: [],
                }));
            }
        }
    };

    const handleClickHr = (partnerHrName: OptionType) =>
        setState(prevState => ({
        ...prevState,
            partnerHrName,
            isLoadingHr: false,
        }));

    const changeHandlerHr = (partner: string) => {
        if (
            state.partnerHrName &&
            state.partnerHrName.label !== partner
        ) {
            setState(prevState => ({
                ...prevState,
              partnerHrName: null,
            }));
        }
        if (partner.length > 2) {
            setState(prevState => ({
                ...prevState,
                isLoadingHr: true,
            }));
            dispatch(moveInitiationService.actions.fetchFinancePartner(partner))
            .then(partners => {
                setState(prevState => ({
                    ...prevState,
                    partnerHrNames: partners.result,
                    isLoadingHr: false,
                }));
            })
            .catch(err => console.log(err));
        } else {
            if (state.partnerHrNames.length) {
                setState(prevState => ({
                    ...prevState,
                    partnerHrNames: [],
                }));
            }
        }
    };

// 	componentDidUpdate = () => {
//         enableContinueBtn();        
//     } 
    
//     const enableContinueBtn = () => {
//         const {manager, derivedSecondManager} = state;
//         if( (manager !== null && manager !== undefined ) && (derivedSecondManager !== null && derivedSecondManager !== undefined )){
//             setState(prevState => ({
//                 ...prevState,disableStyle: "", disableButton: false}))
//         } else {
//             setState(prevState => ({
//                 ...prevState,disableStyle: "disabled", disableButton: true}))
//         }
//    }
   useEffect(() => {
    if( (manager !== null && manager !== undefined ) && (derivedSecondManager !== null && derivedSecondManager !== undefined )){
        setState(prevState => ({
            ...prevState,disableStyle: "", disableButton: false}))
    } else {
        setState(prevState => ({
            ...prevState,disableStyle: "disabled", disableButton: true}))
    }
   }, [manager, derivedSecondManager]);
    
    const saveFinanceMoveDetails = (location: string) => {
        setState(prevState => ({
            ...prevState,isLoadingSave: true}));
        let { partnerHrName, partnerName, manager, derivedSecondManager } = state;
        dispatch(moveInitiationService.actions.setMoveFinanceMoveDetails({
            "initiationId": (locationState.initiationId !== undefined) ? locationState.initiationId : '',
            "newFundingFinancePartner": (partnerName !== null) ? partnerName : {},
            "hostHumanResourcesContact": (partnerHrName !== null) ? partnerHrName : {},
            "hirinManager": (manager !== null) ? manager : {},
            "hirinManagerLevel2": (derivedSecondManager !== undefined) ? derivedSecondManager : {}, 
        }))
        .then(data => {
            setState(prevState => ({
                ...prevState,isLoadingSave: false}));
            if(data.success) {
                if(location !== undefined && location !== "")
                    props.navigateTo(location, "finance-admin");
            } else {
                console.log(data.message);
            }
        })
        .catch(console.log);
    }
    const scrnLg = useMediaQuery('(min-width:1024px)', { noSsr: true });

    return (
        <div className={"employee-job-details-container-nc non-competitive-redesign finance-admin-container"}>
        <h1>Let’s finish up!</h1>
        <div style={{ 
            width: scrnLg ? 773 : 'initial', paddingTop: 35
            }} className="RecruitedSelectorContainer finace-admin-body-container">
        <SpinnerWrapper show={isLoadingSave}>
        {/* <div className="finance">
            <h1>Let’s finish up!</h1> */}
            <div className="finance-admin-details-container">
            <div className="left-container">
                <div className="funding-finance-partner">
                <span className="questen">Who is the Funding Finance Partner for this employee's new team?</span>
                    <div className="inputs"> 
                    <div className="Input">
                        <FinanceInputAutoComplete
                            required={false}
                            isLoading={isLoading}
                            placeholder={"Funding Finance Partner"}
                            inputTitle={"Funding Finance Partner"}
                            clearAutoComplete={clearAutoComplete}
                            suggestions={partnerNames}
                            handleClick={handleClick}
                            style={{ marginTop: 38 }}
                            onChange={changeHandler}
                            value={partnerName}
                        />
                    </div>
                    </div>
                    <div style={{ marginTop: 10 }} />
                    {partnerName !==null ? "" : (
                        <span className="not-sure">Not sure? Leave this field blank and we will work with you to identify who your Funding Finance Partner is.</span>
                    )}
                </div>
                <div style={{ marginTop: 40 }} />
                <div className="human-resources">
                <span className="questen">Who is the Host Human Resources contact for this employee?</span>
                    <div className="inputs"> 
                    <div className="Input">
                    <FinanceInputAutoComplete
                            required={false}
                            isLoading={isLoadingHr}
                            placeholder={"Host Human Resources contact"}
                            inputTitle={"Host Human Resources contact"}
                            clearAutoComplete={clearAutoComplete}
                            suggestions={partnerHrNames}
                            handleClick={handleClickHr}
                            style={{ marginTop: 38 }}
                            onChange={changeHandlerHr}
                            value={partnerHrName}
                        />
                    </div>
                    </div>
                </div>
                <div className="approver-1">
                    <ApprovalAutoComplete
                    required
                        title={"Approver #1: Hiring Manager"}
                        placeholder={"Enter name"}
                        inputTitle={"Hiring Manager Name"}
                        clearAutoComplete={clearAutoComplete}
                        handleClick={handleClickFirstManager}
                        suggestions={partnersOne}
                        style={{ marginTop: 38 }}
                        onChange={onManagerChange}
                        isLoading={isLoadingFieldOne}
                        value={manager}
                        isconfirmApprover={true}
                    />
                </div>
                <div style={{ marginTop: 40 }} />
                <div className="approver-2" >
                    <ApprovalAutoComplete
                        required
                        title={"Approver #2: Hiring Manager's Manager"}
                        placeholder={"Enter name"}
                        inputTitle={"Hiring Manager's Manager Name"}
                        clearAutoComplete={clearAutoComplete}
                        suggestions={partnersTwo}
                        secondManagerData={derivedSecondManager}
                        style={{ marginTop: 38 }}
                        onChange={onSecondManagerChange}
                        handleClick={handleClickSecondManager}
                        isLoading={isLoadingFieldTwo}
                        isconfirmApprover={true}
                        value={null}
                    />
                </div>
            </div>
            <hr className="line" style={{ marginTop: 35 }} />
            <section className="button-section">
            <div className={"flexible"} style={{ justifyContent: 'space-between' }}>
                <Button
                    title="Go Back"
                    className="job-details-submit"
                    style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", minWidth: 150 }}
                    labelStyle={{color:"#CA001B"}}
                    onClick={() => {
                        props.navigateTo("additional-move-details", "finance-admin")
                    }}
                    />
                <Button
                    title="Continue"
                    className={"job-details-submit"}
                    disabled={disableButton}
                    style={{ backgroundColor:"#CA001B", minWidth: 150 }}
                    labelStyle={{color:"#FFFFFF"}}
                    onClick={() => {
                        props.history.push({
                            state: {
                                ...locationState,
                                partnerHrName, partnerName,manager,derivedSecondManager
                            }
                        });
                    saveFinanceMoveDetails("review-submit")
                    }}
                />
            </div>
            </section>
            </div>
            </SpinnerWrapper>
            </div>
            </div>
    );
}

export default connect(null, mapDispatchToProps)(FinanceAdmin);