import React from 'react';
import TextField from '@material-ui/core/TextField';

import './styles.scss';

export default class TextArea extends React.PureComponent {
    static defaultProps = {
        value: '',
    };

    onChange = event => !this.props.disabled && this.props.onChange && this.props.onChange(event);

    render() {
        const { props } = this;

        return (
            <div className="TextArea">
                <TextField
                    multiline
                    disabled={props.disabled}
                    rows={props.rowsCount}
                    value={props.value}
                    label={props.label}
                    fullWidth
                    onChange={this.onChange}
                    inputProps={{
                        'data-key': props.dataKey
                    }}
                />

                { (props.showValidationMessages && props.errorMessages) && props.errorMessages.map((message, i) => (
                        <p className="formElement_errorText" key={i}>
                            {message}
                        </p>
                    )
                )}
                {props.helperText && (
                    <p className="formElement_helperText">
                        {props.helperText}
                    </p>
                )}
            </div>
        );
    }
}
