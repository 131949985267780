// @flow

import { Api } from 'backend';

const DocumentsAPI = {
    getDocuments: (relocationID: string): Promise<any> =>
        Api.get({path: `/Sirva/relocations/${relocationID}/documents`}),
    downloadDocument: (relocationID: string, documentID: number): Promise<any> =>
        Api.get({
            path: `/Sirva/relocations/${relocationID}/documents/${documentID}`,
            additionalOptions: {
                responseType: 'blob',
            }
        }),
};

export default DocumentsAPI;