import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { History } from "history";
import { moveInitiationService } from "services";
import "./styles.scss";
import { ReviewAndSubmitProps } from "types";

const ReviewAndSubmit = (props: ReviewAndSubmitProps) => {

    const { state: locationState } = props.history.location;
    
    const { category } = locationState;
    const preInitData = locationState.empCompetitiveInit;
    const policyData = locationState.policyData;
    const financeData = locationState.financeData;
    const approvalsData = locationState.approvalsData;
    const scenario_key = locationState.scenario;

    const newHireData = (locationState.empCompetitiveInit.offers !== undefined && 
        locationState.empCompetitiveInit.offers !== null) ? 
            ((locationState.empCompetitiveInit.offers.hireStatus !== undefined && 
                locationState.empCompetitiveInit.offers.hireStatus !== null) ? 
                    locationState.empCompetitiveInit.offers.hireStatus : null) : null;
    const isNewHire = (newHireData !== null) ? 
    (((newHireData).toLowerCase() == "new hire") ? true : false) : false;

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [continueButtondisabled, setContinueButtondisabled] = useState(false);
    
    const userData = {
        services: "Full Relocation",
        policy: null,
        isNewHire: isNewHire,
    };
    const calMoveTier = {
        "Tier1CaliforniaMarket": "Tier 1: California Market", 
        "Tier2CriticalSkillsPosition":"Tier 2: Critical Skills Position",
        "Tier3BusinessCriticalRole":"Tier 3: Business Critical Role",
    }
    const scenario = {
        "procurement" : "Leadership Development Program (LDP)",
        "FLDP": "MBA Finance Leadership Development Program (FLDP)",
        "None": "Not in procurement and is NOT an MBA FLDP"
    }


    function submitHandler() {
        setIsLoading(true);
        setContinueButtondisabled(true);
        const initiationId = (locationState.initiationId !== undefined) ? locationState.initiationId : '';
        dispatch(moveInitiationService.actions.setMoveEmppSirvaDetails(initiationId)).then(data => {
            setIsLoading(false);
            setContinueButtondisabled(false);
            if(data.success) {
                props.history.push({
                    pathname: "/move-details/success",
                    state: {
                        ...locationState,
                        success: true,
                    },
                });
            } else {
                console.log(data.message);
            }
        })
        .catch(err => console.log(err));
    }

    return (
        <div className="review-and-submit-form">
            <h1>Please review and then submit</h1>
            <hr className="line" />
            <p className="section-title">Services request for:</p>
            <div className="detailsSection">
                <div>
                    <section>
                        <p className="move-details-titles">Employee:</p>
                        <span className="move-details-heading">
                            {preInitData.candidate.firstName} {preInitData.candidate.lastName}
                        </span>
                        {(userData.isNewHire) ? (
                            <section className="buttonSection">
                                <button className="newHire">
                                    <span className="buttonLabel">NEW HIRE</span>
                                </button>
                            </section>
                        ) : null}
                    </section>
                    {/* <section>
                        <p className="move-details-titles">
                            Services being initiated:
                        </p>
                        <span className="move-details-heading">
                            {userData.services}
                        </span>
                        <br />
                        <span className="move-details-sub-heading">
                            {userData.policy && (
                                <>
                                    Policy: {userData.policy}
                                </>
                            )}
                        </span>
                    </section> */}
                </div>
                <div>
                    <section>
                        <p className="move-details-titles">New Job Title:</p>
                        <span className="move-details-heading">
                            {preInitData.requisition.jobInformation.title}
                        </span>
                        <br />
                        <span className="move-details-sub-heading">
                            ID: {preInitData.requisition.jobInformation.positionID}
                        </span>
                    </section>

                    <section>
                        <p className="move-details-titles">
                            New Work Location:
                        </p>
                        <span className="move-details-heading">
                            {preInitData.requisition.jobInformation.country}
                        </span>
                        <br />
                        {preInitData != null && (preInitData.requisition.jobInformation.primaryLocationAddress1 !== null || preInitData.requisition.jobInformation.primaryLocationAddress2 !== null || preInitData.requisition.jobInformation.primaryLocationName !== null || preInitData.requisition.jobInformation.primaryLocationCity !== null || preInitData.requisition.jobInformation.primaryLocationState !== null) ?
                        <span className="move-details-sub-heading">
                            {preInitData.requisition.jobInformation.primaryLocationAddress1 !== null ? (
                                <>
                                    {preInitData.requisition.jobInformation.primaryLocationAddress1} <br />
                                </>
                                ) : null}
                            {preInitData.requisition.jobInformation.primaryLocationAddress2 !== null ? (
                                <>
                                    {preInitData.requisition.jobInformation.primaryLocationAddress2} <br />
                                </>
                                ) : null}
                            {preInitData.requisition.jobInformation.city !== null ?
                            preInitData.requisition.jobInformation.city : null } 
                            {preInitData.requisition.jobInformation.stateProvince !== null ? 
                            ',' + preInitData.requisition.jobInformation.stateProvince
                            : null }
                        </span>
                        : null }
                    </section>
                </div>
            </div>
            <hr className="line" style={{ marginTop: 10 }} />
            <p className="section-title">Details</p>
            <div className="detailsSection">
                <div>
                    {(category != undefined && category === "ldp") &&
                    (scenario_key!= null || scenario_key != undefined) ?
                    <section>
                        <p className="move-details-titles">
                            J&J Leadership Development Program:
                            <span
                                onClick={(): void =>
                                    props.navigateTo("policy-selection","job-details")
                                }
                                className="edit-button"
                            >
                                Edit
                            </span>
                        </p>
                        <span className="move-details-heading">
                            {scenario[scenario_key]}
                        </span>
                    </section>
                    : null}
                </div>
                <div>
                    {(policyData.competitiveEmployeeInfo.calMoveTier !== null && policyData.competitiveEmployeeInfo.calMoveTier !== undefined ) ? (
                        <section>
                            <p className="move-details-titles">
                                California Tier:
                                <span
                                    onClick={(): void =>
                                        props.navigateTo("job-details","review-submit")
                                    }
                                    className="edit-button"
                                >
                                    Edit
                                </span>
                            </p>
                            <span className="move-details-heading">
                                {calMoveTier[policyData.competitiveEmployeeInfo.calMoveTier]}
                            </span>
                        </section>
                    ) : null}
                </div>
                <div>
                    <section style={{ width: "100%" }}>
                        <p className="move-details-titles">
                            Funding Finance Contact Name:
                            <span
                                onClick={(): void =>
                                    props.navigateTo("finance","approvals")
                                }
                                className="edit-button"
                            >
                                Edit
                            </span>
                        </p>
                        <span className="move-details-heading">
                            {financeData.financePartner.firstName + ' ' + financeData.financePartner.lastName}
                        </span>
                        <br />
                        <span className="move-details-sub-heading">
                            WWID: {financeData.financePartner.wwid}
                        </span>
                        <br />
                        <span className="move-details-sub-heading">
                            Email: {financeData.financePartner.email}
                        </span>
                    </section>
                </div>
                <div>
                    <section>
                        <p className="move-details-titles">
                            Approver #1 Hiring Manager:
                            <span
                                onClick={(): void =>
                                    props.navigateTo("approvals","review-submit")
                                }
                                className="edit-button"
                            >
                                Edit
                            </span>
                        </p>
                        <span className="move-details-heading">
                            {approvalsData.hirinManager.firstName + ' ' + approvalsData.hirinManager.lastName}
                        </span>
                        <br />
                        <span className="move-details-sub-heading">
                            WWID: {approvalsData.hirinManager.wwid}
                        </span>
                        <br />
                        <span className="move-details-sub-heading">
                            Email: {approvalsData.hirinManager.email}
                        </span>
                    </section>

                    <section>
                        <p className="move-details-titles">
                            Approver #2 Hiring Manager’s Manager:
                            <span
                                onClick={(): void =>
                                    props.navigateTo("approvals","review-submit")
                                }
                                className="edit-button"
                            >
                                Edit
                            </span>
                        </p>
                        <span className="move-details-heading">
                            {approvalsData.hirinManagerLevel2.firstName + ' ' + approvalsData.hirinManagerLevel2.lastName}
                        </span>
                        <br />
                        <span className="move-details-sub-heading">
                            WWID: {approvalsData.hirinManagerLevel2.wwid}
                        </span>
                        <br />
                        <span className="move-details-sub-heading">
                            Email: {approvalsData.hirinManagerLevel2.email}
                        </span>
                    </section>
                </div>
            </div>
            <hr className="line" style={{ marginTop: 10 }} />
            <button 
                className="submitButton" 
                onClick={submitHandler}
                disabled={continueButtondisabled}
            >
                Submit
            </button>
        </div>
    );
};

export default ReviewAndSubmit;
