import React from "react";
import { Icon, SelectionCard } from "components/common";
import Lightbulb from "components/common/Images/Lightbulb.svg";
import NoMoving from "components/common/Images/NoMoving.svg";
import WorkingWithRecruiter from "../WorkingWithRecruiter";
import WhichOptions from "../WhichOptions";
import RouterProps from "types";

const GlobalAssignment = (props: RouterProps) => {
    const clickHandler = (value: string, alreadyInAssignment: boolean): void =>
        props.history.push({
            state: {
                ...props.location.state,
                "global-assignment": value,
                alreadyInAssignment: alreadyInAssignment,
            },
        });

    const backHandler = (): void =>
        props.history.push({
            state: {},
        });

    if (
        props.location.state &&
        props.location.state["global-assignment"] === "yes"
    ) {
        return (
            <WhichOptions history={props.history} location={props.location} />
        );
    } else if (
        props.location.state &&
        props.location.state["global-assignment"] === "no"
    ) {
        return (
            <WorkingWithRecruiter
                history={props.history}
                location={props.location}
            />
        );
    }

    return (
        <div className="people-leader-container">
            <div className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </div>
            <h1 className="section-title assignment-question-h1">
                Is the employee already on a global assignment?
            </h1>
            <p className="assignment-question-txt-dsc" >(Long Term, Short Term, Commuter, LDP Program)</p>
            <section className="CardSection">
                <SelectionCard
                    type="optionSelectionCard"
                    backgroundColor="white"
                    title="Yes"
                    icon={Lightbulb}
                    value="yes"
                    step="fifty-percent"
                    onClick={() => clickHandler("yes", true)}
                />
                <SelectionCard
                    type="optionSelectionCard"
                    backgroundColor="white"
                    title="No"
                    value="no"
                    icon={NoMoving}
                    step="fifty-percent"
                    onClick={() => clickHandler("no", false)}
                />
            </section>
        </div>
    );
};

export default GlobalAssignment;
