import { getDataStore } from "../index";

export const getMoveInitiation = (state: any) =>
    getDataStore(state).moveInitiation;

export const getLeaderShipPrograms = (state: any) => {
    const moveInitiation = getMoveInitiation(state);
    return moveInitiation ? moveInitiation.leaderShipPrograms : null;
};

export const getCaliforniaTiers = (state: any) => {
    const moveInitiation = getMoveInitiation(state);
    return moveInitiation ? moveInitiation.californiaTiers : null;
};

export const getEmpJobDetails = (state: any) => {
    const moveInitiation = getMoveInitiation(state);
    return moveInitiation ? moveInitiation.empJobDetails : null;
};

export const getEmpPosJobDetails = (state: any) => {
    const moveInitiation = getMoveInitiation(state);
    return moveInitiation ? moveInitiation.empPosJobDetails : null;
};

export const getEmpCompetitiveInit = (state: any) => {
    const moveInitiation = getMoveInitiation(state);
    return moveInitiation ? moveInitiation.empCompetitiveInitDetails : null;
};