import React from "react";
import { Icon, SelectionCard } from "components/common";
import { RouterProps } from "types";
import TalentMobilityOWM from "../TalentMobilityOWM";
import ReqWdScreen from "../ReqWD";
import "./styles.scss";

const OneWayMove = (props: RouterProps) => {
    const clickHandler = (value: string): void =>
        props.history.push({
            state: {
                ...props.location.state,
                "owm-or-assignment": value,
            },
        });

    const backHandler = (): void =>
        props.history.push({
            state: {
                ...props.location.state,
                "global-vs-domestic": "",
            },
        });

    if (
        props.location.state &&
        props.location.state["owm-or-assignment"] === "owm"
    ) {
        return (
            <TalentMobilityOWM
                history={props.history}
                location={props.location}
            />
        );
    } else if (
        props.location.state &&
        props.location.state["owm-or-assignment"] === "assignment"
    ) {
        return ( <ReqWdScreen history={props.history} location={props.location}/> );
    }

    return (
        <div className="people-leader-container">
            <div className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </div>
            <h1 className="section-title">Is this a one-way move or assignment?</h1>
            <section className="CardSection">
                <SelectionCard
                    type="regularSelectionCard"
                    backgroundColor="white"
                    title="One Way Move"
                    content="A Global relocation cross border that is intended to last over 5 years."
                    className="one-way-move-or-assignment"
                    onClick={(): void => clickHandler("owm")}
                />
                <SelectionCard
                    type="regularSelectionCard"
                    backgroundColor="white"
                    title="Assignment"
                    content="A temporary relocation intended to last between 3 months and 5 years."
                    className="one-way-move-or-assignment"
                    onClick={(): void => clickHandler("assignment")}
                />
            </section>
        </div>
    );
};

export default OneWayMove;
