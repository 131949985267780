import * as React from "react";
import { SelectionCard } from "components/common";
import { Icon } from "components/common";
import PayGrade from "../PayGrade";
import ShortTerm from "../EligibleMove/shortTerm";
import { RouterProps } from "types";
import "./styles.scss";

const Assignment = (props: RouterProps) => {
    const clickHandler = (value,lengthOfAssignment): void =>
        props.history.push({
            state: {
                ...props.location.state,
                assignment: value,
                lengthOfAssignment: lengthOfAssignment,
            },
        });

    if (props.location.state && props.location.state["assignment"] === "12m") {
        
        return (
            <ShortTerm
                history={props.history}
                location={props.location}
            />
        );
    }
    if (
        props.location.state &&
        props.location.state["assignment"] &&
        props.location.state["assignment"] === "4y"
    ) {
        return (
            <PayGrade
                history={props.history}
                location={props.location}
                fromAssignment
            />
        );
    } else {
        return (
            <article className="assignment-container">
                <div
                    className="back-icon"
                    onClick={() =>
                        props.history.push({
                            state: {
                                ...props.location.state,
                                "fifty-percent": "",
                            },
                        })
                    }
                >
                    <Icon name="backIcon" />
                    <p>Go back</p>
                </div>
                <h1 className="section-title">
                    How long is the assignment expected to last?
                </h1>
                <section className="CardSection">
                    <SelectionCard
                        type="optionSelectionCard"
                        backgroundColor="white"
                        title="12 months or less"
                        value="yes"
                        step="fifty-percent"
                        route="/initiate-move/recruitment-selector"
                        onClick={() => clickHandler("12m","LessThanYear")}
                    />
                    <SelectionCard
                        type="optionSelectionCard"
                        backgroundColor="white"
                        title="1 - 5 years"
                        value="no"
                        step="fifty-percent"
                        route="/initiate-move/recruitment-selector"
                        onClick={() => clickHandler("4y","OneToFourYear")}
                    />
                </section>
            </article>
        );
    }
};

export default Assignment;
