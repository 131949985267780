export const getDataStore = state => state.dataStore || {};

export const getAssignmentHeader = state =>
    getDataStore(state).assignmentHeader;
export const getExpenseReportCategories = state =>
    getDataStore(state).expenseReportCategories;
export const getExpenseReports = state => {
    const expenseReports = getDataStore(state).expenseReports;
    return expenseReports
        ? { isLoading: false, payload: expenseReports }
        : { isLoading: true, payload: [] };
};
export const getExpenseReportItem = state =>
    getDataStore(state).expenseReportItem;
export const getExpenseReportLineItem = state =>
    getDataStore(state).expenseReportLineItem;
export const getExpenseReportsValidation = state =>
    getDataStore(state).expenseReportsValidation;
export const getReceipts = state => getDataStore(state).receipts;
export const getRelocations = state => getDataStore(state).relocations;
export const getTaxMilestones = state => getDataStore(state).taxMilestones;
export const getContacts = state => getDataStore(state).contacts;
export const getPosts = state => getDataStore(state).posts;
export const getDocuments = state => getDataStore(state).documents;
export const getTasks = state => getDataStore(state).tasks;

export * from "./allowances";
export * from "./user";
export * from "./move-initiation";
export * from "./expenseReportLineItem";
