import axios from "axios";
import { config } from "configs";
import { redirectToLogin } from "../api/authGuard";
import { userService, errorService } from "services";
// import {getStore} from 'stores';
import { ERROR_STATUSES } from "../consts";
import history from "../history";
import { store } from "../index";
import { appInsightsTracking } from "../App";
import { getUserProfile } from "../selectors/dataSelectors";

export default class Api {
    static async request(options) {
        let userData = {};
        if (userService.userManager.getUser) {
            userData = await userService.userManager.getUser();
        }
        const { access_token: ACCESS_TOKEN } = userData;
        const { method, path, headers, body, additionalOptions = {} } = options;
        let requestConfig = {
            url: `${config.apiBaseUrl}${path}`,
            method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                Pragma: "no-cache",
                Authorization: ACCESS_TOKEN ? `Bearer ${ACCESS_TOKEN}` : undefined,
                ...headers,
            },
            ...additionalOptions,
        };

        if (body) {
            requestConfig.data = JSON.stringify(body);
        }

        // Fire the Request and Return the Response Promise Object.
        const responsePromise = await axios.request(requestConfig).catch(err => {
            const state = store.getState();
            const user = getUserProfile(state);
            const userId = user && user.email ? user.email : "";
            const message = err && err.response && err.response.data && err.response.data.message;
            let detail = err && err.response && err.response.data && err.response.data.detail;
            detail = typeof detail === "object" ? JSON.stringify(detail) : detail;
            const error = "uiAppHandledError: " + userId + " " + message + " " + detail;

            appInsightsTracking.trackException({ error: new Error(error) });

            if (options && options.additionalOptions && options.additionalOptions.callback) {
                return options.additionalOptions.callback(err);
            }

            if (err && err.response && err.response.status === 404) {
                return history.push("/not-found");
            }

            if (
                (err && err.response && ERROR_STATUSES.includes(err.response.status)) ||
                err.message === "Network Error"
            ) {
                const message =
                    err.response.data && err.response.data.message
                        ? err.response.data.message
                        : "We’re experiencing a problem. Please try again later.";
                store.dispatch(errorService.actions.toggleErrorModal(true, "Sorry. It’s not you. It’s us.", message));
            }
        });

        if (responsePromise && responsePromise.data && responsePromise.config.responseType !== "blob") {
            if (!responsePromise.data.success) {
                const message =
                    responsePromise.data && responsePromise.data.message
                        ? responsePromise.data.message
                        : "We’re experiencing a problem. Please try again later.";
                store.dispatch(errorService.actions.toggleErrorModal(true, "Sorry. It’s not you. It’s us.", message));
            }
        }

        if (responsePromise && responsePromise.status) {
            // Check ::: it can be not json, for example text/html
            //

            if (responsePromise.status === 401) {
                return redirectToLogin();
            }

            if (responsePromise.config.responseType === "blob" && responsePromise.data) {
                //only for downloaded blobs
                //extract file name from 'content-disposition'response header and attach it to result
                const disposition = responsePromise.headers["content-disposition"];
                if (disposition && disposition.indexOf("attachment") !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        responsePromise.data.filename = matches[1].replace(/['"]/g, "");
                    }
                }
            }
            return responsePromise.data;
        }

        return body;
    }

    /* GET (retrieve) */
    static get = options => Api.request({ ...options, method: "GET" });

    /* POST (create) */
    static post = options => Api.request({ ...options, method: "POST" });

    /* PUT (update) */
    static put = options => Api.request({ ...options, method: "PUT" });

    /* DELETE (remove) */
    static delete = options => Api.request({ ...options, method: "DELETE" });
}
