// @flow

import React, { Component } from "react";
import uuidv1 from "uuid/v1";

import "./style.scss";

interface TableProps {
    cols: Record<string, any>[];
    rows: Object[][];
    rowHeight: string | number;
}

export default class Table extends Component<TableProps> {
    state = {
        selected: null,
    };

    generateHeaders = (cols: Record<string, any>[]) => (
        <tr className="headers">
            {cols.map(Col => (
                <th key={Col.title}>{React.isValidElement(Col) ? Col : Col.title}</th>
            ))}
        </tr>
    );

    generateRows = (rows: Record<string, any>[][]) =>
        rows.map(row => {
            return (
                <tr
                    style={{ height: this.props.rowHeight }}
                    key={uuidv1()}
                    data-key={row.id}
                    onClick={() => row.onClick && row.onClick()}
                    className={this.state.selected === row.id ? "selected" : ""}
                >
                    {row.body.map(Column => (
                        <td key={uuidv1()}>{React.isValidElement(Column) ? Column : Column.value}</td>
                    ))}
                </tr>
            );
        });

    generateTableData = (cols, rows) => (
        <table>
            <thead>{this.generateHeaders(cols)}</thead>
            <tbody>{this.generateRows(rows)}</tbody>
        </table>
    );

    render() {
        return (
            <div className={"Table " + this.props.className}>
                {this.generateTableData(this.props.cols, this.props.rows)}
            </div>
        );
    }
}
