import React, { useState, useEffect } from "react";
import { RadioButton, Icon } from "components/common";
import { History } from "history";
import GetPolicy from "../../../Policy";
import { policyEnum } from "../../../../policyMapping";
import Checkbox from '@material-ui/core/Checkbox';

const MBA = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    const { state: locationState } = props.history.location;
    const [state, setState] = useState<{
        [key: string]: string;
    }>({
        category: "",
    });
    const clickHandler = (employeeTypeKey: string, homeownerOrRenter: string, policy: string): void => {
        props.history.push({
            state: {
                ...props.history.location.state,
                homeownerOrRenter,
                policy,
                lumpSumPolicy: "NotSet",
                policyTier: policy,
                eligibleTitle: policy ? policyEnum[policy].displayText : "NotSet",
                employeeTypeKey,
                employeeTypeTitle: homeownerOrRenter
            },
        });
    };

    useEffect(() => {
        if (state["category"]) {
            props.disableContinueBtn(false);
        } else {
            props.disableContinueBtn(true);
        }
    }, [state["category"]]);

    useEffect(() => {
        return () => {
            props.disableContinueBtn(true);
        };
    }, []);

    const [isChecked, setIsChecked] = useState(false);

    const checkedHandler = () => {
        //setIsChecked(!isChecked);
        if(isChecked) {
            setIsChecked(false);
            props.history.push({
                state: {
                    ...locationState,
                    "lumpSumPolicy": "No",
                    policy: locationState.policyTier
                },
            });
        } else {
            setIsChecked(true);
            props.history.push({
                state: {
                    ...locationState,
                    "lumpSumPolicy": "Yes",
                    policy: policyEnum.LumpSum.key
                },
            });
        }
        
    }
    useEffect(() => { 
        if(isChecked && ["No", "NotSet"].includes(locationState["lumpSumPolicy"]))
            setIsChecked(false);
    }, [locationState["lumpSumPolicy"] ]);
    
    useEffect(() => {   //  back handler
        props.history.push({
            state: {
                ...locationState,
                policy: "NotSet",
                rotationTypeAKey: "NotSet",
                rotationTypeATitle: "NotSet",
            }
        })
    }, [locationState["policyType"], locationState["empProcurementType"]]);

    return (
        <>
            <div className="MBA-category">
                <div className="top">
                    <div className="title">
                        Is the employee a homeowner or renter?
                    </div>
                </div>
                <div className="options horizontal-options">
                    <div className="option">
                        <RadioButton
                            checked={state["category"] === "homeowner"}
                            onChange={e => { 
                                setState({
                                    ...state,
                                    category: "homeowner",
                                })
                                clickHandler("homeowner","Homeowner","USDomesticHomeownerTier2")
                            }}
                        />
                        <div className="titles">
                            <p className="title">Homeowner</p>
                        </div>
                    </div>
                    <div className="option">
                        <RadioButton
                            checked={state["category"] === "renter"}
                            onChange={e => { 
                                setState({
                                    ...state,
                                    category: "renter",
                                })
                                clickHandler("renter","Renter","USDomesticRenterTier2")
                            }}
                        />
                        <div className="titles">
                            <p className="title">Renter</p>
                        </div>
                    </div>
                </div>
            </div>
            {state["category"] && (
                <div className="download-policy-section">
                    <p className="title">What's included?</p>
                    {state["category"] === "homeowner" && (<>
                        <div className="horizontal-section download">
                            <Icon name="linkIcon" className="linkIconCls" />
                            <p><a target="_blank" href={process.env.REACT_APP_EXT_LINK_US_DOMESTIC_RESOURCES} >
                                Policy for {policyEnum.USDomesticHomeownerTier2.displayText}
                                </a>
                            </p>
                        </div>
                        <p className="lum-sum-policy-header-title" >{policyEnum.LumpSum.title}</p>
                        <p className="lum-sum-policy-checkbox-area" ><Checkbox checked={isChecked} onChange={() => {checkedHandler()}} /> &nbsp;Yes</p>
                        <br />
                        </>
                    )}
                    {state["category"] === "renter" && (<>
                        <div className="horizontal-section download" >
                            <Icon name="linkIcon" className="linkIconCls" />
                            <p><a target="_blank" href={process.env.REACT_APP_EXT_LINK_US_DOMESTIC_RESOURCES} >
                                Policy for {policyEnum.USDomesticRenterTier2.displayText}
                                </a>
                            </p>
                        </div>
                        <p className="lum-sum-policy-header-title" >{policyEnum.LumpSum.title}</p>
                        <p className="lum-sum-policy-checkbox-area" ><Checkbox checked={isChecked} onChange={() => {checkedHandler()}} /> &nbsp;Yes</p>
                        <br />
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default MBA;
