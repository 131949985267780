import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";

import "./style.scss";

class SelectionCard extends PureComponent {
    renderIcon = icon => {
        return (
            <div className="icon-block">
                <img src={icon} alt="" />
            </div>
        );
    };
    navigateTo = (e, route) => {
        e.preventDefault();
        if(route){
            this.props.history.push({
                pathname: route,
                state: {
                    fromConsult: this.props.fromConsult,
                },
            });
        }
    };

    regularSelectionCard = (backgroundColor, title, content, route, className, onClick) => {
        if(route){
            return (
                <div
                    className={`regularSelectionCard ${className}`}
                    style={{ backgroundColor: backgroundColor }}
                    onClick={e => {this.navigateTo(e, route) }}
                >
                    <section className="selection-card-block">
                        <h2>
                            {typeof title !== "string"
                                ? title
                                : title.split("/n").map(text => (
                                      <React.Fragment key={text}>
                                          {text}
                                          <br />
                                      </React.Fragment>
                                  ))}
                        </h2>
    
                        <p>{content}</p>
                    </section>
                </div>
            );
        } else {
            return (
                <div
                    className={`regularSelectionCard ${className}`}
                    style={{ backgroundColor: backgroundColor }}
                    onClick={e => {this.props.onClick(e)}}
                >
                    <section className="selection-card-block">
                        <h2>
                            {typeof title !== "string"
                                ? title
                                : title.split("/n").map(text => (
                                      <React.Fragment key={text}>
                                          {text}
                                          <br />
                                      </React.Fragment>
                                  ))}
                        </h2>
    
                        <p>{content}</p>
                    </section>
                </div>
            );
        }
    };
    imageSelectionCard = (backgroundColor, title, content, icon, route, className) => {
        if(route){
            return (
                <div
                    className={`imageSelectionCard ${className}`}
                    style={{ backgroundColor: backgroundColor }}
                    onClick={e => {this.navigateTo(e, route) }}
                >
                    {this.renderIcon(icon)}
                    <section className="selection-card-block">
                        <h2>
                            {typeof title !== "string"
                                ? title
                                : title.split("/n").map(text => (
                                      <React.Fragment key={text}>
                                          {text}
                                          <br />
                                      </React.Fragment>
                                  ))}
                        </h2>
    
                        <p>{content}</p>
                    </section>
                </div>
            );
        } else {
            return (
                <div
                    className={`imageSelectionCard ${className}`}
                    style={{ backgroundColor: backgroundColor }}
                    onClick={e => {this.props.onClick(e)}}
                >
                    {this.renderIcon(icon)}
                    <section className="selection-card-block">
                        <h2>
                            {typeof title !== "string"
                                ? title
                                : title.split("/n").map(text => (
                                      <React.Fragment key={text}>
                                          {text}
                                          <br />
                                      </React.Fragment>
                                  ))}
                        </h2>
    
                        <p>{content}</p>
                    </section>
                </div>
            );
        }
    };
    optionSelectionCard = (backgroundColor, title, content, icon, route, value, step) => {
        return (
            <div
                className={`optionSelectionCard`}
                style={{ backgroundColor: backgroundColor }}
                onClick={e => {
                    this.props.onClick(e, route, value, step);
                }}
            >
                {this.renderIcon(icon)}

                <section className="selection-card-block">
                    <h2>
                        {typeof title !== "string"
                            ? title
                            : title.split("/n").map(text => (
                                  <React.Fragment key={text}>
                                      {text}
                                      <br />
                                  </React.Fragment>
                              ))}
                    </h2>
                    <p>{content}</p>
                </section>
            </div>
        );
    };
    countrySelectionCard = (backgroundColor,title,icon, route ) => {
        if(route){
            return (
                <div
                    className={`countrySelectionCard`}
                    style={{ backgroundColor: backgroundColor }}
                    onClick={e => {this.navigateTo(e, route) }}
                >
                    <section className="imageContainer">
                        {this.renderIcon(icon)} 
                    </section>
                    <p>
                            {typeof title !== "string"
                                ? title
                                : title.split("/n").map(text => (
                                      <React.Fragment key={text}>
                                          {text}
                                          <br />
                                      </React.Fragment>
                                  ))}
                        </p>
                </div>
            )
        } else {
            return(
                <div
                    className={`countrySelectionCard`}
                    style={{ backgroundColor: backgroundColor }}
                    onClick={e => {this.props.onClick(e)}}
                >
                    <section className="imageContainer">
                        {this.renderIcon(icon)} 
                    </section>
                    <p>
                            {typeof title !== "string"
                                ? title
                                : title.split("/n").map(text => (
                                    <React.Fragment key={text}>
                                        {text}
                                        <br />
                                    </React.Fragment>
                                ))}
                    </p>
                </div>
            )
        }
    };
    render() {
        const { type, className = "", backgroundColor, title, content, icon, route, value, step, onClick } = this.props;

        const stepMap = {
            imageSelectionCard: this.imageSelectionCard(backgroundColor, title, content, icon, route, className),
            regularSelectionCard: this.regularSelectionCard(backgroundColor, title, content, route, className, onClick),
            optionSelectionCard: this.optionSelectionCard(backgroundColor, title, content, icon, route, value, step),
            countrySelectionCard: this.countrySelectionCard(backgroundColor, title, icon, route),
        };

        return stepMap[type] || this.regularSelectionCard(backgroundColor, title, content, route, className);
    }
}

export default withRouter(SelectionCard);
