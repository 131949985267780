import React, { useEffect } from "react";
import { Asset, Icon } from "components/common";
import { History } from "history";

const SelfInitiated = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    useEffect(() => {
        props.history.push({
            state: {
                ...props.history.location.state,
                paygrade: null,
                policyTier: "OneWayMoveTier3",
                policy: "OneWayMoveTier3"
            },
        });
        props.disableContinueBtn(false);
        return () => {
            props.disableContinueBtn(true);
        };
    }, []);

    return (
        <div className="download-policy-section">
            <p className="title">What's included?</p>
            <div className="horizontal-section download">
                <Icon name="linkIcon" />
                <p><a target="_blank" href={process.env.REACT_APP_EXT_LINK_INTERNATIONAL_RESOURCES} >Policy for J&J One-Way Employee - Tier III</a></p>
            </div>
        </div>
    );
};

export default SelfInitiated;
