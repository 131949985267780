// @flow

import React, { PureComponent } from 'react';

import StepComponent from './StepComponent';
import './styles.scss';

export type StepObj = {
    label: string,
    key: string,
}

type ActiveStepInfo = {
    kay: string,
    index: number,
};

type StepperProps = {
    steps: Array<StepObj>,
    activeStepKey?: ?string,
    onStepChange?: Function,
};

type StepperState = {
    activeStepInfo: ActiveStepInfo,
};

export default class Stepper extends PureComponent<StepperProps, StepperState> {

    constructor(props: StepperProps) {
        super(props);

        this.state = {
            activeStepInfo: {}
        };
    }

    componentWillMount() {
        const { activeStepKey, steps } = this.props;
        let activeStepInfo = {};
        if(steps.length) {
            if(activeStepKey) {
                activeStepInfo = this.findStepByKey(activeStepKey);
            } else {
                activeStepInfo = this.findStepByKey(steps[0].key);
            }
        }
        this.setState({ activeStepInfo });
    }

    componentWillReceiveProps(nextProps: StepperProps) {
        const { activeStepKey } = this.props;
        if(activeStepKey && nextProps.steps.length && activeStepKey !== this.state.activeStepInfo.key) {
            this.setState({ activeStepInfo: this.findStepByKey(activeStepKey) })
        }
    }

    findStepByKey = (key: string): StepObj => this.props.steps.find(step => step.key === key) || {};

    handleStepClick = (stepInfo: ActiveStepInfo): void => {
        this.setState({
            activeStepInfo: stepInfo,
        });

        this.props.onStepChange && this.props.onStepChange(stepInfo);
    };

    generateSteps = (data: Array<StepObj>): Array<React.Node> => data && data.length ? (
        data.map((step, index) => (
            <StepComponent
                isActive={this.state && this.state.activeStepInfo && this.state.activeStepInfo.key === step.key}
                onClick={this.handleStepClick}
                label={step.label}
                key={step.key}
                stepKey={step.key}
                index={index}
                isLastStep={!data[index+1]}
            />
        ))
    ) : null;

    render() {
        return (
            <div className="Stepper">
                <div className="flexible horizontal jBetween">
                    { this.generateSteps(this.props.steps) }
                </div>
            </div>
        );
    }
}

// This should be removed later
Stepper.defaultProps = {
    steps: [],
};
