import React from "react";
import { MoveStepLayout } from "components/moveInitiationLayouts";
import { withRouter, RouteComponentProps } from "react-router";
import { RouterProps } from "types";
import AddMoveDetails from "./AddMoveDetails";

import "./style.scss";

 const AdditionalMoveDetails = (props: RouteComponentProps<any> & RouterProps) => (
           <div className="RecruitedSelectorContainer">
              <MoveStepLayout selected={3} />
              <AddMoveDetails history={props.history} location={props.location}/>
           </div>
   );

export default withRouter(AdditionalMoveDetails);
