import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'components/common';
import { onHistoryLinkClicked } from '../../../utils';

import './style.scss';

class InfoCard extends PureComponent {
    isMultiLinks() {
        return this.props.underLink2 && this.props.externalLink2;
    }

    render() {
        const { className = '', title } = this.props;
        return (
            <div
                className={`InfoCard ${className}`}
                style={{ backgroundColor: this.props.backgroundColor }}
                onClick={(e) => this.isMultiLinks() ? null : onHistoryLinkClicked({
                    action: this.props.action ? this.props.action : 'click-info-card',
                    category: this.props.category ? this.props.category : 'other',
                    label: this.props.label ? this.props.label : (this.props.externalLink ? this.props.externalLink : ''),
                    onClick: (e) => this.props.externalLink ? window.open(this.props.externalLink) : this.props.onClick(e)
                }, e)}
                data-key={this.props.dataKey}
            >
                <div className="info-card-block flexible vertical">
                    <h2>
                        {typeof title !== 'string' ? title : title.split('/n').map(text => (
                                <React.Fragment key={text}>
                                    {text}
                                    <br/>
                                </React.Fragment>
                            )
                        )
                        }
                    </h2>

                    <p className="truncate threeLines">
                        {this.props.content}
                    </p>

                    {this.props.underLink &&
                    <Link
                        text={this.props.underLink}
                        externalLink={this.isMultiLinks() ? this.props.externalLink : null}
                    />}

                    {this.isMultiLinks() &&
                    <Link
                        text={this.props.underLink2}
                        externalLink={this.props.externalLink2}
                    />}
                </div>
            </div>
        );
    }
}

export default withRouter(InfoCard);
