import React, { useState, useEffect } from "react";
import { RouterProps } from "types";
import { SpinnerWrapper} from "components/common";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { moveInitiationService, userService } from "services";
import { getUserProfile } from 'selectors';
import { USER_ROLES } from "consts";

import "./../style.scss";

  const mapDispatchToProps = {
    fetchEmpCompetitiveInit: moveInitiationService.actions.fetchEmpCompetitiveInit,
    setRole: userService.actions.setRole
  };

  const mapStateToProps = (store) => ({
    user: getUserProfile(store)
   });

  const CompetitiveInitiation = (props: RouterProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    //const [empCompetitiveInit, setEmpCompetitiveInit] = useState(null);
    
    useEffect(() => {
        let requisitionContestNumber = "";
        let requisitionHmgrEmail = "";
        let candidateNumber = "";
        updateUserRole();
        if(props.match.params !== undefined) {
            requisitionContestNumber = (props.match.params.requisitionContestNumber !== undefined && props.match.params.requisitionContestNumber !== null ) ? props.match.params.requisitionContestNumber: ""; 
            candidateNumber = (props.match.params.candidateNumber !== undefined && props.match.params.candidateNumber !== null ) ? props.match.params.candidateNumber: ""; 
            requisitionHmgrEmail = (props.match.params.requisitionHmgrEmail !== undefined && props.match.params.requisitionHmgrEmail !== null ) ? props.match.params.requisitionHmgrEmail: "";             
        } 
        if(requisitionContestNumber !== "" && candidateNumber !== "" && requisitionHmgrEmail !== "") {
                setIsLoading(true);
                dispatch(moveInitiationService.actions.fetchEmpCompetitiveInit(requisitionContestNumber,candidateNumber))
                .then(data => {
                    setIsLoading(false);
                    if(data.success) {
                        //setEmpCompetitiveInit(data.result)
                        let empCompetitiveInit = data.result;
                        return navigateTo("move-details",requisitionContestNumber,candidateNumber,empCompetitiveInit);
                    } else {
                        return navigateTo("dashboard",null,null,null);
                    }
                })
                .catch(err => console.log(err));
        } else {
                return navigateTo("dashboard",null,null,null);
        }
    }, []);

    const updateUserRole = () => {
        const role = props.user.role === 'HrManager' ? USER_ROLES.hiring_manager : USER_ROLES.employee;
        const email = props.user.email;
        if( role === "Assignee" && email !== "" && email != undefined ) {
            let swapRole = USER_ROLES.hiring_manager;
            props.setRole({
                email,
                role: swapRole
            }).then((re) => {
               return;
            });
        }
        return;
    }

    const navigateTo = (location: string, requisitionContestNumber: string, candidateNumber: string, empCompetitiveInit: any) => {
        switch (location) {
            case 'move-details':
                if (empCompetitiveInit !== null) {
                    props.history.push(
                        "/move-details",{"requisitionContestNumber":requisitionContestNumber,"candidateNumber": candidateNumber, "empCompetitiveInit":empCompetitiveInit}
                    );
                }
                break;
            case 'dashboard':
                props.history.push("/move-dashboard");
                break;
        }
    }    

    return (
        <div className="Profil flexible jCenter vertical ">
            <div className="hr-main-content">
                <div className="competitive-entry-wraper">
                    <SpinnerWrapper show={isLoading}>
                        <section className="header-section">
                        Loading......
                        </section>
                    </SpinnerWrapper>
                </div>
            </div>
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetitiveInitiation);
