import globus from 'components/common/Images/world.svg';
import React, { Component } from 'react'
import { MidSectionComponent, Link, TaskListComponent,
  QuestionsListCard, SectionHeading } from 'components/common';

export default class DomExploreStep extends Component {

  render() {
    return (
      <div>
        <MidSectionComponent title = "How do I get U.S. Domestic experience?" col1={
            <div className="flexible vertical pad-right">
            <p>Some programs are by nomination only through our J&J Talent Development programs while others are through self initiation. For individuals interested in cross-company, cross-functional or regional experiences, it is important to express these interests to your manager.</p>
            <p>Additionally, be sure to update your talent profile frequently in Workday, including cities / companies / functions / regions of interest.</p>
            <Link text="Workday" externalLink='https://wd5.myworkday.com/jj/d/home.htmld'/>
            </div>
            }

            col2={
            <div className="flexible vertical pad-left">
            <p>The Center for Leadership & Learning develops programs to enhance the leadership skills and capabilities of employees across the Johnson & Johnson companies.  The goal is to provide a wide range of consistent development opportunities and partner with employees in fostering their career growth.</p>
            <p>Learn more about J&J Leadership and Development program offerings:</p>
            <Link text="Global HR Portal" externalLink='https://jnj.sharepoint.com/sites/leadershipandlearning/Pages/default.aspx'/>
            </div>
            }
        />

        <div className="question-part steps-columns-section">
          <div className="question-block flexible">
            <div className="flexible vertical">
              <SectionHeading
                eyebrow="More Information"
                title="Types of U.S. Domestic Relocations"
                className="section-heading mbottom-40"
              />
              <QuestionsListCard
                  key={1}
                  icon={globus}
                  body={(<div>
                      <p>
                        Johnson & Johnson, like most multi-nationals, supports different types of U.S. Domestic relocations. These can and do vary depending on business objectives and talent strategies. U.S. Domestic relocations are generally considered permanent, one way moves. U.S. domestic business moves less than 12 months in length are considered business travel and may be managed directly by the business.  Should the business want support for talent moves of 12 months or less, please continue to follow the process steps described herein.
                      </p>
                      <h3>Most common types of relocation packages:</h3>
                      <ul>
                        <li>{'Professional: Pay Grade ' + String.fromCharCode(60) + ' 30'}</li>
                        <li>Manager: Pay Grade 30-31</li>
                        <li>Director & Above: Pay Grade 40+</li>
                        <li>By Nomination Leadership Development Programs (varies by function)</li>
                      </ul>
                    </div>)}
                  listData={["", "Manager: Pay Grade 30-31", "Director & Above: Pay Grade 40+", "By Nomination Leadership Development Programs (varies by function)"]}
                  urlText={["View Domestic Relocation Packages"]}
                  urlActions={[()=>window.location.replace('/resources/dom/EmployeeDomesticPolicies')]}
              />
            </div>
            <div className="flexible vertical">
              <TaskListComponent taskList={this.props.getTasks()}
                  title={this.props.getTasksTitle()}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
