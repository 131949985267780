import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

// Store imports
import { expenseReportLineItemService, expenseReportItem, expenseReportCategoriesService } from "services";

import {
    getExpenseReportLineItem,
    getUserProfile,
    getExpenseReportCategories,
    getExpenseReportLineItemById,
} from "selectors";

import { ExpenseReportAPI } from "api";

// components
import ValidatableForm, {
    FormsyText,
    FormsySelect,
    FormsyTextArea,
    FormsyDatePicker,
} from "components/sections/ValidatableForm";
import { Button, SpinnerWrapper } from "components/common";
import CurrencyConversion from "./CurrencyConversion";

import { browserDetecter } from "utils/IESupport";

import "./style.scss";

const OPTIONAL_CONTENT = {
    add: {
        title: "Add an Expense",
        button: "Add Expense",
    },
    edit: {
        title: "Edit an Expense",
        button: "Save Changes",
    },
};
export const EXCHANGE_METHODS = {
    InterBank: 1,
    Override: 2,
};
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
const DATE_FORMAT = "YYYY-MM-DDT00:00:00";
export const FORMAT_TO_SHOW = "M/D/YYYY";

class CreateEditLineItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                startPeriod: moment(),
                endPeriod: moment()
                    .add(1, "day")
                    .endOf("day"), //Tomorrow
                exchangeMethod: "InterBank",
            },
            exchangeRateInfo: {
                needToFetch: true,
                InterBank: {
                    exchangeRate: 1,
                },
            },
            errorMessage: "",
            categories: {
                needToFetch: true,
                payload: [],
            },
            subCategories: [],
            isFormBusy: false,
            isConversionBusy: true,
        };
    }

    componentWillMount() {
        const { actionType } = this.props.match.params;

        this.fetchExpReportIfNecessary(this.props);
        actionType === "add" && this.fetchExchangeRateIfNecessary(this.props); // otherwise will be fetch after line item be fetched
        this.fetchCategoriesIfNecessary(this.props);
        this.setLineItemIfNecessary(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const { actionType } = nextProps.match.params;

        this.fetchExpReportIfNecessary(nextProps);
        actionType === "add" && this.fetchExchangeRateIfNecessary(nextProps);
        this.fetchCategoriesIfNecessary(nextProps);
        this.setLineItemIfNecessary(nextProps);
        this.setFormBusyState(nextProps);
    }

    setFormBusyState = props => {
        this.setState({
            isFormBusy: this.defineIsFormBusy(props),
        });
    };

    setLineItemIfNecessary = props => {
        const {
            expReportLineItem,
            match: { params },
        } = props;

        if (
            (params.actionType === "edit" &&
                this.props.expReportLineItem.lineItemId !== expReportLineItem.lineItemId) ||
            (!this.state.formData.lineItemId && expReportLineItem.lineItemId)
        ) {
            const exchangeRateInfo =
                expReportLineItem.exchangeMethod === "Override"
                    ? {
                          ...this.state.exchangeRateInfo,
                          Override: {
                              exchangeRate: expReportLineItem.exchangeRate,
                              exchangeDate: expReportLineItem.exchangeDate,
                          },
                      }
                    : {};
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        ...expReportLineItem,
                    },
                    exchangeRateInfo,
                },
                () => {
                    this.handleCategoryChange({
                        target: { value: expReportLineItem.category },
                    });
                    this.handleStartDateBlur({
                        target: { value: expReportLineItem.startPeriod },
                    });
                },
            );
        }
    };

    fetchExpReportIfNecessary = props => {
        const {
            match: { params },
            expReportItem: { reportId: reportIdStore, isLoading },
            userProfile,
        } = props;
        let { expRepId: reportIdParams } = params;

        if (
            (!reportIdStore || Number(reportIdStore) !== Number(reportIdParams)) &&
            !isLoading &&
            userProfile.currentRelocationId
        ) {
            props.fetchExpReportItem(reportIdParams, userProfile.currentRelocationId).catch(e => {
                if (e.response.status === 404) this.navigateToExpenseReport();
            });
        }
    };

    fetchExchangeRateIfNecessary = (props = this.props) => {
        const {
            formData: { startPeriod },
            exchangeRateInfo: { needToFetch },
        } = this.state;
        if (!needToFetch) {
            return;
        }

        const {
            userProfile: { currentRelocationId },
            expReportItem: { incurredCurrency, reimbursementCurrency },
        } = props;

        if (currentRelocationId && incurredCurrency && reimbursementCurrency) {
            this.fetchExchangeRateInfo(
                currentRelocationId,
                moment(startPeriod).format(DATE_FORMAT),
                incurredCurrency,
                reimbursementCurrency,
            );
        }
    };

    fetchExchangeRateInfo = (currentRelocationId, date, incurredCurrency, reimbursementCurrency) => {
        this.setState({
            exchangeRateInfo: {
                ...this.state.exchangeRateInfo,
                needToFetch: false,
            },
            isConversionBusy: true,
        });
        ExpenseReportAPI.getExchangeRateInfo(currentRelocationId, date, incurredCurrency, reimbursementCurrency)
            .then(resp => {
                this.setState(state => ({
                    exchangeRateInfo: {
                        ...state.exchangeRateInfo,
                        needToFetch: false,
                        InterBank: {
                            exchangeDate: resp.result.exchangeDate,
                            exchangeRate: resp.result.exchangeRate,
                        },
                    },
                    isConversionBusy: false,
                }));
            })
            .catch(e => {
                this.setState({
                    errorMessage: (e.response.data || {}).message || "",
                    isConversionBusy: false,
                });
            });
    };

    fetchCategoriesIfNecessary = props => {
        const {
            userProfile: { currentRelocationId },
        } = props;

        const {
            categories: { needToFetch },
        } = this.state;

        if (needToFetch && currentRelocationId) {
            this.setState({
                categories: {
                    ...this.state.categories,
                    needToFetch: false,
                },
            });
            props.fetchCategories(currentRelocationId).then(resp => {
                if (Array.isArray(resp.result)) {
                    this.setState({
                        categories: {
                            needToFetch: false,
                            payload: this.generateCategoryOptions(resp.result),
                        },
                    });
                }
            });
        }
    };

    defineIsFormBusy = props => {
        const { actionType } = this.props.match.params;
        let isBusy = false;
        if (actionType === "edit" && props.expReportItem.isLoading) {
            isBusy = true;
        }
        if (this.state.categories.needToFetch) {
            isBusy = true;
        }
        return isBusy;
    };

    generateCategoryOptions = categories =>
        categories.map(category => ({
            label: category.description,
            value: category.code,
        }));

    handleInputChange = (propName, handler) => ({ target, ...eventRest }, ...argsRest) => {
        let value = target ? target.value : Object.values(eventRest).join("");
        const state = { ...this.state };
        state.formData = {
            ...state.formData,
            [propName]: value,
        };

        if (propName === "category") {
            delete state.formData.subCategory;
        }

        this.setState(state, () => {
            if (handler) {
                handler({ target, ...eventRest }, ...argsRest);
            }
        });
    };

    handleCategoryChange = e => {
        const categoryCode = e.target.value;
        ExpenseReportAPI.getExpenseReportSubCategories(categoryCode, this.props.userProfile.currentRelocationId).then(
            resp => {
                if (resp.result) {
                    this.setState({
                        subCategories: this.generateCategoryOptions(resp.result),
                    });
                }
            },
        );
    };

    handleStartDateBlur = ({ target, ...eventRest }) => {
        const date = target ? target.value : Object.values(eventRest).join("");
        if (!date || !moment(date).isValid() || moment(date).year() < 1980) {
            return;
        }

        this.setState(
            {
                exchangeRateInfo: {
                    ...this.state.exchangeRateInfo,
                    needToFetch: true,
                },
            },
            () => {
                this.fetchExchangeRateIfNecessary();
            },
        );
    };

    navigateToExpenseReport = _ => {
        const {
            params: { action, expRepId },
        } = this.props.match;
        this.props.history.push(`/expense-report/${action}/${expRepId}`);
    };

    handleOverride = exchangeInfo => {
        this.setState({
            exchangeRateInfo: {
                ...this.state.exchangeRateInfo,
                Override: exchangeInfo,
            },
        });
    };

    saveLineItem = formData => {
        this.setState({
            isFormBusy: true,
        });

        const {
            userProfile: { currentRelocationId },
            expReportItem: { reportId, incurredCurrency, reimbursementCurrency },
        } = this.props;
        const {
            formData: formDataState,
            exchangeRateInfo: { InterBank = {}, Override = {} },
        } = this.state;

        const optionalFormData =
            formDataState.exchangeMethod === "InterBank"
                ? {
                      amount: InterBank.exchangeRate
                          ? (InterBank.exchangeRate * formData.incurredAmount).toFixed(2)
                          : 0,
                      exchangeRate: InterBank.exchangeRate,
                      exchangeDate: moment(InterBank.exchangeDate).format(DATE_FORMAT),
                  }
                : {
                      amount: Override.exchangeRate ? (Override.exchangeRate * formData.incurredAmount).toFixed(2) : 0,
                      exchangeRate: Override.exchangeRate,
                      exchangeDate: moment(Override.exchangeDate).format(DATE_FORMAT),
                  };

        this.props
            .saveExpenseReportLineItem(
                {
                    reportId,
                    relocationId: currentRelocationId,
                },
                {
                    incurredCurrency,
                    reimbursementCurrency,
                    ...formDataState,
                    startPeriod: moment(formDataState.startPeriod).format(DATE_FORMAT),
                    endPeriod: moment(formDataState.endPeriod).format(DATE_FORMAT),
                    ...optionalFormData,
                },
            )
            .then(() => {
                this.navigateToExpenseReport();
            })
            .catch(e => {
                this.setState({
                    errorMessage: (e.response.data || {}).message || "Something went wrong. Please try again",
                    isFormBusy: false,
                });
            });
    };

    renderActionButtons = () => {
        const { actionType } = this.props.match.params;

        return (
            <div className="additional-form-buttons flexible grow horizontal">
                <React.Fragment>
                    <Button
                        dataKey="expense_cancel"
                        className="cancel-button"
                        title="Cancel"
                        simple
                        onClick={this.navigateToExpenseReport}
                    />

                    <button className="Button submit" data-key="expense_save">
                        <span>{OPTIONAL_CONTENT[actionType].button}</span>
                    </button>
                </React.Fragment>
            </div>
        );
    };

    render() {
        const {
            match: { params },
            userProfile: { currentRelocationIsDomestic },
        } = this.props;
        const { actionType } = params;
        const { formData } = this.state;

        // console.log(formData);

        return (
            <div className="create-edit-root">
                <div className="crete-edit-header">
                    <h2>{OPTIONAL_CONTENT[actionType].title}</h2>
                </div>
                <SpinnerWrapper show={this.state.isFormBusy}>
                    <ValidatableForm
                        onSubmit={this.saveLineItem}
                        className="animated fadeInDown line-item--form"
                        additionalFooterContent={this.renderActionButtons()}
                    >
                        <div className="main-form-body">
                            <div className="form-section flexible vertical">
                                <span className="form-section-subtitle">Describe this expense:</span>
                                <div className="description-inputs">
                                    <div className="input-container" style={{ flexDirection: "column" }}>
                                        <FormsyText
                                            _required
                                            onChange={this.handleInputChange("name")}
                                            value={formData.name}
                                            name="name"
                                            label="Name"
                                            validations="_required,max50"
                                            validationErrors={{
                                                _required: "Please fill",
                                            }}
                                            dataKey="expense_name"
                                        />
                                        {this.state.formData.name && this.state.formData.name.length > 50 && (
                                            <p className="formElement_errorText">
                                                This field should be no more than 50 characters
                                            </p>
                                        )}
                                    </div>
                                    <div className="flexible horizontal wrap jBetween">
                                        <div className="input-container">
                                            <FormsySelect
                                                _required
                                                validationErrors={{
                                                    _required: "Please fill",
                                                }}
                                                validations="_required"
                                                label="Category"
                                                name="category"
                                                onChange={this.handleInputChange("category", this.handleCategoryChange)}
                                                value={formData.category}
                                                options={this.state.categories.payload}
                                                dataKey="expense_category"
                                            />
                                        </div>
                                        <div className="input-container">
                                            <FormsySelect
                                                _required
                                                validationErrors={{
                                                    _required: "Please fill",
                                                }}
                                                validations="_required"
                                                label="Subcategory"
                                                name="subCategory"
                                                onChange={this.handleInputChange("subCategory")}
                                                value={formData.subCategory}
                                                options={this.state.subCategories}
                                                disabled={!this.state.subCategories.length}
                                                dataKey="expense_subcategory"
                                            />
                                        </div>
                                    </div>

                                    <FormsyTextArea
                                        name="description"
                                        onChange={this.handleInputChange("description")}
                                        label="Expense Description"
                                        rowsCount={2}
                                        value={formData.description}
                                        validations="max255"
                                        dataKey="expense_description"
                                    />
                                    {this.state.formData.description &&
                                        this.state.formData.description.length > 255 && (
                                            <p className="formElement_errorText">
                                                This field should be no more than 255 characters
                                            </p>
                                        )}
                                </div>
                            </div>
                            <div className="form-section flexible vertical">
                                <span className="form-section-subtitle">When did you incur this expense?</span>
                                <div className="input-container flexible wrap jBetween">
                                    <div className="date-input-container">
                                        <FormsyDatePicker
                                            _required
                                            defaultValue={formData.startPeriod}
                                            format={DATE_TIME_FORMAT}
                                            formatToShow={FORMAT_TO_SHOW}
                                            validations={{
                                                isDate: DATE_TIME_FORMAT,
                                            }}
                                            onChange={this.handleInputChange("startPeriod", this.handleStartDateBlur)}
                                            name="startPeriod"
                                            label="Start Date"
                                            validationErrors={{
                                                isDate: "Please select correct date",
                                            }}
                                            dataKey="expense_start_period"
                                            nativeProps={{
                                                maxDate: formData.endPeriod,
                                                maxDateMessage: "Start Date can't be greater than End Date",
                                            }}
                                        />
                                    </div>
                                    <div className="date-input-container">
                                        <FormsyDatePicker
                                            _required
                                            format={DATE_TIME_FORMAT}
                                            formatToShow={FORMAT_TO_SHOW}
                                            onChange={this.handleInputChange("endPeriod")}
                                            defaultValue={formData.endPeriod}
                                            validations={{
                                                isDate: DATE_TIME_FORMAT,
                                            }}
                                            name="endPeriod"
                                            label="End Date"
                                            validationErrors={{
                                                isDate: "Please select correct date",
                                            }}
                                            dataKey="expense_end_period"
                                            nativeProps={{
                                                minDate: formData.startPeriod,
                                                minDateMessage: "End Date can't be smaller than Start Date",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-section flexible vertical">
                                <span className="form-section-subtitle">How much was the expense?</span>
                                <div className="flexible horizontal jBetween">
                                    <div className="input-container">
                                        <FormsyText
                                            _required
                                            type={browserDetecter() === "firefox" ? "text" : "number"}
                                            name="incurredAmount"
                                            label="Incurred Amount"
                                            validations="_required"
                                            onChange={this.handleInputChange("incurredAmount", this.doConvertion)}
                                            validationErrors={{
                                                _required: "Please fill",
                                            }}
                                            value={formData.incurredAmount || undefined}
                                            dataKey="expense_incurred_amount"
                                        />
                                    </div>
                                    <div className="input-container">
                                        <p className="text-with-label">
                                            <span className="label">Incurred Currency:</span>
                                            <span className="text">{this.props.expReportItem.incurredCurrency}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!currentRelocationIsDomestic && (
                            <div className="form-section flexible vertical">
                                <span className="form-section-subtitle">
                                    Which converstion rate would you like to use?
                                </span>
                                <SpinnerWrapper show={this.state.isConversionBusy}>
                                    <CurrencyConversion
                                        exchangeRateInfo={this.state.exchangeRateInfo}
                                        type={EXCHANGE_METHODS[formData.exchangeMethod]}
                                        onChange={this.handleInputChange("exchangeMethod")}
                                        incurredAmount={formData.incurredAmount}
                                        reimbursementCurrency={formData.reimbursementCurrency}
                                        amount={formData.amount}
                                        handleOverride={this.handleOverride}
                                    />
                                </SpinnerWrapper>
                            </div>
                        )}
                        <p className="formElement_errorText error-message form-level--error">
                            {this.state.errorMessage}
                        </p>
                    </ValidatableForm>
                </SpinnerWrapper>
            </div>
        );
    }
}

const mapSateToProps = (state, ownProps) => ({
    expReportItem: getExpenseReportLineItem(state),
    userProfile: getUserProfile(state),
    categories: getExpenseReportCategories(state),
    expReportLineItem: getExpenseReportLineItemById(state, ownProps.match.params.lineItemId),
});

const mapDispatchToProps = {
    fetchExpReportItem: expenseReportLineItemService.actions.getExpenseReportLineItem,
    fetchCategories: expenseReportCategoriesService.actions.fetchExpenseReportCategories,
    saveExpenseReportLineItem: expenseReportItem.actions.createExpenseReportItem,
};

export default connect(
    mapSateToProps,
    mapDispatchToProps,
)(withRouter(CreateEditLineItem));
