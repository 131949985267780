const createDataReducer = (
    type,
    defaultState = null,
    uniqueKey,
    notShowError,
    otherHandlers = state => state,
) => (state = defaultState, action) => {
    const { payload, options } = action;

    switch (action.type) {
        //@TODO refactor
        case `@@${type}`:
            return { ...state, isLoading: false, ...payload };
        case `@@${type}_STARTED`:
            return { ...state, isLoading: true };

        case `@@${type}_UPDATE_STARTED`:
            return { ...state, isLoading: true };
        case `@@${type}_UPDATE`:
            return uniqueKey
                ? state.map(item =>
                      item[uniqueKey] === payload[uniqueKey]
                          ? { ...item, ...payload }
                          : item,
                  )
                : payload;
        case type:
            return payload;
        case `${type}_CREATE`:
            return Array.isArray(payload)
                ? [...payload, ...state]
                : [payload, ...state];
        case `${type}_UPDATE`:
            return uniqueKey
                ? state.map(item =>
                      item[uniqueKey] === payload[uniqueKey]
                          ? { ...item, ...payload }
                          : item,
                  )
                : payload;
        case `${type}_DELETE`:
            return options
                ? state.filter(item => item[options.key] !== options.value)
                : state;
        default:
            return otherHandlers(state, action) || state;
    }
};

export default createDataReducer;
