import React from "react";
import { Table } from "components/common";
import EligibleMoveBottom from "./Bottom";
import EligibleMoveTop from "./Top";
import uuidv1 from "uuid/v1";
import { useWindowSize } from "../../common/WindowSize";
import { MobileTable } from "../../common";
import { EligibleMoveProps } from "types";
import "./styles.scss";

const EligibleMove = (props: EligibleMoveProps & { url?: string}) => {
    // const generateTableRows = (
    //     rows: Array<Array<string>>,
    // ): Array<{ id: string; body: Array<JSX.Element> }> =>
    //     rows.map(item => ({
    //         id: uuidv1(),
    //         body: item.map((child, childIndex) => (
    //             <div
    //                 className={childIndex === 0 ? "firstElem" : ""}
    //                 key={uuidv1()}
    //             >
    //                 {child}
    //             </div>
    //         )),
    //     }));

    // const size = useWindowSize();

    return (
        <div className="eligible-container RecruitedSelector">
            <EligibleMoveTop
                history={props.history}
                location={props.location}
                backState={props.backState}
            />
            <div className={"top " + props.assetId}>
                <div className="move-title">
                    <p>
                        Your move is eligible for:
                        <span>{props.eligibleTitle}</span>
                    </p>
                </div>
                <div className="top-content">
                    <div className="col">
                        <p>Type of Move:</p>
                        <span>{props.moveType}</span>
                    </div>
                    <div className="col">
                        <p>Category:</p>
                        <span>{props.category}</span>
                    </div>
                    <div className="col">
                        <p>Employee:</p>
                        <span>{props.employee}</span>
                    </div>
                    {props.category2 && (
                        <div className="col">
                            <p>Category:</p>
                            <span>{props.category2}</span>
                        </div>
                    )}
                </div>
            </div>
            {/* <div className="middle">
                <div className="middle-top">
                    <div className="question-text">
                        What’s it going to cost?
                    </div>
                    <p>
                        This is just a guideline and the final cost estimate will have to be approved by you and your manager.
                    </p>
                </div>
                <div className="middle-table">
                    {size.width > 600 ? (
                        <Table
                            className="eligible-table"
                            rowHeight={100}
                            cols={props.cols}
                            rows={generateTableRows(props.rows)}
                        />
                    ) : (
                        <MobileTable
                            className="eligible-mobile-table"
                            cols={props.cols}
                            rows={props.rows}
                        />
                    )}
                </div>
            </div> */}
            <EligibleMoveBottom
                policyName={props.policyName}
                history={props.history}
                location={props.location}
                assetId={props.assetId}
                assetType={props.assetType}
                url={props.url}
                {...props}
            />
        </div>
    );
};

export default EligibleMove;
