const CurrencyFormatter = (props) => {
    const currency_symbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine
		'ALL': 'L', // Albania Lek
		'DZD': 'دج', // Algeria Algerian Dinar 
		'ARS': '$ ', // Argentina Argentine peso
		'AMD': '֏', // Armenia Armenian dram
		'AUD': 'AU$', // Australia Australian Dollar
		'AZN': '₼', // Azerbaijan Manat
		'BYN': 'Br', // Belarus Belarusian ruble
		'BAM': 'KM', // Bosnia 	Convertible Mark
		'BRL': 'R$', // Brazil Brazilian Real
		'BGN': 'Лв.', // Bulgaria Bulgarian Lev
		'CAD': 'Can$', // Canada Canadian Dollar
		'CLP': '$', // Chile Chilean peso
		'CNY': '¥', // Chaina Yuan Renminbi
		'COP': '$', // Columbia Colombian Peso
		'HRK': 'kn', // Croatia Croatian kuna
		'CZK': 'Kč', // Czech Republic Czech koruna
		'DKK': 'Kr.', // Denmark Danish Krone	
		'DOP': 'RD$', // Dominican Republic Dominican peso
		'EGP': 'E£', // Egypt Egyptian Pound
        'FIM': 'mk', // Finland Finnish markka
        'GEL': 'ლ', // Georgia Lari
        'GHS': 'GH¢', // Ghana Ghana Cedi
		'GTQ': 'Q', // Gautemala Guatemalan quetzal
		'HNL': 'L', // Honduras Lempira
        'HKD': 'HK$', // Hongkong Hong Kong Dollar	
        'HUF': 'Ft', // Hungarian Forint
        'IDR': 'Rp', // Indonesia Rupiah
        'IEP': 'IR£', // Ireland Irish pound
        'JMD': '$', // Jamaica Jamaican dollar
        'JOD': 'د.ا', // Jordan Jordanian Dinar
        'KZT': '₸', // Kazakhstan Kazakhstani tenge
		'KES': '/-', // Kenya Kenyan shilling
		'KWD': 'د.ك', // Kuwait Kuwaiti dinar
		'LVL': '֏', // Latvia
		'LBP': 'ل.ل.‎', // Lebanon Lebanese pound
		'LTL': 'ct', // Lithuania
		'MYR': 'RM', // Malaysia Malaysian Ringgit	
		'MUR': '₨', // Mauritius Mauritius Rupee
		'MXN': 'Mex$', // Mexico Mexican Peso	
		'MAD': 'د.م.', // Morocco Moroccan Dirham	
		'ANG': 'ƒ', // Netherlands
		'NZD': '$', // New Zealand dollar
		'NOK': 'kr', // Norway Norwegian Krone
		'PKR': '₨', // Pakistan Pakistani rupee
		'PAB': 'B/.', // Panama Panamanian balboa
		'PYG': '₲', // Paraguay Paraguayan guarani
		'PEN': 'S/', // Peru Sol
		'PLN': 'zł', // Poland	Polish Zloty
		'RON': 'lei', // Romania Romanian leu
		'RUB': '₽', // Russia Russian Ruble	
		'SAR': '﷼‎', // Saudia Arabia Saudi Riyal	
        'RSD': 'din', // Serbia Serbian Dinar
		'SGD': 'S$', // Singapore Dollar	
        'SKK': 'Sk', // Slovakia
        'ZAR': 'R', // South Africa Rand
		'SEK': 'kr', // Sweden Swedish Krona	
		'CHF': 'Fr.', // Switzerland Swiss franc
        'TWD': 'NT$', // Taiwan New Taiwan Dollar
        'THB': '฿', // Thailand Thai baht
        'TTD': 'TT$', // Trinidad and Tobago Dollar
        'TRY': '₺', // Turkey Turkish Lira
        'UAH': '₴', // Ukraine Ukrainian hryvnia
        'AED': 'د.إ', // United Arab Emirates Dirham
		'VES': 'Bs.S.', // Venezuela Venezuelan bolívar
		'VND': '₫', // Vietnam Vietnamese dong
		'ZMW': 'ZK', // Zambia Zambian kwacha
    };
    const currency = (props.currencyCode !== undefined && props.currencyCode !== null )? props.currencyCode : '';
    const currencyAmount = (props.amount !== undefined && props.amount !== null ) ? props.amount : '';
    const symbol = (currency_symbols[currency] !== undefined && currency_symbols[currency] !== null )? currency_symbols[currency]: currency;
    return (symbol + " " + currencyAmount);

};

export default CurrencyFormatter;
