import React from 'react';
import FFButton from './FFButton';
import Feedback from './Feedback';


export default class FastFeedback extends React.Component {

    state = {
        active: "experience",
        showModal: true, 
        showButton: true, 
        submitted: false,
        satisfactionLevel: "Skipped"
    };

    getTabMap() {
        return {
            experience: () => <Feedback mode="experience" modalController={this.modalController} />,
            comments: () => <Feedback mode="comments" modalController={this.modalController} />,
            thankyou: () => <Feedback mode="thankyou" modalController={this.modalController} />,
        };
    }

    modalController = {
        setActive: (param) => this.setState({active:param}),
        getActive: () => {return this.state.active},
        setSubmitted: () => this.setState({submitted: true}),
        getSubmitted: () => {return this.state.submitted},
        getShowModal: () => {return this.state.showModal},
        setShowModal: (param) => this.setState({showModal:param}),
        setShowButton: (param) => this.setState({showButton:param}),
        onOpenModal: () => this.setState({showModal: true}),
        onCloseModal: () => { 
            // if on thankyou page, and the form was submitted, hide the button
            if (this.state.active==="thankyou" && this.state.submitted) this.modalController.setShowButton(false);
            this.setState({showModal: false}); 
            this.modalController.setActive(null); 
        },  
        onRestart: () => { this.setState({showModal: true}); this.modalController.setActive("experience"); },
        getSatisfactionLevel: () => {return this.state.satisfactionLevel}, 
        setSatisfactionLevel: (param) => this.setState({satisfactionLevel:param}),
    }


    render() {
        if (!this.state.active && !this.state.submitted) {
            return (
                <FFButton clicked={this.modalController.onRestart} modalController={this.modalController}/>
            );
        }

        const tabMap = this.getTabMap();
        const tabFunction = tabMap[this.state.active];

        if (!tabFunction) {
            return null;
        }

        return tabFunction();
    }
}
 
