import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    fetchPosts,
    fetchSinglePost,
    createPost,
    updatePost,
    deletePost,
} from 'actions/Posts';

import { getPosts } from 'selectors';

import {
    QuestionsListCard,
} from 'components/common'

import './Main.scss';

const mapStateToProps = state => ({ posts: getPosts(state) });

class Main extends PureComponent {

    componentWillMount() {
        this.props.fetchPosts();
    }

    render() {
        const { posts } = this.props;

        return (
            <section className="Main">
                {posts && posts.length > 0 && posts.map(item => (
                    <QuestionsListCard
                        key={item.id}
                        title={item.title}
                        body={item.body}
                    />
                ))}
            </section>
        );
    }
}

export default connect(mapStateToProps, {
    fetchPosts,
    fetchSinglePost,
    createPost,
    updatePost,
    deletePost,
})(Main);
