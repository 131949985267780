import { detect } from 'detect-browser';

const SUPPORTED_BROWSERS = {
    chrome: { version: 70 },
    ie: { version: 11 }
};

class Storage {
    constructor() {
        this.browser = detect();
        this.storage = window.sessionStorage;
    }

    getIsOpened = () => {
        if(this.storage && this.storage.getItem
            && this.storage.getItem('isBannerOpened') === "true"
        ) {
            return true
        }
        return false;
    };

    setOpened = (isOpened, handler) => {
        if(!this.storage || !this.storage.setItem) {
            return;
        }
        this.storage.setItem('isBannerOpened', isOpened);
        handler && handler();
    };

    shouldOpenBanner = () => {
        const isOpened = this.getIsOpened();
        const { name, version } = this.browser;

        const matchSupported = SUPPORTED_BROWSERS[name];

        if((!matchSupported || parseInt(version) < matchSupported.version) && !isOpened) {
            return true;
        }
        return false;
    }
}

export default new Storage();
