import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button as CustomButton } from "components/common";

const DeletePopup = (props) => {
  return(
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"md"}
    >
      {/* <DialogTitle id="alert-dialog-title">We can’t seem to find this employee.</DialogTitle> */}
      <DialogContent>
      <p className="modalDesctextForPreMoveSirva">
        Are You sure you want to delete ?
      </p>
      </DialogContent>
      <DialogActions>
      <CustomButton
        title="Delete"
        className="delete-button"
        style={{backgroundColor:"#CA001B", border:"1px solid #CA001B", width:"75px", textTransform: "uppercase"}}
        labelStyle={{color:"#FFFFFF"}}
        onClick={props.confirm}/>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default (DeletePopup);