// @flow

import * as React from 'react';
import carouselButtonLeft from 'components/common/Images/carousel-button-left.svg';

import './style.scss';

type MainTopContainerProps = {
    backgroundSrc?: string,
    withOpacity: boolean,
    footer?: React.Node | boolean,
    footerAction?: () => void,
};

export default class MainTopContainer extends React.PureComponent<MainTopContainerProps> {

    static defaultProps = {
        withOpacity: false,
    };

    // this function will add wrapper on children for own styles
    addWrapper = (children: React.Node): React.Node => (
        React.Children.map(children, child => (
            React.isValidElement(child) ? <div className="main-top-section flexible" style={{ width: '100%' }}>{child}</div> : null
        ))
    );

    renderOwnFooter = (): React.Node => (
        <div className='flexible aCenter jCenter'>
            <img
                onClick={this.props.footerAction}
                src={carouselButtonLeft}
                className="main-top-footer-back-button"
                alt=""
            />
        </div>
    );

    wrapFooter = (el: React.Node): React.Node => (
        <div className='top-container-footer flexible shrink noGrow'>
            <div className="main-top-footer-offset">
                {el}
            </div>
        </div>
    );

    render() {
        const {
            backgroundSrc,
            footer,
            withOpacity
        } = this.props;

        return (
            <div className="main-top-container flexible">
                { !!backgroundSrc &&
                    <div
                        className="top-container-background"
                        style={{
                            backgroundImage: `url(${backgroundSrc})`,
                            opacity: withOpacity ? .2 : 1,
                        }}
                    >
                        { this.props.withOpacity && <div className='background-opacity' />}
                    </div>
                }
                <div className="main-top-content flexible grow">{this.addWrapper(this.props.children)}</div>
                { footer === false ?
                    null :
                    (footer === true || footer === undefined) ?
                        this.wrapFooter(this.renderOwnFooter()) :
                        this.wrapFooter(footer)
                }
            </div>
        );
    }
}
