import React from 'react';

import { Popup } from 'components/sections';
import { Button, Asset } from 'components/common';

import Storage from './storage';

import './style.scss';

export default class BrowserSupportBanner extends React.PureComponent {

    onClick = () => {
        Storage.setOpened(true, this.forceUpdate.bind(this));
    };

    render() {
        return (
            Storage.shouldOpenBanner() ?
            <Popup
                title="You seem to be using an unsupported browser…"
                className="browser-support"
                onClose={this.onClick}
            >
                <div className="browser-support-body">
                    <p className='browser-support-info-text'>
                        For an optimal experience and full site functionality, please switch to Chrome 70+ or Internet Explorer 11.
                    </p>

                    <div className="browser-support-browsers-list flexible">
                        <div className="browser-support-supported flexible vertical grow">
                            <p className="browser-support-title">We support:</p>
                            <div className="flexible grow">
                                <div className="browser-support-browser-section">
                                    <Asset
                                        assetType="BROWSER_SUPPORT"
                                        assetId="chrome"
                                        showLoading
                                    />
                                    <span className="browser-support-browser-name">Chrome 70+</span>
                                </div>
                                <div className="browser-support-browser-section">
                                    <Asset
                                        assetType="BROWSER_SUPPORT"
                                        assetId="ie"
                                        showLoading
                                    />
                                    <span className="browser-support-browser-name">IE 11</span>
                                </div>
                            </div>
                        </div>
                        <div className="browser-support-unsupported flexible vertical grow">
                            <p className="browser-support-title">We don’t support:</p>
                            <div className="flexible grow">
                                <div className="browser-support-browser-section">
                                    <Asset
                                        assetType="BROWSER_SUPPORT"
                                        assetId="safari"
                                        showLoading
                                    />
                                    <span className="browser-support-browser-name">Safari</span>
                                </div>
                                <div className="browser-support-browser-section">
                                    <Asset
                                        assetType="BROWSER_SUPPORT"
                                        assetId="firefox"
                                        showLoading
                                    />
                                    <span className="browser-support-browser-name">Firefox</span>
                                </div>
                                <div className="browser-support-browser-section">
                                    <Asset
                                        assetType="BROWSER_SUPPORT"
                                        assetId="opera"
                                        showLoading
                                    />
                                    <span className="browser-support-browser-name">Opera</span>
                                </div>
                                <div className="browser-support-browser-section">
                                    <Asset
                                        assetType="BROWSER_SUPPORT"
                                        assetId="edge"
                                        showLoading
                                    />
                                    <span className="browser-support-browser-name">Edge</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Button
                        className="browser-support-button"
                        dataKey="browser_support_button"
                        title="Ok, got it"
                        submit
                        onClick={this.onClick}
                    />
                </div>
            </Popup> : null
        );
    }
}
