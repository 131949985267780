// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { MainTopContainer } from 'components/sections';
import logoImage from 'components/common/Images/manager-aspot-photo.png';
import models from './models';
import './style.scss';

type HrMainTopContainerProps = {
    type: string,
    navigateTo: (string) => void
};

class HrMainTopContainer extends React.PureComponent<HrMainTopContainerProps> {

    state = { // temporary state. this will be removed after changing control with routes
        previousType: ['MAIN']
    };
    // temporary function
    componentWillReceiveProps = (nextProps) => {
        const { previousType } = this.state;
        if(previousType[previousType.length - 1] !== nextProps.type) {
            this.addType(nextProps.type);
        }
    };

    componentWillMount(){
        this.addType(this.props.type);
    }

    addType = (type: string): void => {
        this.setState((prevState) => ({
            previousType: [
                ...prevState.previousType,
                type,
            ]
        }));
    };

    // temporary function
    goBack = () => {
        const previousType = this.state.previousType.slice();
        previousType.pop();
        this.setState({ previousType }, () => {
            this.props.navigateTo(previousType[previousType.length - 1]);
        });
    };

    render() {
        const Model = models[this.props.type](this.props);
        return (
            <MainTopContainer
                backgroundSrc={logoImage}
                withOpacity
                footerAction={this.goBack}
            >
                { !!Model.left && <Model.left.component {...Model.left.props} /> }
                { !!Model.right && <Model.right.component {...Model.right.props}/> }
            </MainTopContainer>
        );
    }
}

export default withRouter(HrMainTopContainer);
