// @flow

import React from 'react';
import { connect } from 'react-redux';

import { errorService, receiptsService } from 'services';
import { getReceipts, getUserProfile } from 'selectors';
import { ReceiptsAPI } from 'api';

import { Uploader, Icon, Button, Asset } from 'components/common';

import { Popup } from 'components/sections';
import ValidatorPopup from './ValidatorPopup';

import './style.scss';

type ReceiptsProps = {
    expenseReportId: number,
    receipts: Array<Object>,
    fetchReceipts: Function,
    uploadReceipts: Function,
    deleteReceipt: Function,
};

type ReceiptsState = {
    readyForDeleteReceipt: boolean,
    receiptID: ?string|number,
    reportID: ?string|number,
    isBusy: boolean,
};

const mapStateToProps = (state) => ({
    profile: getUserProfile(state),
    receipts: getReceipts(state)
});
const mapDispatchToProps = {
    fetchReceipts: receiptsService.actions.fetchReceipts,
    uploadReceipts: receiptsService.actions.uploadReceipts,
    deleteReceipt: receiptsService.actions.deleteReceipt,
    toggleErrorModal: errorService.actions.toggleErrorModal
};

class Receipts extends React.PureComponent<ReceiptsProps, ReceiptsState> {

    state = {
        readyForDeleteReceipt: false,
        receiptID: null,
        reportID: null,
        isBusy: false,
        invalidFiles: [],
    };

    componentWillMount() {
        this.props.expenseReportId && this.props.fetchReceipts(this.props.profile.currentRelocationId, this.props.expenseReportId);
    }

    componentWillReceiveProps(nextProps: ReceiptsProps) {
        if(!this.props.expenseReportId && nextProps.expenseReportId ) {
            this.props.fetchReceipts(this.props.profile.currentRelocationId, nextProps.expenseReportId);
        }
    }

    generateReceiptsList = (receipts: Array<Object>) =>
        !!(receipts && receipts.length) && receipts.map((receipt) => {
            return (
                <span
                    className="file-name aCenter jBetween flexible"
                    key={receipt.receiptId}
                >
                    <span className="singleLine truncate download-link "
                        onClick={() => {
                            this.downloadReceipt(receipt)
                        }}>{receipt.fileName}</span>
                    {this.props.isReadOnly || <Icon
                        name="close"
                        onClick={() => this.isReadyForDeletingReceipts(receipt.reportId, receipt.receiptId)}
                    />}
                </span>
            )
        });

    downloadReceipt = async(receipt: Object): void => {
        new Asset({
            customDownloader:() => ReceiptsAPI.downloadReceipt(this.props.profile.currentRelocationId, receipt.reportId, receipt.receiptId),
            customFileName:receipt.fileName
        }).execute();
    };

    deleteReceipt = (): void => {
        this.setState({ isBusy: true });
        this.props
            .deleteReceipt(
                this.props.profile.currentRelocationId,
                this.props.expenseReportId,
                this.state.receiptID
            )
            .then(()=> {
                this.setState({
                    isBusy: false,
                    readyForDeleteReceipt:false,
                })
            });
    };

    handleFileChose = (files) => {
        if(files && files.length) {
            const formData = new FormData();
            const invalidFiles = [];
            let shouldSendFile = false;
            for(let i = 0; i<files.length; i++) {
                const file = files[i];
                if(file.name.length > 25) {
                    invalidFiles.push(file);
                } else {
                    shouldSendFile = true;
                    formData.append('file', file, file.name);
                }
            }
            this.setState({invalidFiles});
            return shouldSendFile ? this.props.uploadReceipts(this.props.profile.currentRelocationId, this.props.expenseReportId, formData, this.handleUploadError) : Promise.resolve();
        }
        return Promise.resolve();
    };

    handleUploadError = (err) => {
        let errorMessage = 'You selected an invalid file type. Please re-upload the file using any of the following file types: pdf, jpg, jpeg, png';

        if (err.response && err.response.data && err.response.data.message) {
            errorMessage = err.response.data.message;
        }

        console.log(err.response);
        return this.props.toggleErrorModal(true, 'File error', errorMessage);
    };

    handleInvalidFileSave = (file, newFileName) => {
        const formData = new FormData();
        formData.append('file', file, newFileName);
        return this.props.uploadReceipts(this.props.profile.currentRelocationId, this.props.expenseReportId, formData, this.handleUploadError);
    };

    isReadyForDeletingReceipts = (reportID, receiptID) => {
        this.setState({ readyForDeleteReceipt:true, reportID, receiptID })
    };

    onPopupClose = () => {
        this.setState({
            readyForDeleteReceipt: false,
            isBusy: false,
        })
    };

    resetFiles = _ => {
        this.setState({invalidFiles: []});
    };

    generatePopupBody = () => (
        <div className="expense-popup-body">
            {
                !this.state.isBusy ?
                    <div>
                        <Button
                            dataKey="edit_bank_account_button"
                            title="Cancel"
                            simple
                            onClick={this.onPopupClose}
                            style={{ marginRight: 12 }}
                        />
                        <Button
                            dataKey="edit_bank_account_button"
                            title="Delete"
                            submit
                            onClick={this.deleteReceipt}
                        />
                    </div>
                    : <div className="Spinner"/>
            }
        </div>
    );

    render() {
        const {invalidFiles} = this.state;
        const showValidatorPopup = !!invalidFiles.length;

        return !!(!this.props.isReadOnly || this.props.receipts.length) && (
            <div className="Receipts">
                <h3>Receipts</h3>
                {this.props.isReadOnly ? null : <Uploader
                    title="Upload Receipts"
                    onFileChoosen={this.handleFileChose}
                    dataKey="upload_receipts_button"
                />}
                {/*<p className="validation-message">{this.state.validationMessage}</p>*/}
                <div className="value vertical flexible">
                    {this.generateReceiptsList(this.props.receipts)}
                </div>
                {
                    showValidatorPopup &&
                    <ValidatorPopup
                        title="The file name for your receipt is too long. Please provide a shorter name below"
                        files={this.state.invalidFiles}
                        onClose={this.resetFiles}
                        onSave={this.handleInvalidFileSave}
                        onFileChange={this.saveFileChanges}
                    />
                }
                {
                    this.state.readyForDeleteReceipt ?
                        <Popup
                            title="Are you sure you want to delete this receipt"
                            body={this.generatePopupBody('receipt')}
                            closePopup={this.onPopupClose}
                        /> : null
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Receipts);
