// @flow

import { Api } from 'backend';

const FeedbackAPI = {
    displayFeedback: (relocationID: string): Promise<any> =>
        Api.get({path: `/feedback/${relocationID}`}),
    sendFeedback: (relocationID: string, data={} ): Promise<any> =>
        Api.post({
            path: `/feedback/${relocationID}`, body:data}),
};

export default FeedbackAPI;
