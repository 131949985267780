import React, { PureComponent } from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "components/common";

const DropIcons = () => {
    return (
        <Icon
            name={"chevronBlack"}
            width={15}
            style={{ top: "calc(50% - 7.5px)", right: 0, position: "absolute" }}
        />
    );
};

interface IProps {
    readonly label: string;
    readonly value: string;
    readonly onChange: (data: React.ChangeEvent<HTMLSelectElement>) => void;
    readonly options: string[];
    readonly name: string;
    readonly style: object;
    readonly loading?: boolean;
}

class SelectAble extends PureComponent<IProps> {
    public static defaultProps = {
        style: {},
    };

    public render() {
        return (
            <FormControl
                required={true}
                className={"dropdown"}
                style={this.props.style}
            >
                <InputLabel htmlFor={name} classes={{ root: "dropdown-label" }}>
                    {this.props.label}
                </InputLabel>
                <Select
                    disabled={this.props.loading}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    IconComponent={DropIcons}
                    classes={{ selectMenu: "dropdown-menu" }}
                    name={this.props.name}
                >
                    {this.props.options.map(el => (
                        <MenuItem
                            value={el}
                            key={el}
                            classes={{ root: "dropdown-item" }}
                        >
                            {el}
                        </MenuItem>
                    ))}
                </Select>
                {this.props.loading && (
                    <span className="select-field-spinner">
                        <div className="Spinner " />
                    </span>
                )}
            </FormControl>
        );
    }
}

export default SelectAble;
