import moment from "moment";

export default {
    isDate: function(values, value, format) {
        return !!value && moment(value, format).isValid();
    },
    _required: function(values, value) {
        return !!value;
    },
    isNumber: function(values, value) {
        return !!value && !isNaN(value);
    },
    max50: function(values, value) {
        if (value) {
            if (value.length > 50) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    },
    max255: function(values, value) {
        if (value) {
            if (value.length > 255) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    },
};
