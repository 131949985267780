import React from 'react';

export default (
    <div className="article-info">
        <div className="article-info-item">
            <h2 className="title">It's Not Just What You Say, But How You Say It—and Who You Say It To</h2>
            <p className="description">
                Regional differences in business etiquette begin to emerge the moment people meet.
                <br/><br/>Let's start in the urban Northeast, where it’s wholly uncommon for strangers to exchange greetings of any kind when they pass each other on the street. In fact, to nod, to say “hello," to acknowledge the other person in any form other than making the minimal effort required to avoid a physical collision would be highly unusual.
                <br/><br/>Why? Well, it may have something to do with the scale of commerce: Large cities like New York, Boston and Philadelphia are global commercial hubs, and so transactional efficiency is a priority. Outsiders often relate that Northeasterners appear to be in a hurry.
                <br/><br/>Down South and out West, on the other hand, things are a little different—a lil' more drawn out. And who you talk to is different, too. Passing someone in the hall? Sharing an elevator ride with a stranger? In the South, certainly, both of these situations would merit some kind of friendly acknowledgement—nodding, smiling or extending a casual greeting.
                <br/><br/>And unlike the Northeast, where employees are going to conduct business with you as briskly as they possibly can, folks in the American South and West tend to be a bit more conversational. In business meetings, that means people might expect a bit of friendly chit-chat before jumping into a serious discussion.
                <br/><br/>Also, you shouldn't be surprised if the cashier—though a total stranger to you—asks how your day's going, what your plans are, if you're new to town and so forth. Engaging in this kind of banter is regarded as the polite thing to do in much of the South.
                <br/><br/>Throughout the South and much of the Midwest, "Sir" and "Ma'am" remain very much in circulation. It's a good idea to use these terms as polite forms of address in business settings.
                <br/><br/>Meanwhile, on the West Coast, the language of business can seem more indirect. Instead of openly criticizing an idea or disagreeing about an issue, you might hear someone say things like, "I understand your point," or "I'll definitely take that into consideration." It's a more diplomatic approach, to be sure, than the cut-and-dry language commonplace among business folks in the Northeast. On the other hand, if you aren't discerning, you risk emerging from a meeting without a clear sense of where things stand.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Diverging Standards of Dress</h2>
            <p className="description">
                The meaning of "business casual" changes perceptibly depending on where you are in the U.S.
                <br/><br/>For instance, take the American South, where the dress code in general tends to be more conservative. For men, "business casual" often means blazers over an oxford shirt with khakis, and skirts or dress pants with button-downs or sweaters for women. But then, dealing with seasonal heat is a big factor in the way people dress, too. That's why, in the summer, it's not uncommon to see men in suits and slacks made of linen, which is a lightweight material. Polo shirts and short-sleeve dress shirts are also popular. You're unlikely to see double-breasted jackets during the summer for the same reason.
                <br/><br/>Over on the West Coast, the sartorial trend in general is towards more casual clothing. For starters, men rarely wear suits, and "business casual" often seems patterned after Steve Jobs' uniform of black turtlenecks and jeans. And, within that particular corridor known as Silicon Valley, the dress code gets notoriously loose. For men, that might look like jeans and a casual button-down; for women, jeans and a t-shirt. Hoodies, obviously, are ubiquitous.
                <br/><br/>In the Northeast, on the other hand, business casual for men stops just short of a suit: for instance, a dress shirt (with tie optional), combined with dark-colored wool pants or khakis. For women, it's generally a stylish blouse paired with slacks or a skirt.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Gifts</h2>
            <p className="description">
                This one is fairly consistent across the board. However, for non-natives especially, customs in this area can be confusing—and can even potentially get you in trouble.
                <div className="list-with-title">
                    <h4 className="list-title">To help simplify things, here are three rules of thumb:</h4>
                    <ul className="list">
                        <li className="list-item">Gift-giving is prohibited across the board in heavily regulated industries like banking and insurance, where gifts may conflict with anti-bribery policies.</li>
                        <li className="list-item">Many government employees are prohibited from receiving gifts.</li>
                        <li className="list-item">Avoid cash gifts—they're inappropriate and may be interpreted as bribes.</li>
                    </ul>
                </div>
                Still confused? Fortunately, many companies, regardless of where they're situated in the U.S., have specific policies governing giving and receiving gifts. Check with your HR department if you're unsure what to do.
                <br/><br/>While regional differences can be surprising to newcomers, you'll get the hang of things sooner than you think.
            </p>
        </div>
    </div>
)