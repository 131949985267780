import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    expenseReportLineItemService,
    expenseReportItem,
    expenseReportServise,
} from "services";

import moment from "moment";
import PaymentDetails from "./PaymentDetails";

import { Button, Table, Icon, Asset, SpinnerWrapper } from "components/common";

import { Popup } from "components/sections";

import { Receipts } from "./Receipts";

import "./style.scss";

import {
    getUserProfile,
    getExpenseReportLineItem,
    getExpenseReportItem,
} from "selectors";
import { SHORT_DATE_FORMAT } from "../../consts";
import TextField from "components/sections/ValidatableForm/elements/TextField";
import { appInsightsTracking } from "../../App";

const mapStateToProps = store => ({
    expenseReportLineItem: getExpenseReportLineItem(store),
    expenseReportItem: getExpenseReportItem(store),
    profile: getUserProfile(store),
});

class CreateEditExpenseReport extends PureComponent {
    state = {
        reportId: "",
        expenseData: [],
        count: 0,
        readyForDeleteExpenseReport: false,
        showSpinner: false,
        shouldSaveButtonBeDisabled: true,
    };

    componentDidMount() {
        const ID = Number(this.props.match.params.expRepId);
        if (isNaN(ID)) {
            // We are one a page to create new report
            this.props.clearReportStorage();
        } else {
            // We are on edit page
            if (this.props.profile.currentRelocationId) {
                this.props
                    .getExpenseReportLineItem(
                        ID,
                        this.props.profile.currentRelocationId,
                    )
                    .then(resp => {
                        if (resp && resp.result) {
                            const { name } = resp.result;

                            if (resp.result.status === "Pending Submission") {
                                window.confirm(
                                    "The report hasn't finished processing. We will redirect you to the report list and update it once processing is finished",
                                );

                                return this.props.history.push(
                                    "/expense-report",
                                );
                            }
                            this.setState({
                                name,
                                shouldSaveButtonBeDisabled: false,
                            });
                        }
                    });
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            !this.props.profile.currentRelocationId &&
            nextProps.profile.currentRelocationId
        ) {
            const ID = Number(this.props.match.params.expRepId);
            if (!Number.isNaN(ID)) {
                this.props
                    .getExpenseReportLineItem(
                        ID,
                        nextProps.profile.currentRelocationId,
                    )
                    .then(resp => {
                        if (resp && resp.result) {
                            const { name } = resp.result;
                            this.setState({ name });
                        }
                    });
            }

            // this.props.getExepenseReportItem(ID, nextProps.profile.currentRelocationId);
        }
        if (
            nextProps.expenseReportLineItem != null &&
            nextProps.expenseReportLineItem.expenseLineItems != null &&
            Object.keys(nextProps.expenseReportLineItem).length &&
            nextProps.expenseReportLineItem.expenseLineItems.length < 1
        ) {
            this.setState({ count: 0 });
        }
    }

    backToExpenseReport = () => {
        this.props.history.push("/expense-report");
    };
    navigateToAddLineItemPage = () => {
        const { action, expRepId } = this.props.match.params;
        this.props.history.push(
            `/expense-report/${action}/${expRepId}/line-item/add`,
        );
    };
    navigateToEditLineItemPage = report => {
        const { action, expRepId } = this.props.match.params;
        this.props.history.push(
            `/expense-report/${action}/${expRepId}/line-item/edit/${
                report.lineItemId
            }`,
        );
    };
    navigateToNewReport = reportId => {
        const { action } = this.props.match.params;
        this.props.history.push(`/expense-report/${action}/${reportId}`);
    };

    deleteLineItem = lineItemId => {
        const ID = this.props.expenseReportLineItem.reportId;
        this.setState({
            loaderForDeleteID: lineItemId,
        });
        return this.props
            .deletExepenseReportItem(
                ID,
                lineItemId,
                this.props.profile.currentRelocationId,
            )
            .then(() => {
                this.props.getExpenseReportLineItem(
                    ID,
                    this.props.profile.currentRelocationId,
                );
            })
            .finally(() =>
                this.setState({
                    loaderForDeleteID: false,
                }),
            );
    };

    deleteExpenseReport = () => {
        if (true === this.state.readyForDeleteExpenseReport) {
            //Deleting expense report
            const ID = Number(this.props.expenseReportLineItem.reportId);
            this.props
                .deleteExpenseReports(
                    this.props.profile.currentRelocationId,
                    ID,
                )
                .then(() => this.props.history.push("/expense-report"));
        } else {
            //Deleting line item
            this.setState({ readyForDeleteExpenseReport: false });
            this.deleteLineItem(this.state.readyForDeleteExpenseReport);
        }
    };

    submitExpenseReport = () => {
        const confirmationText =
            "Please ensure the necessary receipts have been added/attached to the expense report" +
            ".\nAny missing receipts could delay the processing of the reimbursement. The expense report " +
            "cannot be changed after submission.";
        if (window.confirm(confirmationText)) {
            const ID = Number(this.props.expenseReportLineItem.reportId);
            this.setState({ showSpinner: true });
            this.props
                .submitExpenseReport(this.props.profile.currentRelocationId, ID)
                .then(() => {
                    this.setState({ showSpinner: false });
                    this.props.history.push("/expense-report");
                });
        }
    };

    generateTableRows = () => {
        let count = 0;
        const { expenseLineItems = [] } =
            this.props.expenseReportLineItem || {};
        if (expenseLineItems.length) {
            count = this.props.expenseReportLineItem.totalAmount;
        } else {
            count = 0;
        }

        this.setState({ count });
        return (
            expenseLineItems.length &&
            expenseLineItems.map(report => ({
                id: report.lineItemId,
                onClick: () =>
                    this.getIsReadOnly() ||
                    this.navigateToEditLineItemPage(report),
                body: [
                    <div
                        className="item singleLine truncate"
                        style={{ maxWidth: 350 }}
                    >
                        {report.name}
                    </div>,
                    <div className="description singleLine truncate">
                        {report.description}
                    </div>,
                    <div className="startDate singleLine truncate">
                        {moment(report.startPeriod)
                            .utcOffset(0)
                            .format(SHORT_DATE_FORMAT)}
                    </div>,
                    <div className="endDateTime singleLine truncate">
                        {moment(report.endPeriod)
                            .utcOffset(0)
                            .format(SHORT_DATE_FORMAT)}
                    </div>,
                    <div className="amount singleLine truncate">
                        {report.amount} {report.reimbursementCurrency}
                    </div>,
                    this.getIsReadOnly() ? (
                        <span />
                    ) : this.state.loaderForDeleteID === report.lineItemId ? (
                        <div className="Spinner" />
                    ) : (
                        <Icon
                            name="trash"
                            onClick={event => {
                                event.stopPropagation();
                                this.setState({
                                    readyForDeleteExpenseReport:
                                        report.lineItemId,
                                });
                            }}
                        />
                    ),
                ],
            }))
        );
    };

    generateLinesItemsForMobile = () =>
        this.props.expenseReportLineItem.expenseLineItems.length &&
        this.props.expenseReportLineItem.expenseLineItems.map(report => (
            <div
                className="lineITem-mobile flexible jBetween"
                key={report.lineItemId}
            >
                <div className="name-date flexible vertical jBetween">
                    <span className="singleLine truncate">{report.name}</span>
                    <span>
                        {moment(report.startPeriod).format(SHORT_DATE_FORMAT)} -{" "}
                        {moment(report.endPeriod).format(SHORT_DATE_FORMAT)}
                    </span>
                </div>
                <div className="ammount flexible vertical aEnd jBetween">
                    <span>
                        {report.amount} {report.reimbursementCurrency}
                    </span>
                    <span>
                        {this.getIsReadOnly() ? null : this.state
                              .loaderForDeleteID === report.lineItemId ? (
                            <div className="Spinner" />
                        ) : (
                            <Icon
                                name="trash"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.setState({
                                        readyForDeleteExpenseReport:
                                            report.lineItemId,
                                    });
                                }}
                            />
                        )}
                    </span>
                </div>
            </div>
        ));

    downloadExpenseReport = () => {
        new Asset({
            customDownloader: () =>
                this.props.downloadingExpenseReports(
                    this.props.profile.currentRelocationId,
                    this.props.expenseReportLineItem.reportId,
                ),
            customFileName: `${this.props.expenseReportLineItem.reportId}-${
                this.props.expenseReportLineItem.name
            }`,
        }).execute();
    };

    handleSave = () => {
        this.setState({ showSpinner: true });
        const { expenseReportLineItem } = this.props;
        if (expenseReportLineItem.reportId) {
            // Save updates on report
            const updatedReport = {
                reportName: this.state.name,
                reportId: expenseReportLineItem.reportId,
            };

            this.props
                .saveExpenseReport(
                    this.props.profile.currentRelocationId,
                    updatedReport,
                )
                .then(() => this.setState({ showSpinner: false }))
                .then(() => this.props.history.push("/expense-report"))
                .catch(err => {
                    const { profile } = this.props;
                    const userId =
                        profile && profile.email ? profile.email : "";
                    const message =
                        err &&
                        err.response &&
                        err.response.data &&
                        err.response.data.message;
                    let detail =
                        err &&
                        err.response &&
                        err.response.data &&
                        err.response.data.detail;
                    detail =
                        typeof detail === "object"
                            ? JSON.stringify(detail)
                            : detail;
                    const error =
                        "uiAppHandledError: Action ExpsenReportSubmit" +
                        userId +
                        " " +
                        message +
                        " " +
                        detail;

                    appInsightsTracking.trackException({
                        error: new Error(error),
                    });
                    appInsightsTracking.flush(true);
                    this.setState({ showSpinner: false });
                });
        } else {
            // Create new report
            this.props
                .createExpenseReport(this.props.profile.currentRelocationId, {
                    name: this.state.name,
                    incurredCurrency: this.state.incurredCurrency,
                })
                .then(res => {
                    if (res && res.result)
                        this.navigateToNewReport(res.result.reportId);
                    this.setState({ showSpinner: false });
                });
        }
    };
    handleNameChange = ({ target }) => {
        this.setState({
            name: target.value,
            shouldSaveButtonBeDisabled: target.value ? false : true,
        });
    };
    handleIncurredCurrencyChange = ({ key }) => {
        this.setState({
            incurredCurrency: key,
        });
    };

    generatePopupBody = () => (
        <div className="expense-popup-body">
            <Button
                dataKey="edit_bank_account_button"
                title="Cancel"
                simple
                onClick={this.onPopupClose}
                style={{ marginRight: 12 }}
            />
            <Button
                dataKey="edit_bank_account_button"
                title="Delete"
                submit
                onClick={this.deleteExpenseReport}
            />
        </div>
    );

    onPopupClose = () => {
        this.setState({
            readyForDeleteExpenseReport: false,
        });
    };

    isReadyForDeletingExpenseReport = () => {
        this.setState({ readyForDeleteExpenseReport: true });
    };
    render() {
        const Spinner = <div className="Spinner" />;
        if (this.props.profile.currentRelocationId) {
            if (
                this.props.expenseReportLineItem.error &&
                this.props.expenseReportLineItem.error.global
            ) {
                return this.props.expenseReportLineItem.error.global;
            } else if (
                this.props.expenseReportLineItem.reportId ||
                !this.props.match.params.expRepId
            ) {
                return this.renderExpense();
            } else {
                return Spinner;
            }
        } else {
            return Spinner;
        }
    }
    getIsReadOnly = () =>
        !/(Draft|Ready)/.test(
            this.props.expenseReportLineItem.status || "Draft",
        );
    renderExpense() {
        const { expenseReportLineItem } = this.props;
        const {
            params: { action },
        } = this.props.match;
        const isEdit = action === "edit";
        const {
            reportId = this.props.expenseReportLineItem.reportID,
        } = this.props.expenseReportLineItem;
        const {
            name: reportName = this.props.expenseReportLineItem.name || "",
        } = this.state;
        const { status } = this.props.expenseReportLineItem;
        const { expenseLineItems = [], error = {} } =
            this.props.expenseReportLineItem || {};
        const isReadOnly = this.getIsReadOnly();

        return (
            <section className="CreateExpenseReport">
                <SpinnerWrapper show={this.state.showSpinner}>
                    <h1 className="header-text">
                        {isEdit ? "Edit an" : "Create a new"} Expense Report
                    </h1>
                    <p>
                        Please be certain your direct deposit information is on
                        file before submitting an expense report. If your
                        expenses are reimbursed directly to your employer,
                        direct deposit information is not required and you will
                        select “Check” as the payment method. Should you have
                        questions regarding the process please contact your
                        SIRVA Consultant.
                    </p>
                    <div className="create-expense-report-body">
                        <div className="body-content flexible jBetween">
                            <div>
                                <TextField
                                    dataKey="expense_report_name_input"
                                    type="text"
                                    onChange={this.handleNameChange}
                                    label="Expense Report Name"
                                    value={reportName}
                                    errorMessages={[error.name]}
                                    disabled={isReadOnly}
                                    showValidationMessages={error.name}
                                />
                            </div>
                            <div
                                data-key="status"
                                className="status flexible vertical jAround"
                            >
                                <span>Status</span>
                                <text>
                                    {this.props.expenseReportLineItem.status ||
                                        "Draft"}
                                </text>
                            </div>
                        </div>
                        {!!reportId && (
                            <React.Fragment>
                                <aside>
                                    <PaymentDetails
                                        isEditPage={isEdit}
                                        profile={this.props.profile}
                                        expenseData={
                                            this.props.expenseReportLineItem
                                        }
                                        updateExpenseReport={
                                            this.props.getExpenseReportLineItem
                                        }
                                        isReadOnly={isReadOnly}
                                    />
                                </aside>
                                {this.props.expenseReportLineItem
                                    .reimbursementCurrency && (
                                    <aside>
                                        <h3>Expenses</h3>
                                        {Object.keys(expenseLineItems).length >
                                            0 && (
                                            <div>
                                                <Table
                                                    className="line-item--table"
                                                    rowHeight={100}
                                                    cols={[
                                                        { title: "Item" },
                                                        {
                                                            title:
                                                                "Description",
                                                        },
                                                        { title: "End Date" },
                                                        {
                                                            title:
                                                                "Item Amount",
                                                        },
                                                        { title: "" },
                                                        { title: "" },
                                                    ]}
                                                    rows={this.generateTableRows()}
                                                />
                                                <div className="lineItems-mobile-data flexible vertical">
                                                    <div className="lineItem-mobile-header flexible jBetween aCenter">
                                                        <span>Item</span>
                                                        <span>Item Amount</span>
                                                    </div>
                                                    {this.generateLinesItemsForMobile()}
                                                </div>
                                            </div>
                                        )}
                                        <div className="flexible horizontal jBetween">
                                            {!isReadOnly && (
                                                <Button
                                                    dataKey="add_an_expense_button"
                                                    title="Add an Expense"
                                                    simple
                                                    onClick={
                                                        this
                                                            .navigateToAddLineItemPage
                                                    }
                                                />
                                            )}
                                            {!!expenseReportLineItem.reimbursementCurrency &&
                                            expenseReportLineItem.expenseLineItems &&
                                            expenseReportLineItem
                                                .expenseLineItems.length ? (
                                                <text className="total flexible horizontal aCenter">
                                                    Total:{" "}
                                                    <b>
                                                        {this.state.count}{" "}
                                                        {
                                                            expenseReportLineItem.reimbursementCurrency
                                                        }
                                                    </b>
                                                </text>
                                            ) : null}
                                        </div>
                                    </aside>
                                )}
                                <aside>
                                    <Receipts
                                        expenseReportId={
                                            this.props.expenseReportLineItem
                                                .reportId
                                        }
                                        isReadOnly={isReadOnly}
                                    />
                                </aside>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="create-expense-report-footer flexible jBetween aCenter">
                        <div className="buttons-block flexible horizontal">
                            {isEdit && (
                                <div className="flexible horizontal">
                                    {isReadOnly || (
                                        <div
                                            className="remove flexible horizontal aCenter"
                                            onClick={
                                                this
                                                    .isReadyForDeletingExpenseReport
                                            }
                                            data-key="remove_report_button"
                                        >
                                            <Icon name="trash" />
                                            Remove Report
                                        </div>
                                    )}
                                    {this.props.expenseReportLineItem.status !==
                                    "Draft" ? (
                                        <div
                                            className="download flexible horizontal aCenter"
                                            onClick={this.downloadExpenseReport}
                                        >
                                            <Icon name="download" />
                                            Download Report
                                        </div>
                                    ) : null}
                                </div>
                            )}
                            <div
                                data-key="cancel_editing_button"
                                className="cancel flexible horizontal aCenter"
                                onClick={this.backToExpenseReport}
                            >
                                Cancel
                            </div>
                        </div>
                        <div className="submitting flexible">
                            <Button
                                className="expense-report--submit"
                                dataKey="submit_report_button"
                                disabled={
                                    this.state.shouldSaveButtonBeDisabled ||
                                    isReadOnly
                                }
                                title={reportId ? "Save" : "Create"}
                                onClick={this.handleSave}
                            />
                            {reportId &&
                                (status === "ReadyToSubmit" ||
                                    status === "Ready to Submit") && (
                                    <Button
                                        className="expense-report--submit"
                                        dataKey="submit_report_button"
                                        title="Submit"
                                        onClick={this.submitExpenseReport}
                                    />
                                )}
                        </div>
                        {this.state.readyForDeleteExpenseReport ? (
                            <Popup
                                title={`Are you sure you want to delete this expense ${
                                    true ===
                                    this.state.readyForDeleteExpenseReport
                                        ? "report"
                                        : "line item"
                                }`}
                                body={this.generatePopupBody()}
                                className="delete-popup"
                                closePopup={this.onPopupClose}
                            />
                        ) : null}
                    </div>
                </SpinnerWrapper>
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    {
        ...expenseReportLineItemService.actions,
        ...expenseReportItem.actions,
        ...expenseReportServise.actions,
    },
)(withRouter(CreateEditExpenseReport));
