export const international = [
    {
        label: 'Global Assignment Letter',
        key: 'assignment-letter/international',
    },
    {
        label: 'My Documents',
        key: 'my-documents',
    },
    {
        label: 'Benefit Information',
        key: 'benefit-info',
    },
    {
        label: 'Expense Tracking',
        key: 'expense-report',
    },
    {
        label: 'View a Different Move',
        key: 'your-moves',
    },

];

export const domestic = [
    {
        label: 'U.S. Domestic Relocation Letter',
        key: 'assignment-letter/domestic',
    },    
    {
        label: 'Relocation tasks',
        key: 'tasks',
    },
    {
        label: 'My Documents',
        key: 'my-documents',
    },
    {
        label: 'Expense Tracking',
        key: 'expense-report',
    },
    {
        label: 'View a Different Move',
        key: 'your-moves',
    }
];


export const hrmenu = [
    {
        label: 'Global Experiences',
        key: 'international',
    },
    {
        label: 'Moves within the U.S.',
        key: 'domestic',
    },
    {
        label: 'About Talent Mobility',
        key: 'about',
        skipIsHr: true
    }
];

export const changeViewMenu = [
    {
        label: 'Employee view',
        key: 'employee'
    },
    {
        label: 'People Leader view',
        key: 'peopleLeader'
    }
];
