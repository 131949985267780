import React from 'react';
import { Link } from 'components/common';
import BenefitServiceCenter from "./BenefitServiceCenter";

export default function(props) {
    const {
        description,
        listTitle,
        list,
        link,
    } = props;
    return (
        <div className="benefit-info-content">
            { !!description && <p className="description">{description}</p> }
            { !!listTitle && <p className="list-title">{listTitle}</p> }
            { !!BenefitServiceCenter && <BenefitServiceCenter /> }
            { (Array.isArray(list) && !!list.length) && <ul className="list">
                { list.map((item, key) => <li key={key}>{item}</li>) }
            </ul>}
            { !!link && <Link text={link.text} onClick={() => link.url && window.open(link.url)} /> }
        </div>
    );
}
