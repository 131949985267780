import React, { useEffect, useState } from "react";
import { Asset, Icon, RadioButton } from "components/common";
import { History } from "history";
import { oneWayOrAssignmentOpts as moveOpts, oneWayOrAssignmentOpts } from "../keyMappings";
import { WorkingWithRecKnockout, GlobalOneWayKnockout } from "../knockoutScreens";
import SubmitReqWD from "../ReqWD";
import AcceleratedDevelopment from "../reasons/AcceleratedDepelopment";
import PayGrade from "../paygrades";
import GetPolicy from "../../Policy/index2";
import PayGradeSelect from "../paygrades";
import { LenOfAssignmentOpts } from "../../keyMappings";
import { policyEnum } from "../../../policyMapping";

const HomeLongAssignment = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
  const { state: locationState } = props.history.location;
  const [moveOption, setMoveOption] = useState("");

    const clickHandler = (moveOptionKey: string, value: string): void => {
      props.history.push({
          state: {
              ...locationState,
              assignment: moveOptionKey,
              lengthOfAssignment: value,
              payGrade:""
          },
      });
      setMoveOption(moveOptionKey);
  };

  useEffect(() => {
    setMoveOption("");
    props.disableContinueBtn(true);
  },[locationState["strategic-indent"]]);

  useEffect(() => {
    if(moveOption === "12m") {
      props.history.push({
        state: {
          ...locationState,
          lengthOfAssignment: LenOfAssignmentOpts.LessThanYear.key
        }
      })
      props.disableContinueBtn(false);
    } else {
      if(moveOption === "4y") {
        props.history.push({
          state: {
            ...locationState,
            lengthOfAssignment: LenOfAssignmentOpts.OneToFourYear.key
          }
        })
      }
      props.disableContinueBtn(true);
    }
  },[moveOption]);


  const handleHowLong = () => {
    switch(moveOption) {
      case "12m": 
        return (
            <GetPolicy title={policyEnum.ShortTerm.displayText}  policyName={policyEnum.ShortTerm.key}
         history={props.history} disableContinueBtn={props.disableContinueBtn} movePolicyType={"global"}/>)
      case "4y":
        return <PayGradeSelect history={props.history} disableContinueBtn={props.disableContinueBtn} />
      default:
          return null;
    }
  }
    return (
        <div className="primary-reason-global">
            <div className="top">
                <div className="title">How long is the assignment expected to last?</div>
            </div>
            <div className="options">
                <div className="option">
                  <RadioButton
                      checked={moveOption === LenOfAssignmentOpts["LessThanYear"].value}
                      onChange={(): void => clickHandler("12m","LessThanYear")}
                  />
                  <div className="titles">
                      <p className="title">{LenOfAssignmentOpts["LessThanYear"].displayText}</p>
                  </div>
                </div>
                <div className="option">
                  <RadioButton
                      checked={moveOption === LenOfAssignmentOpts["OneToFourYear"].value}
                      onChange={(): void => clickHandler("4y","OneToFourYear")}
                  />
                  <div className="titles">
                    <p className="title">{LenOfAssignmentOpts["OneToFourYear"].displayText}</p>
                  </div>
                </div>
            </div>
            {handleHowLong()}
        </div>
    );
};

export default HomeLongAssignment;
