// @flow
import React from 'react';
import StepperIcon from './StepperIcon';

type TableRowType = {
    active: boolean;
    statusDescription: string;
    date: string;
    passed: boolean;
    lastChild?: boolean;
    firstChild?: boolean;
}

export default class TableRow extends React.PureComponent<TableRowType> {
    render(): React.ReactNode {
        const { firstChild } = this.props;
        const parentClasses = ["TaxMilestonesTableRow", "flexible", "jBetween"];

        if (this.props.active) {
            parentClasses.push('active');
        }

        if (!this.props.lastChild) {
            parentClasses.push('has-border');
        }

        if (!firstChild) {
            parentClasses.push('has-margin');
        }

        return (
            <div className="flexible aCenter">
                <StepperIcon
                    style={{ marginTop: firstChild ? -14 : 0 }}
                    isActive={this.props.active}
                    isComplete={this.props.passed}
                />
                <div className={parentClasses.join(' ')}>
                    <h5 className="activity-title">{this.props.statusDescription}</h5>
                </div>
            </div>
        );
    }
}
