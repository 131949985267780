import React from 'react';

export default (
    <div className="article-info">
        <div className="article-info-item">
            <h2 className="title">Knowing When to Relocate</h2>
            <p className="description">
                Maybe you think you’re too set in your ways and your career to make a big geographical change. If so, in fact, you might be right: According to experts, &nbsp;
                <a href="https://www.bna.com/workers-willing-change-n57982087022/" rel="noopener noreferrer" target="_blank" className="link">
                    while more than 80% of people over age 45 dream of making a career change, only 6% actually do it.
                </a>
                <br/><br/>The kicker? Those who take the career plunge tend to be not only more successful, but happier as well.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Understand Your Motivation to Move</h2>
            <p className="description">
                A move for the sake of moving isn't going to cure your career doldrums. In order for a relocation to re-energize your career, it's got to be the right move—for the right reasons. So, if you're stuck in a rut, what kind of rut are you stuck in? If you're bored, try not to settle for a lateral move; otherwise, you'll likely end up facing the same stressors and dissatisfactions, only in a new setting.
                <br/><br/>
                Finally, while relocating for a new role can certainly help clear the path for upward professional mobility, you should be sure discuss the opportunities for advancement with your supervisor before you commit to the move.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Get Your Entire Family Onboard</h2>
            <p className="description">
                If you have a family, think carefully about whether the move will also be beneficial to them. A new city or country can broaden horizons, but it will also expose your family to new cultures, customs and languages. And change can be frightening, especially for children. You're not going to enjoy your new role if your family begrudges the move.
                <br/><br/>Get your partner and kids onboard by showing them the positives to be gained—for instance, opportunities to see and experience new places and cultures, learn new languages and meet new people.&nbsp;
                <a href="https://www.parents.com/parenting/money/buy-a-house/make-moving-easier-on-you-and-your-kids/" rel="noopener noreferrer" target="_blank" className="link">
                    Show your kids all the fun things to see and do in your new city
                </a>
                , and mention some of the free communication tools—like FaceTime, Skype, Google Hangouts and more—that they can use to stay connected with their friends back home. Lastly, since Johnson & Johnson will be supporting you with the transition, be sure to explain to your family how this support will make the move less stressful.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Budget Appropriately</h2>
            <p className="description">
                Make sure you've got a budget mapped out in advance so there are no surprises. Having things settled financially will take a lot of stress out of the situation. Just be sure, when you’re calculating your relocation budget, to factor in the cost of shipping your belongings and purchasing or renting a new home. For qualified employees, Johnson & Johnson will even help offset this cost with a relocation allowance.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Get a Feel for the Lay of the Land</h2>
            <p className="description">
                Study your new location well ahead of time, familiarizing yourself with its geographic features, culture, history and industry. If possible, pay a few visits to the area before making any decisions, and try and connect with prospective colleagues, especially anyone who’s made a similar type of move (&nbsp;
                <a href="https://www.linkedin.com/" rel="noopener noreferrer" target="_blank" className="link">LinkedIn</a>
                &nbsp; can be helpful for this).
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Evaluate the Career Perk</h2>
            <p className="description">
                Consider the career perks. Exposing yourself to other cities, countries and cultures can give you an edge career-wise over candidates who might have an otherwise similar resume. The global market is constantly expanding, and your experiences can make you more marketable to prospective employers, especially those who operate internationally.
                <br/><br/>Ultimately, there’s no denying that relocating for work is a big step. But if you do your research and prepare appropriately, it may just be the most rewarding professional choice you ever make.
            </p>
        </div>
    </div>
);
