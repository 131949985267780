import React from 'react';
import { EXTERNAL_LINKS } from 'consts';
import {Link, Icon, Button, ColumnComponent, Asset} from 'components/common';

export const InitMoveStepAccordionData = (onButtonClick) => {
    return [
      {
        title: 'Create Position or Job Requisition',
        content: (
          <div className="flexible">
            <div className="flexible vertical pad-right">
              <p className="expl_hr_acc_item_part_1">A new or open position must be available to transfer the employee. You will need to create a Host Position ID (Our Source) or a Host Job Requisition ID (Workday) for the employee who is moving.</p>
              <p className="expl_hr_acc_item_part_2">For positions located in Argentina, Brazil, Canada, South Africa, and US/PR, use OurSource.
                <br />Refer to “Organization View and Reports” on the Our Source home page and click on “Create and Update Position On Behalf of” for support with creating a Host Position ID.
              </p>
              <p className="expl_hr_acc_item_part_3 pt0">For positions located in any other country, use Workday.</p>
              <p className="expl_hr_acc_item_part_4">Refer to “Employee Quick Links and Reference Materials” on the Workday home page and click on “Global Services HR support”  for guidance on creating a Host Job Requisition ID.</p>
              <h4 className="flexible horizontal expl_hr_acc_item_buttons">
                <Link text="OUR SOURCE" externalLink={EXTERNAL_LINKS.OurSource} />
                <Link text="WORKDAY" externalLink={EXTERNAL_LINKS.Workday} />
              </h4>
            </div>
            <div className="flexible vertical pad-left">
              <p className="expl_hr_acc_item_part_3">Before you begin, be sure you have:</p>
              <ul className="list">
                <li>Estimated start date</li>
                <li>Company code</li>
                <li>Location</li>
                <li>Cost center</li>
                <li>MRC (Management Reporting Company)</li>
              </ul>
              <div className="estimate">
                <hr/>
                <Icon name="timeRed"/><span className="time-label">Estimated Time:</span><br/>
                <span className='time-desc'>Generally takes about 30 minutes of your time<br />Global Services needs 1-2 business days</span>
              </div>
            </div>
          </div>
        )
      },
      {
        title: 'Initiate Move',
        content: (
          <div>
            <div className="flexible">
              <div className="flexible vertical pad-right">
                <p className="expl_hr_acc_item_part_1">In order to initiate relocation services, you will need to complete the Initiation Form.</p>
                <p className="expl_hr_acc_item_part_2">J&J partners with SIRVA, an industry leading relocation services firm. Your move request will be managed by SIRVA. You will have access to their initiation tool and SIRVA Connect. This web based tool will enable you to initiate and track the status of your move request.</p>
                <p className="expl_hr_acc_item_part_3">Does your employee have questions and concerns you can’t answer?</p>
                <p className="expl_hr_acc_item_part_2">Sometimes employees need extra help to understand the relocation policy or may even need to tour the city and  get a better sense of whether a move is the right decision for them. We offer a number of pre-determination services to help answer the employee’s questions & concerns. Services include a policy  phone consultation or a site visit/city tour. Pre-determination services can be initiated via SIRVA Connect.</p>

              </div>
              <div className="flexible vertical pad-left">
              <p className="expl_hr_acc_item_part_3">Before you begin, be sure you have:</p>
              <ul className="list">
                <li>Position ID/Job Requisition ID</li>
                <li>Employee name</li>
                <li>Job details (new salary, pay grade, job title, manager)</li>
                <li>Funding/Finance contact name</li>
                <li>Home/host HR and manager</li>
                <li>Policy type</li>
              </ul>
              <span onClick={() => new Asset({assetType:'move-initiation-checklist', assetId:'international'}).execute() }>View Initiation Checklist</span>
              <div className="estimate">
                <hr/>
                <Icon name="timeRed"/><span className="time-label">Estimated Time:</span><br/>
                <span className='time-desc'>Generally takes about 1 week and involves J&J management, SIRVA, and the employee</span>
              </div>
            </div>
            </div>

            <h4 className="flexible horizontal expl_hr_acc_item_buttons">
              <Button className="question-parts__button" title="Initiate a Move"  onClick={onButtonClick} />
            </h4>

            <ColumnComponent title='How Initiating a Move Works' data={[
                {
                  title: 'Step 1',
                  icon: 'screen',
                  desc: 'First, the manager fills out and submits the move initiation form. ',
                  estimateTime: 'Generally takes about 15-30 minutes of your time',
                },
                {
                  title: 'Step 2',
                  icon: 'personWorld',
                  desc: 'The employee receives an email with a link to a form enabling them to provide additional family details if needed.',
                  estimateTime: 'Generally takes less than 15 minutes of the employee’s time',
                },
                {
                  title: 'Step 3',
                  icon: 'docsDollar',
                  desc: 'The funding finance contact receives an email with a link to a form enabling them to provide the financial details related to the move.',
                  estimateTime: 'Generally takes about 15 minutes',
                },
                {
                  title: 'Step 4',
                  icon: 'talk',
                  desc: 'After receiving the initiation request, a SIRVA Consultant will contact the manager to verify the initiation details and collect any other required information. ',
                  estimateTime: 'Generally takes about an hour',
                },
                {
                  title: 'Step 5',
                  icon: 'clipboard',
                  desc: 'SIRVA completes a due diligence analysis based on the specific country combination and assignment type to identify any compliance challenges.',
                  estimateTime: 'SIRVA needs 1-2 business days',
                },
                {
                  title: 'Step 6',
                  icon: 'approval',
                  desc: 'SIRVA completes the cost estimate and sends to approver 1 for review and approval.  Once approved by approver 1 goes to approver 2 for approval. ',
                  estimateTime: 'SIRVA needs about 2 business days to complete cost estimate once all completed information is received',
                },
                {
                  title: 'Step 7',
                  icon: 'scroll',
                  desc: 'SIRVA creates the Global Assignment Letter (GAL) detailing the relocation package terms and & allowances.',
                  estimateTime: 'Generally takes about an hour',
                },
                {
                  title: 'Step 8',
                  icon: 'world',
                  desc: 'SIRVA initiates immigration services with Fragomen.',
                  estimateTime: 'Immigration application and approval timelines vary significantly by country from 3 weeks to 5 or more months',
                },
                {
                  title: 'Step 9',
                  icon: 'sign',
                  desc: 'The GAL is sent to the employee for review and approval.  Employee signs the GAL and returns it to SIRVA',
                  estimateTime: 'Generally takes about 1 business day ',
                },
                {
                  title: 'Step 10',
                  icon: 'moving',
                  desc: 'Relocation Services are initiated so employee can begin move preparations. ',
                  estimateTime: 'SIRVA will initiate authorizations within 1 business day of receiving all approvals',
                }
              ]}/>
          </div>
        )
      },
      {
        title: 'Support the Immigration Process',
        content: (
          <div className="flexible">
            <div className="flexible vertical pad-right">
              <p className="expl_hr_acc_item_part_1">Fragomen will be supporting the employee with immigration services. As the manager you will need to review and provide various documents for submission.  </p>
              <p className="expl_hr_acc_item_part_2">You will be required to provide corporate documents to Fragomen as part of the immigration process and ensure the accuracy of employee info, host country, and host job details within the visa application. Immigration Processing times start only after an assignment has been formally initiated and the file authorized to Fragomen. Prioritized submission of documentation is critically important to visa and work permit application agility and, in turn, to the effective start date.</p>
            </div>
            <div className="flexible vertical pad-left">
              <div className="estimate">
                <Icon name="timeRed"/><span className="time-label">Estimated Time:</span><br/>
                <span className='time-desc'>Immigration can take anywhere from 3 weeks to 5 months depending on the country requirements and employee specifics</span>
              </div>
            </div>
          </div>
        )
      }
    ];
};
