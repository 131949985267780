import React from 'react';

export default function() {
    return (
        <div className="benefit-service-center">
            <h1 className="title">
                Benefit Service Center
            </h1>
            <p className="text">Representatives can take your elections Monday - Friday from 9 a.m. to 5 p.m., U.S. Eastern Time.</p>
            <div className="phone-info flexible horizontal jBetween">
                <div className="phone-block flexible vertical">
                    <p className="phone-region">From the U.S.</p>
                    <p className="phone-number">1-800-565-0122</p>
                    <p className="additional-info">Say “Representative” at the main menu.</p>
                </div>
                <div className="phone-block flexible vertical">
                    <p className="phone-region">Outside the U.S.</p>
                    <p className="phone-number">1-847-883-0796</p>
                    <p className="additional-info">A representative will answer.</p>
                </div>
            </div>
        </div>
    );
}