import React, { useEffect, useState, useContext } from "react";
import { Asset, Icon, RadioButton } from "components/common";
import { History } from "history";
import { PayGradeOpts } from "../keyMappings";
import { Pay50AboveKnockout } from "../knockoutScreens";
import GlobalOrDomestic from "../GlobalOrDomestic";
import { IsEmpGlobContext } from "../index2";
import SubmitReqWD from "../ReqWD";

const PayGradeSelect = (props: {
    navigateTo: (to: string, from: string) => void;
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    const [moveOption, setMoveOption] = useState("");
    const IsEmpGlobAssign = useContext(IsEmpGlobContext);

    const clickHandler = (moveOptionKey: string, value: string, paygradeVal: string): void => {
        {
            (IsEmpGlobAssign == 'yes') &&
                props.history.push({
                    state: {
                        ...props.history.location.state,
                        "pay-grade": value,
                        payGrade: paygradeVal,
                        moveType: "Global",
                        countryName: "",
                        countryCode: "",
                    },
                });
        }
        {
            (IsEmpGlobAssign == 'no') &&
                props.history.push({
                    state: {
                        ...props.history.location.state,
                        "pay-grade": value,
                        payGrade: paygradeVal,
                        moveType: "Domestic",
                        countryName: "United States",
                        countryCode: "US",
                    },
                });
        }

        setMoveOption(moveOptionKey);
    };

    return (
        <div className="primary-reason-global">
            <div className="top">
                <div className="title">What is the pay grade of the employee?</div>
            </div>
            <div className="options">
                {Object.keys(PayGradeOpts).map(key => {
                    return (
                        <div className="option" key={key}>
                            <RadioButton
                                checked={moveOption === PayGradeOpts[key].text}
                                onChange={(): void =>
                                    clickHandler(PayGradeOpts[key].text, PayGradeOpts[key].value, PayGradeOpts[key].payGrade)
                                }
                            />
                            <div className="titles">
                                <p className="sub-title title">
                                    Paygrade
                                </p>
                                <p className="title">{PayGradeOpts[key].displayText}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            {moveOption === PayGradeOpts.FiftyAndAbove.text && (
                <Pay50AboveKnockout disableContinueBtn={props.disableContinueBtn}
                />
            )}
            {(moveOption === PayGradeOpts.FortyOneAndBelow.text) && (IsEmpGlobAssign == 'yes') && (
                <SubmitReqWD
                    navigateTo={props.navigateTo}
                    history={props.history}
                    disableContinueBtn={props.disableContinueBtn}
                />
            )}
            {(moveOption === PayGradeOpts.FortyOneAndBelow.text) && (IsEmpGlobAssign == 'no') && (
                <SubmitReqWD
                    navigateTo={props.navigateTo}
                    history={props.history}
                    disableContinueBtn={props.disableContinueBtn}
                />
            )}
        </div>
    );
};

export default PayGradeSelect;
