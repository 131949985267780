import React, { PureComponent } from 'react';
import { TextWithImage, Accordion, MidSectionComponent, Link, Icon, SectionHeading} from 'components/common';
import MobilityConnectBlock from '../../MobilityConnectBlock';
import { QuestionsList } from './QuestionsList';

import './style.scss';

export default class IntJourneyBackStep extends PureComponent {

    generateJourneyBackQuestions = () => QuestionsList.map((item, index) => ({
        props: {
            title: (
                <div className='title flexible horizontal aCenter'>
                    <div className="iconblock flexible jCenter aCenter">
                        <Icon name={item.icon}/>
                    </div>
                    <p className="title-text">{ item.title }</p>
                </div>
            ),
            uniqueKey: index,
        },
        content: item.content
    }));

    render() {
        return (
            <div className="hr-journey-back">
                <TextWithImage
                    subtitle="Take the Journey Back"
                    title="How to support an employee at the end of assignment"
                    content={(<p>At the end of a global assignment, the journey back can be filled with questions. Employees, as well as their home and host Manager, have important roles to play in identifying post assignment opportunities. Find out more about what role a manager plays as the journey is concluded.</p>)}
                    assetType="top-content-image"
                    assetId="inactive-int-journey-back"
                />
                <div className="help_content">
                    <p className="flexible vertical aCenter">
                        <h3 className="title">How you can help</h3>
                        <span className="divider"/>
                    </p>
                    <div className="flexible horizontal jBetween">
                        <div className="help-item flexible vertical">
                            <div className="icon">
                                <Icon name="people"/>
                            </div>
                            <h4>Guidance</h4>
                            <p>Support the employee in building and maintaining professional connections in both the home and host location.</p>
                        </div>
                        <div className="help-item flexible vertical">
                            <div className="icon">
                                <Icon name="partnership"/>
                            </div>
                            <h4>Networking</h4>
                            <p>Home and Host Managers should actively leverage their own professional networks and connect with their HR Partners to identify potential next role options for the employee.</p>
                        </div>
                        <div className="help-item flexible vertical">
                            <div className="icon">
                                <Icon name="screen"/>
                            </div>
                            <h4>Careers.jnj.com</h4>
                            <p>Encourage the employee to regularly visit the J&J Careers website to keep abreast of posted positions in their areas of professional interest.</p>
                        </div>
                    </div>
                </div>
                <MidSectionComponent title = "What happens at the end of assignment?" hideDivider="true"
                 col1={
                    <div className="flexible vertical">
                        <h3>Repatriation</h3>
                        <p>The employee in most cases returns to their originating country.</p>
                    </div>
                }
                 col2={
                     <div className="flexible vertical  pad-left">
                         <h3>Localization</h3>
                         <p>In some cases, the global assignee will choose to be localized in the host location rather than to return to the home country.</p>
                         <Link text="Learn More"/>
                     </div>
                 }

                 col3={
                     <div className="flexible vertical  pad-left">
                         <h3>Sequential Move</h3>
                         <p>If a new assignment to a different location takes place upon completion of an existing assignment, the assignment is treated as a new one.</p>
                     </div>
                 }

                 col4={
                     <div className="flexible vertical  pad-left">
                         <h3>Extension of Assignment</h3>
                         <p>Should a decision be reached to extend the assignment in the current location, the Host Manager will connect with SIRVA and provide updated extension details.  SIRVA will then append the existing Global Assignment Letter and document the revised assignment end date. </p>
                     </div>
                 }
                />

                <div className="flexible manager-tasks-content">
                    <div className="flexible vertical questions-tasks-block">
                        <div className="tasks-block">
                            <SectionHeading 
                                eyebrow="Manager Tasks"
                                title="Steps for a Smooth Transition"
                                description="A smooth transition back to the home environment is supported by clear plans and strong alignment between the current and new manager. Recommended steps to support this process include the following:"
                                className="section-heading mbottom-40"
                            />
                            <div className="hr-tasks">
                                <div className="flexible horizontal">
                                    <span>1</span>
                                    <div>
                                        <h4>Engage SIRVA</h4>
                                        <p>
                                            Proactively plan and identify the employee’s next role at least 6 months before the assignment end date. It is the responsibility of the host and home manager to partner in planning and identifying new job opportunities that leverage the global experience and capabilities of the assignee. Managers should also engage with the HR Business Partners for support in identifying potential positions. Localization in the host country may also be an alternative, where business and personal drivers align, and host country immigration regulations permit.
                                            <br/><br/>For short term assignments, the Relocation Company will reach out to Host Managers 3 months prior to end of the assignment to begin discussing next steps. For long term assignments, the Relocation Company will reach out to Host Managers 6 months prior to end of assignment.
                                        </p>
                                    </div>
                                </div>
                                <div className="flexible horizontal">
                                    <span>2</span>
                                    <div>
                                        <h4>Assignment Review</h4>
                                        <p>
                                            Conduct a review meeting with the employee (or leverage the 5 Conversations if this coincides with Mid-Year or Year-end review) to discuss assignment outcomes, assignee experience and strategies for leveraging the experience gained for a next role.
                                        </p>
                                    </div>
                                </div>
                                <div className="flexible horizontal">
                                    <span>3</span>
                                    <div>
                                        <h4>Departure and Reintegration</h4>
                                        <p>
                                            Plan for a warm farewell celebration together with the current host team. Provide an opportunity for the Assignee to reflect on the job and personal benefits of the assignment and for the host team to reflect on the benefits of their partnership with the Assignee. The manager in the new location should work on supporting the reintegration of the employee back to the home environment by defining an onboarding plan to help accelerate the employee’s transition.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="questions-block">
                            <SectionHeading 
                                eyebrow="The Journey Back"
                                title="Common Questions"
                                className="section-heading mbottom-40"
                            />
                            <div className="questions-list">
                                <Accordion data={this.generateJourneyBackQuestions()}/>
                            </div>
                        </div>
                    </div>
                    <div className="flexible vertical manager-tasks-mobility">
                        <MobilityConnectBlock />
                    </div>
                </div>
            </div>
        );
    }
}