import React, { useEffect, useState } from "react";
// import { policySelectionMessage } from "./mockData";
import { Button, Link, RadioButton, Icon, SpinnerWrapper } from "components/common";
// import { useInterval } from "hooks/useInterval";
import { useDispatch } from "react-redux";
// import PrimaryReasonGlobal from "./PrimaryReasonGlobal";
// import DomesticCategory from "./DomesticCategory";
import { History } from "history";
import { moveInitiationService } from "services";
import { packageOpts as PackageOpts } from "./keyMappings";
import "./styles.scss";

import LDP from "./DomesticCategory/LDP";
import Renter from "./DomesticCategory/Renter";
import HomeOwner from "./DomesticCategory/HomeOwner";

import FiftyPercent from "./PrimaryReasonGlobal/promptScreens/FiftyPercent";
import GetPolicy from "./Policy/index2";
import { policyEnum } from "../policyMapping";

const isObj = (obj: object) => obj !== undefined && Object.values(obj).length > 0;

const PackageSelection = (props: {
    navigateTo: (to: string, from: string) => void;
    history: History;
    location: Location;
}) => {
    const dispatch = useDispatch();
    const [delay, setDelay] = useState(1000);
    const [isLoading, setIsLoading] = useState(false);

    const { state: locationState } = props.history.location;
    
    const [state, setState] = useState({
        "global-vs-domestic": "",
        primaryReason: "",
        moveType: !isObj(locationState)
            ? "NotSet" : locationState.moveType,
        empCategoryRepresentation: "NotSet",
        empProcurementType: "NotSet",
        homeownerOrRenter: "NotSet",
        payGrade: "NotSet",
        policy: "NotSet",
        strategicIntent: "NotSet",
        halfInHomeCountrySpent: false,
        lengthOfAssignment: "NotSet",
        beginingOrEndAssignment: false,
        endOfAssignmentType: "NotSet",
        localTalentAvailable: false,
        initiationId: !isObj(locationState)
            ? ""
            : locationState.initiationId
    });
    // const [count, setCount] = useState(0);
    const [continueButtondisabled, setContinueButtondisabled] = useState(true);
    // const [currentMessages, setCurrentMessage] = useState([]);
    const [moveOption, setMoveOption] = useState("");

    useEffect(() => {
        if(isObj(locationState)) {
            if(state.initiationId === "" && state.moveType === "") {
                setState(prevState => ({
                    ...prevState,
                    initiationId: locationState.initiationId,
                    moveType: locationState.moveType
                }))
            }
        }
    },[locationState])
    
    // const empCategoryRepresentation = {
    //     "ldp": "LeadershipDevelopmentProgram",
    //     "homeowner": "Homeowner",
    //     "renter": "Renter"
    // }
    // const lDPScenario = {
    //     "procurement": "InProcurement",
    //     "FLDP": "FLDP",
    //     "None": "NonProcurNonFLDP"
    // }
    // const payGradeScenario = {
    //     "24-": "PayGrade24",
    //     "0-3YOW": "PayGrade26",
    //     "3+YOE": "PayGrade26",
    //     "30-31": "PayGrade30",
    //     "40+": "PayGrade40",
    //     "31-": "PayGrade31",
    //     "25-26": "PayGrade25",
    //     "25-26-0-3YOW":"PayGrade26",
    //     "25-26-3+YOE": "PayGrade26"
    // }
    const payGradeScenarioGlobal = {
        "40+": "PayGrade41",
        "31-": "PayGrade30"
    }
    const payGradeScenarioDomestic = {
        "24-": "PayGrade24",
        "20-": "PayGrade24",
        "25-26": "PayGrade26",
        "25-26-0-3YOW":"PayGrade26",
        "25-26-3+YOE": "PayGrade26",
        "30-31": "PayGrade31",
        "40+": "PayGrade40",
        // "0-3YOW": "PayGrade26",
        // "3+YOE": "PayGrade26",
        // "31-": "PayGrade31",
    }
    // const paygrade = (locationState && locationState.payGrade in payGradeScenario) ? 
    //     payGradeScenario[locationState.payGrade] 
    //     : "NotSet";

    const paygrade = locationState && locationState.moveType === "Domestic" ? 
            locationState.payGrade in payGradeScenarioDomestic ?
                payGradeScenarioDomestic[locationState.payGrade] : "NotSet"
            : locationState && locationState.payGrade in payGradeScenarioGlobal ?
                payGradeScenarioGlobal[locationState.payGrade] : "NotSet";

    // const stIntentScenario = {
    //     "accelerated-development": "AcceleratedDevelopment",
    //     "skill-need": "SkillNeeded",
    //     "business-critical-need": "BusinessCriticalNeeds",
    //     "self-initiated": "SelfInitiated"
    // }

    // const lengthOfAssignment = {
    //     "5m+": "MoreThanFiveYear",
    //     ">12m": "LessThanYear"
    // }
    let dataSet = {};
    if(isObj(locationState)) {
        dataSet = {
            "initiationId": state.initiationId,
            "empCategoryRepresentation": (locationState && locationState.empCategoryRepresentation != null && typeof locationState.empCategoryRepresentation !== 'undefined') ? locationState.empCategoryRepresentation : "NotSet",
            "empProcurementType": (locationState && locationState.empProcurementType != null && typeof locationState.empProcurementType !== 'undefined') ? locationState.empProcurementType : "NotSet",
            "homeownerOrRenter": (locationState && locationState.homeownerOrRenter != null && typeof locationState.homeownerOrRenter !== 'undefined') ? locationState.homeownerOrRenter : "NotSet",
            "payGrade": paygrade,
            "policy": (locationState && locationState.policy != null && typeof locationState.policy !== 'undefined') ? locationState.policy : "NotSet",
            "policyTier": (locationState && locationState.policyTier != null && 
                typeof locationState.policyTier !== 'undefined') ? locationState.policyTier : "NotSet",
            "moveType": isObj(locationState) && locationState.moveType == "Domestic" ? "Domestic" : "Global",
            "strategicIntent": (locationState && locationState.strategicIntent !== null && typeof locationState.strategicIntent !== 'undefined') ? locationState.strategicIntent : "NotSet",
            "halfInHomeCountrySpent": false,
            "lengthOfAssignment": (locationState && locationState.lengthOfAssignment != null && typeof locationState.lengthOfAssignment !== 'undefined') ? locationState.lengthOfAssignment : "NotSet",
            "beginingOrEndAssignment": false,
            "endOfAssignmentType": "NotSet",
            "localTalentAvailable": false,
            "lumpSumPolicy": (locationState && locationState.lumpSumPolicy !== undefined && locationState.lumpSumPolicy != null) ? locationState.lumpSumPolicy : "NotSet",
        }
    } else {
        props.history.replace("/move-dashboard");
    }
    
    const continueHandler = (): void => {
        const { moveType, policySelection } = locationState;
        props.history.push({
            state: {
                ...locationState,
                "policyData": dataSet,
                initiationId: state.initiationId,
                policySelection
            },
        });
        // props.navigateTo("emp-job-details", "package-selection");
        setIsLoading(true);
        setContinueButtondisabled(true);
        dispatch(moveInitiationService.actions.setNonCompetitivePackageDetails(dataSet)).then(data => {
            setIsLoading(false);
            setContinueButtondisabled(false);
            if(data.success) {
                props.history.push({
                    state: {
                        ...locationState,
                        "policyData": dataSet,
                        initiationId: state.initiationId,
                    },
                });
                props.navigateTo("emp-job-details", "package-selection");
            } else {
                console.log(data.message);
            }
        })
        .catch(console.log);
    };
    
    const clickHandler = (moveOptionKey: string, title: string, value: string): void => {
        const { moveType } = locationState;
        moveType === "Global" ? props.history.push({
            state: {
                ...locationState,
                "strategic-indent": title,
                "strategicIntent": value, //  back handler below
                "fifty-percent": "",
                "payGrade": "",
                "payGradeValue": "",
                "lengthOfAssignment": "",
                "eligibleTitle": moveOptionKey === "early-career" 
                    ? policyEnum.ShortTerm.displayText : "",
                "policy": moveOptionKey === "early-career" 
                    ? policyEnum.ShortTerm.key : "",
                policyTier: moveOptionKey === "early-career" 
                    ? policyEnum.ShortTerm.key : ""
            },
        }) : props.history.push({
            state: {
                ...locationState,
                "policyType": moveOptionKey,
                "reasonSelect": title,
                "empCategoryRepresentation": value, //  back handler below
                "fifty-percent": "",
                policy: "NotSet",
                policyTier: "NotSet",
                rotationTypeAKey: "NotSet",
                rotationTypeATitle: "NotSet",
                finalMoveTypeKey: "NotSet",
                finalMoveTypeTitle: "NotSet",
                empProcurementType: "NotSet",
                procurementKey: "NotSet",
                procurementTitle: "NotSet",
                eligibleTitle: "NotSet"
            },
        });
        setMoveOption(moveOptionKey);
    };

    useEffect(() => {
        moveOption === "early-career" && disableContinueBtnHandler(false);
    }, [moveOption])

    const disableContinueBtnHandler = (continueButtondisabled: boolean): void =>
        setContinueButtondisabled(continueButtondisabled);
    
    const renderMovetype = (type: string) => {
        switch(type) {
            case "Domestic": return (<div className="options">
                <div className="option">
                    <Icon name="moving" width={30} height={30} />

                    <div className="titles">
                        <p className="title">
                            Moves within the U.S.
                        </p>
                        <p className="sub-title">
                            The employee is moving from one U.S.
                            location to another U.S. location
                        </p>
                    </div>
                </div></div>)
            case "Global": return (<div className="options">
                <div className="option">
                    <Icon name="world" width={30} height={30} />
                    <div className="titles">
                        <p className="title">Global Move</p>
                        <p className="sub-title">
                            The employee is moving from one country
                            to another country
                        </p>
                    </div>
                </div></div>)
        }
    }
    return (
        <>
        <SpinnerWrapper show={isLoading} >
        <div className="policy-selection policy-wrapper-package">
            <h1>Package selection</h1>
            {/* <div className="messages">{generatedMessages}</div> */}
            {isObj(locationState) && (() => { 
                const { moveType } = locationState;
                return(
                <>
                    <div className="global-vs-domestic domestic-category">
                        <div className="title">
                            {moveType ? renderMovetype(moveType) : null}
                        </div>
                        {moveType ? <>
                            <div className="title">{moveType === "Domestic" ? 
                                `Which category best represents the employee?`
                                : `What is the primary reason you are moving this employee?`} 
                            </div>
                            {moveType === "Domestic" ? null 
                                :<p className="sub-title" style={{ 
                                    paddingBottom: 20
                                 }}>Please select the reason that best describes 
                                your move. If you have any questions, please reach out to Mobility Connect.</p>}
                            <div className="options">
                            {Object.keys(PackageOpts[moveType]).map((key) => {
                                let Movetype = PackageOpts[moveType];
                                return (
                                    <div className="option" key={key}>
                                        <RadioButton
                                            checked={moveOption === Movetype[key].key}
                                            onChange={(): void =>
                                                clickHandler(Movetype[key].key, Movetype[key].title, Movetype[key].value)
                                            }
                                        />
                                        <div className="titles">
                                            <p className="title">{Movetype[key].title}</p>
                                            <p className="sub-title">{Movetype[key].description}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div></>: null}
                        
                        {moveType === "Domestic" && moveOption === "homeowner" && (
                        <div className="homeowner">
                            <HomeOwner disableContinueBtn={disableContinueBtnHandler} history={props.history}/>
                        </div>
                        )}
                        {moveType === "Domestic" && moveOption === "renter" && (
                            <div className="renter">
                                <Renter disableContinueBtn={disableContinueBtnHandler} history={props.history} />
                            </div>
                        )}
                        {moveType === "Domestic" && moveOption === "LDP" && (
                            <div className="ldp">
                                <LDP disableContinueBtn={disableContinueBtnHandler} history={props.history} />
                            </div>
                        )}
                        {moveType !== "Domestic" && moveOption !== "" && moveOption !== "early-career" && (
                            <FiftyPercent 
                            disableContinueBtn={disableContinueBtnHandler} history={props.history}
                            />
                        )}
                        {moveType !== "Domestic" && moveOption !== "" && moveOption === "early-career" && (
                            <GetPolicy title={policyEnum.ShortTerm.displayText} policyName={policyEnum.ShortTerm.key}
                            disableContinueBtn={disableContinueBtnHandler} history={props.history}
                            movePolicyType={"global"}/>
                        )}
                    </div>
                </>
            )})()}
            <div className="divider" />
            <div className="flexible jEnd">
                <Button
                    disabled={continueButtondisabled}
                    onClick={continueHandler}
                    title="Continue"
                    className="submit-button"
                    submit={true}
                />
            </div>
        </div>
        </SpinnerWrapper>
        </>
    );
};

export default PackageSelection;
