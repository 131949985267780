import * as React from "react";
import { SelectionCard } from "components/common";
import { Icon } from "components/common";
import { RouterProps } from "types";
import HomeownerPackageA from "../EligibleMove/HomeownerPackageA";
import HomeownerPackageB from "../EligibleMove/HomeownerPackageB";
import HomeownerPackageCNonManager from "../EligibleMove/HomeownerPackageCNonManager";
import HomeownerPackageC from "../EligibleMove/HomeownerPackageC";
import HomeownerPackageD from "../EligibleMove/HomeownerPackageD";
import RenterPackageA from "../EligibleMove/RenterPackageA";
import NewHire from "../NewHire";
import RenterPackageC from "../EligibleMove/RenterPackageC";
import RenterPackageD from "../EligibleMove/RenterPackageD";


import "./style.scss";

const DomesticPayGrade = (props: RouterProps) => {
    const { moveType, policySelection, policyType  } = props.location.state;
   
    const clickHandler = (policyType: string,payGrade: string, procurementTitle: string, payGradeValue: string): void => {
        props.history.push({
            state: {
                ...props.location.state,
                payGrade,policyType,procurementTitle,payGradeValue
            },
        });
    };

    const backHandler = (moveType, policySelection) => {
        props.history.push({
            state: {
                ...props.location.state,
                "moveType": moveType,
                "policySelection": policySelection,
                "policyType": "",
            },
        });
    };

    if (
        props.location.state["payGrade"] && props.location.state["policyType"] && props.location.state["policyType"] === "homeowner" &&
        props.location.state["payGrade"] === "20-"
    ) {
        return (
            <HomeownerPackageA history={props.history} location={props.location} backState={"payGrade"} />
        );
    } else if (
        props.location.state["payGrade"] && props.location.state["policyType"] && props.location.state["policyType"] === "homeowner" &&
        props.location.state["payGrade"] === "25-26-0-3YOW"
    ) {
        return (
            <HomeownerPackageB history={props.history} location={props.location} backState={"payGrade"} />
        );
    } else if (
        props.location.state["payGrade"] && props.location.state["policyType"] && props.location.state["policyType"] === "homeowner" &&
        props.location.state["payGrade"] === "25-26-3+YOE"
    ) {
        return (
            <HomeownerPackageCNonManager history={props.history} location={props.location} backState={"payGrade"} />
        );
    } else if (
        props.location.state["payGrade"] && props.location.state["policyType"] && props.location.state["policyType"] === "homeowner" &&
        props.location.state["payGrade"] === "30-31"
    ) {
        return (
            <HomeownerPackageC history={props.history} location={props.location} backState={"payGrade"} />
        );
    } else if (
        props.location.state["payGrade"] && props.location.state["policyType"] && props.location.state["policyType"] === "homeowner" &&
        props.location.state["payGrade"] === "40+"
    ) {
        return (
            <HomeownerPackageD history={props.history} location={props.location} backState={"payGrade"} />
        );
    } else if (
        props.location.state["payGrade"] && props.location.state["policyType"] && props.location.state["policyType"] === "renter" &&
        props.location.state["payGrade"] === "20-"
    ) {
        return (
            <RenterPackageA history={props.history} location={props.location} backState={"payGrade"} />
        );
    } else if (
        props.location.state["payGrade"] && props.location.state["policyType"] && props.location.state["policyType"] === "renter" &&
        props.location.state["payGrade"] === "25-26"
    ) {
        return (
            <NewHire history={props.history} location={props.location} />
        );
    } else if (
        props.location.state["payGrade"] && props.location.state["policyType"] && props.location.state["policyType"] === "renter" &&
        props.location.state["payGrade"] === "30-31"
    ) {
        return (
            <RenterPackageC history={props.history} location={props.location} backState={"payGrade"} />
        );
    } else if (
        props.location.state["payGrade"] && props.location.state["policyType"] && props.location.state["policyType"] === "renter" &&
        props.location.state["payGrade"] === "40+"
    ) {
        return (
            <RenterPackageD history={props.history} location={props.location} backState={"payGrade"} />
        );
    }
    else {
        return (
            <article className="DomesticPayGradeContainer">
                <div className="back-icon" onClick={() => backHandler(moveType,policySelection)} >
                    <Icon name="backIcon" />
                    <p>Go back</p>
                </div>
                <h1 className="section-title">
                    What pay grade is the employee?
                </h1>
                <section className="CardSection">
                {props.location.state &&
                    props.location.state["policyType"] == "homeowner" ? (
                        <span className="domestic-homeowner">
                            <SelectionCard
                                type="regularSelectionCard"
                                backgroundColor="white"
                                title="Paygrade"
                                content="24 and below"
                                step="pay-grade"
                                onClick={() => clickHandler(policyType,"20-","24 and below","PayGrade24")}
                            />
                            <SelectionCard
                                type="regularSelectionCard"
                                backgroundColor="white"
                                title="Paygrade"
                                content="25-26, professional /technical / MBA,0-3 YOW"
                                step="pay-grade"
                                onClick={() => clickHandler(policyType,"25-26-0-3YOW","25-26, professional /technical / MBA,0-3 YOW","PayGrade26")}
                            />
                            <SelectionCard
                                type="regularSelectionCard"
                                backgroundColor="white"
                                title="Paygrade"
                                content="25-26, professional /technical /MBA, 3+YOE"
                                step="pay-grade"
                                onClick={() => clickHandler(policyType,"25-26-3+YOE","25-26, professional /technical /MBA, 3+YOE","PayGrade26")}
                            />
                            <SelectionCard
                                type="regularSelectionCard"
                                backgroundColor="white"
                                title="Paygrade"
                                content="30-31"
                                step="pay-grade"
                                onClick={() => clickHandler(policyType,"30-31","30-31","PayGrade31")}
                            />
                            <SelectionCard
                                type="regularSelectionCard"
                                backgroundColor="white"
                                title="Paygrade"
                                content="40 and above"
                                step="pay-grade"
                                onClick={() => clickHandler(policyType,"40+","40 and above","PayGrade40")}
                            />
                        </span>
                    ) : (
                        <span className="domestic-renter">
                            <SelectionCard
                                type="regularSelectionCard"
                                backgroundColor="white"
                                title="Paygrade"
                                content="24 and below"
                                step="pay-grade"
                                onClick={() => clickHandler(policyType,"20-","24 and below","PayGrade24")}
                            />
                            <SelectionCard
                                type="regularSelectionCard"
                                backgroundColor="white"
                                title="Paygrade"
                                content="25-26"
                                step="pay-grade"
                                onClick={() => clickHandler(policyType,"25-26","25-26","PayGrade26")}
                            />
                            <SelectionCard
                                type="regularSelectionCard"
                                backgroundColor="white"
                                title="Paygrade"
                                content="30-31"
                                step="pay-grade"
                                onClick={() => clickHandler(policyType,"30-31","30-31","PayGrade31")}
                            />
                            <SelectionCard
                                type="regularSelectionCard"
                                backgroundColor="white"
                                title="Paygrade"
                                content="40 and above"
                                step="pay-grade"
                                onClick={() => clickHandler(policyType,"40+","40 and above","PayGrade40")}
                            />
                        </span>
                    )}
                </section>
            </article>
        );
    }
};

export default DomesticPayGrade;
