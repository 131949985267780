import React from "react";
import { Icon } from "components/common";
import { RouterProps } from "types";
import './style.scss'

const TalentMobilityOWM = (props: RouterProps) => {
    const backHandler = (): void => {
        props.history.push({
            state: {
                ...props.location.state,
                "owm-or-assignment": "",
            },
        });
    }
    return (
        <div className="talentMobiltyOWMContainer">
            <section className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </section>
            <h1 className="section-title">Great, the Talent Mobility Team is here to help!</h1>
            {/* <p className="sub-header">In order to better serve you, please email <a href="mailto:TalentMobil@its.jnj.com?subject=[OWM] Talent Mobility Support">TalentMobil@its.jnj.com</a> and include the following information:</p>
            <ul className="bulletPointsSection">
                <li className="textFont">Employee’s Name and WWID</li>
                <li className="textFont">Pay Grade of the Position</li>
                <li className="textFont">Hiring Manager’s Name and WWID</li>
                <li className="textFont">Current Country Location</li>
                <li className="textFont">Future Country Location</li>
            </ul>
            <p className="label">What's Next</p>
            <p className="textFont">Once we receive your email, we will contact you within 48 hours to guide you through the rest of your journey.</p> */}
            <section className="button-section">
                    {/* <div className="line" />
                    <br /> */}
                    <section className="buttons">
                        <button
                            className="red-button"
                            onClick={() => {
                                props.history.push(
                                    "/profile",
                                    { state: {} },
                                );
                            }}
                        >
                            <span className="buttonLabel">Return to Home</span>
                        </button>
                    </section>
                </section>
        </div>
    );
};

export default TalentMobilityOWM;
