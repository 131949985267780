import React, { Component } from 'react';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import './SelectField.scss';


export default class FormsySelect extends Component {

  checkedHandler = (item) => {
    return this.props.value && this.props.value.some(e=> e === item)
  };

  renderValue = (value) => {
      if(Array.isArray(value)) {
          return this.props.options.reduce((placeholder, option) => {
              if (value.includes(option.value)) {
                  return `${placeholder} ${placeholder ? ', ' : ''} ${option.label}`
              }
              return placeholder;
          }, '');
      }
      const option = this.props.options.find(option => option.value === value);
      return option ? option.label : '';
  };

  render(){
    const { multiple, disabled, options, errorMessages, helperText, simple, withCheckbox, defaultValue, dataKey } = this.props;
    return (
      <div
        style={{ display: 'inline-flex', position: 'relative', flexDirection: 'column' }}
        className={`
          ${disabled ? 'disabled' : ''}
          ${simple ? 'simple' : ''}
          ${this.props.value.length || typeof this.props.value === 'number' ? 'selectField-filled' : ''}
          select-field-body`}
      >
        <InputLabel htmlFor="select">{this.props.label}</InputLabel>
        <Select
          input={<Input id="select" />}
          value={this.props.value || []}
          className={this.props.className}
          onChange={this.props.onChange}
          multiple={multiple}
          disabled={disabled}
          style={this.props.style}
          name={this.props.name}
          MenuProps={this.props.MenuProps}
          defaultValue={defaultValue}
          renderValue={this.renderValue}
          SelectDisplayProps={{'data-key': dataKey}}
        >
          {(options && options.length > 0)
            ? options.map((item, index) => (
              <MenuItem key={index} value={item.value} children={item.label}>
                {withCheckbox ?
                  <div className="menu-item-checkbox">
                    <Checkbox checked={this.checkedHandler(item.value)} />
                    <ListItemText primary={item.label} />
                  </div>
                  : item.label
                }
              </MenuItem>
            ))
            : (
              <MenuItem disabled>
                Not result
              </MenuItem>
            )
          }
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {
            (this.props.showValidationMessages && errorMessages) && errorMessages.map((message, i) => (
                <p className="formElement_errorText" key={i}>
                    {message}
                </p>
            ))
        }
      </div>
    )
  }
}
