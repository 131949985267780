import React from "react";
import { Icon } from "components/common";
import { RouterProps } from "types";
// import ContactMobility from './ContactMobility';
import "./styles.scss";


const OptionsScreen = (props: RouterProps) => {
    const clickHandler = (): void => props.history.push("/profile");

    const backHandler = (): void => 
        props.history.push({
            state: {
                ...props.location.state,
                "options-screen": "",
            },
        });

    return (
        <div className="option-screen-container">
            <section className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </section>
            <div className="title-section">
                <h1 className="section-title">Great, thanks for the information!</h1>
                <p className="for-repatriation-ex">In order to better serve you, please contact 
                    <a href="mailto:mobilityconnect@sirva.com"> Mobility Connect</a> who will guide you throughout the rest of your experience. </p>
                {/* <p className="for-repatriation-ex">In order to better serve you, please contact Mobility Connect who will guide you throughout the rest of your experience (see below). </p> */}
            </div>  
            {/* <div className="line" />
            <ContactMobility />
            <div className="line" /> */}
            <section className="button-section">
                <button className="submit-button" onClick={clickHandler}>
                    Return to Home
                </button>  
            </section>
        </div>
    );
};

export default OptionsScreen;

