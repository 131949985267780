import globus from 'components/common/Images/world.svg';
import React, { Component } from 'react'
import { Accordion, TextWithImage, MidSectionComponent, QuestionsListCard} from 'components/common';
import { ExplFirstStepData } from './ExplFirstStepData';
import { ExplQuestionsData } from './ExplQuestionsData';
import './style.scss';
import MobilityConnectBlock from '../../MobilityConnectBlock';

export default class DomExploreStep extends Component {

  generateExplFirstStepData = () => ExplFirstStepData.map((item, index) => ({
      props: {
          title: (
              <div className='title flexible horizontal aCenter'>
                  <span className="index flexible jCenter aCenter">{ index + 1 }</span>
                  <p className="title-text">{ item.title }</p>
              </div>
          ),
          uniqueKey: index,
      },
      content: item.content
  }));
  generateExplFirstStepQuestionsData = () => ExplQuestionsData.map((item, index) => ({
      props: {
        title: (
            <div className='title flexible horizontal aCenter'>
                <div className="iconblock flexible jCenter aCenter">
                  <img src={item.icon} alt="" />
                </div>
                <p className="title-text">{ item.title }</p>
            </div>
        ),
        uniqueKey: index,
    },
    content:
      (<div className="dom-expl-first-step-info-content">
          { item.content }
      </div>)
  }));

  render() {
    return (
      <div className="hr-DomExploreStep">
         <TextWithImage
                subtitle="Strategic Planning & Smart Choices"
                title="U.S. Domestic Relocations"
                content={(<p>Expanding your team perspective with individuals from around the country can help drive diverse thinking and innovation for the future success of Johnson & Johnson.<br />
                  The Support Packages for U.S. Domestic moves are based on the job and managed via the job application & interviewing process.  Read on to learn how to move forward.</p>)}
                assetType="hr_top_content_image"
                assetId="domestic_planning"
              />

        <div className="question-part dom-expl-question-part">
          <h4>The Basics</h4>
          <h2>Understanding our Relocation Packages</h2>
          <div className="question-block">
            <QuestionsListCard
                key={1}
                icon={globus}
                body={(<div>
                    <p>
                    U.S. Domestic relocations vary depending on business objectives and talent strategies. They are generally considered permanent, one way moves.  U.S. domestic business moves less than 12 months in length are considered business travel and may be managed directly by the business.  Should the business want support for talent moves of 12 months or less, please continue to follow the process steps described herein.
                    </p>
                    <h3>Relocation Packages vary by Pay Grade level and support a range of business investments, such as:</h3>
                    <ul>
                      <li>{'Professional: Pay Grade ' + String.fromCharCode(60) + ' 30'}</li>
                      <li>Manager: Pay Grade 30-31</li>
                      <li>Director & Above: Pay Grade 40+</li>
                      <li>By Nomination Leadership Development Programs (varies by function)</li>
                    </ul>
                  </div>)}
                urlText={["View Domestic Relocation Packages"]}
                urlActions={[() => window.location.replace('/resources/managerDom/DomesticPolicies')]}
            />
          </div>
        </div>

        <div className="question-part">
          <h4>Your First Step</h4>
          <h2>Align your Talent Acquisition Strategy & Budget</h2>
          <div className="dom-expl-first-step">
            <Accordion
                data={this.generateExplFirstStepData()}
            />
          </div>
        </div>

        <MidSectionComponent className='illustration-bg'
          col1={
            <div className="flexible vertical">
            <h3>Did you know?</h3>
            <p>47% of domestic relocations have supported new employees.</p>
            <p>53% of domestic relocations have supported current employees.</p>
            </div>
          }
        />

        <div className="flexible dom-expl-question-part">
            <div className="flexible vertical dom-expl-questions">
              <div className="question-part">
                <h4>What managers need to know before they get started</h4>
                <h2>Common Questions</h2>
                <div className="dom-expl-first-step-questions">
                  <Accordion
                      data={this.generateExplFirstStepQuestionsData()}
                  />
                </div>
              </div>
            </div>
            <div className="flexible vertical dom-expl-mobility">
                <MobilityConnectBlock />
            </div>
        </div>
    </div>
    )
  }
}
