import React, { useEffect } from "react";
import { INFO_CARD_COLORS } from "consts";
import { InfoKnockOutCard } from "components/common";

const ContactMobility = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
}) => {

    useEffect(() => {
        return () => {
            props.disableContinueBtn(true);
        };
    }, []);

    return (<div className="contact-mobility">
        <InfoKnockOutCard
            backgroundColor={INFO_CARD_COLORS.first}
            headerIsLink
            title="Great, thanks for the information!"
            content={<>In order to better serve you, please contact <a href="mailto:mobilityconnect@sirva.com" style={{color: "#cc0099"}}> Mobility Connect</a> who will guide you throughout the rest of your experience.</>}
        />
      </div>
    );
};

export default ContactMobility;
