import React, { useState, useEffect } from "react";
import { RadioButton, Asset, Icon } from "components/common";
import { History } from "history";
import GetPolicy from "../../../../Policy";
import { policyEnum } from "../../../../../policyMapping";
import Checkbox from '@material-ui/core/Checkbox';

const FinalMove = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    const { state: locationState } = props.history.location;
    const [state, setState] = useState<{
        [key: string]: string;
    }>({
        "final-move": "",
    });
    const clickHandler = (finalMove: string, policy: string): void => {
        props.history.push({
            state: {
                ...props.history.location.state,policy,
                policyTier: policy,
                lumpSumPolicy: "NotSet",
                eligibleTitle: policy ? policyEnum[policy].displayText : "NotSet",
                finalMoveTypeKey: finalMove,
                finalMoveTypeTitle: finalMove === "yes" ? "Yes" : "No"
            },
        });
    };
   
    useEffect(() => {
        if (state["final-move"]) {
            props.disableContinueBtn(false);
        } else {
            props.disableContinueBtn(true);
        }
    }, [state["final-move"]]);

    const [isChecked, setIsChecked] = useState(false);

    const checkedHandler = () => {
        //setIsChecked(!isChecked);
        if(isChecked) {
            setIsChecked(false);
            props.history.push({
                state: {
                    ...locationState,
                    "lumpSumPolicy": "No",
                    policy: locationState.policyTier
                },
            });
        } else {
            setIsChecked(true);
            props.history.push({
                state: {
                    ...locationState,
                    "lumpSumPolicy": "Yes",
                    policy: policyEnum.LumpSum.key
                },
            });
        }
        
    }
    useEffect(() => { 
        if(isChecked && ["No", "NotSet"].includes(locationState["lumpSumPolicy"]))
            setIsChecked(false);
    }, [locationState["lumpSumPolicy"] ]);
    // useEffect(() => {   //  back handler
    //     props.history.push({
    //         state: {
    //             ...locationState,
    //             policy: "NotSet",
    //             rotationTypeAKey: "NotSet",
    //             rotationTypeATitle: "NotSet",
    //         }
    //     })
    // }, [locationState["policyType"], locationState["empProcurementType"], locationState["rotationTypeAKey"]]);

    useEffect(() => {
        return () => {
            props.disableContinueBtn(true);
        };
    }, []);

    useEffect(() => {
        return () => {
            props.disableContinueBtn(true);
        };
    }, []);

    return (
        <>
            <div className="final-move">
                <div className="top">
                    <div className="title">Final Move?</div>
                </div>
                <div className="options horizontal-options">
                    <div className="option">
                        <RadioButton
                            checked={state["final-move"] === "yes"}
                            onChange={e => { 
                                setState({
                                    ...state,
                                    "final-move": "yes",
                                })
                                clickHandler("yes","USDomesticRenterTier2")
                            }}
                        />
                        <div className="titles">
                            <p className="title">Yes</p>
                        </div>
                    </div>
                    <div className="option">
                        <RadioButton
                            checked={state["final-move"] === "no"}
                            onChange={e => { 
                                setState({
                                    ...state,
                                    "final-move": "no",
                                })
                                clickHandler("no","USDomesticLDPRotational")
                            }}
                        />
                        <div className="titles">
                            <p className="title">No</p>
                        </div>
                    </div>
                </div>
            </div>
            {state["final-move"] && (
                <div className="download-policy-section">
                    <p className="title">What's included?</p>
                    {state["final-move"] === "yes" && (<>
                        <div className="horizontal-section download">
                            <Icon name="linkIcon" className="linkIconCls" />
                            <p><a target="_blank" href={process.env.REACT_APP_EXT_LINK_US_DOMESTIC_RESOURCES} > Policy for {policyEnum.USDomesticRenterTier2.displayText}</a></p>
                        </div>
                        <p className="lum-sum-policy-header-title" >{policyEnum.LumpSum.title}</p>
                        <p className="lum-sum-policy-checkbox-area" ><Checkbox checked={isChecked} onChange={() => {checkedHandler()}} /> &nbsp;Yes</p>
                        <br />
                    </>
                    )}
                    {state["final-move"] === "no" && (<>
                        <div className="horizontal-section download">
                            <Icon name="linkIcon" className="linkIconCls" />
                            <p>
                                <a target="_blank" href={process.env.REACT_APP_EXT_LINK_US_DOMESTIC_RESOURCES} > Policy for {policyEnum.USDomesticLDPRotational.displayText}</a>
                            </p>
                        </div>
                        <p className="lum-sum-policy-header-title" >{policyEnum.LumpSum.title}</p>
                        <p className="lum-sum-policy-checkbox-area" ><Checkbox checked={isChecked} onChange={() => {checkedHandler()}} /> &nbsp;Yes</p>
                        <br />
                    </>
                    )}
                </div>
            )}
        </>
    );
};

export default FinalMove;
