import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import IntExploreStep from "components/stepLayouts/IntExploreStep";
import DomExploreStep from "components/stepLayouts/DomExploreStep";
import IntGetStartedStep from "components/stepLayouts/IntGetStartedStep";
import IntPrepPlanStep from "components/stepLayouts/IntPrepPlanStep";
import IntArrivalStep from "components/stepLayouts/IntArrivalStep";
import IntInHostCountryStep from "components/stepLayouts/IntInHostCountryStep";
import IntJourneyBackStep from "components/stepLayouts/IntJourneyBackStep";
import DomGetStartedStep from "components/stepLayouts/DomGetStartedStep";
import { camelCase } from "lodash";
import {
    titles,
    subtitles,
    topContent,
    questions,
    taskList,
    profileGalButtons,
} from "./mockData";
import {
    TextWithImage,
    QuestionsListCard,
    HelpComponent,
    TaskListComponent,
    InfoCard,
    SectionHeading,
    // eslint-disable-next-line
    Link,
    Asset,
} from "components/common";
import { INFO_CARD_COLORS } from "consts";
import DomPrepPlanStep from "../DomPrepPlanStep";

import "./styles.scss";
import DomRelocateStep from "../DomRelocateStep";
import GalHeader from "../../../containers/AssignmentLetter/AssignmentHeader";
import { withRouter } from "react-router";
import { BalanceSheetsAPI } from "../../../api";

class StepLayout extends PureComponent {
    static propTypes = {
        stepName: PropTypes.string,
        move: PropTypes.string,
        activeRelocation: PropTypes.bool,
        currentRelocationId: PropTypes.string,
    };

    state = {
        hideBalanceSheet: true,
    };

    componentDidMount() {
        const { currentRelocationId } = this.props;
        currentRelocationId &&
            this.props.move === "international" &&
            this.getRecentBalanceSheet(currentRelocationId);
    }

    componentDidUpdate(prevProps) {
        const { currentRelocationId } = prevProps;
        if (
            currentRelocationId &&
            currentRelocationId !== this.props.currentRelocationId &&
            this.props.move === "international"
        ) {
            this.getRecentBalanceSheet(currentRelocationId);
        }
    }

    getRecentBalanceSheet = currentRelocationId => {
        BalanceSheetsAPI.getRecentBalanceSheet(currentRelocationId).then(
            resp => {
                if (resp && resp.result) {
                    resp.result.asOfDate &&
                        this.setState({ hideBalanceSheet: false });
                } else {
                    this.setState({ hideBalanceSheet: true });
                }
            },
        );
    };

    isRelocationInProgress = () => {
        return (
            this.props.activeRelocation &&
            ["prep-plan", "relocate", "arrival", "in-host-country"].indexOf(
                this.props.stepName,
            ) > -1
        );
    };

    getTopImageAssetId = () => {
        let reloType = this.props.move.substring(0, 3);
        return `inactive-${reloType}-${this.props.stepName}`;
    };

    generateQuestions = () =>
        questions[this.props.move][camelCase(this.props.stepName)].details.map(
            (question, index) => (
                <QuestionsListCard
                    key={index}
                    icon={question.icon}
                    title={question.title}
                    body={question.body}
                    urlText={question.urlText}
                    urlActions={question.urlActions}
                />
            ),
        );

    getTasks = () => {
        return taskList[this.props.move][this.getStepName()].tasks;
    };
    getTasksTitle = () => {
        return taskList[this.props.move][this.getStepName()].title;
    };

    getStepName = () => {
        return camelCase(this.props.stepName);
    };

    render() {
        const { move, stepName, taxMilestones } = this.props;
        const additionalInfoCardProps = {
            underLink: "Explore Countries",
            externalLink:
                "https://www.internationalrelocationcenter.com/front_end/?urlid=1067456504",
        };

        if (
            !(
                this.props.move &&
                this.props.stepName &&
                questions[this.props.move][camelCase(this.props.stepName)]
            )
        )
            return null;

        let fromProfileButtons =
            profileGalButtons[this.props.move][this.getStepName()];

        if (this.state.hideBalanceSheet) {
            fromProfileButtons = (fromProfileButtons || []).filter(
                el => !el.type || el.type !== "balance",
            );
        }

        return (
            <div>
                {this.isRelocationInProgress() ? (
                    <div className="gal-greeting-header">
                        <p className="gal-greeting-text">
                            {this.props.move === "international" &&
                            ["arrival", "in-host-country"].indexOf(
                                this.props.stepName,
                            ) > -1
                                ? "We're so glad you're here"
                                : "Congratulations again!"}
                        </p>
                        <GalHeader
                            type={this.props.move}
                            fromProfile={true}
                            fromProfileButtons={fromProfileButtons}
                        />
                        {move === "international" &&
                        stepName === "in-host-country" &&
                        Array.isArray(taxMilestones) &&
                        taxMilestones.length ? (
                            <div className="gal-taxes-section">
                                <hr />
                                <div className="gal-taxes-grid">
                                    <h2>Your Tax Returns</h2>
                                    <div className="gal-taxes-grid">
                                        <p>Most Recent Status:</p>
                                        <strong>
                                            {taxMilestones[0].year} U.S. Return
                                            filed
                                        </strong>
                                    </div>
                                    <Link
                                        text="Tax Return Details"
                                        action="tax-milestones"
                                        onClick={() =>
                                            this.props.history.push(
                                                "/tax-milestones",
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <TextWithImage
                        subtitle={
                            subtitles[this.props.move][this.getStepName()]
                        }
                        title={titles[this.props.move][this.getStepName()]}
                        content={
                            topContent[this.props.move][this.getStepName()]
                        }
                        assetType="top-content-image"
                        assetId={this.getTopImageAssetId()}
                    />
                )}

                {this.props.move === "international"
                    ? {
                          explore: (
                              <IntExploreStep
                                  {...this.props}
                                  getTasks={this.getTasks}
                                  getTasksTitle={this.getTasksTitle}
                              />
                          ),
                          getStarted: <IntGetStartedStep {...this.props} />,
                          prepPlan: (
                              <IntPrepPlanStep
                                  {...this.props}
                                  getTasks={this.getTasks}
                                  getTasksTitle={this.getTasksTitle}
                              />
                          ),
                          arrival: (
                              <IntArrivalStep
                                  {...this.props}
                                  getTasks={this.getTasks}
                                  getTasksTitle={this.getTasksTitle}
                              />
                          ),
                          inHostCountry: (
                              <IntInHostCountryStep
                                  {...this.props}
                                  getTasks={this.getTasks}
                                  getTasksTitle={this.getTasksTitle}
                              />
                          ),
                          journeyBack: (
                              <IntJourneyBackStep
                                  {...this.props}
                                  getTasks={this.getTasks}
                                  getTasksTitle={this.getTasksTitle}
                              />
                          ),
                      }[camelCase(this.props.stepName)]
                    : {
                          explore: (
                              <DomExploreStep
                                  {...this.props}
                                  getTasks={this.getTasks}
                                  getTasksTitle={this.getTasksTitle}
                              />
                          ),
                          getStarted: <DomGetStartedStep {...this.props} />,
                          prepPlan: (
                              <DomPrepPlanStep
                                  {...this.props}
                                  getTasks={this.getTasks}
                                  getTasksTitle={this.getTasksTitle}
                              />
                          ),
                          relocate: (
                              <DomRelocateStep
                                  {...this.props}
                                  getTasks={this.getTasks}
                                  getTasksTitle={this.getTasksTitle}
                              />
                          ),
                      }[camelCase(this.props.stepName)]}

                <div className="question-part steps-columns-section">
                    <div className="question-block flexible">
                        <div className="flexible vertical">
                            <SectionHeading
                                eyebrow={
                                    questions[this.props.move][
                                        camelCase(this.props.stepName)
                                    ].tagline
                                }
                                title={
                                    questions[this.props.move][
                                        camelCase(this.props.stepName)
                                    ].subHeader
                                }
                                className="section-heading mbottom-40"
                            />
                            {this.generateQuestions()}
                        </div>
                        {["explore", "in-host-country"].indexOf(
                            this.props.stepName,
                        ) < 0 ? (
                            <div className="flexible vertical">
                                <TaskListComponent
                                    taskList={this.getTasks()}
                                    title={this.getTasksTitle()}
                                />
                                {this.props.activeRelocation ? (
                                    <HelpComponent
                                        title="We're here to help"
                                        mode="profile"
                                    />
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>

                {this.props.move === "international" ? (
                    <section
                        className="three-cards flexible"
                        style={{ marginBottom: 10 }}
                    >
                        {!this.props.activeRelocation ||
                        (this.props.activeRelocation &&
                            this.getStepName() === "journeyBack") ? (
                            <InfoCard
                                backgroundColor={INFO_CARD_COLORS.first}
                                headerIsLink
                                title="Explore Countries /n & Cultures"
                                content="Understand your individual communication style and get to know other countries."
                                underLink="Explore Countries"
                                category="external"
                                externalLink="https://www.internationalrelocationcenter.com/front_end/?urlid=1067456504"
                            />
                        ) : (
                            <InfoCard
                                backgroundColor={INFO_CARD_COLORS.first}
                                headerIsLink
                                title="Take a cultural assessment"
                                content="Gain a better understanding of your individual communication style and work proccess."
                                category="external"
                                externalLink2="https://jnj.sharepoint.com/sites/leadershipandlearning/Pages/CourseDetail.aspx?CourseUniqueID=1140"
                                {...additionalInfoCardProps}
                            />
                        )}
                        <InfoCard
                            backgroundColor={INFO_CARD_COLORS.second}
                            headerIsLink
                            title="Explore Immigration Timelines"
                            content="Visa processing times vary by country."
                            underLink="Explore the Details"
                            category="external"
                            onClick={()=>new Asset({assetType:'international', assetId:'explore-the-details-immigration-timelines'}).execute()}
                        />
                        <InfoCard
                            backgroundColor={INFO_CARD_COLORS.third}
                            headerIsLink
                            title="Assignment /n Policies"
                            content="Get the details behind each of the assignment types for Talent Mobility."
                            underLink="Read policies"
                            onClick={() =>
                                window.location.replace("/resources")
                            }
                        />
                    </section>
                ) : (
                    <section className="three-cards flexible">
                        <InfoCard
                            backgroundColor={INFO_CARD_COLORS.first}
                            headerIsLink
                            title="Support & Help"
                            content="Have a question? Need Help? You have lots of support throughout the process. We’re here whenever you need us."
                            underLink="View your contact list"
                            onClick={() =>
                                window.location.replace("/contact-us")
                            }
                        />
                        <InfoCard
                            backgroundColor={INFO_CARD_COLORS.second}
                            headerIsLink
                            title="Homeowner or Renter?"
                            content="Learn how our relocation packages vary for homeowners and renters."
                            underLink="Explore the Details"
                            onClick={() =>
                                window.location.replace("/resources")
                            }
                        />
                        <InfoCard
                            backgroundColor={INFO_CARD_COLORS.third}
                            headerIsLink
                            title="U.S. Domestic Relocation Packages"
                            content="Get the details behind our relocation packages."
                            underLink="View Relocation Packages"
                            onClick={() =>
                                window.location.replace("/resources/dom/")
                            }
                        />
                    </section>
                )}
            </div>
        );
    }
}

export default withRouter(StepLayout);
