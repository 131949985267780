import React, { useEffect, useState } from "react";
import "./styles.scss";
// import Select from "./Select";
// import {
//     generalMessages,
//     familySizeValues,
// } from "./mockData";
import { History } from "history";
import { Icon, Button, SpinnerWrapper, TextCaseFormatter, CountryFlags, CurrencyFormatter } from "components/common";
// import Tooltip from "@material-ui/core/Tooltip";
// import { RouteComponentProps, withRouter } from "react-router";
// import { getLeaderShipPrograms, getCaliforniaTiers } from "selectors";
import { connect } from "react-redux";
import { moveInitiationService } from "services";
// import isEqual from "lodash.isequal";
// import { JobDetailsProps, JobDetailsState } from "types";
// import { RouterProps } from "types";
import { getEmpJobDetails } from "selectors";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@material-ui/core";

const mapStateToProps = (state: any) => ({
  empJobDetails: getEmpJobDetails(state),
});

const mapDispatchToProps = {
  fetchEmpJobDetails: moveInitiationService.actions.fetchEmpJobDetails,
  setMoveEmppJobDetails: moveInitiationService.actions.setMoveEmppJobDetails
};

const  EmpJobReview = (props: {
    navigateTo: (to: string, from: string) => void;
    setReviewScrn: (reviewScrn: boolean) => void;
    empJobDetails: any;
    history: History;
    location: Location;
}) => {
               
    const { empJobDetails } = props;
    const { state: locationState } = props.history.location;

    const dispatch = useDispatch();
    const [state, setState] = useState({
      empInfoRepChildEle: null,
      reqInfoRepChildEle: null,
    });
    const [jobID, setJobID] = React.useState(locationState !== undefined ? 
        locationState.jobID !== undefined ? locationState.jobID : "" : "");
    const [isLoading, setIsLoading] = useState(false);
    const [continueButtondisabled, setContinueButtondisabled] = useState(false);

    const { empInfoRepChildEle, reqInfoRepChildEle } = state;

    // console.log("job id: ", jobID)

    const saveEmployeejobDetails = () => {
      setIsLoading(true);
        let reqInfo = locationState.reqInfoRepChildEle != null ? {"Report_Entry":[locationState.reqInfoRepChildEle]} : null;
        const dataSet = {
          "initiationId": (locationState.initiationId !== undefined) ? locationState.initiationId : '',
          "wwid": (locationState.empInfoRepChildEle !== undefined) ? locationState.empInfoRepChildEle.employeeNumber : '',
          "requisitionId": (locationState.jobID !== undefined) ? locationState.jobID : '',
          "positionId": null,
          "employeeInfo": null,
          "reqInfo": (reqInfo),
          "osPosInfo": null
        }
        // console.log("dataset: ", dataSet);
        dispatch(moveInitiationService.actions.setMoveEmppJobDetails(dataSet))
        .then(data => {
            setIsLoading(false);
          if(data.success) {
                props.navigateTo("additional-move-details", "emp-job-details");
          } else {
                console.log(data.message);
          }
        })
        .catch(console.log);
        // props.navigateTo("additional-move-details", "emp-job-details");
    }

  useEffect(() => {
    if(empJobDetails === null && ( typeof locationState === undefined || Object.keys(locationState).length == 0) ) {
      if(locationState.employeeInfo === null || locationState.employeeInfo === undefined ) {
        props.history.push("/move-dashboard");
      }        
      props.history.push("/move-dashboard");
    }

  
    if(typeof locationState !== undefined) {
      let empInfoRepChildEle = null, reqInfoRepChildEle = null;
      if(locationState.empInfoRepChildEle !== undefined && locationState.reqInfoRepChildEle !== undefined) {
          empDetails = {}
          empInfoRepChildEle = (locationState.empInfoRepChildEle != null && typeof locationState.empInfoRepChildEle !== 'undefined') ? 
                                  locationState.empInfoRepChildEle : null;
          reqInfoRepChildEle = (locationState.reqInfoRepChildEle != null && typeof locationState.reqInfoRepChildEle !== 'undefined') ? 
                                  locationState.reqInfoRepChildEle : null;
      } else {    
          var empInfo = (locationState.employeeInfo !== undefined && locationState.employeeInfo !== null ) ? locationState.employeeInfo : null;  
          var empInfoReport = (empInfo != null && empInfo.hasOwnProperty('empNJobDet')) ? 
                              empInfo.empNJobDet : null;
          empInfoRepChildEle = (empInfoReport != null && empInfoReport.hasOwnProperty('empDetails')) ?
                              empInfoReport.empDetails : null;

          var empDetails = (props == null) ? null : (props.hasOwnProperty('empJobDetails') ? props.empJobDetails : null);
          var reqInfo = (empDetails != null && empDetails.hasOwnProperty('requisitionInfo')) ? 
                          empDetails.requisitionInfo : null;
          var reqInfoReport = (reqInfo != null && reqInfo.hasOwnProperty('Report_Entry')) ? 
                          reqInfo.Report_Entry : null;
          reqInfoRepChildEle = (reqInfoReport != null && typeof reqInfoReport[0] !== 'undefined') ? 
                          reqInfoReport[0] : null;
      }
      
        if(empDetails !== null && empInfoRepChildEle !== null && reqInfoRepChildEle !== null ) {
          setState({
              empInfoRepChildEle: empInfoRepChildEle,
              reqInfoRepChildEle: reqInfoRepChildEle
          });
          props.history.push({
              state: {
                  ...locationState,
                  empInfoRepChildEle: empInfoRepChildEle,
                  reqInfoRepChildEle: reqInfoRepChildEle
              },
          });
      }
    }
  },[])
  
  const scrnLg = useMediaQuery('(min-width:1280px)', { noSsr: true });

      return (      
          <div className={"job-details-form"}>
              <SpinnerWrapper show={isLoading}>
              <div className="eligible-container RecruitedSelector" style={{ 
                width: scrnLg ? 773 : 'initial'
               }}>
              <h1 className="section-title" style={{ paddingBottom: 25, marginTop: 10 }}>
                  We’ve found your employee & their new job. Is this correct?
              </h1>
                <div className="top">
                  <div className="move-title">
                      <p>
                        Employee:
                        <span>{empInfoRepChildEle!= null 
                          ? TextCaseFormatter(empInfoRepChildEle.firstName) 
                          + ((empInfoRepChildEle.middleName !== null)
                            ? TextCaseFormatter(empInfoRepChildEle.middleName) 
                              + '':'') + ' ' + TextCaseFormatter(empInfoRepChildEle.lastName) : null}
                        </span>
                      </p>
                  </div>
                  <div className="top-content">
                    <div className="col">
                        <div>
                      <CountryFlags countryCode={empInfoRepChildEle!= null 
                        ?empInfoRepChildEle.countryCode : null} countryName={null} />
                      <p>Home Country:</p>
                      <span>{empInfoRepChildEle!= null 
                        ?TextCaseFormatter(empInfoRepChildEle.addresses.country) : null}</span>
                        </div>
                    </div>
                    <div className="col">
                      <p>Current Office:</p>
                      <span>{empInfoRepChildEle != null && ((empInfoRepChildEle.addresses.addressLine1 !== null && empInfoRepChildEle.addresses.addressLine1 !== "") ||
                            (empInfoRepChildEle.addresses.addressLine2 !== null && empInfoRepChildEle.addresses.addressLine2 !== "") ||  
                            (empInfoRepChildEle.addresses.city !== null && empInfoRepChildEle.addresses.city !== "") || 
                            (empInfoRepChildEle.addresses.stateProvince !== null && empInfoRepChildEle.addresses.stateProvince !== "") || 
                            (empInfoRepChildEle.addresses.postalCode !== null && empInfoRepChildEle.addresses.postalCode !== "") ) ?
                                <>
                                    {empInfoRepChildEle.addresses.addressLine1 !== null && empInfoRepChildEle.addresses.addressLine1 !== "" ? (
                                        <>
                                            {TextCaseFormatter(empInfoRepChildEle.addresses.addressLine1)} <br />
                                        </>
                                        ) : null}
                                    {empInfoRepChildEle.addresses.addressLine2 !== null && empInfoRepChildEle.addresses.addressLine2 !== "" ? (
                                        <>
                                            {TextCaseFormatter(empInfoRepChildEle.addresses.addressLine2)}
                                        </>
                                        ) : null}
                                    {empInfoRepChildEle.addresses.city !== null && empInfoRepChildEle.addresses.city !== "" ? (
                                        <>
                                            {TextCaseFormatter(empInfoRepChildEle.addresses.city)} 
                                        </>
                                        ) : null}
                                    {empInfoRepChildEle.addresses.stateProvince !== null && empInfoRepChildEle.addresses.stateProvince !== "" && empInfoRepChildEle.addresses.stateProvince != undefined ? (
                                            <>
                                                , {(empInfoRepChildEle.addresses.stateProvince).length <= 2 ? TextCaseFormatter(empInfoRepChildEle.addresses.stateProvince,"uper-case") : TextCaseFormatter(empInfoRepChildEle.addresses.stateProvince, "sentence-case")} 
                                            </>
                                            ) : null}
                                    {empInfoRepChildEle.addresses.postalCode !== null && empInfoRepChildEle.addresses.postalCode !== "" ? 
                                    '/' + empInfoRepChildEle.addresses.postalCode
                                    : null }
                                </>
                                : null }</span>
                    </div>
                    <div className="col">
                      <p>Current Job Title:</p>
                      <span>{empInfoRepChildEle!= null 
                        ? TextCaseFormatter(empInfoRepChildEle.jobTitle) : null}</span>
                    </div>
                    <div className="col">
                      <p>Pay grade:</p>
                      <span>{(empInfoRepChildEle != null && 
                        typeof empInfoRepChildEle.compensationGrade !== 'undefined') 
                        ? TextCaseFormatter(empInfoRepChildEle.compensationGrade) : null}</span>
                    </div>
                    <div className="col">
                      <p>WWID:</p>
                      <span>{empInfoRepChildEle!= null ?empInfoRepChildEle.employeeNumber : null}</span>
                    </div>
                  </div>
                  {/** commenting  Current Salary */}
                  {/* <div className="top-content">
                    <div className="col">
                      <p>&nbsp;</p>
                      <span>&nbsp;</span>
                    </div>
                    <div className="col">
                      <p>Current Salary:</p>
                      <span>{(empInfoRepChildEle!= null && empInfoRepChildEle.annualizedCurrency !== undefined ) ? (
                          <CurrencyFormatter 
                            currencyCode={empInfoRepChildEle.annualizedCurrency} 
                            amount={empInfoRepChildEle.annualizedAmount} 
                          />
                        ) : null}</span>
                    </div>
                    <div className="col">
                      <p>&nbsp;</p>
                      <span>&nbsp;</span>
                    </div>
                    <div className="col">
                      <p>&nbsp;</p>
                      <span>&nbsp;</span>
                    </div>
                    <div className="col">
                      <p>&nbsp;</p>
                      <span>&nbsp;</span>
                    </div>
                  </div> */}
                  <div className="top-content">
                    <div className="col empty-col">
                      <p>&nbsp;</p>
                      <span>&nbsp;</span>
                    </div>
                    <div className="col">
                      <p>Home Manager:</p>
                      <span>{empInfoRepChildEle!= null 
                        ? TextCaseFormatter(empInfoRepChildEle.manager) : null}</span>
                    </div>
                    <div className="col">
                      <p>Home Manager Email:</p>
                      <span>{empInfoRepChildEle!= null 
                        ? TextCaseFormatter(empInfoRepChildEle.managerEmail,"lower-case") : null}</span>
                    </div>
                    <div className="col">
                      <p>Home Manager WWID:</p>
                      <span>{empInfoRepChildEle!= null ?empInfoRepChildEle.homeManagerWWID : null}</span>
                    </div>
                    <div className="col empty-col">
                      <p>&nbsp;</p>
                      <span>&nbsp;</span>
                    </div>
                  </div>
                </div>
                <hr className="line" style={{ marginTop: 10 }} />
                <div className="top">
                  <div className="move-title">
                      <p>
                        New job:
                        <span>&nbsp;</span>
                      </p>
                  </div>
                  <div className="top-content">
                    <div className="col">
                    <div>
                      <CountryFlags countryCode={reqInfoRepChildEle != null 
                        ? reqInfoRepChildEle.countryCode : null} countryName={null} />
                        {reqInfoRepChildEle != null && 
                        (reqInfoRepChildEle.countryCode).toLowerCase() == "na" && 
                        (reqInfoRepChildEle.Country).toLowerCase() == "zimbabwe" ? null :
                          <>
                              <p>Host Country:</p>
                              <span>{reqInfoRepChildEle != null 
                              ? TextCaseFormatter(reqInfoRepChildEle.Country) : null}</span>
                          </>
                        }
                        </div>
                    </div>
                    <div className="col">
                      <p>New Office:</p>
                      <span>{reqInfoRepChildEle != null && ((reqInfoRepChildEle["Primary_Address_-_Line_1"] !== null && reqInfoRepChildEle["Primary_Address_-_Line_1"] !== "") ||  
                        (reqInfoRepChildEle.City !== null && reqInfoRepChildEle.City !== "") || 
                        (reqInfoRepChildEle.State !== null && reqInfoRepChildEle.State !== "") || 
                        (reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== null && reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== "") ) ?
                            <>
                                {reqInfoRepChildEle["Primary_Address_-_Line_1"] !== null && reqInfoRepChildEle["Primary_Address_-_Line_1"] !== "" ? (
                                    <>
                                        {TextCaseFormatter(reqInfoRepChildEle["Primary_Address_-_Line_1"])} <br />
                                    </>
                                    ) : null}
                                {reqInfoRepChildEle.City !== null && reqInfoRepChildEle.City !== "" ? (
                                    <>
                                        {TextCaseFormatter(reqInfoRepChildEle.City)} 
                                    </>
                                    ) : null}
                                {reqInfoRepChildEle.State !== null && reqInfoRepChildEle.State !== "" && reqInfoRepChildEle.State != undefined ? (
                                        <>
                                            , {(reqInfoRepChildEle.State).length <= 2 ? TextCaseFormatter(reqInfoRepChildEle.State,"uper-case") : TextCaseFormatter(reqInfoRepChildEle.State, "sentence-case")}
                                        </>
                                        ) : null}
                                {reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== null && reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== "" ? 
                                '/' + reqInfoRepChildEle["Primary_Address_-_Postal_Code"]
                                : null }
                            </>
                          : null }</span>
                    </div>
                    <div className="col">
                      <p>New Job Title:</p>
                      <span>{reqInfoRepChildEle!= null 
                        ? TextCaseFormatter(reqInfoRepChildEle.Job_Posting_Title) : null}</span>
                    </div>
                    <div className="col">
                      <p>New Pay Grade:</p>
                      <span>{(reqInfoRepChildEle != null && 
                        reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req != null && 
                        reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req != undefined) 
                        ? TextCaseFormatter(reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req) 
                        : (reqInfoRepChildEle != null && 
                        reqInfoRepChildEle.Job_Family_From_Position != null
                        && reqInfoRepChildEle.Job_Family_From_Position != undefined 
                        ? TextCaseFormatter(reqInfoRepChildEle.Job_Family_From_Position) : null)}</span>
                    </div>
                    <div className="col">
                      <p>Job ID:</p>
                      <span>{reqInfoRepChildEle != null 
                        ? (reqInfoRepChildEle.Job_Requisition_ID) : null}</span>
                    </div>
                  </div>
                    <div className="top-content">
                        <div className="col empty-col">
                            <p>&nbsp;</p>
                            <span>&nbsp;</span>
                        </div>
                        <div className="col">
                            <p>Host Manager:</p>
                            <span>{reqInfoRepChildEle != null && 
                                reqInfoRepChildEle.Hiring_Manager_Name !== null 
                                ? TextCaseFormatter(reqInfoRepChildEle.Hiring_Manager_Name.replace(/ *\([^)]*\) */g, "")) 
                                : null}</span>
                        </div>
                        <div className="col">
                            <p>Host Manager Email:</p>
                            <span>{reqInfoRepChildEle != null && 
                                reqInfoRepChildEle.Hiring_Manager_Email !== null 
                                ? TextCaseFormatter(reqInfoRepChildEle.Hiring_Manager_Email,"lower-case") 
                                : null}</span>
                        </div>
                        <div className="col">
                            <p>Host Manager WWID:</p>
                            <span>{reqInfoRepChildEle != null && 
                                reqInfoRepChildEle.Hiring_Manager_Name !== null 
                                ? reqInfoRepChildEle.Hiring_Manager_Name.replace(/.*\(|\)/gi,'') 
                                : null}</span>
                        </div>
                        <div className="col empty-col">
                            <p>&nbsp;</p>
                            <span>&nbsp;</span>
                        </div>
                    </div>
                </div>  
                {/* <div className={"divider"} /> */}
                <hr className="line" style={{ marginTop: 50 }} />
                <section className="button-section">
                <div className={"flexible"} style={{ justifyContent: 'space-between' }}>
                  <Button
                      title="Go Back"
                      className="job-details-submit"
                      style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", minWidth: 150 }}
                      labelStyle={{color:"#CA001B"}}
                      onClick={() => {
                        props.setReviewScrn(false);
                      }}
                    />
                    <Button
                        onClick={saveEmployeejobDetails}
                        title="Continue"
                        style={{ minWidth: 150 }}
                        className="job-details-submit"
                        disabled={continueButtondisabled}
                        submit={true}
                    /> 
                </div></section></div>
              </SpinnerWrapper>
          </div>
      );
    }
    

export default connect(mapStateToProps, mapDispatchToProps)(EmpJobReview);