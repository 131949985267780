// @flow

import { Api } from 'backend';
const DEFAULT_EXPENSE = {
    "name": "",
    "status": "Draft",
    "incurredCurrency": "USD",
    "reimbursedCurrency": "USD"
};

const ExpenseReportAPI = {
    setExpenseReports: (reportID: number): Promise<any> => (
        Api.get({ path: `/expensereport/relocations/${reportID}/expreports`})
    ),
    validationExpenseReports: (relocationID: number): Promise<any> => (
        Api.get({ path: `/expensereport/validation/${relocationID}`})
    ),
    deletingExpenseReports: (relocationID: number,reportID: number): Promise<any> => (
        Api.delete({ path: `/expensereport/${relocationID}/${reportID}`})
    ),
    submitExpenseReport: (relocationID: number,reportID: number): Promise<any> => (
        Api.post({ path: `/expensereport/relocations/${relocationID}/${reportID}/submit`})
    ),
    downloadingExpenseReports: (reloID : number,reportID: number): Promise<any> => (
        Api.get({
            path: `/expensereport/${reloID}/${reportID}/download`,
            additionalOptions: {
                responseType: 'blob',
            }
        })
    ),
    getPaymentRemittanceMethods: (reportID) => (
        Api.get({ path: `/expensereport/remittancemethods/${reportID}`})
    ),
    getPaymentRemittanceAccount: ({reportID, methodID}) => (
        Api.get({ path: `/expensereport/remittanceAccounts/${reportID}/${methodID}`})
    ),
    savePaymentRemittance: ({reportID, relocationId}, remittanceInfo) => (
        Api.put({ path: `/expensereport/${relocationId}/${reportID}/paymentMethods`, body:remittanceInfo })
    ),
    getExpenseReportCategories: (relocationID: string) => Api.get({
        path: `/expensereport/categories?relocationId=${relocationID}`
    }),
    getExpenseReportSubCategories: (categoryCode: string, relocationID: string) => Api.get({
        path: `/expensereport/categories/${categoryCode}?relocationId=${relocationID}`
    }),
    getExchangeRateInfo: (relocationID: string, startDate: string, incurredCurrency: string, reimbursedCurrency: string) => Api.get({
        path: `/expensereport/relocations/${relocationID}/exchangerate/${startDate}/${incurredCurrency}/${reimbursedCurrency}`
    }),
    createExpenseReport: (relocationID, data = {}) => Api.put({
        path: `/expensereport/relocations/${relocationID}`,
        body:{
            ...DEFAULT_EXPENSE,
            ...data
        }
    }),
    saveExpenseReport: (reloId, data = {}) => Api.post({
        path: `/expensereport/${reloId}`,
        body:data
    }),
};

export default ExpenseReportAPI;
