import React from 'react';
import { Asset } from 'components/common';
import ReEnergizeYourCareer from "./ReEnergizeYourCareer";
import RelocateSolo from './RelocateSolo';
import KeyDifferences from './KeyDifferences';

export default [
    {
        header: "How to Use a Relocation to Re-energize Your Career",
        image: <Asset assetType="ARTICLE_MAIN_IMAGE" assetId="4"  maxWidth={1000}/>,
        description:
            <p className="article-description">
                If you’re feeling unchallenged or unmotivated to grow in your current role, maybe it’s time for a bold change, like relocating, to re-energize your career. But before you do, here are six things to keep in mind.
            </p>,
        quote: 'while more than 80% of people over age 45 dream of making a career change, only 6% actually do it',
        articleInfo: ReEnergizeYourCareer,
    },
    {
        header: "How to Relocate Solo—Without Doing It Alone",
        image: <Asset assetType="ARTICLE_MAIN_IMAGE" assetId="10"  maxWidth={1000} />,
        description:
            <p className="article-description">
                Relocating to a new city—especially if you’re doing it alone—might seem daunting, but it doesn’t have to be that way. At Johnson & Johnson, we believe that working in a new place is a great way to gain insight and perspective while also advancing your career, and so we do our best to support our teammates when they move.
                <br/><br/>If you’re single, here are three simple steps to help you relocate solo and thrive in a new role—without feeling like you’re doing it alone.
            </p>,
        quote: 'Before making the decision to relocate, analyze your budget and salary, and set aside some time to research the relative affordability of the state you plan to move to.',
        articleInfo: RelocateSolo,
    },
    {
        header: "3 Key Differences in Business Culture Around the U.S.",
        image: <Asset assetType="ARTICLE_MAIN_IMAGE" assetId="6"   maxWidth={1000}/>,
        description:
            <p className="article-description">
                Every country does business a little differently, and in a country the size of the United States, each region has its own distinctive quirks when it comes to business etiquette. What’s acceptable in meetings in New York City might not fly in, say, Mobile, Alabama. What’s more, the definition of "business casual" in Silicon Valley looks very different than it does in Boston. So, whether you're relocating for a new role or just traveling for business, it pays to know what's what in business cultures around the country.
            </p>,
        quote: 'Outsiders often relate that Northeasterners appear to be in a hurry.',
        articleInfo: KeyDifferences,
    }
];