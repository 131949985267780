import React from 'react';

import {CAROUSEL_NAMES} from "../constants";
import { EXTERNAL_LINKS } from 'consts';
import { onHistoryLinkClicked } from 'utils';
import TitleWithDescription from './TitleWithDescription';
import Contacts from './Contacts';
import Cards from './Cards';
import {
    Asset,
    Button,
    LinkButton,
} from 'components/common';
import { DescriptionPart as InitiationDescriptionPart, SirvaConnectPart } from './InitiationContents';
import chevronRedRight from 'components/common/Images/chevron-red-right.svg';

export default {
    [CAROUSEL_NAMES.LEARN_ABOUT] : (props) => ({
        left: {
            component: TitleWithDescription,
            props: {
                classNames: "padding-left padding-right",
                title: 'What type of move would you like to learn about?',
                subtitle: 'Fantastic!',
                description: 'Talent Mobility is a powerful tool in the development of Johnson & Johnson employees. Select a type of move and we’ll explain the process and your role along the way.'
            }
        },
        right: {
            component: Cards,
            props: {
                cards: [
                    {
                        icon: 'world',
                        action: () => {onHistoryLinkClicked({
                            action:'click-hero',
                            category:'profile',
                            label:"learn-international",
                            onClick: () => {props.history.push('/profile/international/explore')}
                        })},
                        primaryText: 'Global Experience',
                        secondaryText: 'I’d like to learn about',
                    },
                    {
                        icon: 'pin',
                        action: () => {onHistoryLinkClicked({
                        action:'click-hero',
                        category:'profile',
                        label:"learn-domestic",
                        onClick: () => props.history.push('/profile/domestic/explore')
                    })},
                        primaryText: 'Move within the U.S.',
                        secondaryText: 'I’d like to learn about',
                    }
                ],
            }
        }
    }),
    [CAROUSEL_NAMES.READY_TO_MOVE]: (props) => ({
        left: {
            component: TitleWithDescription,
            props: {
                classNames: "padding-left padding-right",
                title: 'What type of move do you need to initiate?',
                subtitle: 'Great!',
                description: 'We’ll need to ask you some questions about the strategic business or talent objectives that are prompting you to initiate the move of an employee. Having your Business or Talent Plan ready to hand will help you to select the right type of move.',
                additionalContent:
                    <div className="flexible">
                        <LinkButton
                            className="buttons"
                            text='Learn More About Moves'
                            icon={chevronRedRight}
                            onClick={() => {props.navigateTo(CAROUSEL_NAMES.LEARN_ABOUT)}}
                        />
                    </div>
            }
        },
        right: {
            component: Cards,
            props: {
                cards: [
                    {
                        icon: 'world',
                        action: () => {props.navigateTo(CAROUSEL_NAMES.READY_TO_MOVE_GLOBAL)},
                        primaryText: 'Global Experience',
                        secondaryText: 'I’d like to initiate a',
                    },
                    {
                        icon: 'pin',
                        action: () => {props.navigateTo(CAROUSEL_NAMES.READY_TO_MOVE_DOMESTIC)},
                        primaryText: 'Move within the U.S.',
                        secondaryText: 'I’d like to initiate a',
                    }
                ],
            }
        }
    }),
    [CAROUSEL_NAMES.READY_TO_MOVE_GLOBAL]: (props) => ({
        left: {
            component: InitiationDescriptionPart,
            props: {
                title: 'Before you begin, be sure you have the following:',
                subtitle: 'Ok, you want to initiate a Global Move!',
                requirmentsList: [
                    ['Host Position ID (OurSource)/Requisition ID (Workday)', 'Employee name', 'Job details (new salary, pay grade, job title, manager)'],
                    ['Funding/Finance contact', 'Home/host HR and manager', 'Assignment guide']
                ],
                additionalContentProps: {
                    label: 'View Initiation Checklist',
                    action: () => {new Asset({assetType:'move-initiation-checklist', assetId:'international'}).execute()}
                }
            }
        },
        right: {
            component: SirvaConnectPart,
            props: {
                connectButtonLabel: 'initiate-international',
                externalLinkAction: () => {props.history.push('profile/international/initiate-move')}
            }
        }
    }),
    [CAROUSEL_NAMES.READY_TO_MOVE_DOMESTIC]: (props) => ({
        left: {
            component: InitiationDescriptionPart,
            props: {
                title: 'Before you begin, be sure you have the following:',
                subtitle: 'Ok, you want to initiate a U.S. Domestic Move!',
                requirmentsList: [
                    ['Employee Name & Contact Info', 'New job title, salary, pay grade info'],
                    ['Relocation Package Details', 'Hiring manager & HR contact']
                ],
                additionalContentProps: {
                    label: 'View Initiation Checklist',
                    action: () => {new Asset({assetType:'move-initiation-checklist', assetId:'domestic'}).execute()}
                }
            }
        },
        right: {
            component: SirvaConnectPart,
            props: {
                connectButtonLabel: 'initiate-domestic',
                externalLinkAction: () => { props.history.push('profile/domestic/initiate-move')}
            }
        }
    }),
    [CAROUSEL_NAMES.CONTINUE]: () => ({
        left: {
            component: TitleWithDescription,
            props: {
                classNames: 'padding-left padding-right',
                title: 'You can continue your move initiation.',
                subtitle: 'No problem!',
                description: 'SIRVA Connect is our Move Initiation Portal. From this portal you can start or continue the move initiation process. You can also request pre-determination services. Pre-determination services can help an employee clarify questions prior to a definitive decision to accept a move opportunity.',
                additionalContent:
                    <Button
                        className="sirva-connect-button"
                        title="Open SIRVA Connect"
                        action='click-hero'
                        category='profile'
                        label="continue initiation"
                        externalLink={EXTERNAL_LINKS.SirvaConnect}
                    />,
                styles: { maxWidth: 850 }
            }
        }
    }),
    [CAROUSEL_NAMES.TALK]: () => ({
        left: {
            component: TitleWithDescription,
            props: {
                classNames: "padding-left padding-right",
                title: 'Mobility Connect is here to help.',
                subtitle: 'Thanks!',
                description: 'There are several ways to contact and obtain support regarding a move. Our Relocation Consultants will provide guidance on general inquiries, answer policy and initiation questions, review the move process, and direct you to other process partners as may be appropriate.'
            }
        },
        right: {
            component: Contacts,
            props: {}
        }
    })
};
