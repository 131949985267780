import React, { useEffect, useState } from "react";
import { Asset, Icon, RadioButton } from "components/common";
import { History } from "history";
// import { oneWayOrAssignmentOpts as moveOpts, oneWayOrAssignmentOpts } from "../keyMappings";
// import { WorkingWithRecKnockout, GlobalOneWayKnockout } from "../knockoutScreens";
import SubmitReqWD from "../ReqWD";
import AcceleratedDevelopment from "../reasons/AcceleratedDepelopment";
import HowLongAssignment from "./HowLongAssignment";
import PolicyDisplay from "../../Policy/PolicyDisplay";
import { LenOfAssignmentOpts, packageOpts } from "../../keyMappings";
import PayGradeSelect from "../paygrades";
import GetPolicy from "../../Policy/index2";
import { policyEnum } from "../../../policyMapping";

const FiftyPercent = (props: {
    // navigateTo: (to: string, from: string) => void;
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    const { state: locationState } = props.history.location;
    const { moveType, strategicIntent, empCategoryRepresentation } = locationState;
    const [moveOption, setMoveOption] = useState("");
  
    const clickHandler = (moveOptionKey: string): void => {
      props.history.push({
          state: {
              ...props.history.location.state,
              "fifty-percent": moveOptionKey,
              lengthOfAssignment: "NotSet",
              payGrade: "",
              policy: moveOptionKey === "yes" ? policyEnum.Commuter.key: "",
              policyTier: moveOptionKey === "yes" ? policyEnum.Commuter.key: ""
            },
      });
      setMoveOption(moveOptionKey);
  };
    useEffect(() => {
        setMoveOption("");
        props.disableContinueBtn(true);
    },[strategicIntent, empCategoryRepresentation]);

    useEffect(() => {
        props.disableContinueBtn(true);
    },[])

    useEffect(() => {
        moveOption === "" && props.disableContinueBtn(true);
        moveOption === "yes" && props.disableContinueBtn(false);
        moveOption === "no" &&
        locationState["strategic-indent"] === packageOpts.Global.BusinessCriticalNeeds.title &&
        props.disableContinueBtn(false);
    },[moveOption, locationState["strategic-indent"]]);

    // useEffect(() => {
    // },[moveOption]);

  const handleFiftyPercent = () => {
      switch(moveOption) {
        case "no":
            if(locationState["strategic-indent"] === "Accelerated Development")
                return (<PayGradeSelect disableContinueBtn={props.disableContinueBtn} history={props.history}/>);
            if(locationState["strategic-indent"] === "Skill Need" || locationState["strategic-indent"] === "Project")
                return (<HowLongAssignment disableContinueBtn={props.disableContinueBtn} history={props.history}/>)
            return (
                <GetPolicy 
                title={policyEnum.LongTermTier1.displayText} policyName={policyEnum.LongTermTier1.key}
                history={props.history} disableContinueBtn={props.disableContinueBtn} movePolicyType={"global"}/>)
        case "yes":
            return (<GetPolicy 
                title={policyEnum.Commuter.displayText} policyName={policyEnum.Commuter.key}
                history={props.history} disableContinueBtn={props.disableContinueBtn} movePolicyType={"global"}/>)
        default:
            return null;
      }
  }


    return (
        <div className="primary-reason-global">
            <div className="top">
                <div className="title"> Will the employee spend at least 50% of their time in their
                    home country?</div>
            </div>
            <div className="options">
                <div className="option">
                    <RadioButton
                        checked={moveOption === "yes"}
                        onChange={(): void => clickHandler("yes")}
                    />
                    <div className="titles">
                        <p className="title">Yes</p>
                    </div>
                </div>
                <div className="option">
                    <RadioButton
                        checked={moveOption === "no"}
                        onChange={(): void => clickHandler("no")}
                    />
                    <div className="titles">
                        <p className="title">No</p>
                    </div>
                </div>
            </div>
            {handleFiftyPercent()}
        </div>
    );
};

export default FiftyPercent;
