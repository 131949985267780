import React from "react";
import { Icon, SelectionCard } from "components/common";
import { RouterProps } from "types";
import OneWayMove from "../OneWayMove";
import WorldImage from "components/common/Images/world.svg";
import MovingImage from "components/common/Images/moving.svg";
import "./style.scss";
//import ReqOsScreen from "../ReqOS";
import ReqWdScreen from "../ReqWD";

const GlobalVSDomestic = (props: RouterProps) => {
    const clickHandler = (value: string, countryName: string, countryCode: string): void => {
        props.history.push({
            state: {
                ...props.location.state,
                "global-vs-domestic": value,
                moveType: value,
                countryName: countryName,
                countryCode: countryCode,
            },
        });
    };

    const backHandler = (): void =>
        props.history.push({
            state: {
                ...props.location.state,
                "pay-grade": "",
            },
        });

    if (
        props.location.state &&
        props.location.state["global-vs-domestic"] === "Domestic"
    ) {
        return (
            // <ReqOsScreen history={props.history} location={props.location} />
            <ReqWdScreen history={props.history} location={props.location} prevPage={"domestic"} />
        );
    } else if (
        props.location.state &&
        props.location.state["global-vs-domestic"] === "Global"
    ) {
        return <OneWayMove history={props.history} location={props.location} />;
    }

    return (
        <div className="people-leader-container">
            <div className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </div>
            <h1 className="section-title">
                Is this a move within the United States or is this a move across
                a global border?
            </h1>
            <section className="CardSection">
                <SelectionCard
                    type="imageSelectionCard"
                    backgroundColor="white"
                    icon={MovingImage}
                    title="Moves within the U.S."
                    content="The employee is moving from one U.S. location to another U.S. location"
                    className="global-vs-domestic"
                    onClick={() => clickHandler("Domestic","United States","US")}
                />
                <SelectionCard
                    type="imageSelectionCard"
                    backgroundColor="white"
                    icon={WorldImage}
                    title="Global Move"
                    content="The employee is moving from one country to another country"
                    className="global-vs-domestic"
                    onClick={() => clickHandler("Global","" ,"")}
                />
            </section>
        </div>
    );
};

export default GlobalVSDomestic;
