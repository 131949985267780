import React from 'react';

import { withFormsy } from 'formsy-react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider} from "material-ui-pickers";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import Indigo from "@material-ui/core/colors/indigo";

import { DatePicker } from '../elements';
import { ValidationContext } from '../ValidatableForm';
import { getRequiredLabel, getLabel } from '../utils';

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            display: "none"
        },
        MuiPickersDay: {
            day: {
                color: Indigo["900"],
            },
            isSelected: {
                '& > span:first-child': {
                    color: "white"
                },
                backgroundColor: Indigo["900"],
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: Indigo["400"],
            },
        },
    },
});

class FormsyDatePicker extends React.Component {

    handleChange = (date) => {
        const value = date.format(this.props.format || 'L');
        this.props.setValue(value);
        this.props.onChange && this.props.onChange(value);
    };

    componentWillMount() {
        if(this.props.defaultValue) {
            this.props.setValue(this.props.defaultValue);
        }
    }


    render() {
        const { props } = this;
        const {
            inline = true,
            nativeProps
        } = props;

        const errorMessages = this.props.getErrorMessages();
        const label = props._required ? getRequiredLabel(props.label) : getLabel(props.label);
        const defaultValue = props.defaultValue ? moment(props.defaultValue, props.format).format("YYYY-MM-DD") : undefined;

        return (
            <ValidationContext.Consumer>
                {(showValidationMessages) => (
                    <MuiThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                onChange={this.handleChange}
                                dataKey={props.dataKey}
                                errorMessages={errorMessages}
                                helperText={props.helperText}
                                defaultValue={defaultValue}
                                label={label}
                                minDate={props.minDate}
                                maxDate={props.maxDate}
                                showValidationMessages={showValidationMessages}
                                InputProps={props.InputProps}
                                formatToShow={this.props.formatToShow}
                                inline={inline}
                                nativeProps={nativeProps}
                            />
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                )}
            </ValidationContext.Consumer>
        );
    }
}

export default withFormsy(FormsyDatePicker);
