// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { getUserProfile } from 'selectors';
import { BalanceSheetsAPI } from 'api';
import { Button } from 'components/common';
import './styles.scss';

type AllowancesAndDeductionsProps = {
    userProfile: Object,
};

type AllowancesAndDeductionsState = {
    lastBalanceSheetDate: string,
    hideBalanceSheet: boolean,
};

const mapStateToProps = (state) => ({
    userProfile: getUserProfile(state)
});

class AllowancesAndDeductions extends React.Component<AllowancesAndDeductionsProps, AllowancesAndDeductionsState> {

    state = {
        lastBalanceSheetDate: '',
        hideBalanceSheet: true
    };

    componentWillMount() {
        const { currentRelocationId } = this.props.userProfile;
        (currentRelocationId && this.props.type === 'international') && this.getRecentBalanceSheet(currentRelocationId);
    }

    componentWillReceiveProps(nextProps: AllowancesAndDeductionsProps) {
        const { currentRelocationId} = nextProps.userProfile;
        if( !this.props.userProfile.currentRelocationId && currentRelocationId && (nextProps.type === 'international')) {
            this.getRecentBalanceSheet(currentRelocationId);
        }
    }

    getRecentBalanceSheet = (currentRelocationId: string): void => {
        BalanceSheetsAPI.getRecentBalanceSheet(currentRelocationId)
            .then(resp => {
                if(resp && resp.result) {
                    resp.result.asOfDate && this.setState({
                        lastBalanceSheetDate: resp.result.asOfDate,
                        hideBalanceSheet: false,
                    })
                }
            })
    };

    navigateToPolicies = (): void => {
        this.props.history.push('/resources');
    };

    navigateToBalanceSheets = (): void => {
        this.props.history.push('/my-documents/balanceSheets')
    };

    render() {
        const { type } = this.props;
        const isDomestic = (type === 'domestic');
        const hideBalanceSheet = (!isDomestic && this.state.hideBalanceSheet);
        return (
            <div className={`AllowancesAndDeductions flexible grow ${type}`}>
                <h1 className="ad_header">{ isDomestic ? 'Policy Details' : 'Your Assignment Compensation Allowances & Deductions'}</h1>
                <div className="ad_content flexible grow horizontal jBetween">
                    <div className={`left_block flexible vertical ${hideBalanceSheet ? 'fullWidth' : ''}`}>
                        {
                            isDomestic ?
                                <p>Please take the time to read your policy details carefully and take full advantage of the assistance that is available to help you make plans and manage your personal move. Planning your move with a clear understanding of the benefits and potential non-reimbursable expenses will also help to avoid unpleasant surprises such as non-reimbursable costs. </p>
                                : <p>J&J uses a compensation methodology referred to as the balance sheet approach. The basic aim of the balance sheet is to maintain the same standard of living in the host location that the global assignee enjoys in the home location prior to accepting the global transfer. This approach is intended to ensure that you continue to remain neutral while on a global assignment.</p>
                        }
                    </div>
                    {
                        (hideBalanceSheet ? null :
                            <div className="right_block flexible jEnd">
                                <div className="flexible vertical noGrow jCenter">
                                    {
                                        !isDomestic &&
                                        <div>
                                            <span className="bs_date_header">Latest Balance Sheet Effective:</span>
                                            <p className="bs_date">{ moment(this.state.lastBalanceSheetDate).format("MMMM DD, YYYY")}</p>
                                        </div>
                                    }
                                    <Button
                                        title={!isDomestic ? 'View Balance Sheet Updates' : 'View Policy'}
                                        submit
                                        onClick={!isDomestic ? this.navigateToBalanceSheets : this.navigateToPolicies}
                                        dataKey="bs_updates"
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(AllowancesAndDeductions));
