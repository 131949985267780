import React  from 'react';

import Flag_Default_World from "components/common/Images/world.svg";

const CountryFlags = (props) => {
    
    const countryFlagsObj = {
        "US" : 'Flag_UnitedStates.png',
        "BG" : "Flag_Bulgaria.png",
        "MY" : "Flag_Malaysia.png",
        "IL" : "Flag_Isreal.png",
        "TW" : "Flag_Taiwan.png",
        "AL" : "Flag_Albania.png",
        "IT" : "Flag_Italy.png",
        "TH" : "Flag_Thailand.png",
        "DZ" : "Flag_Algeria.png",
        "JM" : "Flag_Jamaica.png",
        "TT" : "Flag_Trinidad.png",
        "AR" : "Flag_Argentina.png",
        "JP" : "Flag_Japan.png",
        "TR" : "Flag_Turkey.png",
        "AM" : "Flag_Armenia.png",
        "JO" : "Flag_Jordan.png",
        "UA" : "Flag_Ukraine.png",
        "AU" : "Flag_Australia.png",
        "KZ" : "Flag_Kazakhstan.png",
        "AE" : "Flag_UnitedArabEmirates.png",
        "EE" : "Flag_Estonia.png",
        "IE" : "Flag_Ireland.png",
        "PL" : "Flag_Poland.png",
        "CH" : "Flag_Switzerland.png",
        "CA" : "Flag_Canada.png",
        "FI" : "Flag_Finland.png",
        "MU" : "Flag_Mauritius.png",
        "PT" : "Flag_Portugal.png",
        "CL" : "Flag_Chile.png",
        "FR" : "Flag_France.png",
        "MX" : "Flag_Mexico.png",
        "PR" : "Flag_PuertoRico.png",
        "CN" : "Flag_China.png",
        "GE" : "Flag_Georgia.png",
        "MA" : "Flag_Morocco.png",
        "RO" : "Flag_Romania.png",
        "CO" : "Flag_Columbia.png",
        "DE" : "Flag_Germany.png",
        "NL" : "Flag_Netherlands.png",
        "RU" : "Flag_Russia.png",
        "CR" : "Flag_CostaRica.png",
        "GH" : "Flag_Ghana.png",
        "NZ" : "Flag_NewZealand.png",
        "SA" : "Flag_SaudiaArabia.png",
        "HR" : "Flag_Croatia.png",
        "GR" : "Flag_Greece.png",
        "NG" : "Flag_Nigeria.png",
        "RS" : "Flag_Serbia.png",
        "AT" : "Flag_Austria.png",
        "CZ" : "Flag_CzechRepublic.png",
        "GT" : "Flag_Guatemala.png",
        "KE" : "Flag_Kenya.png",
        "NO" : "Flag_Norway.png",
        "SG" : "Flag_singapore.png",
        "GB" : "Flag_UnitedKingdom.png",
        "AZ" : "Flag_Azerbaijan.png",
        "DK" : "Flag_Denmark.png",
        "HN" : "Flag_Honduras.png",
        "KP" : "Flag_Korea.png",
        "PK" : "Flag_Pakistan.png",
        "SK" : "Flag_Slovakia.png",
        "BY" : "Flag_Belarus.png",
        "DO" : "Flag_DominicanRepublic.png",
        "HK" : "Flag_HongKong.png",
        "KW" : "Flag_Kuwait.png",
        "PA" : "Flag_Panama.png",
        "SI" : "Flag_Slovenia.png",
        "UY" : "Flag_Uruguay.png",
        "BE" : "Flag_Belgium.png",
        "EC" : "Flag_Ecuador.png",
        "HU" : "Flag_Hungary.png",
        "LV" : "Flag_Latvia.png",
        "PY" : "Flag_Paraguay.png",
        "ZA" : "Flag_SouthAfrica.png",
        "VE" : "Flag_Venezuela.png",
        "BA" : "Flag_Bosnia.png",
        "EG" : "Flag_Egypt.png",
        "IN" : "Flag_India.png",
        "LB" : "Flag_Lebonan.png",
        "PE" : "Flag_Peru.png",
        "ES" : "Flag_Spain.png",
        "VN" : "Flag_Vietnam.png",
        "BR" : "Flag_Brazil.png",
        "SV" : "Flag_ElSavador.png",
        "LT" : "Flag_Lithuania.png",
        "PH" : "Flag_Phillipines.png",
        "SE" : "Flag_Sweden.png",
        "ZM" : "Flag_Zambia.png",
        "ID" : "Flag_Indonsia.png",
    }

    const countryNameFlagObj = {
        "United States of America": "Flag_UnitedStates.png",
        "Brazil": "Flag_Brazil.png",
        "Canada": "Flag_Canada.png",
        "South africa": "Flag_SouthAfrica.png",
    }
    const countryCode = (props.countryCode !== undefined && props.countryCode !== null )? props.countryCode : null;
    const countryName = (props.countryName !== undefined && props.countryName !== null )? props.countryName : null;
    
    if(countryCode !== null) {
        const imgAlt = (countryCode !== null ) ? countryCode : '';
        const countryFlag = (countryFlagsObj[countryCode] !== undefined && countryFlagsObj[countryCode] !== null )? countryFlagsObj[countryCode]: null;
        if( countryFlag !== null) {
            return (<img src={require("./images/"+countryFlag)} alt={imgAlt} className="countryFlag-img" />  );
        } else {
            return (<img src={Flag_Default_World} alt={imgAlt} className="countryFlag-img" />  );
        }
    }
    if(countryName !== null) {
        const imgFlagAlt = (countryName !== null ) ? countryName : '';
        const countrynameFlag = (countryNameFlagObj[countryName] !== undefined && countryNameFlagObj[countryName] !== null )? countryNameFlagObj[countryName]: null;
        if( countrynameFlag !== null) {
            return (<img src={require("./images/"+countrynameFlag)} alt={imgFlagAlt} className="countryFlag-img" />  );
        } else {
            return (<img src={Flag_Default_World} alt={imgFlagAlt} className="countryFlag-img" />  );
        }
    }
    return null;
};

export default CountryFlags;