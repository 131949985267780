import React, { Component } from "react";

import CarouselMain from "./CarouselMain";
import HrMainTopContainer from "./HrMainTopContainer";

import { CAROUSEL_NAMES } from "./constants";

export default class MainCarousel extends Component {
    state = {
        currentCarouselItem: CAROUSEL_NAMES.MAIN,
    };

    onChangeCarousel = toName => {
        this.setState({
            currentCarouselItem: toName,
        });
    };

    render() {
        const { userName } = this.props;
        const { currentCarouselItem } = this.state;

        if (currentCarouselItem === CAROUSEL_NAMES.MAIN) {
            return (
                <CarouselMain
                    userName={userName}
                    onChangeCarousel={this.onChangeCarousel}
                    newFlow={this.props.newFlow}
                    history={this.props.history}
                />
            );
        }
        return (
            <HrMainTopContainer
                type={currentCarouselItem}
                navigateTo={this.onChangeCarousel}
            />
        );
    }
}
