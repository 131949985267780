// @flow
import React from 'react';
import { FeedbackAPI } from 'api';
import { connect } from 'react-redux';
import { getUserProfile } from 'selectors';

import { Popup } from 'components/sections';


import { HelpComponent, Button } from 'components/common';
import happy from '../../../common/Images/feedback_happy.gif';
import neutral from '../../../common/Images/feedback_neutral.gif';
import sad from '../../../common/Images/feedback_sad.gif';
import x from '../../../common/Images/x.svg';
import approval from '../../../common/Images/approval.svg';

import './style.scss';

const mapStateToProps = state => ({
    user: getUserProfile(state),
});

class Feedback extends React.Component {

    satisfactionLevels=['Skipped', 'Sad', 'Neutral', 'Happy'];

    feedback = {
        satisfactionLevel: '',
        comments: 'None',
        contactMe: true,
        feedbackFormUrl: '',
        policyName: '',
        policyId: '',
        isGlobal: !this.props.user.currentRelocationIsDomestic,
    };

    onSelectExperience = (e) => {
        let selected = e.currentTarget.dataset.id;
        // Save the state of satisfactionLevel in parent controller
        this.props.modalController.setSatisfactionLevel(this.satisfactionLevels[selected]);
        this.props.modalController.setActive('comments');
    }

    onTextChange = (e) => {
        this.feedback.comments = e.target.value;
    }

    onChecked = () => {
        this.feedback.contactMe = this.refs.contact_checkbox.checked;
    };

    onSend = () => {
        if (this.props.user.currentRelocationId == null) {
            alert('You have not active relocation');
            return null;
        }
        // Get the saved state of satisfactionLevel from parent controller
        this.feedback.satisfactionLevel=this.props.modalController.getSatisfactionLevel();
        this.feedback.feedbackFormUrl=window.location.href;
        this.writeFeedback(this.props.user.currentRelocationId, this.feedback);
        this.props.modalController.setActive('thankyou');
    }


    writeFeedback(relocationId, feedback) {
        FeedbackAPI
            .sendFeedback(relocationId, feedback)
            .then((data) => {
                this.props.modalController.setSubmitted();
                }
            )
            .catch((err) => {
                alert("Something went wrong and your feedback was not submitted.  Please contact your Sirva Consultant directly");
            });
    }

    renderSwitch(param) {
        switch(param) {
            case 'experience': {
                return (
                    <Popup onClose={this.props.modalController.onCloseModal} className="fast-feedback-popup">
                        <div className="Feedback">
                            <div className="contact">
                                <HelpComponent mode="feedback"/>
                            </div>
                            <div className="right">
                                <img src={x} className="close pointer" alt="close" onClick={this.props.modalController.onCloseModal}/>
                                <div className="content">
                                    <p className="user-name">
                                        Hi {this.props.user.userName},
                                    </p>
                                    <p className="text">
                                        How are you feeling?
                                    </p>
                                    <ul>
                                        <li data-id="3" onClick={this.onSelectExperience} style={{ backgroundImage: `url(${happy})` }}/>
                                        <li data-id="2" onClick={this.onSelectExperience} style={{ backgroundImage: `url(${neutral})` }}/>
                                        <li data-id="1" onClick={this.onSelectExperience} style={{ backgroundImage: `url(${sad})` }}/>
                                    </ul>
                                    <p className="pointer" >
                                        <span onClick={this.props.modalController.onCloseModal}>
                                            Skip For Now
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Popup>
                );
            }
            case 'comments': {
                return (
                    <Popup onClose={this.props.modalController.onCloseModal}>
                        <div className="Feedback">
                            <div className="contact">
                                <HelpComponent mode="feedback"/>
                            </div>
                            <div className="right comments">
                                <img src={x} className="close pointer" onClick={this.props.modalController.onCloseModal} alt="close"/>
                                <div className="content">
                                    <p className="user-name">Okay, I'd love to know more...</p>
                                    <p className="text">Would you like to share some details?</p>
                                    <p className="optional">Optional Comments</p>
                                    <textarea rows="4" cols="50" onChange={this.onTextChange} />
                                    <div className="list-item">
                                        <label htmlFor="comment" className="flexible aCenter grow">
                                            <input
                                                type="checkbox"
                                                id="comment"
                                                onChange={this.onChecked}
                                                defaultChecked={true}
                                                ref="contact_checkbox"
                                            />
                                            <div className="flexible grow">
                                            <span>
                                                Please contact me within the next business day to discuss
                                            </span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="flexible jEnd">
                                        <Button
                                            onClick={this.onSend}
                                            title="Send"
                                            submit
                                            className="comments"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popup>
                );
            }
            default: {
                return (
                    <Popup onClose={this.props.modalController.onCloseModal}>
                        <div className="Feedback flexible aCenter jCenter thankyou">
                            <div className="left">
                                <img src={approval} alt="Approval" />
                            </div>
                            <div className="right">
                                <img src={x} className="close pointer" onClick={this.props.modalController.onCloseModal} alt="close"/>
                                <div className="content">
                                    <p className="user-name">Thank you!</p>
                                    <p className="text">Your feedback will help me continue to improve your move experience and will help others too.</p>
                                </div>
                            </div>
                        </div>
                    </Popup>
                );
            }
        }
    }

    render() {
        if (!this.props.modalController.getShowModal()) {
            return null;
        }

        return this.renderSwitch(this.props.mode);
    }
}


export default connect(mapStateToProps, {})(Feedback);
