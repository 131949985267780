import { MoveDetailsStepper } from "types";

const stepperData: Array<MoveDetailsStepper> = [
    {
        id: 1,
        description: "Request Overview",
        active: true,
        isComplete: false,
        key: "request-overview",
    },
    {
        id: 2,
        description: "Policy Selection",
        active: false,
        isComplete: false,
        key: "policy-selection",
    },
    {
        id: 3,
        description: "Job Details",
        active: false,
        isComplete: false,
        key: "job-details",
    },
    {
        id: 4,
        description: "Finance",
        active: false,
        isComplete: false,
        key: "finance",
    },
    {
        id: 5,
        description: "Approvals",
        active: false,
        isComplete: false,
        key: "approvals",
    },
    {
        id: 6,
        description: "Review & Submit",
        active: false,
        isComplete: false,
        key: "review-submit",
    },
];

export default stepperData;
