import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
    Icon,
    Button,
    CurrencyFormatter,
    SpinnerWrapper,
    CountryFlags,
    TextCaseFormatter,
  } from "components/common";
import { moveInitiationService } from "services";
import { getEmpPosJobDetails } from "selectors";
import { MoveStepLayout } from "components/moveInitiationLayouts";
import "./style.scss";

const mapStateToProps = (state: any) => ({
    empPosJobDetails: getEmpPosJobDetails(state),
});

const mapDispatchToProps = {
    fetchEmpJobDetailsPosId: moveInitiationService.actions.fetchEmpJobDetailsPosId,
    setMoveEmppJobDetails: moveInitiationService.actions.setMoveEmppJobDetails
};

var empInfoPosChildEle = null;
var positionInfoChildEle = null;

class EmployeePositionDetailsReview extends PureComponent<RouteComponentProps & {fetchEmpJobDetailsPosId,setMoveEmppJobDetails}>{
    state = {
        empJobDetails: {},
        empPosJobDetails: {},
        empInfoRepChildEle: null,
        reqInfoRepChildEle: null,
        empInfoPosChildEle: null,
        positionInfoChildEle: null,
        isLoading: false,
    };

    saveEmployeejobDetails = (location) => {
        this.setState({isLoading: true});
        let posInfo = this.props.location.state.positionInfoChildEle != null ? this.props.location.state.positionInfoChildEle : null;
        const dataSet = {
            "initiationId": (this.props.location.state.initiationId !== undefined) ? this.props.location.state.initiationId : '',
            "wwid": (this.props.location.state.empInfoPosChildEle !== undefined) ? this.props.location.state.empInfoPosChildEle.employeeNumber : '',
            "requisitionId": null,
            "positionId": (this.props.location.state.posID !== undefined) ? this.props.location.state.posID : '',
            "employeeInfo": null,
            "reqInfo": null,
            "osPosInfo": posInfo, 
        }

        this.props
                .setMoveEmppJobDetails(dataSet)
                    .then(data => {
                        this.setState({isLoading: false});
                       if(data.success) {
                            this.navigateTo(location);
                       } else {
                            console.log(data.message);
                       }
                    })
                    .catch(err => console.log(err));
        
    }

    componentDidMount(): void {
        if(this.props.empPosJobDetails === null && ( typeof this.props.location.state === undefined || Object.keys(this.props.location.state).length == 0) ) {
            if(this.props.location.state.employeeInfo === null || this.props.location.state.employeeInfo === undefined ) {
                this.navigateTo("returndashboard");
            }
            this.navigateTo("returndashboard");
        }

        if(typeof this.props.location.state !== undefined) {
            if(this.props.location.state.empInfoPosChildEle !== undefined && this.props.location.state.positionInfoChildEle !== undefined) {
                empDetails = {}
                empInfoPosChildEle = (this.props.location.state.empInfoPosChildEle != null && typeof this.props.location.state.empInfoPosChildEle !== 'undefined') ? 
                                        this.props.location.state.empInfoPosChildEle : null;
                positionInfoChildEle = (this.props.location.state.positionInfoChildEle != null && typeof this.props.location.state.positionInfoChildEle !== 'undefined') ? 
                                        this.props.location.state.positionInfoChildEle : null;
            } else {
                this.setState({
                            empInfoRepChildEle: null,
                            reqInfoRepChildEle: null
                        });
                var empInfo = (this.props.location.state.employeeInfo !== undefined && this.props.location.state.employeeInfo !== null ) ? this.props.location.state.employeeInfo : null;  
                var empInfoReport = (empInfo != null && empInfo.hasOwnProperty('empNJobDet')) ? 
                                    empInfo.empNJobDet : null;
                empInfoPosChildEle = (empInfoReport != null && empInfoReport.hasOwnProperty('empDetails')) ?
                                    empInfoReport.empDetails : null;
                                    
                var empDetails = (this.props == null) ? null : (this.props.hasOwnProperty('empPosJobDetails') ? this.props.empPosJobDetails : null);
                var posInfoReport = (empDetails != null && empDetails.hasOwnProperty('position')) ? empDetails.position : null;
                positionInfoChildEle = (posInfoReport != null && typeof posInfoReport !== 'undefined') ? posInfoReport : null;
            }
        } 
        
        if(empDetails !== null && empInfoPosChildEle !== null && positionInfoChildEle !== null ) {
            this.setState({
                empInfoPosChildEle, positionInfoChildEle
            });
            this.props.history.push({
                state: {
                    ...this.props.location.state, empInfoPosChildEle, positionInfoChildEle,
                },
            });
        }
    }

    navigateTo = (location: string) => {
        switch (location) {
            case 'continue':
                this.saveEmployeejobDetails("yesandcontinue");
                break;
            case 'returnhome':
                this.saveEmployeejobDetails("returndashboard");
                break;
            case 'startover':
                let backLocation = "/initiate-move";
                if(this.props.location.state.moveType === "Domestic" ) {
                    if(this.props.location.state.policySelection == "ShortTerm") {
                        backLocation = "/move-dashboard";
                    }  
                }
                this.props.history.push(
                    backLocation,{...this.props.location.state}
                );
                break;
            case 'backstate':
                    this.props.history.push(
                        "/initiate-move/employee-job-details",{...this.props.location.state}
                    );
                    break; 
            case 'returndashboard':
                    this.props.history.push(
                        "/move-dashboard"
                    );
                    break;
            case 'yesandcontinue':
                    this.props.history.push(
                        "/initiate-move/additional-move-details",{...this.props.location.state}
                    );
                    break;
        }
    };

    render() {
        let { isLoading} = this.state;

        return (
            <section className="emp-job-review-container">
                <div className="RecruitedSelectorContainer">
                    <MoveStepLayout selected={2} />
                    <SpinnerWrapper show={isLoading}>
                    <div className="employee-job-review-container">
                        <div
                        className="back-icon"
                        onClick={() => {
                            this.navigateTo("backstate");
                        }}
                        >
                        <Icon name="backIcon" />
                        <p>Go back</p>
                        </div>
                        <section className="header-section">
                            <h1 className="section-title">
                            We’ve found your employee & their new job. Is this correct?
                            </h1>
                        </section>
                        <section className="body-section">
                        <div className="employee-details">
                            <div className="top-section">
                                <h2>Employee: <span>{empInfoPosChildEle!= null ? TextCaseFormatter(empInfoPosChildEle.firstName) + ((empInfoPosChildEle.middleName !== null)? TextCaseFormatter(empInfoPosChildEle.middleName) + '':'') + ' ' + TextCaseFormatter(empInfoPosChildEle.lastName) : null}</span></h2>
                            </div>
                            <div className="bottom-section">
                                <div className="first">
                                    <ul className="icon-section">
                                        <li className="review-flag-icon">
                                            <div className="icon-block">
                                                <CountryFlags countryCode={empInfoPosChildEle!= null ? empInfoPosChildEle.countryCode : null} countryName={null} />
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="location-section">
                                        <li className="title">Home Country:</li>
                                        <li className="desc">{empInfoPosChildEle!= null ? TextCaseFormatter(empInfoPosChildEle.addresses.country) : null}</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="title">Current Office:</li>
                                        <li className="desc">
                                        {empInfoPosChildEle != null && ((empInfoPosChildEle.addresses.addressLine1 !== null && empInfoPosChildEle.addresses.addressLine1 !== "") ||
                                        (empInfoPosChildEle.addresses.addressLine2 !== null && empInfoPosChildEle.addresses.addressLine2 !== "") ||  
                                        (empInfoPosChildEle.addresses.city !== null && empInfoPosChildEle.addresses.city !== "") || 
                                        (empInfoPosChildEle.addresses.stateProvince !== null && empInfoPosChildEle.addresses.stateProvince !== "") || 
                                        (empInfoPosChildEle.addresses.postalCode !== null && empInfoPosChildEle.addresses.postalCode !== "") ) ?
                                            <>
                                                {empInfoPosChildEle.addresses.addressLine1 !== null && empInfoPosChildEle.addresses.addressLine1 !== "" ? (
                                                    <>
                                                        {TextCaseFormatter(empInfoPosChildEle.addresses.addressLine1)} <br />
                                                    </>
                                                    ) : null}
                                                {empInfoPosChildEle.addresses.addressLine2 !== null && empInfoPosChildEle.addresses.addressLine2 !== "" ? (
                                                    <>
                                                        {TextCaseFormatter(empInfoPosChildEle.addresses.addressLine2)}
                                                    </>
                                                    ) : null}
                                                {empInfoPosChildEle.addresses.city !== null && empInfoPosChildEle.addresses.city !== "" ? (
                                                    <>
                                                        {TextCaseFormatter(empInfoPosChildEle.addresses.city)} 
                                                    </>
                                                    ) : null}
                                                {empInfoPosChildEle.addresses.stateProvince !== null && empInfoPosChildEle.addresses.stateProvince !== "" && empInfoPosChildEle.addresses.stateProvince != undefined ? (
                                                        <>
                                                            , {(empInfoPosChildEle.addresses.stateProvince).length <= 2 ? TextCaseFormatter(empInfoPosChildEle.addresses.stateProvince,"uper-case") : TextCaseFormatter(empInfoPosChildEle.addresses.stateProvince, "sentence-case")}
                                                        </>
                                                        ) : null}
                                                {empInfoPosChildEle.addresses.postalCode !== null && empInfoPosChildEle.addresses.postalCode !== "" ? 
                                                '/' + empInfoPosChildEle.addresses.postalCode
                                                : null }
                                            </>
                                            : null }
                                        </li>
                                        <li className="slary title">Current Salary:</li>
                                        <li className="slary-amount desc">
                                            {(empInfoPosChildEle!= null && empInfoPosChildEle.annualizedCurrency !== undefined ) ? (
                                                <CurrencyFormatter 
                                                    currencyCode={empInfoPosChildEle.annualizedCurrency} 
                                                    amount={empInfoPosChildEle.annualizedAmount} 
                                                />
                                            ) : null}
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="manager-email title">Current Job Title:</li>
                                        <li className="manager-emaildetails desc">{empInfoPosChildEle!= null ? TextCaseFormatter(empInfoPosChildEle.jobTitle) : null}</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="manager-email title">WWID:</li>
                                        <li className="manager-emaildetails desc">{empInfoPosChildEle!= null ?empInfoPosChildEle.employeeNumber : null}</li>
                                    </ul>
                                </div>
                                <div className="last">
                                    <ul>
                                        <li className="manager-wwid title">Pay grade:</li>
                                        <li className="manager-wwiddetails desc">
                                            {(empInfoPosChildEle != null && typeof empInfoPosChildEle.compensationGrade !== 'undefined') ? empInfoPosChildEle.compensationGrade : null}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="bottom-section">
                                <div className="first">
                
                                </div>
                                <div>
                                    <ul>
                                        <li className="manager title">Home Manager:</li>
                                        <li className="manager-name desc">{empInfoPosChildEle!= null ? TextCaseFormatter(empInfoPosChildEle.manager) : null}</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="manager-email title">Home Manager Email:</li>
                                        <li className="manager-emaildetails desc">{empInfoPosChildEle!= null ? TextCaseFormatter(empInfoPosChildEle.managerEmail, "lower-case") : null}</li>
                                    </ul>
                                </div>
                                <div className="last">
                                    <ul>
                                        <li className="manager-wwid title">Home Manager WWID:</li>
                                        <li className="manager-wwiddetails desc">{empInfoPosChildEle!= null ?empInfoPosChildEle.homeManagerWWID : null}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="new-job-details">
                            <div className="top-section">
                                <h2>New job:</h2>
                            </div>
                            <div className="bottom-section">
                                <div className="first">
                                    <ul className="icon-section">
                                        <li className="review-flag-icon">
                                            <div className="icon-block">
                                                <CountryFlags countryCode={positionInfoChildEle!= null ? positionInfoChildEle.currentWorkCountryCode : null} countryName={null} />
                                            </div>
                                        </li>
                                    </ul>
                                    {positionInfoChildEle != null && (positionInfoChildEle.currentWorkCountryCode).toLowerCase() == "na" && (positionInfoChildEle.currentWorkCountry).toLowerCase() == "zimbabwe" ? null :
                                        <ul className="location-section">
                                            <li className="title">Host Country:</li>
                                            <li className="desc">{positionInfoChildEle != null ? TextCaseFormatter(positionInfoChildEle.currentWorkCountry) : null}</li>
                                        </ul>
                                    }
                                </div>
                                <div>
                                    <ul>
                                        <li className="title">New Office:</li>
                                        <li className="desc">
                                            {positionInfoChildEle != null && (positionInfoChildEle.currentWorkaddress1 !== null || positionInfoChildEle.currentWorkCity !== null || positionInfoChildEle.currentWorkState !== null || positionInfoChildEle.currentWorkZipCode !== null) ?
                                            <>
                                                {positionInfoChildEle.currentWorkaddress1 !== null && positionInfoChildEle.currentWorkaddress1 !== "" ? (
                                                    <>
                                                        {TextCaseFormatter(positionInfoChildEle.currentWorkaddress1)} <br />
                                                    </>
                                                    ) : null}
                                                {positionInfoChildEle.currentWorkCity !== null && positionInfoChildEle.currentWorkCity !== "" ? (
                                                    <>
                                                        {TextCaseFormatter(positionInfoChildEle.currentWorkCity)} 
                                                    </>
                                                    ) : null}
                                                {positionInfoChildEle.currentWorkState !== null && positionInfoChildEle.currentWorkState !== "" && positionInfoChildEle.currentWorkState != undefined ? (
                                                        <>
                                                            , { (positionInfoChildEle.currentWorkState).length <= 2 ? TextCaseFormatter(positionInfoChildEle.currentWorkState,"uper-case") : TextCaseFormatter(positionInfoChildEle.currentWorkState, "sentence-case")} 
                                                        </>
                                                        ) : null}
                                                {positionInfoChildEle.currentWorkZipCode !== null && positionInfoChildEle.currentWorkZipCode !== "" ? 
                                                '/' + positionInfoChildEle.currentWorkZipCode
                                                : null }
                                            </>
                                            : null }
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="title">New Job Title:</li>
                                        <li className="desc">{positionInfoChildEle != null ? TextCaseFormatter(positionInfoChildEle.jobTitle) : null}</li>
                                    </ul>
                                </div>
                                <div >
                                    <ul>
                                        <li className="title">New Pay Grade:</li>
                                        <li className="desc">
                                            {(positionInfoChildEle != null && positionInfoChildEle != "" && typeof positionInfoChildEle.payGrade !== 'undefined') ? TextCaseFormatter(positionInfoChildEle.payGrade) : null}
                                        </li>
                                    </ul>
                                </div>
                                <div className="last">
                                    <ul>
                                        <li className="title">Job ID:</li>
                                        <li className="desc">{positionInfoChildEle != null ? positionInfoChildEle.position : null}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="bottom-section">
                                <div className="first">

                                </div>
                                <div>
                                    <ul>
                                        <li className="manager title">Host Manager:</li>
                                        <li className="manager-name desc">{positionInfoChildEle != null ? TextCaseFormatter(positionInfoChildEle.managerName) : null}</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="manager-email title">Host Manager Email:</li>
                                        <li className="manager-emaildetails desc">{positionInfoChildEle != null ? TextCaseFormatter(positionInfoChildEle.managerEmail,"lower-case") : null}</li>
                                    </ul>
                                </div>
                                <div >
                                    <ul>
                                        <li className="manager-wwid title">Host Manager WWID:</li>
                                        <li className="manager-wwiddetails desc">{positionInfoChildEle != null ? positionInfoChildEle.managerWWID : null}</li>
                                    </ul>
                                </div>
                                <div className="last">

                                </div>
                            </div>
                        </div>
                        </section> 
                        <section className="button-section">
                            <div className="button-wrapper">

                                <Button
                                title="Start Over"
                                className="start-button"
                                onClick={() => {
                                    this.navigateTo("startover");
                                }}
                                style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", width:"160px"}}
                                labelStyle={{color:"#CA001B"}}
                                />
                                <Button
                                title="Save and Return to Home"
                                className="save-button"
                                onClick={() => {
                                    this.navigateTo("returnhome");
                                }}
                                style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", width:"205px"}}
                                labelStyle={{color:"#CA001B"}}
                                />
                                <Button
                                title="No, something's wrong"
                                className="wrong-button"
                                onClick={() => {
                                    this.navigateTo("backstate");
                                }}
                                style={{backgroundColor:"#CA001B", border:"1px solid #CA001B", width:"205px"}}
                                labelStyle={{color:"#FFFFFF"}}
                                />
                                <Button
                                title="Yes, continue"
                                className="submit-button"
                                onClick={() => {
                                    this.navigateTo("continue");
                                }}
                                style={{backgroundColor:"#CA001B", border:"1px solid #CA001B", width:"169px"}}
                                labelStyle={{color:"#FFFFFF"}}
                                />
                            </div>
                        </section>
                    </div>
                    </SpinnerWrapper>             
                </div>    
                </section>
        );
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EmployeePositionDetailsReview));
