import * as React from "react";
import { RouterProps } from "types";
import EligibleMoveShort from "./eligibleMoveShort";
import "./styles.scss";

const shortTerm = (props: RouterProps) => {
    const rows = [
        ["Pre-Assignment", "20%", "One time at the start", "Charges as services incurred"],
        ["On Assignment", "25%", "Each year", "Charges as services incurred per year"],
        ["Post Assignment", "20%", "One time after end of assignment", "Charges as services incurred post assignment"],
        ["Tax on assignment", "15%", "Each year of assignment", "Charges as services incurred per year"],
        ["Tax Post Assignment", "20%", "One time after end of assignment", "Within 3 months of close of assignment"],
    ];

    const cols = [
        { title: "" },
        { title: "% of salary" },
        { title: "Budget Allocation" },
        { title: "Timing of actual charges" },
    ];

    return (
        <EligibleMoveShort
            eligibleTitle="Short Term"
            policyName="ShortTerm"
            moveType="Global"
            strategicIndent={props.location.state["strategic-indent"] || "N/A"}
            assignmentLength="12 months or less"
            backState="assignment"
            cols={cols}
            rows={rows}
            assetId="short-term"
            url="/initiate-move/employee-job-details"
            {...props}
        />
    );
};

export default shortTerm;
