import React, { PureComponent } from "react";
import "./styles.scss";
import Select from "./Select";
import {
    generalMessages,
    familySizeValues,
} from "./mockData";
import { Icon, Button } from "components/common";
import Tooltip from "@material-ui/core/Tooltip";
import { RouteComponentProps, withRouter } from "react-router";
import { getLeaderShipPrograms, getCaliforniaTiers } from "selectors";
import { connect } from "react-redux";
import { moveInitiationService } from "services";
import isEqual from "lodash.isequal";
import { JobDetailsProps, JobDetailsState } from "types";

const mapStateToProps = store => ({
    leaderShipPrograms: getLeaderShipPrograms(store),
    californiaTiers: getCaliforniaTiers(store),
});

const mapDispatchToProps = {
    fetchLeaderShipPrograms: moveInitiationService.actions.fetchLeaderShipPrograms,
    fetchCaliforniaTiers: moveInitiationService.actions.fetchCaliforniaTiers,
    setPreInitPackageDetails: moveInitiationService.actions.setPreInitPackageDetails,
};

class JobDetails extends PureComponent<RouteComponentProps & {
                fetchLeaderShipPrograms: () => Promise<any>;
                fetchCaliforniaTiers: () => Promise<any>;
                leaderShipPrograms: () => Promise<any>;
                californiaTiers: () => Promise<any>;
                setPreInitPackageDetails: (data: object) => Promise<any>;
                navigateTo: (to: string, from: string) => void;
            }, 
            JobDetailsProps, 
            JobDetailsState> {
    private interval: any;

    public readonly state = {
        currentMessage: -1,
        showContent: false,
        rotations: "",
        californiaTier: (this.props.history.location.state.californiaTier != null && typeof this.props.history.location.state.californiaTier !== 'undefined') ? 
                        this.props.history.location.state.californiaTier : null,
        newBaseSalary: (this.props.history.location.state.newBaseSalary != null && typeof this.props.history.location.state.newBaseSalary !== 'undefined') ? 
                        this.props.history.location.state.newBaseSalary : "",
        openTooltip: false,
        familySize: (this.props.history.location.state.familySize != null && typeof this.props.history.location.state.familySize !== 'undefined') ? 
                    this.props.history.location.state.familySize : "",
        valueInput: (this.props.history.location.state.newBaseSalary != null && typeof this.props.history.location.state.newBaseSalary !== 'undefined') ? 
                    this.props.history.location.state.newBaseSalary : "",
        leaderShipValues: [],
        californiaTiers: [],
        isLoading: false,
        disableButton: true,
        moveType: (this.props.history.location.state.moveType != null && typeof this.props.history.location.state.moveType !== 'undefined') ? 
                    this.props.history.location.state.moveType : null,
        positionLocation: "NotSet",
    };

    public readonly californiaPositionTire = {
        "ca": "california",
        "california": "california",
    }

    public componentDidMount(): void {
        this.interval = setInterval(this.showMessage, 1000);
        this.props.fetchLeaderShipPrograms();
        this.props.fetchCaliforniaTiers();

        let positionLocation = "NotSet";
        let goingToCity = (this.props.history.location.state.empCompetitiveInit.City != null && typeof this.props.history.location.state.empCompetitiveInit.City !== 'undefined') ? 
                            (this.props.history.location.state.empCompetitiveInit.City).toLowerCase() : null;
        let goingToState = (this.props.history.location.state.empCompetitiveInit.State != null && typeof this.props.history.location.state.empCompetitiveInit.State !== 'undefined') ? 
                                (this.props.history.location.state.empCompetitiveInit.State).toLowerCase() : null;
        let goingToCountry = (this.props.history.location.state.empCompetitiveInit.Country != null && typeof this.props.history.location.state.empCompetitiveInit.Country !== 'undefined') ? 
                                (this.props.history.location.state.empCompetitiveInit.Country).toLowerCase() : null; 
        let goingToPrimaryLocation = (this.props.history.location.state.empCompetitiveInit.primaryLocation != null && typeof this.props.history.location.state.empCompetitiveInit.primaryLocation !== 'undefined') ? 
                                (this.props.history.location.state.empCompetitiveInit.primaryLocation).toLowerCase() : null;                        
        if(positionLocation === "NotSet" && goingToCity !== null && this.californiaPositionTire[goingToCity] === "california") {
            positionLocation = "california";
        }else if(positionLocation === "NotSet" && goingToState !== null && this.californiaPositionTire[goingToState] === "california") {
            positionLocation = "california";
        }else if(positionLocation === "NotSet" && goingToCountry !== null && this.californiaPositionTire[goingToCountry] === "california") {
            positionLocation = "california";
        }else if(positionLocation === "NotSet" && goingToPrimaryLocation !== null && this.californiaPositionTire[goingToPrimaryLocation] === "california") {
            positionLocation = "california";
        }
        this.setState({positionLocation});
    }
    componentWillReceiveProps(nextProps) {
        if (
            nextProps.californiaTiers &&
            !isEqual(this.props.californiaTiers, nextProps.californiaTiers) &&
            !this.state.californiaTiers.length
        ) {
            this.setState({
                californiaTiers: Object.values(nextProps.californiaTiers),
            });
        } else {
            if( nextProps.californiaTiers !== null ) {
                this.setState({
                    californiaTiers: Object.values(nextProps.californiaTiers),
                });
            }
        }
    }

    private showMessage = () => {
        if (this.state.currentMessage === generalMessages.length - 1) {
            clearInterval(this.interval);
            this.interval = null;
            return this.setState({ showContent: true });
        }

        this.setState({ currentMessage: this.state.currentMessage + 1 });
    };

    private handleDropdownChange = ({
        target,
    }: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ [target.name]: target.value });
        this.props.history.push({
            state: {
                ...this.props.history.location.state,
                [target.name] : target.value,
            },
        });
    };
    private newBasesalaryDetailsChange = ({target}: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({valueInput: event.target.value.replace(/\D/,'')})
        this.props.history.push({
            state: {
                ...this.props.history.location.state,
                newBaseSalary : target.value,
            },
        });
    }

    private calMoveTier = {
        "Tier 1: California Market": "Tier1CaliforniaMarket", 
        "Tier 2: Critical Skills Position": "Tier2CriticalSkillsPosition", 
        "Tier 3: Business Critical Role": "Tier3BusinessCriticalRole"
    }
    private familySize = {
        "Single": "Single",
        "Couple": "Couple",
        "Family": "Family"
    }

    saveInitiationPackageDetails = () => {
        this.setState({
            isLoading: true,
            disableButton: true,
        })
        const policyData = this.props.location.state.policyData;
        policyData.competitiveEmployeeInfo = {
            newBaseSalary: this.props.location.state.newBaseSalary,
            calMoveTier: this.calMoveTier[this.props.location.state.californiaTier],
            familySize: this.familySize[this.props.location.state.familySize]
        }
        this.props
            .setPreInitPackageDetails(policyData)
            .then(data => {
                this.setState({
                    isLoading: false,
                    disableButton: false,
                })
                this.props.history.push({
                    state: {
                        ...this.props.location.state,
                        "policyData": policyData
                    },
                })
                this.props.navigateTo("finance", "job-details");
            })
            .catch(e => {
                console.log(e)
            });
    }
    

    private handleInputTextChange = ({
        target,
    }: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ [target.name]: target.value });       
    };

    private handleRadioChange = (
        event: React.ChangeEvent<{}>,
        value: string,
    ) => {
        this.setState({ confidential: value });
    };

    private toggleTooltip = () => {
        this.setState({ openTooltip: !this.state.openTooltip });
    };

    handleNameChange = ({ target }) => {
        this.setState({
            name: target.value,
           // shouldSaveButtonBeDisabled: target.value ? false : true,
        });
    };

    componentDidUpdate = () => {
        this.getButtonStatus();   
    }

    private getButtonStatus() {
        const {
            californiaTier,
            familySize,
            valueInput,
            positionLocation,
            moveType,
        } = this.state;
        if( positionLocation == "california" ) {
            if (!californiaTier || !familySize ||!valueInput ){
                this.setState({disableButton: true});
            } else {
                this.setState({disableButton: false});
            }
        } else {
            if (!familySize ||!valueInput ){
                this.setState({disableButton: true});
            } else {
                this.setState({disableButton: false});
            }
        }
    }

    private renderCaliforniaTooltip() {
        return (
            <React.Fragment>
                <Icon
                    name={"closeGray"}
                    width={15}
                    className={"flexible jEnd"}
                    onClick={this.toggleTooltip}
                />
                <h4>About California Tiers</h4>
                <div className={"tier"}>
                    <span>Tier 1 (California Market)</span>
                    For standard relocations into/within California.
                </div>
                <div className={"tier"}>
                    <span>Tier 2 (Critical Skills Position)</span>
                    Positions with unique skills needed within an occupation
                    which typically make the skills niche with a limited talent
                    supply and competition for talent is very competitive.
                </div>
                <div className={"tier"}>
                    <span>Tier 3 (Business Critical Role) </span>
                    Positions absolutely essential to the achievement of
                    long-term strategic business priorities through their
                    strategic impact and/or contribution of unique capabilities.
                </div>
            </React.Fragment>
        );
    }

    private renderMiddleContent() {
        if (!this.state.showContent) {
            return null;
        }

        const {
            californiaTier,
            familySize,
            valueInput,
            positionLocation,
            californiaTiers,
            disableButton,
            moveType,
        } = this.state;
        
        return (      
            <div className={"job-details-form"}>   
                 {(positionLocation == "california") ? (
                <h5 className={"california-title"}>
                    For California moves, we need to know which tier should be
                    used.
                </h5>
                     ) : null} 
                  {(positionLocation == "california") ? (  
                <div className={"flexible aCenter"}>
                    <Select
                        label={"Select applicable CA tier"}
                        onChange={this.handleDropdownChange}
                        value={californiaTier}
                        name={"californiaTier"}
                        options={californiaTiers}
                    />
                    <Tooltip
                        title={this.renderCaliforniaTooltip()}
                        PopperProps={{ disablePortal: true }}
                        open={this.state.openTooltip}
                        placement={"right"}
                        classes={{
                            popper: "tooltip-popper",
                            tooltip: "tooltip-root",
                        }}
                    >
                        <div
                            style={{ marginLeft: 14, paddingTop: 20 }}
                            onMouseEnter={this.toggleTooltip}
                        >
                            <Icon name={"infoBlue"} />
                        </div>
                    </Tooltip>
                </div>
          ) : null}

                <h5 className={"base-title"}>
                    New Base Salary
                </h5>
                <div className="Input">
                    <input 
                        name="valueInput"
                        value={this.state.valueInput} 
                        maxLength="10"
                        onChange={this.newBasesalaryDetailsChange}
                    />
                 </div>
                <h5 className={"family-title"}>
                Family size relocating (including Employee)
                </h5>
                <div className={"flexible aCenter"}>
                <Select
                        label={"Select an option"}
                        onChange={this.handleDropdownChange}
                        value={familySize}
                        name={"familySize"}
                        options={familySizeValues}
                    />
                </div>
                <div className={"divider"} />
                <div className={"flexible jEnd"}>
                    <Button
                        onClick={(): void =>
                            this.saveInitiationPackageDetails()
                        }
                        title="Continue"
                        className="job-details-submit"
                        disabled={disableButton}
                        submit={true}
                    />
                </div>
            </div>
        );
    }

    public render() {
        const messages = generalMessages.filter(
            (el, i) => i <= this.state.currentMessage,
        );
        return (
            <div className={"job-details"}>
                <h1>Job details</h1>
                <div className={"messages"}>
                    {messages.map(el => (
                        <div key={el} className={"message"}>
                            {el}
                        </div>
                    ))}
                </div>
                {this.renderMiddleContent()}
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(JobDetails),
);
