import React from 'react';

export default (
    <div className="article-info">
        <div className="article-info-item">
            <h2 className="title">We Won't Forgot Your Furry Friends</h2>
            <p className="description">
                We want you to feel at home in your host country—and&nbsp;
                <span className="link">for more than half of the people on earth, home is where a pet is</span>. <br /> <br />
                At the same time, we recognize that moving pets across borders isn't always easy.  There are many aspects to be considered including immunization, quarantine and transportation. Once you’ve landed, show your little buddy some extra affection—and keep in mind that, just like you, your beloved pet will need some time to settle in. rtation. Once you’ve landed, show your little buddy some extra affection—and keep in mind that, just like you, your beloved pet will need some time to settle in.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">We Offer Language Training Programs</h2>
            <p className="description">
                Along with exciting new sights and scents, the sounds of a new place—especially if you're hearing a foreign language—is part of what makes traveling to other countries so exciting. Of course, the ability to communicate with colleagues is also going to be crucial to your professional success. That's why, prior to relocating, Johnson & Johnson provides online language training programs. We want our employees to feel comfortable, whether they're at the water cooler, collaborating on a project—or just ordering coffee.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Pre-Move Cross-Cultural Training to Your Whole Family</h2>
            <p className="description">
                At Johnson & Johnson, we believe that the cultural experiences gained by relocating are just as important as the opportunities for professional development and career growth. After all, we know that bringing together diverse perspectives, while fostering an inclusive workforce where everyone feels comfortable being themselves, is how to set the stage for groundbreaking innovation. So we help our employees prepare to adjust to new cultures through a&nbsp;
                <span className="link">cross-cultural training portal</span>.
                <div className="list-with-title">
                    <h4 className="list-title">Topics covered include:</h4>
                    <ul className="list">
                        <li className="list-item">living conditions</li>
                        <li className="list-item">national history, economy and politics</li>
                        <li className="list-item">business skills and etiquette</li>
                        <li className="list-item">basics of household management and day-to-day living</li>
                        <li className="list-item">social customs</li>
                        <li className="list-item">the cycle of cultural adjustment</li>
                    </ul>
                </div>
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">We're Looking Out for Your Kids' Education</h2>
            <p className="description">
                Learning is a lifelong commitment, and one that is especially close to the heart of our mission and everything we do at Johnson & Johnson. So we believe passionately that your new role shouldn’t represent a hiccup in your kids’ education.
                <br /> <br />Before you move, then, you should think about the criteria that matter to you in finding a school that’s the right fit. Is it the curriculum? The type of instruction? The average class size? Thinking about these factors will help you narrow down the possible choices—and be mindful that rating systems for schools aren’t the same all over the world. Finally, it’s a good idea to brush up on some of the ways that&nbsp;
                <span className="link">educational norms differ from one country to the next</span>.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">We Want You to Stay Connected With Everyone Back Home</h2>
            <p className="description">
                Undoubtedly the most painful part of relocating is saying goodbye, however temporarily, to so many familiar faces. We know how important it is to stay in touch with everyone back home. Before you leave, talk to your loved ones about texting and calling apps that work internationally, or set aside specific times during the week to FaceTime with people from home. That will help you feel connected, even while you’re away.
                <br /><br />We’re trying to make professional relocation a little bit easier—so you can focus on your success on the job. We recognize that our talent is our most precious resource, and we’re doing our best to support you every step of the way.
            </p>
        </div>
    </div>
)
