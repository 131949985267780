import globus from 'components/common/Images/world.svg';
import React, { Component } from 'react'
import {MidSectionComponent, Link, TaskListComponent,
  HelpComponent, QuestionsListCard, Asset, SectionHeading} from 'components/common';

export default class IntExploreStep extends Component {

  render() {
    return (
      <div>
        <MidSectionComponent title = "How do I get global experience?" col1={
            <div className="flexible vertical pad-right">
            <p>Some programs are by nomination only through our J&J Talent development programs while others are through self initiation. For individuals interested in global experiences, it is important to express these interests to your manager.</p>
            <p>Additionally, be sure to update your talent profile frequently in Workday, including countries / companies / functions / regions of interest.</p>
            <Link text="Workday" externalLink='http://jjworkday.com/'/>
            </div>
            }

            col2={
            <div className="flexible vertical pad-left">
            <p>The Center for Leadership & Learning develops leadership skills and capabilities of leaders across the Johnson & Johnson companies with the goal of providing consistent leadership development enterprise-wide. </p>
            <p>Learn more about J&J Leadership and Development opportunities:</p>
            <Link text="Global HR Portal" externalLink='https://jnj.sharepoint.com/sites/leadershipandlearning/Pages/default.aspx'/>
            </div>
            }
        />

        <div className="question-part steps-columns-section">
          <div className="question-block flexible">
              <div className="flexible vertical">
                <SectionHeading 
                  eyebrow="The Basics"
                  title="Types of Global Experience"
                  className="section-heading mbottom-40"
                />
                <QuestionsListCard
                    key={1}
                    icon={globus}
                    title="What types of assignments are there?"
                    body="Johnson & Johnson, like most multi-nationals, supports different types of global experiences. These can and do vary depending on business objectives and talent strategies. Assignments range in length from short- to long-term to even, indefinite stays in another country on local employment terms and may be within or across regions."
                    urlText={["View Policies", "View Office Locations"]}
                    urlActions={[() => window.location.replace('/resources') /*TODO: this should redirect to local policies url*/,
                    () => new Asset({assetType:'int-planning', assetId:'country-experience-locations'}).execute()]}
                    factLabels={["Long Term","Short Term","One Way Move","Commuter","Business Traveler"]}
                    factValues={['12+ months','3 to less than 12 months','5+ years','50% of time in home country','Less than 3 months']}
                />
              </div>
              <div className="flexible vertical">
                  <TaskListComponent taskList={this.props.getTasks()}
                      title={this.props.getTasksTitle()}
                  />
                  {this.props.activeRelocation ? <HelpComponent title="We're here to help" mode="profile" /> : null}
              </div>
          </div>
        </div>
      </div>
    )
  }
}
