import React from 'react';

export default (
    <div className="article-info">
        <div className="article-info-item">
            <h2 className="title">Reach Out to Support Resources</h2>
            <p className="description">
                It's always exciting to complete a project, and in this case, you need to plan for it well in advance. That means beginning to discuss next steps with management and HR in your home office at least six to nine months prior to your anticipated return date. You should also begin to have a dialogue with your Johnson & Johnson Move Specialist to discuss next steps. This is the time to talk about goals and growth—and what’s next for you at Johnson & Johnson.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Get Your Documents in Order</h2>
            <p className="description">
                While dealing with the complexities of government bureaucracy can be frustrating and time-consuming, having the proper paperwork is nonetheless essential for travel. Not sure what you need? Most countries today provide online guidelines, such as &nbsp;
                <a href="https://travel.state.gov/content/travel/en/international-travel/before-you-go/travelers-checklist.html" rel="noopener noreferrer" target="_blank" className="link">these from the U.S. Department of State</a>, to help you understand what paperwork and documents you need to travel. 
            </p>
            <p>
                If necessary, we’ll work with a third-party provider to help you obtain the immigration-related documentation you need to return to your home country following an assignment.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Prepare Your Family for the Transition</h2>
            <div>
                <p className="description">
                If you relocated with your family, there's a lot to discuss. While your spouse might have been keeping track of the length of your assignment, and mentally preparing for your inevitable return to your home country, the same is not necessarily true of your children. So you should begin to prepare your kids for the return trip as early as possible. And as you plan for their educational transition, make sure you obtain transcripts from any schools your children attended in the host country.
                </p>
            </div>
        </div>
        <div className="article-info-item">
            <h2 className="title">Sort Out Benefits</h2>
            <div>
                <p className="description">
                    You may need to re-enroll in your home country’s benefits and social security system as soon as you return home and start working again. Get in touch with someone from HR if you need any help or clarification with that process.
                </p>
            </div>
        </div>
        <div className="article-info-item">
            <h2 className="title">Prepare Your Belongings</h2>
            <div>
                <p className="description">
                    For many people, packing is the most grueling part of the moving process. And process is probably the keyword—give yourself plenty of time, because this isn't going to happen overnight. As a simple first step, you'll need to get some boxes—your local post office is always a reliable bet.
                </p>
                <p>
                    Besides scissors, tape and the optional bubblewrap, keep a pen or marker handy: carefully labeling boxes will make your job a lot easier when it's time to unpack. Another good idea: inventorying your valuables before they go in the moving truck.
                </p>
            </div>
        </div>
        <div className="article-info-item">
            <h2 className="title">Update Your Address</h2>
            <div>
                <p className="description">
                    Make a list of the things that get regularly delivered to your door: magazines, bank statements, odds and ends from Amazon—the list might be longer than you think. You'll need to change your address across the board.
                </p>
            </div>
        </div>
        <div className="article-info-item">
            <h2 className="title">Secure Your Next Home</h2>
            <div>
                <p className="description">
                    Finding and securing an apartment or house in a country where you don't currently reside can be a challenge—which is why a short-term solution, like a two-week or month-long rental, might be best.
                </p>
            </div>
        </div>
    </div>
)
