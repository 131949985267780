import React, { PureComponent } from 'react';
import {JIBE_SIGNUP_LINK} from "../../consts";
import JibeRegPic from 'components/common/Images/JibeRegPic.png';

import './style.scss';
export default class Executive extends PureComponent {
    render() {
        return (
 <div className="exec-main">
    <h1>Welcome to move.jnj.com!</h1>
    <p>You will use this platform to manage your relocation.
        To access relevant information before you have been onboarded to Johnson &amp; Johnson, you will need to create an account.
        Please follow the steps below on how to create an account.</p>
    <h2>Create Your Passport Account:</h2>
    <ol>
        <li>Access the passport <a href={JIBE_SIGNUP_LINK} rel="noopener noreferrer" target="_blank">account registration</a> page.</li>
        <li>Review and accept the Privacy Policy.</li>
        <li>Complete the registration form and register your account using the email address you provided to your relocation specialist.</li>
    </ol>
    <p className="altText"><img src={JibeRegPic} alt=""/><br/>Passport registration page</p>

    <h2>Accessing move.jnj.com.</h2>
    <ol>
        <li>Navigate to <a href="/login"  rel="nofollow" target="_blank">move.jnj.com</a>.</li>
        <li>Select the “Don't have Johnson & Johnson network credentials?”.</li>
        <li>Login using your credentials that were created during step 3 of "Create your Passport Account".</li>
    </ol>
    <p>Welcome to move.jnj.com!</p>
</div>);
    }
}
