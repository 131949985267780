import React, { Component } from 'react'
import { TaskListComponent, Contacts, CAROUSEL_TALK_CONTACTS_PHONES,
    CAROUSEL_TALK_CONTACTS_EMAILS } from 'components/common';
import Typography from '@material-ui/core/Typography';
import './style.scss';

export default class MobilityConnectBlock extends Component {
  render() {
    return (
            <div className="MobilityConnectBlock">
                <TaskListComponent title="Mobility Connect" content={<div>
                    <Typography variant="body2" className="pbottom-10">There are several ways to contact and obtain support regarding a move. Our advisors will provide guidance on general inquiries, answer policy and initiation questions, review move process, and general mobility questions.</Typography>
                    <div className="info-block">
                        <Contacts
                            className='contact'
                            title='Connect By Phone:'
                            contacts={CAROUSEL_TALK_CONTACTS_PHONES} />
                        <Contacts
                                className='contact mtop-20'
                                title='Connect By Email:'
                                contacts={CAROUSEL_TALK_CONTACTS_EMAILS} />
                    </div>
                </div> }
                 />
            </div>
    )
  }
}
