// @flow
import * as React from 'react'
import cs from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import withStyles from '@material-ui/core/styles/withStyles';
import type {WithStyles, StyleRulesCallback} from '@material-ui/core/styles/withStyles';
import IconRaw from '../Icons/IconRaw';

type CollapsedItemProps = {
    className?: string,
    title: string,
    expanded: boolean,
    onClick?: Function,
    icon?: React.Element<typeof IconRaw>,
    children: React.Node
};

type CollapsedItemState = {
    expanded: boolean
}

const styles: StyleRulesCallback = theme => ({
    root: {
    },
    header: {
        paddingTop: 20,
        paddingBottom: 20,
        cursor: 'pointer',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    container: {
        borderBottom: '2px solid #D8D8D8',
    },
    hasIcon: {
        marginLeft: 70,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        }
    },
    icon: {
        flexGrow: 0,
        marginRight: 20
    },
    iconToggle: {
        flexGrow: 0,
        marginLeft: 20
    },
    title: {
        flexGrow: 1,
        transition: [
            ['color', '200ms'],
        ],
        fontSize: 18,
        lineHeight: 23/18,
        color: '#212121',
        fontWeight: 300
    },
    opened: {
        color: theme.palette.secondary.main,
    }
});

class CollapsedItem extends React.Component<CollapsedItemProps & WithStyles, CollapsedItemState> {
    static defaultProps = {
        expanded: false
    };

    state = {
        expanded: false
    };

    static getDerivedStateFromProps(props, state) {
        if (!!props.onClick) {
            return {expanded: props.expanded};
        }
    }

    // change state if element is not controlled
    handleClick = () => {
        if (!!this.props.onClick) {
            this.props.onClick();
        } else {
            this.setState({expanded: !this.state.expanded})
        }
    }

    render() {
        const {classes, className, title, children, icon} = this.props;
        const {expanded} = this.state;

        return (
            <div className={cs(classes.root, className)}>

                <div className={classes.header} onClick={this.handleClick}>
                    {icon && (<div className={classes.icon}>
                        {React.cloneElement(icon, {height: 50, width: 50})}
                    </div>)}
                    <div className={cs(classes.title, {
                        [classes.opened]: expanded
                    })}>{title}</div>
                    <div className={classes.iconToggle}>
                        {!expanded
                            ? <IconRaw name="plus" height={0} width={20}/>
                            : <IconRaw name="minus" height={0} width={20}/>
                        }
                    </div>
                </div>

                <Collapse className={cs(classes.container, {
                    [classes.hasIcon]: !!icon
                })} timeout="auto" in={expanded}>
                    {children}
                    <br/>
                </Collapse>
            </div>
        );
    }
}



export default withStyles(styles)(CollapsedItem);
