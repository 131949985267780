// @flow

import { Api } from 'backend';

const AssetsAPI = {
    downloadAsset: function(assetType : string, assetID: string, size:number, callback): Promise<any> {
        if (size > 0)  {
            return Api.get({
                path: `/assets/${assetType}/${assetID}/webimage?size=${size}`,
                additionalOptions: {
                    responseType: 'blob',
                    callback
                }
            });
        }

        return Api.get({
            path: `/assets/${assetType}/${assetID}/raw`,
            additionalOptions: {
                responseType: 'blob',
                callback
            }
        });
    }
};

export default AssetsAPI;
