import * as React from "react";
import { SelectionCard } from "components/common";
import { Icon } from "components/common";
import { RouterProps } from "types";
import LDPFinalMovePackageB from "../EligibleMove/LDPFinalMovePackageB";
import LDPRotationalPackageA from "../EligibleMove/LDPRotationalPackageA";
import "./style.scss";

const FinalMove = (props: RouterProps) => {
    const finalMoveType = [
        {
            id: 1,
            key: "yes",
            title: "Yes"
        },
        {
            id: 2,
            key: "no",
            title: "No",
        }
    ];
    const clickHandler = (key: string,value: string): void =>
    props.history.push({
        state: {
            ...props.location.state,
            "finalMoveTypeKey": key,
            "finalMoveTypeTitle": value,
        },
    });
    const backHandler = (moveType,policySelection): void =>
                    props.history.push({
                        state: {
                            "moveType": moveType,
                            "policySelection": policySelection,
                            "procurementKey": props.location.state.procurementKey,
                            "policyType": "LDP",
                            "reasonSelect": props.location.state.reasonSelect,
                            "procurementTitle":  props.location.state.procurementTitle,
                            "empCategoryRepresentation": (props.location.state.empCategoryRepresentation !== undefined) ? props.location.state.empCategoryRepresentation : null,
                            "initiationId": (props.location.state.initiationId !== undefined) ? props.location.state.initiationId : null,
                            "initiationCountryCode": (props.location.state.initiationCountryCode !== undefined) ? props.location.state.initiationCountryCode : null,
                            "empProcurementType": (props.location.state.empProcurementType !== undefined) ? props.location.state.empProcurementType : null,
                            employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
                        },
                    });
    if (
        props.location.state &&
        props.location.state["finalMoveTypeKey"] &&
        props.location.state["finalMoveTypeKey"] === "yes"
    ) {
        return <LDPFinalMovePackageB history={props.history} location={props.location} backState={"finalMoveTypeKey"}/>;
        
    } else if (
        props.location.state &&
        props.location.state["finalMoveTypeKey"] &&
        props.location.state["finalMoveTypeKey"] === "no"
    ) {
        return <LDPRotationalPackageA history={props.history} location={props.location} backState={"finalMoveTypeKey"}/>;
    } else {
        const { moveType, policySelection } = props.location.state;
        return (
            <article className="domestic-scenario-wrapper">
                <div className="back-icon" onClick={() => backHandler(moveType,policySelection)} >
                    <Icon name="backIcon" />
                    <p>Go back</p>
                </div>
                <h1 className="section-title">
                    Final Move?
                </h1>
                <div className={"reasons flexible wrap jBetween"}>
                        {finalMoveType.map(data => (
                            <div
                                key={data.id}
                                className={"reason"}
                                onClick={() =>
                                    clickHandler(data.key,data.title)
                                }
                            >
                                <p className="reason-info">{data.title}</p>
                            </div>
                        ))}
                    </div>
            </article>
        );
    }
};

export default FinalMove;
