import React, { PureComponent } from "react";
import { MoveStepLayout } from "components/moveInitiationLayouts";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { moveInitiationService } from "services";
import { Icon, Link, Button, ApprovalAutoComplete, SpinnerWrapper, FinanceInputAutoComplete } from "components/common";
import { MoveDetailsFinanceState } from "types";
import { OptionType } from "../AutoComplete";
import hint from 'components/common/Images/hint.png';
import Lightbulb from "components/common/Images/Lightbulb.svg";
import "./style.scss";

const mapDispatchToProps = {
    fetchFinancePartner: moveInitiationService.actions.fetchFinancePartner,
    setMoveFinanceMoveDetails: moveInitiationService.actions.setMoveFinanceMoveDetails
};

class FinanceAdmin extends PureComponent<
    RouteComponentProps & {
        fetchFinancePartner: (partner: string) => Promise<any>;
        navigateTo: (to: string, from: string) => void;
        setMoveFinanceMoveDetails: (data: Object) => Promise<any>;
    },
    MoveDetailsFinanceState
> {
    interval: any;
    state = {
        currentMessage: -1,
        showContent: false,
        manager: (this.props.location.state.manager != null && typeof this.props.location.state.manager !== 'undefined') ? 
                    this.props.location.state.manager : null,
        derivedSecondManager: (this.props.location.state.derivedSecondManager != null && typeof this.props.location.state.derivedSecondManager !== 'undefined') ? 
                    this.props.location.state.derivedSecondManager : null,
        partnersOne: [],
        partnersTwo: [],
        isLoadingFieldOne: false,
        isLoadingFieldTwo: false,
        selectedFirstManager: null,
        selectedSecondManager: null,
        disableStyle: "disabled", 
        disableButton: true,
        isLoading: false,
        partnerName: (this.props.location.state.partnerName != null && typeof this.props.location.state.partnerName !== 'undefined') ? 
                        this.props.location.state.partnerName : null,
        partnerNames: [],
        isLoadingHr: false,
        partnerHrName: (this.props.location.state.partnerHrName != null && typeof this.props.location.state.partnerHrName !== 'undefined') ? 
                        this.props.location.state.partnerHrName : null,
        partnerHrNames: [],
        isLoadingSave: false,
    };

    clearAutoComplete = () => [];

    handleClickFirstManager = (manager: OptionType) => {
        this.setState({
            manager,
            isLoadingFieldOne: false,
        });
    }    

    handleClickSecondManager = (derivedSecondManager: OptionType) =>
        this.setState({
            derivedSecondManager,
            isLoadingFieldTwo: false,
        });


    onManagerChange = (value: string) => {
        if (value === "") {
            const clearSecond = {
                label: "clear",
                subLabel: "",
                displayName: "",
                firstName: "",
                jobTitle: "",
                lastName: "",
                email: "",
                wwid: "",
            };
            this.setState({
                manager: null,
                derivedSecondManager: clearSecond,
                disableStyle: "disabled",
            });
        } else {
            if (value.length > 2) {
                this.setState({
                    isLoadingFieldOne: true,
                });
                let secondManager = undefined;
                this.props
                        .fetchFinancePartner(value)
                        .then(partnersOne => {
                            if( partnersOne.result.length > 0) {
                                // if(partnersOne.result[0].manager !== undefined && partnersOne.result[0].manager.length > 0) {
                                //     secondManager = partnersOne.result[0].manager;    
                                // }
                                this.setState({
                                    partnersOne: partnersOne.result,
                                    derivedSecondManager: secondManager,
                                    isLoadingFieldOne: false,
                                });
                            }
                        })
                        .catch(err => console.log(err));
            } else {
                if (this.state.partnersOne.length) {
                    this.setState({
                        partnersOne: [],
                    });
                }
            }
        }
    };

    onSecondManagerChange = value => {
        if (value === "") {
            this.setState({
                derivedSecondManager: null,
            });
        } else {
            if (value.length > 2) {
                this.setState({
                    isLoadingFieldTwo: true,
                });
                this.props
                        .fetchFinancePartner(value)
                        .then(partnersTwo => {
                            if( partnersTwo.result.length > 0) {
                                this.setState({
                                    partnersTwo: partnersTwo.result,
                                    isLoadingFieldTwo: false,
                                });
                            }    
                        })
                        .catch(err => console.log(err));
            } else {
                if (this.state.partnersTwo.length) {
                    this.setState({
                        partnersTwo: [],
                    });
                }
            }
        }
    };
  
    handleClick = (partnerName: OptionType) =>
        this.setState({
            partnerName,
            isLoading: false,
        });

    changeHandler = (partner: string) => {
        if (
            this.state.partnerName &&
            this.state.partnerName.label !== partner
        ) {
            this.setState({
              partnerName: null,
            });
        }
        if (partner.length > 2) {
            this.setState({
                isLoading: true,
            });
            this.props
                .fetchFinancePartner(partner)
                .then(partners => {
                    this.setState({
                        partnerNames: partners.result,
                        isLoading: false,
                    });
                })
                .catch(err => console.log(err));
        } else {
            if (this.state.partnerNames.length) {
                this.setState({
                  partnerNames: [],
                });
            }
        }
    };

    handleClickHr = (partnerHrName: OptionType) =>
        this.setState({
            partnerHrName,
            isLoadingHr: false,
        });

    changeHandlerHr = (partner: string) => {
        if (
            this.state.partnerHrName &&
            this.state.partnerHrName.label !== partner
        ) {
            this.setState({
              partnerHrName: null,
            });
        }
        if (partner.length > 2) {
            this.setState({
                isLoadingHr: true,
            });
            this.props
                .fetchFinancePartner(partner)
                .then(partners => {
                    this.setState({
                        partnerHrNames: partners.result,
                        isLoadingHr: false,
                    });
                })
                .catch(err => console.log(err));
        } else {
            if (this.state.partnerHrNames.length) {
                this.setState({
                  partnerHrNames: [],
                });
            }
        }
    };

	componentDidUpdate = () => {
        this.enableContinueBtn();        
    } 
    
    enableContinueBtn = () => {
        const {manager, derivedSecondManager} = this.state;
        if( (manager !== null && manager !== undefined ) && (derivedSecondManager !== null && derivedSecondManager !== undefined )){
            this.setState({disableStyle: "", disableButton: false})
        } else {
            this.setState({disableStyle: "disabled", disableButton: true})  
        }
   }
   
   componentDidMount = () => {
        const {manager, derivedSecondManager} = this.state;
        if( (manager !== null && manager !== undefined ) && (derivedSecondManager !== null && derivedSecondManager !== undefined )){
            this.setState({disableStyle: "", disableButton: false})
        } else {
            this.setState({disableStyle: "disabled", disableButton: true})  
        }
    }
    
    saveFinanceMoveDetails = (location) => {
        this.setState({isLoadingSave: true});
        let { partnerHrName, partnerName, manager, derivedSecondManager } = this.state;
        this.props
                .setMoveFinanceMoveDetails({
                        "initiationId": (this.props.location.state.initiationId !== undefined) ? this.props.location.state.initiationId : '',
                        "newFundingFinancePartner": (partnerName !== null) ? partnerName : {},
                        "hostHumanResourcesContact": (partnerHrName !== null) ? partnerHrName : {},
                        "hirinManager": (manager !== null) ? manager : {},
                        "hirinManagerLevel2": (derivedSecondManager !== undefined) ? derivedSecondManager : {}, 
                    })
                    .then(data => {
                        this.setState({isLoading: false});
                       if(data.success) {
                            this.navigateTo(location);
                       } else {
                            console.log(data.message);
                       }
                    })
                    .catch(err => console.log(err));
    }

    navigateTo = (location: string) => {
        switch (location) {
            case 'continue':
                this.saveFinanceMoveDetails("yesandcontinue");
                break;
            case 'returnhome':
                this.saveFinanceMoveDetails("returndashboard");
                break;
            case 'lets-go':
                this.props.history.push(
                    "/initiate-move",{...this.props.location.state}
                );
                break;
            case 'returndashboard':
                    this.props.history.push(
                        "/move-dashboard"
                    );
                    break;
            case 'yesandcontinue':
                    let { partnerHrName, partnerName, manager, derivedSecondManager } = this.state;
                    this.props.history.push(
                        "/initiate-move/review-submit",
                        { ...this.props.location.state, partnerHrName, partnerName,manager,derivedSecondManager },
                    );
                    break;
        }
    };

    render() {
        
        const { manager,derivedSecondManager, partnersOne, partnersTwo, isLoadingFieldOne, isLoadingFieldTwo, isLoading, partnerNames, partnerName, isLoadingHr, partnerHrNames, partnerHrName, disableButton, disableStyle, isLoadingSave } = this.state;
        return (
          <section className="finance-admin-container">
            <div className="RecruitedSelectorContainer">
              <MoveStepLayout selected={4} />
              <SpinnerWrapper show={isLoadingSave}>
              <div className="finace-admin-body-container">
              <div className="finance-admin-header-container">
                <div 
                    className="back-icon" 
                    onClick={() => {
                        this.props.history.push(
                            "/initiate-move/additional-move-details",{...this.props.location.state}
                        );
                    }}
                >
                    <Icon name="backIcon" />
                    <p>Go back</p>
                </div>
                <section className="header-section">
                    <h1 className="section-title">
                    Let’s finish up!
                    </h1>
                </section>
              </div>
              <div className="finance-admin-details-container">
                <div className="left-container">
                  <div className="funding-finance-partner">
                    <span className="questen">Who is the Funding Finance Partner for this employee's new team?</span>
                      <div className="inputs"> 
                        <div className="Input">
                          <FinanceInputAutoComplete
                              required={false}
                              isLoading={isLoading}
                              placeholder={"Funding Finance Partner"}
                              inputTitle={"Funding Finance Partner"}
                              clearAutoComplete={this.clearAutoComplete}
                              suggestions={partnerNames}
                              handleClick={this.handleClick}
                              style={{ marginTop: 38 }}
                              onChange={this.changeHandler}
                              value={partnerName}
                          />
                        </div>
                      </div>
                      {partnerName !==null ? "" : (
                          <span className="not-sure">Not sure? Leave this field blank and we will work with you to identify who your Funding Finance Partner is.</span>
                      )}
                  </div>
                  <div className="human-resources">
                    <span className="questen">Who is the Host Human Resources contact for this employee?</span>
                      <div className="inputs"> 
                        <div className="Input">
                        <FinanceInputAutoComplete
                              required={false}
                              isLoading={isLoadingHr}
                              placeholder={"Host Human Resources contact"}
                              inputTitle={"Host Human Resources contact"}
                              clearAutoComplete={this.clearAutoComplete}
                              suggestions={partnerHrNames}
                              handleClick={this.handleClickHr}
                              style={{ marginTop: 38 }}
                              onChange={this.changeHandlerHr}
                              value={partnerHrName}
                          />
                        </div>
                      </div>
                  </div>
                  <div className="approver-1">
                      <ApprovalAutoComplete
                        required
                          title={"Approver #1: Hiring Manager"}
                          placeholder={"Enter name"}
                          inputTitle={"Hiring Manager Name"}
                          clearAutoComplete={this.clearAutoComplete}
                          handleClick={this.handleClickFirstManager}
                          suggestions={partnersOne}
                          style={{ marginTop: 38 }}
                          onChange={this.onManagerChange}
                          isLoading={isLoadingFieldOne}
                          value={manager}
                          isconfirmApprover={true}
                      />
                  </div>
                  <div className="approver-2" >
                        <ApprovalAutoComplete
                            required
                            title={"Approver #2: Hiring Manager's Manager"}
                            placeholder={"Enter name"}
                            inputTitle={"Hiring Manager's Manager Name"}
                            clearAutoComplete={this.clearAutoComplete}
                            suggestions={partnersTwo}
                            secondManagerData={derivedSecondManager}
                            style={{ marginTop: 38 }}
                            onChange={this.onSecondManagerChange}
                            handleClick={this.handleClickSecondManager}
                            isLoading={isLoadingFieldTwo}
                            isconfirmApprover={true}
                            value={null}
                        />
                  </div>
                </div>
                <div className="right-container">
                  <div className="finance">
                    <div className="left">
                      <img src={hint} />
                    </div>
                    <div className="right">
                      <span>Your funding finance contact is the finance individual for the department which is paying for the move. </span>
                      <span className="contact-finance-link" >
                      <Link
                            text="Find your finance contacts"
                            onClick={() => {
                                this.props.history.push(
                                    "",
                                );
                            }}
                            className="profile-header-right"
                        />
                      </span>
                    </div>
                  </div>
                  {/* <div className="mobility-service">
                    <div className="left">
                    <img src={Lightbulb} alt=""/>
                    </div>
                    <div className="right">
                      <span>Talent Mobility Services require 2 levels of approval; the first from the Hiring Manager and the second from the Hiring Manager’s manager.</span>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="button-section">
                <div className="left-section">
                    <Button
                      title="Start Over"
                      className="start-button"
                      style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", width:"132px"}}
                      labelStyle={{color:"#CA001B"}}
                      onClick={() => {
                            this.navigateTo("lets-go");
                      }}
                    />
                </div>
                <div className="right-section">
                    <Button
                      title="Save and Return to Home"
                      className={"save-button " + disableStyle}
                      disabled={disableButton}
                      style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", width:"215px"}}
                      labelStyle={{color:"#CA001B"}}
                      onClick={() => {
                            this.navigateTo("returnhome");
                      }}
                    />
                    <Button
                      title="Continue"
                      className={"continue-button " + disableStyle}
                      disabled={disableButton}
                      style={{border:"1px solid #D8D8D8", width:"169px"}}
                      labelStyle={{color:"#FFFFFF"}}
                      onClick={() => {
                            this.navigateTo("continue");
                      }}
                    />
                </div>
              </div>
              </div>
              </SpinnerWrapper>       
            </div>
          </section>
        );
    }
}
export default withRouter(
    connect(
        null,
        mapDispatchToProps,
    )(FinanceAdmin),
);
