import React from 'react';

export default (
    <div className="article-info">
        <div className="article-info-item">
            <h2 className="title">Plan for Medical Essentials</h2>
            <p className="description">
                Be sure to visit your dentist, optometrist, generalist and any specialists you typically see before you leave—and bear in mind that we’ll do our best to help you find replacements for each of them in your host country. Still, if you have important prescriptions to take, it’s a good idea to try to fill as many months as your medical plan will allow before leaving. Consider asking your doctor to prescribe just-in-case travel staples, like a multipurpose antibiotic, too.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Time to Tidy Up</h2>
            <p className="description">
                There’s nothing like a good old-fashioned moving purge. Take a look at your furniture—especially your biggest items—to assess what should stay and what can go. Keep in mind that it might cost a fortune to ship your prized couch halfway around the world.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Ship (or Store) Your Things</h2>
            <p className="description">
                For the must-keep stuff, you have a couple of options. If you're making a short-term move, you might consider leaving smaller items like paintings and keepsakes with friends. For bigger items, consider renting a storage locker. &nbsp;
                <a href="https://www.zillow.com/blog/how-much-will-you-pay-to-move-91663" rel="noopener noreferrer" target="_blank" className="link">Pricing</a> &nbsp; varies , but these facilities typically start around $50 per month for small-sized units; for larger ones, the cost can sometimes be $400 or more for larger units.
                <br/><br/>Make sure you choose a secure facility with camera surveillance and 24/7 staffing. You might even want to ask a trusted friend or relative to check in periodically—and don’t forget to pay your monthly bill!
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Get Your Pets Ready</h2>
            <p className="description">
                Most countries will allow you to bring your domestic animals with you, but there are sometimes strict importation rules you should be aware of. Early on in the moving process, check on your host country’s requirements surrounding vaccinations and quarantine. You might also want to check with the available airlines to see what their kennel requirements are. Finally, you could consider getting your pet a sedative or anti-anxiety medication to help put them at ease during a long journey.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Withdraw Cash</h2>
            <p className="description">
                It’s a good idea to have some money on hand to pay for miscellaneous living expenses as you settle into your new home. Of course, that doesn’t mean you should convert all of your savings into cold hard cash. You can use international money orders or wire transfers (but beware of fees!) in order to move your money from your old account to your new one.
            </p>
            <p className="description">
                If you can, keep at least a few months’ worth of living expenses in cash just in case you can’t get immediate access to your funds. Also, you should be mindful that most countries ask you to declare cash amounts higher than $10,000 upon arrival.
            </p>
            <p className="description">
                A major move is bound to be somewhat stressful, but it's also a great opportunity—and tackling these five time-consuming areas first can help set the stage for a successful transition.
            </p>
        </div>
    </div>
)
