import React from 'react';

import './styles.scss';

export default class TitleWithIcon extends React.PureComponent {
    render() {
        return (
            <div className="TitleWithIcon">
                <div className="flexible horizontal jBetween">
                    <div className="icon-block flexible noGrow">
                        <img src={`data:image/svg+xml;base64,${this.props.picture}`} alt="tile icon"/>
                    </div>
                    <div className="title-block flexible grow">
                        <h1 className="title">{this.props.title}</h1>
                    </div>
                </div>
            </div>
        );
    }
}