import React, { PureComponent } from 'react';

import './style.scss';
import { Link } from 'components/common';

export default class CTAComponent extends PureComponent {

    handleClick = () => { this.props.onClick &&  this.props.onClick()};

    render() {
        return (
            <div className="CTAComponent" onClick={this.handleClick}>
                <div className="cta-block flexible vertical">
                    <div className="image-block">{this.props.image}</div>
                    <h3 className="truncate twoLines">{this.props.title}</h3>
                    <div className="read-more">
                        <hr/>
                        <div className="aside">
                            <div className="text">
                                <Link text="Read More"/>
                            </div>
                            <div className="follow-button">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}