import React, { SetStateAction, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Icon } from "components/common";
import "./styles.scss";

export interface OptionType {
    displayName: string;
    label: string;
    subLabel: string;
    email: string;
    wwid: string | number;
    manager?: string;
    firstName: string;
    jobTitle: string;
    lastName: string;
}

function renderInputComponent(inputProps: any) {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: node => {
                    ref(node);
                    inputRef(node);
                },
                classes: {
                    input: classes.input,
                },
            }}
            {...other}
        />
    );
}

function renderSuggestion(
    suggestion: OptionType,
    { query, isHighlighted }: Autosuggest.RenderSuggestionParams,
) {
    const matches = match(suggestion.displayName, query);
    const parts = parse(suggestion.displayName, matches);

    return (
        <MenuItem
            selected={isHighlighted}
            component="div"
            classes={{ selected: "selected-menu", root: "combobox-item" }}
        >
            <span>
                {parts.map(part => (
                    <span
                        key={part.text}
                        style={{ fontWeight: part.highlight ? 900 : 300 }}
                    >
                        {part.text}
                    </span>
                ))}
            </span>
            {suggestion.subLabel && (
                <span className={"combobox-item-position"}>
                    {suggestion.subLabel}
                </span>
            )}
        </MenuItem>
    );
}

function getSuggestionValue(suggestion: OptionType, props: IProps) {
    props.handleClick && props.handleClick(suggestion);
    return suggestion.displayName;
}

interface IProps {
    readonly inputTitle?: string;
    readonly title: string;
    readonly placeholder: string;
    readonly clearAutoComplete: () => SetStateAction<any>;
    readonly style?: object;
    readonly suggestions: OptionType[];
    readonly onChange?: (value: string) => void;
    readonly secondManagerData?: OptionType;
    handleClick?: (value: OptionType) => void;
    readonly required?: boolean;
    readonly isLoading?: boolean;
    readonly isconfirmApprover?: boolean;
}

const ApprovalAutoComplete = (props: IProps) => {
    const classes = useStyles();
    const [state, setState] = React.useState("");
    const [stateSuggestions, setSuggestions] = React.useState<OptionType[]>([]);

    const handleSuggestionsClearRequested = () => {
        setSuggestions(props.clearAutoComplete());
    };

   // let selectedSuggestion = props.suggestions.find(el => state === el.displayName);
    let selectedSuggestion = null;
    if(props.value !== null && typeof props.value !== undefined) {
        selectedSuggestion = props.value;
    } else {
        selectedSuggestion = props.suggestions.find(el => state === el.displayName);
    }

    useEffect(() => {
        if(props.value !== null) {
            setState(props.value.displayName);
        } 
    });

    const handleChange = () => (
        event: React.ChangeEvent<{}>,
        { newValue }: Autosuggest.ChangeEvent,
    ) => {
        if (newValue === "") {
            props.onChange("");
            setState(newValue);
        }
        if (newValue !== "" && typeof props.onChange === "function") {
            props.onChange(newValue);
            setState(newValue);
        }
    };

    const updateName = () => {
        if (props.secondManagerData.label === "clear") {
            setState("");
        } else if (state !== props.secondManagerData.displayName) {
            setState(props.secondManagerData.displayName);
        }
    };
    const handleNoState = () => {
        if (props.secondManagerData.label !== "clear") {
            setState(props.secondManagerData.displayName);
        }
    };
    if (props.secondManagerData) {
        state ? updateName() : handleNoState();
        selectedSuggestion = state ? props.secondManagerData : undefined;
    }

    return (
        <div style={props.style}>
            <h6 className={classes.title}>
                {props.isconfirmApprover && selectedSuggestion && (
                    <span>
                        Confirm &nbsp;
                    </span>
                )}
                {props.title}
            </h6>
            {props.inputTitle && selectedSuggestion && (
                <h6 className={classes.subtitle}>
                    {props.inputTitle}
                    <span className={classes.required}>*</span>
                </h6>
            )}
            <div className={"autosuggest-container"}>
                <Autosuggest
                    renderInputComponent={renderInputComponent}
                    suggestions={props.suggestions}
                    onSuggestionsFetchRequested={() => {}}
                    onSuggestionsClearRequested={
                        handleSuggestionsClearRequested
                    }
                    getSuggestionValue={value =>
                        getSuggestionValue(value, props)
                    }
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                        classes,
                        placeholder: `${props.placeholder}${
                            props.required ? "*" : ""
                        }`,
                        value: state,
                        onChange: handleChange(),
                    }}
                    focusInputOnSuggestionClick={false}
                    theme={{
                        container: "combo-box-container",
                        suggestionsContainerOpen:
                            classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                        input: "input-top-container",
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                />
                {state.length ? (
                    <Icon
                        name={"close"}
                        className={"close-icon"}
                        width={15}
                        onClick={() => {
                            setState("");

                            if (typeof props.onChange === "function") {
                                props.onChange("");
                            }

                            handleSuggestionsClearRequested();
                        }}
                    />
                ) : null}
                {props.isLoading && (
                    <span className="select-field-spinner">
                        <div className="Spinner " />
                    </span>
                )}
            </div>
            {selectedSuggestion ? (
                <div className={"flexible jBetween combo-box-extra-data"}>
                    {selectedSuggestion.email ? (
                        <div className="extra-item">
                            <h6>
                                Email <span>*</span>
                            </h6>
                            {selectedSuggestion.email}
                        </div>
                    ) : null}
                    {selectedSuggestion.wwid ? (
                        <div className="extra-item">
                            <h6>
                                WWID <span>*</span>
                            </h6>
                            {selectedSuggestion.wwid}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 18,
            fontWeight: 900,
            lineHeight: "36px",
            color: theme.palette.text.primary,
        },
        subtitle: {
            color: "#63666A",
            fontSize: 12,
            fontWeight: 300,
            lineHeight: "18px",
            marginTop: 9,
        },
        required: {
            color: "#ca001b",
            marginLeft: 2,
        },
        suggestionsContainerOpen: {
            position: "absolute",
            zIndex: 1,
            left: 0,
            right: 0,
        },
        suggestion: {
            display: "block",
        },
        suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: "none",
        },
        input: {
            paddingTop: 7,
            paddingBottom: 12,
            fontSize: 16,
            fontWeight: 300,
        },
    }),
);

export default ApprovalAutoComplete;