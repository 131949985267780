import * as React from "react";
import { EligibleMoveForDomestic } from "components/moveInitiationLayouts";
import { Asset } from "components/common";
import { RouterProps } from "types";
import "./styles.scss";

const HomeownerPackageD = (props: RouterProps) => {
    const rows = [
        [
            "100+%",
            "One time",
            "Charged first quarter after move authorization",
        ]
    ];

    const cols = [
        { title: "% of salary" },
        { title: "Budget Allocation" },
        { title: "Timing of actual charges" },
    ];
    const {backState} = props;
   
    return (
        <EligibleMoveForDomestic
            eligibleTitle="Homeowner - Package D"
            policyName="HomeownerPackageD"
            moveType="U.S. Domestic" 
            category={props.location.state.reasonSelect || "N/A"}
            employee={props.location.state.procurementTitle}
            backState={backState}
            cols={cols}
            rows={rows}
            lumSumFieldEnable={true}
            assetId="homeowner-policy"
            assetType="domestic-package-c-40"
            url="/initiate-move/employee-job-details"
            {...props}
        />
    );
};

export default HomeownerPackageD;
