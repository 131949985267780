// @flow

import React from 'react';

import ListItem from './ListItem';
import './styles.scss';


type AccordionItemType = {
    props: Object, // pass uniqueKey for correct work of expand/collapse
    content: React.Node,
}

type OpenedItemKeys = Array<number | string>;

type AccordionProps = {
    ItemRenderer?: Function,
    itemProps?: Object,
    data: Array<AccordionItemType>,
    className?: string,
    onItemClick?: Function,
};

type AccordionState = {
    openedItemKeys: OpenedItemKeys
};

export default class Accordion extends React.PureComponent<AccordionProps, AccordionState> {
    constructor(props) {
        super(props);

        this.state = {
            openedItemKeys: this.props.openedItems || [],
        }
    }

    handleItemClick = (e: SyntheticMouseEvent<*>, key: number | string): void => {
        this.props.onItemClick && this.props.onItemClick(e, key);
        let openedItemKeys = [...this.state.openedItemKeys];
        if(this.checkIsOpened(openedItemKeys, key)) {
            openedItemKeys = openedItemKeys.filter(k => (k !== key));
        } else {
            openedItemKeys = [...openedItemKeys, key];
        }

        this.setState({ openedItemKeys })
    };

    checkIsOpened = (openedItemKeys: OpenedItemKeys, key: number | string): boolean => openedItemKeys.includes(key);

    generateItems = (data: Array<AccordionItemType>): React.ChildrenArray<any> => {
        const { ItemRenderer = ListItem } = this.props;
        if(Array.isArray(data) && data.length) {
            return data.map((item) => {
                    const disabled = !item.content;
                    const isItemOpened = this.checkIsOpened(this.state.openedItemKeys, item.props.uniqueKey);
                    return (
                        <div
                            key={item.props.uniqueKey}
                            className={`accordion-item ${disabled ? 'disabled' : ''}`}
                        >
                            <div
                                className="item-header"
                                onClick={(e) => { !disabled && this.handleItemClick(e, item.props.uniqueKey)}}
                            >
                                <ItemRenderer
                                    {...(this.props.itemProps || {})}
                                    {...item.props }
                                    open={isItemOpened}
                                    disabled={disabled}
                                />
                            </div>
                            {
                                !disabled &&
                                <div className={`item-content ${isItemOpened ? 'opened' : ''}`}>
                                    {item.content}
                                </div>
                            }
                        </div>
                    )
            })
        }
        return null;
    };

    render() {
        return (
            <div className={`Accordion ${this.props.className}`}>
                { this.generateItems(this.props.data) }
            </div>
        );
    }
}
