import phone from 'components/common/Images/phone.svg';
import mail from 'components/common/Images/mail.svg';

export const CAROUSEL_TALK_CONTACTS_PHONES = {
    icon: phone,
    items: [{
        title: 'North America & Latin America:',
        content: [{
            text: 'Toll-Free: +1 866 575 3545',
        }, {
            text: 'Non Toll-Free: +1 260 429 3935',
        }],
    }, {
        title: 'Europe, Middle East & Africa:',
        content: [{
            text: '+44 844 588 1172',
        }],
    }, {
        title: 'Asia Pacific:',
        content: [{
            text: 'Singapore: +65 6506 6078',
        }, {
            text: 'China: +86 21 6332 2998',
        }],
    }],
};

export const DOMESTIC_CAROUSEL_TALK_CONTACTS_PHONES = {
    icon: phone,
    items: [{
        title: 'North America',
        content: [{
            text: 'Toll-Free: +1 866 575 3545',
        }, {
            text: 'Non Toll-Free: +1 260 429 3935',
        }],
    }]
};

export const CAROUSEL_TALK_CONTACTS_EMAILS = {
    icon: mail,
    items: [{
        content: [{
            href: 'mailto:mobilityconnect@sirva.com',
            text: 'MobilityConnect@sirva.com',
        }],
    }],
};
