import React, { PureComponent } from 'react';
import TableRow from './TableRow';

import './styles.scss';

class Stepper extends PureComponent {
    render() {
        const { steps } = this.props;

        return (
            <div className="TableRowContainer">
                {steps.map((step, i) => (
                    <TableRow key={step.id} {...step} index={i} />
                ))}
            </div>
        );
    }
}

export default Stepper;
