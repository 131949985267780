import * as React from "react";
import { Icon } from "components/common";
import LDPFinalMovePackageBNewHire from "../EligibleMove/LDPFinalMovePackageBNewHire";
import { RouterProps } from "types";
import Fldp from "../Fldp";
import FirstRotation from "../FirstRotation";
import "./styles.scss";

const WhichScenario = (props: RouterProps) => {
    const scenario = [
        {
            id: 1,
            key: "in-procurement",
            title: "The employee is in procurement.",
            value: "InProcurement"
        },
        {
            id: 2,
            key: "FLDP",
            title: "The employee is MBA Finance Leadership Development Program (FLDP).",
            value: "FLDP"
        },
        {
            id: 3,
            key: "not-in-procurement",
            title: "The employee is NOT in procurement and is NOT an MBA FLDP.",
            value: "NonProcurNonFLDP"
        }
    ];

    const clickHandler = (key: string, title: string, value: string): void =>
        props.history.push({
            state: {
                ...props.location.state,
                "procurementKey": key,
                "procurementTitle": title,
                "empProcurementType": value,
            },
        });

    const backHandler = (moveType,policySelection): void =>
        props.history.push({
            state: {
                "moveType": moveType,
                "policySelection": policySelection,
                "empCategoryRepresentation": (props.location.state.empCategoryRepresentation !== undefined) ? props.location.state.empCategoryRepresentation : null,
                "initiationId": (props.location.state.initiationId !== undefined) ? props.location.state.initiationId : null,
                "initiationCountryCode": (props.location.state.initiationCountryCode !== undefined) ? props.location.state.initiationCountryCode : null,
                employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
            },
        });
        if (
            props.location.state &&
            props.location.state["procurementKey"] &&
            props.location.state["procurementKey"] === "in-procurement"
        ) {
            return <LDPFinalMovePackageBNewHire history={props.history} location={props.location} backState={"procurementKey"}/>;
            
        } else if (
            props.location.state &&
            props.location.state["procurementKey"] &&
            props.location.state["procurementKey"] === "FLDP"
        ) {
            return <Fldp history={props.history} location={props.location} />;
        } else if (
            props.location.state &&
            props.location.state["procurementKey"] &&
            props.location.state["procurementKey"] === "not-in-procurement"
        ) {
            return <FirstRotation history={props.history} location={props.location} backState={"procurementKey"}/>;
        } else {

            const { moveType, policySelection } = props.location.state;

            return (
                <article className="domestic-scenario-wrapper">
                    <div className="back-icon" onClick={() => backHandler(moveType,policySelection)} >
                        <Icon name="backIcon" />
                        <p>Go back</p>
                    </div>
                    <h1 className="section-title">
                        Which of the following applies to the employee?
                    </h1>
                    <div className={"reasons flexible wrap jBetween"}>
                            {scenario.map(reason => (
                                <div
                                    key={reason.id}
                                    className={"reason"}
                                    onClick={() =>
                                        clickHandler(reason.key, reason.title, reason.value)
                                    }
                                >
                                    <p className="reason-info">{reason.title}</p>
                                </div>
                            ))}
                        </div>
                </article>
            );
    }
};

export default WhichScenario;
