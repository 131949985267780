import { getDataStore } from './index';

const getUser = state => getDataStore(state).user || {};

export const getUserAuth = state => {
    const user = getUser(state);
    return user.auth || {};
};

export const getUserProfile = state => {
    const user = getUser(state);
    return user.profile || {};
};
