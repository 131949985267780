import * as React from "react";
import { SelectionCard } from "components/common";
import { Icon } from "components/common";
import Lightbulb from "components/common/Images/Lightbulb.svg";
import NoMoving from "components/common/Images/NoMoving.svg";
import JobPolicy from "../JobPolicy/index";
import LongTermTier1 from "../EligibleMove/longTermTier1";
import { RouterProps } from "types";
import PayGrade from "../PayGrade";
import Assignment from "../Assignment";
import "./styles.scss";

const FiftyPercent = (props: RouterProps) => {
    const clickHandler = (value: string): void =>
        props.history.push({
            state: {
                ...props.location.state,
                "fifty-percent": value,
            },
        });

    const backHandler = (): void =>
        props.history.push({
            state: {
                "moveType": props.location.state.moveType,
                "policySelection": props.location.state.policySelection,
                "empCategoryRepresentation": (props.location.state.empCategoryRepresentation !== undefined) ? props.location.state.empCategoryRepresentation : null,
                "initiationId": (props.location.state.initiationId !== undefined) ? props.location.state.initiationId : null,
                "initiationCountryCode": (props.location.state.initiationCountryCode !== undefined) ? props.location.state.initiationCountryCode : null,
                employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
            },
        });
        if (
            props.location.state &&
            props.location.state["fifty-percent"] &&
            props.location.state["fifty-percent"] === "no" &&
            props.location.state["strategic-indent"] === "Accelerated Development"
        ) {
            return <PayGrade history={props.history} location={props.location} />;
        } else if (
            props.location.state &&
            props.location.state["fifty-percent"] &&
            props.location.state["fifty-percent"] === "no" &&
            (props.location.state["strategic-indent"] === "Skill Need" ||
                props.location.state["strategic-indent"] === "Project")
        ) {
            return <Assignment history={props.history} location={props.location} />;
        } else if (
            props.location.state &&
            props.location.state["fifty-percent"] &&
            props.location.state["fifty-percent"] === "no"
        ) {
            return <LongTermTier1 history={props.history} location={props.location} backState={"fifty-percent"}/>;
        }else if (
        props.location.state &&
        props.location.state["fifty-percent"] &&
        props.location.state["fifty-percent"] === "yes"
    ) {
        return <JobPolicy history={props.history} location={props.location} />;
    } else {
        return (
            <article className="fifty-percent">
                <div className="back-icon" onClick={backHandler}>
                    <Icon name="backIcon" />
                    <p>Go back</p>
                </div>
                <h1 className="section-title">
                    Will the employee spend at least 50% of their time in their
                    home country?
                </h1>
                <section className="CardSection">
                    <SelectionCard
                        type="optionSelectionCard"
                        backgroundColor="white"
                        icon={Lightbulb}
                        title="Yes"
                        value="yes"
                        step="fifty-percent"
                        route="/initiate-move/recruitment-selector"
                        onClick={() => clickHandler("yes")}
                    />
                    <SelectionCard
                        type="optionSelectionCard"
                        backgroundColor="white"
                        icon={NoMoving}
                        title="No"
                        value="no"
                        step="fifty-percent"
                        route="/initiate-move/recruitment-selector"
                        onClick={() => clickHandler("no")}
                    />
                </section>
            </article>
        );
    }
};

export default FiftyPercent;
