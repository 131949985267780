import React, { PureComponent } from 'react';

import './style.scss';

export default class Contacts extends PureComponent {
    renderItemContent = (item, key) => {
        const { className = '', href, text } = item;

        const itemClassName = `${className} contacts__contact-content`;
        return (href)
          ? <a className={`${itemClassName} contacts__contact-content_link`} href={href} key={key}>{ text }</a>
          : <p className={itemClassName} key={key}>{ text }</p>;
    };

    renderItems = (items) => {
        return items.map((item, itemIndex) => {
            const { title, content } = item;

            const renderItemContent = this.renderItemContent;
            const convertedContent = (content && content.length > 0)
              ? content.map((bodyItem, bodyItemIndex) => renderItemContent(bodyItem, `cotacts__contact-content-${bodyItemIndex}`))
              : content;

            return (
              <li className='contacts__contact' key={`contacts__contact-${itemIndex}`}>
                  {
                      title && (
                          <p className='contacts__contact-header'>
                              { title }
                          </p>
                      )
                  }
                  {
                      convertedContent && (
                          <div className='contacts__contact-items'>{ convertedContent }</div>
                      )
                  }
              </li>
            );
        });
    };

    render() {
        const { className = '', title, contacts } = this.props;

        const { icon, items } = contacts;

        return (
          <div className={`${className} contacts`}>
              <div className='contacts__header'>
                  { title }
                  <div className='contacts__content'>
                      { icon && <img className='contacts__icon' src={icon} alt="" /> }
                      <ul className='contacts__items'>
                          { this.renderItems(items) }
                      </ul>
                  </div>
              </div>
          </div>
        );
    }
}
