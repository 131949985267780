import React from 'react';
import globus from 'components/common/Images/world.svg';
import hospital from 'components/common/Images/hospital.svg';
import globusiwithman from 'components/common/Images/personWorld.svg';
import pin from 'components/common/Images/pin.svg';
import scroll from 'components/common/Images/scroll.svg';
import info from 'components/common/Images/info.svg';
import house from 'components/common/Images/house.svg';
import people from 'components/common/Images/people.svg';
import dollar from 'components/common/Images/dollar.svg';
import infoFile from 'components/common/Images/infoFile.svg';
import idea from 'components/common/Images/idea.svg';
import moving from 'components/common/Images/moving.svg';
import docs from 'components/common/Images/docs.svg';
import sign from 'components/common/Images/sign.svg';
import Asset from '../../common/Asset';
import HistoryA from '../../common/HistoryA'

export const subtitles = {
    international : {
      explore : "Understand the journey",
      getStarted : "How it works",
      prepPlan : "Plan for a Smart Start",
      arrival : "Adjust to New Experiences",
      inHostCountry : "Enjoy your Journey",
      journeyBack : "Take the Journey Back"
    },
    domestic : {
      explore : "Make a Smart Choice",
      getStarted : "The Journey Begins",
      prepPlan : "Plan for a Smart Start",
      relocate: "Adjusting to New Experiences"
    }
  }

export const titles = {
    international : {
      explore : "Why consider a global experience",
      getStarted : "Ready to get started?",
      prepPlan : "How to prepare for a global assignment",
      arrival : "What to expect on a new assignment",
      inHostCountry : "How to maximize a global assignment experience",
      journeyBack : "What you need to know about returning home"
    },
    domestic : {
      explore : "Why consider a move to another city in the U.S.?",
      getStarted : "Ready to get started?",
      prepPlan : "How to prepare for a U.S. Domestic move",
      relocate: "What to expect in your new office location"
    }
  }


export const profileGalButtons = {
    international : {
        prepPlan : [{title:'View Allowances', url:'/assignment-letter'}, {title:'View Balance Sheet', url:'/my-documents/balanceSheets', type: 'balance'}],
        arrival : [{title:'View Allowances', url:'/assignment-letter'}, {title:'View Balance Sheet', url:'/my-documents/balanceSheets', type: 'balance'}],
        inHostCountry : [{title:'View Allowances', url:'/assignment-letter'}, {title:'View Balance Sheet', url:'/my-documents/balanceSheets', type: 'balance'}],
    },
    domestic : {
      prepPlan : [{title:'View Relocation Details', url:'/assignment-letter'}, {title:'Complete Relocation Tasks', url:'/tasks'}],
      relocate:  [{title:'View Relocation Details', url:'/assignment-letter'}],
    }
  }

export const topContent = {
    international : {
      explore : "Global experiences develop leaders with the diverse thinking necessary to drive growth and innovation for the future success of Johnson & Johnson.",
      getStarted : "Global experiences provide you with the opportunity to develop an understanding of customer and business needs in different parts of the world, expand your business skills and ability to work across cultures, build your global network, and pick up some language skills. It may also lead to enhanced career prospects.",
      prepPlan : "When it comes to global assignments, there is a lot to do to prepare for your journey before leaving. Understanding the process and assignment details is the first place to start.",
      arrival : "A new global assignment allows employees and their families unique experiences, as well as challenges. Learn more about how to navigate a new position in a new locale.",
      inHostCountry : "Understanding the responsibilities of an employee on assignment is important to get the most out of an experience and minimize the stress for you and your family.",
      journeyBack : "At the end of a global assignment, the journey back can be filled with questions. Find out more about what steps an employee takes when they consider relocating."
    },
    domestic : {
      explore : "Expanding your professional experience across J&J U.S. companies, functions and office locations can help you build your skill sets, professional network, develop diverse thinking and insights, and ultimately enhance your career.",
      getStarted : "Because U.S. Domestic moves are determined by the job itself, the relocation process is closely coupled with the job application & interviewing process.  Read on to learn how to move forward.",
      prepPlan : "Relocating is a time of transition for you and your family and will require adapting to a new lifestyle. Johnson & Johnson will provide various types of support to help reduce disruption and enable you to get settled in your new location and job as quickly as possible.",
      relocate : "U.S. Domestic relocation can be both exciting and a challenge for employees and their families. Learn more about how to navigate moving to a new position and a new city."
    }
  }

export const questions = {
  domestic :{
    explore: {
    tagline: 'Explore', subHeader: 'Common Questions', details:
      [{
          icon: people,
          title: 'How do I know if I am eligible for a U.S. Domestic relocation?',
          body: 'U.S. Domestic relocation is for job moves within the US.  The job posting will dictate whether or not relocation benefits will be offered as part of the total compensation package.  As an employee, you will work with the Johnson & Johnson recruiters to understand if a job you are interested in offers relocation support.',
          urlText: []
      },
      {
          icon: globus,
          title: 'How does distance factor into relocation benefits?',
          body: (<div>
                   <p>Your relocation must result in a change of your primary residence and must meet minimum distance requirements. Before you begin the relocation process, you will first need to determine if your move meets this distance requirement.</p>
                   <p>The following two distance tests should be calculated by plotting the most direct (shortest, not fastest) land route available as calculated by Google Maps. If you are employed in a regional position and are considered a “non-locational” colleague, you should designate your “work location” as your new territory city.</p>

                   <h4>Pre-Relocation</h4>
                      <p>Your new work location must be at least 50 miles further than your commute was to your old work location. You can use the formula below to help you calculate this.</p>

                      <ul>
                          <li>Measure the distance from your origination home to your new work location/territory city</li>
                          <li>Measure the distance from your origination home to your old work location/territory city</li>
                          <li>Subtract to find the difference. If this number is more than 50, you meet the distance requirement for location</li>
                      </ul>

                   <h4>Post-Relocation</h4>
                      <p>Your new home must be at least 25 miles closer to your new work location than your origination home was. Even if you do not have a new home picked out yet, the formula below can be used to help you determine a new location.</p>

                      <ul>
                          <li>Measure the distance from your origination home to your new work location/territory city</li>
                          <li>Measure the distance from your new home to your new work location/territory city</li>
                          <li>Subtract to find the difference. If this number is more than 25, you meet the requirement</li>
                      </ul>

                 </div>
                ),
          urlText: []
      },
      {
          icon: moving,
          title: 'How does Johnson & Johnson determine which relocation package is offered?',
          body: 'Relocation packages are based on your pay-grade level and your homeowner/renter status. To learn how relocation packages differ for homeowners and renters, please read our Homeowner & Renter Packages Summary.',
          urlActions: [()=> window.location.replace('/resources/dom/')],
          urlText: ['U.S. Domestic Relocation Packages']
      },
      {
          icon: house,
          title: 'Who will assist me with the relocation & moving process?',
          body: 'Johnson & Johnson partners with an experienced global relocation services company. They offer a range of services to help Johnson & Johnson employees manage their physical relocation.',
          urlText: []
      },
      {
          icon: globusiwithman,
          title: 'Can I relocate to another U.S. city temporarily?',
          body: 'U.S. Domestic relocations are consider permanent in nature',
          urlText: []
      },
      ]
  },
  getStarted: {
    tagline: 'Get Started', subHeader: 'Common Questions', details:
    [{
        icon: pin,
        title: 'What are the top 10 U.S. locations for relocation?',
        body: (<div>Johnson & Johnson’s Top U.S. Destination Cities are:<ol><li>{'Raritan' + String.fromCharCode(47)+ 'Somerville, NJ'}</li><li>New Brunswick, NJ</li><li>{'Fort Washington' + String.fromCharCode(47)+ 'Springhouse, PA'}</li><li>Titusville, NJ</li><li>Skillman, NJ</li><li>Cincinnati, OH</li><li>Jacksonville, FL</li><li>Raynham, MA</li><li>Irvine, CA</li><li>West Chester, PA</li></ol></div>),
        urlText: []
    },
    {
        icon: globus,
        title: 'How long does the relocation process take?',
        body: 'Relocation timelines vary depending on the position start date and detailed requirements as determined by the job, distance to be moved and candidate / family considerations. All relocation benefits must be completed within 12 months of the transfer, or start of the new job.',
        urlText: []
    },
    {
        icon: scroll,
        title: 'What is a U.S. Domestic Relocation Letter?',
        body: 'If you are extended a job offer with relocation support, the details of your relocation package and allowances will be documented in your U.S. Domestic Relocation Letter. You will receive this letter in addition to your job offer documentation. You will required to sign the letter as well as the associated Relocation Repayment Agreement before services will be initiated.',
        urlText: []
    },
    {
        icon: docs,
        title: 'Where can I find details about my relocation package?',
        body: 'All Relocation Package details, policies, checklists and other reference materials are provided on this website. Visit the Resources section to browse detailed documentation.',
        urlActions: [()=>window.open('/resources')],
        urlText: ['View Resources']
    },
    {
        icon: info,
        title: 'If I have a question about the move process, who do I talk to?',
        body: 'After you have signed your U.S. Domestic Relocation letter and the services have been initiated, you will be assigned a dedicated Consultant from our service provider.  Your Consultant will provide support, guidance and answer any questions you might have about our policies.',
        urlText: []
    },
    ]
  },
  prepPlan: {
    tagline: 'Preparation & Planning', subHeader: 'Common Questions', details:
    [{
        icon: info,
        title: 'If I have a question about the move process, who do I talk to?',
        body: 'After you have signed your U.S. Domestic Relocation letter and the services have been initiated, you will be assigned a dedicated Consultant from our service provider who will provide support, guidance and answer any questions you might have about our policies.',
        urlText: ['']
    },
    {
        icon: globus,
        title: 'Can I hire my own relocation services?',
        body: 'You should not take any action related to your move before discussing your relocation benefits with your Relocation Consultant. Benefits can be jeopardized if you do not work through the JnJ Procured network suppliers. Please discuss which suppliers and vendors to use for every aspect of your move with your SIRVA Relocation Consultant.',
        urlText: []
    },
    {
        icon: house,
        title: 'How do I start the home finding process?',
        body: 'After you accept the job offer and relocation package, you will be connected with your Relocation Consultant who will help you organize and initiate home finding services.  Do not initiate any home finding services prior to connecting with the Relocation Consultant. ',
        urlText: []
    },
    {
        icon: house,
        title: 'What important home selling tips do I need to know?',
        body: (
                <ul>
                    <li>Do not sign a listing agreement until you have spoken with your Relocation Consultant.</li>
                    <li>Do not sign any sales agreements without speaking with your Relocation Consultant, otherwise, benefits maybe jeopardized.</li>
                    <li>Do not sign a contract (or any other document) with the buyers or take any money as a deposit from the broker or prospective buyer. Be aware that oral agreements between you and a potential buyer may be considered to be legally binding agreements.</li>
                </ul>
        ),
        urlText: []
    },
    {
        icon: docs,
        title: 'Where can I find details about my relocation package?',
        body: 'All Relocation Package details, policies, checklists and other reference materials are provided on this website. Visit the U.S. Domestic Relocation Resources section to browse detailed documentation.',
        urlActions: [()=>window.open('/resources')],
        urlText: ['View U.S. Domestic Relocation Resources']
    },
    {
        icon: infoFile,
        title: 'How long does the relocation process take?',
        body: 'Relocation timelines vary depending on the position start date and detailed requirements as determined by the job, distance to be moved and candidate / family considerations. All relocation benefits must be completed within 12 months of the transfer, or start of the new job.',
        urlText: ['']
    },
    ]
  },


  relocate: {
    tagline: 'Relocate', subHeader: 'Common Questions', details:
    [{
        icon: infoFile,
        title: 'What happens if I decide to leave my new job position after I relocate?',
        body: 'A Repayment Agreement will be in effect for two years following the start date of Employment at the new location. You will be responsible for repayment of all relocation fees per the terms of your signed Repayment Agreement.  The terms of the Repayment Agreement apply both for voluntary resignations and terminations by a J&J company or affiliate for just cause.',
        urlActions: [()=>new Asset({assetType:'repayment-agreement', assetId:'agreement-process'}).execute()],
        urlText: ['U.S. Domestic Relocation Repayment Agreement']
    },
    {
        icon: docs,
        title: 'Do I need to track my expenses?',
        body: 'There will be few instances that might require you to submit an expense reimbursement request. For those expenses that will be directly reimbursed to you, your Relocation Management Company will process your expense reimbursements when your completed relocation expense report and receipts are received. The expenses must be submitted within 30 days of occurrence. You should expect reimbursement within 7 to 10 business days.',
        urlText: ['']
    },
    {
        icon: pin,
        title: 'How do I track my expenses?',
        body: 'Move.jnj.com will provide access to our Relocation Expense Tracking system. You will come to this very website to create expense reports, upload receipts, and track payment status.',
        urlText: []
    },
    {
        icon: sign,
        title: 'Do I need to do anything specific when I start my new job?',
        body: 'When you begin your new job, you may need to update your payroll and W4.',
        urlActions: [()=>window.open('http://oursource.biz')],
        urlText: ['Visit OurSource']
    },
    ]
  }
},
  international :{
    explore: {
    tagline: 'Explore', subHeader: 'Common Questions', details:
      [{
          icon: globusiwithman,
          title: 'How do I know if I am eligible to live in another country?',
          body: 'Immigration compliance is a critical element of international assignments and transfers. No one can legally work in another country without the required visa and work permit authorizations.  In order to ensure immigration compliance around the world, J&J engages the support of an industry leading global immigration law firm for all international assignments and transfers.',
          urlActions: [()=>new Asset({assetType:'global-immigration', assetId:'immigration-complexity-matrix'}).execute()],
          urlText: ['Immigration Timelines']
      },
      {
          icon: hospital,
          title: 'Do my health benefits extend to international locations?',
          body: 'While on international assignment your current benefit plans may not be sufficient or may not provide coverage while outside your home country. As an expatriate, you may not be eligible for medical coverage in the host country schemes. When this is the case, Johnson & Johnson partners with CIGNA Global Health Benefits to ensure assignees and their families receive healthcare coverage. Use the link to CIGNA’s website to learn more about what to expect from the Cigna International health plan while on assignment and other useful information for pre-departure planning.',
          urlActions: ['http://www.cignaenvoy.com'],
          urlText: ['Cigna Envoy']
      },
      {
          icon: dollar,
          title: 'How do taxes work?',
          body: 'Complying with the various tax requirements in both the home and host countries is one of the more complex areas of an international assignment or transfer. Johnson & Johnson partners with a highly skilled global employment tax services partner to deliver tax orientations and tax compliance services to help assignees and transferees plan, prepare and comply.',
          urlText: []
      },
      {
          icon: house,
          title: 'Who will assist me with the relocation & moving process?',
          body: 'Johnson & Johnson partners with an experienced global relocation and destination services company. They offer a range of services to help Johnson & Johnson assignees and transfers manage their physical relocation.',
          urlText: []
      },
      {
          icon: globus,
          title: 'How do I prepare for a change in culture?',
          body: 'While a global experience offers employees the opportunity to learn and grow by working in a different country, there are also many changes in culture, language and business environment that require consideration and preparation.',
      },
      ]
  },
  getStarted: {
    tagline: 'Get Started', subHeader: 'Common Questions', details:
    [{
        icon: globus,
        title: 'What percentage of J&J employees are relocated every year?',
        body: 'Less than 1% of Johnson & Johnson employee population globally.',
    },
    {
        icon: pin,
        title: 'How long does this process take?',
        body: 'There are many variables that impact the process of moving globally. Immigration is by far the most important to deployment timelines. The specific visa and work permit requirements of a country determine when an employee can legallly start work. As immigration regulations vary country to country, and are subject to change, it is best to plan early, well in advance of the need to be present in the country. No employee is authorized to start work prior to authorization by the Immigration Services Provider.',
    },
    {
        icon: scroll,
        title: 'What is a Global Assignment Letter or GAL?',
        body: 'A Global Assignment Letter confirms the benefits that pertain to your relocation. When received, your signature on the Global Assignment Letter will confirm your acceptance of the terms and conditions of the assignment relocation.',
    },
    {
        icon: info,
        title: 'If I have a question about the move process, who do I talk to?',
        body: 'Once your manager has initiated the move process, you will be assigned a dedicated consultant from our relocation service provider who will provide support, guidance and answer any questions you might have about our Johnson & Johnson policies and benefits.',
    },

    ]
  },
  prepPlan: {
    tagline: 'Get Started', subHeader: 'Common Questions', details:
    [{
        icon: globusiwithman,
        title: 'How long does the immigration process take?',
        body: 'There are many variables that impact the process of moving globally.  Immigration is by far the most important to deployment timelines.  The specific visa and work permit requirements of a host country determine when an employee can legally start work.  As immigration regulations vary country to country, and are subject to change, it is best to plan early, well in advance of the need to be present in the country.   No employee is authorized to start work prior to authorization by the Immigration Services Provider.',
    },
    {
        icon: house,
        title: 'How do I find a home?',
        body: 'Your choice of housing will vary according to the type of relocation (temporary or indefinite).',

    },
    ]
  },
  arrival: {
    tagline: 'Arrival', subHeader: 'Common Questions', details:
    [{
        icon: people,
        title: 'What can I do to help my family adjust to our new home?',
        body: (<div>
                <p>Living and working at a new location can be both exciting and challenging. It is a time of transition for you and your family and will require that you adapt to a new lifestyle. J&J will provide various types of support to help reduce disruption and enable you to get settled in your new location and job as quickly as possible.</p>
                <p>J&J also provides ongoing information on travel, weather, health and other country alerts via International SOS. All employees engaged in business travel or assignments should keep the contact information for Intl. SOS on their phones and sign up for host country alerts. J&J Member ID Log In for International SOS is 11BMMS000126</p>
                </div>),
        urlActions: ['https://www.internationalsos.com/travel-risk-management?utm_medium=adwords&utm_term=%2Binternational%20travel%20security&utm_source=%7badgroup%7d&gclid=EAIaIQobChMIo6-1s86X5wIVSJyzCh03HwucEAAYASAAEgLOI_D_BwE'],
        urlText: ['Travel & Country Security Services: International SOS']
    },
    {
        icon: info,
        title: 'What if I have questions or need help after I arrive?',
        body: 'Please contact your dedicated relocation consultant for all general inquiries, policy guidance, travel and coordination of relocation services assigned to your move.',
    },
    {
        icon: pin,
        title: 'What do I need to do to prepare for my new position?',
        body: 'Acclimate to your new team and office location.  Engage with key stakeholders.  Adapt to the new country culture and norms.',
    },
    ]
  },
  inHostCountry: {
    tagline: 'In Host Country', subHeader: 'Common Questions', details:
    [{
        icon: people,
        title: 'How can I stay in touch with people at home?',
        body: 'Leveraging social networks is a great way to stay in touch with friends and family at home. You can also share your adventure with others by including the hashtag #jnjmoves with any photos you or your family post on Instagram. Your photos can help assignees around the world feel connected to others experiencing a similar journey.',
    },
    {
        icon: dollar,
        title: 'Who do I talk to about taxes?',
        body: 'Complying with the various tax requirements in both the home and host countries is one of the more complex areas of an international assignment or transfer. Johnson & Johnson partners with an industry leading tax provider that manages tax orientations and tax compliance services to help assignees and transferees plan, prepare and comply.',
    },
    {
        icon: infoFile,
        title: 'How do taxes work?',
        body: 'Immigration compliance is a top priority for J&J, therefore we work with an industry leading global immigration law firm who will be responsible for obtaining the necessary permits and visas for you and any eligible family members. Please remember that any change in your employment circumstances (job title changes, work or local address, dependent information etc.) must be immediately communicated to the Immigration Services provider so as to maintain work permits and visas up to date. No independent actions are to be taken by employees to make changes in their visa status without the express direction of J&J’s Immigration Services Provider.',
    },
    ]
  },
  journeyBack: {
    tagline: 'The Journey Back', subHeader: 'Common Questions', details:
    [{
        icon: globusiwithman,
        title: 'What happens at the end of assignment?',
        body: (
            <div>
            <p>For short term assignments, the Relocation Company will reach out to Host Managers 3 months prior to end of assignment to begin discussing next steps. For long-term assignments, the Relocation Company will reach out to Host Managers 6 months prior to end of assignment.</p>
            <p>At that time, Host Managers will begin evaluating next steps for the employee based on their performance, career objectives and the strategic intents of the business. If an assignment extension is desired, the Relocation Company will work with the Host Manager to update the Global Assignment Letter, identify a new position end date, and support immigration as needed.</p>
            </div>),
    },
    {
        icon: idea,
        title: 'Who is responsible for identifying a return role for the employee?',
        body: 'Ultimately, the employee is responsible for their post-assignment career path. An assignee is encouraged to take advantage of opportunities to expand his/her networking throughout the assignment. For long-term assignments, we recommend employees actively begin sourcing new opportunities 6-8 months prior to end of assignment. For short-term assignments, we recommend employees actively begin sourcing new opportunities 3 months prior to end of assignment.',
    },
    {
        icon: hospital,
        title: 'How do taxes work?',
        body: 'Complying with the various tax requirements in both the home and host countries is one of the more complex areas of an international assignment or transfer. Johnson & Johnson partners with a highly skilled global employment tax services partner to deliver tax orientations and tax compliance services to help assignees and transferees plan, prepare and comply.',
    },
    {
        icon: moving,
        title: 'Who assists with the relocation logistics for returning back to the home country?',
        body: 'Relocation to the home country will leverage the same process and procedures followed at the beginning of the global assignment.',
    },
    ]
  }
}

};



export const orderedData = [
    'The employee and manager identify a move opportunity',
    'The manager creates the position',
    'Then, the manager initiates talent movement process',
    'The employee provides any required family information',
    'Formal approvals are received for funding',
    'Global Assignment Letter is reviewed and signed by the employee',
    'Other authorized services are initiated ',
    'Employee consultations occur',
    'Prep & Plan for relocation begins',
];


export const taskList = {
  domestic:{
    explore:{
      title:'Things to do while exploring the opportunities',
      tasks:[{
        checked: false,
        title: 'Evaluate your career goals'
    },
    {
      checked: false,
      title: 'Talk to your manager'
    },
    {
        checked: false,
        title: (<span>
            Explore available <HistoryA href='https://mynextstep.jnj.com' target='_blank' rel='noopener noreferrer' className='task-link'>job opportunities</HistoryA>
            </span>),
    },
    {
        checked: false,
        title: (<span>
            Update your <HistoryA href='http://jjworkday.com/' target='_blank' rel='noopener noreferrer' className='task-link'>Workday profile</HistoryA>
            </span>),
    },]
    },
    getStarted:{
      title:'Things to do to get the process started',
      tasks:[{
        checked: false,
        title: 'Select a job of interest'
    },
    {
      checked: false,
      title: 'Advise current Manager of job interest and intent to apply for the position'
    },
    {
        checked: false,
        title: 'Apply to the job opportunity'
    },
    {
        checked: false,
        title: 'Discuss relocation desires with recruiter'
    },
    {
        checked: false,
        title: 'Complete interview process'
    },
    {
        checked: false,
        title: 'Review & approve your offer and relocation package, if offered'
    },]
    },
    prepPlan:{
      title:'Things to do to prepare for your move',
      tasks:[{
        checked: false,
        title: 'Update personal info and addresses with financial service providers'
    },
    {
      checked: false,
      title: 'Check with bank about new branches and/or affiliates'
    },
    {
        checked: false,
        title: 'Secure valuables remaining in safe-deposit boxes'
    },
    {
        checked: false,
        title: 'Review and modify automatic payroll deductions'
    },
    {
        checked: false,
        title: 'Notify children’s school of move'
    },
    {
        checked: false,
        title: 'Arrange for transfer of school records'
    },
    {
        checked: false,
        title: 'Obtain extra copies of school records/curricula'
    },
    {
        checked: false,
        title: 'Obtain directory of new schools'
    },
    {
        checked: false,
        title: 'Review and select schools'
    },
    {
        checked: false,
        title: 'Consult Pediatrician about medications, vaccinations, etc.'
    },
    {
        checked: false,
        title: 'Review/Use Checklist shared by Susy from SIRVA'
    },]
    },
    relocate:{
      title:'Things to do now that you’ve arrived',
      tasks:[{
        checked: false,
        title: 'Move into your new home'
    },
    {
      checked: false,
      title: 'Hook up utilities'
    },
    {
        checked: false,
        title: 'Check in with your new manager'
    },
    {
        checked: false,
        title: 'Enroll your children in school'
    },
    {
        checked: false,
        title: 'Test your commute to work'
    },
    {
        checked: false,
        title: 'Start your new job!'
    },
    {
        checked: false,
        title: 'View the complete checklist'
    },
]
    },
  },
  international:
  {
    explore:{ title: 'Things to do while exploring the opportunities', tasks: [
  {
      checked: false,
      title: 'Evaluate your career goals'
  },
  {
      checked: false,
      title: 'Evaluate family readiness for a global move'
  },
  {
      checked: false,
      title: 'Talk to your manager',
  },
  {
      checked: false,
      title: (<span>
          Explore available <a href='https://mynextstep.jnj.com' target='_blank' rel='noopener noreferrer' className='task-link'>job opportunities</a>
          </span>),
  },
  {
      checked: false,
      title: (<span>
          Take the <Asset isLink='true' assetType='int-planning' assetId='cultural-assessment-guide' linkClassName='task-link' linkText='Cultural Assessment' />
          </span>),
  },
  {
      checked: false,
      title: (<span>
          Update your <HistoryA href='http://jjworkday.com/' target='_blank' rel='noopener noreferrer' className='task-link'>Workday profile</HistoryA>
          </span>),
  },
  ]},
  getStarted: {
    title: 'Things to do to get the process started',
    tasks:[
      {
        checked: false,
        title: 'Meet with your manager'
      },
      {
        checked: false,
        title: 'Select a move opportunity'
      },
      {
        checked: false,
        title: 'Provide all documentation for self and family required by the Immigration Services Provider',
      },
      {
        checked: false,
        title: 'Research schools for your children',
      },
      {
        checked: false,
        title: 'Take language classes',
      },
      {
        checked: false,
        title: 'Prepare for moving',
      },
      {
        checked: false,
        title: 'Work with moving company on logistics',
      },
      {
        checked: false,
        title: 'Schedule departing flights',
      },

    ],
  },
  prepPlan: {
    title: 'Things to do to prepare for your relocation',
    tasks:[
      {
        checked: false,
        title: 'Find housing'
      },
      {
        checked: false,
        title: (<span>Complete the <a href='https://jnj.sharepoint.com/sites/leadershipandlearning/Pages/CourseDetail.aspx?CourseUniqueID=1140' target='_blank' className='task-link' rel='noopener noreferrer'>Cross Cultural Training</a></span>)
      },
      {
        checked: false,
        title: 'Provide all documentation for self and family required by the Immigration Services Provider',
      },
      {
        checked: false,
        title: 'Research schools for your children',
      },
      {
        checked: false,
        title: 'Take language classes',
      },
      {
        checked: false,
        title: 'Prepare for moving',
      },
      {
        checked: false,
        title: 'Work with moving company on logistics',
      },
      {
        checked: false,
        title: 'Schedule departing flights',
      },

    ],
  },
  arrival: {
    title: "Things to do now that you've arrived",
    tasks:[
      {
        checked: false,
        title: 'Move into your new home'
      },
      {
        checked: false,
        title: 'Schedule a tax briefing'
      },
      {
        checked: false,
        title: 'Check in with your new manager',
      },
      {
        checked: false,
        title: 'Enroll your family in health benefits',
      },
      {
        checked: false,
        title: 'Obtain new medical ID cards',
      },
      {
        checked: false,
        title: 'Enroll your children in school',
      },
      {
        checked: false,
        title: 'Continue language classes',
      },
      {
        checked: false,
        title: 'Start your new job!',
      },

    ],
  },
  inHostCountry: {
    title: "Things to do while you're in your host country",
    tasks:[
      {
        checked: false,
        title: 'Review your Balance Sheet quarterly'
      },
      {
        checked: false,
        title: 'Plan trips home'
      },
      {
        checked: false,
        title: 'Get tax assistance as needed',
      },
      {
        checked: false,
        title: 'Manage your health',
      },
      {
        checked: false,
        title: 'Meet quarterly with your Move Manager',
      },
    ],
  },
  journeyBack: {
    title: 'Things to do while planning the journey back',
    tasks:[
    {
        checked: false,
        title: 'Evaluate your career goals'
    },
    {
        checked: false,
        title: 'Evaluate family readiness for a global move'
    },
    {
        checked: false,
        title: 'Talk to your manager',
    },
    {
        checked: false,
        title: (<span>
            Explore available <HistoryA href='https://mynextstep.jnj.com' target='_blank' rel='noopener noreferrer' className='task-link'>job opportunities</HistoryA>
            </span>),
    },
    {
        checked: false,
        title: (<span>
            Take the <Asset isLink='true' assetType='int-planning' assetId='cultural-assessment-guide' linkClassName='task-link' linkText='Cultural Assessment' />
            </span>),
    },
    {
        checked: false,
        title: (<span>
            Update your <HistoryA href='http://jjworkday.com/' target='_blank' rel='noopener noreferrer' className='task-link'>Workday profile</HistoryA>
            </span>),
    },
    ],
  },
}

};
