import React, { PureComponent } from 'react';
import moment from 'moment';
import './style.scss';
import {LONG_DATE_FORMAT} from "../../../consts";

export default class TaskCard extends PureComponent {
    render() {
        return (
            <div className="TaskCard" onClick={() => this.props.onSelect(this.props.taskId, this.props.title, this.props.dueDate, this.props.linkUrl)}>
                <div className="task-card-content flexible vertical">
                    <h1 className="singleLine truncate">{this.props.title}</h1>
                    <span>Due: {moment(this.props.dueDate).format(LONG_DATE_FORMAT)}</span>
                </div>
            </div>
        )
    }
}
