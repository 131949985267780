import React, { PureComponent } from 'react';
import { DatePicker as MUIDatePickcer, InlineDatePicker } from "material-ui-pickers";
import { detect } from 'detect-browser';
import { Icon } from 'components/common';


import './styles.scss';

export default class Customized extends PureComponent {

    handleChange = (date) => {
        // date here is Moment date object
        this.props.onChange && this.props.onChange(date);
    };

    render() {
        const inputAdormentProps = (detect().name === 'ie') ? {
            style: {
                position: "absolute",
                right: 0,
                bottom: 5
            }
        } : {};

        const {
            errorMessages,
            helperText,
            label,
            showValidationMessages,
            formatToShow,
            inline,
            nativeProps,
            minDate,
            shouldDisableDate,
            value, 
            labelFunc
        } = this.props;

        const DPComponent = inline ? InlineDatePicker: MUIDatePickcer;
        return (

            <div className="DatePicker" style={{display: "flex", flexDirection: "row"}}>
                <DPComponent
                    onChange={this.handleChange}
                    errorMessages={errorMessages}
                    value={value}
                    minDate={minDate}
                    label={label}
                    labelFunc={labelFunc}
                    format={formatToShow}
                    helperText={helperText}
                    shouldDisableDate={shouldDisableDate}
                    showValidationMessages={showValidationMessages}
                    disableOpenOnEnter
                    animateYearScrolling={false}
                    keyboard
                    keyboardIcon={<Icon name={'chevronBlack'} width={15} style={{ top: 'calc(50% - 15px)', right: "5px", position: 'absolute' }}/>}
                    autoOk
                    clearable={false}
                    InputAdornmentProps={inputAdormentProps}
                    {...nativeProps}
                />
                { (errorMessages && showValidationMessages) && (errorMessages.map(( message, i ) => (
                        <p className="formElement_errorText" key={i}>
                            {message}
                        </p>
                    ))
                )}
                {helperText && (
                    <p className="formElement_helperText">
                        {helperText}
                    </p>
                )}
            </div>
        );
    }
}

