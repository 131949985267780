import * as React from "react";
import { Icon } from "components/common";
import { EligibleMoveTopProps } from "types";
import "./styles.scss";

const EligibleMoveTop = (props: EligibleMoveTopProps) => {

    const backHandler = (): void => {
        if(props.location.state["strategic-indent"]==="Early Career") {
            props.history.push({
                state: {
                    "moveType": props.location.state.moveType,
                    "policySelection": props.location.state.policySelection,
                    "initiationId": (props.location.state.initiationId !== undefined) ? props.location.state.initiationId : null,
                    "initiationCountryCode": (props.location.state.initiationCountryCode !== undefined) ? props.location.state.initiationCountryCode : null,
                    employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
                },
            });
        } else {
            props.history.push({
                state: {
                            ...props.location.state,
                            [props.backState]: "",
                        }
            });
        }
    }
    
    return (
        <>
            <div
                className="back-icon"
                onClick={backHandler}
            >
                <Icon name="backIcon" />
                <p>Go back</p>
            </div>
            <h1 className="section-title">
                We’ve matched your job to a policy!
            </h1>
        </>
    );
};

export default EligibleMoveTop;
