import React, { useContext, useEffect, useState } from "react";
import { Button, Link, Asset, Icon, RadioButton, SpinnerWrapper } from "components/common";
import { History } from "history";
import { useDispatch } from "react-redux";
import { moveInitiationService } from "services";
import { InfoKnockOutCard } from "components/common";
import { INFO_CARD_COLORS } from "consts";
import { globalOrDomesticOpts as moveOpts, nosequentialMoveOpts } from "../keyMappings";
import { sequentialMoveOpts } from "../keyMappings";
import { IsEmpGlobContext } from "../index2";
import { moveOptionContext } from "../GlobalOrDomestic";
import { MovOptionContext } from "../WorkingWithRecruiter";

const SubmitReqWD = (props: {
  disableContinueBtn: (disableContinueButton: boolean) => void;
  history: History;
  navigateTo: (to: string, from: string) => void;
}) => {
  const { location } = props.history;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [continueButtondisabled, setContinueButtondisabled] = useState(false);
  const IsEmpGlobAssign = useContext(IsEmpGlobContext);
  const moveOption = useContext(moveOptionContext);
  const movOption = useContext(MovOptionContext);

  const dataSet = {
    "employeeInfo": (location.state.employeeInfo !== undefined && location.state.employeeInfo !== null) ? location.state.employeeInfo : null,
    "alreadyInAssignment": (location.state.alreadyInAssignment !== undefined) ? location.state.alreadyInAssignment : false,
    "moveOption": (location.state.moveOption !== undefined) ? location.state.moveOption : "NotSet",
    "moveType": (location.state.moveType !== undefined) ? location.state.moveType : "NotSet",
    "payGrade": (location.state.payGrade !== undefined) ? location.state.payGrade : "NotSet",
  }
  const savePreInitDetails = (pathName) => {
    setIsLoading(true);
    // disableContinueBtn(true);
    dispatch(moveInitiationService.actions.setPreInitDetails(dataSet)).then(data => {
      setIsLoading(false);
      // disableContinueBtn(false);
      if (data.success) {
        if (pathName == "non-competitive-initiation") {
          let moveType = dataSet.moveType;
          let initiationId = data.result;
          let initiationCountryCode = null;
          let relocationType = null;
          let policySelection = null;
          let employeeInfo = (location.state.employeeInfo !== undefined && location.state.employeeInfo !== null) ? location.state.employeeInfo : null;
          props.history.push({
            state: {
              moveType,
              policySelection,
              relocationType,
              initiationId,
              initiationCountryCode,
              employeeInfo
            }
          });
          props.navigateTo("package-selection", "pre-initiation");
        } else if (pathName == "submittedSucess") {
          window.location.replace("http://jjworkday.com/");
        } else {
          props.navigateTo(pathName, "");
        }
      } else {
        console.log(data.message);
      }
    })
      .catch(err => console.log(err));
  }

  const handleClick = (e: Event, location: string) => {
    switch (location) {
      case 'continue':
        savePreInitDetails("submittedSucess");
        break;
      case 'already-have-continue':
        savePreInitDetails("non-competitive-initiation");
        break;
      case 'submittedSucess':
        window.location.replace("http://jjworkday.com/");
        break;
    }
  };

  return (
    <>
      <SpinnerWrapper show={isLoading} />
      <div className="primary-reason-global">
        {/* <div className="top">
                <div className="title">Ok, now it's time to create your Requisition ID.</div>
            </div> */}
        {IsEmpGlobAssign == 'no' && movOption == nosequentialMoveOpts.yesWorkingWithRec.text ? <div className="contact-mobility">
          <InfoKnockOutCard
            backgroundColor={INFO_CARD_COLORS.first}
            title="Ok, now it's time to move to the next step."
            content="" />
        </div>
          :
          <div className="contact-mobility">
            <InfoKnockOutCard
              backgroundColor={INFO_CARD_COLORS.first}
              title="Ok, now it's time to create your Requisition ID."
              content="" />
          </div>}
        <div className="divider" />
        <div className="flexible jEnd" style={{ paddingTop: 30, justifyContent: 'space-between' }}>
          {IsEmpGlobAssign == 'yes' && movOption == sequentialMoveOpts.notWorkingWithRec.text && moveOption == moveOpts.domestic.text ?
            <Button
              // disabled={continueButtondisabled}
              onClick={e => { handleClick(e, "continue") }}
              title="Submit and Open Workday"
              className="submit-buttonsubmit-button"
              submit={true}
            />
            :
            <>
              <Button
                // disabled={continueButtondisabled}
                onClick={e => { handleClick(e, "continue") }}
                title="Submit and Open Workday"
                className="submit-buttonsubmit-button"
                submit={true}
              />

              <Button
                //disabled={continueButtondisabled}
                onClick={e => { handleClick(e, "already-have-continue") }}
                title="Already have a Requisition ID, Continue"
                className="continue-button"
                submit={true}
              /> </>
          }
        </div>
      </div>
    </>
  );
};

export default SubmitReqWD;
