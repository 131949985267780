import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
    Icon,
    Button,
    CurrencyFormatter,
    SpinnerWrapper,
    CountryFlags,
    TextCaseFormatter,
  } from "components/common";
import { moveInitiationService } from "services";
import { getEmpJobDetails } from "selectors";
import { MoveStepLayout } from "components/moveInitiationLayouts";
import "./style.scss";

const mapStateToProps = (state: any) => ({
    empJobDetails: getEmpJobDetails(state),
});

const mapDispatchToProps = {
    fetchEmpJobDetails: moveInitiationService.actions.fetchEmpJobDetails,
    setMoveEmppJobDetails: moveInitiationService.actions.setMoveEmppJobDetails
};

var empInfoRepChildEle = null;
var reqInfoRepChildEle = null;

class EmployeeJobDetailsReview extends PureComponent<RouteComponentProps & {fetchEmpJobDetails,setMoveEmppJobDetails}>{
    state = {
        empJobDetails: {},
        empPosJobDetails: {},
        empInfoRepChildEle: null,
        reqInfoRepChildEle: null,
        empInfoPosChildEle: null,
        positionInfoRepChildEle: null,
        isLoading: false,
    };

    saveEmployeejobDetails = (location) => {
        this.setState({isLoading: true});
        let reqInfo = this.props.location.state.reqInfoRepChildEle != null ? {"Report_Entry":[this.props.location.state.reqInfoRepChildEle]} : null;
        const dataSet = {
            "initiationId": (this.props.location.state.initiationId !== undefined) ? this.props.location.state.initiationId : '',
            "wwid": (this.props.location.state.empInfoRepChildEle !== undefined) ? this.props.location.state.empInfoRepChildEle.employeeNumber : '',
            "requisitionId": (this.props.location.state.jobID !== undefined) ? this.props.location.state.jobID : '',
            "positionId": null,
            "employeeInfo": null,
            "reqInfo": (reqInfo),
            "osPosInfo": null
        }
        this.props
                .setMoveEmppJobDetails(dataSet)
                    .then(data => {
                        this.setState({isLoading: false});
                       if(data.success) {
                            this.navigateTo(location);
                       } else {
                            console.log(data.message);
                       }
                    })
                    .catch(err => console.log(err));
        
    }

    componentDidMount(): void {

        if(this.props.empJobDetails === null && ( typeof this.props.location.state === undefined || Object.keys(this.props.location.state).length == 0) ) {
            if(this.props.location.state.employeeInfo === null || this.props.location.state.employeeInfo === undefined ) {
                this.navigateTo("returndashboard");
            }
            this.navigateTo("returndashboard");
        }

        if(typeof this.props.location.state !== undefined) {
            if(this.props.location.state.empInfoRepChildEle !== undefined && this.props.location.state.reqInfoRepChildEle !== undefined) {
                empDetails = {}
                empInfoRepChildEle = (this.props.location.state.empInfoRepChildEle != null && typeof this.props.location.state.empInfoRepChildEle !== 'undefined') ? 
                                        this.props.location.state.empInfoRepChildEle : null;
                reqInfoRepChildEle = (this.props.location.state.reqInfoRepChildEle != null && typeof this.props.location.state.reqInfoRepChildEle !== 'undefined') ? 
                                        this.props.location.state.reqInfoRepChildEle : null;
            } else {    
                var empInfo = (this.props.location.state.employeeInfo !== undefined && this.props.location.state.employeeInfo !== null ) ? this.props.location.state.employeeInfo : null;  
                var empInfoReport = (empInfo != null && empInfo.hasOwnProperty('empNJobDet')) ? 
                                    empInfo.empNJobDet : null;
                empInfoRepChildEle = (empInfoReport != null && empInfoReport.hasOwnProperty('empDetails')) ?
                                    empInfoReport.empDetails : null;

                var empDetails = (this.props == null) ? null : (this.props.hasOwnProperty('empJobDetails') ? this.props.empJobDetails : null);
                var reqInfo = (empDetails != null && empDetails.hasOwnProperty('requisitionInfo')) ? 
                                empDetails.requisitionInfo : null;
                var reqInfoReport = (reqInfo != null && reqInfo.hasOwnProperty('Report_Entry')) ? 
                                reqInfo.Report_Entry : null;
                reqInfoRepChildEle = (reqInfoReport != null && typeof reqInfoReport[0] !== 'undefined') ? 
                                reqInfoReport[0] : null;
            }
        } 
        
        if(empDetails !== null && empInfoRepChildEle !== null && reqInfoRepChildEle !== null ) {
            this.setState({
                empInfoRepChildEle: empInfoRepChildEle,
                reqInfoRepChildEle: reqInfoRepChildEle
            });
            this.props.history.push({
                state: {
                    ...this.props.location.state,
                    empInfoRepChildEle: empInfoRepChildEle,
                    reqInfoRepChildEle: reqInfoRepChildEle
                },
            });
        }
    }

    navigateTo = (location: string) => {
        switch (location) {
            case 'continue':
                this.saveEmployeejobDetails("yesandcontinue");
                break;
            case 'returnhome':
                this.saveEmployeejobDetails("returndashboard");
                break;
            case 'startover':
                let backLocation = "/initiate-move";
                this.props.history.push(
                    backLocation,{...this.props.location.state}
                );
                break;
            case 'backstate':
                    this.props.history.push(
                        "/initiate-move/employee-job-details",{...this.props.location.state}
                    );
                    break; 
            case 'returndashboard':
                    this.props.history.push(
                        "/move-dashboard"
                    );
                    break;
            case 'yesandcontinue':
                    this.props.history.push(
                        "/initiate-move/additional-move-details",{...this.props.location.state}
                    );
                    break;
        }
    };

    render() {
        let { isLoading} = this.state;
        return (
            <section className="emp-job-review-container">
                <div className="RecruitedSelectorContainer">
                    <MoveStepLayout selected={2} />
                    <SpinnerWrapper show={isLoading}>
                    <div className="employee-job-review-container">
                        <div
                        className="back-icon"
                        onClick={() => {
                            this.navigateTo("backstate");
                        }}
                        >
                        <Icon name="backIcon" />
                        <p>Go back</p>
                        </div>
                        <section className="header-section">
                            <h1 className="section-title">
                            We’ve found your employee & their new job. Is this correct?
                            </h1>
                        </section>
                        <section className="body-section">
                        <div className="employee-details">
                            <div className="top-section">
                                <h2>Employee: <span>{empInfoRepChildEle!= null ? TextCaseFormatter(empInfoRepChildEle.firstName) + ((empInfoRepChildEle.middleName !== null)? TextCaseFormatter(empInfoRepChildEle.middleName) + '':'') + ' ' + TextCaseFormatter(empInfoRepChildEle.lastName) : null}</span></h2>
                            </div>
                            <div className="bottom-section">
                                <div className="first">
                                    <ul className="icon-section">
                                        <li className="review-flag-icon">
                                            <div className="icon-block">
                                                <CountryFlags countryCode={empInfoRepChildEle!= null ?empInfoRepChildEle.countryCode : null} countryName={null} />
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="location-section">
                                        <li className="title">Home Country:</li>
                                        <li className="desc">{empInfoRepChildEle!= null ?TextCaseFormatter(empInfoRepChildEle.addresses.country) : null}</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="title">Current Office:</li>
                                        <li className="desc">
                                            {empInfoRepChildEle != null && ((empInfoRepChildEle.addresses.addressLine1 !== null && empInfoRepChildEle.addresses.addressLine1 !== "") ||
                                            (empInfoRepChildEle.addresses.addressLine2 !== null && empInfoRepChildEle.addresses.addressLine2 !== "") ||  
                                            (empInfoRepChildEle.addresses.city !== null && empInfoRepChildEle.addresses.city !== "") || 
                                            (empInfoRepChildEle.addresses.stateProvince !== null && empInfoRepChildEle.addresses.stateProvince !== "") || 
                                            (empInfoRepChildEle.addresses.postalCode !== null && empInfoRepChildEle.addresses.postalCode !== "") ) ?
                                                <>
                                                    {empInfoRepChildEle.addresses.addressLine1 !== null && empInfoRepChildEle.addresses.addressLine1 !== "" ? (
                                                        <>
                                                            {TextCaseFormatter(empInfoRepChildEle.addresses.addressLine1)} <br />
                                                        </>
                                                        ) : null}
                                                    {empInfoRepChildEle.addresses.addressLine2 !== null && empInfoRepChildEle.addresses.addressLine2 !== "" ? (
                                                        <>
                                                            {TextCaseFormatter(empInfoRepChildEle.addresses.addressLine2)}
                                                        </>
                                                        ) : null}
                                                    {empInfoRepChildEle.addresses.city !== null && empInfoRepChildEle.addresses.city !== "" ? (
                                                        <>
                                                            {TextCaseFormatter(empInfoRepChildEle.addresses.city)} 
                                                        </>
                                                        ) : null}
                                                    {empInfoRepChildEle.addresses.stateProvince !== null && empInfoRepChildEle.addresses.stateProvince !== "" && empInfoRepChildEle.addresses.stateProvince != undefined ? (
                                                            <>
                                                                , {(empInfoRepChildEle.addresses.stateProvince).length <= 2 ? TextCaseFormatter(empInfoRepChildEle.addresses.stateProvince,"uper-case") : TextCaseFormatter(empInfoRepChildEle.addresses.stateProvince, "sentence-case")} 
                                                            </>
                                                            ) : null}
                                                    {empInfoRepChildEle.addresses.postalCode !== null && empInfoRepChildEle.addresses.postalCode !== "" ? 
                                                    '/' + empInfoRepChildEle.addresses.postalCode
                                                    : null }
                                                </>
                                                : null }
                                        </li>
                                        <li className="slary title">Current Salary:</li>
                                        <li className="slary-amount desc">
                                            {(empInfoRepChildEle!= null && empInfoRepChildEle.annualizedCurrency !== undefined ) ? (
                                                <CurrencyFormatter 
                                                    currencyCode={empInfoRepChildEle.annualizedCurrency} 
                                                    amount={empInfoRepChildEle.annualizedAmount} 
                                                />
                                            ) : null}
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="manager-email title">Current Job Title:</li>
                                        <li className="manager-emaildetails desc">{empInfoRepChildEle!= null ? TextCaseFormatter(empInfoRepChildEle.jobTitle) : null}</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="manager-wwid title">Pay grade:</li>
                                        <li className="manager-wwiddetails desc">
                                            {(empInfoRepChildEle != null && typeof empInfoRepChildEle.compensationGrade !== 'undefined') ? TextCaseFormatter(empInfoRepChildEle.compensationGrade) : null}
                                        </li>
                                    </ul>
                                </div>
                                <div className="last">
                                    <ul>
                                        <li className="manager-email title">WWID:</li>
                                        <li className="manager-emaildetails desc">{empInfoRepChildEle!= null ?empInfoRepChildEle.employeeNumber : null}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="bottom-section">
                                <div className="first">
                
                                </div>
                                <div>
                                    <ul>
                                        <li className="manager title">Home Manager:</li>
                                        <li className="manager-name desc">{empInfoRepChildEle!= null ? TextCaseFormatter(empInfoRepChildEle.manager) : null}</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="manager-email title">Home Manager Email:</li>
                                        <li className="manager-emaildetails desc">{empInfoRepChildEle!= null ? TextCaseFormatter(empInfoRepChildEle.managerEmail,"lower-case") : null}</li>
                                    </ul>
                                </div>
                                <div className="last">
                                    <ul>
                                        <li className="manager-wwid title">Home Manager WWID:</li>
                                        <li className="manager-wwiddetails desc">{empInfoRepChildEle!= null ?empInfoRepChildEle.homeManagerWWID : null}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="new-job-details">
                            <div className="top-section">
                                <h2>New job:</h2>
                            </div>
                            <div className="bottom-section">
                                <div className="first">
                                    <ul className="icon-section">
                                        <li className="review-flag-icon">
                                            <div className="icon-block">
                                                <CountryFlags countryCode={reqInfoRepChildEle != null ? reqInfoRepChildEle.countryCode : null} countryName={null} />
                                            </div>
                                        </li>
                                    </ul>
                                    {reqInfoRepChildEle != null && (reqInfoRepChildEle.countryCode).toLowerCase() == "na" && (reqInfoRepChildEle.Country).toLowerCase() == "zimbabwe" ? null :
                                        <ul className="location-section">
                                            <li className="title">Host Country:</li>
                                            <li className="desc">{reqInfoRepChildEle != null ? TextCaseFormatter(reqInfoRepChildEle.Country) : null}</li>
                                        </ul>
                                    }
                                </div>
                                <div>
                                    <ul>
                                        <li className="title">New Office:</li>
                                        <li className="desc">
                                            {reqInfoRepChildEle != null && ((reqInfoRepChildEle["Primary_Address_-_Line_1"] !== null && reqInfoRepChildEle["Primary_Address_-_Line_1"] !== "") ||  
                                            (reqInfoRepChildEle.City !== null && reqInfoRepChildEle.City !== "") || 
                                            (reqInfoRepChildEle.State !== null && reqInfoRepChildEle.State !== "") || 
                                            (reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== null && reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== "") ) ?
                                                <>
                                                    {reqInfoRepChildEle["Primary_Address_-_Line_1"] !== null && reqInfoRepChildEle["Primary_Address_-_Line_1"] !== "" ? (
                                                        <>
                                                            {TextCaseFormatter(reqInfoRepChildEle["Primary_Address_-_Line_1"])} <br />
                                                        </>
                                                        ) : null}
                                                    {reqInfoRepChildEle.City !== null && reqInfoRepChildEle.City !== "" ? (
                                                        <>
                                                            {TextCaseFormatter(reqInfoRepChildEle.City)} 
                                                        </>
                                                        ) : null}
                                                    {reqInfoRepChildEle.State !== null && reqInfoRepChildEle.State !== "" && reqInfoRepChildEle.State != undefined ? (
                                                            <>
                                                                , {(reqInfoRepChildEle.State).length <= 2 ? TextCaseFormatter(reqInfoRepChildEle.State,"uper-case") : TextCaseFormatter(reqInfoRepChildEle.State, "sentence-case")}
                                                            </>
                                                            ) : null}
                                                    {reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== null && reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== "" ? 
                                                    '/' + reqInfoRepChildEle["Primary_Address_-_Postal_Code"]
                                                    : null }
                                                </>
                                                : null }
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="title">New Job Title:</li>
                                        <li className="desc">
                                            {reqInfoRepChildEle!= null ? TextCaseFormatter(reqInfoRepChildEle.Job_Posting_Title) : null}
                                        </li>
                                    </ul>
                                </div>
                                <div >
                                    <ul>
                                        <li className="title">New Pay Grade:</li>
                                        <li className="desc">
                                            {(reqInfoRepChildEle != null && reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req != null && reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req != undefined) ? TextCaseFormatter(reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req) : (reqInfoRepChildEle != null && reqInfoRepChildEle.Job_Family_From_Position != null && reqInfoRepChildEle.Job_Family_From_Position != undefined ? TextCaseFormatter(reqInfoRepChildEle.Job_Family_From_Position) : null)}
                                            {/* {reqInfoRepChildEle != null ? TextCaseFormatter(reqInfoRepChildEle.Compensation_Grade_Profile) : null}  */}
                                        </li>
                                    </ul>
                                </div>
                                <div className="last">
                                    <ul>
                                        <li className="title">Job ID:</li>
                                        <li className="desc">{reqInfoRepChildEle != null ? (reqInfoRepChildEle.Job_Requisition_ID) : null}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="bottom-section">
                                <div className="first">

                                </div>
                                <div>
                                    <ul>
                                        <li className="manager title">Host Manager:</li>
                                        <li className="manager-name desc">{reqInfoRepChildEle != null && reqInfoRepChildEle.Hiring_Manager_Name !== null ? TextCaseFormatter(reqInfoRepChildEle.Hiring_Manager_Name.replace(/ *\([^)]*\) */g, "")) : null}</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="manager-email title">Host Manager Email:</li>
                                        <li className="manager-emaildetails desc">{reqInfoRepChildEle != null && reqInfoRepChildEle.Hiring_Manager_Email !== null ? TextCaseFormatter(reqInfoRepChildEle.Hiring_Manager_Email,"lower-case") : null}</li>
                                    </ul>
                                </div>
                                <div >
                                    <ul>
                                        <li className="manager-wwid title">Host Manager WWID:</li>
                                        <li className="manager-wwiddetails desc">{reqInfoRepChildEle != null && reqInfoRepChildEle.Hiring_Manager_Name !== null ? reqInfoRepChildEle.Hiring_Manager_Name.replace(/.*\(|\)/gi,'') : null}</li>
                                    </ul>
                                </div>
                                <div className="last">

                                </div>
                            </div>
                        </div>
                        </section> 
                        <section className="button-section">
                            <div className="button-wrapper">

                                <Button
                                title="Start Over"
                                className="start-button"
                                onClick={() => {
                                    this.navigateTo("startover");
                                }}
                                style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", width:"160px"}}
                                labelStyle={{color:"#CA001B"}}
                                />
                                <Button
                                title="Save and Return to Home"
                                className="save-button"
                                onClick={() => {
                                    this.navigateTo("returnhome");
                                }}
                                style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", width:"205px"}}
                                labelStyle={{color:"#CA001B"}}
                                />
                                <Button
                                title="No, something's wrong"
                                className="wrong-button"
                                onClick={() => {
                                    this.navigateTo("backstate");
                                }}
                                style={{backgroundColor:"#CA001B", border:"1px solid #CA001B", width:"205px"}}
                                labelStyle={{color:"#FFFFFF"}}
                                />
                                <Button
                                title="Yes, continue"
                                className="submit-button"
                                onClick={() => {
                                    this.navigateTo("continue");
                                }}
                                style={{backgroundColor:"#CA001B", border:"1px solid #CA001B", width:"169px"}}
                                labelStyle={{color:"#FFFFFF"}}
                                />
                            </div>
                        </section>                      
                    </div>
                    </SpinnerWrapper>
                </div>    
                </section>
        );
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EmployeeJobDetailsReview));
