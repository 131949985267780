//@flow
import * as React from 'react';
import { onHistoryLinkClicked } from '../../../utils';
import './style.scss';

type ButtonProps = {
    simple?: boolean,
    submit?: boolean,
    disabled?: boolean,
    className?: string,

    action?: String,
    text?: String,
    label?: String,
    externalLink?: String,

    onClick: (e: SyntheticEvent<HTMLDivElement>) => void,

    style?: Object,
    labelStyle?: Object,
    title: React.Node,
    dataKey?: String,
    category?: String
};

export default class Button extends React.PureComponent<ButtonProps> {
    render(): React.Node {
        return(
            <div
                data-key={this.props.dataKey}
                className={`Button ${this.props.simple ? 'simple' : '' || this.props.submit ? 'submit' : ''} ${this.props.disabled ? 'disabled' : ''} ${this.props.className}`}
                onClick={(e) => onHistoryLinkClicked({
                    category: this.props.category ? this.props.category : 'btn-click',
                    action: this.props.action ? this.props.action : this.props.text,
                    label: this.props.label ? this.props.label : (this.props.externalLink ? this.props.externalLink : ''),
                    onClick: (e) => this.props.externalLink ? window.open(this.props.externalLink) : this.props.onClick && this.props.onClick(e)
                }, e)}
                style={this.props.style}
            >
                <span
                    style={this.props.labelStyle}
                >
                    {this.props.title}
                </span>
            </div>
        )
    }
}
