import React from 'react';
import PersonWorld from './PersonWorld.svg';
import './style.scss';

export default () => {
    return (
        <section className="PageUnavailable flexible horizontal aStart">
            <img src={PersonWorld} alt={'Page Unavailable'} />
            <div className="text-container">
                <h1>Sorry. It’s not you. It’s us.</h1>
                <p>
                    We’re experiencing a problem. Please try again later.
                </p>
            </div>
        </section>
    );
}
