import React from 'react';
import './styles.scss';
import { Icon } from 'components/common';

export default class ArticleContent extends React.PureComponent {

    componentWillMount() {
        window.scrollTo(0,0);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.currentArticleIndex !== this.props.currentArticleIndex) {
            window.scrollTo(0,0);
        }
    }

    getPrevArticle = (articles, currentIndex) => (
        (currentIndex === 0) ?
            {
                index: articles.length-1,
                article: articles[articles.length-1]
            } :
            {
                index: currentIndex - 1,
                article: articles[currentIndex - 1]
            }
    );

    getNextArticle = (articles, currentIndex) => (
        (currentIndex === articles.length-1) ?
            {
                index: 0,
                article: articles[0],
            } :
            {
                index: currentIndex + 1,
                article: articles[currentIndex + 1]
            }
    );

    generateRecommendedArticles = (articles, count) => {
        if(Array.isArray(articles)) {
            if(articles.length <= count) {
                return articles.map((article, index) => ({ index, article }));
            }
            const randomIndexes = [];
            const maxIndex = articles.length;

            while (randomIndexes.length < count) {
                const randomIndex = Math.floor(Math.random() * maxIndex);
                if(!randomIndexes.includes(randomIndex) && randomIndex !== this.props.currentArticleIndex)
                    randomIndexes.push(randomIndex);
            }

            return randomIndexes.map(index => ({ index, article: articles[index]}));
        }
        return [];
    };

    render () {
        const { content, articles, currentArticleIndex } = this.props;
        const prevArticle = this.getPrevArticle(articles, currentArticleIndex);
        const nextArticle = this.getNextArticle(articles, currentArticleIndex);
        const recemmendedArticles = this.generateRecommendedArticles(articles, 3);

        return (
            <div className="article-content">
                <div className="description-with-text flexible horizontal">
                    {content.description}
                    <div className="quote flexible vertical noShrink">
                        <span className="apostrophe">&ldquo;</span>
                        <p className="text">{content.quote}”</p>
                    </div>
                </div>
                <div className="article-info-block flexible horizontal">
                    {content.articleInfo}
                    { !!recemmendedArticles.length &&
                        <div className="recommendations flexible vertical noShrink">
                            <h3 className="title">You May Also Like</h3>
                            <div className="rec-articles flexible vertical">
                                { recemmendedArticles.map(articleItem => (
                                    <div
                                        key={articleItem.index}
                                        className="rec-article-item flexible horizontal aCenter"
                                        onClick={() => {this.props.changeArticle(articleItem.index)}}
                                    >
                                        <div className="article-image">{articleItem.article.image}</div>
                                        <div className="article-name">{articleItem.article.header}</div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    }
                    <div className="pagination flexible aCenter jBetween">
                        <div
                            className="step flexible horizontal aCenter prev"
                            onClick={() => {this.props.changeArticle(prevArticle.index)}}
                        >
                            <span className="shadow"><Icon name="chevronRed"/></span>
                            <div className="step-name flexible vertical">
                                <p className="step-type text-left">Previous</p>
                                <p className="article-name">{prevArticle.article.header}</p>
                            </div>
                        </div>
                        <div className="divider" />
                        <div
                            className="step flexible horizontal aCenter jEnd next"
                            onClick={() => this.props.changeArticle(nextArticle.index)}
                        >
                            <div className="step-name flexible vertical">
                                <p className="step-type text-right">Next</p>
                                <p className="article-name text-right">{nextArticle.article.header}</p>
                            </div>
                            <span className="shadow"><Icon name="chevronRed"/></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}