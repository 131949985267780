import React, { PureComponent } from "react";
import { MoveStepLayout } from "components/moveInitiationLayouts";
import { Icon, Button, CurrencyFormatter, SpinnerWrapper, CountryFlags, TextCaseFormatter } from "components/common";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { moveInitiationService } from "services";

import './style.scss'
import DeletePopup from "./deletePopup";

const mapDispatchToProps = {
    setMoveEmppSirvaDetails: moveInitiationService.actions.setMoveEmppSirvaDetails,
    deleteMoveEmppSirvaDetails: moveInitiationService.actions.deleteMoveJobCard
};

class ReviewAndSubmit extends PureComponent<
    RouteComponentProps & {setMoveEmppSirvaDetails, deleteMoveEmppSirvaDetails}
> {
    
    state = {
        empInfoRepChildEle: {},
        reqInfoRepChildEle: {},
        additionalDetailsData: {},
        fundingFinancePartner: {},
        moveType: null,
        relocationPackage: null,
        relocationType: null,
        strategicIndent: null,
        hiringManagerName: null, 
        hiringManagerEmail: null, 
        hiringManagerWWID: null,
        isLoading: false,
        reqInfoAddress: null,
        reqInfoPhone:  null,
        popupOpen: false
    };

    componentDidMount = () => {
        if(this.props.location.state === undefined) {
            this.props.history.push({
                 state: {
                     "moveType": "global",
                     "policySelection": ""
                 },
             });
         } else {
            
            let empInfoRepChildEle = (this.props.location.state.empInfoRepChildEle != null && typeof this.props.location.state.empInfoRepChildEle !== 'undefined') ? 
                                        this.props.location.state.empInfoRepChildEle : null;
            let reqInfoAddress = (this.props.location.state.empInfoRepChildEle != null && typeof this.props.location.state.empInfoRepChildEle !== 'undefined') ? 
                                        this.props.location.state.empInfoRepChildEle.addresses : null;
            let reqInfoPhone = (this.props.location.state.empInfoRepChildEle != null && typeof this.props.location.state.empInfoRepChildEle !== 'undefined') ? 
                                        this.props.location.state.empInfoRepChildEle.phone : null;

            let reqInfoRepChildEle = (this.props.location.state.reqInfoRepChildEle != null && typeof this.props.location.state.reqInfoRepChildEle !== 'undefined') ? 
                                        this.props.location.state.reqInfoRepChildEle : null;

            let hiringManagerName = (reqInfoRepChildEle != null && reqInfoRepChildEle.Hiring_Manager_Name !== null ? reqInfoRepChildEle.Hiring_Manager_Name.replace(/ *\([^)]*\) */g, "") : null);
            let hiringManagerEmail = (reqInfoRepChildEle != null && reqInfoRepChildEle.Hiring_Manager_Email !== null ? reqInfoRepChildEle.Hiring_Manager_Email : null);
            let hiringManagerWWID = (reqInfoRepChildEle != null && reqInfoRepChildEle.Hiring_Manager_Name !== null ? reqInfoRepChildEle.Hiring_Manager_Name.replace(/.*\(|\)/gi,'') : null);
        
            let additionalDetailsData = {
                startDate: this.props.location.state.startDate,
                endDate: this.props.location.state.endDate,
                numberOfFlights: this.props.location.state.numberOfFlights,
                additionalDetails :this.props.location.state.additionalDetails,
                valueInput :this.props.location.state.valueInput,
                californiaTier :this.props.location.state.californiaTier,
            }
            let fundingFinancePartner = (this.props.location.state.partnerName != {} && typeof this.props.location.state.partnerName !== 'undefined') ? 
                                        this.props.location.state.partnerName : {};
            let moveType = "";
            if(this.props.location.state.moveType !== null && this.props.location.state.moveType !== 'undefined') {
                if(this.props.location.state.moveType === 'Domestic' ) {
                    moveType = "Domestic";
                } else {
                    moveType = "Global";
                }
            }

            this.setState({
                empInfoRepChildEle,
                reqInfoRepChildEle,
                additionalDetailsData,
                fundingFinancePartner,
                moveType: moveType,
                relocationPackage: (this.props.location.state.eligibleTitle != null && typeof this.props.location.state.eligibleTitle !== 'undefined') ? this.props.location.state.eligibleTitle : null,
                relocationType: (this.props.location.state.relocationType != null && typeof this.props.location.state.relocationType !== 'undefined') ? this.props.location.state.relocationType : null,
                strategicIndent: (this.props.location.state.strategicIndent  != null && typeof this.props.location.state.strategicIndent  !== 'undefined') ? this.props.location.state.strategicIndent : null,
                hiringManagerName,
                hiringManagerEmail,
                hiringManagerWWID,
                reqInfoAddress,
                reqInfoPhone,
            });
         }
    }
    deleteReqHandler = e => {
        e.preventDefault();
        this.handlePopupState(e, false);
        this.setState({ isLoading: true });
        let initiationId = (this.props.location.state.initiationId !== undefined) ? this.props.location.state.initiationId : '';
        this.props
        .deleteMoveEmppSirvaDetails(initiationId)
            .then(data => {
                this.setState({isLoading: false});
            if(data.success) {
                    this.navigateTo("/profile");
                    console.log('delete success', data);
            } else {
                    console.log(data.message);
            }
            })
            .catch(err => console.log(err));
    }

    handlePopupState = (e, stateVal: boolean) => {
        e.preventDefault();
        this.setState({ popupOpen: stateVal});
    }

    savedataToSirvaMoveDetails = (location) => {
        this.setState({isLoading: true});
        let initiationId = (this.props.location.state.initiationId !== undefined) ? this.props.location.state.initiationId : '';
        this.props
                .setMoveEmppSirvaDetails(initiationId)
                    .then(data => {
                        this.setState({isLoading: false});
                       if(data.success) {
                            this.navigateTo(location);
                       } else {
                            console.log(data.message);
                       }
                    })
                    .catch(err => console.log(err));
    }

    navigateTo = (location: string) => {
        switch (location) {
            case 'lets-go':
                if(this.props.location.state.moveType !== null && 
                    this.props.location.state.moveType !== undefined && 
                    this.props.location.state.moveType === "Domestic" && this.props.location.state.policySelection !== null && 
                    this.props.location.state.policySelection !== undefined && 
                    this.props.location.state.policySelection === "ShortTerm") {
                        this.props.history.push("/initiate-move/employee-job-details",{...this.props.location.state});
                } else {
                    this.props.history.push("/initiate-move",{...this.props.location.state});
                }
                break;
            case 'employee-job-details':
                this.props.history.push(
                    "/initiate-move/employee-job-details",{ ...this.props.location.state }
                );
                break;
            case 'finance-admin':
                this.props.history.push(
                    "/initiate-move/finance-admin",{ ...this.props.location.state }
                );
                break;
            case 'employee-job-details-review':
                let jobDetailsBackState = (this.props.location.state.jobDetailsBackState != null && typeof this.props.location.state.jobDetailsBackState !== 'undefined') ? 
                                            this.props.location.state.jobDetailsBackState : 'employee-job-details';
                this.props.history.push(
                    "/initiate-move/"+jobDetailsBackState,{ ...this.props.location.state }
                );
                break;
            case 'additional-move-details':
                this.props.history.push(
                    "/initiate-move/additional-move-details",{ ...this.props.location.state }
                );
                break;
            case 'success':
                this.props.history.push(
                    "/initiate-move/success",
                    { state: {} },
                );
                break;
            case 'dashboard':
                        this.props.history.push("/move-dashboard",{ ...this.props.location.state });
                    break;
            case 'yesandcontinue':
                    this.savedataToSirvaMoveDetails("success");
                    break;
            case 'saveandreturn':
                    this.savedataToSirvaMoveDetails("dashboard");
                    break;
            default:
                this.props.history.push("/profile", { ...this.props.location.state });
                break;
        }
    }

    render() {
        const {moveType, relocationPackage, relocationType, strategicIndent, hiringManagerName, hiringManagerEmail, hiringManagerWWID, empInfoRepChildEle, reqInfoRepChildEle, additionalDetailsData, fundingFinancePartner, isLoading, reqInfoAddress, reqInfoPhone} = this.state;

        return (
            <div className="RecruitedSelectorContainer">
                <MoveStepLayout selected={5} />
                <SpinnerWrapper show={isLoading}>
                <div className="review-and-submit-container">
                    <div 
                        className="back-icon" 
                        onClick={() => {
                            this.navigateTo("finance-admin");
                        }}
                    >
                        <Icon name="backIcon" />
                        <p>Go back</p>
                    </div>
                    <section className="header-section">
                        <h1 className="section-title">
                        Please review and then submit your request
                        </h1>
                    </section>
                    <section className="body-section">
                        <div className="relocation-package-details">
                            <div className="top-section">
                                <h2>Relocation Package</h2>
                                <a
                                    onClick={() => {
                                         this.navigateTo("lets-go");
                                    }}
                                >Start over</a>
                            </div>
                            <div className="bottom-section">
                                <div>
                                    <ul>
                                        <li className="title">Services Being Initiated:</li>
                                        <li className="desc">{relocationType}</li>
                                    </ul>
                                </div>
                                <div className="second">

                                </div>
                                <div>
                                    <ul>
                                        <li className="title">Type of Move:</li>
                                        <li className="desc"> {moveType + " move"}</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="title">Strategic Intent:</li>
                                        <li className="desc">{strategicIndent}</li>
                                    </ul>
                                </div>
                                <div className="last">
                                    <ul>
                                        <li className="title">Relocation Package:</li>
                                        <li className="desc">{relocationPackage}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {(this.props.location.state.jobID != null && typeof this.props.location.state.jobID !== 'undefined') ? (
                            <>
                                <div className="employee-details">
                                    <div className="top-section">
                                        <h2>Employee</h2>
                                    </div>
                                    <div className="bottom-section">
                                        <div>
                                            <ul>
                                                <li className="title">Name:</li>
                                                <li className="desc">
                                                    {empInfoRepChildEle!= null ? TextCaseFormatter(empInfoRepChildEle.firstName) + ((empInfoRepChildEle.middleName !== null)? TextCaseFormatter(empInfoRepChildEle.middleName) + '':'') + ' ' +TextCaseFormatter( empInfoRepChildEle.lastName) : null}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="second">
                                            
                                        </div>
                                        <div>
                                            <ul>
                                                <li className="title">WWID:</li>
                                                <li className="desc">{empInfoRepChildEle!= null ?empInfoRepChildEle.employeeNumber : null}</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <ul>
                                                <li className="title">Email:</li>
                                                <li className="desc">{empInfoRepChildEle!= null ? TextCaseFormatter(empInfoRepChildEle.preferredEmail, "lower-case") : null}</li>
                                            </ul>
                                        </div>
                                        <div className="last">
                                            {reqInfoPhone != null && reqInfoPhone.number != null ?
                                                <ul>
                                                    <li className="title">Phone:</li>
                                                    <li className="desc">{reqInfoPhone!= null ?reqInfoPhone.number : null}</li>
                                                </ul>
                                            : null }
                                        </div>
                                    </div>
                                </div>
                                <div className="current-job-details">
                                    <div className="top-section">
                                        <h2>Current Job</h2>
                                    </div>
                                    <div className="bottom-section">
                                        <div className="review-flag-section">
                                            <ul>
                                                <li className="review-flag-icon">
                                                    <div className="icon-block">
                                                        <CountryFlags countryCode={empInfoRepChildEle!= null ?empInfoRepChildEle.countryCode : null} countryName={null} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            {reqInfoAddress != null && reqInfoAddress.country != null ?
                                                <ul>
                                                    <li className="desc">
                                                        <li className="title">Home Country:</li>
                                                        <span className="country">{reqInfoAddress!= null ? TextCaseFormatter(reqInfoAddress.country) : null}</span>
                                                    </li>
                                                </ul>
                                            : null }
                                        </div>
                                        <div>
                                            {reqInfoAddress != null && ((reqInfoAddress.addressLine1 != null && reqInfoAddress.addressLine1 !== "") ||
                                            (reqInfoAddress.addressLine2 != null && reqInfoAddress.addressLine2 !== "") ||  
                                            (reqInfoAddress.city != null && reqInfoAddress.city !== "") || 
                                            (reqInfoAddress.stateProvince != null && reqInfoAddress.stateProvince !== "") || 
                                            (reqInfoAddress.postalCode != null && reqInfoAddress.postalCode !== "") ) ?
                                                <ul>
                                                    <li className="title">Current Office:</li>
                                                    <li className="desc">
                                                        <>
                                                            {reqInfoAddress.addressLine1 !== null && reqInfoAddress.addressLine1 !== "" ? (
                                                                <>
                                                                    {TextCaseFormatter(reqInfoAddress.addressLine1)} <br />
                                                                </>
                                                                ) : null}
                                                            {reqInfoAddress.addressLine2 !== null && reqInfoAddress.addressLine2 !== "" ? (
                                                                <>
                                                                    {TextCaseFormatter(reqInfoAddress.addressLine2)}
                                                                </>
                                                                ) : null}
                                                            {reqInfoAddress.city !== null && reqInfoAddress.city !== "" ? (
                                                                <>
                                                                    {TextCaseFormatter(reqInfoAddress.city)} 
                                                                </>
                                                                ) : null}
                                                            {reqInfoAddress.stateProvince !== null && reqInfoAddress.stateProvince !== "" && reqInfoAddress.stateProvince != undefined ? (
                                                                    <>
                                                                        , {(reqInfoAddress.stateProvince).length <= 2 ? TextCaseFormatter(reqInfoAddress.stateProvince,"uper-case") : TextCaseFormatter(reqInfoAddress.stateProvince, "sentence-case")} 
                                                                    </>
                                                                    ) : null}
                                                            {reqInfoAddress.postalCode !== null && reqInfoAddress.postalCode !== "" ? 
                                                            '/' + reqInfoAddress.postalCode
                                                            : null }
                                                        </>
                                                    </li>
                                                </ul>
                                            : null }
                                            <ul>
                                                <li className="slary title">Current Salary:</li>
                                                <li className="slary-amount desc">
                                                    {(empInfoRepChildEle!= null && empInfoRepChildEle.annualizedCurrency !== undefined ) ? (
                                                            <CurrencyFormatter 
                                                                currencyCode={empInfoRepChildEle.annualizedCurrency} 
                                                                amount={empInfoRepChildEle.annualizedAmount} 
                                                            />
                                                        ) : null}
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <ul>
                                                <li className="manager-email title">Current Job Title:</li>
                                                <li className="manager-emaildetails desc">
                                                    {empInfoRepChildEle!= null ? TextCaseFormatter(empInfoRepChildEle.jobTitle) : null}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="last">
                                            {empInfoRepChildEle != null && empInfoRepChildEle.compensationGrade != undefined && empInfoRepChildEle.compensationGrade != "" && empInfoRepChildEle.compensationGrade != null ?
                                                <ul>
                                                    <li className="manager-wwid title">Pay Grade:</li>
                                                    <li className="manager-wwiddetails desc">
                                                        {(empInfoRepChildEle != null && typeof empInfoRepChildEle.compensationGrade !== 'undefined') ? TextCaseFormatter(empInfoRepChildEle.compensationGrade) : null}
                                                    </li>
                                                </ul>
                                            : null }
                                        </div>
                                    </div>
                                    <div className="bottom-section">
                                        <div className="review-flag-section">

                                        </div>
                                        <div>
                    
                                        </div>
                                        <div>
                                            <ul>
                                                <li className="manager title">Home Manager:</li>
                                                <li className="manager-name desc">{empInfoRepChildEle!= null ? TextCaseFormatter(empInfoRepChildEle.manager) : null}</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <ul>
                                                <li className="manager-email title">Home Manager Email:</li>
                                                <li className="manager-emaildetails desc">{empInfoRepChildEle!= null ? TextCaseFormatter(empInfoRepChildEle.managerEmail,"lower-case") : null}</li>
                                            </ul>
                                        </div>
                                        <div className="last">
                                            <ul>
                                                <li className="manager-wwid title">Home Manager WWID:</li>
                                                <li className="manager-wwiddetails desc">{empInfoRepChildEle!= null ?empInfoRepChildEle.homeManagerWWID : null}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="new-job-details">
                            <div className="top-section">
                                <h2>New Job</h2>
                                <a
                                    onClick={() => {
                                        this.navigateTo("employee-job-details-review");
                                    }}
                                >Edit</a>
                            </div>
                            <div className="bottom-section">
                                <div className="review-flag-section">
                                    <ul>
                                        <li className="review-flag-icon">
                                            <div className="icon-block">
                                                <CountryFlags countryCode={reqInfoRepChildEle != null ? reqInfoRepChildEle.countryCode : null} countryName={null} />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    {reqInfoRepChildEle != null && reqInfoRepChildEle.Country != null && (reqInfoRepChildEle.countryCode).toLowerCase() == "na" && (reqInfoRepChildEle.Country).toLowerCase() == "zimbabwe" ? null :
                                        <ul>
                                            <li className="desc">
                                                <li className="title">Host Country:</li>
                                                <span className="country">{reqInfoRepChildEle != null ? TextCaseFormatter(reqInfoRepChildEle.Country) : null}</span>
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div>
                                {reqInfoRepChildEle != null && ((reqInfoRepChildEle["Primary_Address_-_Line_1"] != null && reqInfoRepChildEle["Primary_Address_-_Line_1"] !== "") ||  
                                (reqInfoRepChildEle.City != null && reqInfoRepChildEle.City !== "") || 
                                (reqInfoRepChildEle.State != null && reqInfoRepChildEle.State !== "") || 
                                (reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== null && reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== "") ) ? 
                                        <ul>
                                            <li className="title">New Office:</li>
                                            <li className="desc">
                                                <>
                                                    {reqInfoRepChildEle["Primary_Address_-_Line_1"] !== null && reqInfoRepChildEle["Primary_Address_-_Line_1"] !== "" ? (
                                                        <>
                                                            {TextCaseFormatter(reqInfoRepChildEle["Primary_Address_-_Line_1"])} <br />
                                                        </>
                                                        ) : null}
                                                    {reqInfoRepChildEle.City !== null && reqInfoRepChildEle.City !== "" ? (
                                                        <>
                                                            {TextCaseFormatter(reqInfoRepChildEle.City)} 
                                                        </>
                                                        ) : null}
                                                    {reqInfoRepChildEle.State !== null && reqInfoRepChildEle.State !== "" && reqInfoRepChildEle.State != undefined ? (
                                                            <>
                                                                , {(reqInfoRepChildEle.State).length <= 2 ? TextCaseFormatter(reqInfoRepChildEle.State,"uper-case") : TextCaseFormatter(reqInfoRepChildEle.State, "sentence-case")} 
                                                            </>
                                                            ) : null}
                                                    {reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== null && reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== "" ? 
                                                    '/' + reqInfoRepChildEle["Primary_Address_-_Postal_Code"]
                                                    : null }
                                                    </>
                                            </li>
                                        </ul>
                                    : null }    
                                    <ul>
                                        <li className="slary title">New Salary:</li>
                                        <li className="slary-amount desc">
                                            {(additionalDetailsData!= null && additionalDetailsData.valueInput !== undefined ) ? (
                                                <CurrencyFormatter 
                                                    currencyCode={reqInfoRepChildEle.Salary_Currency} 
                                                    amount={additionalDetailsData.valueInput} 
                                                />
                                            ) : null }
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="title">New Job Title:</li>
                                        <li className="desc">{reqInfoRepChildEle!= null ? TextCaseFormatter(reqInfoRepChildEle.Job_Posting_Title) : null}</li>
                                    </ul>
                                </div>
                                <div >
                                    {(reqInfoRepChildEle!= null && (reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req !== null && reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req !== "" && reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req !== undefined) || (reqInfoRepChildEle.Job_Family_From_Position !== null && reqInfoRepChildEle.Job_Family_From_Position !== "" && reqInfoRepChildEle.Job_Family_From_Position !== undefined) ) ?
                                        <ul>
                                            <li className="title">New Pay Grade:</li>
                                            <li className="desc">
                                                {(reqInfoRepChildEle != null && reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req != null && reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req != undefined) ? TextCaseFormatter(reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req) : (reqInfoRepChildEle != null && reqInfoRepChildEle.Job_Family_From_Position != null && reqInfoRepChildEle.Job_Family_From_Position != undefined ? TextCaseFormatter(reqInfoRepChildEle.Job_Family_From_Position) : null)}
                                            </li>
                                        </ul>
                                    : null}
                                </div>
                                <div className="last">
                                    <ul>
                                        <li className="title">Job ID:</li>
                                        <li className="desc">{reqInfoRepChildEle != null ? reqInfoRepChildEle.Job_Requisition_ID : null}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="bottom-section">
                                <div className="review-flag-section">
                                    
                                </div>
                                <div>

                                </div>
                                <div>
                                    <ul>
                                        <li className="manager title">Host Manager:</li>
                                        <li className="manager-name desc">{hiringManagerName != null ? TextCaseFormatter(hiringManagerName) : null}</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="manager-email title">Host Manager Email:</li>
                                        <li className="manager-emaildetails desc">{hiringManagerEmail != null ? TextCaseFormatter(hiringManagerEmail,"lower-case") : null}</li>
                                    </ul>
                                </div>
                                <div >
                                    <ul>
                                        <li className="manager-wwid title">Host Manager WWID:</li>
                                        <li className="manager-wwiddetails desc">{hiringManagerWWID != null ? hiringManagerWWID : null}</li>
                                    </ul>
                                </div>
                                <div className="last">

                                </div>
                            </div>
                        </div>
                            </>                    
                        ) : null }                         
                        <div className="additional-move-details">
                            <div className="top-section">
                                <h2>Additional Move Details</h2>
                                <a
                                    onClick={() => {
                                        this.navigateTo("additional-move-details");
                                    }}
                                >Edit</a>
                            </div>
                            <div className="bottom-section">
                                <div>
                                    {(additionalDetailsData.californiaTier != null && typeof additionalDetailsData.californiaTier !== 'undefined') ? (
                                        <ul>
                                            <li className="title">California moves:</li>
                                            <li className="desc">{additionalDetailsData.californiaTier}</li>
                                            
                                        </ul>
                                    ): null }
                                </div>
                                <div className="second">
                                    
                                </div>
                                <div>
                                    <ul>
                                         <li className="title">New Base Salary:</li>
                                        <li className="slary-amount desc">
                                            <CurrencyFormatter 
                                                currencyCode={''} 
                                                amount={additionalDetailsData.valueInput} 
                                            />
                                        
                                        </li>


                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                    <li className="title">Special Instructions:</li>
                                        <li className="desc">{additionalDetailsData.additionalDetails}</li>
                                    </ul>
                                </div>
                                <div>
                                    {(additionalDetailsData.numberOfFlights != null && additionalDetailsData.numberOfFlights != "" && typeof additionalDetailsData.numberOfFlights !== 'undefined') ? (
                                        <ul>
                                            <li className="title">Commuter Flights:</li>
                                            <li className="desc">{additionalDetailsData.numberOfFlights}</li>
                                        </ul>
                                    ): null }
                                </div>
                            </div>
                        </div>
                        <div className="finance-details">
                            <div className="top-section">
                                <h2>Finance Details</h2>
                                <a
                                    onClick={() => {
                                        this.navigateTo("finance-admin");
                                    }}
                                >Edit</a>
                            </div>
                            <div className="bottom-section">
                                <div>
                                    <ul>
                                        <li className="title">Funding Finance Partner:</li>
                                        <li className="desc name">{fundingFinancePartner != null ? TextCaseFormatter(fundingFinancePartner.firstName)  + ' ' + TextCaseFormatter(fundingFinancePartner.lastName) : null}</li>
                                        <li className="desc email">{fundingFinancePartner != null ? TextCaseFormatter(fundingFinancePartner.email, "lower-case") : null}</li>
                                        <li className="desc contact">{fundingFinancePartner != null ? fundingFinancePartner.wwid : null}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section> 
                    <section className="button-section">
                        <div className="delete" onClick={e => this.handlePopupState(e, true)}>
                            <Icon name="trash" />
                            Delete this service request</div>
                        <div className="button-wrapper">
                            <Button
                            title="Save and Return to Home"
                            className="save-button"
                            style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", width:"215px"}}
                            labelStyle={{color:"#CA001B"}}
                            onClick={() => {
                                this.navigateTo("saveandreturn");
                            }}
                            />
                            <Button
                            title="Submit"
                            className="submit-button"
                            style={{backgroundColor:"#CA001B", border:"1px solid #CA001B", width:"169px"}}
                            labelStyle={{color:"#FFFFFF"}}
                            onClick={() => {
                                this.navigateTo("yesandcontinue");
                            }}
                            />
                        </div>
                    </section>
                </div>
                </SpinnerWrapper>           
                <DeletePopup 
                    open={this.state.popupOpen} 
                    onClose={e => this.handlePopupState(e, false)} 
                    confirm={this.deleteReqHandler}
                /> 
            </div>
        )
    }
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps,
    )(ReviewAndSubmit),
);