import { createDataReducer } from "helpers";

const type = 'COMPETETIVE_HEADER';

const actions = {
    toggleCompetetiveMode: (isCompetetive) => ({
        type,
        payload: isCompetetive
    })
};

const reducer = createDataReducer(type, false);

export default {
    type,
    actions,
    reducer
};
