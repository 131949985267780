import { MoveDetailsStepper } from "types";

const stepperData: Array<MoveDetailsStepper> = [
    {
        id: 1,
        description: "Pre Initiation",
        active: true,
        isComplete: false,
        key: "pre-initiation",
    },
    {
        id: 2,
        description: "Package Selection",
        active: false,
        isComplete: false,
        key: "package-selection",
    },
    {
        id: 3,
        description: "Employee & Job Details",
        active: false,
        isComplete: false,
        key: "emp-job-details",
    },
    {
        id: 4,
        description: "Additional Move Details",
        active: false,
        isComplete: false,
        key: "additional-move-details",
    },
    {
        id: 5,
        description: "Finance & Admin",
        active: false,
        isComplete: false,
        key: "finance-admin",
    },
    {
        id: 6,
        description: "Review & Submit",
        active: false,
        isComplete: false,
        key: "review-submit",
    },
];

export default stepperData;
