import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { INFO_CARD_COLORS } from "consts";

import {
    InfoCard,
    QuestionsListCard,
    YourTalentMove,
    Link,
    SpinnerWrapper
} from "components/common";

import MainCarousel from "./MainCarousel";

// @ts-ignore
import personWorldImage from "components/common/Images/personWorld.svg";

import { getUserProfile } from "selectors";

import "../style.scss";
import { Typography } from "@material-ui/core";
import { IUserProfile } from "utils/types";
import { moveInitiationService, userService } from "services";
import { string } from "prop-types";
import { USER_ROLES } from "consts";
import { policyEnum as policyEnumerations } from "containers/NonCompetitive/policyMapping";

const mapStateToProps = (state: any) => ({
    userProfile: getUserProfile(state),
});

const mapDispatchToProps = {
    fetchMoveDashboardMemberList: moveInitiationService.actions.fetchMoveDashboardMemberList,
    setRole: userService.actions.setRole
};
interface IProps extends RouteComponentProps {
    userProfile: IUserProfile;
}

class HrMainProfile extends PureComponent<
    IProps &
    RouteComponentProps & {
        fetchMoveDashboardMemberList,
        setRole
    }
> {
    state = {
        dashboardList: [],
        moveType: String,
        policySelection: String,
        relocationType: String,
        isLoading: false,
    };

    navigateTo = (location: string,moveType: string,policySelection: string, relocationType: string, initiationId: String, initiationCountryCode: string, empCompetitiveInit: object, isCompetitive: boolean, employeeInfo: null) => {
        this.setState({
            moveType: moveType,
            policySelection: policySelection
        });
        if(moveType !== null && policySelection !== null && initiationId !== null && employeeInfo !== null) {
            this.props.history.push(location, {
                    moveType: moveType,
                    policySelection: policySelection,
                    relocationType: relocationType,
                    initiationId: initiationId,
                    initiationCountryCode: initiationCountryCode,
                    empCompetitiveInit: empCompetitiveInit,
                    competitiveEdit: isCompetitive,
                    employeeInfo: employeeInfo,
            });
        } else {
            this.props.history.push(location);
        }
    };

    componentDidMount(): void {
        this.updateUserRole();
        this.setState({
            isLoading: true
        });
        this.props
                .fetchMoveDashboardMemberList()
                .then(data => {
                    this.setState({
                        dashboardList: data.result,
                        isLoading: false
                    });
                })
                .catch(err => console.log(err));
    }

    updateUserRole = () => {
        const role = this.props.userProfile.role === 'HrManager' ? USER_ROLES.hiring_manager : USER_ROLES.employee;
        const email = this.props.userProfile.email;
        if( role === "Assignee" && email !== "" && email != undefined ) {
            let swapRole = USER_ROLES.hiring_manager;
            this.props.setRole({
                email,
                role: swapRole
            }).then((re) => {
               return;
            });
        }
        return;
    }

    // policyEnumerations = {
    //     "HomeownerPackageA" : "Homeowner Package A",
    //     "HomeownerPackageB" : "Homeowner Package B",
    //     "HomeownerPackageC" : "Homeowner Package C",
    //     "HomeownerPackageCManager" : "Homeowner Package C - Manager",
    //     "HomeownerPackageCNonManager" : "Homeowner Package C - Non Manager",
    //     "HomeownerPackageD" : "Homeowner Package D",
    //     "LDPFinalMovePackageB" : "LDP Final Move Package B",
    //     "LDPFinalMovePackageBNewHire" : "LDP Final Move Package B - New Hire",
    //     "LDPNewHirePackageA" : "LDP New Hire - Package A",
    //     "LDPRotationalPackageA" : "LDP Rotational - Package A",
    //     "LongTermTier1" : "Long Term Tier 1",
    //     "LongTermTier2" : "Long Term Tier 2",
    //     "OneWayMoveTier1" : "One Way Move Tier 1",
    //     "OneWayMoveTier2" : "One Way Move Tier 2",
    //     "OneWayMoveTier3" : "One Way Move Policy Tier 3",
    //     "RenterPackageA" : "Renter Package A",
    //     "RenterPackageB" : "Renter Package B",
    //     "RenterPackageBNewHire" : "Renter Package B - New Hire",
    //     "RenterPackageC" : "Renter Package C",
    //     "RenterPackageD" : "Renter Package D",
    //     "ShortTerm" : "Short Term",
    //     "Commuter" : "Commuter",
    // }

    draftCard(i,item) {
        if(item.employeeFullName !== null ) {
            let moveType = ( item.moveType ) ? item.moveType : null;
            let policySelection = ( item.policy ) ? item.policy : null;
            let initiationId = ( item.initiationId ) ? item.initiationId : null;
            let initiationCountryCode = null;
            let relocationType = "Pre-Determination No City Tour";
            let step = (item.initiationStep) ? item.initiationStep : null;
            let isCompetitive = (item.competitiveData == null) ? false : true;
            let preinitData = (isCompetitive) ? ((item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq != null)) ? item.competitiveData.preInitReq : item.preInitiation.compPreInit) : null;
            
            let nonCompData = (item.nonCompetitiveData ) ? item.nonCompetitiveData : null;
            let policy = ( nonCompData ) ? nonCompData.policyPackageInfo.policy : null;
            policy = (isCompetitive) ? item.competitiveData.policyPackageInfo.policy : policy;
            let jobId = ( item.employeeInfo ) ? ( item.employeeInfo.requisitionId ? item.employeeInfo.requisitionId : item.employeeInfo.positionId ) : " ";
            jobId = (isCompetitive) ? preinitData.requisition.contestNumber : jobId;
            let toCountry = null;
            let toCountryCode = null;
            let fromCountry = null;
            let newJobTitle = null;
            let destinationWorkCountry = "NA";
            if (item.initiationStep == "ReviewAndSubmit") {
            
                if (isCompetitive) {
                    toCountry = (item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq != null)) ? "NA" : item.competitiveData.empNJobDet.jobDetails.destination.workCountry;
                    toCountryCode = (item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq != null)) ? "NA" : item.competitiveData.empNJobDet.jobDetails.destination.workCountryCode;
                    fromCountry = (item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq != null)) ? "NA" : item.competitiveData.empNJobDet.empDetails.addresses.country;
                    newJobTitle = (preinitData != undefined && (preinitData != null)) ? preinitData.requisition.jobInformation.title : null;
                    destinationWorkCountry = (item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq != null)) ? "NA" : preinitData.requisition.jobInformation.country;
                    let destinationWorkCountryData = (destinationWorkCountry != null) ? destinationWorkCountry.toLowerCase() : null;
                    let toCountryData = (toCountry != null) ? toCountry.toLowerCase() : null;
                    if(toCountryData !== destinationWorkCountryData && toCountryData == "zimbabwe") {
                        toCountry = null;
                        toCountryCode = "NA";
                    }
                }
                else {
                    toCountry = (nonCompData.empNJobDet && nonCompData.empNJobDet.jobDetails) ? nonCompData.empNJobDet.jobDetails.destination.workCountry : null;
                    toCountryCode = (nonCompData.empNJobDet && nonCompData.empNJobDet.jobDetails) ? nonCompData.empNJobDet.jobDetails.destination.workCountryCode : 'NA';   
                    newJobTitle = (nonCompData.empNJobDet && nonCompData.empNJobDet.jobDetails) ? nonCompData.empNJobDet.jobDetails.jobTitle : null;
                    fromCountry = (nonCompData.empNJobDet) ? nonCompData.empNJobDet.empDetails.addresses.country : null;
                    destinationWorkCountry = (item.preInitiation && item.preInitiation != undefined && item.preInitiation.empJobInfo != undefined && item.preInitiation.empJobInfo.empDetails != undefined) ? (item.preInitiation.empJobInfo.empDetails.addresses != undefined) ? item.preInitiation.empJobInfo.empDetails.addresses.country : "NA" : "NA";
                    let destinationWorkCountryData = (destinationWorkCountry != null) ? destinationWorkCountry.toLowerCase() : null;
                    let toCountryData = (toCountry != null) ? toCountry.toLowerCase() : null;
                    if(toCountryData != destinationWorkCountryData && toCountryData == "zimbabwe") {
                        toCountry = null;
                        toCountryCode = "NA";
                    }
                }
                // policy = (this.policyEnumerations[policy] !== undefined && this.policyEnumerations[policy] !== null )? this.policyEnumerations[policy]: policy;
                policy = (policyEnumerations[policy].displayText !== undefined && 
                    policyEnumerations[policy].displayText !== null )? 
                    policyEnumerations[policy].displayText: policy;
            }    

            if (step !== "ReviewAndSubmit" && item.state === "Incomplete") {
                
                if(isCompetitive) {
                    let moveTypeSelected = ((item.moveType).toLowerCase() == "domestic") ? "Move within U.S." : "Global Experience";
                    let empCompetitiveInit = (item.competitiveData !== null) ? preinitData : null;
                    let employeeInfo = (item.employeeInfo !== undefined && item.employeeInfo !== null ) ? item.employeeInfo : null; 
                    return (
                        <YourTalentMove
                            key={i}
                            name={item.employeeFullName}
                            status={"draft"}
                            id={initiationId}
                            move={moveTypeSelected}
                            onClick={() => {
                                this.navigateTo("/move-details",moveType,policySelection,relocationType,initiationId,initiationCountryCode,empCompetitiveInit,isCompetitive,employeeInfo);
                            }}
                            isCompetitive={isCompetitive}
                        />
                    );
                } else {
                    let employeeInfo = (item.nonCompetitiveData !== undefined && item.nonCompetitiveData !== null ) ? item.nonCompetitiveData : null; 
                    if(employeeInfo === null ) {
                        employeeInfo = (item.employeeInfo !== undefined && item.employeeInfo !== null ) ? item.employeeInfo : null; 
                    }
                    if((item.moveType).toLowerCase() == "domestic") {
                        return (
                            <YourTalentMove
                                key={i}
                                name={item.employeeFullName}
                                status={"draft"}
                                id={initiationId}
                                move={"Move within U.S."}
                                onClick={() => {
                                    item.policy == "ShortTerm" ? 
                                    this.navigateTo("/non-competitive-initiation/emp-job-details",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo) 
                                    : this.navigateTo("/non-competitive-initiation/package-selection",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                                }}
                                // onClick={() => {
                                //     item.policy == "ShortTerm" ? this.navigateTo("/initiate-move/employee-job-details",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo) : this.navigateTo("/initiate-move",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                                // }}
                                isCompetitive={isCompetitive}
                            />
                        );
                    } else if (item.moveType === "Global"){
                        return (
                            <YourTalentMove
                                key={i}
                                name={item.employeeFullName}
                                status={"draft"}
                                id={initiationId}
                                move={"Global Experience"}
                                onClick={() => {
                                    this.navigateTo("/non-competitive-initiation/package-selection",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                                }}
                                // onClick={() => {
                                //     this.navigateTo("/initiate-move",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                                // }}
                                isCompetitive={isCompetitive}
                            />
                        );
                    } else {
                        return (
                            <YourTalentMove
                                key={i}
                                name={item.employeeFullName}
                                status={"draft"}
                                id={initiationId}
                                move={item.moveType}
                                onClick={() => {
                                    this.navigateTo("/non-competitive-initiation/package-selection",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                                }}
                                // onClick={() => {
                                //     this.navigateTo("/initiate-move",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                                // }}
                                isCompetitive={isCompetitive}
                            />
                        );
                    }
                }
            } else {
                if(item.employeeFullName !== null ) {
                    if(item.moveType === "Domestic" ) {
                        return (
                            <YourTalentMove
                                name={item.employeeFullName}
                                status={"submitted"}
                                move={"Move within U.S."}
                                id={item.externalId}
                                moveDetails={{
                                    to: toCountry,
                                    from: fromCountry,
                                    package: policy,
                                    newJob: newJobTitle,
                                    jobId: jobId,
                                    toCounrtryCode: toCountryCode,
                                }}
                                isCompetitive={isCompetitive}
                            />
                        );
                    } else if (item.moveType === "Global"){
                        return (
                            <YourTalentMove
                                name={item.employeeFullName}
                                status={"submitted"}
                                move={"Global Experience"}
                                id={item.externalId}
                                moveDetails={{
                                    to: toCountry,
                                    from: fromCountry,
                                    package: policy,
                                    newJob: newJobTitle,
                                    jobId: jobId,
                                    toCounrtryCode: toCountryCode,
                                }}
                                isCompetitive={isCompetitive}
                            />
                        );
                    } else {
                        return (
                            <YourTalentMove
                                name={item.employeeFullName}
                                status={"submitted"}
                                move={item.moveType}
                                id={item.externalId}
                                moveDetails={{
                                    to: toCountry,
                                    from: fromCountry,
                                    package: policy,
                                    newJob: newJobTitle,
                                    jobId: jobId,
                                    toCounrtryCode: toCountryCode,
                                }}
                                isCompetitive={isCompetitive}
                            />
                        );
                    }
                }
            }
        }    
    }

    PreDCard(i,item) {
        let moveType = ( item.moveType ) ? item.moveType : null;
        let policySelection = ( item.policy ) ? item.policy : null;
        let initiationId = ( item.initiationId ) ? item.initiationId : null;
        let initiationCountryCode = null;
        let relocationType = "Relocation Services";
        let isCompetitive = (item.competitiveData == null) ? false : true;
        
        if(item.employeeFullName !== null ) {
            if(isCompetitive) {
                let moveTypeSelected = "NotSet";
                if(item.moveType !== "NotSet") {
                    moveTypeSelected = ((item.moveType).toLowerCase() == "domestic") ? "Move within U.S." : "Global Experience";
                }
                let empCompetitiveInit = (item.competitiveData !== null) ? ((item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq !== null)) ? item.competitiveData.preInitReq : item.preInitiation.compPreInit) : null;
                let employeeInfo = (item.employeeInfo !== undefined && item.employeeInfo !== null ) ? item.employeeInfo : null; 

                return (
                    <YourTalentMove
                        key={i}
                        name={item.employeeFullName}
                        status={"draft"}
                        id={initiationId}
                        move={moveTypeSelected}
                        onClick={() => {
                            this.navigateTo("/move-details",moveType,policySelection,relocationType,initiationId,initiationCountryCode,empCompetitiveInit,isCompetitive,employeeInfo);
                        }}
                        isCompetitive={isCompetitive}
                    />
                );
            } else {
                let employeeInfo = (item.nonCompetitiveData !== undefined && item.nonCompetitiveData !== null ) ? item.nonCompetitiveData : null; 
                if(employeeInfo === null ) {
                    employeeInfo = (item.employeeInfo !== undefined && item.employeeInfo !== null ) ? item.employeeInfo : null; 
                }
                if(item.moveType === "Domestic" ) {
                    return (
                        <YourTalentMove
                            key={i}
                            name={item.employeeFullName}
                            status={"preInitiation"}
                            id={initiationId}
                            move={"Move within U.S."}
                            onClick={() => {
                                item.policy == "ShortTerm" ? 
                                this.navigateTo("/non-competitive-initiation/emp-job-details",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo)
                                : this.navigateTo("/non-competitive-initiation/package-selection",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                            }}
                            // onClick={() => {
                            //     item.policy == "ShortTerm" ? this.navigateTo("/initiate-move/employee-job-details",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo) : this.navigateTo("/initiate-move",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                            // }}
                            isCompetitive={isCompetitive}
                        />
                    );
                } else if (item.moveType === "Global"){
                    return (
                        <YourTalentMove
                            key={i}
                            name={item.employeeFullName}
                            status={"preInitiation"}
                            id={initiationId}
                            move={"Global Experience"}
                            onClick={() => {
                                this.navigateTo("/non-competitive-initiation/package-selection",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                            }}
                            // onClick={() => {
                            //     this.navigateTo("/initiate-move",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                            // }}
                            isCompetitive={isCompetitive}
                        />
                    );
                } else {
                    return (
                        <YourTalentMove
                            key={i}
                            name={item.employeeFullName}
                            status={"preInitiation"}
                            id={initiationId}
                            move={item.moveType}
                            onClick={() => {
                                this.navigateTo("/non-competitive-initiation/package-selection",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                            }}
                            // onClick={() => {
                            //     this.navigateTo("/initiate-move",moveType,policySelection,relocationType,initiationId,initiationCountryCode,{},isCompetitive,employeeInfo);
                            // }}
                            isCompetitive={isCompetitive}
                        />
                    );
                }
            }    
        }
    }

    SubmittedCard(i,item) {
        if(item.employeeFullName !== null ) {;
            let isCompetitive = (item.competitiveData == null) ? false : true;
            let preinitData = (isCompetitive) ? ((item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq != null)) ? item.competitiveData.preInitReq : item.preInitiation.compPreInit) : null;            
            let nonCompData = (item.nonCompetitiveData ) ? item.nonCompetitiveData : null;
            let policy = ( nonCompData ) ? nonCompData.policyPackageInfo.policy : null;
            policy = (isCompetitive) ? item.competitiveData.policyPackageInfo.policy : policy;
            let jobId = ( item.employeeInfo ) ? ( item.employeeInfo.requisitionId ? item.employeeInfo.requisitionId : item.employeeInfo.positionId ) : " ";
            jobId = (isCompetitive) ? preinitData.requisition.contestNumber : jobId;
            let empCompetitiveInit = (item.competitiveData !== null) ? ((item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq !== null)) ? item.competitiveData.preInitReq : item.preInitiation.compPreInit) : null;

            jobId = (item.competitiveData !== null ) ? empCompetitiveInit.requisition.contestNumber : jobId;
            // policy = (this.policyEnumerations[policy] !== undefined && this.policyEnumerations[policy] !== null )? this.policyEnumerations[policy]: policy;
            policy = (policyEnumerations[policy].displayText !== undefined && 
                policyEnumerations[policy].displayText !== null )? 
                policyEnumerations[policy].displayText: policy;

            let toCountry = null;
            let toCountryCode = null;
            let fromCountry = null;
            let newJobTitle = null;
            let destinationWorkCountry = "NA";
            if (item.initiationStep == "ReviewAndSubmit") {
                if (isCompetitive) {
                    toCountry = (item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq != null)) ? "NA" : item.competitiveData.empNJobDet.jobDetails.destination.workCountry;
                    toCountryCode = (item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq != null)) ? "NA" : item.competitiveData.empNJobDet.jobDetails.destination.workCountryCode;
                    fromCountry = (item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq != null)) ? "NA" : item.competitiveData.empNJobDet.empDetails.addresses.country;
                    newJobTitle = (preinitData != undefined && (preinitData != null)) ? preinitData.requisition.jobInformation.title : null;
                    destinationWorkCountry = (item.competitiveData.hasOwnProperty("preInitReq") && (item.competitiveData.preInitReq != null)) ? "NA" : preinitData.requisition.jobInformation.country;
                    let destinationWorkCountryData = (destinationWorkCountry != null) ? destinationWorkCountry.toLowerCase() : null;
                    let toCountryData = (toCountry != null) ? toCountry.toLowerCase() : null;
                    if(toCountryData !== destinationWorkCountryData && toCountryData == "zimbabwe") {
                        toCountry = null;
                        toCountryCode = "NA";
                    }
                }
                else {
                    toCountry = (nonCompData.empNJobDet && nonCompData.empNJobDet.jobDetails) ? nonCompData.empNJobDet.jobDetails.destination.workCountry : null;
                    toCountryCode = (nonCompData.empNJobDet && nonCompData.empNJobDet.jobDetails) ? nonCompData.empNJobDet.jobDetails.destination.workCountryCode : 'NA';   
                    newJobTitle = (nonCompData.empNJobDet && nonCompData.empNJobDet.jobDetails) ? nonCompData.empNJobDet.jobDetails.jobTitle : null;
                    fromCountry = (nonCompData.empNJobDet) ? nonCompData.empNJobDet.empDetails.addresses.country : null;
                    destinationWorkCountry = (item.preInitiation && item.preInitiation != undefined && item.preInitiation.empJobInfo != undefined && item.preInitiation.empJobInfo.empDetails != undefined) ? (item.preInitiation.empJobInfo.empDetails.addresses != undefined) ? item.preInitiation.empJobInfo.empDetails.addresses.country : "NA" : "NA";
                    let destinationWorkCountryData = (destinationWorkCountry != null) ? destinationWorkCountry.toLowerCase() : null;
                    let toCountryData = (toCountry != null) ? toCountry.toLowerCase() : null;
                    if(toCountryData !== destinationWorkCountryData && toCountryData == "zimbabwe") {
                        toCountry = null;
                        toCountryCode = "NA";
                    }
                }
            }
            if(item.moveType === "Domestic" ) {
                return (
                    <YourTalentMove
                        name={item.employeeFullName}
                        status={"submitted"}
                        move={"Move within U.S."}
                        id={item.externalId}
                        moveDetails={{
                            to: toCountry,
                            from: fromCountry,
                            package: policy,
                            newJob: newJobTitle,
                            jobId: jobId,
                            toCounrtryCode: toCountryCode,
                        }}
                        isCompetitive={isCompetitive}
                    />
                );
            } else if (item.moveType === "Global"){
                return (
                    <YourTalentMove
                        name={item.employeeFullName}
                        status={"submitted"}
                        move={"Global Experience"}
                        id={item.externalId}
                        moveDetails={{
                            to: toCountry,
                            from: fromCountry,
                            package: policy,
                            newJob: newJobTitle,
                            jobId: jobId,
                            toCounrtryCode: toCountryCode,
                        }}
                        isCompetitive={isCompetitive}
                    />
                );
            } else {
                return (
                    <YourTalentMove
                        name={item.employeeFullName}
                        status={"submitted"}
                        move={item.moveType}
                        id={item.externalId}
                        moveDetails={{
                            to: toCountry,
                            from: fromCountry,
                            package: policy,
                            newJob: newJobTitle,
                            jobId: jobId,
                            toCounrtryCode: toCountryCode,
                        }}
                        isCompetitive={isCompetitive}
                    />
                );
            }
        }
    }
    
    render() {
        const userProfileShortName = this.props.userProfile.shortName;
        let ListObject = this.state.dashboardList;
        const listData = []
        Object.keys(ListObject).forEach(key => listData.push({name: key, data: ListObject[key]}))
        var self = this;
        return (
            <div className="Profil flexible jCenter vertical new-flow">
                <MainCarousel userName={userProfileShortName} newFlow={true} />
                <SpinnerWrapper show={self.state.isLoading}>
                <div className="your-talent-moves-container">
                    <div className={"flexible jBetween aCenter"}>
                        <span>
                            <h1>Your Talent Moves</h1>
                        </span>
                        <Link
                            text="Initiate a new move"
                            onClick={() => {
                                this.navigateTo("/profile",null,null,null,null,null,{},false,null);
                            }}
                            className="profile-header-right"
                        />
                    </div>
                    <div className="items">
                        <div>
                            {listData.map((value, index) => {
                                let dataLists = value.data;
                                if ( value.name === 'Incomplete') {
                                    return ( 
                                        <div key={index}>
                                            {dataLists.map(function (item,i) {
                                                return self.draftCard(i,item);
                                            })}
                                        </div>
                                    );
                                }
                                if ( value.name === 'PreD' ) {
                                    return ( 
                                        <div key={index}>
                                            {dataLists.map(function (item,i) {
                                                return self.PreDCard(i,item);
                                            })}
                                        </div>
                                    );
                                }
                                if ( value.name === 'Submitted') {
                                    return ( 
                                        <div key={index}>
                                            {dataLists.map(function (item,i) {
                                                return self.SubmittedCard(i,item);
                                            })}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
                {/* <div className="hr-main-content">
                    <div className="question-part">
                        <QuestionsListCard
                            key="hr_main_q1"
                            icon={personWorldImage}
                            title=""
                            body={
                                <div>
                                    <div className="question-part">
                                        <h2>
                                            Moving an employee is a big decision
                                            but we have the tools and
                                            information you need.
                                        </h2>
                                    </div>
                                    <Typography variant="body1">
                                        We've made it easy to get detailed
                                        information based on the type of move
                                        and the phase of the journey, so you
                                        know exactly what to do and when. Start
                                        exploring now to learn more about next
                                        steps.
                                    </Typography>
                                </div>
                            }
                            urlText={[
                                " Learn more about Global Experiences",
                                "Learn more about moves within the U.S.",
                            ]}
                            urlActions={[
                                () =>
                                    this.navigateTo(
                                        "/profile/international/explore",null,null,null,null,null,{},false,null
                                    ),
                                () =>
                                    this.navigateTo(
                                        "/profile/domestic/explore",null,null,null,null,null,{},false,null
                                    ),
                            ]}
                            urlIconClassName={"profile-move-icon"}
                        />
                    </div>
                </div>
                <section className="three-cards jAround flexible">
                    <InfoCard
                        backgroundColor={INFO_CARD_COLORS.first}
                        headerIsLink
                        title="Estimate the cost of a move"
                        content="Learn more about the process and  manager responsibilities when moving someone internationally."
                        underLink="Cost Estimate Tool"
                        onClick={() => {
                            this.navigateTo("/profile/international/explore",null,null,null,null,null,{},false,null);
                        }}
                    />
                    {!this.props.userProfile.currentRelocationIsDomestic && (
                        <InfoCard
                            backgroundColor={INFO_CARD_COLORS.second}
                            headerIsLink
                            title="Check eligibility for immigration"
                            content="Not sure if someone is eligible to work in one country or another? Use our pre-assessment tool to find out."
                            underLink="Immigration Pre-Assessment"
                            onClick={() => {
                                this.navigateTo("/profile/domestic/explore",null,null,null,null,null,{},false,null);
                            }}
                        />
                    )}
                    <InfoCard
                        backgroundColor={INFO_CARD_COLORS.third}
                        headerIsLink
                        title="Questions? /n Get Help"
                        content="We’re here to support and guide managers throughout the relocation and move process."
                        underLink="Contact Mobility Connect"
                        onClick={() => {
                            this.navigateTo("/contact-us",null,null,null,null,null,{},false,null);
                        }}
                    />
                </section> */}
                </SpinnerWrapper>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(HrMainProfile));
