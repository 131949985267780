import React, { PureComponent } from 'react';

import { Icon } from 'components/common';

import './style.scss';

export default class QuotesImage extends PureComponent {
    render() {
        const { className = '', imageSrc, title, description } = this.props;

        return (
          <div className={`${className} quotes-image`}>
              <img className="quotes-image__image" src={imageSrc} alt="" />
              <div className="quotes-image__content">
                  <Icon name="quote"></Icon>
                  <p className="quotes-image__content-title">
                      { title }
                  </p>
                  <p className="quotes-image__content-description">
                      { description }
                  </p>
              </div>
          </div>
        )
    }
}
