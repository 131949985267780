export const USER_ROLES = {
    hiring_manager: "HrManager",
    employee: "Assignee"
};

export const SHORT_DATE_FORMAT = ' MMM DD, YYYY';
export const LONG_DATE_FORMAT = ' MMMM DD, YYYY';

export const INFO_CARD_COLORS = {
    first: '#000099',
    second: '#f4f4f4',
    third: '#cc0099'
};

export const JIBE_SIGNUP_LINK=process.env.REACT_APP_APP_JIBE_SIGNUP_URL;
export const API_LINK = process.env.REACT_APP_API_BASE_URL;
export const OIDC_SETTINGS = {
    authority: process.env.REACT_APP_AUTH_SERVER_URL, /// Identity server url
    client_id: "urn:jnj_oi_client",
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login/callback`, //client UI url
    response_type: "id_token token",
    scope:"openid jnjapi offline_access",
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`, //client UI url,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
    check_session_iframe: `${process.env.REACT_APP_AUTH_SERVER_URL}/connect/checksession`,
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: true,
    monitorSession: true,
    loadUserInfo: true,
    silentRequestTimeout:2000,
    checkSessionInterval:2000
}

export const EXTERNAL_LINKS = {
    GlobalCostEstimator: process.env.REACT_APP_EXT_LINK_COST_ESTIMATOR_URL,
    SirvaConnect: process.env.REACT_APP_EXT_LINK_SIRVA_CONNECT_URL || '/page-unavailable' ,
    OurSource: process.env.REACT_APP_EXT_LINK_OURSOURCE,
    Workday: process.env.REACT_APP_EXT_LINK_WORKDAY,
    Domestic: {
        Immigration_Pre_Assessment: process.env.REACT_APP_EXT_LINK_DOM_IMMIGRATION_PRE_ASSESSMENT
    },
    International: {
        Immigration_Pre_Assessment: process.env.REACT_APP_EXT_LINK_INT_IMMIGRATION_PRE_ASSESSMENT
    }    
}

export const ERROR_STATUSES = [400, 403, 500, 503];
