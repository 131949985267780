import { EXTERNAL_LINKS } from 'consts';
import clipboard from 'components/common/Images/clipboard.svg';
import world from 'components/common/Images/world.svg';
import personWorld from 'components/common/Images/personWorld.svg';
import hospital from 'components/common/Images/hospital.svg';
import docs from 'components/common/Images/docs.svg';
import screen from 'components/common/Images/screen.svg';
import partnership from 'components/common/Images/partnership.svg';
import dollar from 'components/common/Images/dollar.svg';
import pin from 'components/common/Images/pin.svg';


export const resources =
{
  managerToolkit: [
    {
      title: "Tool",
      subTitle: "Global Policy Decision Tree Tool",
      text: "Interactive guide to help you identify a relocation package for a global experience.",
      linkText: "View Now",
      assetType: "policy_decision_tree",
      assetId: "international"
    },
    {
      title: "Tool",
      subTitle: "U.S. Domestic Policy Decision Tree Tool",
      text: "Interactive guide to help you identify a relocation package for U.S. Domestic moves. ",
      linkText: "View Now",
      assetType: "policy_decision_tree",
      assetId: "domestic"
    },
    {
      title: "Tool",
      subTitle: "Global Cost Estimator",
      text: "Need to establish a budget? We’ll help you estimate the cost of relocation.",
      linkText: "View Now",
      isLinkExternal: true,
      linkUrl: EXTERNAL_LINKS.GlobalCostEstimator
    },
    // {
    //   title: "Resource",
    //   subTitle: "Global Immigration Sponsorship Assessment",
    //   text: "For individuals looking to move globally, complete the global Immigration Sponsorship Assessment.",
    //   linkText: "View Now",
    //   assetType:'immigration',
    //   assetId:'us_pre_assessment'
    // },
    // {
    //   title: "Resource",
    //   subTitle: "Global/US Immigration Initiation Form",
    //   text: "Global/US Immigration Initiation Form to be completed and submitted to Immigration Provider.",
    //   linkText: "View Now",
    //   assetType:'immigration',
    //   assetId:'deloitte_immigration_service_initiation'
    // },
    {
      title: "Resource",
      subTitle: "Immigration Initiation Form",
      text: "Immigration Initiation Form to be completed and submitted to Immigration Provider.",
      linkText: "View Now",
      assetType:'immigration-service',
      assetId:'immigration-service-initiation-form'
    },
    // {
    //   title: "Website",
    //   subTitle: "OurSource",
    //   text: "To create a Host Position ID for Argentina, Brazil, Canada, South Africa, and US/PR. Refer to \"Organization View and Reports\" on the Our Source home page and click on \"Create and Update Position On Behalf of\".",
    //   linkText: "Go Now",
    //   isLinkExternal: true,
    //   linkUrl: EXTERNAL_LINKS.OurSource
    // },
    {
      title: "Website",
      subTitle: "WORKDAY",
      text: "To create a Host Job Requisition ID in Workday, refer to \"Employee Quick Links and Reference Materials\" on the Workday home page and click on \"Global Services HR support\".",
      linkText: "Go Now",
      isLinkExternal: true,
      linkUrl: EXTERNAL_LINKS.Workday
    }
  ],
  managerInt: [
    {
      key: "PreInitiation",
      title: "Pre-Initiation",
      icon: pin,
      items: [
        {
          fileType: "PPT",
          documentId: 10,
          fileName: "",
          description: "Global Policy Decision Tree",
          documentType: "",
          assetKey: "policy_decision_tree",
          assetId: "international"
        },
        {
          fileType: "Website",
          documentId: 1,
          fileName: "",
          description: "International Cost Estimate Tool",
          documentType: "",
          isLinkExternal: true,
          linkUrl: EXTERNAL_LINKS.GlobalCostEstimator
        },
        // {
        //   fileType: "PDF",
        //   documentId: 180,
        //   fileName: "",
        //   description: "Sample Assignment Spend",
        //   documentType: "",
        //   assetKey: "sample-assignment-spend",
        //   assetId: "international"
        // },
        // {
        //   fileType: "PPT" ,
        //   documentId: 183,
        //   fileName: "https://jnj.sharepoint.com/sites/onehr/OurEnterprise/PublishingImages/TalentMobility/5YearExtensionProcess.pptx",
        //   description: "5-Year Extension Process",
        //   documentType: "",
        // },
        {
          fileType: "PPT",
          documentId: 183,
          fileName: "",
          description: "5-Year Extension Process",
          documentType: "",
          assetKey: "five-year-extension-process",
          assetId: "international"
        },
        // {
        //   fileType: "PDF",
        //   documentId: 182,
        //   fileName: "",
        //   description: "Initiation Checklist",
        //   documentType: "",
        //   assetKey: "move-initiation-checklist",
        //   assetId: "international"
        // },
      ],
    },
    {
      key: "Immigration",
      title: "Immigration",
      icon: personWorld,
      items: [
        {
          fileType: "XLS",
          documentId: 20,
          fileName: "",
          description: "Immigration Recommended Lead Times",
          documentType: "",
          assetKey: "global-immigration",
          assetId: "immigration-complexity-matrix"
        },
        // {
        //   fileType: "PDF",
        //   documentId: 181,
        //   fileName: "",
        //   description: "Global Immigration Sponsorship Assessment",
        //   documentType: "",
        //   assetKey:'immigration',
        //   assetId:'us_pre_assessment'
        // },
        // {
        //   fileType: "XLS",
        //   documentId: 185,
        //   fileName: "",
        //   description: "Global/US Immigration Initiation Form",
        //   documentType: "",
        //   assetKey:'immigration',
        //   assetId:'deloitte_immigration_service_initiation'
        // },
        {
          fileType: "XLS",
          documentId: 185,
          fileName: "",
          description: "Immigration Initiation Form",
          documentType: "",
          assetKey:'immigration-service',
          assetId:'immigration-service-initiation-form'
        },
      ]
    },
    {
      key: "Assignment",
      title: "Assignment Guides & Policies",
      icon: clipboard,
      items: [
        {
          fileType: "PDF",
          documentId: 1,
          fileName: "",
          description: "Short Term Policy",
          documentType: "",
          assetKey: "global-policy",
          assetId: "short-term"
        },
        {
          fileType: "PDF",
          documentId: 2,
          fileName: "",
          description: "Long Term Tier 1 Policy",
          documentType: "",
          assetKey: "global-policy",
          assetId: "long-term-tier-1"
        },
        {
          fileType: "PDF",
          documentId: 3,
          fileName: "",
          description: "Long Term Tier 2 Policy",
          documentType: "",
          assetKey: "global-policy",
          assetId: "long-term-tier-2"
        },
        {
          fileType: "PDF",
          documentId: 4,
          fileName: "",
          description: "Commuter Policy",
          documentType: "",
          assetKey: "global-policy",
          assetId: "commuter"
        },
        {
          fileType: "PDF",
          documentId: 5,
          fileName: "",
          description: "One Way Move Policy Tier 1",
          documentType: "",
          assetKey: "global-policy",
          assetId: "one-way-move-tier-1"
        },
        {
          fileType: "PDF",
          documentId: 6,
          fileName: "",
          description: "One Way Move Policy Tier 2",
          documentType: "",
          assetKey: "global-policy",
          assetId: "one-way-move-tier-2"
        },
        {
          fileType: "PDF",
          documentId: 7,
          fileName: "",
          description: "One Way Move Policy Tier 3",
          documentType: "",
          assetKey: "global-policy",
          assetId: "one-way-move-tier-3"
        }
      ]
    },
    {
      key: "Cultural",
      title: "Cultural Prep & Planning",
      icon: world,
      items: [
        {
          fileType: "PDF",
          documentId: 17,
          fileName: "",
          description: "Cultural Assessment Guide",
          documentType: "",
          assetKey: "global-planning",
          assetId: "cultural-assessment-guide"
        },
      ]
    },
    {
      key: "Healthcare",
      title: "Healthcare",
      icon: hospital,
      items: [
        {
          fileType: "PDF",
          documentId: 22,
          fileName: "",
          description: "Pre-Assignment Assistance",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "pre-assignment-assistance"
        },
        {
          fileType: "PDF",
          documentId: 23,
          fileName: "",
          description: "Pre Departure Guide",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "pre-departure-guide"
        },
        {
          fileType: "PDF",
          documentId: 24,
          fileName: "",
          description: "CIGNA Envoy Registration Guide",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "envoy-registration-for-the-americas"
        },
        {
          fileType: "PDF",
          documentId: 25,
          fileName: "",
          description: "Benefit Summary: Global Assignees in the U.S. or Overseas",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "global-assignees-in-the-U-S-or-overseas"
        },
        {
          fileType: "PDF",
          documentId: 26,
          fileName: "",
          description: "Benefit Summary: U.S.-Based Assignees working outside the U.S.",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "assignees-working-outside-the-u-s"
        },
        {
          fileType: "PDF",
          documentId: 27,
          fileName: "",
          description: "Benefit Summary: Assignees from Puerto Rico",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "assignees-from-puerto-rico"
        },
        {
          fileType: "PDF",
          documentId: 28,
          fileName: "",
          description: "Online Claims Submissions Info",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "online-claims-submission"
        },
      ]
    },
    {
      title: "Finance Resources",
      icon: dollar,
      items: [
        {
          fileType: "PPT",
          documentId: 500,
          fileName: "",
          description: "Global Fees & Charges Process",
          documentType: "",
          assetKey: "global-fees-charges-process",
          assetId: "international"
        },
        {
          fileType: "DOC",
          documentId: 501,
          fileName: "",
          description: "Global Fees & Charges FAQs",
          documentType: "",
          assetKey: "global-fees-charges-faqs",
          assetId: "international"
        },
      ],
    },
  ],
  managerDom: [
    {
      key: "Planning",
      title: "Planning",
      icon: clipboard,
      items: [
        {
          fileType: "Website",
          documentId: 600,
          fileName: "http://onehr.jnj.com/Pages/default.aspx",
          description: "OneHR",
          documentType: "",
        },
        {
          fileType: "PDF",
          documentId: 601,
          fileName: "",
          description: "U.S. Domestic Package Decision Tree",
          documentType: "",
          assetKey: "policy_decision_tree",
          assetId: "domestic"
        },
        {
          fileType: "PDF",
          documentId: 602,
          description: "2020 Cost Estimate",
          documentType: "",
          assetKey: "domestic-relocation-planning",
          assetId: "domestic-relo-cost-averages"
        },
        {
          fileType: "PDF",
          documentId: 603,
          fileName: "",
          description: "Move Initiation Checklist",
          documentType: "",
          assetKey: "move-initiation-checklist",
          assetId: "domestic"
        },
        {
          fileType: "PDF",
          documentId: 604,
          fileName: "",
          description: "US Domestic Relo Mileage Requirements Summary",
          documentType: "",
          assetKey: "us-domestic-relo-mileage-requirements-summary",
          assetId: "domestic"
        },
        {
          fileType: "",
          documentId: 33,
          fileName: "",
          title: "Preferred Mortgage Lenders",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 3300,
              fileName: "",
              description: "Mileage Requirements",
              documentType: "",
              assetKey: "domestic-planning",
              assetId: "mileage-requirements",
            },
            {
              fileType: "Website",
              documentId: 3302,
              fileName: "https://premiarelocationmortgage.com/jnj-relo/",
              description: "Premia",
              documentType: "",
            },
            {
              fileType: "Website",
              documentId: 3303,
              fileName: "http://www.quickenloans.com/home-buying/relocation-services",
              description: "QuickenLoans",
              documentType: "",
            },
            {
              fileType: "Website",
              documentId: 3304,
              fileName: "https://www.chase.com/personal/mortgage/relo",
              description: "Chase",
              documentType: "",
            },
          ]
        },
        {
          fileType: "",
          documentId: 34,
          fileName: "",
          title: "Temporary Living Providers",
          documentType: "",
          content: [
            {
              fileType: "Website",
              documentId: 3401,
              fileName: "https://www.aveliving.com ",
              description: "Ave/Korman Corporate Housing",
              documentType: "",
            },
            {
              fileType: "Website",
              documentId: 3302,
              fileName: "https://www.nationalcorporatehousing.com/",
              description: "National Corporate Housing",
              documentType: "",
            }
          ]
        }
      ],
    },
    {
      key: "DomesticPolicies",
      title: "U.S. Domestic Relocation",
      icon: docs,
      items: [
        {
          fileType: "PDF",
          documentId: 84,
          fileName: "",
          description: "U.S. Domestic Relocation Package Benefits Summary",
          documentType: "",
          assetKey: "domestic-policy",
          assetId: "domestic-benefits-summary"
        },
        {
          fileType: "",
          documentId: 34,
          fileName: "",
          title: "Package A - Pay Grade 24 and Below",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 35,
              fileName: "",
              description: "Home Owners Summary",
              documentType: "",
              assetKey: "domestic-package-a",
              assetId: "homeowners-summary"
            },
            {
              fileType: "PDF",
              documentId: 36,
              fileName: "",
              description: "Home Owners Policy",
              documentType: "",
              assetKey: "domestic-package-a",
              assetId: "homeowners-policy"
            },
            {
              fileType: "PDF",
              documentId: 37,
              fileName: "",
              description: "Renters Summary",
              documentType: "",
              assetKey: "domestic-package-a",
              assetId: "renters-summary"
            },
            {
              fileType: "PDF",
              documentId: 38,
              fileName: "",
              description: "Renters Policy",
              documentType: "",
              assetKey: "domestic-package-a",
              assetId: "renters-policy"
            },
          ]
        },
        {
          fileType: "",
          documentId: 39,
          fileName: "",
          title: "Package B - Pay Grade 25 and 26",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 40,
              fileName: "",
              description: "Home Owners Summary",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "homeowner-summary"
            },
            {
              fileType: "PDF",
              documentId: 41,
              fileName: "",
              description: "Home Owners Policy",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "homeowner-policy"
            },
            {
              fileType: "PDF",
              documentId: 42,
              fileName: "",
              description: "Renters Summary",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "renter-summary"
            },
            {
              fileType: "PDF",
              documentId: 43,
              fileName: "",
              description: "Renters Policy",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "renter-transfer-policy"
            },
            {
              fileType: "PDF",
              documentId: 44,
              fileName: "",
              description: "Renter New Hire Policy",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "renter-new-hire-policy"
            },
            {
              fileType: "PDF",
              documentId: 45,
              fileName: "",
              description: "Renter New Hire Summary",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "renter-new-hire-summary"
            },

          ]
        },
        {
          fileType: "PDF",
          documentId: 46,
          fileName: "",
          title: "Package C: Professional - Pay Grade < 30",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 47,
              fileName: "",
              description: "Home Owners Summary",
              documentType: "",
              assetKey: "domestic-package-c-30",
              assetId: "homeowner-summary"
            },
            {
              fileType: "PDF",
              documentId: 48,
              fileName: "",
              description: "Home Owners Policy",
              documentType: "",
              assetKey: "domestic-package-c-30",
              assetId: "homeowner-policy"
            },
            {
              fileType: "PDF",
              documentId: 49,
              fileName: "",
              description: "Renters Summary",
              documentType: "",
              assetKey: "domestic-package-c-30",
              assetId: "renter-summary"
            },
            {
              fileType: "PDF",
              documentId: 50,
              fileName: "",
              description: "Renters Policy",
              documentType: "",
              assetKey: "domestic-package-c-30",
              assetId: "renter-policy"
            },

          ]
        },
        {
          fileType: "PDF",
          documentId: 51,
          fileName: "",
          title: "Package C: Manager Level Only - Pay Grade 30-31",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 52,
              fileName: "",
              description: "Home Owners Summary",
              documentType: "",
              assetKey: "domestic-package-c-30-31",
              assetId: "homeowner-summary"
            },
            {
              fileType: "PDF",
              documentId: 53,
              fileName: "",
              description: "Home Owners Policy",
              documentType: "",
              assetKey: "domestic-package-c-30-31",
              assetId: "homeowner-policy"
            },
            {
              fileType: "PDF",
              documentId: 54,
              fileName: "",
              description: "Renters Summary",
              documentType: "",
              assetKey: "domestic-package-c-30-31",
              assetId: "renter-summary"
            },
            {
              fileType: "PDF",
              documentId: 55,
              fileName: "",
              description: "Renters Policy",
              documentType: "",
              assetKey: "domestic-package-c-30-31",
              assetId: "renter-policy"
            },

          ]
        },
        {
          documentId: 56,
          title: "Package D: Director Level & Above - Pay Grade 40+",
          content: [
            {
              fileType: "PDF",
              documentId: 57,
              fileName: "",
              description: "Home Owners Summary",
              documentType: "",
              assetKey: "domestic-package-c-40",
              assetId: "homeowner-summary"
            },
            {
              fileType: "PDF",
              documentId: 58,
              fileName: "",
              description: "Home Owners Policy",
              documentType: "",
              assetKey: "domestic-package-c-40",
              assetId: "homeowner-policy"
            },
            {
              fileType: "PDF",
              documentId: 59,
              fileName: "",
              description: "Renters Summary",
              documentType: "",
              assetKey: "domestic-package-c-40",
              assetId: "renter-summary"
            },
            {
              fileType: "PDF",
              documentId: 60,
              fileName: "",
              description: "Renters Policy",
              documentType: "",
              assetKey: "domestic-package-c-40",
              assetId: "renter-policy"
            },

          ]
        },
        {
          fileType: "PDF",
          documentId: 61,
          fileName: "",
          title: "Leadership Development Program",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 85,
              fileName: "",
              description: "Leadership Development Relocation Package Summary",
              documentType: "",
              assetKey: "domestic-policy",
              assetId: "leadership-development-summary"
            },
            {
              fileType: "PDF",
              documentId: 62,
              fileName: "",
              description: "New Hire Policy",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "new-hire-policy"
            },
            {
              fileType: "PDF",
              documentId: 63,
              fileName: "",
              description: "New Hire Summary",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "new-hire-summary"
            },
            {
              fileType: "PDF",
              documentId: 64,
              fileName: "",
              description: "Rotational Policy",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "rotational-policy"
            },
            {
              fileType: "PDF",
              documentId: 65,
              fileName: "",
              description: "Rotational Summary",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "rotational-summary"
            },
            {
              fileType: "PDF",
              documentId: 66,
              fileName: "",
              description: "Final Move Policy",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "final-move-policy"
            },
            {
              fileType: "PDF",
              documentId: 67,
              fileName: "",
              description: "Final Move Summary",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "final-move-summary"
            },
            {
              fileType: "PDF",
              documentId: 68,
              fileName: "",
              description: "Final Move Closing Costs Addendum",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "final-move-closing-costs-addendum"
            },
          ]
        },
        {
          fileType: "PDF",
          documentId: 73,
          fileName: "",
          title: "Policies Requiring Consultation",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 70,
              fileName: "",
              description: "Lump Sum Policy",
              documentType: "",
              assetKey: "domestic-policy",
              assetId: "lump-sum-policy"
            },
            {
              fileType: "PDF",
              documentId: 71,
              fileName: "",
              description: "Lump Sum Summary",
              documentType: "",
              assetKey: "domestic-policy",
              assetId: "lump-sum-summary"
            },
            {
              fileType: "PDF",
              documentId: 72,
              fileName: "",
              description: "Lump Sum with Household Goods Summary",
              documentType: "",
              assetKey: "domestic-policy",
              assetId: "lump-sum-with-household-goods-summary"
            },
            {
              fileType: "PDF",
              documentId: 74,
              fileName: "",
              description: "Homeowner 1st Rotation Summary",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "homeowner-1st-rotation-summary"
            },
            {
              fileType: "PDF",
              documentId: 75,
              fileName: "",
              description: "Renter 1st Rotation Summary",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "renter-1st-rotation-summary"
            },
            {
              fileType: "PDF",
              documentId: 76,
              fileName: "",
              description: "Renter 2nd Rotation Summary",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "renter-2nd-rotation-summary"
            },
            {
              fileType: "PDF",
              documentId: 77,
              fileName: "",
              description: "Rotational Program: Homeowner Greater than 12 months 1st Rotation",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "rotational-program-homeowner-greater-than-12-months-1st-rotation"
            },
            {
              fileType: "PDF",
              documentId: 78,
              fileName: "",
              description: "Rotational Program: Homeowner Greater than 12 months 2nd Rotation",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "rotational-program-homeowner-greater-than-12-months-2nd-rotation"
            },
            {
              fileType: "PDF",
              documentId: 79,
              fileName: "",
              description: "Rotational Program: Renter Greater than 12 months 1st Rotation",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "rotational-renter-greater-than 12-months-1st-rotation"
            },
            {
              fileType: "PDF",
              documentId: 80,
              fileName: "",
              description: "Rotational Program: Renter Greater than 12 months 2nd Rotation",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "rotational-renter-greater-than 12-months-2nd-rotation"
            },
            {
              fileType: "PDF",
              documentId: 81,
              fileName: "",
              description: "Household Goods Only Summary",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "household-goods-only-summary"
            },
            {
              fileType: "PDF",
              documentId: 82,
              fileName: "",
              description: "Household Goods Only Policy",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "household-goods-only-policy"
            },
            {
              fileType: "PDF",
              documentId: 83,
              fileName: "",
              description: "Auto Transport Only Summary",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "auto-transport-only-summary"
            },
          ]
        },
      ]
    },
    {
      key: "Agreements",
      title: "Repayment Agreements",
      icon: partnership,
      items: [
        {
          fileType: "PDF",
          documentId: 86,
          fileName: "",
          description: "Relocation Payment Guide",
          documentType: "",
          assetKey: "repayment-agreement",
          assetId: "repayment-guide"
        },
        {
          fileType: "PDF",
          documentId: 87,
          fileName: "",
          description: "Summary Repayment Process",
          documentType: "",
          assetKey: "repayment-agreement",
          assetId: "summary-repayment-process"
        },
        {
          fileType: "DOC",
          documentId: 88,
          fileName: "",
          description: "Repayment Form",
          documentType: "",
          assetKey: "repayment-agreement",
          assetId: "repayment-form"
        },
      ]
    },
    {
      title: "Additional Policies",
      key: "Policies",
      icon: clipboard,
      items: [
        {
          fileType: "PDF",
          documentId: 700,
          fileName: "",
          description: "Relocation Chargeback Guidelines",
          documentType: "",
          assetKey: "relocation-chargeback-guidelines",
          assetId: "domestic"
        },
        {
          fileType: "PDF",
          documentId: 90,
          fileName: "",
          description: "High Cost Area Subsidy",
          documentType: "",
          assetKey: "domestic-additional",
          assetId: "high-cost-area-subsidy"
        },
        {
          fileType: "PDF",
          documentId: 91,
          fileName: "",
          description: "Loss of Company Car",
          documentType: "",
          assetKey: "domestic-additional",
          assetId: "loss-of-company-car"
        },
      ],
    },
  ],
  managerTrain: [
    {
      title: "Training Materials",
      icon: clipboard,
      items: [
        {
          fileType: "PDF",
          documentId: 1,
          fileName: "",
          description: "Website User Guide",
          documentType: "",
          assetKey: "training-guide",
          assetId: "move"
        },
        {
          fileType: "Webpage",
          documentId: 56,
          fileName: "",
          description: "Website Overview",
          documentType: "",
        },
      ],
    },
  ],
  int: [
    {
      title: "Assignment Guides & Policies",
      icon: clipboard,
      items: [
        {
          fileType: "PDF",
          documentId: 1,
          fileName: "",
          description: "Short Term Policy",
          documentType: "",
          assetKey: "global-policy",
          assetId: "short-term"
        },
        {
          fileType: "PDF",
          documentId: 2,
          fileName: "",
          description: "Long Term Tier 1 Policy",
          documentType: "",
          assetKey: "global-policy",
          assetId: "long-term-tier-1"
        },
        {
          fileType: "PDF",
          documentId: 3,
          fileName: "",
          description: "Long Term Tier 2 Policy",
          documentType: "",
          assetKey: "global-policy",
          assetId: "long-term-tier-2"
        },
        {
          fileType: "PDF",
          documentId: 4,
          fileName: "",
          description: "Commuter Policy",
          documentType: "",
          assetKey: "global-policy",
          assetId: "commuter"
        },
        {
          fileType: "PDF",
          documentId: 5,
          fileName: "",
          description: "One Way Move Policy Tier 1",
          documentType: "",
          assetKey: "global-policy",
          assetId: "one-way-move-tier-1"
        },
        {
          fileType: "PDF",
          documentId: 6,
          fileName: "",
          description: "One Way Move Policy Tier 2",
          documentType: "",
          assetKey: "global-policy",
          assetId: "one-way-move-tier-2"
        },
        {
          fileType: "PDF",
          documentId: 7,
          fileName: "",
          description: "One Way Move Policy Tier 3",
          documentType: "",
          assetKey: "global-policy",
          assetId: "one-way-move-tier-3"
        }
      ]
    },
    {
      title: "Cultural Prep & Planning",
      icon: world,
      items: [
        {
          fileType: "PDF",
          documentId: 17,
          fileName: "",
          description: "Cultural Assessment Guide",
          documentType: "",
          assetKey: "global-planning",
          assetId: "cultural-assessment-guide"
        },
      ]
    },
    {
      title: "Immigration",
      icon: personWorld,
      items: [
        {
          fileType: "XLS",
          documentId: 20,
          fileName: "",
          description: "Immigration Recommended Lead Times",
          documentType: "",
          assetKey: "global-immigration",
          assetId: "immigration-complexity-matrix"
        },
      ]
    },
    {
      title: "Healthcare",
      icon: hospital,
      items: [
        {
          fileType: "PDF",
          documentId: 22,
          fileName: "",
          description: "Pre-Assignment Assistance",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "pre-assignment-assistance"
        },
        {
          fileType: "PDF",
          documentId: 23,
          fileName: "",
          description: "Pre Departure Guide",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "pre-departure-guide"
        },
        {
          fileType: "PDF",
          documentId: 24,
          fileName: "",
          description: "CIGNA Envoy Registration Guide",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "envoy-registration-for-the-americas"
        },
        {
          fileType: "PDF",
          documentId: 25,
          fileName: "",
          description: "Benefit Summary: Global Assignees in the U.S. or Overseas",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "global-assignees-in-the-U-S-or-overseas"
        },
        {
          fileType: "PDF",
          documentId: 26,
          fileName: "",
          description: "Benefit Summary: U.S.-Based Assignees working outside the U.S.",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "assignees-working-outside-the-u-s"
        },
        {
          fileType: "PDF",
          documentId: 27,
          fileName: "",
          description: "Benefit Summary: Assignees from Puerto Rico",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "assignees-from-puerto-rico"
        },
        {
          fileType: "PDF",
          documentId: 28,
          fileName: "",
          description: "Online Claims Submissions Info",
          documentType: "",
          assetKey: "global-healthcare",
          assetId: "online-claims-submission"
        },

      ]
    }
  ],
  dom: [
    {
      title: "Planning & Checklists",
      icon: clipboard,
      items: [
        {
          fileType: "PDF",
          documentId: 29,
          fileName: "",
          description: "Relocation Checklist: Homeowners Package C",
          documentType: "",
          assetKey: "domestic-planning",
          assetId: "relocation- checklist:-homeowners-package-c"
        },
        {
          fileType: "PDF",
          documentId: 30,
          fileName: "",
          description: "Relocation Checklist: Homeowners Package D",
          documentType: "",
          assetKey: "domestic-planning",
          assetId: "relocation-checklist:-homeowners-package-d"
        },
        {
          fileType: "PDF",
          documentId: 31,
          fileName: "",
          description: "Relocation Checklist: Renters",
          documentType: "",
          assetKey: "domestic-planning",
          assetId: "relocation-checklist-renters"
        },
        {
          fileType: "DOC",
          documentId: 32,
          fileName: "",
          description: "Pre-Departure Checklist",
          documentType: "",
          assetKey: "domestic-planning",
          assetId: "pre-departure-checklist"
        },
          {
              fileType: "PDF",
              documentId: 33,
              fileName: "",
              description: "US Domestic Relo Mileage Requirements Summary",
              documentType: "",
              assetKey: "us-domestic-relo-mileage-requirements-summary",
              assetId: "domestic"
          },
        {
          fileType: "",
          documentId: 34,
          fileName: "",
          title: "Preferred Mortgage Lenders",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 3300,
              fileName: "",
              description: "Mileage Requirements",
              documentType: "",
              assetKey: "domestic-planning",
              assetId: "mileage-requirements",
            },
            {
              fileType: "Website",
              documentId: 3302,
              fileName: "https://premiarelocationmortgage.com/jnj-relo/",
              description: "Premia",
              documentType: "",
            },
            {
              fileType: "Website",
              documentId: 3303,
              fileName: "http://www.quickenloans.com/home-buying/relocation-services",
              description: "QuickenLoans",
              documentType: "",
            },
            {
              fileType: "Website",
              documentId: 3304,
              fileName: "https://www.chase.com/personal/mortgage/relo",
              description: "Chase",
              documentType: "",
            },
          ]
        },
        {
          fileType: "",
          documentId: 35,
          fileName: "",
          title: "Temporary Living Providers",
          documentType: "",
          content: [
            {
              fileType: "Website",
              documentId: 3401,
              fileName: "https://www.aveliving.com ",
              description: "Ave/Korman Corporate Housing",
              documentType: "",
            },
            {
              fileType: "Website",
              documentId: 3302,
              fileName: "https://www.nationalcorporatehousing.com/",
              description: "National Corporate Housing",
              documentType: "",
            }
          ]
        }
      ]
    },
    {
      key: "EmployeeDomesticPolicies",
      title: "U.S. Domestic Relocation",
      icon: docs,
      items: [
        {
          fileType: "PDF",
          documentId: 84,
          fileName: "",
          description: "U.S. Domestic Relocation Package Benefits Summary",
          documentType: "",
          assetKey: "domestic-policy",
          assetId: "domestic-benefits-summary"
        },
        {
          fileType: "",
          documentId: 34,
          fileName: "",
          title: "Package A - Pay Grade 24 and Below",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 35,
              fileName: "",
              description: "Home Owners Summary",
              documentType: "",
              assetKey: "domestic-package-a",
              assetId: "homeowners-summary"
            },
            {
              fileType: "PDF",
              documentId: 36,
              fileName: "",
              description: "Home Owners Policy",
              documentType: "",
              assetKey: "domestic-package-a",
              assetId: "homeowners-policy"
            },
            {
              fileType: "PDF",
              documentId: 37,
              fileName: "",
              description: "Renters Summary",
              documentType: "",
              assetKey: "domestic-package-a",
              assetId: "renters-summary"
            },
            {
              fileType: "PDF",
              documentId: 38,
              fileName: "",
              description: "Renters Policy",
              documentType: "",
              assetKey: "domestic-package-a",
              assetId: "renters-policy"
            },

          ]
        },
        {
          fileType: "",
          documentId: 39,
          fileName: "",
          title: "Package B - Pay Grade 25 and 26",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 40,
              fileName: "",
              description: "Home Owners Summary",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "homeowner-summary"
            },
            {
              fileType: "PDF",
              documentId: 41,
              fileName: "",
              description: "Home Owners Policy",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "homeowner-policy"
            },
            {
              fileType: "PDF",
              documentId: 42,
              fileName: "",
              description: "Renters Summary",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "renter-summary"
            },
            {
              fileType: "PDF",
              documentId: 43,
              fileName: "",
              description: "Renters Policy",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "renter-transfer-policy"
            },
            {
              fileType: "PDF",
              documentId: 44,
              fileName: "",
              description: "Renter New Hire Policy",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "renter-new-hire-policy"
            },
            {
              fileType: "PDF",
              documentId: 45,
              fileName: "",
              description: "Renter New Hire Summary",
              documentType: "",
              assetKey: "domestic-package-b",
              assetId: "renter-new-hire-summary"
            },

          ]
        },
        {
          fileType: "PDF",
          documentId: 46,
          fileName: "",
          title: "Package C: Professional - Pay Grade < 30",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 47,
              fileName: "",
              description: "Home Owners Summary",
              documentType: "",
              assetKey: "domestic-package-c-30",
              assetId: "homeowner-summary"
            },
            {
              fileType: "PDF",
              documentId: 48,
              fileName: "",
              description: "Home Owners Policy",
              documentType: "",
              assetKey: "domestic-package-c-30",
              assetId: "homeowner-policy"
            },
            {
              fileType: "PDF",
              documentId: 49,
              fileName: "",
              description: "Renters Summary",
              documentType: "",
              assetKey: "domestic-package-c-30",
              assetId: "renter-summary"
            },
            {
              fileType: "PDF",
              documentId: 50,
              fileName: "",
              description: "Renters Policy",
              documentType: "",
              assetKey: "domestic-package-c-30",
              assetId: "renter-policy"
            },

          ]
        },
        {
          fileType: "PDF",
          documentId: 51,
          fileName: "",
          title: "Package C: Manager Level Only - Pay Grade 30-31",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 52,
              fileName: "",
              description: "Home Owners Summary",
              documentType: "",
              assetKey: "domestic-package-c-30-31",
              assetId: "homeowner-summary"
            },
            {
              fileType: "PDF",
              documentId: 53,
              fileName: "",
              description: "Home Owners Policy",
              documentType: "",
              assetKey: "domestic-package-c-30-31",
              assetId: "homeowner-policy"
            },
            {
              fileType: "PDF",
              documentId: 54,
              fileName: "",
              description: "Renters Summary",
              documentType: "",
              assetKey: "domestic-package-c-30-31",
              assetId: "renter-summary"
            },
            {
              fileType: "PDF",
              documentId: 55,
              fileName: "",
              description: "Renters Policy",
              documentType: "",
              assetKey: "domestic-package-c-30-31",
              assetId: "renter-policy"
            },

          ]
        },
        {
          documentId: 56,
          title: "Package D: Director Level & Above - Pay Grade 40+",
          content: [
            {
              fileType: "PDF",
              documentId: 57,
              fileName: "",
              description: "Home Owners Summary",
              documentType: "",
              assetKey: "domestic-package-c-40",
              assetId: "homeowner-summary"
            },
            {
              fileType: "PDF",
              documentId: 58,
              fileName: "",
              description: "Home Owners Policy",
              documentType: "",
              assetKey: "domestic-package-c-40",
              assetId: "homeowner-policy"
            },
            {
              fileType: "PDF",
              documentId: 59,
              fileName: "",
              description: "Renters Summary",
              documentType: "",
              assetKey: "domestic-package-c-40",
              assetId: "renter-summary"
            },
            {
              fileType: "PDF",
              documentId: 60,
              fileName: "",
              description: "Renters Policy",
              documentType: "",
              assetKey: "domestic-package-c-40",
              assetId: "renter-policy"
            },

          ]
        },
        {
          fileType: "PDF",
          documentId: 61,
          fileName: "",
          title: "Leadership Development Program",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 85,
              fileName: "",
              description: "Leadership Development Relocation Package Summary",
              documentType: "",
              assetKey: "domestic-policy",
              assetId: "leadership-development-summary"
            },
            {
              fileType: "PDF",
              documentId: 62,
              fileName: "",
              description: "New Hire Policy",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "new-hire-policy"
            },
            {
              fileType: "PDF",
              documentId: 63,
              fileName: "",
              description: "New Hire Summary",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "new-hire-summary"
            },
            {
              fileType: "PDF",
              documentId: 64,
              fileName: "",
              description: "Rotational Policy",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "rotational-policy"
            },
            {
              fileType: "PDF",
              documentId: 65,
              fileName: "",
              description: "Rotational Summary",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "rotational-summary"
            },
            {
              fileType: "PDF",
              documentId: 66,
              fileName: "",
              description: "Final Move Policy",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "final-move-policy"
            },
            {
              fileType: "PDF",
              documentId: 67,
              fileName: "",
              description: "Final Move Summary",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "final-move-summary"
            },
            {
              fileType: "PDF",
              documentId: 68,
              fileName: "",
              description: "Final Move Closing Costs Addendum",
              documentType: "",
              assetKey: "domestic-leadership-development",
              assetId: "final-move-closing-costs-addendum"
            },
          ]
        },
        {
          fileType: "PDF",
          documentId: 73,
          fileName: "",
          title: "Policies Requiring Consultation",
          documentType: "",
          content: [
            {
              fileType: "PDF",
              documentId: 70,
              fileName: "",
              description: "Lump Sum Policy",
              documentType: "",
              assetKey: "domestic-policy",
              assetId: "lump-sum-policy"
            },
            {
              fileType: "PDF",
              documentId: 71,
              fileName: "",
              description: "Lump Sum Summary",
              documentType: "",
              assetKey: "domestic-policy",
              assetId: "lump-sum-summary"
            },
            {
              fileType: "PDF",
              documentId: 72,
              fileName: "",
              description: "Lump Sum with Household Goods Summary",
              documentType: "",
              assetKey: "domestic-policy",
              assetId: "lump-sum-with-household-goods-summary"
            },
            {
              fileType: "PDF",
              documentId: 74,
              fileName: "",
              description: "Homeowner 1st Rotation Summary",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "homeowner-1st-rotation-summary"
            },
            {
              fileType: "PDF",
              documentId: 75,
              fileName: "",
              description: "Renter 1st Rotation Summary",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "renter-1st-rotation-summary"
            },
            {
              fileType: "PDF",
              documentId: 76,
              fileName: "",
              description: "Renter 2nd Rotation Summary",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "renter-2nd-rotation-summary"
            },
            {
              fileType: "PDF",
              documentId: 77,
              fileName: "",
              description: "Rotational Program: Homeowner Greater than 12 months 1st Rotation",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "rotational-program-homeowner-greater-than-12-months-1st-rotation"
            },
            {
              fileType: "PDF",
              documentId: 78,
              fileName: "",
              description: "Rotational Program: Homeowner Greater than 12 months 2nd Rotation",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "rotational-program-homeowner-greater-than-12-months-2nd-rotation"
            },
            {
              fileType: "PDF",
              documentId: 79,
              fileName: "",
              description: "Rotational Program: Renter Greater than 12 months 1st Rotation",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "rotational-renter-greater-than 12-months-1st-rotation"
            },
            {
              fileType: "PDF",
              documentId: 80,
              fileName: "",
              description: "Rotational Program: Renter Greater than 12 months 2nd Rotation",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "rotational-renter-greater-than 12-months-2nd-rotation"
            },
            {
              fileType: "PDF",
              documentId: 81,
              fileName: "",
              description: "Household Goods Only Summary",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "household-goods-only-summary"
            },
            {
              fileType: "PDF",
              documentId: 82,
              fileName: "",
              description: "Household Goods Only Policy",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "household-goods-only-policy"
            },
            {
              fileType: "PDF",
              documentId: 83,
              fileName: "",
              description: "Auto Transport Only Summary",
              documentType: "",
              assetKey: "domestic-consultation",
              assetId: "auto-transport-only-summary"
            },
          ]
        }
      ]
    },
    {
      title: "Repayment Agreements",
      icon: partnership,
      items: [
        {
          fileType: "PDF",
          documentId: 86,
          fileName: "",
          description: "Relocation Payment Guide",
          documentType: "",
          assetKey: "repayment-agreement",
          assetId: "repayment-guide"
        },
        {
          fileType: "PDF",
          documentId: 87,
          fileName: "",
          description: "Summary Repayment Process",
          documentType: "",
          assetKey: "repayment-agreement",
          assetId: "summary-repayment-process"
        },
        {
          fileType: "DOC",
          documentId: 88,
          fileName: "",
          description: "Repayment Form",
          documentType: "",
          assetKey: "repayment-agreement",
          assetId: "repayment-form"
        },
      ]
    },
    {
      title: "Additional Policies",
      icon: docs,
      items: [
        {
          fileType: "PDF",
          documentId: 90,
          fileName: "",
          description: "High Cost Area Subsidy",
          documentType: "",
          assetKey: "domestic-additional",
          assetId: "high-cost-area-subsidy"
        },
        {
          fileType: "PDF",
          documentId: 91,
          fileName: "",
          description: "Loss of Company Car",
          documentType: "",
          assetKey: "domestic-additional",
          assetId: "loss-of-company-car"
        },

      ]
    },
  ],
  train: [
    {
      title: "Training Materials",
      icon: screen,
      items: [
        {
          fileType: "PDF",
          documentId: 55,
          fileName: "",
          description: "Website User Guide",
          assetKey: "training-guide",
          assetId: "move"
        },
        {
          fileType: "Webpage",
          documentId: 56,
          fileName: "",
          description: "Website Overview",
          documentType: "",
        },
      ]
    },

  ]
};
