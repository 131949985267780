// @flow

import React from 'react';
import {
    Contacts,
    CAROUSEL_TALK_CONTACTS_PHONES,
    CAROUSEL_TALK_CONTACTS_EMAILS
} from 'components/common';
import './style.scss';

export default () => (
    <div className="top-container-contacts flexible aCenter">
        <div className="contacts-container flexible jBetween grow">
            <Contacts className='contact' title='Connect By Phone:' contacts={CAROUSEL_TALK_CONTACTS_PHONES} />
            <Contacts className='contact' title='Connect By Email:' contacts={CAROUSEL_TALK_CONTACTS_EMAILS} />
        </div>
    </div>
);
