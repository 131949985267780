export const globalOrDomesticOpts = {
  global: {
    text: "global",
    displayText: "Global Move",
    value: "Global",
    countryName: "",
    countryCode: "",
    description: "The employee is moving from one country to another country"
  },
  domestic: {
    text: "domestic",
    displayText: "Moves within the U.S.",
    value: "Domestic",
    countryName: "United States",
    countryCode: "US",
    description: "The employee is moving from one U.S. location to another U.S. location"
  }
}

export const oneWayOrAssignmentOpts = {
  owm: {
    text: "owm",
    displayText: "One Way Move",
    value: "owm",
    description: "A Global relocation cross border that is intended to last over 5 years."
  },
  assignment: {
    text: "assignment",
    displayText: "Assignment",
    value: "assignment",
    description: "A temporary relocation intended to last between 3 months and 5 years."
  }
}

export const PayGradeOpts = {
  FiftyAndAbove: {
    text: "50-and-above",
    displayText: "50 and above",
    value: "50+",
    payGrade: "PayGrade50"
  },
  FortyOneAndBelow: {
    text: "41-and-below",
    displayText: "41 and below",
    value: "41-",
    payGrade: "PayGrade40"
  }
}

export const nosequentialMoveOpts = {
  notWorkingWithRec: {
    text: "not-working-with-rec",
    displayText: "No, I am not working with a recruiter",
    value: "no",
    description: ""
  },
  yesWorkingWithRec: {
    text: "yes-working-with-rec",
    displayText: "Yes, I am working with a recruiter",
    value: "yes",
    description: ""
  }
}

export const sequentialMoveOpts = {
  willMakeOneWay: {
    text: "will-make-one-way",
    displayText: "Yes, Employee will make a One Way Move",
    value: "yes",
    description: "A Global relocation cross border that is intended to last over 5 years."
  },
  empOnAssignment: {
    text: "yes-employee-will-be-on-assignment",
    displayText: "Yes, Employee will be on Assignment",
    value: "yes-employee-will-be-on-assignment",
    description: "A temporary relocation intended to last between 3 months and 5 years."
  },
  notWorkingWithRec: {
    text: "not-working-with-rec",
    displayText: "No, I am not working with a recruiter",
    value: "no",
    description: ""
  },
}

export const global = {
  repatriation: {
    text: "repatriation",
    displayText: "Repatriation",
    value: "Repatriation",
    description: "The employee's assignment will be ending."
  },
  extension: {
    text: "extension",
    displayText: "Extension",
    value: "Extension",
    description: "A decision has been made to extend the assignment in the current location."
  },
  localization: {
    text: "localization",
    displayText: "Localization",
    value: "Localization",
    description: "The global assignee will be localized in the host location rather than return to the home country."
  },
  sequentialMove: {
    text: "sequential-move",
    displayText: "Sequential Move",
    value: "SequentialMove",
    description: "The global assignee is completing one assignment and beginning another assignment in a different location.",
    sequentialMoveOpts
  },
}

export const domestic = sequentialMoveOpts
const PreInitMap = {
  global,
  domestic
}
export default PreInitMap;