import React from "react";
import { Icon, SelectionCard } from "components/common";
import Lightbulb from "components/common/Images/Lightbulb.svg";
import NoMoving from "components/common/Images/NoMoving.svg";
import PayGrade from "../PayGrade";
import { RouterProps } from "types";
import RecruiterKnockout from "../RecruiterKnockout";
import "./styles.scss";

const WorkingWithRecruiter = (
    props: RouterProps & {
        fromOptions?: boolean;
    },
) => {
    const clickHandler = (value: string): void =>
        props.history.push({
            state: {
                ...props.location.state,
                "working-with-recruiter": value,
            },
        });

    const backHandler = (): void => {
        if (props.fromOptions) {
            props.history.push({
                state: {
                    ...props.location.state,
                    "initiate-move": "",
                },
            });
        } else {
            props.history.push({
                state: {
                    ...props.location.state,
                    "global-assignment": "",
                },
            });
        }
    };

    if (
        props.location.state &&
        props.location.state["working-with-recruiter"] === "yes"
    ) {
        return (
            <RecruiterKnockout
                history={props.history}
                location={props.location}
            />
        );
    } else if (
        props.location.state &&
        props.location.state["working-with-recruiter"] === "no"
    ) {
        return <PayGrade history={props.history} location={props.location} />;
    } else if (
        props.location.state &&
        props.location.state["working-with-recruiter"] === "yes-employee-will-be-on-assignment"
    ) {
        return <PayGrade history={props.history} location={props.location} />;
    }

    return (
        <div className="people-leader-container">
            <div className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </div>
            <h1 className="section-title">Are you currently working with a recruiter for this position?</h1>
            <section className="CardSection">

                <SelectionCard
                    type="regularSelectionCard"
                    backgroundColor="white"
                    title="Yes, Employee will make a One Way Move"
                    content="A Global relocation cross border that is intended to last over 5 years."
                    className="working-with-a-recruiter"
                    onClick={(): void => clickHandler("yes")}
                />
                <SelectionCard
                    type="regularSelectionCard"
                    backgroundColor="white"
                    title="Yes, Employee will be on Assignment"
                    content="A temporary relocation intended to last between 3 months and 5 years."
                    className="working-with-a-recruiter"
                    onClick={(): void => clickHandler("yes-employee-will-be-on-assignment")}
                />
                <SelectionCard
                    type="regularSelectionCard"
                    backgroundColor="white"
                    title="No, I am not working with a recruiter"
                    content=" "
                    className="working-with-a-recruiter"
                    onClick={(): void => clickHandler("no")}
                />
            </section>
        </div>
    );
};

export default WorkingWithRecruiter;
