import React, { PureComponent } from 'react';

import './style.scss';

export default class Input extends PureComponent{

    state = {
        value: '',
        isFocused: ''
    };

    componentWillMount(){
        this.setState({ value: this.props.value })
    }

    setValue = ({ target }) => {
        this.props.onChange && this.props.onChange({target})
        this.setState({ value: target.value });
    }

    inputFocused = ({ target }) => {
        this.setState({ isFocused: 'focused' });
    }

    inputBlur = ({ target }) => {
        if(target.value !== ''){
            this.setState({isFocused: "focused"})
        } else {
            this.setState({isFocused: ""})  
        }
    }

    render(){
        const {style} = this.props;
        const { isFocused } = this.state;
        return(
            <div className={"Input " + isFocused }>
                <input
                    type="text"
                    required
                    maxlength={this.props.maxlength}
                    data-key={this.props.dataKey}
                    value={this.state.value}
                    onChange={this.setValue}
                    onFocus={this.inputFocused}
                    onBlur={this.inputBlur}
                    style={style}
                />
                <span className="placeholder">{this.props.placeholder}<span className="required-symbol" > *</span></span>
            </div>
        )
    }
}
