// @flow
import React from 'react';

type StepperIconType = {
    isActive: boolean;
    isComplete: boolean;
    style: object;
}

export default class StepperIcon extends React.PureComponent<StepperIconType> {
    render(): React.ReactNode {
        const { isActive, isComplete, style } = this.props;
        const iconClasses = ['stepper-dot'];

        if (isActive) {
            iconClasses.push('dot-active');
        }

        if (isComplete) {
            iconClasses.push('dot-complete');
        }
        return <span style={style} className={iconClasses.join(' ')} />
    }
}
