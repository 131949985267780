import React, { PureComponent } from 'react';
import HistoryA from '../../components/common/HistoryA'
import './style.scss';
export default class CookiePolicy extends PureComponent {
    render() {
        return (
            
            <div id="main-content" className="wiki-content">

            <h1>Cookie Policy</h1>
            <p>This Cookie Policy is designed to tell you about our practices regarding the collection of information through cookies and other tracking technologies (like gifs, web beacons, etc.).</p>
            <p>The functionalities for which we use these technologies may include the following:</p>
            <ul>
                <li>Assisting you in navigation,</li>
                <li>Assisting in registering for our events, login, and your ability to provide feedback,</li>
                <li>Analyzing the use of our products, services or applications,</li>
                <li>Assisting with our promotional and marketing efforts (including behavioral advertising),</li>
                <li>Offering content of third parties (such as social media content).</li>
            </ul>
            <p>Below is a detailed list of the cookies we use, with a description of its purpose(s), expiry and information on refusing the Cookies in “How to Block”.</p>
            <p>Except where allowed by applicable law, we place cookies after having received your consent.</p>
            <h3>Links to other websites</h3>
            <p>This site may contain links or references to other websites. Please be aware that we do not control the cookies / tracking technologies of other websites and this Cookie Policy does not apply to those websites.</p>
            <h3>How to Contact Us</h3>
            <p>If you have any questions, comments, or concerns about this Cookie Policy or the information practices of this site, please use the contact information included in our <a href="/privacypolicy">Privacy Policy</a>.</p>
            <h3>Changes to This Cookie Policy</h3>
            <p>If this Cookie Policy changes, the revised policy will be posted on this site. This Cookie Policy was last updated 08/20/2018<strong>.</strong></p>
          
          
            <h2>Annex A: Types of Cookies Used on This Website</h2>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <colgroup>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                    </colgroup>
                    <thead>
                        <tr>
                            <td class="confluenceTd">
                                <p align="center"><strong>Type of Cookie</strong></p>
                            </td>
                            <td class="confluenceTd">
                                <p align="center"><strong>Source</strong></p>
                            </td>
                            <td class="confluenceTd">
                                <p align="center"><strong>Purpose</strong></p>
                            </td>
                            <td class="confluenceTd">
                                <p align="center"><strong>Expiry</strong></p>
                            </td>
                            <td class="confluenceTd">
                                <p align="center"><strong>How to Block</strong></p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="5" class="confluenceTd">
                                <p align="center"><strong> </strong></p>
                                <p align="center"><strong>Cookies / tracking technologies served by us (first party cookies)</strong></p>
                                <p align="center"><strong> </strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td class="confluenceTd">
                                <p><strong>Cookies necessary for essential website purposes and functions</strong></p>
                                <p>idsrv.session</p>
                                <p>Saml2.*</p>
                                <p>idsrv.external</p>
                                <p>.AspNetCore.Identity.Application</p>
                                <p>Identity.External</p>
                                <p>Identity.TwoFactorUserId</p>
                                <p>.AspNetCore.Cookies</p>
                                <p>idsrv.session</p>
                                <p>.AspNetCore.Identity.Application</p>
                                <p>ARRAffinity</p>
                                <p>.AspNetCore.Antiforgery</p>
                            </td>
                            <td class="confluenceTd">
                                <p>Johnson &amp; Johnson</p>
                            </td>
                            <td class="confluenceTd">
                                <p>These cookies are strictly necessary for the proper operation of our site. They allow us to ensure the security and efficient delivery of our site.</p>
                            </td>
                            <td class="confluenceTd">
                                <p>The cookie we set for this purpose is automatically removed from your device one month after you last visited our website.</p>
                            </td>
                            <td class="confluenceTd">
                                <p>You can change your browser settings to accept or refuse these cookies.</p>
                                <p>As the means vary from browser to browser, you should visit your browser's Help menu for more information.</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="confluenceTd">
                                <p><strong>Website enhancement cookies</strong></p>
                                <p>movejnjthirdparty</p>
                            </td>
                            <td class="confluenceTd">
                                <p>Johnson &amp; Johnson</p>
                            </td>
                            <td class="confluenceTd">
                                <p>These cookies help enhance the performance and usability of our website. For example, they may allow us to detect whether your browser can run small website programs (called 'scripts') that provide additional website functionality or enable us to remember any website preferences you set (such as font size or language preferences).</p>
                            </td>
                            <td class="confluenceTd">
                                <p>The cookie we set for this purpose is automatically removed from your device once you close your browser.</p>
                                <p> </p>
                            </td>
                            <td class="confluenceTd">
                                <p>You can change your browser settings to accept or refuse these cookies. As the means vary from browser to browser, you should visit your browser's help menu for more information.</p>
                                <p> </p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5" class="confluenceTd">
                                <p><strong><em> </em></strong></p>
                                <p align="center"><strong>Cookies / tracking technologies served by service providers and other companies (third party cookies)</strong></p>
                                <p> </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="confluenceTd">
                                <p><strong>Analytics cookies and technologies</strong></p>
                                <p>_ga</p>
                                <p>_gid</p>
                            </td>
                            <td class="confluenceTd">
                                <p>Google Analytics</p>
                            </td>
                            <td class="confluenceTd">
                                <p>These cookies are used to collect information about how visitors use our site. We use the information to compile reports and to help us improve the site. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from and the pages they visited.</p>
                            </td>
                            <td class="confluenceTd">
                                <p>Some of the cookies set for this purpose are automatically removed from your device once you close your browser.</p>
                                <p>Others may last for a period of up to 24 months from your last visit to our site.</p>
                                <p> </p>
                            </td>
                            <td class="confluenceTd">
                                <p>You can opt out of tracking by Google Analytics by visiting: <HistoryA href="http://tools.google.com/dlpage/gaoptout?hl=en-GB" class="external-link" rel="nofollow">http://tools.google.com/dlpage/gaoptout?hl=en-GB</HistoryA>.</p>
                                <p> </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="confluenceTd">
                                <p><strong>Website functionality cookies and technologies</strong></p>
                                <p> </p>
                                <p>BOX.SessionCacheKey.SessID</p>
                                <p>x-server</p>
                                <p>flt</p>
                                <p>gatewaydc</p>
                                <p>x-ms-gateway-slice</p>
                                <p>stsservicecookie</p>
                            </td>
                            <td class="confluenceTd">
                                <p>Microsoft</p>
                            </td>
                            <td rowspan="3" class="confluenceTd">
                                <p>These cookies are used to provide functionality on our website that is supplied by third parties. Without these cookies, certain website functionality will be unavailable to our visitors. The data collected by our service providers for this purpose may be shared on an anonymised basis with third parties, and may be used for targeted advertising purposes.</p>
                            </td>
                            <td rowspan="3" class="confluenceTd">
                                <p>Some of the cookies set for this purpose are automatically removed from your device once you close your browser.</p>
                                <p>Others may last for a period of up to 24 hours from your last visit to our site.</p>
                                <p> </p>
                            </td>
                            <td rowspan="3" class="confluenceTd">
                                <p>You can change your browser settings to accept or refuse these cookies. As the means vary from browser to browser, you should visit your browser's help menu for more information.</p>
                                <p> </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="confluenceTd">
                                <p>ASP.NET_SessionId</p>
                                <p><strong> </strong></p>
                            </td>
                            <td class="confluenceTd">
                                <p>Sirva</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="confluenceTd">
                                <p>i18n s</p>
                                <p>session_id</p>
                                <p>jrasession</p>
                                <p>jasession</p>
                            </td>
                            <td class="confluenceTd">
                                <p>Jibe</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="confluenceTd">
                                <p><strong>Security cookies</strong></p>
                                <p>auth0</p>
                                <p>FedAuth</p>
                            </td>
                            <td class="confluenceTd">
                                <p>Sirva</p>
                            </td>
                            <td rowspan="2" class="confluenceTd">
                                <p>These cookies are used to improve the security of our site and to protect our site against malicious visitor activity. The data collected by our service providers for this purpose may be shared on an anonymised basis with third parties, and may be used for targeted advertising purposes.</p>
                                <p> </p>
                            </td>
                            <td rowspan="2" class="confluenceTd">
                                <p>Some of the cookies set for this purpose are automatically removed from your device once you close your browser.</p>
                                <p>Others may last for a period of up to 24 hours from your last visit to our site.</p>
                                <p> </p>
                            </td>
                            <td rowspan="2" class="confluenceTd">
                                <p>You can change your browser settings to accept or refuse these cookies. As the means vary from browser to browser, you should visit your browser's help menu for more information.</p>
                                <p> </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="confluenceTd">
                                <p>OpenIdConnect.nonce.OpenIdConnect</p>
                                <p><strong> </strong></p>
                            </td>
                            <td class="confluenceTd">
                                <p>Microsoft</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        );
    }
}