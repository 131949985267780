import React from "react";
import classNames from "classnames";
import Stepper from "./Stepper";
import stepperData from "./MockData";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserProfile } from "selectors";
import PreInitiation from "./PreInitiation/index2";
import JobDetails from "./EmpJobDetails";
import FinanceAdmin from "./FinanceAdmin";
import ReviewAndSubmit from "./ReviewAndSubmit";
import AdditionalDetails from "./AdditionalDetails";
import SuccessCompletion from "./Success";
import PackageSelection from "./PackageSelection";
import { MoveDetailsProps, MoveDetailsState, MoveDetailsStepper } from "types";
import "./styles.scss";

const mapStateToProps = (state: any) => ({
    userProfile: getUserProfile(state),
});

class NonCompetitveDetails extends React.Component<MoveDetailsProps, MoveDetailsState> {
    constructor(props: MoveDetailsProps) {
        super(props);
        this.state = {
            stepper: this.handleInitStepper(),
            activeStepperIndex: Object.keys(this.stepMap)
                .indexOf(this.props.match.params.step) || 0,
            comp: this.stepMap[this.props.match.params.step] || this.stepMap["pre-initiation"],
        };
    }

    navigateTo = (to: string, from: string): void => {
        this.setState({
            comp: this.stepMap[to],
        });
        this.generateStepper(to, from);
        const { pathname } = this.props.location;
        let pathArr = pathname.split("/");
        /** 
         * not allowing to go back to preinitiation in a particular flow, if started from the begining.
         * */
         if(to === "package-selection" && from === "pre-initiation") {
            this.props.history.replace("/non-competitive-initiation/" + to, 
            {...this.props.history.location.state});
        }
        // console.log(pathname, pathArr) 
        if(pathArr.length === 2) {
            this.props.history.push("/non-competitive-initiation/" + to, 
            {...this.props.history.location.state});
        } else {
            this.props.history.push(to, {...this.props.history.location.state});
        }
        
    };

    handleInitStepper = () => {
        const { step } = this.props.match.params;
        if(step) {
            let found = null;
            return stepperData.map((d, idx) => {
                if(d.key === step){
                    found = idx;
                    return ({ ...d, active: true });
                }
                if(!found) {
                    return ({ ...d, active: false, isComplete: true })
                }
                
                if(found && found < idx) {
                    return ({...d, active: false})
                }
            }) 
        }
        return stepperData;
    }

    componentDidUpdate(prevProps, prevState) {
        const { step: currStep } = this.props.match.params;
        if(prevProps !== undefined) {
            const { step: prevStep } = prevProps.match.params;
            const { activeStepperIndex } = prevState;
            let currStepIdx = Object.keys(this.stepMap).indexOf(currStep); // 0, 1, 2...
            if(currStep !== prevStep && activeStepperIndex !== currStepIdx) {  //  set stepper data
                this.generateStepper(currStep, prevStep);
                this.setState({
                    comp: this.stepMap[currStep],
                });
            }
        }
        
    }

    stepMap = {
        // "candidate-selection": <CandidateSelection
        //     navigateTo={this.navigateTo}
        //     step="candidate-selection"
        //     history={this.props.history}
        //     location={this.props.location} />,
        "pre-initiation": <PreInitiation 
            navigateTo={this.navigateTo}
            step="pre-initiation"
            history={this.props.history}
            location={this.props.location}
        />,
        "package-selection": (
            <PackageSelection
                navigateTo={this.navigateTo}
                history={this.props.history}
                location={this.props.location}
            />
        ),
        "emp-job-details": <JobDetails 
            navigateTo={this.navigateTo} 
            history={this.props.history}
            location={this.props.location}/>,
        "additional-move-details": <AdditionalDetails 
            navigateTo={this.navigateTo}  
            history={this.props.history}
            location={this.props.location}/>,
        "finance-admin": <FinanceAdmin
            navigateTo={this.navigateTo}
            history={this.props.history}
            location={this.props.location} />,
        "review-submit": (
            <ReviewAndSubmit
                navigateTo={this.navigateTo}
                history={this.props.history}
                location={this.props.location}
            />
        ),
    } as { [key: string]: JSX.Element };

    generateStepper = (state: string, prevState: string = "pre-initiation"): void => {
        const newStepper: Array<MoveDetailsStepper> = this.state.stepper.map(
            (item: MoveDetailsStepper): MoveDetailsStepper => {
                if (prevState === item.key) {
                    return {
                        ...item,
                        isComplete: true,
                        active: false,
                    };
                } else if (item.key === state) {
                    return {
                        ...item,
                        active: true,
                    };
                } else {
                    return {
                        ...item,
                        active: false,
                    };
                }
            },
        );
        this.setState({
            stepper: newStepper,
            activeStepperIndex: newStepper.findIndex(
                (el: MoveDetailsStepper): boolean => el.active === true,
            ),
        });
    };
    render() {
        const { step } = this.props.match.params;
        // if(state === undefined || !state.moveType)
        //     this.props.history.replace("/move-dashboard");
        // console.log("step: ", Object.keys(this.stepMap)
        // .indexOf(this.props.match.params.step), this.props.match.params.step)
        return (
            <>
                {step !== "success" && (
                    <div className="MoveDetailNCContainer">
                        <aside className="StepperContainer">
                            <Stepper
                                steps={this.state.stepper}
                                activeStepIndex={this.state.activeStepperIndex}
                            />
                        </aside>
                        <hr className="vertical-line" />
                        <section
                            className={classNames({
                                ComponentsContainer: true,
                                // JobDetails: this.state.activeStepperIndex === 3,
                                // Finance: this.state.activeStepperIndex === 4,
                            })}
                        >
                            {this.state.comp}
                        </section>
                    </div>
                )}
                {step === "success" && (
                    <div className="MoveDetailContainer move-details-success">
                        <div className="success-container">
                            <SuccessCompletion {...this.props} />
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps)(NonCompetitveDetails));
