import React from 'react';

import { Button } from 'components/common';

import './FFButton.scss';

const ffbutton = (props) => (
    <div className="FeedbackButton">
        <div className="FeedbackButton-inside">
            <div className="FeedbackButton-button">
                <Button simple title="Feedback" onClick={props.clicked}></Button>
            </div>
        </div>
    </div>
);

export default ffbutton;
