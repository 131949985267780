//@flow

import * as React from 'react';
import cs from 'classnames';
import Button from '@material-ui/core/Button';
import type {StyleRulesCallback} from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

type NavigationProps = {
    disabled?: boolean,
    className?: string,
    action?: String,
    classes: Object,
    bgColor?: String,
    direction?: String
};

const styles: StyleRulesCallback = theme => ({
    chevron: {
        minWidth: 51,
        minHeight: 51,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        padding: 0,
        margin: 0,
        '&:hover': {
            backgroundColor: '#A50016'
        },
        '& span': {
            margin: '0 !important',
            // due to old css rules need this line. Delete when span inside block not be with width: 200px
            width: 'auto !important'
        }
    },
    white: {
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            '& $chevronLineRed': { stroke: "#fff"}
        }
    },
    disabled: {
        backgroundColor: '#D8D8D8',
        '&:hover': {
            backgroundColor: '#D8D8D8'
        }
    },
    left: {
        transform: 'rotate(180deg)'
    },
    chevronLine: {
        stroke: 'white'
    },
    chevronLineRed: {
        stroke:  theme.palette.primary.main,
        '&:hover': {
            stroke: '#fff'
        }
    },
});

const CarouselNavigation = (props: NavigationProps) => {
    const {classes, bgColor, direction, disabled, className} = props;
    const rootClass = cs(
        classes[bgColor],
        {
            [classes.disabled]: disabled
        },
        classes.chevron,
        className
    );
    return(
        <Button className={rootClass} disabled={disabled} >
            <SvgIcon className={`${classes[direction]}`} width="51" height="51" viewBox="16 15 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg">>
                <g transform="translate(.445 .191)" fill="none" fillRule="evenodd">
                    <path className={cs('chevronLineRed',{
                        [classes.chevronLineRed]: bgColor === 'white',
                        [classes.chevronLine]: disabled,
                        [classes.chevronLine]: bgColor !== 'white',
                    })} d="M23.5 32.5l7-7.5-7-7.5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                </g>
            </SvgIcon>
        </Button>
    )
}

export default withStyles(styles)(CarouselNavigation);
