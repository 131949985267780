import React, { useState, useEffect } from "react";
import { RouterProps } from "types";
import {Icon, Button, SpinnerWrapper} from "components/common";
import hint from 'components/common/Images/hint.png';
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { moveInitiationService } from "services";
import { getEmpJobDetails } from "selectors";

import './index.scss'

  const mapStateToProps = state => ({
    empJobDetails: getEmpJobDetails(state),
  });

  const mapDispatchToProps = {
    fetchEmpJobDetails: moveInitiationService.actions.fetchEmpJobDetails
  };

  const EmployeeJobInfo = (props: RouterProps) => {

    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [continueButtondisabled, setContinueButtondisabled] = useState(false);
    const [employeeWWID, setEmployeeWWID] = React.useState(null);
    const [jobID, setJobID] = React.useState(props.location.state.jobID !== undefined ? props.location.state.jobID : "");
    const [validationMessageEmpID, setValidationMessageEmpID] = React.useState("");
    const [validationMessageReqID, setValidationMessageReqID] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isReqIdCorrect, setIsReqIdCorrect] = useState(false);

    const validWWIDRegEx = /^\d*$/
    const validReqRegEx = /^[0-9a-z]+$/i
    const invalidReqIdMsg = "Please enter a valid job requisition ID.";

    const changeHandler = () => {
      setIsReqIdCorrect(validReqRegEx.test(jobID));
      if (validReqRegEx.test(jobID)) {
        setIsLoading(true);
        setMessages("");
        setIsError(false);
        setContinueButtondisabled(true);
        dispatch(moveInitiationService.actions.fetchEmpJobDetails(null, jobID))
        .then(names => {
            setIsError(false);
            setIsLoading(false);
            setValidationMessageEmpID("");
            if(names.message == "Success") dispatchResolve(names.result);
            else dispatchError(names.message);
        })
        .catch(err => console.log(err));
      } else {
        var reqIdStatus = validReqRegEx.test(jobID);
        if (!reqIdStatus) {
          setMessages(invalidReqIdMsg);
        }
        setIsError(true);
      }
    };

    const dispatchError = (msg) => {
      var msg = msg.split(" | ")
      let wwwIdErrorMsg = "";
      let requisitionIdErrorMsg = "";
      if(Array.isArray(msg) != null) {
        if(msg[0] !== null) {
          if(msg[0].includes('WWID')) {
            wwwIdErrorMsg = msg[0];
            requisitionIdErrorMsg = msg[1];
          } else {
              requisitionIdErrorMsg = msg[0];
          }
        }
      }
      Array.isArray(msg) != null ? 
      setMessages(requisitionIdErrorMsg)
        : setValidationMessageEmpID(msg);
        setIsError(true)
      setIsLoading(false);
      setContinueButtondisabled(false);
    }

    const dispatchResolve = (result) => {
      setMessages("")
      setIsError(false)
      setIsLoading(false);
      setContinueButtondisabled(false);
      props.history.push("/initiate-move/employee-job-details-review",{...props.location.state.state,
        employeeWWID,
        jobID,
        "posID": null,
        "eligibleTitle": (props.location.state.eligibleTitle != null && typeof props.location.state.eligibleTitle !== 'undefined') ? props.location.state.eligibleTitle : null,
        "employee": (props.location.state.employee != null && typeof props.location.state.employee !== 'undefined') ? props.location.state.employee : null,
        "category": (props.location.state.category != null && typeof props.location.state.category !== 'undefined') ? props.location.state.category : null,
        "category2": (props.location.state.category2 != null && typeof props.location.state.category2 !== 'undefined') ? props.location.state.category2 : null,
        "relocationType": (props.location.state.relocationType !== undefined) ? props.location.state.relocationType : null,
        "moveType": (props.location.state.moveType !== undefined) ? props.location.state.moveType : null,
        "policySelection": (props.location.state.policySelection !== undefined) ? props.location.state.policySelection : null,
        "strategicIndent": (props.location.state.strategicIndent  != null && typeof props.location.state.strategicIndent  !== 'undefined') ? props.location.state.strategicIndent : null,
        "initiationId": (props.location.state.initiationId  != null && typeof props.location.state.initiationId  !== 'undefined') ? props.location.state.initiationId : null,
        "initiationCountryCode": (props.location.state.initiationCountryCode  != null && typeof props.location.state.initiationCountryCode  !== 'undefined') ? props.location.state.initiationCountryCode : null,
        "jobDetailsBackState": "employee-job-details-review",
        employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
      });
    }

    const setMessages = (reqId) => {
      setValidationMessageReqID(reqId)
    }

    const validateIDInputs = (input, type) => {      
     if (type == "ReqID") {
        setIsReqIdCorrect(input.match(validReqRegEx))
      }
    }

    const disableContinueBtnHandler = (continueButtondisabled: boolean): void =>
    setContinueButtondisabled(continueButtondisabled);

    

    const handleInputChange = (propName) => ({ type: string, target, ...eventRest }, ...argsRest) => {
        let value = target ? target.value : Object.values(eventRest).join("");
        if (propName === "toggleJ-job-requisition-section") {
          open ? setOpen(false): setOpen(true)
        }
        if (propName === "jobID") {
          setJobID(value)
        }
        if (propName === "cancel") {
          const { moveType, policySelection } = props.location.state;
          setJobID("")
          let backLocation = "/initiate-move";
          props.history.push(backLocation,
            {
              ...props.location.state,
              moveType: moveType,
              policySelection: policySelection
            }
          );
        }
        if (propName === "continue") {
          changeHandler();
        }
    };
    useEffect(() => {
        if(props.location.state === undefined) {
          props.history.push("/move-dashboard", {
                  "moveType": "",
                  "policySelection": ""
          });
        } else {
          if (jobID !== "") {
            disableContinueBtnHandler(false);
          } else {
            disableContinueBtnHandler(true);
          } 
        }
    },[jobID]);

    return (
        <div className="employee-job-details-container">
            <section className="header-section">
                {((validationMessageReqID) && isError) == false ? <h1 className="section-title">
                    Next, let's <span style={{color: "#CC0099"}}>import</span> your job information.
                </h1> : <h1 className="section-title">
                  <span className="error-heading">Oh no!</span><br /><span className="error-subheading">There seems to be a problem. Please try again.</span>
                </h1>}
            </section>
            <section className="input-section">
              <SpinnerWrapper show={isLoading}>
                <div className="inputs">
                  <div className={`Input ${validationMessageReqID ? 'on-error' : ''}`}>
                    <input
                        type="text"
                        required
                        value={jobID}
                        onChange={handleInputChange("jobID")}
                        className={validationMessageReqID ? "text-input-error" : ""}
                    />
                    <span className={`placeholders ${(validationMessageReqID && isError) ? 'has-reqid-error' : ''}`}>Job Requisition ID<span className="required-symbol"> *</span></span>
                    {validationMessageReqID ? <span className="reqid-error-msg">{validationMessageReqID}</span> : null}
                    
                  </div>

                  <p className={`${validationMessageReqID ? 'shift-on-error' : ''}`}>Your Requisition ID will be 10 numbers followed by a “W”</p>
                </div>
                {/* {((validationMessageEmpID || validationMessageReqID) && isError) == false ? <div className={"immigration_timeline_modal"}>
                  <section className="icon-section">
                    <img src={hint} alt=""/>
                  </section>
                  <section className="text-section">
                      <p className="title">Need Help?</p>
                      <p>If you have a question regarding this step in the process, please contact us.</p>
                      <div className="email-section">
                        <p><a href="mailto:TalentMobil@its.jnj.com?Subject=[Initiation] Talent Mobility Support" >Email us now  ></a></p>
                      </div>
                  </section>
              </div> : null} */}
              </SpinnerWrapper>
            </section>

            {/* <div className="line" /> */}

            {/* <section className="information-section">
              <div className="title-section">
                <p className="title">Where can I find the Job Requisition ID?</p>
                {<Icon name={open ? 'minus' : 'plus'} onClick={handleInputChange("toggleJ-job-requisition-section")} style={{cursor: "pointer"}}/> }
              </div>
              <div className="body-section" style={open ? {display: "block"} : {display: "none"}}>
                <p className="text">A new or open position must be available to transfer the employee. You will need to create a Job Requisition ID (Workday) for the employee who is moving.</p>
                <p className="sub-heading">Where to find the approved ID in Workday</p>
                <p className="small-text">To retrieve the approved requisition ID from Workday:</p>
                <ul>
                  <li className="small-text">1. Use Google Chrome or Internet Explorer 11 to open Workday.</li>
                  <li className="small-text">2. Click My Team Management to view approved positions.</li>
                  <li className="small-text">3. The Requisition ID will appear when the status is Approved.</li>
                  <li className="small-text">4. The Requisition ID will be 10 digits long followed by a “W”.</li>
                </ul>
              </div>
            </section> */}

            <section className="button-section">
                {/* <div className="line" />
                <br /> */}
                <section className="buttons">
                    <Button
                      onClick={handleInputChange("cancel")}
                      title="Cancel"
                      className="submit-button"
                      style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", width:"80px"}}
                      labelStyle={{color:"#CA001B"}}
                      submit={true}

                    />
                    <Button
                      disabled={continueButtondisabled}
                      onClick={handleInputChange("continue")}
                      title="Continue"
                      className="submit-button"
                      style={{width:"80px"}}
                      submit={true}
                    />
                </section>
            </section>
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeJobInfo);
