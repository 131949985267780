// @flow
import * as React from "react";
import { withRouter } from "react-router";
import Button from "components/common/Button";
import Grid from "@material-ui/core/Grid";
import Icon from "components/common/Icons";
import "./style.scss";

export type NoMatchProps = {|
    className?: string,
|};

function NoMatch(props: NoMatchProps) {
    const { className, history } = props;
    return (
        // @ToDo replace to JnJGridComponent
        <Grid
            style={{ padding: 30, minHeight: "86vh" }}
            className={`flex-grow-1 defaultBg ${className || ""}`}
            container
            direction="column"
            justify="center"
            alignItems="center"
        >
            <Grid
                container
                // spacing={32}
                className="nomatch-container"
            >
                <Grid item xs={false} sm={1} className="hidden-xs" />
                <Grid item xs={12} sm={2}>
                    <Icon className="nomatch-icon" name="screen" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <h1 className="nomatch-title">Whoops.</h1>
                    <p className="nomatch-text">
                        We can’t find the page you are trying to view. It may have been moved or no longer exists. Let’s
                        get you somewhere more useful…
                    </p>
                    <Button
                        submit
                        title="Go to Homepage"
                        onClick={() => history.replace("/")}
                        className="goto-homepage-button"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withRouter(NoMatch);
