import React from 'react';
import {Link, Icon, Asset} from 'components/common';

export const ExplFirstStepData = [
    {
        title: 'Establish Your Talent Acquisition Goals & Business Objectives',
        content: (
            <div>
                <p className="expl_hr_acc_item_part_1">When opening your new job requisition, you should consider whether or not you will support a candidate’s relocation from another U.S. city to your office. </p>
                <p className="expl_hr_acc_item_part_2">Aligning to Johnson & Johnson’s business objectives is very important at this stage. The right people and talent help Johnson & Johnson deliver timely and efficient results and drive innovation and diverse thinking across the organization.    When evaluating investments in talent moves, J&J looks at strategic business and talent objectives such as:</p>
                <ul className="list">
                    <li>Filling interim skill/competency gaps in local markets</li>
                    <li>Bringing specialized expertise not available locally to accelerate key business objectives</li>
                    <li>Developing knowledge and/or expertise of early career talent with the expectation of building local capabilities longer term</li>
                    <li>By nomination Leadership Development Programs (i.e., functional programs in Operations, Finance, HR, IT, and others)</li>
                </ul>
                <p className="expl_hr_acc_item_part_2">Visit the Functional J&J Portal of your area of interest and/or contact your HR Business Partner for further direction.</p>
                <h4 className="flexible horizontal expl_hr_acc_item_buttons">
                    <Link text="Careers.JnJ.com Operations" externalLink="http://www.careers.jnj.com/operations" />
                    <Link text="Careers.JnJ.com Finance" externalLink="http://www.careers.jnj.com/finance" />
                    <Link text="OneHR" externalLink="https://jnj.sharepoint.com/sites/onehr/Pages/default.aspx" />
                </h4>
            </div>
        )
    },
    {
        title: 'Select a U.S. Domestic Relocation Package',
        content: (
            <div className="flexible">
                <div className="flexible vertical pad-right">
                    <p className="expl_hr_acc_item_part_1">After you understand your strategic intent for supporting a domestic talent move, you will need to identify the proper relocation package. </p>
                    <p className="expl_hr_acc_item_part_2">Our relocation packages are organized by pay grade, homeowner status, and other attributes that can influence the overall complexity and level of support required for the move.  Our interactive package selection tool will ask you a series of questions and recommend relocation packages that meet your business needs.</p>
                    <h4 className="flexible horizontal expl_hr_acc_item_buttons">
                        <Link text="U.S. Domestic Relocation Policy Decision Tree"
                              onClick={()=>new Asset({assetType:'policy_decision_tree', assetId:'domestic'}).execute()} />
                        <Link text="Package Summaries" 
                              onClick={()=>window.location.replace('/resources/managerDom/DomesticPolicies')} />
                    </h4>
                </div>
                <div className="flexible vertical pad-left">
                    <p className="expl_hr_acc_item_part_3">Before you begin, be sure you have:</p>
                    <ul className="list">
                        <li>Strategic Intent</li>
                        <li>Target Pay Grade for the Position</li>
                        <li>Homeowner  / Renter Status</li>
                    </ul>
                    <div className="estimate">
                        <hr/>
                        <Icon name="timeRed"/><span className="time-label">Estimated Time:</span><br/>
                        <span className='time-desc'>Generally takes about 15 minutes of your time</span>
                    </div>
                </div>
            </div>
        )
    },
    {
        title: 'Determine Package, Obtain Cost Estimate & Secure Funding',
        content: (
            <div className="flexible">
                <div className="flexible vertical pad-right">
                    <p className="expl_hr_acc_item_part_1">Once you know which relocation packages correspond to the job parameters, you can estimate the cost and  go through the necessary channels to obtain funding.</p>
                    <p className="expl_hr_acc_item_part_2">Relocation package and funding details should be shared with the recruiter so that they can tailor their candidate conversations appropriately.</p>
                    <h4 className="flexible horizontal expl_hr_acc_item_buttons">
                        <Link text="Average Relocation Cost Estimates"        
                              onClick={()=>new Asset({assetType:'domestic-relocation-planning', assetId:'domestic-relo-cost-averages'}).execute()} />
                    </h4>
                </div>
                <div className="flexible vertical pad-left">
                    <p className="expl_hr_acc_item_part_3">Before you begin, be sure you have:</p>
                    <ul className="list">
                        <li>Selected Relocation Package</li>
                        <li>Pay Grade</li>
                        <li>Salary Specifications</li>
                    </ul>
                    <div className="estimate">
                        <hr/>
                        <Icon name="timeRed"/><span className="time-label">Estimated Time:</span><br/>
                        <span className='time-desc'>Generally takes about 30 minutes of your time<br/>Funding approval times vary</span>
                    </div>
                </div>
            </div>
        )
    }
];