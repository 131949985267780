import React from 'react';

export default (
    <div className="article-info">
        <div className="article-info-item">
            <h2 className="title">Do Your Homework Before You Leave</h2>
            <p className="description">
                Learn as much about your new host country as possible. This ranges from cuisine, climate and modes of transportation to politics, history, culture and language.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Don’t Forget to Study the Business Culture, Too</h2>
            <p className="description">
                Even though you’re still at the same company, business practices differ around the world. But you can start the acclimation process before you move into your new office. We provide employees with cultural training on&nbsp;
                <a href="https://learning.aperianglobal.com" rel="noopener noreferrer" target="_blank" className="link">GlobeSmart</a>, an on-demand website that provides information about how business practices, customs and cultural norms differ around the world.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Get Busy!</h2>
            <div>
                <p className="description">
                    It's easy to feel overwhelmed by a strange new city—so get out and make it less strange! Try to discover a new favorite café or bookstore in your host city. Build a routine that will start to make your new house feel like your home. Join a volunteer group with your new coworkers, or take up a new hobby that wasn’t available in your old city.
                </p>
                <p>
                    First time living by the ocean? You’re never too old to learn how to surf or explore marine life in nearby tide pools. Never lived near the mountains? Hiking's a great way to exercise, relieve stress and even make some new friends. No matter where you are, there's always something new to see and do, so get busy doing it!
                </p>
            </div>
        </div>
        <div className="article-info-item">
            <h2 className="title">Keep in Touch with Friends...</h2>
            <p className="description">
                It can be challenging to keep in touch with friends and family when you’re busy building a new community or living in a different time zone. So plan ahead, and make sure your loved ones back home download texting and calling apps that work internationally. And since it can be hard to fit everyone in, schedule specific times each week to FaceTime with important people in your life back home.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">...and Make New Ones!</h2>
            <p className="description">
                Don't forget that this is an opportunity to make new friends, too! When you relocate, you’ll be surrounded with positive, dynamic coworkers who can help you thrive in your new environment. And don’t be shy: Ask them to help you learn the lay of the land, introduce you to new people and show you their favorite local restaurants.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Give Yourself Time</h2>
            <p className="description">
                As excited as you may be for this new opportunity, the honeymoon period of your move might be followed by a period of irritation and homesickness once the novelty of living in a place wears off. Be kind to yourself during this adjustment period—the highs and lows of what you’re feeling are completely normal. Eventually you’ll become more familiar with local cultural nuances, and cues that once felt foreign will be easier to read. At the end of the day, you’ll inevitably like certain aspects of this new culture more than others, but it will be much easier to cope over time.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Travel!</h2>
            <div>
                <p className="description">
                    Exploring your new home doesn’t stop at the boundaries of your neighborhood. Take full advantage of living in a new place by traveling to nearby towns or countries. Drive along the California coastline to experience the seaside cities between Los Angeles and San Francisco. Or, if cheap flight options are available, why not use your time off to explore different countries within Asia or Europe? If you purchase tickets in advance, these flights are generally inexpensive. After all, this is a once-in-a-lifetime opportunity—so make the most of it!
                </p>
                <p>
                    Moving to a new city or country can be daunting, but it's also an opportunity to experience new things, see new sights and make new friends! Whether you're there for one year or for a longer term, don't let a new location intimidate you. Get out, get busy and get ready to grow both personally and professionally.
                </p>
            </div>
        </div>
    </div>
)
