import React, { Component } from "react";
import { MidSectionComponent } from "components/common";
import Mail from "components/common/Images/mail.svg";

export default class IntPrepPlanStep extends Component {
    render() {
        return (
            <div>
                <MidSectionComponent
                    title="Immigration Next Steps"
                    className="IntPrepPlanStep"
                    col1={
                        <div className="flexible vertical pad-right">
                            <p className="midsect-sub-text">
                                In order to finalize your start date in your new
                                host location, immigration will need to be
                                finalized.
                            </p>
                            <p>
                                Immigration compliance is a top priority for
                                J&J, therefore Fragomen will be responsible for
                                obtaining any of the necessary permits and visas
                                for you and any family members accompanying you
                                on your assignment. Fragomen will reach out to
                                you by email to validate your personal details
                                so that they can determine your immigration
                                requirements and earliest feasible assignment
                                start date. Your start date in the host country
                                will then be aligned with your Manager/HR when
                                Fragomen has confirmed that you are legally
                                compliant to begin working in your new host
                                location.
                            </p>
                            <p>
                                Johnson & Johnson will cover all costs
                                associated with obtaining appropriate
                                work/entry/residency authorization for the
                                global assignee and any entry/residency
                                authorization for accompanying dependents, if
                                eligible.
                            </p>
                        </div>
                    }
                    col2={
                        <div className="flexible vertical pad-left">
                            <a href="mailto:immigrationcoe@its.jnj.com">
                                <p className="midsect-sub-header">
                                    For more information, click here to contact the Immigration COE
                                    &nbsp;<img src={Mail} alt="" className="mail-icon" />
                                </p>
                            </a>
                            <p>
                                Once services have been authorized, an
                                Immigration Services caseworker will contact you
                                to request the required documentation and to
                                clarify the next steps.
                            </p>
                        </div>
                    }
                />
            </div>
        );
    }
}
