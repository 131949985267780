import React from 'react';
import {
    NavLink,
} from 'react-router-dom';

import { Icon } from 'components/common';



import moment from "moment/moment";
import {SHORT_DATE_FORMAT} from '../../consts';

export const reportStatus =  {
    all: [
       { title: 'Report Name' },
       { title: 'Amount/ Submitted date' },
       { title: 'Approved Amount / Date' },
       { title: 'Status' },
       { title: '' }
   ],
    draft: [
        { title: 'Report Name' },
        { title: 'Amount' },
        { title: 'Status' },
        { title: '' }
    ],
    submitted : [
        { title: 'Report Name' },
        { title: 'Amount/ Submitted date' },
        { title: 'Status' },
        { title: '' }
    ],
    approved: [
        { title: 'Report Name' },
        { title: 'Amount/ Submitted date' },
        { title: 'Approved Amount / Date' },
        { title: 'Status' },
        { title: '' }
    ],
};

const DownloadButton = ({report, downloadReport}) => {
    if(report.status !== 'Draft'){
        return <Icon
            name="download"
            onClick={()=> downloadReport(report)}
        />
    } return null
};

const formatDate = (reportDate, formatString = SHORT_DATE_FORMAT) => {
    return (reportDate !== null && reportDate !== '0001-01-01T00:00:00Z' ?  moment(reportDate).utcOffset(0).format(formatString) : '');
};

const alertPending = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return window.alert('The report hasn\'t finished processing. We will update the list once it is finished');
};

const GenerateLink = ({report}) => {
    if (report.status !== 'Pending Submission') {
        return (
            <NavLink to={`expense-report/edit/${report.reportId}`}>
                {report.reportName}
            </NavLink>
        );
    }

    return <a onClick={alertPending} href={'/'}>{report.reportName}</a>
};

export const gettableData = (report, key, deleteReport, downloadReport) => {
    switch (key) {
       case 'all':
          return [
               <div className="expense-report-name singleLine truncate">
                   <GenerateLink report={report} />
               </div>,
               <div className="submitted_amount flexible vertical">
                   { report.status === 'Draft' || report.status === 'Ready to Submit'
                       ? (report.draftAmount + ' ' + (report.reimbursedCurrency || ''))
                       : (report.amountSubmitted + ' ' + (report.reimbursedCurrency || ''))}
                   <span className="date">{
                       (report.status === 'Draft' || report.status === 'Ready to Submit') ? '' : formatDate(report.dateSubmitted)
                   }</span>
               </div>,
               <div className="approved_amount flexible vertical">
                   { (report.amountApproved || '0') + ' ' + (report.reimbursedCurrency || '') }
                   <span className="date">{ (report.status === 'Draft' || report.status === 'Ready to Submit')? '' : formatDate(report.dateApproved) }</span>
               </div>,

               <div className="status">
                   {report.status}
               </div>,
              <div className="flexible aCenter jBetween">
                  {
                      (report.status === 'Draft' || report.status === 'Ready to Submit') && <Icon
                          name="trash"
                          onClick={()=> deleteReport(report.reportId)}
                      />
                  }

                  <DownloadButton
                      downloadReport={downloadReport}
                      report={report}
                  />
              </div>
           ];
       case 'draft':
          return [
              <div className="expense-report-name singleLine truncate">
                  <GenerateLink report={report} />
              </div>,
              <div className="submitted_amount flexible vertical">
                  { report.status === 'Draft' || report.status === 'Ready to Submit'
                      ? (report.draftAmount + ' ' + (report.reimbursedCurrency || ''))
                      : (report.amountSubmitted + ' ' + (report.reimbursedCurrency || ''))}
              </div>,
              <div className="status">
                  {report.status}
              </div>,
              <div className="flexible aCenter jEnd">
                  {
                      (report.status === 'Draft' || report.status === 'Ready to Submit') && <Icon
                          name="trash"
                          onClick={()=> deleteReport(report.reportId)}
                      />
                  }
                  <DownloadButton
                      downloadReport={downloadReport}
                      report={report}
                  />
              </div>
          ];
       case 'submitted' :
          return [
              <div className="expense-report-name singleLine truncate">
                  <GenerateLink report={report} />
              </div>,
              <div className="submitted_amount flexible vertical">
                  { (report.amountSubmitted || '0') + ' ' + (report.reimbursedCurrency || '') }
                  <span className="date">{ formatDate(report.dateSubmitted)}</span>
              </div>,
              <div className="status">
                  {report.status}
              </div>,
              <div className="flexible aCenter jEnd">
                  <DownloadButton
                      downloadReport={downloadReport}
                      report={report}
                  />
              </div>
          ];
        case 'approved' :
            return [
                <div className="expense-report-name singleLine truncate">
                    <GenerateLink report={report} />
                </div>,
                <div className="submitted_amount flexible vertical">
                    { (report.amountSubmitted || '0') + ' ' + (report.reimbursedCurrency || '') }
                    <span className="date">{ formatDate(report.dateSubmitted)}</span>
                </div>,
                <div className="approved_amount flexible vertical">
                    {(report.amountApproved || '0') + ' ' + (report.reimbursedCurrency || '')}
                    <span className="date">{formatDate(report.dateApproved)}</span>
                </div>,
                <div className="status">
                    {report.status}
                </div>,
                <div className="flexible aCenter jEnd">
                    <DownloadButton
                        downloadReport={downloadReport}
                        report={report}
                    />
                </div>
            ];
       default:
           return [
               <div className="expense-report-name singleLine truncate">
                   <GenerateLink report={report} />
               </div>,
               <div className="submitted_amount flexible vertical">
                   { report.status === 'Draft' || report.status === 'Ready to Submit'
                       ? (report.draftAmount + ' ' + (report.reimbursedCurrency || ''))
                       : (report.amountSubmitted + ' ' + (report.reimbursedCurrency || ''))}
                   <span className="date">{formatDate(report.dateSubmitted)}</span>
               </div>,
               <div className="approved_amount flexible vertical">
                   { (report.amountApproved || '0') + ' ' + (report.reimbursedCurrency || '') }
                   <span className="date">{ formatDate(report.dateApproved) }</span>
               </div>,
               <div className="status">
                   {report.status}
               </div>,
               <div className="flexible aCenter jBetween">
                   <DownloadButton
                       downloadReport={downloadReport}
                       report={report}
                   />
               </div>
           ];
   }

};

export const getTableDataForMobile = (report, key, deleteReport, downloadReport) => {

            const getamount = () => {
              if(key === 'submitted' || key === 'draft')  {
                return (
                    <span>
                        { (report.amountSubmitted || '0') + ' ' + (report.reimbursedCurrency || '') }
                    </span>
                )
              } else {
                  return (
                        <span>
                            { (report.amountSubmitted || '0') + ' ' + (report.reimbursedCurrency || '') }
                            { (report.amountApproved || '0') + ' ' + (report.reimbursedCurrency || '') }
                        </span>
                  )
              }
            };

            return (
                <div className="expense-report-mobile  flexible aCenter jBetween">
                    <div className="info flexible vertical">
                        <div className="key">
                            {key}
                        </div>
                        <NavLink to={`expense-report/edit/${report.reportId}`} className="name singleLine truncate" title={report.reportName}>
                            {report.reportName}
                        </NavLink>
                    </div>
                    <div className="price flexible">
                        <span className="current singleLine flexible grow aCenter">{getamount()}</span>
                        {
                            key === 'draft' || key === 'all' ?
                                <Icon
                                    name="trash"
                                    onClick={()=> deleteReport(report.reportId)}
                                />:
                                <DownloadButton
                                    downloadReport={downloadReport}
                                    report={report}
                                />
                        }
                    </div>
                </div>
            );
};
