import React, { PureComponent } from "react";
import Happy from "components/common/Images/feedback_happy.gif";
import { ColumnComponent } from "components/common";
import { Icon } from "components/common";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import "./styles.scss";

const howItWorkData = [
    {
        title: "Step 1",
        icon: "screen",
        desc:
            "Your service request and associated details are sent to our Talent Mobility partners for processing. ",
        estimateTime: "Generally takes about 15-30 minutes of your time",
    },
    {
        title: "Step 2",
        icon: "personWorld",
        desc:
            "The employee receives an email with a link to a form enabling them to provide additional family details if needed.",
        estimateTime:
            "Generally takes less than 15 minutes of the employee’s time",
    },
    {
        title: "Step 3",
        icon: "clipboard",
        desc:
            "SIRVA completes a due diligence analysis based on the specific country combination and assignment type to identify any compliance challenges.",
        estimateTime: "SIRVA needs 1-2 business days",
    },
    {
        title: "Step 4",
        icon: "approval",
        desc:
            "SIRVA completes the cost estimate and sends to approver 1 for review and approval.  Once approved by approver 1 goes to approver 2 for approval. ",
        estimateTime: "SIRVA needs about 2 business days to complete cost estimate",
    },
    {
        title: "Step 5",
        icon: "scroll",
        desc:
            "SIRVA creates the Global Assignment Letter (GAL) detailing the relocation package terms and & allowances. ",
        estimateTime: "Generally takes about an hour",
    },
    {
        title: "Step 6",
        icon: "world",
        desc:
            "SIRVA initiates immigration services with the immigration services provider.",
        estimateTime: "Immigration application and approval timelines vary significantly by country from 3 weeks to 5 or more months",
    },
    {
        title: "Step 7",
        icon: "sign",
        desc:
            "The GAL is sent to the employee for review and approval.  Employee signs the GAL and returns it to SIRVA",
        estimateTime: "Generally takes about 1 business day ",
    },
    {
        title: "Step 8",
        icon: "moving",
        desc:
            "Relocation Services are initiated so employee can begin move preparations.",
        estimateTime: "SIRVA will initiate authorizations within 1 business day of receiving all approvals",
    },
];

class InitiateMoveSuccess extends PureComponent<
    RouteComponentProps & {
        navigateTo: (to: string, from: string) => void;
    }
> {
    submitHandler = () => {
        this.props.history.push("/move-dashboard");
    }

    render() {
        return (
            <div className="InitiateMoveDetailContainer">
                <div className="success-container">     
                    <div className="success-completion-page">
                <div className="top">
                    <h1>
                        <span>Success!</span> Your request has been submitted.<img src={Happy} alt="" />
                    </h1>
                </div>
                <div className="middle">
                    <div className="left-middle">
                        <p>
                            J&J partners with relocation services firm, SIRVA. A
                            SIRVA consultant will be assigned to your move and will
                            manage your request from this point on with support from
                            Talent Mobility and yourself.
                        </p>
                    </div>
                    <div className="right-middle">
                        <Icon name="timeBlue" />
                        <div>
                            <p className="title">How long will this take?</p>
                            <p className="content">
                                Generally takes about 1 week and involves J&J
                                management, SIRVA, and the employee
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="slider">
                    <ColumnComponent
                        title="What happens now?"
                        className="how-the-process-works"
                        data={howItWorkData}
                    />
                </div> */}
                {/* <h2>Important things to know about immigration</h2> */}
                {/* <div className="middle">
                    <div className="left-middle">
                        <p style={{ fontSize: "16px" }}>
                            As the manager, you will need to review and provide
                            various corporate documents to our immigration partner
                            as part of the immigration process. Prioritized
                            submission of documentation is critically important to
                            visa and work permit application agility and, in turn,
                            to the effective start date.
                        </p>
                    </div>
                    <div className="right-middle">
                        <Icon name="timeBlue" />
                        <div>
                            <p className="title">How long will this take?</p>
                            <p className="content">
                                Immigration can take anywhere from 3 weeks to 5
                                months depending on the country requirements and
                                employee specifics
                            </p>
                        </div>
                    </div>
                </div> */}
                <hr className="line" />

                <button className="submitButton" onClick={this.submitHandler}>
                    Return to Home
                </button>
            </div>
                </div>
            </div>
        );
    }
};

export default withRouter(
    connect(
        null,
        null,
    )(InitiateMoveSuccess),
);