import React from "react";
import { MoveStepLayout } from "components/moveInitiationLayouts";
import { withRouter, RouteComponentProps } from "react-router";
import { RouterProps } from "types";
import EmployeeJobInfo from "./EmployeeJobInfo";

 const EmployeeJobDetails = (props: RouteComponentProps<any> & RouterProps) => {
  
  if(props.location.state === undefined) {
      props.history.push("/move-dashboard");
      return null;
  } else {
    if(props.location.state !== undefined ) {
      return (
             <div className="RecruitedSelectorContainer">
                <MoveStepLayout selected={2} />
                <EmployeeJobInfo history={props.history} location={props.location}/>
             </div>
         );
    } else {
      props.history.push("/move-dashboard");
      return null;
    }  
  }
}
export default withRouter(EmployeeJobDetails);
