
import React, { Component } from 'react'
import {TextComponent} from 'components/sections';

const orderedData2 = [
  'An existing U.S. based employee, selects and applies for a job at another U.S. office location.',
  'The employee completes the job interviewing process and is offered the position, along with a relocation package previously approved by line manager and finance.',
  'If relocation benefits are part of the offer package and the offer is accepted, relocation services will be initiated. Then move preparation  and planning will begin.'
];

export default class DomGetStartedStep extends Component {

  render() {
    return (
      <div>
        <TextComponent
          title="How the Process Works"
          orderedData={orderedData2} 
        />

      </div>
    )
  }
}
