import React, { PureComponent } from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { Icon } from "components/common";

import './index.scss'


const DropIcons = () => {
    return (
        <Icon
            name={"chevronBlack"}
            width={15}
            style={{ top: "calc(50% - 7.5px)", right: 0, position: "absolute" }}
        />
    );
};

interface IProps {
    readonly label: string;
    readonly value: string;
    readonly onChange: (data: React.ChangeEvent<HTMLSelectElement>) => void;
    readonly options: string[];
    readonly name: string;
    readonly style: object;
    readonly loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MuiFormControl: {
      width: "100%",

      root: {
        dropdown: {
          width: "200px",
        }
      } 
    },
    MuiInputLabel: {
      color: "black",
      "& span": {
        color: "red"
      },
    },
  })
)
const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&&:before": {
          borderBottom: "2.7px solid black",
        }
      }
    },
    MuiSelect: {
      selectMenu: {
        zIndex: "1"
      }
    }
  }
});
const SelectAble = (props:IProps) => {
  const classes = useStyles();
  
  return(
    <MuiThemeProvider theme={theme}>
      <FormControl
        style={props.style}
        required={true}
        className={classes.MuiFormControl}
      >
          <InputLabel htmlFor={props.name} className={classes.MuiInputLabel} >
              {props.label}
          </InputLabel>
          <Select
              disabled={props.loading}
              value={props.value}
              onChange={(e) => {props.onChange(e)}}
              IconComponent={DropIcons}
              name={props.name}
          >
              {
                props.options.map(el => (
                  <MenuItem
                      value={el}
                      key={el}
                      classes={{ root: "dropdown-item" }}
                  >
                      {el}
                  </MenuItem>
                ))
              }
          </Select>
          {props.loading && (
              <span className="select-field-spinner">
                  <div className="Spinner " />
              </span>
          )}
      </FormControl>
    </MuiThemeProvider>
  );
}
export default SelectAble;