import { Api } from "backend";

const MoveInitiationAPI = {
    getLeaderShipPrograms: (): Promise<any> =>
        Api.get({ path: `/moveinitiation/leadership-programs` }),
    getCaliforniaTiers: (): Promise<any> =>
        Api.get({ path: "/moveinitiation/california-tires" }),
    getFinancePartner: (partner): Promise<any> =>
        Api.get({ path: `/moveinitiation/employeeSearch/${partner}` }),
    getMoveDashboardMemberList: (): Promise<any> =>
        Api.get({ path: "/moveinitiation/request/list" }),
    getEmpJobDetails: (employeeWWID, jobID): Promise<any> =>
        Api.get({ path: `/moveinitiation/request/employeejobinfo/${employeeWWID}/${jobID}` }),
    setMoveEmppJobDetails: (data={} ): Promise<any> =>
        Api.post({
            path: `/moveinitiation/non-competitive/employee-job-position-details`, body:data}),
    getEmpPosJobDetails: (employeeWWID, posID): Promise<any> =>
        Api.get({ path: `/moveinitiation/request/employeepositioninfo/${employeeWWID}/${posID}` }),
    getEmpCompetitiveInit: (reqContestNumber,candidateNumber): Promise<any> =>
        Api.get({ path: `/moveinitiation/candidate/${reqContestNumber}/${candidateNumber}/requisitions` }),
    setMoveAdditionalMoveDetails: (data={} ): Promise<any> =>
        Api.post({
            path: `/moveinitiation/non-competitive/move-additioal-details`, body:data}),
    setMoveFinanceMoveDetails: (data={} ): Promise<any> =>
        Api.post({
            path: `/moveinitiation/non-competitive/move-finance-details`, body:data}),
    setMoveEmppSirvaDetails: (initiationId): Promise<any> =>
        Api.post({
            path: `/moveinitiation/sirva/submit/${initiationId}`}),
    setNonCompetitivePackageDetails: (data={} ): Promise<any> =>
        Api.post({
            path: `/moveinitiation/non-competitive/savepackage`, body:data}),
    setPreInitDetails: (data={} ): Promise<any> =>
        Api.post({
            path: `/moveinitiation/submit-preinit`, body:data}),
    setCompPreInitDetails: (data={} ): Promise<any> =>
        Api.post({
            path: `/moveinitiation/competitive/save-pre-initiation`, body:data}),
    setPreInitPackageDetails: (data={} ): Promise<any> =>
        Api.post({
            path: `/moveinitiation/competitive/savepackage`, body:data}),
    setCompFinanceDetails: (data={} ): Promise<any> =>
        Api.post({
            path: `/moveinitiation/competitive/save-finance-details`, body:data}),
    setCompApprovalDetails: (data={} ): Promise<any> =>
        Api.post({
            path: `/moveinitiation/competitive/save-approver-details`, body:data}),
    deleteMoveJobCard: (initiationId): Promise<any> =>
        Api.delete({
            path: `/moveinitiation/delete/${initiationId}`}),
    getEmployeeByCategory: (category,keyword): Promise<any> =>
        Api.get({ path: `/moveinitiation/employeeSearch/${category}/${keyword}` }),
    setPreInitEmppInfoDetails: (data={} ): Promise<any> =>
        Api.post({
            path: `/moveinitiation/build/combined-employee-record`, body:data}),
    updateOldEmployeeDetails: (initiationId): Promise<any> =>
        Api.get({
            path: `/moveinitiation/complete-old-record/${initiationId}`}),
};

export default MoveInitiationAPI;
