import './style.scss';

import React, { PureComponent } from 'react';
import Slider from "react-slick";
import { Icon } from 'components/common';
import CarouselNavigation from '../CarouselNavigation';

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style}}
            onClick={onClick}
        >
            <CarouselNavigation bgColor={props.bgColor} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style}}
            onClick={onClick}
        >
            <CarouselNavigation direction="left" bgColor={props.bgColor} />
        </div>
    );
}

export default class ColumnComponent extends PureComponent {

 settings = {
    className: "center",
    infinite: false,
    centerPadding: "10px",
    slidesToShow: 3,
    swipeToSlide: true,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1110,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
    ],
    nextArrow: <SampleNextArrow bgColor={this.props.chevronBg} />,
    prevArrow: <SamplePrevArrow bgColor={this.props.chevronBg} />
};

 generateColumntContent = () => (
     this.props.data && this.props.data.map((item, key) => (
         <div className="card" key={key}>
             <Icon name={item.icon} width={40} height={40}/>
                <h3>{item.title}</h3>
             <p className="truncate fourLines">{item.desc}</p>
             <div className="estimate">
                 <hr/>
                 <Icon name="timeRed"/><span className="time-label">Estimated Time:</span><br/>
                 <span className='time-desc'>{item.estimateTime}</span>
             </div>
         </div>
     ))
 );

  render() {
    return (
      <div className={`ColumnComponent ${this.props.className || ''}`}>
        {
          this.props.title ?
          <div className="column-component-header">
          <h2 className="flexible vertical aCenter">{this.props.title}
          <span/>
          </h2>
          </div> : null
        }
        <div className="column-component-body flexible horizontal grow ">
            <Slider {...this.settings}>
                {this.generateColumntContent()}
            </Slider>

        </div>
      </div>
    )
  }
}
