// @flow

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import type { DropDownOption } from '../index';

import './style.scss';

type PositionInfo = {
    x: number,
    y: number
};

type OptionsProps = {
    data: Array<DropDownOption>,
    selectedOption: DropDownOption,
    onChange: Function,
    anchorElement: HTMLElement<*>,
    isInFixedContent: ?boolean,
    withCloseButton: ?boolean,
    styles: Object,
    optionsClassName: string,
    toggle: Function,
};

type OptionsState = {
    positionInfo: PositionInfo;
};

const modals_root = document.getElementById('modals-root');

export default class Options extends PureComponent<OptionsProps, OptionsState> {
    optonsEl: HTMLElement;

    constructor(props){
        super(props);

        this.optionsEl = null;

        this.state = {
            positionInfo: null
        };
    }

    componentDidMount() {
        const positionInfo = this.getPositionInfo(this.optionsEl);
        this.setState({positionInfo});
    }

    calculatePosition = (anchorElementRects: Object, optionsElement: HTMLElement): PositionInfo => {
        const {
            x: anchorElX,
            y: anchorElY,
            height: anchorElHeight,
            width: anchorElWidth
        } = anchorElementRects;
        const {
            offsetHeight: optionsHeight,
            offsetWidth: optionsWidth,
        } = optionsElement;

        const { innerHeight, pageYOffset } = window;

        const freeBottomSpace = innerHeight - (anchorElY + anchorElHeight);
        let optionsElTop;
        let optionsElLeft;
        if(freeBottomSpace > optionsHeight) {
            optionsElLeft = anchorElX;
            optionsElTop = this.props.isInFixedContent ? anchorElY + anchorElHeight : pageYOffset + anchorElY + anchorElHeight;
        } else {
            optionsElLeft = anchorElX - (optionsWidth - anchorElWidth);
            optionsElTop = this.props.isInFixedContent ? anchorElY - optionsHeight : pageYOffset + anchorElY - optionsHeight;
        }

        return ({
            x: optionsElLeft,
            y: optionsElTop,
        });
    };

    getPositionInfo = (optionsElement: HTMLElement): Object => {
        const { anchorElement } = this.props;
        if(!anchorElement) {
            return;
        }

        const anchorElementRects = anchorElement.getBoundingClientRect();
        const position = this.calculatePosition(anchorElementRects, optionsElement);
        return ({
            top: position.y,
            left: position.x,
        });
    };

    handleRef = (ref: HTMLElement): void => { this.optionsEl = ref; };

    render() {
        const { selectedOption, isInFixedContent } = this.props;

        return ReactDOM.createPortal(
            <div>
                <div className="dropdown-block">
                    <div
                        className={`Options ${this.props.optionsClassName}`}
                        style={{
                            position: `${isInFixedContent ? 'fixed': 'absolute'}`,
                            ...this.state.positionInfo,
                            ...this.props.styles
                        }}
                        ref={this.handleRef}
                    >
                        {
                            this.props.withCloseButton &&
                            <div
                                className="closeButton"
                                onClick={this.props.toggle}
                            />
                        }
                        <ul>
                            { this.props.data.map(option => (
                                <li
                                    data-key={option.key}
                                    key={option.key}
                                    className={`option flexible aCenter truncate singleLine ${(selectedOption && selectedOption.key === option.key) ? 'selected' : ''}`}
                                    onClick={() => {this.props.onChange && this.props.onChange(option)}}
                                >
                                    {option.label}
                                    {option.key === 'tasks' && option.count ? <span className={'count flexible aCenter jCenter'}>{option.count}</span> : null}
                                </li>
                            )) }
                        </ul>
                    </div>
                </div>
            </div>, modals_root
        );
    }
}
