import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Stepper, EmployeeHero } from 'components/sections';
// import { Asset, InfoCard } from 'components/common';
// import { INFO_CARD_COLORS,EXTERNAL_LINKS } from 'consts';
import { getUserProfile } from 'selectors';
import {camelCase} from 'lodash';
import steps from './../steps';
import './../style.scss';
import IntExploreStep from './MoveInfoSteps/IntExploreStep';
import IntInitMoveStep from './MoveInfoSteps/IntInitMoveStep';
import IntArivalStep from './MoveInfoSteps/IntArivalStep';
import DomExploreStep from './MoveInfoSteps/DomExploreStep';
import InitiateAMove from './MoveInfoSteps/MoveInfo/InitiateAMove'
import IntJourneyBackStep from './MoveInfoSteps/IntJourneyBackStep';

import photoRoadImage from 'components/common/Images/photo-road.png';

const mapStateToProps = ( state ) => ({
    userProfile: getUserProfile(state)
});

class HrMoveInfoProfile extends PureComponent {

    constructor(props)    {
        super(props);
        this.handleStepChange = this.handleStepChange.bind(this);
    }

    handleStepChange = (stepInfo: StepObj): void => {
        const { params } = this.props.match;
        this.props.history.push(`/profile/${params.move}/${stepInfo.key}`);
    };

    getStepLayout = (moveType:string, stepName:string) => {
      if(camelCase(moveType) === 'international') {
          if(camelCase(stepName) === 'explore') { return (<div><IntExploreStep /></div>) }
          else if(camelCase(stepName) === 'initiateMove') { return (<IntInitMoveStep />) }
          else if(camelCase(stepName) === 'arrival') { return (<IntArivalStep />) }
          else { return (<IntJourneyBackStep />) }
      } else if(camelCase(stepName) === 'explore'){
          return  (<DomExploreStep />);
      } else {
          return (<InitiateAMove />);
      }
    }

    render() {
        const { params } = this.props.match;

        return (
            <div className="Profil flexible jCenter vertical">
                <EmployeeHero
                    imageUrl={photoRoadImage}
                    userName={this.props.userProfile.shortName}
                    title="Learn all about talent moves and how to initiate mobility services."
                />
                <div className={'employee-stepper ' + (camelCase(params.move) === 'domestic' ? 'short-stepper domestic-initiate-step-single' : '') }>
                    <Stepper
                        key={params.move}
                        onStepChange={this.handleStepChange}
                        activeStepKey={params.step}
                        steps={steps.hrmanager[params.move]}
                    />
                </div>

                { this.getStepLayout(params.move, params.step )}

                {/* { camelCase(params.move) === 'international'
                ? (<section className="three-cards flexible">
                        <InfoCard
                            className="small"
                            backgroundColor={INFO_CARD_COLORS.first}
                            headerIsLink
                            title="Find the Right Relocation Package"
                            content="Use our interactive guide to select the right relocation package for your global assignment scenario."
                            underLink="Package Selection Tool"
                            onClick={()=>new Asset({assetType:'policy_decision_tree', assetId:'international'}).execute()}
                        />
                        <InfoCard
                            className="small"
                            backgroundColor={INFO_CARD_COLORS.second}
                            headerIsLink
                            title="Immigration Initiation form"
                            content="Determine if your candidate is eligible for immigration."
                            underLink="Immigration Initiation Form"
                            category="external"
                            onClick={()=>new Asset({assetType:'immigration-service', assetId:'immigration-service-initiation-form'}).execute()}
                        />
                        <InfoCard
                            className="small"
                            backgroundColor={INFO_CARD_COLORS.third}
                            headerIsLink
                            title="Estimate the Cost of Relocation"
                            content="Need to set a budget? Use our tool to get a preliminary cost estimate."
                            underLink="Cost Estimate Tool"
                            category="external"
                            externalLink={EXTERNAL_LINKS.GlobalCostEstimator}
                        />
                    </section>)
                : (<section className="three-cards jAround flexible">
                        <InfoCard
                            className="small"
                            backgroundColor={INFO_CARD_COLORS.first}
                            headerIsLink
                            title="Find the Right Relocation Package"
                            content="Use our interactive guide to select the right relocation package for your U.S. Domestic scenario."
                            underLink="Policy Decision Tree"
                            onClick={()=>new Asset({assetType:'policy_decision_tree', assetId:'domestic'}).execute()}
                        />
                        <InfoCard
                            className="small"
                            backgroundColor={INFO_CARD_COLORS.second}
                            headerIsLink
                            title="Learn what you need to initiate move services"
                            content="Use our comprehensive checklist to make sure you have everything you need."
                            underLink="Domestic Initiation Checklist"
                            onClick={()=>new Asset({assetType:'move-initiation-checklist', assetId:'domestic'}).execute()}
                        />
                        <InfoCard
                            className="small"
                            backgroundColor={INFO_CARD_COLORS.third}
                            headerIsLink
                            title="Estimate the Cost of Relocation"
                            content="Need to set a budget? Use our tool to get a preliminary cost estimate."
                            underLink="U.S. Domestic Cost Estimator"
                            category="external"
                            externalLink={EXTERNAL_LINKS.GlobalCostEstimator}
                        />
                    </section>)
                } */}
                {<section className="three-cards flexible">&nbsp;</section>}
                
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, {})(HrMoveInfoProfile));
