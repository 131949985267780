import React from 'react';
import { Link } from 'components/common';

export const QuestionsList = [
    {
        title: 'When should I begin planning for end of assignment?',
        icon: 'pin',
        content: (
            <p className="question-description">
                Host and home manager have a responsibility to partner in planning and identification of next job opportunities that leverage the global experience and capabilities of the assignee. Managers should also engage with the HR Business Partners for support in identifying potential positions. Localization in the host country may also be an alternative, where business and personal drivers align, and host country immigration regulations permit.
                <br/><br/>For short term assignments, the Relocation Company will reach out to Host Managers 3 months prior to end of assignment to begin discussing next steps. For long term assignments, the Relocation Company will reach out to Host Managers 6 months prior to end of assignment.
            </p>
        )
    },
    {
        title: 'What guidelines should I follow for goal setting?',
        icon: 'personWorld',
        content: (
            <p className="question-description">
                Ultimately, the employee is responsible for their post-assignment career path. An assignee is encouraged to take advantage of opportunities to expand his/her networking throughout the assignment. For long term assignments, we recommend employees actively begin sourcing new opportunities 6-8 months prior to end of assignment. For short term assignments, we recommend employees actively begin sourcing new opportunities 3 months prior to end of assignment.
            </p>
        )
    },
    {
        title: 'Who assists with the relocation logistics for returning back to the home country?',
        icon: 'moving',
        content: (
            <p className="question-description">
                Relocation to the home country will leverage the same process and procedures followed at the beginning of the global assignment. This includes immigration, tax and relocation services.
            </p>
        )
    },
    {
        title: 'Can assignments extend beyond 5 years? ',
        icon: 'infoFile',
        content: (
            <p className="question-description">
                Assignments should not exceed 5 years in duration in one country. If  the business needs to extend an assignment beyond 5 years, there is a formal extension approval process. The link explaining the 5 Year Extension approval request process is provided below.
                <Link text="5+ Year Extension Process" externalLink="https://jnj.sharepoint.com/sites/onehr/OurEnterprise/PublishingImages/TalentMobility/5YearExtensionProcess.pptx"/>
            </p>
        )
    },
    {
        title: 'What is the process for localization?',
        icon: 'person',
        content: (
            <p className="question-description">
                In some cases, the global assignee will choose to be localized in the host location rather than to return to the home country. The company may request localization prior to the expected end date of an assignment. All localizations will require a review of the immigration regulations of the host country to assess the feasibility of localization. Localization will only occur with the agreement of both the Company and the global Assignee, and after the Assignee has been briefed about local employment terms and other pertinent implications.
            </p>
        )
    },
    {
        title: 'What do I need to know about role transition management?',
        icon: 'clipboard',
        content: (
            <p className="question-description">
                It is important that you work with the Assignee to ensure a plan is in place to effectively transition information and duties (if applicable) to local country staff and/or an incoming assignee.  Effective transition planning supports organizational knowledge retention and extends the benefit of an assignment beyond just the assignee to benefit host talent and the local company, thereby building capabilities for Johnson & Johnson for the long term.
            </p>
        )
    }
];