// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Tabs } from 'components/sections';
import {
    List,
    ListItem,
    DropDown,
    Asset
} from 'components/common';

import { getUserProfile, getDocuments } from 'selectors';

import { documentsService } from 'services/';
import { DocumentsAPI } from 'api';

import './styles.scss';
import { LONG_DATE_FORMAT } from 'consts';
import { IMyDocuments, IUserProfile } from "utils/types";

const mapStateToProps = (state: any) => ({
    userProfile: getUserProfile(state),
    documents: getDocuments(state)
});

const mapDispatchToProps = {
    fetchDocuments: documentsService.actions.fetchDocuments
};

interface IProps {
    readonly userProfile: IUserProfile
    readonly fetchDocuments: (id: string) => Promise<void>;
    readonly documents: IMyDocuments[];
}

interface ITab {
    readonly key: string;
    readonly label: string;
}

interface IState {
    readonly activeTabKey: string;
    readonly isLoaded: boolean;
    readonly tabs: ITab[]
}

class MyDocuments extends PureComponent<IProps, IState> {
    private readonly defaultTabs = {
        all: {
            count: 0,
            name: 'All Documents'
        },
        'Balance Sheet': {
            count: 0,
            name: 'Balance Sheets'
        },
        'GAL': {
            count: 0,
            name: 'Global Assignment Letter'
        },
        'Expenses': {
            count: 0,
            name: 'Expenses'
        },
        'Immigration': {
            count: 0,
            name: 'Immigration'
        },
        'Other': {
            count: 0,
            name: 'Other'
        }
    };


    constructor(props: IProps) {
        super(props);

        this.onTabChange = this.onTabChange.bind(this);

        this.state = {
            activeTabKey: 'all',
            isLoaded: false,
            tabs: []
        };
    }


    public componentDidMount(): void {
        if (this.props.userProfile && this.props.userProfile.currentRelocationId) {
            this.loadDocuments();
        }
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.userProfile.currentRelocationId !== prevProps.userProfile.currentRelocationId) {
            return this.loadDocuments();
        }
    }

    private loadDocuments() {
        this.props
            .fetchDocuments(this.props.userProfile.currentRelocationId)
            .then(() => {
                this.setState({ isLoaded: true });
                this.generateTabsList();
            });
    }

    private readonly onTabChange = (tabInfo: { key: string }) => {
        this.setState({
            activeTabKey: tabInfo ? tabInfo.key : 'all',
        });
    };

    private generateTabsList = () => {
        if (!this.props.documents || !this.props.documents.length) {
            return this.setState({ tabs: [] });
        }

        const newTabs = JSON.parse(JSON.stringify(this.defaultTabs)) as { [key: string]: { count: number, name: string } };

        this.props.documents.forEach(item => {
            if (item.documentGroup) {
                newTabs[item.documentGroup].count = newTabs[item.documentGroup].count + 1
            }

            newTabs.all.count = newTabs.all.count + 1;
        });

        const tabs = Object.keys(newTabs)
            .filter(el => newTabs[el].count > 0)
            .map(key => {
                const tab = newTabs[key];

                return {
                    label: `${tab.name} ${tab.count}`,
                    key
                }
            });

        this.setState({ tabs });
    };

    private generateDocumentsList = () => {
        const activeTabKey = this.state.activeTabKey;

        if (!this.props.documents.length) {
            return null;
        }

        const documents = this.props.documents.filter(doc => activeTabKey === 'all' || activeTabKey === doc.documentGroup);

        return documents.map((doc, i) => {
            return (
                <ListItem
                    key={(doc.documentId * doc.fileSize) + i}
                    header={
                        <div className="document-name-section flexible vertical jCenter jStart"
                        >
                            {doc.documentType && <div className="document-category">{doc.documentType}</div>}
                            <p className="document-name truncate singleLine"
                               title={doc.fileName}
                               onClick={() =>
                                   new Asset({
                                       customDownloader: () => DocumentsAPI.downloadDocument(this.props.userProfile.currentRelocationId, doc.documentId),
                                       customFileName: doc.fileName
                                   }).execute()}>{doc.fileName}</p>
                        </div>
                    }
                    style={{}}
                    middleContent={doc.createDate ? moment(doc.createDate).format(LONG_DATE_FORMAT) : ''}
                    rightButtons={[{
                        name: 'download',
                        onClick: () => {
                            new Asset({
                                customDownloader: () => DocumentsAPI.downloadDocument(this.props.userProfile.currentRelocationId, doc.documentId),
                                customFileName: doc.fileName
                            }).execute();
                        }
                    }]}
                />
            )
        })
    };

    public render() {
        const { documents } = this.props;
        const { isLoaded } = this.state;
        return (
            <section className="MyDocuments">
                <div className="my-documents-header">
                    <h1 className="header-text">My Documents</h1>
                    <div className="flexible vertical">
                        {isLoaded && !documents.length ?
                            <div className="empty-mobile">There are not available documents</div>
                            : <DropDown
                                data={this.state.tabs}
                                dataKey="my_documents_mobile"
                                onChange={this.onTabChange}
                                defaultSelected="all"
                                optionsClassName="my-documents-mobile-select"
                            />
                        }
                        {documents.length ? <Tabs
                                className="linear-selected"
                                data={this.state.tabs}
                                activeTabKey='all'
                                onTabChange={this.onTabChange}
                            />
                            : null
                        }
                        {isLoaded ? (!documents.length ? (
                                    <div className="not-mobile">There are not available documents</div>
                                ) : (
                                    <List>{this.generateDocumentsList()}</List>
                                )
                            ) : <div className="Spinner"/>
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDocuments as any);
