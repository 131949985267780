(function() {
    initializeArrayFind();
})();

function initializeArrayFind () {
    if (!Array.prototype.find) {
      // eslint-disable-next-line no-extend-native
        Array.prototype.find = function(predicate) {
            if (this == null) {
                throw new TypeError('Array.prototype.find called on null or undefined');
            }
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }
            const list = Object(this);
            const length = list.length >>> 0;
            const thisArg = arguments[1];
            let value;

            for (let i = 0; i < length; i++) {
                value = list[i];
                if (predicate.call(thisArg, value, i, list)) {
                    return value;
                }
            }
            return undefined;
        };
    }
}

export const browserDetecter = () => {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const html = document.querySelector('html');

    function getBrowser() {
        if(/*@cc_on!@*/false || !!document.documentMode) {
            return 'ie';
        } else if (!isIE && !!window.StyleMedia) {
            return 'edge';
        } else if (!!window.chrome && !!window.chrome.webstore) {
            return 'chrome'
        } else if (typeof InstallTrigger !== 'undefined') {
            return 'firefox';
        }
        else {
            return 'safari';
        }
    }
    html.classList.add(getBrowser());
    return getBrowser();
};
