// @flow

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import {Asset, List, ListItem, Link} from 'components/common';
import {Tabs, Accordion} from 'components/sections';
import './../style.scss';
import {resources} from './../resources.js';
import { onHistoryLinkClicked } from '../../../utils';
import {connect} from "react-redux";
import { errorService, siteOverviewService } from "services";

const tabData = [
    {
        label: "Manager Toolkit",
        key: 'managerToolkit',
    },
    {
        label: "Global Resources",
        key: 'managerInt',
    },
    {
        label: "U.S. Domestic Resources",
        key: 'managerDom',
    },
    {
        label: "Training Materials",
        key: 'managerTrain',
    }
];

class HrResources extends PureComponent<*> {

    constructor(props) {
        super(props);
        this.categoryItem = [];
        const autoSelectTab = props.match.params.selectTab || 'managerToolkit';
        this.state = {
            selectTab:autoSelectTab,
            selectCategory:props.match.params.selectCategory
        }
    }
    componentWillReceiveProps(nextProps) {
        if(
            (!this.props.selectCategory && nextProps.match.params.selectCategory)
            || (this.props.match.params.selectTab !== nextProps.match.params.selectTab)
        )
            this.setState({
                selectTab:nextProps.match.params.selectTab,
                selectCategory:nextProps.match.params.selectCategory
            });
    }

    componentDidMount() {
        if (this.state.selectCategory)
            this.focusCategory();
    }
    componentDidUpdate() {
        if(this.state.selectCategory && this.state.active)
            this.focusCategory();
    }
    focusCategory() {
        if (this.state.selectCategory) {
            const itemDiv = this.categoryItem[this.state.selectCategory];
            if (itemDiv) {
                const top = itemDiv.offsetTop;
                window.scrollTo({ top: top-77, behavior: "smooth"}); // 77 is height of header
            }
        }
    }

    genLineItems = (items : Array<*>) => {
        return(items.map(doc => (
            doc.content ?
            <Accordion data={doc} mode="resource" key={doc.documentId} handleDownloadClick={this.handleDownloadClick}/>
            :
            <ListItem
                className="pointer"
                onClick={() => {this.handleItemClick(doc)}}
                key={doc.documentId}
                header={
                    <div className="document-name-section">
                        <p className="document-name">{doc.description}</p>
                    </div>

                }
                middleContent={<div className="document-category">{doc.fileType}</div>}
                rightButtons={[{
                    name: doc.fileType === 'Webpage' || doc.fileType === 'Website' ? 'chevronRed' : 'download',
                    onClick: () => {this.handleDownloadClick(doc.assetKey, doc.assetId, doc.fileType, doc.isLinkExternal ? doc.linkUrl : doc.fileName)}
                }]}
            />
            ))
        );
    };

    generateDocumentsList = (tabKey:string, categories: Array<Object>) => {
        if (!categories)
            return null;
        if (tabKey === 'managerToolkit') {
            return (<div className="manager-toolkit-body">
                        {this.generateManagerCardRows()}
                    </div>);
        }
        return categories.map((cat, index) => (
            <div className="category-header" key={cat.key ? cat.key : `${cat.title}_${index}`}
                 ref={(catDiv) => { if (cat.key) this.categoryItem[cat.key] = catDiv; }} >
            <div className="flexible horizontal aCenter">
            <img alt={cat.title} src={cat.icon} /><h2>{cat.title}</h2>
            </div>
            <List className="flexible vertical">
                {this.genLineItems(cat.items)}
            </List>
            </div>
        ));
    };

    generateManagerCardItems = (fromIndex:number) => resources.managerToolkit.slice(fromIndex, fromIndex+3).map(((item, index) => (
        <div className="card" key={index+1}>
            <h5 className="title">{item.title}</h5>
            <h4 className="subtitle">{item.subTitle}</h4>
            <p className="text">{item.text}</p>
            <p>
            { item.linkText ? (item.assetId
                ? <Link text={item.linkText} onClick={() => new Asset({assetType:item.assetType, assetId:item.assetId}).execute()} />
                : (item.isLinkExternal
                    ? <Link text={item.linkText} externalLink={item.linkUrl} />
                    : <Link text={item.linkText} action='click-resource-toolkit'
                        category='resources'
                        label={item.linkText}
                        onClick={() =>window.location.replace(item.linkUrl)} />))
            : null
            }
            </p>
        </div>
    )));

    culcCountRows = (len:number) => parseInt(len/3, 0)+ (len % 3 > 0 ? 1 : 0);

    generateManagerCardRows = () =>  {
        const rowsCount = this.culcCountRows(resources.managerToolkit.length);
        const _self = this;
        return Array.apply(null, {length: rowsCount}).map(function(value, index){
            return ( <div className="info-block flexible horizontal grow" key={index+1}>
                <div className="ColumnComponent ">
                    <div className="column-component-body flexible horizontal grow ">
                        {_self.generateManagerCardItems(index*3)}
                    </div>
                </div>
            </div>);
          });
    };

    tabsOnchange = ({ key }) => {
        this.props.history.push(`/resources/${key}`);
    };

    handleItemClick = ( doc: Object ) => {
        if(doc.documentId === 56) {
            this.props.toggleSiteOverview(true);
        }
    };

    handleDownloadClick = async(assetKey : string, assetId : string, fileType : string, fileName : string) : void => {
        if (fileType === 'PDF' && (!assetKey || !assetId)) {
            return this.props.toggleErrorModal(true, 'Sorry. It’s not you. It’s us.', 'Document was not found.');
        }

        fileType === 'Website'
            ? onHistoryLinkClicked({
                action:'click-website-link',
                category:'resources',
                label:fileName,
                onClick:() => window.open(fileName)
             })
            : new Asset({assetType:assetKey, assetId:assetId}).execute();
    };

    render() {
        const { selectTab } = this.state;
        return (
            <section className="Resources">
                <h1 className="header-text" data-key="contact_us_title">Tools & Resources</h1>
                    <div className="flexible vertical">
                        <Tabs
                            className="linear-selected"
                            data={tabData}
                            activeTabKey={selectTab}
                            onTabChange={this.tabsOnchange}
                        />
                    {
                        this.generateDocumentsList(selectTab, resources[selectTab])
                    }
                    </div>
            </section>
        );
    }
}

const mapDispatchToProps = {
    toggleSiteOverview: siteOverviewService.actions.toggleSiteOverview,
    toggleErrorModal: errorService.actions.toggleErrorModal
};

export default withRouter(connect(null, mapDispatchToProps)(HrResources));
