import React, { PureComponent } from "react";
import { Button, Link } from "components/common";
import { generalMessages } from "./mockData";
import { OptionType } from "../AutoComplete";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { moveInitiationService } from "services";
import FinanceAutoComplete from "../Finance-AutoComplete";
import { MoveDetailsFinanceState } from "types";
import "./styles.scss";

const mapDispatchToProps = {
    fetchFinancePartner: moveInitiationService.actions.fetchFinancePartner,
    setCompFinanceDetails: moveInitiationService.actions.setCompFinanceDetails,
};

class Finance extends PureComponent<
    RouteComponentProps & {
        fetchFinancePartner: (partner: string) => Promise<any>;
        navigateTo: (to: string, from: string) => void;
        setCompFinanceDetails: (data: object) => Promise<any>;
    },
    MoveDetailsFinanceState
> {
    interval: any;
    generatedMessages: Array<JSX.Element> = [];
    state = {
        currentMessage: 0,
        showContent: false,
        partner: "",
        selectedPartner: (this.props.history.location.state.selectedPartner != null && typeof this.props.history.location.state.selectedPartner !== 'undefined') ? 
                        this.props.history.location.state.selectedPartner : null,
        partners: [],
        isLoading: false,
        messages: [],
    };

    componentDidMount(): void {
        this.interval = setInterval(this.showMessage, 1000);
    }

    showMessage = (): void => {
        if (this.state.currentMessage === generalMessages.length) {
            clearInterval(this.interval);
            this.interval = null;
            return this.setState({ showContent: true });
        }

        this.setState(
            (prevState: MoveDetailsFinanceState): MoveDetailsFinanceState => {
                const messages = [
                    ...prevState.messages,
                    generalMessages[prevState.currentMessage],
                ];
                this.generatedMessages = this.generateMessages(messages);
                return {
                    currentMessage: prevState.currentMessage + 1,
                    messages,
                };
            },
        );
    };

    generateMessages = (messages: Array<string>): Array<JSX.Element> =>
        messages.map((element: string, index: number) => (
            <div key={element} className={"message"}>
                {element}
                {/* {index === 1 ? (
                    <Link
                        dataKey={"find-your-finance-contacts"}
                        text={"Find your finance contacts"}
                        action={"find-your-finance-contacts"}
                        onClick={() => null}
                        className={"finance-contacts"}
                    />
                ) : null} */}
            </div>
        ));

    clearAutoComplete = () => [];

    handleClick = (selectedPartner: OptionType) =>
        this.setState({
            selectedPartner,
            isLoading: false,
        });
        //console.log(partners);

    changeHandler = (partner: string) => {
        if (
            this.state.selectedPartner &&
            this.state.selectedPartner.label !== partner
        ) {
            this.setState({
                selectedPartner: null,
            });
        }
        if (partner.length > 2) {
            this.setState({
                isLoading: true,
            });
            this.props
                .fetchFinancePartner(partner)
                .then(partners => {
                    this.setState({
                        partners: partners.result,
                        isLoading: false,
                    });
                })
                .catch(err => console.log(err));
        } else {
            if (this.state.partners.length) {
                this.setState({
                    partners: [],
                });
            }
        }
    };

    continueHandler = (): void => {
        this.setState({
            isLoading: true
        })
        this.props.history.push({
            state: {
                ...this.props.location.state,
                "selectedPartner": this.state.selectedPartner
            },
        })
        
        const dataSet = {
            "initiationId": this.props.location.state.initiationId,
            "financePartner": {
              "firstName": this.state.selectedPartner.firstName,
              "lastName": this.state.selectedPartner.lastName,
              "displayName": this.state.selectedPartner.displayName,
              "jobTitle": this.state.selectedPartner.jobTitle,
              "email": this.state.selectedPartner.email,
              "wwid": this.state.selectedPartner.wwid
            }
          };

        this.props
            .setCompFinanceDetails(dataSet)
            .then(data => {
                this.setState({
                    isLoading: false
                })
                this.props.history.push({
                    state: {
                        ...this.props.location.state,
                        "financeData": dataSet
                    },
                })
                this.props.navigateTo("approvals", "finance");
            })
            .catch(e => {
                console.log(e)
            });
    };

    render() {
        const { selectedPartner } = this.state;
        return (
            <div className="finance">
                <h1>Finance</h1>
                <div className="messages">{this.generatedMessages}</div>
                {this.state.showContent && (
                    <React.Fragment>
                        <FinanceAutoComplete
                            isLoading={this.state.isLoading}
                            title={"Finance Partner"}
                            placeholder={"Enter name"}
                            inputTitle={"Funding Finance Contact Name"}
                            clearAutoComplete={this.clearAutoComplete}
                            suggestions={this.state.partners}
                            handleClick={this.handleClick}
                            style={{ marginTop: 38 }}
                            required
                            onChange={this.changeHandler}
                            value={selectedPartner}
                        />

                        <div className={"divider"} />
                        <div className={"flexible jEnd"}>
                            <Button
                                onClick={this.continueHandler}
                                title="Continue"
                                disabled={!this.state.selectedPartner}
                                className="finance-submit"
                                submit={true}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(
        null,
        mapDispatchToProps,
    )(Finance),
);
