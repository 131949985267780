// @flow

import React from 'react';
import { Icon } from 'components/common';

import './styles.scss';

type AllowanceItemProps = {
    open: boolean,
    allowanceData: Object,
};

export default class AllowanceItem extends React.PureComponent<AllowanceItemProps> {
    render() {
        const { open } = this.props;
        const { name, value, valueType, description, unitOfMeasure } = this.props.allowanceData;
        const itemText = (value === 0 ? 'Not Included' : 'Included');
        const displayValue =
            (valueType === undefined) ? '' :
                (valueType === "Boolean") ? itemText : <div>{`${unitOfMeasure} `} <span className="currency">{new Intl.NumberFormat().format(value)}</span></div>;

        return (
            (valueType === undefined) || value ?
            <div className={`AllowanceItem flexible horizontal jBetween aCenter ${open ? 'opened' : ''}`}>
                <p className="allowance-name">{name}</p>
                <div className="icon-block flexible jBetween">
                    <p className="allowance-value">{displayValue}</p>
                    { !!description && <Icon name={open ? 'minus' : 'plus'} /> }
                </div>
            </div> : null
        );
    }
}
