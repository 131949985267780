// @flow

import * as React from 'react';
import Icon from '../../Icons';

import './styles.scss';

type ListItemProps = {
    open: boolean,
    title: string | React.Node,
    middleContent?: string | React.Node,
    rightIcons?: {
        open: string,
        close: string,
    },
    disabled: boolean,
};

export default class ListItem extends React.PureComponent<ListItemProps> {
    render() {
        const { open } = this.props;
        const { title, middleContent, rightIcons = {}, disabled } = this.props;

        return (
            <div className={`ListItem flexible horizontal jBetween aCenter ${open ? 'opened' : ''}`}>
                <div className='title-with-content flexible grow horizontal jBetween'>
                    { !!title &&  <div className="title">{title}</div> }
                    { !!middleContent &&  <div className="middle-content">{middleContent}</div> }
                </div>
                <div className="icon-block flexible grow jEnd">
                    { !disabled && <Icon name={open ? rightIcons.close || 'minus' : rightIcons.open || 'plus'} /> }
                </div>
            </div>
        );
    }
}
