import { PureComponent } from 'react';
import { userService } from 'services';
import './Login.scss';

export default class Login extends PureComponent {

    getParameterFromLocation(paramName) {
        var searchString = window.location.search.substring(1),
            i, val, params = searchString.split("&");

        for (i=0;i<params.length;i++) {
          val = params[i].split("=");
          if (val[0] === paramName) {
            return val[1];
          }
        }
        return null;
    }

    componentWillMount() {
        var redirectUrl = '';
        if (window.location.search) {
            const redirectUrlFromPar = this.getParameterFromLocation('returnUrl');
            if (redirectUrlFromPar)
                redirectUrl = redirectUrlFromPar;
        }
        userService.userManager.signinRedirect({ data: { redirectUrl } });
    }

    render() {
        return null;
    }
}
