import React, { PureComponent, Fragment } from 'react';
import { Button } from 'components/common';
import {SelectField }from "components/sections/ValidatableForm/elements";
import { Popup } from "components/sections";
import ExpenseReportAPI from "../../api/expense-reports";
import Currencies from './Currencies';

export default class PaymentDetails extends PureComponent{
    static defaultProps = {
        expenseData: {}
    }
    constructor(props){
        super(props);
        const { remittanceInfo: { remittanceAccount = {}} = {} } = props.expenseData;

        this.state = {
            isEditing:false,
            isAdding:false,
            paymentMethods: [],
            paymentMethod: '',
            paymentAccounts: [],
            paymentAccount: '',
            isPopupOpen: false,
            payTo: remittanceAccount && `${remittanceAccount.bankName}, ${remittanceAccount.city}, ${remittanceAccount.state}`,
            currencies:{
                incurredCurrency:props.expenseData.incurredCurrency || 'USD',
                reimbursementCurrency: props.expenseData.reimbursementCurrency || 'USD',
            },
            allowAllReimbursed: false,
        }
    }
    enableForm = () => {
        const { remittanceInfo = {} } = this.props.expenseData;
        if (remittanceInfo && remittanceInfo.method && remittanceInfo.method.methodId) {
            const newState = {
                isEditing:true,
                payTo: remittanceInfo.remitAccount.addressLine1
            };
            if(!this.state.paymentMethod){
                Object.assign(newState,{
                    paymentMethod:  remittanceInfo.method.methodId,
                    paymentMethods: [{
                        value: remittanceInfo.method.methodId,
                        label: remittanceInfo.method.description,
                    }],
                })
            }
            if(!this.state.paymentAccount){
                Object.assign(newState,{
                    paymentAccounts: [{
                        value: remittanceInfo.remitAccount.accountId,
                        label: remittanceInfo.remitAccount.description,
                    }],
                    paymentAccount: remittanceInfo.remitAccount.accountId,
                })
            }
            this.setState(newState)
            this.fetchAccounts(remittanceInfo.method.methodId);
        }
        this.setState({
            isEditing: true
        });
        ExpenseReportAPI.getPaymentRemittanceMethods(this.props.profile.currentRelocationId)
            .then((response = []) => {
                let shouldChangeAssignment = false;
                const paymentMethods = response.result.map(method => {
                    shouldChangeAssignment = shouldChangeAssignment ||
                        (method.methodId === this.state.paymentMethod);
                    return ({
                        value: method.methodId,
                        label: method.description
                    })})
                this.setState({
                    paymentMethods,
                    paymentMethod: !shouldChangeAssignment ? paymentMethods[0].value : this.state.paymentMethod,
                });
                if(!shouldChangeAssignment){
                    this.fetchAccounts(paymentMethods[0].value)
                }
            });

    };
    fetchAccounts = (methodID) =>
        ExpenseReportAPI.getPaymentRemittanceAccount({
            reportID: this.props.profile.currentRelocationId,
            methodID
        }).then((response = [])=>{
            let shouldChangeAssignment = false;
            if(response.result && response.result.length){
                const paymentAccount = response.result.map(account => {
                    shouldChangeAssignment = shouldChangeAssignment ||
                        (account.accountId === this.state.paymentAccount);
                    return ({
                        value:account.accountId,
                        label:account.accountName,
                        ...account
                    })})
                this.setState({
                    paymentAccounts: paymentAccount,
                    paymentAccount: shouldChangeAssignment ? this.state.paymentAccount : response.result[0].accountId
                });
                if(paymentAccount.length){
                    this.updatePaymentAccount({target:paymentAccount[0],...response.result[0]});
                }
            } else {
                this.setState({
                    paymentAccounts: null,
                    paymentAccount: 'No payment accounts',
                });
            }

        })

    doSave = () => {
        if(!this.state.paymentAccounts || !this.state.paymentAccounts.length) {
            this.setState({isPopupOpen: true});
            return false;
        }
        const { remittanceInfo = {} } = this.props.expenseData;
        const remitAccount  = this.state.paymentAccounts.find(({value:key}) =>
            key === this.state.paymentAccount
        );
        const paymentMathod = this.state.paymentMethods.find(({value:key}) =>
            key === this.state.paymentMethod
        );
        this.setState({
            isSaveOnProgress:true,
        })
        ExpenseReportAPI.savePaymentRemittance(
            {
                reportID:this.props.expenseData.reportId,
                relocationId:this.props.profile.currentRelocationId
            },{
                // ...(this.props.expenseData.reimbursementCurrency ? {} : this.state.currencies ),
                    ...this.state.currencies,
                remitMethodId: this.state.paymentMethod,
                ...(remittanceInfo.remittanceId ? {
                    remittanceId: remittanceInfo.remittanceId
                } : {} ),
                account: {
                    accountId:remitAccount.value,
                    description:remitAccount.label
                },
                method:{
                    methodId:paymentMathod.value,
                    description:paymentMathod.label
                },
            }
        ).then(() => {
            this.setState({isEditing: false});
            this.props.updateExpenseReport(
                this.props.expenseData.reportId,
                this.props.profile.currentRelocationId
            )
        })
            .catch((error)=> {
                console.dir(error)
                this.setState({error:error.response.data || {detail:'Unexpected error happend'}})
            } )
            .finally(() => this.setState({
                isSaveOnProgress:false,
            }))

    };
    handleTheButton = (event) => {
        if(this.state.isEditing){
            this.doSave(event)
        }else{
            this.enableForm(event);
        }
    };
    updatePaymentMethod = ({ target }) => {
        this.setState({
            paymentMethod:target.value
        });
        this.fetchAccounts(target.value);
    };
    updatePaymentAccount = ({ target }) => {
        const theAccount = this.state.paymentAccounts ? this.state.paymentAccounts.find(({value:key}) => key === target.value) : undefined;
        if(theAccount){
            let updatedCurrencies = Object.assign({}, this.state.currencies);
            updatedCurrencies.reimbursementCurrency = theAccount.accountCurrency || "USD";
            this.setState({
                payTo: `${theAccount.bankName}, ${theAccount.city}, ${theAccount.state}`,
                paymentAccount:target.value,
                allowAllReimbursed: !theAccount.accountCurrency,
                currencies: updatedCurrencies
            })
        }
    }
    allowAdding = () => {
        this.setState({
            isAdding:true
        });
        this.enableForm();

    };
    updateCurrencies = (currencies) => this.setState({currencies});
    render(){
        if(this.state.error){
            return <div className="payments-details flexible horizontal aCenter jBetween">
                <div className="payments-details--inner-wrapper">
                    <h3>Payments Details</h3>
                    {this.state.error.message}
                </div></div>
        }else{
            return (
                <div>
                    {this.renderPaymentBox()}
                    { this.state.isPopupOpen && <Popup
                        title="No Payment Account"
                        onClose={() => {this.setState({isPopupOpen: false})}}
                    >
                        <p> You can't save payment details without choosing any payment account. Please, select another payment method.</p>
                    </Popup>
                    }
                </div>
            );
        }
    }
    renderPaymentBox (){
        const { expenseData } = this.props;
        const { isEditing, isAdding, paymentAccounts } = this.state;
        const paymentMethod = this.state.paymentMethods.find(({value:key}) =>
            key === this.state.paymentMethod
        );
        const paymentAccount = paymentAccounts ? paymentAccounts.find(({value:key}) =>
            key === this.state.paymentAccount
        ) : undefined;
        const { remittanceInfo = {remittanceAccount:paymentAccount} } = this.props.expenseData;
        const payTo = paymentAccounts ? (remittanceInfo
            && remittanceInfo.remittanceAccount
            && `${remittanceInfo.remittanceAccount.bankName}, ${remittanceInfo.remittanceAccount.city}, ${remittanceInfo.remittanceAccount.state}`)
            || this.state.payTo : '-';
        return (
            (expenseData.remittanceInfo && expenseData.remittanceInfo.remitMethodId) || isAdding
                ?
                <div className="payments-details flexible horizontal aCenter jBetween">
                    <div className="payments-details--inner-wrapper">
                        <h3>Payments Details</h3>

                        <div className="payments flexible">
                            {isEditing? (<Fragment>
                                <div className="text flexible vertical aStart">
                                    {this.state.paymentMethods.length ? (
                                            this.state.paymentMethods.length === 1 ? (
                                                <React.Fragment>
                                                    <span>Payment Method:</span>
                                                    { (paymentMethod && paymentMethod.label) }
                                                </React.Fragment>
                                            ):<SelectField
                                                name="payment-method"
                                                options={this.state.paymentMethods}
                                                helperText="Payment method:"
                                                onChange={this.updatePaymentMethod}
                                                value={this.state.paymentMethod }
                                                disabled={this.state.paymentMethods.length === 1}
                                            />) :
                                        <span>Payment Method:<div className="payment-details__spinner">
                                        <div className="Spinner "/>
                                    </div></span>}
                                </div>
                                <div className="text flexible vertical aStart">
                                    { paymentAccounts ?
                                        this.state.paymentAccounts.length  ? (
                                            this.state.paymentAccounts.length === 1 ? (
                                                <React.Fragment>
                                                    <span>Payment account:</span>
                                                    { (paymentAccount && paymentAccount.label) ||
                                                    (expenseData.remittanceInfo &&
                                                        expenseData.remittanceInfo.remittanceAccount &&
                                                        expenseData.remittanceInfo.remittanceAccount.accountName )}
                                                </React.Fragment>
                                            ):<SelectField
                                            name="payment-account"
                                            options={this.state.paymentAccounts}
                                            helperText="Payment account:"
                                            onChange={this.updatePaymentAccount}
                                            disabled={this.state.paymentAccounts.length === 1}
                                            value={ this.state.paymentAccount}
                                        />) : <span>Payment account:<div className="payment-details__spinner">
                                            <div className="Spinner "/>
                                        </div></span>
                                        : <span className="flexible vertical">Payment account:
                                            <p className="payment-accounts-info">No payment account</p>
                                        </span>
                                    }

                                </div>
                            </Fragment>) :  (<Fragment>
                                <div className="text flexible vertical aStart">
                                    <span>Payment Method:</span>
                                    { (paymentMethod && paymentMethod.label) ||
                                    (expenseData.remittanceInfo &&
                                        expenseData.remittanceInfo.remittanceAccount &&
                                        expenseData.remittanceInfo.remittanceAccount.accountType )}
                                </div>
                                <div className="text flexible vertical aStart">
                                    <span>Payment Account:</span>
                                    { (paymentAccount && paymentAccount.label) ||
                                    (expenseData.remittanceInfo &&
                                        expenseData.remittanceInfo.remittanceAccount &&
                                        expenseData.remittanceInfo.remittanceAccount.accountName )}
                                </div>
                            </Fragment>)}
                            <div className="text flexible vertical aStart">
                                <span>Mail to:</span>
                                <span>{payTo}</span>
                            </div>
                        </div>
                        <Currencies
                            isLabelOnly={!!(expenseData.reimbursementCurrency || '').replace(/(-)+/,'') || !isEditing}
                            onCurrenciesUpdate={this.updateCurrencies}
                            reimbursementCurrency={this.state.currencies ? this.state.currencies.reimbursementCurrency : null}
                            allowAllReimbursed={this.state.allowAllReimbursed}
                        />
                    </div>
                    {this.state.isSaveOnProgress ? <div className="Spinner"/> : <Button
                        dataKey="change_payment_details_button"
                        title={isEditing ? "Save" : "Change"}
                        disabled={this.props.isReadOnly}
                        simple
                        onClick={this.handleTheButton}
                    />}
                </div>
                :
                <div>
                    <h3>Payment Method</h3>
                    <Button
                        dataKey="select_payment_method_button"
                        title="Select Payment Method"
                        onClick={this.allowAdding}
                        simple
                    />
                </div>
        )
    }
}
