import React, { PureComponent } from "react";

import {
    Link,
    Icon,
    List,
    ListItem,
    Contacts,
    //CAROUSEL_TALK_CONTACTS_PHONES,
    CAROUSEL_TALK_CONTACTS_EMAILS,
    //DOMESTIC_CAROUSEL_TALK_CONTACTS_PHONES,
} from "components/common";
import {
    JnJContainer,
    JnJGridRow,
    JnJGridColumn,
} from "components/common/JnJGrid";
import mobilityConnect from "components/common/Images/mobilityConnect.svg";
import Typography from "@material-ui/core/Typography";

import Slider from "react-slick";
import Teams from 'components/common/Images/teams.png';

import "./style.scss";

export default class Accordion extends PureComponent {
    state = {
        selected: null,
    };

    settings = {
        className: "center",
        infinite: false,
        centerPadding: "10px",
        slidesToShow: 3,
        swipeToSlide: true,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1110,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    domesticVendorData = [
        {
            title: "Authorized Vendors",
            body: {
                description:
                    "The following is list contracted vendors and suppliers which are part of your authorized services.",
                content: [
                    {
                        title: "Relocation Management Company",
                        children: [
                            {
                                subTitle: "Sirva",
                                site: "www.sirva.com",
                                phone: "(800) 341-5648",
                            },
                        ],
                    },
                    {
                        title: "Movement of Household Goods",
                        children: [
                            {
                                subTitle: "New World Van Line",
                                site: "www.nwvl.com",
                                phone: "(800) 422-9300",
                            },
                            {
                                subTitle: "NelsonWesterberg",
                                site: "www.nwvl.com",
                                phone: "(800) 247-3800",
                            },
                        ],
                    },
                    {
                        title:
                            "Destination Home Purchase & Mortgage Closing Management Services",
                        children: [
                            {
                                subTitle: "John McGowan, Esq.",
                                site: null,
                                phone: "(866) 905-8600 ext. 102",
                            },
                        ],
                    },
                    {
                        title: "Mortgage Copmanies",
                        children: [
                            {
                                subTitle: "Premia",
                                site: "www.PremiaRelocationMortage.com",
                                phone: "(866) 591-0665",
                            },
                            {
                                subTitle: "Quicken Loans",
                                site: "www.RelocationQuickenLoans.com",
                                phone: "(800) 581-6683",
                            },
                            {
                                subTitle: "Case",
                                site:
                                    "https://www.chase.com/personal/mortage/relo-direct/jnj",
                                phone: "(800) 247-3800",
                            },
                        ],
                    },
                    {
                        title:
                            "Family Support & Spouse/Domestic Partner Career Assitance",
                        children: [
                            {
                                subTitle: "REA",
                                site: "www.reacareers.com",
                                phone: "(866) 216-3152",
                            },
                        ],
                    },
                ],
            },
        },
    ];

    setSelected = selected => {
        if (selected === this.state.selected) {
            this.setState({ selected: null });
        } else {
            this.setState({ selected });
        }
    };

    contactItem = (item, index) =>
        item.persons.map((person, pi) => (
            <div key={`${index}_person_${pi}`}>
                <h5 className="subtitle">{person.geoRegion}</h5>
                <h4 className="body-title">{person.company}</h4>

                <div className="info-block flexible">
                    {person.name || person.title ? (
                        <span className="body-contacts">
                            Contact: {person.name}
                            {person.title}
                        </span>
                    ) : null}
                    {person.phone ? (
                        <div className="body-phone flexible aCenter">
                            <Icon name="phone" width={20} height={20} />
                            {person.phone}
                        </div>
                    ) : null}
                    {person.email ? (
                        <div className="body-email flexible aCenter">
                            <Icon name="mailBlack" width={20} height={15} />
                            {person.email}
                        </div>
                    ) : null}
                </div>
            </div>
        ));

    contactContent = data => (
        <div>
            {Object.keys(data).map((key, index) => (
                <div className="accordion-body-item" key={key}>
                    <h5 className="subtitle">{key}</h5>
                    {data[key].map((item, index) =>
                        this.contactItem(item, index),
                    )}
                </div>
            ))}
        </div>
    );

    contactContent2 = data =>
        data &&
        Object.keys(data).map((key, index) =>
            this.contactItem(data[key], index),
        );

    handleResourceDownloadClick = doc => {
        if (this.props.handleDownloadClick && doc) {
            this.props.handleDownloadClick(
                doc.assetKey,
                doc.assetId,
                doc.fileType,
                doc.fileName,
            );
        }
    };

    resourceContent = () =>
        this.props.data.content ? (
            <div className="accordion-body animated fadeInDown flexible vertical">
                <List className="flexible vertical">
                    {this.props.data.content.map(doc => (
                        <ListItem
                            key={doc.documentId}
                            header={
                                <div className="document-name-section">
                                    <p className="document-name">
                                        {doc.description}
                                    </p>
                                </div>
                            }
                            onClick={() =>
                                this.handleResourceDownloadClick(doc)
                            }
                            middleContent={
                                <div className="document-category">
                                    {doc.fileType}
                                </div>
                            }
                            rightButtons={[
                                {
                                    name: "download",
                                },
                            ]}
                        />
                    ))}
                </List>
            </div>
        ) : null;

    contactContentRenderer = data =>
        data.map((item, index) => (
            <div key={index} className="accordion-body-item-static">
                <h5 className="title">{item.title}</h5>
                {item.children.map((child, key) => (
                    <div className="info-block flexible vertical" key={key}>
                        <h3>{child.subTitle}</h3>
                        {child.site ? (
                            <a href={child.site}>{child.site}</a>
                        ) : null}
                        <div className="body-phone flexible aCenter">
                            <Icon name="phone" width={20} height={20} />
                            {child.phone}
                        </div>
                    </div>
                ))}
            </div>
        ));

    contactAccordion = data =>
        data &&
        data.map((item, index) => (
            <div
                className={`accordion-item flexible vertical ${
                    this.state.selected === index ? "open" : ""
                }`}
                key={index}
            >
                <div
                    className="accordion-header flexible jBetween aCenter"
                    onClick={() => this.setSelected(index)}
                >
                    <span className="title">{item.title}</span>
                    <div className="icons-block">
                        <Icon
                            name={
                                this.state.selected === index ? "minus" : "plus"
                            }
                        />
                    </div>
                </div>
                <div className="accordion-body animated fadeInDown flexible vertical">
                    <p>{item.body.description}</p>
                    {this.contactContent(item.body.content)}
                </div>
            </div>
        ));

    mobConnectContent = () => (
        <div className="accordion-body animated fadeInDown flexible vertical">
            <div className="flexible horizontal jBetween">
                <Typography variant="body1">
                    There are several ways to contact and obtain support
                    regarding a move. Our advisors will provide guidance on
                    general inquiries, answer policy and initiation questions,
                    review move process, and general mobility questions.
                </Typography>
                <img src={mobilityConnect} alt="Mobility Connect Logo" />
            </div>
            <div className="accordion-body-item" key={1}>
                <JnJContainer>
                    <JnJGridRow>
                        {/* <JnJGridColumn style={{ marginRight: 20 }}>
                            <Contacts
                                className="contact mobConnect-one"
                                title="Connect By Phone:"
                                contacts={
                                    !this.props.domestic
                                        ? CAROUSEL_TALK_CONTACTS_PHONES
                                        : DOMESTIC_CAROUSEL_TALK_CONTACTS_PHONES
                                }
                            />
                        </JnJGridColumn> */}
                        <JnJGridColumn>
                            <Contacts
                                className="contact mobConnect-one-email"
                                title="Connect By Email:"
                                contacts={CAROUSEL_TALK_CONTACTS_EMAILS}
                            />
                        </JnJGridColumn>
                    </JnJGridRow>
                </JnJContainer>
            </div>
        </div>
    );

    regionalContent = () => (
        <div className="accordion-body animated fadeInDown flexible vertical">
            <p>
                The following individuals are the Regional Mobility Services
                Operations Escalation Contacts.
            </p>
            <div className="accordion-body-item" key={2}>
                <div className="info-block flexible horizontal grow">
                    {this.props.activeTabKey === "int" ? (
                        <div className="ColumnComponent regional-mobility">
                            <div className="column-component-body flexible horizontal grow ">
                                <Slider {...this.settings}>
                                    <div className="card">
                                        <h5 className="subtitle">
                                            North America & Latin America
                                        </h5>
                                        <h4 className="body-title">
                                            Susy Arellano
                                        </h4>
                                        <p>
                                            Senior Manager, Regional Operations
                                        </p>
                                        <p>
                                            <Icon
                                                name="phone"
                                                width={20}
                                                height={20}
                                            />
                                            +1 732-524-3115
                                        </p>
                                        <p>
                                            <Icon
                                                name="mailBlack"
                                                width={20}
                                                height={15}
                                            />
                                            <a href="mailto:SArellan@its.jnj.com">
                                                SArellan@its.jnj.com
                                            </a>
                                        </p>
                                        <p>
                                            <div className="team-area-left" >
                                                <img className={'move-type-icon'} src={Teams} alt=""/>
                                            </div>
                                            <div className="team-area-right" >
                                                <a  rel="noopener noreferrer" target="_blank" href="https://teams.microsoft.com/l/chat/0/0?users=SArellan@its.jnj.com">
                                                    Chat with Microsoft Teams
                                                </a>
                                            </div>
                                        </p>
                                    </div>
                                    <div className="card">
                                        <h5 className="subtitle">
                                            Europe, Middle East & Africa
                                        </h5>
                                        <h4 className="body-title">
                                            Valeria Harris
                                        </h4>
                                        <p>
                                            Senior Manager, Regional Operations
                                        </p>
                                        <p>
                                            <Icon
                                                name="phone"
                                                width={20}
                                                height={20}
                                            />
                                            +44 7771 940195
                                        </p>
                                        <p>
                                            <Icon
                                                name="mailBlack"
                                                width={20}
                                                height={15}
                                            />
                                            <a href="mailto:rayoubi@ITS.JNJ.com">
                                                vharris2@its.jnj.com
                                            </a>
                                        </p>
                                        <p>
                                            <div className="team-area-left" >
                                                <img className={'move-type-icon'} src={Teams} alt=""/>
                                            </div>
                                            <div className="team-area-right" >
                                                <a  rel="noopener noreferrer" target="_blank" href="https://teams.microsoft.com/l/chat/0/0?users=rayoubi@ITS.JNJ.com">
                                                    Chat with Microsoft Teams
                                                </a>
                                            </div>
                                        </p>
                                    </div>
                                    <div className="card">
                                        <h5 className="subtitle">
                                            Asia Pacific
                                        </h5>
                                        <h4 className="body-title">
                                            Jayne Line
                                        </h4>
                                        <p>
                                            Senior Manager, Regional Operations
                                        </p>
                                        <p>
                                            <Icon
                                                name="phone"
                                                width={20}
                                                height={20}
                                            />
                                            +65 69186774
                                        </p>
                                        <p>
                                            <Icon
                                                name="mailBlack"
                                                width={20}
                                                height={15}
                                            />
                                            <a href="mailto:jline1@its.jnj.com">
                                                jline1@its.jnj.com
                                            </a>
                                        </p>
                                        <p>
                                            <div className="team-area-left" >
                                                <img className={'move-type-icon'} src={Teams} alt=""/>
                                            </div>
                                            <div className="team-area-right" >
                                                <a  rel="noopener noreferrer" target="_blank" href="https://teams.microsoft.com/l/chat/0/0?users=jline1@its.jnj.com">
                                                    Chat with Microsoft Teams
                                                </a>
                                            </div>
                                        </p>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    ) : (
                        <div className="ColumnComponent ">
                            <div className="column-component-body flexible horizontal grow ">
                                <div className="card">
                                    <h4 className="body-title">
                                        Sue Russell
                                    </h4>
                                    <p>
                                        Mobility Operations Lead
                                    </p>
                                    <p>
                                        <Icon
                                            name="phone"
                                            width={20}
                                            height={20}
                                        />
                                        +1 732-524-3311
                                    </p>
                                    <p>
                                        <Icon
                                            name="mailBlack"
                                            width={20}
                                            height={15}
                                        />
                                        <a href="mailto:SArellan@its.jnj.com">
                                            srussel1@its.jnj.com
                                        </a>
                                    </p>
                                    <p>
                                        <div className="team-area-left" >
                                            <img className={'move-type-icon'} src={Teams} alt=""/>
                                        </div>
                                        <div className="team-area-right" >
                                            <a  rel="noopener noreferrer" target="_blank" href="https://teams.microsoft.com/l/chat/0/0?users=SArellan@its.jnj.com">
                                                Chat with Microsoft Teams
                                            </a>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    globalServicesContent = () => (
        <div className="accordion-body animated fadeInDown flexible vertical">
            <div className="flexible vertical">
                <p>
                    For questions or inquiries relating to Human Resources,
                    Payroll, and/or Procurement, please reach out to Global
                    Services. The AskGS portal is your go-to online resource for
                    questions and support related to HR and Payroll.
                </p>
                <Link
                    text="Visit the AskGS Portal"
                    externalLink="http://askgs.jnj.com"
                />
            </div>
        </div>
    );

    regionalMobilityAdvisory = () => (
        <div className="accordion-body animated fadeInDown flexible vertical">
            <p>
                The following individuals are the Regional Mobility Advisory
                Services Contacts.
            </p>
            <div className="accordion-body-item" key={3}>
                <div className="info-block flexible horizontal grow">
                    {this.props.activeTabKey === "int" ? (
                        <div className="ColumnComponent regional-mobility">
                            <div className="column-component-body flexible horizontal grow ">
                                <Slider {...this.settings}>
                                    <div className="card">
                                        <h5 className="subtitle">
                                            North America & Latin America
                                        </h5>
                                        <h4 className="body-title">
                                            Nina Wortzel-Hoffman
                                        </h4>
                                        <p>Lead Mobility Advisory Services</p>
                                        <p>
                                            <Icon
                                                name="phone"
                                                width={20}
                                                height={20}
                                            />
                                            +1 732 524-2334
                                        </p>
                                        <p>
                                            <Icon
                                                name="mailBlack"
                                                width={20}
                                                height={15}
                                            />
                                            <a href="mailto:nhoffma@its.jnj.com">
                                                nhoffma@its.jnj.com
                                            </a>
                                        </p>
                                        <p>
                                            <div className="team-area-left" >
                                                <img className={'move-type-icon'} src={Teams} alt=""/>
                                            </div>
                                            <div className="team-area-right" >
                                                <a  rel="noopener noreferrer" target="_blank" href="https://teams.microsoft.com/l/chat/0/0?users=nhoffma@its.jnj.com">
                                                    Chat with Microsoft Teams
                                                </a>
                                            </div>
                                        </p>
                                    </div>
                                    <div className="card">
                                        <h5 className="subtitle">
                                            Europe, Middle East & Africa
                                        </h5>
                                        <h4 className="body-title">
                                            Anna Sawicz
                                        </h4>
                                        <p>Lead Mobility Advisory Services</p>
                                        <p>
                                            <Icon
                                                name="phone"
                                                width={20}
                                                height={20}
                                            />
                                            +41  58 231 56 68
                                        </p>
                                        <p>
                                            <Icon
                                                name="mailBlack"
                                                width={20}
                                                height={15}
                                            />
                                            <a href="mailto:asawicz@its.jnj.com">
                                                asawicz@its.jnj.com
                                            </a>
                                        </p>
                                        <p>
                                            <div className="team-area-left" >
                                                <img className={'move-type-icon'} src={Teams} alt=""/>
                                            </div>
                                            <div className="team-area-right" >
                                                <a  rel="noopener noreferrer" target="_blank" href="https://teams.microsoft.com/l/chat/0/0?users=asawicz@its.jnj.com">
                                                    Chat with Microsoft Teams
                                                </a>
                                            </div>
                                        </p>
                                    </div>
                                    <div className="card">
                                        <h5 className="subtitle">
                                            Asia Pacific
                                        </h5>
                                        <h4 className="body-title">
                                            Ruoh Sze (Rachel) Tay
                                        </h4>
                                        <p>Lead Mobility Advisory Services</p>
                                        <p>
                                            <Icon
                                                name="phone"
                                                width={20}
                                                height={20}
                                            />
                                            +65 6918 7222
                                        </p>
                                        <p>
                                            <Icon
                                                name="mailBlack"
                                                width={20}
                                                height={15}
                                            />
                                            <a href="mailto:RTay@ITS.JNJ.com">
                                                RTay@ITS.JNJ.com
                                            </a>
                                        </p>
                                        <p>
                                            <div className="team-area-left" >
                                                <img className={'move-type-icon'} src={Teams} alt=""/>
                                            </div>
                                            <div className="team-area-right" >
                                                <a  rel="noopener noreferrer" target="_blank" href="https://teams.microsoft.com/l/chat/0/0?users=RTay@ITS.JNJ.com">
                                                    Chat with Microsoft Teams
                                                </a>
                                            </div>
                                        </p>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    ) : (
                        <div className="ColumnComponent ">
                            <div className="column-component-body flexible horizontal grow ">
                                <div className="card">
                                    <h4 className="body-title">
                                        Susy Arellano
                                    </h4>
                                    <p>
                                        Leader, People Experience and Executive
                                        Mobility
                                    </p>
                                    <p>
                                        <Icon
                                            name="phone"
                                            width={20}
                                            height={20}
                                        />
                                        +1 732-524-3115
                                    </p>
                                    <p>
                                        <Icon
                                            name="mailBlack"
                                            width={20}
                                            height={15}
                                        />
                                        <a href="mailto:SArellan@its.jnj.com">
                                            SArellan@its.jnj.com
                                        </a>
                                    </p>
                                    <p>
                                        <div className="team-area-left" >
                                            <img className={'move-type-icon'} src={Teams} alt=""/>
                                        </div>
                                        <div className="team-area-right" >
                                            <a  rel="noopener noreferrer" target="_blank" href="https://teams.microsoft.com/l/chat/0/0?users=SArellan@its.jnj.com">
                                                Chat with Microsoft Teams
                                            </a>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    lineItem = (index, title, content) => (
        <div
            className={`accordion-item flexible vertical ${
                this.state.selected === index ? "open" : ""
            }`}
            key={index}
        >
            <div
                className="accordion-header flexible jBetween aCenter"
                onClick={() => this.setSelected(index)}
            >
                <span className="title">{title}</span>
                <div className="icons-block">
                    <Icon
                        name={this.state.selected === index ? "minus" : "plus"}
                    />
                </div>
            </div>
            {content()}
        </div>
    );

    render() {
        return {
            contact: (
                <div className="Accordion">
                    {this.props.contactData
                        ? this.contactAccordion(this.props.contactData)
                        : null}
                    {this.lineItem(
                        100,
                        "Mobility Connect",
                        this.mobConnectContent,
                    )}
                    {this.lineItem(
                        101,
                        "J&J Regional Mobility Services",
                        this.regionalContent,
                    )}
                    {/* {this.lineItem(
                        102,
                        "Regional Mobility Advisory Services",
                        this.regionalMobilityAdvisory,
                    )} */}
                    {this.lineItem(
                        103,
                        "Global Services",
                        this.globalServicesContent,
                    )}
                </div>
            ),
            resource: this.props.data ? (
                <div className="Accordion">
                    {this.lineItem(
                        this.props.data.documentId,
                        this.props.data.title,
                        this.resourceContent,
                    )}
                </div>
            ) : null,
        }[this.props.mode];
    }
}
