export const generalMessages = [
  "Talent Mobility Services require 2 levels of approval; the first from the Hiring Manager and the second from the Hiring Manager's manager.",
  // "Please review the information below and update if necessary"
];

export const partners = [
  {
      label: 'McGrath, Kelly',
      subLabel: 'GLOBAL HEAD OF DIGITAL, TALENT AC…',
      email: 'McGrath@its.jnj.com',
      wwid: '11111111',
      manager: "Katalvarnia, Kellyn"
  },
  {
      label: 'Katalvarnia, Kellyn',
      subLabel: 'CUSTOMER CARE ADVISOR',
      email: 'Katalvarnia@its.jnj.com',
      wwid: '2222222222',
      manager: 'Kelson, Jeremiah'
  },
  {
      label: 'Kelson, Jeremiah',
      subLabel: 'QC TECHNICIAN',
      email: 'Kelson@its.jnj.com',
      wwid: '3333333333',
      manager: 'DellaPeitro, Kyle'
  },
  {
      label: 'DellaPeitro, Kyle',
      subLabel: 'COMMUNICATION LEAD',
      email: 'DellaPeitro@its.jnj.com',
      wwid: '4444444444',
      manager: 'McGrath, Kelly',
  }
];
