import * as React from "react";
import { SelectionCard } from "components/common";
import LongTermTier1 from "../EligibleMove/longTermTier1";
import LongTermTier2 from "../EligibleMove/longTermTier2";
import { Icon } from "components/common";
import { RouterProps } from "types";
import "./style.scss";

const PayGrade = (props: RouterProps) => {
    const clickHandler = (payGrade: string, payGradeValue: string): void => {
        props.history.push({
            state: {
                ...props.location.state,
                payGrade,
                payGradeValue: payGradeValue,
            },
        });
    };

    const backHandler = () => {
        if (props.fromAssignment) {
            props.history.push({
                state: {
                    ...props.location.state,
                    assignment: "",
                },
            });
        } else {
            props.history.push({
                state: {
                    ...props.location.state,
                    "fifty-percent": "",
                },
            });
        }
    };

    if (
        props.location.state["payGrade"] &&
        props.location.state["payGrade"] === "31-"
    ) {
        return (
            <LongTermTier2 history={props.history} location={props.location} backState={"payGrade"} />
        );
    } else if (
        props.location.state["payGrade"] &&
        props.location.state["payGrade"] === "40+"
    ) {
        return (
            <LongTermTier1 history={props.history} location={props.location} backState={"payGrade"} />
        );
    }
    return (
        <article className="PayGradeContainer">
            <div className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </div>
            <h1 className="section-title">
                What is the pay grade of the employee?
            </h1>
            <section className="CardSection">
                <SelectionCard
                    type="regularSelectionCard"
                    backgroundColor="white"
                    title="Paygrade"
                    content="40 and above"
                    step="pay-grade"
                    onClick={() => clickHandler("40+","PayGrade41")}
                />
                <SelectionCard
                    type="regularSelectionCard"
                    backgroundColor="white"
                    title="Paygrade"
                    content="31 and below"
                    step="pay-grade"
                    onClick={() => clickHandler("31-","PayGrade30")}
                />
            </section>
        </article>
    );
};

export default PayGrade;
