import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";

import "./style.scss";

class InfoKnockOutCard extends PureComponent {
    render() {
        const { className = "", title } = this.props;
        return (
            <div
                className={`InfoKnockOutCard ${className}`}
                style={{ backgroundColor: this.props.backgroundColor }}
                data-key={this.props.dataKey}
            >
                <div className="info-card-block flexible vertical">
                    <h2>
                        {typeof title !== "string"
                            ? title
                            : title.split("/n").map(text => (
                                  <React.Fragment key={text}>
                                      {text}
                                      <br />
                                  </React.Fragment>
                              ))}
                    </h2>

                    <p className="truncate threeLines">{this.props.content}</p>
                </div>
            </div>
        );
    }
}

export default withRouter(InfoKnockOutCard);
