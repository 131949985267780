import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { userService } from 'services';
import { getUserProfile, getRelocations } from 'selectors';

import { Icon, Asset } from 'components/common';

import './style.scss';
import { SHORT_DATE_FORMAT } from '../../consts';
import { getReloDatePath, getMoveType } from '../../utils';

const mapStateToProps = (state) => ({
    userProfile: getUserProfile(state),
    relocations: getRelocations(state),
});

const mapDispatchToProps = {
    setActiveRelocation: userService.actions.setActiveRelocation,
};

class YourMoves extends PureComponent{

    componentDidMount(){
        if(this.props.relocations && Array.isArray(this.props.relocations)) {

            if (!this.props.relocations.length) {
                return this.props.history.push('/profile/international/explore');
            }

            if (this.props.relocations.length === 1) {
                this.setRelocation(this.props.relocations[0]);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { relocations } = nextProps;
        if(!this.props.relocations && Array.isArray(relocations)) {
            if (!relocations.length){//TODO add a check for wwid-only log-in
                this.props.history.push('/profile/international/explore');
            }
            else if (relocations.length === 1){
                this.setRelocation(relocations[0]);
            }
        }
    }

    decodeSearchParams = (searchParams) => {
        if(!searchParams) {
            return {};
        }
        const queryStringArr = searchParams.substring(1).split('&');

        const params = {};
        for (let i = 0; i < queryStringArr.length; i++) {
            const pair = queryStringArr[i].split('=');
            params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return params;
    };

    setRelocation = (relocation) => {
        const relocationId = relocation.relocationId;
        const moveType = getMoveType(relocation);
        this.props.setActiveRelocation(this.props.userProfile.email, relocationId)
            .then((resp) => {
                const { redirect } = this.decodeSearchParams(this.props.location.search);
                if(redirect) {
                    this.props.history.push(`/${redirect}`);
                } else {
                    this.props.history.push('/profile/' + moveType + getReloDatePath(moveType, relocation));
                }
            });
    };

    generateCountries = () => (this.props.userProfile.email && !this.props.userProfile.isJibeUnassignedUser && this.props.relocations  ?
        this.props.relocations.map(relocation => {
            if(relocation.addresses) {
                const apropriateAddress = relocation.addresses.find(address => address.addressType === "NewWork" || address.addressType === "NewHome");

                return (
                    <div
                        className="Country flexible horizontal aCenter jBetween"
                        key={relocation.relocationId}
                        onClick={() => {
                            this.setRelocation(relocation);}}
                    >
                        {
                            apropriateAddress ?
                            <div className="image flexible horizontal aCenter">
                                <div className="img">
                                    <Asset assetType="COUNTRY_FLAG" assetId={apropriateAddress.country} maxWidth={200} />
                                </div>
                                <h4>{apropriateAddress.stateDescription}</h4>
                            </div>
                            :
                            relocation.assignmentHeader ?
                            <div className="image flexible horizontal aCenter">
                                <div className="img">
                                    <Asset assetType="COUNTRY_FLAG" assetId={relocation.assignmentHeader.hostCountry.isoCode}  maxWidth={200} />
                                </div>
                                <h4>{relocation.assignmentHeader.hostCountry.name}</h4>
                            </div> : ''
                        }
                        <div className="text flexible horizontal">
                            <div>
                                {
                                    relocation.assignmentHeader && relocation.assignmentHeader.estimatedStartDate ?
                                    <span className="flexible vertical">
                                        Start Date:
                                        <h3>{ moment(relocation.assignmentHeader.estimatedStartDate).format(SHORT_DATE_FORMAT) }</h3>
                                    </span> : ''
                                }
                            </div>
                            <div className="text-end-date">
                                {
                                    relocation.assignmentHeader && relocation.assignmentHeader.estimatedEndDate ?
                                    <span className="flexible vertical">
                                        End Date:
                                        <h3>{ moment(relocation.assignmentHeader.estimatedEndDate).format(SHORT_DATE_FORMAT) }</h3>
                                    </span> : ''
                                }
                            </div>
                            <div className="arrow-left flexible aCenter jEnd"><Icon name="chevronRed" /></div>
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        })
        : <div className="flexible aCenter jCenter"><div className="Spinner" /></div>
    );

    render(){
        if (!this.props.userProfile) {
            return (<div className="flexible aCenter jCenter"><div className="Spinner" /></div>);
        }

        if (this.props.userProfile.role){
            return (
                <section className="YourMoves">
                    <h1>Your Moves</h1>
                    <p>
                        Select which move you’d like to see details for…
                    </p>
                    <div className="countries-block" data-key="my-moves">
                        {this.generateCountries()}
                    </div>
                </section>
            )
        }

        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(YourMoves));
