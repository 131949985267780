// @flow

import React, { PureComponent } from 'react';

import "./styles.scss";

type TabItemProps = {
    label: string,
    tabKey: string,
    styles?: Object,
    className?: string,
    onClick: Function,
    isActive: boolean,
};

export default class TabItem extends PureComponent<TabItemProps> {

    handleClick = () => {
        const {
            isActive,
            onClick,
            tabKey
        } = this.props;

        (!isActive && onClick) && onClick({ key: tabKey });
    };

    render() {
        const classNames = `${this.props.isActive ? 'active' : ''} ${this.props.className || ''}`;
        return (
            <div
                data-key={this.props.tabKey}
                className={`TabItem flexible aCenter ${classNames}`}
                style={this.props.styles || undefined}
                onClick={this.handleClick}
            >
                { this.props.label }
            </div>
        );
    }
}