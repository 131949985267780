import React, { useState, useEffect } from "react";
import { Icon, ContactMobility2, InitiateNameAutoComplete, RadioButton, SpinnerWrapper } from "components/common";
import { RouterProps } from "types";
import { connect } from "react-redux";
import { moveInitiationService, errorService } from "services";
import { useDispatch } from "react-redux";
import GlobalAssignment from "./GlobalAssignment";
import { getUserProfile } from "selectors";
import {NonJnJEmppErrorModal} from "components/common/Modal";
import "./styles.scss";

const mapStateToProps = state => ({
    userProfile: getUserProfile(state),
});

const mapDispatchToProps = {
    toggleErrorModal: errorService.actions.toggleErrorModal
};

const PreIntiateMoveIntro = (props: RouterProps) => {
    const dispatch = useDispatch();
    const userProfileShortName = props.userProfile.shortName;
    const [initiateNames, setInitiateNames] = useState([]);
    const [selectedName, setSelectedName] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [continueButtondisabled, setContinueButtondisabled] = useState(true);
    const disableContinueBtnHandler = (continueButtondisabled: boolean): void =>setContinueButtondisabled(continueButtondisabled);
    const [inputName, setInputName] = useState("");
    const [category, setCategory] = useState("wwid");
    const [inputTitle, setInputTitle] = useState("Employee WWID");
    const [inputPlaceholder, setInputPlaceholder] = useState("Employee WWID");
    const [isNonJnJEmpp, setIsNonJnJEmpp] = useState(false);

    useEffect(() => {
        if( selectedName['displayName'] !== undefined && selectedName['displayName']!=="" ){
            disableContinueBtnHandler(false);
            setInputName(selectedName['displayName']);
        } else {
            disableContinueBtnHandler(true);
        }
    },[selectedName]);
    
    const changeHandler = (name: string) => {
        setInputName(name);
        if (name == "") {
            setSelectedName({});
        }
        if (name.length > 2) {
            setIsLoading(true);
            dispatch(moveInitiationService.actions.fetchEmployeeByCategory(category,name))
                    .then(names => {
                        setInitiateNames(names.result);
                        setIsLoading(false);
                    })
                    .catch(err => console.log(err));
        } else {
            if (initiateNames.length) {
                setInitiateNames([]);
            }
        }
    };
    const clearAutoComplete = () => [];
    
    const handleClick = (selectedName: any) => {
        setSelectedName(selectedName);
        setIsLoading(false);
    }

    const categoryOnChangeHandlar = (categoryFlag: string, title: string, placeholder: string) => {
        setInputName("");
        setSelectedName({});
        setInitiateNames([]);
        if (categoryFlag !== "") {
            setCategory(categoryFlag);
            setInputTitle(title);
            setInputPlaceholder(placeholder);
        } else {
            setCategory("wwid");
            setInputTitle("Employee WWID");
            setInputPlaceholder("Employee WWID");
        }
    };

    const saveEmppInfoDetails = (location) => {
        setIsLoadingSave(true);
        var isMyObjectEmpty = !Object.keys(selectedName).length;
        if(!isMyObjectEmpty) {
            dispatch(moveInitiationService.actions.setPreInitEmppInfoDetails(selectedName)).then(data => {
                setIsLoadingSave(false);              
                if(data.success) {
                    props.history.push("non-competitive-initiation", {
                           fromIntro: true,
                           "preIntEmppDetails": selectedName,
                           employeeInfo: data.result,
                    });
                } else {
                    setIsNonJnJEmpp(true);
                    return props.toggleErrorModal(false, '', '');
                }
            })
            .catch(err => console.log(err));
        } else {
            setIsLoadingSave(false);
            navigateTo("reload");
        }
    }

    const navigateTo = (location: string) => {
        switch (location) {
            case 'continue':
                saveEmppInfoDetails("saved");
                break;
            case 'reload':
                props.history.push('/pre-initiate-move');
                break;
        }
    };

    return (
        <section className="pre-initiate-move-container">
            { isNonJnJEmpp && <NonJnJEmppErrorModal
                open={true}
                onClose={() => {setIsNonJnJEmpp(false)}}
                />
            }
            <SpinnerWrapper show={isLoadingSave}>
                <div className="pre-initiation-intro-screen">
                <h1>Ok {userProfileShortName}, let’s get started!</h1>
                <div className="top-content">
                    <div className="left-info">
                        <p>
                            As a People Leader we need to ask you a few questions to ensure we direct you to the right support to manage your move.​
                        </p>
                        <p>
                            First, we’d like to know the name of the employee you are hoping to move.
                        </p>
                        <p className="select">You can search by (Select One):</p>
                        <div className="options">
                            <div className="option">
                                <RadioButton
                                    checked={category === "wwid"}
                                    onChange={() => categoryOnChangeHandlar("wwid","Employee WWID","Employee WWID")}
                                />
                                <div className="titles">
                                    <p className="title">
                                        WWID
                                    </p>
                                </div>
                            </div>
                            <div className="option">
                                <RadioButton
                                    checked={category === "name"}
                                    onChange={() => categoryOnChangeHandlar("name","Employee first and last name","Employee first and last name")}
                                />
                                <div className="titles">
                                    <p className="title">First and Last Name</p>
                                </div>
                            </div>
                            <div className="option">
                                <RadioButton
                                    checked={category === "email"}
                                    onChange={() => categoryOnChangeHandlar("email","Employee Email Address","Employee Email Address")}
                                />
                                <div className="titles">
                                    <p className="title">Email Address</p>
                                </div>
                            </div>
                        </div>
                        <section className="input-select-container">
                            <InitiateNameAutoComplete
                                required
                                isLoading={isLoading}
                                placeholder={inputTitle}
                                inputTitle={inputPlaceholder}
                                clearAutoComplete={clearAutoComplete}
                                suggestions={initiateNames}
                                handleClick={handleClick}
                                style={{ marginTop: 38 }}
                                onChange={changeHandler}
                                value={inputName}
                            />
                        </section>
                        <p className="general-info">
                            If you're looking for general information about the move process, please contact Mobility Connect. If you are experiencing an issue with the technology, please contact technical support.
                        </p>
                        <button
                            className="submit-button"
                            disabled={continueButtondisabled}
                            onClick={() => {navigateTo("continue")}}
                        >
                            Let's go!
                        </button>
                    </div>
                    <div className="right-info">
                        <div>
                            <Icon name="timeBlue" />
                            <span className="text">How long will this take you?</span>
                        </div>
                        <p>
                            This will take about 10 minutes.
                        </p>
                    </div>
                </div>
                {/* <hr className="line" />
                <div className="contactmobility-wrapper">
                    <ContactMobility2 isTechSupport={true} /> 
                </div> */}
            </div>
            </SpinnerWrapper>
        </section>
    );
};

export default connect(mapStateToProps,mapDispatchToProps)(PreIntiateMoveIntro);
