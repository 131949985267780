import React from 'react';
import { CallbackComponent } from 'redux-oidc';
import { userService } from 'services';
import { appInsightsTracking } from "../../App";
import { clearSessionStorage } from "utils";

const successCallback = (user) => {
    console.log("Auth Callback success");
    localStorage.setItem('authenticated', true);

    if (user && user.state && user.state.redirectUrl) {
        appInsightsTracking.trackEvent({ name: 'UICallbackLogin', properties: user.profile });
        appInsightsTracking.flush(true);
        window.location.replace(user.state.redirectUrl);
    }
};

export default function Callback () {
    return (
        <CallbackComponent
            userManager={userService.userManager}
            successCallback={successCallback}
            errorCallback={error => {
                const newError = new Error(error);
                newError.message = 'uiAuthCallbackError: ' + newError.message + ' ' + window.location.href;

                appInsightsTracking.trackException({ error: newError });
                window.localStorage.clear();
                clearSessionStorage();
                console.error("Auth Callback error="+newError);

                alert('Something went wrong during the Authorization.');
                window.location.replace('/');
            }}
        >
            <React.Fragment>
                <div className="Spinner"/>
            </React.Fragment>
        </CallbackComponent>
    );
}
