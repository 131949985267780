import React, { Component } from "react";
import {
    TextWithImage,
    Accordion,
    MidSectionComponent,
    SectionHeading,
} from "components/common";
import { IntArivalQuestionsData } from "./IntArivalQuestionsData";
import "./style.scss";
import MobilityConnectBlock from "../../MobilityConnectBlock";

export default class IntArivalStep extends Component {
    generateIntArivalStepQuestionsData = () =>
        IntArivalQuestionsData.map((item, index) => ({
            props: {
                title: (
                    <div className="title flexible horizontal aCenter">
                        <div className="iconblock flexible jCenter aCenter">
                            <img src={item.icon} alt="" />
                        </div>
                        <p className="title-text">{item.title}</p>
                    </div>
                ),
                uniqueKey: index,
            },
            content: (
                <div className="dom-expl-first-step-info-content">
                    {item.content}
                </div>
            ),
        }));

    render() {
        return (
            <div className="hr-IntArivalQuestionsData">
                <TextWithImage
                    subtitle="Make a Strong Start"
                    title="How to help employees transition into a new office"
                    content={
                        <p>
                            Living and working at a new location can be both an
                            exciting and challenging time for the employee and
                            their family. It is a time of transition and will
                            require adjusting to a new environment. The Manager
                            has an important role in helping to ensure a
                            positive initial transition.
                        </p>
                    }
                    assetType="hr_top_content_image"
                    assetId="int_arival"
                />

                <MidSectionComponent
                    title="The Role of the Host Manager on Arrival"
                    hideDivider="true"
                    col1={
                        <div className="flexible vertical">
                            <p className="step-number">Step 1</p>
                            <h3>Establish Goals</h3>
                            <p>
                                The Host Manager is responsible for translating
                                development goals provided by the home manager
                                into meaningful responsibilities that will
                                broaden employee’s skills and capabilities in
                                the new location. Ongoing communication and
                                planning between Home and Host Managers is
                                important to a successful assignment and the
                                transition to a next role.
                            </p>
                        </div>
                    }
                    col2={
                        <div className="flexible vertical  pad-left">
                            <p className="step-number">Step 2</p>
                            <h3>Support Adjustment Period</h3>
                            <p>
                                Facilitate introductions to the team and other
                                individuals to help the employee integrate to
                                the new working environment. Remember to allow
                                the employee time to acclimate to their new
                                location and periodically inquire how they are
                                doing with their transition.
                            </p>
                        </div>
                    }
                    col3={
                        <div className="flexible vertical  pad-left">
                            <p className="step-number">Step 3</p>
                            <h3>Coach & Develop Employee</h3>
                            <p>
                                Provide ongoing counsel, guidance, and feedback
                                to support learning objectives of employee
                                throughout the move. Assess progress against
                                development goals and make adjustments as
                                needed.
                            </p>
                        </div>
                    }
                />

                <div className="flexible dom-expl-question-part">
                    <div className="flexible vertical dom-expl-questions">
                        <div className="question-part">
                            <SectionHeading
                                eyebrow="On Arrival"
                                title="Common Questions"
                                className="section-heading mbottom-40"
                            />
                            <div className="dom-expl-first-step-questions">
                                <Accordion
                                    data={this.generateIntArivalStepQuestionsData()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flexible vertical dom-expl-mobility">
                        <MobilityConnectBlock />
                    </div>
                </div>
            </div>
        );
    }
}
