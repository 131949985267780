import React, { PureComponent } from 'react';

import OrderedList from './OrderedList';
import TextsWithUrl from './TextsWithUrl';

import './style.scss';



export default class TextComponent extends PureComponent{

    generateOrderedList = () => (
      this.props.orderedData.map((item, index)=> (
          <OrderedList
              key={index}
              order={index + 1}
              body={item}
          />
       ))
    );

    generateTextsWithUrl = () => ( <TextsWithUrl textsWithUrlData={this.props.textsWithUrlData} /> );

    render(){
        return(
            <div className="TextComponent">
                <div className="text-component-block">
                    <h2>{this.props.title}</h2>
                    <div className="text-component-body">
                        {
                            this.props.orderedData &&
                            <div className="ordered-list-components flexible wrap horizontal jAround">
                                { this.generateOrderedList() }
                            </div>
                        }
                        {
                            this.props.textsWithUrlData &&
                            <div className="texts-with-url vertical flexible">
                                { this.generateTextsWithUrl() }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}