import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { InfoCard, Icon } from "../../components/common";
import {
    userService,
    assignmentHeaderService,
    taxMilestonesService,
} from "services";
import { getUserProfile, getAssignmentHeader } from "selectors";
import { getReloDatePath } from "../../utils";
import { USER_ROLES } from "consts";

import "./style.scss";

const infoCardColors = {
    manager: "#000099",
    employee: "#cc0099",
};

const mapStateToProps = state => ({
    user: getUserProfile(state),
    getAssignmentHeader: getAssignmentHeader(state),
});

const mapDispatchToProps = {
    getAssignmentHeader: assignmentHeaderService.actions.getAssignmentHeader,
    getTaxMilestones: taxMilestonesService.actions.getTaxMilestones,
    ...userService.actions,
};

class IdentificationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logged: false,
            isResetRoleMode: 0,
        };
    }

    onHiringInfoCardClick() {
        this.setState({
            logged: true,
            isResetRoleMode: this.state.isResetRoleMode === 1 ? 2 : 0,
        });
        this.props
            .setRole({
                email: this.props.user.email,
                role: USER_ROLES.hiring_manager,
            })
            .then(data => {
                data &&
                    this.setState({
                        logged: false,
                        isResetRoleMode:
                            this.state.isResetRoleMode === 1 ? 2 : 0,
                    });
            });
    }

    onEmployeeInfoCardClick() {
        this.setState({
            logged: true,
            isResetRoleMode: this.state.isResetRoleMode === 1 ? 2 : 0,
        });
        this.props
            .setRole({
                email: this.props.user.email,
                role: USER_ROLES.employee,
            })
            .then(data => {
                data &&
                    this.setState({
                        logged: false,
                        isResetRoleMode:
                            this.state.isResetRoleMode === 1 ? 2 : 0,
                    });
            });
    }

    redirectToStep(relocationId) {
        this.props.getAssignmentHeader(relocationId).then(res => {
            if (res && res.result) {
                const userProfile = res.result;
                if (
                    userProfile.homeCountry &&
                    userProfile.homeCountry.isoCode &&
                    userProfile.hostCountry &&
                    userProfile.hostCountry.isoCode
                ) {
                    this.props.getTaxMilestones(
                        userProfile.homeCountry.isoCode,
                        userProfile.hostCountry.isoCode,
                    );
                }
                const ah = { assignmentHeader: res.result };
                const moveType = this.props.user.currentRelocationIsDomestic
                    ? "domestic"
                    : "international";
                this.props.history.push(
                    "/profile/" + moveType + getReloDatePath(moveType, ah),
                );
            } else {
                this.props.history.push("/profile/international/explore");
            }
        });
    }

    componentWillMount() {
        if (
            this.props.user.email &&
            !this.props.user.isJibeUser &&
            this.props.match.params.switchrole &&
            this.props.match.params.switchrole === "reset-role" &&
            !this.state.isResetRoleMode
        ) {
            this.setState({ isResetRoleMode: 1 });
            this.redirectToRoute(this.props);
            return;
        }
        this.redirectToRoute(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (
            !this.props.user.role &&
            nextProps.user.role &&
            !this.props.user.email &&
            nextProps.user.email &&
            !nextProps.user.isJibeUser &&
            nextProps.match.params.switchrole &&
            nextProps.match.params.switchrole === "reset-role" &&
            !this.state.isResetRoleMode
        ) {
            this.setState({ isResetRoleMode: 1 });
            this.redirectToRoute(this.props);
            return;
        }

        if (this.state.isResetRoleMode !== 1) {
            this.redirectToRoute(nextProps);
        }
    }

    redirectToRoute(properties) {
        // if (!properties.user.role) return;
        if (properties.user.role === USER_ROLES.hiring_manager) {
            properties.history.push("/profile");
        } else {
            // if (properties.user.currentRelocationId)
            //     this.redirectToStep(properties.user.currentRelocationId);
            // else properties.history.push("/your-moves");
            this.onHiringInfoCardClick();
        }
    }

    render() {
        return (this.props.user.email &&
            !this.props.user.role &&
            !this.props.user.isJibeUser) ||
            this.state.isResetRoleMode === 1 ? (
            <div className="IdentificationPage withStretch">
                <div className="flexible grow">
                    <div className="flexible grow jCenter identification-content">
                        <InfoCard
                            dataKey="hiring_manager"
                            backgroundColor={infoCardColors.manager}
                            title={
                                <p className="InfoCardHeader">
                                    I am a<br /> People Leader
                                    <span className="arrowRight">
                                        <Icon name="chevronRed" />
                                    </span>
                                </p>
                            }
                            content="I am looking to move an individual on my team and need to learn more about the Talent Mobility process."
                            onClick={this.onHiringInfoCardClick.bind(this)}
                        />
                        {/* <InfoCard
                            dataKey="employee"
                            backgroundColor={infoCardColors.employee}
                            title={
                                <p className="InfoCardHeader">
                                    I am an
                                    <br /> Employee
                                    <span className="arrowRight">
                                        <Icon name="chevronRed" />
                                    </span>
                                </p>
                            }
                            content="I would like to learn more about the Talent Mobility processes and policies."
                            onClick={this.onEmployeeInfoCardClick.bind(this)}
                        /> */}
                    </div>
                </div>
            </div>
        ) : (
            <div className="Spinner" />
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(IdentificationPage),
);
