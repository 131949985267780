import React, { PureComponent } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { RouterProps } from "types";
import {Selects, Input, Icon, SpinnerWrapper } from "components/common";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "./Select";
import Tooltip from "@material-ui/core/Tooltip";
import { getCaliforniaTiers } from "selectors";
import './index.scss'
import { moveInitiationService } from "services";


const mapStateToProps = store => ({
    californiaTiers: getCaliforniaTiers(store),
});
const mapDispatchToProps = {
    fetchCaliforniaTiers: moveInitiationService.actions.fetchCaliforniaTiers,
    setMoveAdditionalMoveDetails: moveInitiationService.actions.setMoveAdditionalMoveDetails
};

interface IProps extends RouteComponentProps<{ step: string }> {
    props: RouteComponentProps<any> & RouterProps
}
export const DATE_TIME_FORMAT = "YYYY-MM-DD";
const DATE_FORMAT = "YYYY-MM-DD";
export const FORMAT_TO_SHOW = "M/D/YYYY";
class AddMoveDetails extends React.PureComponent<IProps & {fetchCaliforniaTiers, setMoveAdditionalMoveDetails}>{
    
    private calMoveTierArr = {
        "Tier 1: California Market": "Tier1CaliforniaMarket", 
        "Tier 2: Critical Skills Position": "Tier2CriticalSkillsPosition", 
        "Tier 3: Business Critical Role": "Tier3BusinessCriticalRole"
    }

    public readonly californiaPositionTire = {
        "ca": "california",
        "california": "california" 
    }

    public readonly  state = {
        defaultValue: null,
        startDate: null,
        endDate: null,
        buttonStatus:"disabled",
        disableButton:"disable",
        relocationState: (this.props.location.state.eligibleTitle != null && this.props.location.state.eligibleTitle !== 'undefined') ? 
                        this.props.location.state.eligibleTitle : null,
        startLabel: '',
        endLabel: '',
        californiaTier: (this.props.location.state.californiaTier != null && typeof this.props.location.state.californiaTier !== 'undefined') ? 
        this.props.location.state.californiaTier : null,
        californiaTiers: [],
        valueInput:(this.props.location.state.valueInput != null && typeof this.props.location.state.valueInput !== 'undefined') ? 
        this.props.location.state.valueInput : null,
        openTooltip: false,
        numberOfFlights: (this.props.location.state.numberOfFlights != null && typeof this.props.location.state.numberOfFlights !== 'undefined') ? 
                        this.props.location.state.numberOfFlights : '',
        additionalDetails: (this.props.location.state.additionalDetails != null && typeof this.props.location.state.additionalDetails !== 'undefined') ? 
                        this.props.location.state.additionalDetails : null,
        isReqGoingCaliforniaState: (this.props.location.state.reqInfoRepChildEle != null && typeof this.props.location.state.reqInfoRepChildEle.State !== 'undefined') ? 
                        this.props.location.state.reqInfoRepChildEle.State : null,
        closeModal: '',
        options: [0,1,2,3,4,5],
        jobDetailsBackState: (this.props.location.state.jobDetailsBackState != null && typeof this.props.location.state.jobDetailsBackState !== 'undefined') ? 
                            this.props.location.state.jobDetailsBackState : 'employee-job-details',
        isLoading: false,
        calMoveTier: this.calMoveTierArr,
        isCaliforniaState: null,
    };    

    handleInputChange = (propName, handler) => ({ type: string, target, ...eventRest }, ...argsRest) => {
        let value = target ? target.value : Object.values(eventRest).join("");
        const state = { ...this.state };
        const {relocationState} = this.state;

        if (propName === "numberOfFlights") {
            if (relocationState == "Commuter"){
                this.setState({numberOfFlights: value})
            }else
            {
                this.setState({numberOfFlights: null})
            }
        }
        if (propName === "additionalDetails") {
           this.setState({additionalDetails: value})
        }
        if (propName === "valueInput") {
            this.setState({valueInput: value})
         }
         if (propName === "californiaTier") {
            this.setState({californiaTier: value})
         }
    };

    componentDidMount = () => {
        
        this.props.fetchCaliforniaTiers();
         const {numberOfFlights,relocationState} = this.state;
         if (relocationState == "Commuter"){
             if(numberOfFlights !== ''){
                 this.setState({buttonStatus: "", disableButton: ""});
             }
         }else{
             if(numberOfFlights !== ''){
                 this.setState({buttonStatus: "", disableButton: ""});
             }
         }

        let positionLocation = null;
        let goingToCity = (this.props.history.location.state.reqInfoRepChildEle.City != null && typeof this.props.history.location.state.reqInfoRepChildEle.City !== 'undefined') ? 
                            (this.props.history.location.state.reqInfoRepChildEle.City).toLowerCase() : null;
        let goingToState = (this.props.history.location.state.reqInfoRepChildEle.State != null && typeof this.props.history.location.state.reqInfoRepChildEle.State !== 'undefined') ? 
                                (this.props.history.location.state.reqInfoRepChildEle.State).toLowerCase() : null;
        let goingToCountry = (this.props.history.location.state.reqInfoRepChildEle.Country != null && typeof this.props.history.location.state.reqInfoRepChildEle.Country !== 'undefined') ? 
                                (this.props.history.location.state.reqInfoRepChildEle.Country).toLowerCase() : null;

        if(positionLocation === null && goingToCity !== null && this.californiaPositionTire[goingToCity] === "california") {
            positionLocation = "california";
        }else if(positionLocation === null && goingToState !== null && this.californiaPositionTire[goingToState] === "california") {
            positionLocation = "california";
        }else if(positionLocation === null && goingToCountry !== null && this.californiaPositionTire[goingToCountry] === "california") {
            positionLocation = "california";
        }
        this.setState({isCaliforniaState:positionLocation});
     }

    componentWillReceiveProps(nextProps) {      
        this.setState({
            californiaTiers: Object.values(nextProps.californiaTiers),
        });
    }

    handleInputTextChange = ({
        target,
    }: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ valueInput: target.value });       
    };
     renderCaliforniaTooltip() {
        return (
            <React.Fragment>
                <Icon
                    name={"closeGray"}
                    width={15}
                    className={"flexible jEnd"}
                    onClick={this.toggleTooltip}
                />
                <h4>About California Tiers</h4>
                <div className={"tier"}>
                    <span>Tier 1 (California Market)</span>
                    For standard relocations into/within California.
                </div>
                <div className={"tier"}>
                    <span>Tier 2 (Critical Skills Position)</span>
                    Positions with unique skills needed within an occupation
                    which typically make the skills niche with a limited talent
                    supply and competition for talent is very competitive.
                </div>
                <div className={"tier"}>
                    <span>Tier 3 (Business Critical Role) </span>
                    Positions absolutely essential to the achievement of
                    long-term strategic business priorities through their
                    strategic impact and/or contribution of unique capabilities.
                </div>
            </React.Fragment>
        );
    }
     toggleTooltip = () => {
        this.setState({ openTooltip: !this.state.openTooltip });
    };
    componentDidUpdate = () => {
        this.enableContinue();   
    }
    enableContinue = () => {
         const {numberOfFlights,relocationState,isCaliforniaState,californiaTier} = this.state;
         if (relocationState == "Commuter" && isCaliforniaState == "california"){
             if(numberOfFlights !== '' && californiaTier !== '' && numberOfFlights !== null && californiaTier !== null){
                 this.setState({buttonStatus: "", disableButton: ""});
             }
         } else { 
             if (relocationState == "Commuter" && isCaliforniaState !== "california"){
                if (numberOfFlights !== '' && numberOfFlights !== null){
                    this.setState({buttonStatus: "", disableButton: ""});
                 }
              }else{
            if (relocationState !== "Commuter" && isCaliforniaState == "california"){
                if (californiaTier !== '' && californiaTier !== null){
                    this.setState({buttonStatus: "", disableButton: ""});
                }
            }else{
              this.setState({buttonStatus: "", disableButton: ""});
            }
         }
        }  
    }
    additionalDetailsChange = ({target}: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({additionalDetails: target.value });
    }
   
    closeModal = () => {
        this.setState({closeModal: "close"})
    }

    handlePageRedirect = (e) => {
        e.preventDefault();
        this.props.history.push('/contact-us');
    }
    
    handleDropdownChange = ({
        target,
    }: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ californiaTier: target.value });
    };

    saveAdditionalMoveDetails = (location) => {
        this.setState({isLoading: true, buttonStatus: "disabled", disableButton: "disable"});
        let { additionalDetails, californiaTier, valueInput, numberOfFlights, calMoveTier} = this.state;
        let californiaTierValue = (calMoveTier[californiaTier] !== undefined) ? calMoveTier[californiaTier] : californiaTier;
        this.props
                .setMoveAdditionalMoveDetails({
                        "initiationId": (this.props.location.state.initiationId !== undefined) ? this.props.location.state.initiationId : '',
                        "californiaTier": (californiaTierValue !== null) ? californiaTierValue : "NotSet",
                        "newSalary": (valueInput !== null) ? valueInput : null,
                        "specialInstruction": (additionalDetails !== null) ? additionalDetails : null,
                        "commuterFlightsPerMonth": (numberOfFlights !== '' && numberOfFlights !== undefined) ? numberOfFlights : 0, 
                    })
                    .then(data => {
                       this.setState({isLoading: false, buttonStatus: "", disableButton: ""});
                       if(data.success) {
                            this.navigateTo(location);
                       } else {
                            console.log(data.message);
                       }
                    })
                    .catch(err => console.log(err));
    }

    navigateTo = (location: string) => {
        switch (location) {
            case 'continue':
                this.saveAdditionalMoveDetails("yesandcontinue");
                break;
            case 'returnhome':
                this.saveAdditionalMoveDetails("returndashboard");
                break;
            case 'lets-go':
                this.props.history.push(
                    "/initiate-move",
                    {
                        "moveType": this.props.location.state.moveType,
                        "policySelection": this.props.location.state.policySelection,
                    },
                );
                break;
            case 'returndashboard':
                    this.props.history.push(
                        "/move-dashboard"
                    );
                    break;
            case 'yesandcontinue':
                    let { startDate, endDate, numberOfFlights, additionalDetails, californiaTier, valueInput} = this.state;
                    this.props.history.push(
                        "/initiate-move/finance-admin",{...this.props.location.state,startDate, endDate, numberOfFlights, additionalDetails,californiaTier,valueInput}
                    );
                    break;
        }
    };

    render(){
        const {buttonStatus,valueInput, relocationState,californiaTier, disableButton, startDate, endDate, numberOfFlights, additionalDetails, closeModal,startLabel, endLabel, options, jobDetailsBackState, isLoading,isCaliforniaState, isReqGoingCaliforniaState} = this.state;

        return (
            <SpinnerWrapper show={isLoading}>
            <div className="add-move-details-container">
                <Prompt
                    message={location =>
                        location.pathname.startsWith("/contact-us")
                        ? `You are about to leave this page, your changes will not be saved!`
                        : true
                    }
                />
                <section className="text-section">
                    <div 
                        className="back-icon" 
                        onClick={() => {
                            this.props.history.push("/initiate-move/"+jobDetailsBackState,{...this.props.location.state});
                        }}
                    >
                            <Icon name="backIcon" />
                            <p>Go back</p>
                    </div>
                    <h1 className="section-title">
                        Ok, we need to collect a little more information about this move.
                    </h1>
        
                    
                {isCaliforniaState == "california" ? (   
                <h5 className={"california-title"}>
                    For California moves, we need to know which tier should be
                    used.
                </h5>
                      ) : null}
                {isCaliforniaState == "california" ? (         
                <div className={"flexible aCenter"}>
                    <Select
                        loading={!this.state.californiaTiers.length}
                        label={"Select applicable CA tier"}
                        onChange={this.handleDropdownChange}
                        value={californiaTier}
                        name={"californiaTier"}
                        options={this.state.californiaTiers}
                    />
                    <Tooltip
                        title={this.renderCaliforniaTooltip()}
                        PopperProps={{ disablePortal: true }}
                        open={this.state.openTooltip}
                        placement={"right"}
                        classes={{
                            popper: "tooltip-popper",
                            tooltip: "tooltip-root",
                        }}
                    >
                        <div
                            style={{ marginLeft: 14, paddingTop: 20 }}
                            onMouseEnter={this.toggleTooltip}
                        >
                            <Icon name={"infoBlue"} />
                        </div>
                    </Tooltip>
                </div>
                 ) : null}
                {relocationState == "Commuter" ? (

                <p className="title">
                    Please indicate the number of flights per month for this commuter assignment.
                </p>
                ) : null}
                {relocationState == "Commuter" ? ( 
                <section className="option-select-container">
                <Selects
                    type="options"
                    label={"Select an option"}
                    value={numberOfFlights}
                    onChange={this.handleInputChange("numberOfFlights",null)}
                    options={options}
                    name={"leadership"}
                />
                </section>
                ) : null}

                <h5 className={"base-title"}>
                    New Base Salary
                </h5>
                <div className="Input">
                    <input 
                        name="valueInput"
                        value={this.state.valueInput} 
                        maxlength="10"
                        style={{ borderBottom: "2px solid black" , width:"35%"}}
                        onChange={event => this.setState({valueInput: event.target.value.replace(/\D/,'')})
                    }/>
                 </div>

                 <p className="title">
                        Would you like to add any additional details or special instructions?
                    </p>
                    <section className="input-select-container">
                        <Input 
                            placeholder="Enter additional details if needed"
                            maxlength="500"
                            value={additionalDetails}
                            onChange={this.additionalDetailsChange}
                            style={{ borderBottom: "2px solid black"}}
                        />
                    </section>

                </section>
                <section className="download-policy-section">
                    <div className="line" />
                    <br />
                    <section className="buttons">
                        <button
                            className="white-button"
                            onClick={() => {
                                this.navigateTo("lets-go");
                            }}
                        >
                            <span className="buttonLabel">Start Over</span>
                        </button>
                        <section>
                            <button 
                                className={"white-button second " + buttonStatus} 
                                disabled={disableButton}
                                onClick={() => {
                                    this.navigateTo("returnhome");
                                }}
                            >
                                 <span className="buttonLabel">Save and Return to Home</span>
                            </button>
                            <button 
                                className={"red-button " + buttonStatus} 
                                disabled={disableButton}
                                onClick={() => {
                                    this.navigateTo("continue");
                                }}
                            >
                                 <span className="buttonLabel">Continue</span>
                            </button>
                        </section>
                    </section>
                </section>
            </div>
            </SpinnerWrapper>                    
        );
    }
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AddMoveDetails));
