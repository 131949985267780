const TextCaseFormatter = (text=null, caseType=null, index=null ) => {
	let formatedText = "";
	//{case name}-case
	let caseTypeFlag = "title-case";
	if(caseType !== null) {
		caseTypeFlag = caseType;
	}
	if(text !== null && text !== "") {
		if(caseTypeFlag === "sentence-case") {
			const result = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
			formatedText = result;
		}else if(caseTypeFlag === "title-case") {
			const result =  text.toLowerCase().split(' ').map(function(word) {
				if(word[0] !== undefined) {
					return word.replace(word[0], word[0].toUpperCase());
				} else {
					return word.toUpperCase();
				}
				
			  }).join(' ');
			formatedText = result;
		}else if(caseTypeFlag === "lower-case") {
			const result =  text.toLowerCase();
			formatedText = result;
		}else if(caseTypeFlag === "uper-case") {
			const result =  text.toUpperCase();
			formatedText = result;
		} else {
			formatedText = text;
		}
	} else {
		formatedText = text;
	}
	return formatedText;
};

export default TextCaseFormatter;
