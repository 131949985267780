import React from 'react';

export default (
    <div className="article-info">
        <div className="article-info-item">
            <h2 className="title">Make A Moving Book</h2>
            <p className="description">
                One way to help your child process this new life chapter is by making a scrap book filled with pictures and memory captions of the house, school and friends they’ll be moving away from. At the same time, make sure you also include photos and lists of things they’re excited to try in their new home. Research local playgrounds, restaurants, museums, movie theaters and other fun destinations together—the idea is to give them plenty of new and exciting things to look forward to.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Put Them in Charge</h2>
            <p className="description">
                It’s not a bad idea to consult your children about relocating and ask them for their input. And while they may not have the final say when it comes to making the final decision, you can help them feel empowered and in control by letting them participate in other parts of the decision-making process. For instance, what color do they want to paint their new rooms? If they’re old enough, you could even give them a decoration budget, and ask them for their ideas about how you should set up the furniture in your new home. Little things like these can go a long way toward helping kids feel like your new house is their new home.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Set Up the Bedrooms ASAP</h2>
            <p className="description">
                Once your household items have arrived, prioritize unpacking the children’s new rooms before any of the others so that they have a safe haven full of familiar things—right away. If you are first staying in temporary housing, have your kid pack a small bag of their favorite things to keep with them. This is a great way to remind them that they're still safe and sound, even though their surroundings might be unfamiliar.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Maintain Familiar Routines</h2>
            <p className="description">
                Provide your kids with a sense of stability in the midst of change. Stick to the familiar bedtime rituals, keep playing their favorite board games and find a new violin teacher so they can maintain their old hobbies (yes, they still have to practice every day). And don’t let a dining room that’s temporarily full of boxes stop you from enjoying dinner as a family.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Help Them Meet Other Kids</h2>
            <p className="description">
                Don’t hesitate to sign your kids up for clubs and classes that align with their interests, especially if school hasn’t started yet. And if you’re moving to a new country where a language they're unfamiliar with is spoken, sign your kids up for a language class with expats their own age. While you're at it, set up playdates with new coworkers and neighbors who have kids of similar ages—this will help you and your children establish a sense of community.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Organize Online Playdates with Old Friends</h2>
            <p className="description">
                It can be hard to stay in touch after a move. But you can make it easier by helping to facilitate video chats with your kids’ best friends from home. If the conversation doesn’t flow easily, suggest playing an online game or streaming their favorite television show together.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Accept and Encourage Their Feelings</h2>
            <p className="description">
                Hard as it is to see your child in pain, let them know that it’s okay for them to feel confused about some of the changes they’re experiencing. Let them know that it’s alright to be both excited and sad about something at the same time—and do your best to help them talk through some of these complicated feelings.
            </p>
        </div>
    </div>
)