import React, { PureComponent } from "react";
import { MoveStepLayout } from "components/moveInitiationLayouts";
import { Icon } from "components/common";
import { withRouter } from "react-router";
import WhichScenario from "../WhichScenario";
import DomesticPayGrade from "../DomesticPayGrade";
import "./style.scss";

class ReasonSelector extends PureComponent {
    moveReasons = [
        {
            id: 1,
            key: "LDP",
            title: "Leadership Development Program (LDP)",
            value: "LeadershipDevelopmentProgram",
            descriptionHighlighte: "",// "*always for new hires*",
        },
        {
            id: 2,
            key: "homeowner",
            title: "Homeowner",
            value: "Homeowner",
            descriptionHighlighte: "",
        },
        {
            id: 3,
            key: "renter",
            title: "Renter",
            value: "Renter",
            descriptionHighlighte: "",
        }
    ];
    componentDidMount() {
        //console.log(this.props.location.state)
    }
    navigateTo = (key, title, value) => {
        const { moveType, policySelection } = this.props.location.state;
        if (!key) {
            this.props.history.push("/initiate-move", {
                "moveType": moveType,
                "policySelection": policySelection,
                "relocationType": (this.props.location.state.relocationType !== undefined) ? this.props.location.state.relocationType : null,
                "initiationId": (this.props.location.state.initiationId !== undefined) ? this.props.location.state.initiationId : null,
				"initiationCountryCode": (this.props.location.state.initiationCountryCode !== undefined) ? this.props.location.state.initiationCountryCode : null,
                "empCategoryRepresentation": (this.props.location.state.empCategoryRepresentation !== undefined) ? this.props.location.state.empCategoryRepresentation : null,
                employeeInfo: (this.props.location.state.employeeInfo !== undefined && this.props.location.state.employeeInfo !== null ) ? this.props.location.state.employeeInfo : null,
            });
        } else {
            this.props.history.push({
                state: {
                    "policyType": key,
                    "moveType": moveType,
                    "policySelection": policySelection,
                    "reasonSelect": title,
                    "relocationType": (this.props.location.state.relocationType !== undefined) ? this.props.location.state.relocationType : null,
                    "initiationId": (this.props.location.state.initiationId !== undefined) ? this.props.location.state.initiationId : null,
                    "initiationCountryCode": (this.props.location.state.initiationCountryCode !== undefined) ? this.props.location.state.initiationCountryCode : null,
                    "empCategoryRepresentation": value,
                    employeeInfo: (this.props.location.state.employeeInfo !== undefined && this.props.location.state.employeeInfo !== null ) ? this.props.location.state.employeeInfo : null,
                },
            });
        }
    };


    render() {
 
        if(this.props.location.state && this.props.location.state.policyType){
            return (
                <div>
                    <MoveStepLayout selected={1} />
                    <section className="domestic-package-selection-container">
                        {this.props.location.state &&
                        this.props.location.state["policyType"] !== "LDP" ? (
                            <DomesticPayGrade
                                history={this.props.history}
                                location={this.props.location}
                                policyType={this.props.location.state["policyType"]}
                            />
                        ) : (
                            <WhichScenario 
                                history={this.props.history}
                                location={this.props.location}
                            />
                        )}
                    </section>
                </div>
            );
        } else {
            return (
                <>
                <MoveStepLayout selected={1} />
                <section className="domestic-package-selection-container">
                    <section
                        className="back-icon"
                        onClick={() => this.navigateTo("","","")}
                    >
                        <Icon name="backIcon" />
                        <p>Go back</p>
                    </section>
                    <h1 className={"section-title"}>
                        Which category best represents the employee?
                    </h1>
                    <div className={"reasons flexible wrap jBetween"}> 
                        {this.moveReasons.map(reason => (
                            <div
                                key={reason.id}
                                className={"reason"}
                                onClick={() =>
                                    this.navigateTo(reason.key, reason.title, reason.value)
                                }
                            >
                                <p className="reason-info">{reason.title}</p>
                                <p className="always-for-new-hire">{reason.descriptionHighlighte}</p>
                            </div>
                        ))}
                    </div>
                    </section>
                </>
               
            )
        }
    }
}

export default withRouter(ReasonSelector);
