import React from "react";
import { EXTERNAL_LINKS } from 'consts';
import Typography from '@material-ui/core/Typography';

import {
  QuestionsListCard,
  Icon,
  Asset
} from 'components/common';

export const STEPS = [{
  props: {
    title: (
      <div className='title flexible horizontal aCenter'>
        <span className="index flexible jCenter aCenter">1</span>
        <p className="title-text">Clarify Your Strategic Goals & Business Objectives</p>
      </div>
    ),
    uniqueKey: 1,
  },
  content: (
    <div className="content">
      <Typography variant="body2">
        International assignments and global moves are an important factor in meeting key business goals, advancing an employee’s career development and progression and helping J&J drive innovation and diverse thinking across the organization. To achieve these goals, each move must be clearly aligned to specific business, project or talent strategies. The success and impact of the talent move is directly dependent on how well the business or talent deliverables are defined and communicated at the outset of move planning.
      </Typography>
      <h2 className="mtop-30">
        JnJ strategic intents for talent moves fall into various categories. Some examples include:
      </h2>
      <QuestionsListCard
        key="accordion1"
        body={
          (
            <ul>
              <li>Filling interim skill/competency gaps in local markets</li>
              <li>Bringing specialized expertise to accelerate key business initiatives</li>
              <li>Developing knowledge and/or expertise of early career talent with the expectation of building capabilities</li>
              <li>By nomination Leadership Development Programs (i.e., functional programs in Operations, Finance, HR, IT, and others)</li>
            </ul>
          )
        }
        urlText={["Leadership Development Portal"]}
        urlActions={["https://jnj.sharepoint.com/sites/leadershipandlearning/Pages/default.aspx"]}
      >
      </QuestionsListCard>
      <div className="separator" />
      <h2>
        Optimizing the value of the mobility investment starts with making the right choices. Follow these steps to ensure you have a well planned move:
      </h2>
      <QuestionsListCard
        key="accordion2"
        body={
          (
            <ul>
              <li>Define the strategic drivers for mobility</li>
              <li>Prioritize decisions that are aligned to the business/ talent strategy</li>
              <li>Select the right talent</li>
              <li>Assess the right hosting opportunity / destination</li>
              <li>Decide on the optimum duration for effective performance on assignment</li>
              <li>Scenario plan for return roles to allow for greater clarity on plans upon completion of an assignment</li>
            </ul>
          )
        }
      >
      </QuestionsListCard>
      <div className="footer">
        <p>
          The Talent Mobility Advisory Services team partners with the HR leadership teams to share analytics and insights to promote smart choices in mobility investment decisions.
        </p>
        <p className="title-text-color">
          In line with Consumer SmartSpend, all moves funded by the Consumer sector should be supported by the relevant approvals from the Regional Head of HR.
        </p>
      </div>
    </div>
  ),
},
  {
    props: {
      title: (
        <div className='title flexible horizontal aCenter'>
          <span className="index flexible jCenter aCenter">2</span>
          <p className="title-text">Understand Immigration Complexity</p>
        </div>
      ),
      uniqueKey: 2,
    },
    content: (
      <div className="content">
        <Typography variant="body2">
          Immigration compliance is a critical element of international assignments and transfers.
        </Typography>
        <div className="footer margin">
          <p>
            There are many variables that impact the process of moving globally. Immigration is by far the most important to deployment timelines. The specific visa and work permit requirements of a country determine when an employee can legally start work.  As immigration regulations vary country to country, and are subject to change, it is best to plan early, well in advance of the need to be present in the country. Familiarize yourself with the immigration country timelines below. No employee may start work prior to authorization by the Immigration Services Provider.
          </p>
        </div>
        <h2>
          If the offer process is not managed with support from J&J Talent Acquisition, the manager should proceed as follows.
        </h2>
        <div className="question-list-card">
          <QuestionsListCard
            key="accordion3"
            body={
              (
                <ul>
                  <li>For individuals looking to move globally, complete the global Immigration Sponsorship Assessment</li>
                  <li>Immigration Initiation Form to be completed and submitted to Immigration Provider</li>
                </ul>
              )
            }
            urlText={[
              "Immigration Recommended Lead Times",
              "Immigration Initiation Form"]}
            urlActions={[
              () => new Asset({assetType:'global-immigration', assetId:'immigration-complexity-matrix'}).execute(),
              () => new Asset({assetType:'immigration-service', assetId:'immigration-service-initiation-form'}).execute()]}
          >
          </QuestionsListCard>
        </div>
      </div>
    ),
  },
  {
    props: {
      title: (
        <div className='title flexible horizontal aCenter'>
          <span className="index flexible jCenter aCenter">3</span>
          <p className="title-text">Complete a Preliminary Cost Estimate Scenario</p>
        </div>
      ),
      uniqueKey: 3,
    },
    content: (
      <div className="content flex">
        <div className="accordion_item_main_block">
          <Typography variant="body2">
            Once you have determined the policy and move type, you can generate a scenario cost estimate.
          </Typography>
          <div className="footer margin">
            <p>
              This is just a preliminary estimate. When you formally initiate a global move, our relocation management partner, SIRVA, will complete a formal cost estimate which will be submitted for two levels of management approval.
            </p>
            <p className="title-text-color">
              In line with Consumer SmartSpend governance requirements, all moves funded by the Consumer sector should have the prior approval of the Regional Head of HR.
            </p>
          </div>
          <QuestionsListCard
            key="accordion6"
            urlText={["Cost Estimate Tool"]}
            urlActions={[EXTERNAL_LINKS.GlobalCostEstimator]}
          >
          </QuestionsListCard>
        </div>
        <div className="side_info_list">
          <QuestionsListCard
            key="accordion7"
            body={
              <div className="question-part__list-container">
                <strong>Before you begin, be sure you have:</strong>
                <ul className="question-parts__list">
                  <li>Selected a Move type</li>
                  <li>Host & Home Country info</li>
                  <li>Projected start & end date</li>
                  <li>Pay Grade</li>
                  <li>Family size</li>
                  <li>Estimated Salary</li>
                  <div className="estimate">
                    <hr/>
                    <div className="estimate__header">
                      <Icon name="timeRed"/><span className="time-label">Estimated Time:</span><br/>
                    </div>
                    <span className='time-desc'>
                        Generally takes about 10-15 minutes of your time
                    </span>
                  </div>
                </ul>
              </div>
            }
          >
          </QuestionsListCard>
        </div>
      </div>
    ),
  }];
