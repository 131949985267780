// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserProfile } from 'selectors';

import './styles.scss';

// component imports
import AssignmentHeader from './AssignmentHeader';
import AllowancesAndDeductions from './AllowancesAndDeductions';
import { Allowances } from './Allowances';

type AssignmentLetterProps = {
    userProfile: Object,
    history: Object,
};

const mapStateToProps = ( state ) => ({
    userProfile: getUserProfile(state),
});

class AssignmentLetter extends React.PureComponent<AssignmentLetterProps> {

    componentWillMount(){
        this.checkAccess(this.props);
    }

    componentWillReceiveProps(nextProps: AssignmentLetterProps) {
        this.checkAccess(nextProps);
    }

    checkAccess = (props: AssignmentLetterProps) => {
        const { type } = props.match.params;
        if(props.userProfile.email) {
            if(props.userProfile.currentRelocationIsDomestic) {
                (type !== 'domestic') &&  props.history.push('/assignment-letter/domestic');
            } else {
                (type !== 'international') && props.history.push('/assignment-letter/international');
            }
        }
    };

    render() {
        const { type } = this.props.match.params;
        return (
            <div className="GlobalAssignmentLetter">
                <AssignmentHeader type={type}/>
                <AllowancesAndDeductions type={type}/>
                <Allowances type={type}/>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(AssignmentLetter));
