
export default class CreateActionCreator {

    static read = ({ type, apiCall, args, options }) => CreateActionCreator.dispatch(apiCall(...args), type, options);
    static readAsync = ({ type, apiCall, args, options }) => CreateActionCreator.dispatchAsync(apiCall(...args), type, options);

    static create = ({ type, apiCall, args, options }) => CreateActionCreator.dispatch(apiCall(...args), `${type}_CREATE`, options);

    static update = ({ type, apiCall, args, options }) => CreateActionCreator.dispatch(apiCall(...args), `${type}_UPDATE`, options);
    static updateAsync = ({ type, apiCall, args, options }) => CreateActionCreator.dispatchAsync(apiCall(...args), `${type}_UPDATE`, options);

    static delete = ({ type, apiCall, args, options }) => CreateActionCreator.dispatch(apiCall(...args), `${type}_DELETE`, options);

    static dispatch = (promise, type, options) => dispatch => {
        promise.then(response => dispatch({
            type,
            payload: response.result,
            options,
        })).catch(error => dispatch({
            type: `${type}_FAILURE`,
            payload: error
        }));

        return promise;
    };

    static dispatchAsync = (promise, baseType, options) => dispatch => {
        const type = '@@' + baseType;

        dispatch({type: type + '_STARTED' })
        promise.then(response => dispatch({
            type,
            payload: response.result,
            options,
        })).catch(error => dispatch({
            type: `${type}_FAILURE`,
            payload: error,
            errorMessage: error.toString()
        }));

        return promise;
    };
};
