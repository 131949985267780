import { Icons } from './icon';

type IconRawProps = {
    name: string,
    width: number,
    height: number,
};


const IconRaw = (props: IconRawProps) => {
  return Icons[props.name](props.width, props.height)
}

IconRaw.defaultProps = {
    width: 50,
    height: 50,
};

export default IconRaw;
