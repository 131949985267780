import React, { PureComponent } from 'react';

export default class OrderedList extends PureComponent {
    render(){
        return(
            <div className="OrderedList">
               <div className="order  flexible jCenter aCenter">
                   <span>
                       {this.props.order}
                   </span>
               </div>
                <div className="text">
                    {this.props.body}
                </div>
            </div>
        )
    }
}