export { default as ContactMobilityKnockout } from "./contactMobility";
export { default as GlobalOneWayKnockout } from "./globalOneWay";
export { default as Pay50AboveKnockout } from "./payGrade50Above";
export { default as WorkingWithRecKnockout } from "./workingWithRecruiter";

export const InfoList = [
  "Employee’s Name and WWID",
  "Pay Grade of the Position",
  "Hiring Manager’s Name and WWID",
  "Current Country Location",
  "Future Country Location"
]