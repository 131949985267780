import './style.scss';

import React, { PureComponent } from 'react'


export default class MidSectionComponent extends PureComponent {

  render() {
    return (
      <div className={`MidSectionComponent ${ this.props.className }`}>
        
        {
          this.props.title?
        <div className="mid-section-header flexible horizontal">
          <h2>{this.props.title}</h2>
        </div> : null
        } 
        <div className="mid-section-body flexible horizontal">
        {this.props.col1}
        {this.props.col2 ? (this.props.hideDivider ? null : <hr className='divider'/>) : null} 
        {this.props.col2}
        {this.props.col3}
        {this.props.col4}
        </div>
      </div>
    )
  }
}
