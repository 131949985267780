import React, { PureComponent, useState } from "react";
import { connect } from "react-redux";
import { getUserProfile } from "selectors/dataSelectors";
import { IUserProfile } from "utils/types";
import { getEmpCompetitiveInit } from "selectors";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

// Store imports
import { moveInitiationService } from "services";

interface IProps {
    userProfile: IUserProfile;
    navigateTo: (location: string, fromLocation: string) => void;
    empCompetitiveInit: Object;
}

let empCompetitiveInit = null;
let userData = {
    candidate: "Christopher Vandelbork",
    id: null,
    services: "Full Relocation",
    policy: "Homeowner Package C",
    jobTitle: null,
    Country: null,
    Address: null,
    City: null,
    State: null,
    Postal: null,
    primaryLocation: null,
    requisition: null,
    offers: null,
    job_id: null,
    isNewHire: null,
};

let dataSet = {
    "billRate": null,
    "cswLatestDate": null,
    "dateOfEntry": null,
    "expirationDate": null,
    "grade": null,
    "newApplication": true,
    "number": 0,
    "payRate": 0,
    "isSubmissionExpired": true,
    "applicationState": null,
    "cswLatestStatus": true,
    "cswLatestStep": null,
    "workerType": null,
    "aceAlertStatus": true,
    "applicationRequisition": null,
    "requisition": null,
    "candidate": null,
    "offers": null,
    "job_id": null,
};


class RequestOverview extends PureComponent<RouteComponentProps & IProps & { saveCompetitivePreInitiation}>{

    state = {
        empCompetitiveInitData: null,
        isLoading: false,
        userData,
        buttonStatus:"",
        disableButton: false,
    };

    componentDidMount(): void {
       // console.log(this.props.location.state.initiationId);
        //console.log(this.props.empCompetitiveInit);
        let initId = ( this.props.location.state !== undefined && 
            this.props.location.state.initiationId !== undefined) ? 
            this.props.location.state.initiationId : null;
        let competitiveEdit = ( this.props.location.state !== undefined && 
            this.props.location.state.competitiveEdit !== undefined) ? 
            this.props.location.state.competitiveEdit : false;
        
        if (this.props.location.state === undefined) {
            this.navigateTo("dashboard");
        } else {
            if (this.props.location.state.empCompetitiveInit == null || this.props.location.state.empCompetitiveInit == "") {
                this.navigateTo("dashboard");
            }
            if(this.props.location.state.empCompetitiveInit === undefined && ( typeof this.props.location.state === undefined || Object.keys(this.props.location.state).length == 0) ) {
                this.navigateTo("dashboard");
            }
        }
        if(this.props.location.state !== undefined) {
            if(this.props.location.state.empCompetitiveInit !== undefined) {
                empCompetitiveInit = (this.props.location.state.empCompetitiveInit != null && typeof this.props.location.state.empCompetitiveInit !== 'undefined') ? 
                                        this.props.location.state.empCompetitiveInit : null;
            } 
        } else {
            empCompetitiveInit = (this.props == null) ? null : (this.props.hasOwnProperty('empCompetitiveInit') ? this.props.empCompetitiveInit : null);
        } 
        this.props.history.push({
            state: {
                empCompetitiveInit: empCompetitiveInit
            },
        });

        if(empCompetitiveInit !== null) {
            let address1 = (empCompetitiveInit.requisition.jobInformation !== undefined && empCompetitiveInit.requisition.jobInformation.primaryLocationAddress1 !== null) ? empCompetitiveInit.requisition.jobInformation.primaryLocationAddress1 + " " :'';
            let address2 = (empCompetitiveInit.requisition.jobInformation !== undefined && empCompetitiveInit.requisition.jobInformation.primaryLocationAddress2 !== null) ? empCompetitiveInit.requisition.jobInformation.primaryLocationAddress2 :''; 
            
            // let isJnJEmpp = (empCompetitiveInit.candidate !== undefined && empCompetitiveInit.candidate.email !== null) ? ((empCompetitiveInit.candidate.email).toLowerCase()).includes('@its.jnj.com') : false; 
            // let newHireData = (empCompetitiveInit.offers !== undefined && empCompetitiveInit.offers !== null && isJnJEmpp === false) ? ((empCompetitiveInit.offers.hireStatus !== undefined && empCompetitiveInit.offers.hireStatus !== null) ? empCompetitiveInit.offers.hireStatus : null) : null;
            let newHireData = (empCompetitiveInit.offers !== undefined && empCompetitiveInit.offers !== null) ? ((empCompetitiveInit.offers.hireStatus !== undefined && empCompetitiveInit.offers.hireStatus !== null) ? empCompetitiveInit.offers.hireStatus : null) : null;
            let isNewHire = (newHireData !== null) ? (((newHireData).toLowerCase() == "new hire") ? true : false) : false;

            userData = {
                candidate: empCompetitiveInit.candidate,
                jobTitle: (empCompetitiveInit !== undefined && empCompetitiveInit !== null) ? empCompetitiveInit.requisition.jobInformation.title : '',
                id: (empCompetitiveInit !== undefined && empCompetitiveInit !== null) ? empCompetitiveInit.requisition.jobInformation.positionID : '',
                services: "Full Relocation",
                policy: "NotSet",
                Country: (empCompetitiveInit !== undefined && empCompetitiveInit !== null) ? empCompetitiveInit.requisition.jobInformation.country : '',
                Address: address1+address2,
                City: (empCompetitiveInit !== undefined && empCompetitiveInit !== null) ? empCompetitiveInit.requisition.jobInformation.city : '',
                State: (empCompetitiveInit !== undefined && empCompetitiveInit !== null) ? empCompetitiveInit.requisition.jobInformation.stateProvince : '',
                Postal: '',
                primaryLocation: null,
                requisition: empCompetitiveInit.requisition,
                offers: empCompetitiveInit.offers,
                job_id: empCompetitiveInit.requisition.number,
                isNewHire: isNewHire,  
            };
            this.setState({
                userData: userData
            });
        }
        if(competitiveEdit) {
            this.props.history.push({
                state: {
                    ...this.props.location.state,
                    "initiationId": initId,
                    "empCompetitiveInit": userData
                },
            })
            this.navigateTo("submit");
        }
    }

    navigateTo = (location: string) => {
        switch (location) {
            case 'dashboard':
                this.props.history.push("/move-dashboard");
                break;
            case 'continue':
                this.savePreinitiation();
                break;
            case 'submit':
                this.props.navigateTo(
                    "policy-selection",
                    "request-overview",
                )
                break;
        }
    } 

    savePreinitiation = () => {
        // console.log(this.props)
        dataSet = {
            "billRate": this.props.location.state.empCompetitiveInit.billRate,
            "cswLatestDate": this.props.location.state.empCompetitiveInit.cswLatestDate,
            "dateOfEntry": this.props.location.state.empCompetitiveInit.dateOfEntry,
            "expirationDate": this.props.location.state.empCompetitiveInit.expirationDate,
            "grade": this.props.location.state.empCompetitiveInit.grade,
            "newApplication": this.props.location.state.empCompetitiveInit.newApplication,
            "number": this.props.location.state.empCompetitiveInit.number,
            "payRate": this.props.location.state.empCompetitiveInit.payRate,
            "isSubmissionExpired": this.props.location.state.empCompetitiveInit.isSubmissionExpired,
            "applicationState": this.props.location.state.empCompetitiveInit.applicationState,
            "cswLatestStatus": this.props.location.state.empCompetitiveInit.cswLatestStatus,
            "cswLatestStep": this.props.location.state.empCompetitiveInit.cswLatestStep,
            "workerType": this.props.location.state.empCompetitiveInit.workerType,
            "aceAlertStatus": this.props.location.state.empCompetitiveInit.aceAlertStatus,
            "applicationRequisition": this.props.location.state.empCompetitiveInit.applicationRequisition,
            "requisition": this.props.location.state.empCompetitiveInit.requisition,
            "candidate": this.props.location.state.empCompetitiveInit.candidate,
            "offers": this.props.location.state.empCompetitiveInit.offers,
            "job_id": this.props.location.state.empCompetitiveInit.requisition.number,
        };

        this.setState({
            isLoading: true,
            buttonStatus:"disabled",
            disableButton: true,
        })
        this.props
            .saveCompetitivePreInitiation(dataSet)
            .then(data => {
                this.setState({
                    isLoading: false,
                    buttonStatus:"",
                    disableButton: false,
                })
                if (data.result !== null || data.result !== " " || data.result !== undefined) {
                    this.props.history.push({
                        state: {
                            ...this.props.location.state,
                            "initiationId": data.result,
                            "empCompetitiveInit": userData
                        },
                    })
                    this.navigateTo("submit");
                } else {
                    console.log("InitiationId Not Found");
                }
            })
            .catch(e => {
                console.log(e)
            });
    }

    render() {
        const { props } = this;
        const { shortName } = this.props.userProfile;
        let { userData, buttonStatus, disableButton } = this.state;

        return (
            <div className="requestOverViewContainer">
                <section className="textHeaderSection">
                    <p className="user-name">Hi {shortName},</p>
                    <p className="text-header">
                        We need your help to finalize & initiate services.
                    </p>
                </section>
                <article className="employeeDetailsSection">
                    <section>
                        <p className="move-details-titles">Employee:</p>
                        <span className="move-details-heading">
                            {userData.candidate.firstName} {userData.candidate.lastName}
                        </span>
                        {(userData.isNewHire) ? (
                             <section className="buttonSection">
                                <button className="newHire">
                                    <span className="buttonLabel">NEW HIRE</span>
                                </button>
                            </section>
                        ) : null}
                    </section>
                    <section>
                        <p className="move-details-titles">New Job Title:</p>
                        <span className="move-details-heading">
                            {userData.jobTitle}
                        </span>
                        <br />
                        <span className="move-details-sub-heading">
                            ID: {userData.id}
                        </span>
                    </section>
                    <section>
                        <p className="move-details-titles">New Work Location</p>
                        <span className="move-details-heading">
                            {userData.Country}
                        </span>
                        <br />
                        <span className="move-details-sub-heading">
                            {(userData.Address !== '' && userData.Address !== null) ? (
                                <>
                                    {userData.Address} <br />
                                </>
                                ) : null}
                            {(userData.primaryLocation !== '' && userData.Address !== null )? (
                                    <>
                                        {userData.primaryLocation}
                                    </>
                                    ) : null}
                            {(userData.City !== '' && userData.City !== null) ? userData.City : '' } 
                            {(userData.State !== '' && userData.State !== null) ? ',' + userData.State: '' }
                            {userData.Postal}
                        </span>
                    </section>
                </article>
                <div className="line" />
                <br />
                <section className="buttonSectionGet">
                    <button
                        className={"getStarted " + buttonStatus} 
                        disabled={disableButton}
                        onClick={(): void =>
                            this.navigateTo("continue")
                        }
                    >
                        <span className="buttonLabel">Get Started</span>
                    </button>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    userProfile: getUserProfile(state),
    empCompetitiveInit: getEmpCompetitiveInit(state),
});

const mapDispatchToProps = {
    saveCompetitivePreInitiation: moveInitiationService.actions.setCompPreInitDetails,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(RequestOverview));
