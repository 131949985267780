import React from 'react';
import { Asset } from 'components/common';
import SurprisingWays from './SurprisingWays';
import KidsAdjust from './KidsAdjust';
import RelocatingWithoutCultureShock from './RelocatingWithoutCultureShock';
import BestPracticesforRelocatingInternationally from './BestPracticesforRelocatingInternationally';
import ThingsToKnowWhenRelocatingWithYourFamily from './ThingsToKnowWhenRelocatingWithYourFamily';
import TheKeyToSuccessfullyRelocating from './TheKeyToSuccessfullyRelocating';
import ReturnChecklist from './ReturnChecklist';

export default [
    {
        header: "5 Surprising Ways Johnson & Johnson Is Supporting Global Talent",
        image: <Asset assetType="ARTICLE_MAIN_IMAGE" assetId="2"   maxWidth={1000}/>,
        description:
            <p className="article-description">
                We're at the forefront of health tech, leveraging the latest technologies—from&nbsp;
                <span className="link">artificial intelligence (AI)</span> to&nbsp;
                <span className="link">3-D printing</span>—to&nbsp;
                <span className="link">change the trajectory of human health</span>.&nbsp;
                That means not only hiring the brightest minds from around the world, but making sure everyone is in the right place to contribute. And while moving is always stressful, here are five ways we're trying to make it a little bit easier on our employees around the world when they decide to relocate.
            </p>,
        quote: 'We want you to feel at home in your host country—and for more than half of the people on earth, home is where a pet is.',
        articleInfo: SurprisingWays,
    },
    {
        header: "7 Ways to Help Your Kids Adjust to a New Location",
        image: <Asset assetType="ARTICLE_MAIN_IMAGE" assetId="3"  maxWidth={1000} />,
        description:
            <p className="article-description">
                Whether your new job requires moving down the street, across the country or oceans away, relocating can be both an exciting opportunity and a source of stress. To be sure, leaving the familiar landscape behind presents unique challenges, especially if you have children.
                <br/><br/>How should you properly prepare your children for an encounter with new cultures, customs, foods and more? Read on for seven simple tips parents can use to help minimize stress when relocating.
            </p>,
        quote: 'How should you properly prepare your children for an encounter with new cultures, customs, foods and more?',
        articleInfo:  KidsAdjust,
    },
    {
        header: "7 Steps to Relocating Without Culture Shock",
        image: <Asset assetType="ARTICLE_MAIN_IMAGE" assetId="5"  maxWidth={1000} />,
        description:
            <p className="article-description">
                We believe that the cultural experience gained from relocation is every bit as valuable as the opportunities for professional advancement it affords. But we also know that moving to a new city, country or continent comes with its own set of challenges, like adapting to new customs, languages and business cultures—and we want to support you as much as possible during that transition.
                <br/>Here are some tips to help you avoid culture shock and thrive in your new assignment.
            </p>,
        quote: 'Be kind to yourself during this adjustment period—the highs and lows of what you’re feeling are completely normal.',
        articleInfo:  RelocatingWithoutCultureShock,
    },
    {
        header: "5 Best Practices for Relocating Internationally",
        image: <Asset assetType="ARTICLE_MAIN_IMAGE" assetId="7"  maxWidth={1000} />,
        description:
            <p className="article-description">
                Are you interested in relocating, gaining leadership perspective—and maybe even changing the trajectory of your entire career? If so, great! Here are five best practices for handling some of the most pressing aspects of an international move.
            </p>,
        quote: 'Take a look at your furniture—especially your biggest items—to assess what should stay and what can go.',
        articleInfo:  BestPracticesforRelocatingInternationally,
    },
    {
        header: "5 Things to Know When Relocating With Your Family",
        image: <Asset assetType="ARTICLE_MAIN_IMAGE" assetId="8"  maxWidth={1000} />,
        description:
            <p className="article-description">
                You’ve done it: You’ve found the opportunity of a lifetime—and your partner and children are going to take the leap and move to a new country with you. Congratulations!
                <br/>Obviously, there's a lot to think about. But one thing should be reassuring: You certainly aren't alone. For instance,&nbsp;<a href="https://www.un.org/sustainabledevelopment/blog/2016/01/244-million-international-migrants-living-abroad-worldwide-new-un-statistics-reveal/" rel="noopener noreferrer" target="_blank" className="link">one recent United Nations study</a>&nbsp;found that there's been a 41% increase in the number of people who work and live outside their country of birth between 2000 to 2015—in fact, there are more people living abroad today than ever before. But just because a ton of people are doing it doesn't mean it's easy.
                <br/>So here are a few things to do—and a few things to keep in mind—in order to make the process of moving with your family a little bit smoother.
            </p>,

        quote: 'As a rule of thumb, you should start prepping for the move at least 90 days beforehand to ensure that the host country’s embassy has enough time to process your visa.',
        articleInfo:  ThingsToKnowWhenRelocatingWithYourFamily,
    },
    {
        header: "The Key to Successfully Relocating? It’s All About the Details.",
        image: <Asset assetType="ARTICLE_MAIN_IMAGE" assetId="9"  maxWidth={1000} />,
        description:
            <p className="article-description">
                Picture it: You’ve found your dream job—but it’s halfway around the world. Now what?
                <br/>Moving is a major cause of stress for most people. Not only do you suddenly have to decide what to keep and what to discard, you've also got to pack, arrange transportation and consider a million other details. The to-do list never ends.
                <br/>For international moves, that list is even longer. There’s jumping through the requisite administrative and medical steps to secure a visa, plus learning a new language, finding a new school for your kids and figuring out how to bring your pets into a new country. But focusing on a few key details and thinking strategically can help you get organized—so you can feel less overwhelmed.
                training
            </p>,
        quote: 'Don’t be afraid to tap your family and friends—or even professional support staff—to accomplish all the items on your to-do list.',
        articleInfo:  TheKeyToSuccessfullyRelocating,
    },
    {
        header: "Return Checklist: 7 Steps to Readiness",
        image: <Asset assetType="ARTICLE_MAIN_IMAGE" assetId="12"  maxWidth={1000} />,
        description:
            <p className="article-description">
                If you're heading back home after a work assignment abroad, it may seem like you have a million details to keep track of—and we feel your pain. There's so much to know and do before, during and even after your move. To help out, we've put together a handy checklist.
                <br/>Want to know your level of relocation readiness? You will with these seven simple steps.
            </p>,
        quote: 'Don’t be afraid to tap your family and friends—or even professional support staff—to accomplish all the items on your to-do list.',
        articleInfo:  ReturnChecklist,
    },
];
