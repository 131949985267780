import React from 'react';

export const getRequiredLabel = ( label ) => (
    <span className="input-label">
        { label }
        <span className='label-asterisk'>*</span>
    </span>
);

export const getLabel = (label) => <span className='input-label'>{label}</span>;
