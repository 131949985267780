import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { errorService } from "services";
import { getErrorData } from "selectors";
import { connect } from "react-redux";

const mapStateToProps = store => ({
    error: getErrorData(store)
});

const mapDispatchToProps = {
    toggleErrorModal: errorService.actions.toggleErrorModal
};

const DialogTitle = withStyles(() => ({
    root: {
        margin: 0,
        padding: 24,
    },
    title: {
        color: 'red',
		fontSize: "2rem",
        textAlign: "center",
    }
}))(props => {
    const {children, classes} = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h5" className={classes.title}>{children}</Typography>
        </MuiDialogTitle>
    )
});

class ErrorModal extends PureComponent {
    handleClose = () => {
        this.props.toggleErrorModal(false, '', '');
    };

    render() {
        return (
            <Dialog
                open={this.props.error.isOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{this.props.error.title}</DialogTitle>
                <DialogContent>
                    {this.props.error.description}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" style={{padding: "1% 10%"}}>
                        Close
                    </Button>
                </DialogActions>
				<DialogContent>
                    <p style={{fontSize: "15px"}}>Please click on “Close” to continue with the initiation process.</p>
                </DialogContent>
            </Dialog>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
