import React, { Component } from 'react'
import {MidSectionComponent} from 'components/common';

export default class IntJourneyBackStep extends Component {

  render() {
    return (
      <div>
        <MidSectionComponent title = "There are a number of options at the end of assignment..." col1={
            <div className="flexible horizontal with-for-item">
              <div className="pad-right">
                <h3>Repatriation</h3>
                <p>The employee returns to their originating city/locale. In this scenario, the employee must apply for an open job position at the originating office.</p>
              </div>
              
              <div className="pad-right">
                <h3>Localization</h3>
                <p>
                The employee accepts a permanent position with J&J at the host location and remains in country.
                </p>
              </div>

              
              <div className="pad-right">
                <h3>Sequential</h3>
                <p>
                The employee selects another global assignment at another location. In this scenario, the manager creates a new job position and initiates a new move.
                </p>
              </div>

              
              <div className="pad-left">
                <h3>Extension</h3>
                <p>
                The host manager and employee decide to extend the assignment in the current location. In this scenario, the Host Manager connects with SIRVA to append the assignment GAL and end date.
                </p>
              </div>

            </div>
            }  
        />
      </div>
    )
  }
}
