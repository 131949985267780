import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

import { Icon } from 'components/common';

import './style.scss';

const popupRoot = document.getElementById('popup_root');

export default class Popup extends PureComponent{

    constructor(props){
        super(props);
        this.el = document.createElement('div');
        this.el.className = 'popup-content';
    }

    componentDidMount(){
        popupRoot.appendChild(this.el);
        window.addEventListener('keyup', this.onKeyUp, false);
    }

    componentWillUnmount() {
        popupRoot.removeChild(this.el);
        window.removeEventListener('keyup', this.onKeyUp, false);
    }

    onKeyUp = (e) => {
        if (e.keyCode === 27) {
            e.preventDefault();
            if (this.props.onClose) {
                this.props.onClose();
            }
            // deprecated
            if (this.props.closePopup) {
                this.props.closePopup();
            }
            window.removeEventListener('keyup', this.onKeyUp, false);
        }
    }

    render(){
        return ReactDOM.createPortal(
            <div className={`Popup animated fadeInDown ${this.props.className}`}>
                {this.props.title && (
                    <div className="popup-header flexible jBetween">
                        <div className="title">
                            {this.props.title}
                        </div>
                        <Icon
                            name="close"
                            onClick={this.props.onClose || this.props.closePopup}
                        />
                    </div>
                )}
                <div className="popup-body">
                    {this.props.children || this.props.body}
                </div>
            </div>,
            this.el,
        )
    }
}
