// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import { SiteOverview } from 'components/sections';
import { OverviewInfoContent } from 'components/common';
import MyMoveOverview from './MyMoveOverview';
import BrowserSupportBanner from './BrowserSupportBanner';

import { siteOverviewService } from 'services';
import { getSiteOverviewIsOpen, getUserProfile } from 'selectors';
import { SiteOverviewAPI } from 'api';

import './style.scss';

type ReactExtraContentProps = {
    siteOverviewIsOpen: boolean,
    toggleSiteOverview: (boolean) => void,
    userProfile: Object,
};

const mapStateToProps = (state: Object): Object => ({
    siteOverviewIsOpen: getSiteOverviewIsOpen(state),
    userProfile: getUserProfile(state)
});

const mapDispatchToProps: Object = ({
    toggleSiteOverview: siteOverviewService.actions.toggleSiteOverview
});

const views = {
    HrManager: {
        view1: {
            title: "Welcome! \n We’re here to help you understand how to move a team member.",
            assetId: "4"
        },
        view2: {
            assetId: '5',
            managements: [
                {
                    title: 'Strategic Planning Tools',
                    iconName: 'clipboard',
                    iconStyle: { width: 35, height: 45 },
                    textWidth: 187
                },
                {
                    title: 'Move Initiation',
                    iconName: 'moving',
                    iconStyle: { width: 35, height: 45 },
                    textWidth: 130
                },
                {
                    title: 'Transition & Culture Prep',
                    iconName: 'personWorld',
                    iconStyle: { width: 35, height: 40, marginTop: 30 },
                    textWidth: 190
                },
                {
                    title: 'End of Assignment Guidance',
                    iconName: 'pin',
                    iconStyle: { width: 32, height: 45 }
                },
            ],
            title: 'From planning to initiation, \n we’ve got everything you’ll need \n to make a talent move.',
            description: ''
        },
        view3: {
            description: "Throughout the website you will find common questions that managers have had in the past. Plus, Mobility Connect is just a phone call or email away.",
        },
    },
    Assignee: {
        view1: {
            title: "Welcome! \n We’re here to help you understand how a Talent Move works.",
            assetId: "1",
            classes: { overviewInfoContent: 'firstSlide' }
        },
        view2: {
            assetId: '2',
            managements: [
                {
                    title: 'Your Policy & Allowances',
                    iconName: 'moving',
                    iconStyle: { width: 40, height: 37 },
                    textWidth: 186
                },
                {
                    title: 'Expense Tracking',
                    iconName: 'docsDollar',
                    iconStyle: { width: 31, height: 37 },
                    textWidth: 137
                },
                {
                    title: 'Benefits Info & Resources',
                    iconName: 'docs',
                    iconStyle: { width: 32, height: 37 },
                    textWidth: 190
                },
                {
                    title: 'And we’re mobile friendly!',
                    iconName: 'phone',
                    iconStyle: { width: 31, height: 37 }
                }
            ],
            title: 'Approved for a move? Your personalized experience awaits.',
            description: 'After you have been approved for a move, you\'ll be able to view and manage all your move details right here on the website.'
        },
        view3: {
            description: "Throughout the website you will find common questions that employees have had in the past. During your move, you will have a dedicated Talent Mobility Consultant who will support you along the way.",
        }
    }
};

class ReactExtraContent extends React.PureComponent<ReactExtraContentProps> {
    constructor(props) {
        super(props);
        this.state = {
            views: {}
        };
    }

    componentDidMount(): void {
        if (this.props.userProfile.role && ['Assignee', 'HrManager'].includes(this.props.userProfile.role)) {
            this.setState({ views: views[this.props.userProfile.role] });
        }
    }

    componentWillReceiveProps(nextProps: ReactExtraContentProps): void {
        if (this.props.userProfile.role !== nextProps.userProfile.role) {
            this.setState({ views: views[nextProps.userProfile.role] });
        }
    }

    overviewButtonTitles: Array<string> = ["Tell me more", "One more thing", "Continue to Site"];

    handleOverviewClose = (): void => {
        this.props.toggleSiteOverview(false);
        SiteOverviewAPI.setOverviewShowedValue(true);
    };

    render() {
        const { userProfile } = this.props;
        const { views } = this.state;

        if (!views) {
            return null;
        }

        return (
            <React.Fragment>
                <SiteOverview
                    onClose={this.handleOverviewClose}
                    buttonTitles={this.overviewButtonTitles}
                    isOpen={this.props.siteOverviewIsOpen && userProfile.email}
                >
                    <OverviewInfoContent
                        assetType="WEBSITE_OVERVIEW"
                        description="We've made it easy to get all the information you need based on the type of move and the stage of the journey, so you know exactly what to do and when."
                        {...views.view1}
                    />
                    <MyMoveOverview {...views.view2} />
                    <OverviewInfoContent
                        assetType="WEBSITE_OVERVIEW"
                        assetId="3"
                        title={"You probably have a lot of questions. \n Don’t worry, we’ve got you covered."}
                        {...views.view3}
                    />
                </SiteOverview>
                {!!userProfile.email && <BrowserSupportBanner/>}
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReactExtraContent);
