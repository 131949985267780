// @flow

import { config } from 'configs';
import { Api } from 'backend';

const TasksAPI = {
    getTasks: (relocationID: string): Promise<any> =>
        Api.get({path: `/sirva/relocations/${relocationID}/tasks`})
        .then(c=> {
            if(c && c.result)
            { 
                c.result = c.result.filter(t=>!t.taskComplete && t.taskReady);
            }
         return c}),
    getSirvaFrameUrl:(taskLinkUrl: string): string =>
        taskLinkUrl ? `${config.sirvaUrl}/${taskLinkUrl}` : '',
    getSirvaEditExpenceReportFrameUrl:(relocationID: string): string =>
        relocationID ? `${config.sirvaUrl}/external/auth0login.aspx?returnUrl=/SSO/GlobalBankInformation.aspx?relocationID=${relocationID}` : ''
};

export default TasksAPI;