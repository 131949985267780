// @flow

import React, { PureComponent } from 'react';
import { onHistoryLinkClicked } from '../../../utils';

export default class HistoryA extends PureComponent {  
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e) {
        onHistoryLinkClicked({
            category: 'external',
            action: 'click-to-link',
            label: e.target.href,
        });
        return false;
    }
    render() {
      const {
        to,
        children,
        ...rest
      } = this.props;
      return (<a {...rest} className={this.props.className} onClick={this.handleClick}>{children}</a>);
    }
  }