// @flow

import React, { PureComponent } from 'react';
import './styles.scss';

type StepProps = {
    onClick: Function,
    label: string,
    index: number,
    stepKey: string,
    isLastStep: boolean,
    isActive: boolean,
};

export default class StepComponent extends PureComponent<StepProps> {

    handleClick = () => {
        const {
            stepKey: key,
            index,
        } = this.props;
        this.props.onClick({
            key,
            index
        });
    };

    render() {
        return (
            <div
                className={
                    `StepComponent flexible vertical aCenter jStart
                    ${this.props.isLastStep ? 'last' : ''}
                    ${this.props.isActive ? 'active' : ''}`
                }
                onClick={this.handleClick}
            >
                <div className="circle" />
                <div className="step-label">
                    {this.props.label}
                </div>
            </div>
        );
    }
}
