import personWorld from 'components/common/Images/personWorld.svg';
import person from 'components/common/Images/person.svg';
import React, { Component } from 'react'
import {MidSectionComponent, Link, TaskListComponent, HelpComponent,
  QuestionsListCard, SectionHeading} from 'components/common';

export default class IntInHostStep extends Component {

  render() {
    return (
      <div>
        <MidSectionComponent title = "Manage Your Health" className="IntInHostStep" hideDivider="true" col1={
            <div className="flexible vertical">
            <h3>Enroll Your Dependents</h3>
            <p>You will be able to enroll your dependents 7-10 days after your official start date. You must complete enrollment within 60 days of your official start date or you and your dependents may experience a gap in coverage.</p>
            <Link text="Benefits Service Center Contact" action='benefit-info'  onClick={() => window.location.replace('/benefit-info')}/>
            </div>
            }

            col2={
            <div className="flexible vertical  pad-left">
            <h3>Get Medical ID Cards</h3>
            <p>Medical ID Cards will be sent to you 7-10 business days after your official start date or from the date of enrollment. You can also review your membership ID online.</p>
            <Link text="Additional Details"  action='benefit-info'  onClick={() => window.location.replace('/benefit-info')}/>
            </div>
            }

            col3={
            <div className="flexible vertical  pad-left">
            <h3>Change Your Coverage</h3>
            <p>If you have a status change during the year (such as getting married or having or adopting a baby), you can update your coverage on the For Your Benefit™ (FYB) Website. Enrollment must be completed within 60 days of the life event. </p>
            <Link text="For Your Benefit™" action='benefit-info'  onClick={() => window.location.replace('/benefit-info')}/>
            <Link className="step-second-link" text="More Details" action='benefit-info'  onClick={() => window.location.replace('/benefit-info')}/>
            </div>
            }

            col4={
              <div className="flexible vertical  pad-left">
              <h3>Find Local Doctors</h3>
              <p>CIGNA Global Health Benefits provides access to broad worldwide networks of doctors and hospitals in more than 205 countries and jurisdictions totaling more than 147,000 health care professionals worldwide, and 550,000 in the United States.</p>
              <Link text="CIGNA Envoy" action='benefit-info'  onClick={() => window.location.replace('/benefit-info')}/>
              </div>
              }
        />

        <div className="question-part steps-columns-section">
          <div className="question-block flexible">
            <div className="flexible vertical">
              <SectionHeading 
                eyebrow="Global Experiences"
                title="Our Commitment to You"
                className="section-heading mbottom-40"
              />
              <QuestionsListCard
                  key={1}
                  icon={personWorld}
                  title="Career Support & Objectives"
                  body={
                    <div>
                    <p>An international assignment is an important factor in your career development and progression. Ongoing communication and planning between home and host Business and HR Management is important to a successful assignment and the transition to a next role post assignment. Partnering with your home and host Business and HR Management to ensure the following best practices take place will optimally support your assignment.</p>
                      <ul>
                        <li>
                          Clearly stated objectives and goals specific to the assignment
                        </li>
                        <li>
                          An understanding of potential post-assignment plans
                        </li>
                        <li>
                          End of assignment planning six months in advance of assignment end date
                        </li>
                      </ul>
                    </div>
                  }
              />
              <QuestionsListCard
                  key={2}
                  icon={person}
                  title="Your Role"
                  body={
                    <div>
                    <p>You are ultimately responsible for your career, performance and post-assignment career choices. Maintaining a connection to your home location and your professional networks and building these at your host location are important activities and will require your active participation. We encourage you to optimize the opportunities that a global assignment can bring and formally plan and execute these activities on a consistent basis.</p>
                    </div>
                  }
              />
            </div>
            <div className="flexible vertical">
                <TaskListComponent taskList={this.props.getTasks()}
                    title={this.props.getTasksTitle()}
                />
                {this.props.activeRelocation ? <HelpComponent title="We're here to help" mode="profile"/> : null}
            </div>
        </div>
        </div>

      </div>
    )
  }
}
