import React, { Component } from 'react';
import {MidSectionComponent, ColumnComponent} from 'components/common';
import { Icon } from 'components/common';

export default class IntGetStartedStep extends Component {

  howItWorkData = [
      {
        title: 'Step 1',
        icon: 'people',
        desc: 'The employee and manager identify a move opportunity.',
        estimateTime: 'This time varies',
      },
      {
          title: 'Step 2',
          icon: 'personWorld',
          desc: 'A formal job with a position code is created or identified, to be used in the assignment / transfer process.',
          estimateTime: 'Generally takes 1-2 business days',
      },
      {
          title: 'Step 3',
          icon: 'screen',
          desc: 'Then, the manager initiates the talent movement process.',
          estimateTime: 'Generally takes about 15-30 minutes',
      },
      {
          title: 'Step 4',
          icon: 'mail',
          desc: 'The employee provides any required family information.',
          estimateTime: 'Generally takes about 15 minutes of your time',
      },
      {
          title: 'Step 5',
          icon: 'approval',
          desc: 'Formal approvals are received for funding, due diligence analysis is completed, and the details of the relocation package are finalized. ',
          estimateTime: 'Generally takes 2-4 business days',
      },
      {
          title: 'Step 6',
          icon: 'scroll',
          desc: 'Global Assignment Letter is reviewed and signed by the employee. ',
          estimateTime: 'Generally takes about 1 business day \n',
      },
      {
          title: 'Step 7',
          icon: 'moving',
          desc: 'Authorized services are initiated so employee can begin move preparations. ',
          estimateTime: 'Generally takes 1 business day following all approvals',
      },
      {
          title: 'Step 8',
          icon: 'calendar',
          desc: 'Employee services consultations are scheduled.',
          estimateTime: 'Generally takes about an hour of your time',
      },
      {
          title: 'Step 9',
          icon: 'world',
          desc: 'Preparations and Planning for relocation begins.',
          estimateTime: 'Due to immigration processes, this can vary from 3 weeks to 5 months',
      },
  ];

  render() {
    return (
      <div>

        <MidSectionComponent title = "There is a strategy behind every Talent Move" col1={
          <div className="flexible vertical pad-right">
            <p>International assignments and global moves are an important factor in an employee's career development and progression. They are also important to help Johnson & Johnson meet business goals and drive innovation and diverse thinking across the organization. As such, every international assignment / talent move should have a clear strategic intent, objectives and deliverables.</p>
          </div>
          }

          col2={
          <div className="flexible vertical pad-left">
          <p >J&J strategic intents fall into various categories. Some examples are:</p>
          <ul>
            <li>Filling interim skill/competency gaps in local markets</li>
            <li>Bringing specialized expertise to accelerate key business initiatives</li>
            <li>Developing knowledge and/or expertise of early career talent with the expectation of building capabilities</li>
            <li>By nomination in Leadership Development Programs (i.e., functional programs in Operations, Finance, HR, IT, and others)</li>
          </ul>
          </div>
          }
      />

      <ColumnComponent
        title='How the Process Works'
        className="how-the-process-works"
        data={this.howItWorkData}
        col1={

            <div className="card">
              <Icon name="people" width={40} height={40}/>
              <h3>Step 1</h3>
              <p className="truncate fourLines">The employee and manager identify a move opportunity.</p>
              <div className="estimate">
                <hr/>
                <Icon name="timeRed"/><span className="time-label">Estimated Time:</span><br/>
                <span className='time-desc'>This time varies</span>
              </div>
            </div>

        }
        col2={

          <div className="card">
            <Icon name="personWorld" width={40} height={40}/>
            <h3>Step 2</h3>
            <p className="truncate fourLines">The manager creates a position in the J&J system, if needed, and initiates the talent movement process.</p>
            <div className="estimate">
            <hr/>
            <Icon name="timeRed"/><span className="time-label">Estimated Time:</span><br/>
            <span className='time-desc'>Generally takes 2-4 business days</span>
            </div>
          </div>
        }
        col3={

          <div className="card">
            <Icon name="mail" width={40} height={40}/>
            <h3>Step 3</h3>
            <p className="truncate fourLines">The employee receives an email with a link to a form enabling them to provide additional family details if needed.</p>
            <div className="estimate">
            <hr/>
            <Icon name="timeRed"/><span className="time-label">Estimated Time:</span><br/>
            <span className='time-desc'>Generally takes about 15 minutes</span>
            </div>
          </div>
        }
        />

      </div>
    )
  }
}
