import { Api } from 'backend';
import qs from 'qs';

export default {
    getTaxMilestones: (homeCountry, hostCountry) => {
        let home = homeCountry;
        let host = hostCountry;

        
		const search = qs.parse(window.location.search.replace('?', ''));

		home = search.homeCode || homeCountry;
		host = search.hostCode || hostCountry;
   

        if (!home || !host) {
            return;
        }

        return Api.get({ path: `/tax/${home}/${host}` });
    }
};
