import React from 'react';
import { withFormsy } from 'formsy-react';

import TextField from '../elements/TextField';
import { ValidationContext } from '../ValidatableForm';
import { getRequiredLabel, getLabel } from '../utils';

class FormsyText extends React.Component {
    changeValue = event => {
        this.props.setValue(event.target.value);
        this.props.onChange && this.props.onChange(event);
    };

    render() {
        const { props } = this;
        const label = props._required ? getRequiredLabel(props.label) : getLabel(props.label);

        return (
            <ValidationContext.Consumer>
                {(showValidationMessages) => (
                        <TextField
                            disabled={props.disabled}
                            type={this.props.type}
                            onChange={this.changeValue}
                            label={label}
                            value={this.props.getValue()}
                            helperText={props.helperText}
                            errorMessages={props.getErrorMessages()}
                            showValidationMessages={showValidationMessages}
                            dataKey={props.dataKey}
                        />
                    )}
            </ValidationContext.Consumer>
        );
    }
}

export default withFormsy(FormsyText);
