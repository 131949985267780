import React from 'react';
import { connect } from "react-redux";
import TaxMilestonesHeader from './TaxMilestonesHeader';
import TaxMilestonesTable from './TaxMilestonesTable';
import TaxMilestonesFooter from './TaxMilestonesFooter';
import './styles.scss';
import { getAssignmentHeader, getUserProfile } from "selectors/dataSelectors";
import { assignmentHeaderService, taxMilestonesService } from "services/dataServices";

class TaxMilestones extends React.PureComponent {
    componentDidMount(): void {
        if (this.props.user.currentRelocationId) {
            this.getData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.currentRelocationId !== this.props.user.currentRelocationId) {
            this.getData();
        }
    }

    getData() {
        this.props.getAssignmentHeader(this.props.user.currentRelocationId)
            .then(res => {
                if (res && res.result) {
                    const userProfile = res.result;

                    if (userProfile.homeCountry && userProfile.homeCountry.isoCode && userProfile.hostCountry && userProfile.hostCountry.isoCode) {
                        return this.props.getTaxMilestones(userProfile.homeCountry.isoCode, userProfile.hostCountry.isoCode);
                    }
                }

                return this.props.history.push('/profile/international/explore');
            });
    }

    render() {
        return (
            <section className="TaxMilestones">
                <div className="inner">
                    <h1 className="header-text" data-key="tax_milestones_title">My Tax Returns</h1>
                    <TaxMilestonesHeader/>
                    <TaxMilestonesTable/>
                </div>
                <TaxMilestonesFooter/>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    user: getUserProfile(state),
    assignmentHeader: getAssignmentHeader(state)
});

const mapDispatchToProps = {
    getAssignmentHeader: assignmentHeaderService.actions.getAssignmentHeader,
    getTaxMilestones: taxMilestonesService.actions.getTaxMilestones
};

export default connect(mapStateToProps, mapDispatchToProps)(TaxMilestones);
