import React, { Component } from 'react';
import Typography  from '@material-ui/core/Typography';
import {
    Button,
    QuotesImage,
    QuestionsListCard,
    Icon,
    Asset,
    SectionHeading
} from 'components/common';
import {JnJContainer, JnJGridRow, JnJGridColumn} from 'components/common/JnJGrid';
import InitiateAMoveModalWindow from './InitiateAMoveModalWindow';
import MobilityConnectBlock from '../../../MobilityConnectBlock';

import laboratoryPersonImage from 'components/common/Images/laboratory-person.png';

import './style.scss';

export default class InitiateAMove extends Component {
    state = {
        openedAccordions: [],
        isPopupOpened: false,
    };

    toggleAccordion = (name) => {
        const openedAccordions = this.state.openedAccordions;

        if((openedAccordions.includes(name))) {
          this.setState({ openedAccordions: openedAccordions.filter(accordionName => accordionName !== name) })
        }  else {
          this.setState({ openedAccordions: [...openedAccordions, name] })
        }

        this.forceUpdate();
    };

    openInitiationChecklist = () => {
        new Asset({assetType:'move-initiation-checklist', assetId:'domestic'}).execute()
    }

    togglePopup = () => {
      this.setState({
        isPopupOpened: !this.state.isPopupOpened,
      });
    };

    render() {
        const { openedAccordions, isPopupOpened } = this.state;

        return (
            <div>
                <JnJContainer static>
                    <JnJGridRow>
                        <JnJGridColumn xs={12}>
                            <SectionHeading
                                eyebrow="Get Started"
                                title="How to Initiate a U.S. Domestic Move"
                                description="Your candidate has accepted the job offer and relocation package. Now it’s time to initiate relocation services…"
                            />
                        </JnJGridColumn>
                        <br/>
                        <JnJGridColumn xs={12} md={8}>
                            <h4>Complete the Initiation Request Form</h4>
                            <br/>
                            <Typography variant="body2">
                                In order to initiate relocation services, you will need to complete the Initiation Request Form.
                            </Typography>
                            <br/>
                            <Typography variant="body1">
                                J&J partners with SIRVA, an industry leading relocation services firm. Via this website, you will have access to Sirva Connect, Sirva’s online tool for managing the initiation process and obtaining consistent, accurate and up to date information on status.
                            </Typography>
                            <br/>
                            <Typography variant="body1" >
                                Does your employee have questions and concerns you can not answer?
                            </Typography>
                            <br/>
                            <Typography variant="body1">
                                Sometimes employees need extra help to understand the relocation policy or may even need to tour the city and  get a better sense of whether a move is the right decision for them prior to accepting the offer. We offer a number of decision services to help answer the candidate’s questions & concerns.  These can range from a phone consultation about the policy to a site visit or city tour. To request decision services, use the initiation Request Form, and check the “Pre-Determination” services option.
                            </Typography>
                        </JnJGridColumn>
                        <JnJGridColumn xs={12} md={4}>

                            <div className="sirva-info">
                                <strong>Before you begin, be sure you have:</strong>
                                <ul className="question-parts__list mtop-10 mbottom-10">
                                    <li>Employee Name & Contact Info</li>
                                    <li>New job title, salary, pay grade info</li>
                                    <li>Relocation Package Details</li>
                                    <li>Hiring manager & HR contact</li>
                                    </ul>
                                    <span className="sirva-info-link" onClick={this.openInitiationChecklist}>View Initiation Checklist</span>
                                    <div className="estimate">
                                        <hr/>
                                        <div className="estimate__header mbottom-10">
                                            <Icon name="timeRed"/><span className="time-label">Estimated Time:</span><br/>
                                        </div>
                                        <span className='time-desc'>
                                            Generally takes about 30 minutes of your time
                                            SIRVA will respond in 1-2 business days
                                        </span>
                                    </div>
                            </div>

                        </JnJGridColumn>
                        <JnJGridColumn xs={12}>
                            <Button className="initial-move-service" title="Initiate Move Services" onClick={this.togglePopup} />
                            <br/><br/>
                        </JnJGridColumn>
                    </JnJGridRow>
                </JnJContainer>


            <div className="move-info">


              <QuotesImage
                imageSrc={laboratoryPersonImage}
                title="The employee increased the level of quality in her area of responsibility dramatically, and collaboration cross site improved as well. The feedback from business partners across all departments has been very positive."
                description="J&J Manager"
              />
              <div className="move-info__description-container">
                  <QuestionsListCard
                    key="accordion"
                    body={
                        (
                          <div>
                              <div className="move-info__description">
                                  <div className="question-part">
                                      <h4>
                                          Move Initiation
                                      </h4>
                                      <h2>
                                          Common Questions
                                      </h2>
                                  </div>
                              </div>
                          </div>
                        )
                    }
                  >
                  </QuestionsListCard>
                  <div className="move-info__accordion">
                      <div className="Accordion">
                          <div className={`accordion-item flexible vertical ${openedAccordions.includes('first') ? 'open' : 'close'}`} onClick={() => this.toggleAccordion('first')}>
                              <div className="accordion-header flexible jBetween aCenter">
                  <span className="title">
                    <Icon name="personWorld" width={40} height={40} />
                    How long does the relocation process take?
                  </span>
                                  <div className="icons-block">
                                      <Icon name={openedAccordions.includes('first') ? 'minus' : 'plus'} />
                                  </div>
                              </div>
                              <div className="accordion-body animated fadeInDown flexible vertical">
                                  Relocation timelines vary depending on the position start date and detailed requirements as determined by the job, distance to be moved and candidate / family considerations. All relocation benefits must be completed within 12 months of the transfer, or start of the new job.
                              </div>
                          </div>
                          <div className={`accordion-item flexible vertical ${openedAccordions.includes('second') ? 'open' : 'close'}`} onClick={() => this.toggleAccordion('second')}>
                              <div className="accordion-header flexible jBetween aCenter">
                  <span className="title">
                    <Icon name="scroll" width={40} height={40} />
                    What is a U.S. Domestic Relocation Letter?
                  </span>
                                  <div className="icons-block">
                                      <Icon name={openedAccordions.includes('second') ? 'minus' : 'plus'} />
                                  </div>
                              </div>
                              <div className="accordion-body animated fadeInDown flexible vertical">
                                  If an employee is extended a job offer with relocation support, the details of the relocation package and allowances will be documented in a U.S. Domestic Relocation Letter. The employee will receive this letter in addition to their job offer documentation. The employee is required to sign the letter as well as the associated Relocation Repayment Agreement before services will be initiated.
                              </div>
                          </div>
                          <div className={`accordion-item flexible vertical ${openedAccordions.includes('third') ? 'open' : 'close'}`} onClick={() => this.toggleAccordion('third')}>
                              <div className="accordion-header flexible jBetween aCenter">
                  <span className="title">
                    <Icon name="moving" width={40} height={40} />
                    Who will assist employees with the relocation process?
                  </span>
                                  <div className="icons-block">
                                      <Icon name={openedAccordions.includes('third') ? 'minus' : 'plus'} />
                                  </div>
                              </div>
                              <div className="accordion-body animated fadeInDown flexible vertical">
                                  Johnson & Johnson partners with a third party industry leading relocation services firm. They offer a range of services to help Johnson & Johnson employees manage employee relocations.
                                  <QuestionsListCard
                                    urlText={['Learn More About Talent Mobility Services']}
                                    urlActions={[() => window.location.replace('/about')]}
                                  />
                              </div>
                          </div>
                          <div className={`accordion-item accordion-item_last flexible vertical ${openedAccordions.includes('fourth') ? 'open' : 'close'}`} onClick={() => this.toggleAccordion('fourth')}>
                              <div className="accordion-header accordion-header_last flexible jBetween aCenter">
                  <span className="title">
                    <Icon name="caution" width={40} height={40} />
                    What happens if an employee decides to leave the position after relocating?
                  </span>
                                  <div className="icons-block">
                                      <Icon name={openedAccordions.includes('fourth') ? 'minus' : 'plus'} />
                                  </div>
                              </div>
                              <div className="accordion-body animated fadeInDown flexible vertical">
                                  If an employee is extended a job offer with relocation support, the details of the relocation package and allowances will be documented in a U.S. Domestic Relocation Letter. The employee will receive this letter in addition to their job offer documentation. The employee is required to sign the letter as well as the associated Relocation Repayment Agreement before services will be initiated.
                                  <QuestionsListCard
                                    urlText={['Domestic Relocation Repayment Agreement']}
                                    urlActions={[() => window.location.replace('/resources/managerDom/Agreements')]}
                                  />
                              </div>
                          </div>
                      </div>
                      <div className="move-info__contacts accordion-body flexible vertical">
                        <MobilityConnectBlock/>
                      </div>
                  </div>
              </div>
            <InitiateAMoveModalWindow isOpened={isPopupOpened} onToggle={this.togglePopup} />
          </div>
          </div>
        );
    }
}
