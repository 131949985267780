import { getExpenseReportLineItem } from './index';

export const getExpenseReportLineItemById = (state, lineItemId) => {
    const expenseReport = getExpenseReportLineItem(state);
    const { expenseLineItems } = (expenseReport || {});

    if(expenseLineItems && Array.isArray(expenseLineItems) && lineItemId) {
        return expenseLineItems.find(item => item.lineItemId === Number(lineItemId));
    }
    return {};
};
