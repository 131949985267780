// @flow

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Asset } from 'components/common';
import TextWithLabel from '../TextWithLabel';
import { assignmentHeaderService, relocationsService, taxMilestonesService } from 'services';
import {
    getAssignmentHeader,
    getUserProfile,
    getRelocations
} from 'selectors';

import './styles.scss';

type GalHeaderProps = {
    assignmentHeader: ?Object,
    userProfile: ?Object,
    relocations: ?Object,
    type: 'international' | 'domestic',
    fetchRelocations: Function,
    getAssignmentHeader: Function,
    fromProfile: boolean,
    fromProfileButtons: Array
};

const mapStateToProps = (state) => ({
    assignmentHeader: getAssignmentHeader(state),
    userProfile: getUserProfile(state),
    relocations: getRelocations(state)
});

const mapDispatchToProps = {
    fetchRelocations: relocationsService.actions.fetchRelocations,
    getAssignmentHeader: assignmentHeaderService.actions.getAssignmentHeader,
    getTaxMilestones: taxMilestonesService.actions.getTaxMilestones,
};

class GalHeader extends React.Component<GalHeaderProps> {

    componentWillMount() {
        const { userProfile } = this.props;
        if(!this.props.relocations && userProfile.email) {
            this.props.fetchRelocations(userProfile.email);
        }
        if (userProfile.currentRelocationId) {
            this.props.getAssignmentHeader(userProfile.currentRelocationId);

            if (userProfile.homeCountry && userProfile.homeCountry.isoCode && userProfile.hostCountry && userProfile.hostCountry.isoCode) {
                this.props.getTaxMilestones(userProfile.homeCountry.isoCode, userProfile.hostCountry.isoCode);
            }
        }
    }

    componentWillReceiveProps(nextProps: GalHeaderProps) {

        const { userProfile } = nextProps;
        if( !this.props.userProfile.email && userProfile.email) {
            nextProps.fetchRelocations(userProfile.email);
            if (userProfile.currentRelocationId) {
                this.props.getAssignmentHeader(userProfile.currentRelocationId);

                if (userProfile.homeCountry && userProfile.homeCountry.isoCode && userProfile.hostCountry && userProfile.hostCountry.isoCode) {
                    this.props.getTaxMilestones(userProfile.homeCountry.isoCode, userProfile.hostCountry.isoCode);
                }
            }
        }
    }

    navigateToDocuments = (): void => {
        this.props.history.push('/my-documents/gal');
    };

    navigateToMoves = (): void => {
        this.props.history.push('/your-moves?redirect=assignment-letter');
    };

    render() {
        const { assignmentHeader, relocations, type, fromProfile, fromProfileButtons } = this.props;
        const isDomestic = (type === 'domestic');
        return assignmentHeader ?
            <div className="GalHeader">
                <div className="title-with-buttons flexible horizontal jBetween">
                    <div className="title-with-date flexible vertical">
                        <h1 className="title">
                            { fromProfile ? "Your New Job & Location" : (isDomestic ? 'U.S. Domestic Relocation Letter' : 'Your Global Assignment Letter')}
                        </h1>
                        {!fromProfile ?
                        <p className="effective-date">
                            Effective Date: {assignmentHeader.globalAssigmentLetterDocument ? moment(assignmentHeader.globalAssigmentLetterDocument.createDate).format("MMMM D, YYYY"): ''}
                        </p>
                        : null}
                    </div>
                    {!fromProfile ?
                    <div className="buttons flexible hotizontal jBetween">
                        {
                            (relocations && relocations.length > 1) &&
                            <Button
                                title="Change Move"
                                simple
                                onClick={this.navigateToMoves}
                                dataKey="change move"
                            />
                        }
                    </div>
                    : null }
                </div>
                <div className="relocation-info flexible horizontal jBetween shrink">
                    <div className="countries flexible vertical jBetween">
                        <div className="flexible horizontal withMargin">
                            <div className="flag">
                                <Asset assetType="COUNTRY_FLAG" assetId={assignmentHeader.homeCountry.isoCode}  maxWidth={200} />
                            </div>
                            <div className="country-info">
                                <TextWithLabel
                                    label={ isDomestic ? 'Moving From:' : 'Your Home Country:'}
                                    text={assignmentHeader.homeCountry.name}
                                />
                            </div>
                        </div>
                        <div className="flexible horizontal withMargin">
                            <div className="flag">
                                <Asset assetType="COUNTRY_FLAG" assetId={assignmentHeader.hostCountry.isoCode}  maxWidth={200} />
                            </div>
                            <div className="country-info">
                                <TextWithLabel
                                    label={isDomestic ? 'Moving To: ' : 'Your Host Country:'}
                                    text={assignmentHeader.hostCountry.name}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mid-info-block flexible vertical jBetween">
                        <TextWithLabel
                            className="withMargin"
                            label="Your Job Title:"
                            text={assignmentHeader.newPosition || '-'}
                        />
                        <TextWithLabel
                            className="withMargin"
                            label="Policy:"
                            text={assignmentHeader.policyName || '-'}
                        />
                    </div>
                    <div className="dates flexible vertical jBetween">
                        <TextWithLabel
                            className="withMargin"
                            label="Estimated start date:"
                            text={assignmentHeader.estimatedStartDate ? moment(assignmentHeader.estimatedStartDate, "YYYY-MM-DDThh:mm:ssZ").utc().format('MMMM D, YYYY') : "-"}
                        />
                        { !isDomestic &&
                            <TextWithLabel
                                className="withMargin"
                                label="Estimated End Date:"
                                text={assignmentHeader.estimatedEndDate ? moment(assignmentHeader.estimatedEndDate).utc().format('MMMM D, YYYY') : "-"}
                            />
                        }
                    </div>
                    {fromProfile && fromProfileButtons && fromProfileButtons.length > 0 ?
                    <div className="profile-btns-block flexible vertical">
                        <Button
                            title={fromProfileButtons[0].title}
                            submit
                            onClick={() => this.props.history.push(fromProfileButtons[0].url)}
                            dataKey="profile-btns-1"
                        />
                        {fromProfileButtons.length > 1 ?
                             <Button
                             title={fromProfileButtons[1].title}
                             simple
                             onClick={() => this.props.history.push(fromProfileButtons[1].url)}
                             dataKey="profile-btns-2"
                         />: null}
                    </div>
                    : null }
                </div>
            </div> : <div className="flexible jCenter"><div className="Spinner" /></div>
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GalHeader));
