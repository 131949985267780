import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { History } from "history";
import { moveInitiationService } from "services";
import "./styles.scss";
import { ReviewAndSubmitProps } from "types";
import { Icon, Button, CurrencyFormatter, SpinnerWrapper, CountryFlags, TextCaseFormatter } from "components/common";
import DeletePopup from "./deletePopup";
import { useMediaQuery } from "@material-ui/core";

const ReviewAndSubmit = (props: {
    navigateTo: (to: string, from: string) => void;
    history: History;
    location: Location;
}) => {

    const { state: locationState } = props.history.location;
    
    // const preInitData = props.history.location.state.empCompetitiveInit;
    // const policyData = props.history.location.state.policyData;
    // const financeData = props.history.location.state.financeData;
    // const approvalsData = props.history.location.state.approvalsData;
    // const scenario_key = props.history.location.state.scenario;

    // const newHireData = (props.history.location.state.empCompetitiveInit.offers !== undefined && props.history.location.state.empCompetitiveInit.offers !== null) ? ((props.history.location.state.empCompetitiveInit.offers.hireStatus !== undefined && props.history.location.state.empCompetitiveInit.offers.hireStatus !== null) ? props.history.location.state.empCompetitiveInit.offers.hireStatus : null) : null;
    // const isNewHire = (newHireData !== null) ? (((newHireData).toLowerCase() == "new hire") ? true : false) : false;

    const dispatch = useDispatch();
    // const [isLoading, setIsLoading] = useState(false);
    const [continueButtondisabled, setContinueButtondisabled] = useState(false);
    const [state, setState] = useState({
        empInfoRepChildEle: {},
        reqInfoRepChildEle: {},
        additionalDetailsData: {},
        fundingFinancePartner: {},
        moveType: null,
        relocationPackage: null,
        relocationType: null,
        strategicIndent: null,
        hiringManagerName: null, 
        hiringManagerEmail: null, 
        hiringManagerWWID: null,
        isLoading: false,
        reqInfoAddress: null,
        reqInfoPhone:  null,
        popupOpen: false
    });
    const {moveType, relocationPackage, relocationType, strategicIndent, hiringManagerName, 
        hiringManagerEmail, hiringManagerWWID, empInfoRepChildEle, reqInfoRepChildEle, 
        additionalDetailsData, fundingFinancePartner, isLoading, reqInfoAddress, reqInfoPhone} = state;

    useEffect(() => {
        if(locationState === undefined) {
            props.history.replace("/move-dashboard");
            // props.history.push({
            //      state: {
            //          "moveType": "global",
            //          "policySelection": ""
            //      },
            //  });
         } else {
            
            let empInfoRepChildEle = (locationState.empInfoRepChildEle != null && typeof locationState.empInfoRepChildEle !== 'undefined') ? 
                                        locationState.empInfoRepChildEle : null;
            let reqInfoAddress = (locationState.empInfoRepChildEle != null && typeof locationState.empInfoRepChildEle !== 'undefined') ? 
                                        locationState.empInfoRepChildEle.addresses : null;
            let reqInfoPhone = (locationState.empInfoRepChildEle != null && typeof locationState.empInfoRepChildEle !== 'undefined') ? 
                                        locationState.empInfoRepChildEle.phone : null;

            let reqInfoRepChildEle = (locationState.reqInfoRepChildEle != null && typeof locationState.reqInfoRepChildEle !== 'undefined') ? 
                                        locationState.reqInfoRepChildEle : null;

            let hiringManagerName = (reqInfoRepChildEle != null && reqInfoRepChildEle.Hiring_Manager_Name !== null ? reqInfoRepChildEle.Hiring_Manager_Name.replace(/ *\([^)]*\) */g, "") : null);
            let hiringManagerEmail = (reqInfoRepChildEle != null && reqInfoRepChildEle.Hiring_Manager_Email !== null ? reqInfoRepChildEle.Hiring_Manager_Email : null);
            let hiringManagerWWID = (reqInfoRepChildEle != null && reqInfoRepChildEle.Hiring_Manager_Name !== null ? reqInfoRepChildEle.Hiring_Manager_Name.replace(/.*\(|\)/gi,'') : null);
        
            let additionalDetailsData = {
                startDate: locationState.startDate,
                endDate: locationState.endDate,
                numberOfFlights: locationState.numberOfFlights,
                additionalDetails :locationState.additionalDetails,
                valueInput :locationState.valueInput,
                californiaTier :locationState.californiaTier,
            }
            let fundingFinancePartner = (locationState.partnerName != {} && typeof locationState.partnerName !== 'undefined') ? 
                                        locationState.partnerName : {};
            let moveType = "";
            if(locationState.moveType !== null && locationState.moveType !== 'undefined') {
                if(locationState.moveType === 'Domestic' ) {
                    moveType = "Domestic";
                } else {
                    moveType = "Global";
                }
            }

            setState(prevState => ({
                ...prevState,
                empInfoRepChildEle,
                reqInfoRepChildEle,
                additionalDetailsData,
                fundingFinancePartner,
                moveType: moveType,
                relocationPackage: (locationState.eligibleTitle != null && typeof locationState.eligibleTitle !== 'undefined') ? locationState.eligibleTitle : null,
                relocationType: (locationState.relocationType != null && typeof locationState.relocationType !== 'undefined') ? locationState.relocationType : null,
                strategicIndent: (locationState.strategicIndent  != null && typeof locationState.strategicIndent  !== 'undefined') ? locationState.strategicIndent : null,
                hiringManagerName,
                hiringManagerEmail,
                hiringManagerWWID,
                reqInfoAddress,
                reqInfoPhone,
            }));
         }
    }, []);

    const deleteReqHandler = e => {
        e.preventDefault();
        handlePopupState(e, false);
        setState(prevState => ({
            ...prevState, isLoading: true }));
        let initiationId = (locationState.initiationId !== undefined) ? locationState.initiationId : '';
        dispatch(moveInitiationService.actions.deleteMoveJobCard(initiationId))
        .then(data => {
            setState(prevState => ({
                ...prevState, isLoading: false }));
        if(data.success) {
                props.history.push("/profile");
                console.log('delete success', data);
        } else {
                console.log(data.message);
        }
        })
        .catch(console.log);
    }

    const handlePopupState = (e, stateVal: boolean) => {
        e.preventDefault();
        setState(prevState => ({
            ...prevState, popupOpen: stateVal }));
    }
    
    // const savedataToSirvaMoveDetails = (location : string) => {
    //     setState(prevState => ({
    //         ...prevState,isLoading: true}));
    //     let initiationId = (locationState.initiationId !== undefined) ? locationState.initiationId : '';
    //     dispatch(moveInitiationService.actions.setMoveEmppSirvaDetails(initiationId))
    //     .then(data => {
    //         setState(prevState => ({
    //             ...prevState, isLoading: false}));
    //         if(data.success) {
    //             this.navigateTo(location);
    //         } else {
    //             console.log(data.message);
    //         }
    //     })
    //     .catch(err => console.log(err));
    // }

    // const userData = {
    //     services: "Full Relocation",
    //     policy: null,
    //     isNewHire: isNewHire,
    // };
    // const calMoveTier = {
    //     "Tier1CaliforniaMarket": "Tier 1: California Market", 
    //     "Tier2CriticalSkillsPosition":"Tier 2: Critical Skills Position",
    //     "Tier3BusinessCriticalRole":"Tier 3: Business Critical Role",
    // }
    // const scenario = {
    //     "procurement" : "Leadership Development Program (LDP)",
    //     "FLDP": "MBA Finance Leadership Development Program (FLDP)",
    //     "None": "Not in procurement and is NOT an MBA FLDP"
    // }


    function submitHandler() {
        setState(prevState => ({ ...prevState, isLoading: true }));
        setContinueButtondisabled(true);
        const initiationId = (props.history.location.state.initiationId !== undefined) ? props.history.location.state.initiationId : '';
        dispatch(moveInitiationService.actions.setMoveEmppSirvaDetails(initiationId)).then(data => {
            setState(prevState => ({ ...prevState, isLoading: false }));
            setContinueButtondisabled(false);
            if(data.success) {
                props.history.push({
                    pathname: "/non-competitive-initiation/success",
                    state: {
                        ...props.history.location.state,
                        success: true,
                    },
                });
            } else {
                console.log(data.message);
            }
        })
        .catch(console.log);
    }

    const scrnLg = useMediaQuery('@media screen and (min-width:1280px)', { noSsr: true });

    return (
        <div className="review-and-submit-form non-competitive" style={{ width: `100%` }}>
            <SpinnerWrapper show={isLoading}>
            <div className="eligible-container RecruitedSelector" style={{ 
                width: scrnLg ? 773 : 'initial'
               }}>
                <h1 style={{lineHeight: "44px"}}>
                    Please review and then submit your request
                </h1>
                <div className="top">
                    <div className="move-title">
                        <p>
                            Relocation Package
                        </p>
                        <a 
                                onClick={() => {
                                    props.navigateTo("package-selection", "review-submit");
                                }}
                                className="edit-button"
                            >Edit</a>
                    </div>
                    <div className="top-content">
                        <div className="col">
                            <p>Services Being Initiated:</p>
                            <span>
                                {relocationType}
                            </span>
                        </div>
                        <div className="col">
                            <p>Type of Move:</p>
                            <span>
                                {moveType + " move"}
                            </span>
                        </div>
                        <div className="col">
                            <p>Strategic Intent:</p>
                            <span>
                                {strategicIndent}
                            </span>
                        </div>
                        <div className="col">
                            <p>Relocation Package:</p>
                            <span>
                                {locationState !== undefined && 
                                locationState.lumpSumPolicy === "Yes" ? 
                                "J&J US Domestic Lump Sum" : relocationPackage}
                            </span>
                        </div>
                    </div>
                </div>
                {(locationState !== undefined && locationState.jobID != null && typeof locationState.jobID !== 'undefined') ? (
                <><hr className="line" style={{ marginTop: 10 }} />
                <div className="top">
                    <div className="move-title">
                        <p>
                            Employee
                        <span></span>
                        </p>
                    </div>
                    <div className="top-content">
                        <div className="col">
                            <p>Name:</p>
                            <span>{empInfoRepChildEle!= null ? 
                                TextCaseFormatter(empInfoRepChildEle.firstName) 
                                + ((empInfoRepChildEle.middleName !== null)
                                    ? TextCaseFormatter(empInfoRepChildEle.middleName) + '':
                                    '') + ' ' +TextCaseFormatter( empInfoRepChildEle.lastName) 
                            : null}</span>
                        </div>
                        <div className="col">
                            <p>WWID:</p>
                            <span>{empInfoRepChildEle!= null ?empInfoRepChildEle.employeeNumber : null}</span>
                        </div>
                        <div className="col">
                            <p>Email:</p>
                            <span>{empInfoRepChildEle!= null 
                                ? TextCaseFormatter(empInfoRepChildEle.preferredEmail, "lower-case") 
                                : null}</span>
                        </div>
                        {reqInfoPhone != null && reqInfoPhone.number != null ?
                            <div className="col">
                                <p>Phone:</p>
                                <span>
                                    {reqInfoPhone!= null ?reqInfoPhone.number : null}
                                </span>
                            </div> : null}
                    </div>
                    <hr className="line" style={{ marginTop: 10 }} />
                    <div className="top">
                        <div className="move-title">
                            <p>
                                Current Job
                            <span></span>
                            </p>
                        </div>{/* Current Job - first Row  */}
                        <div className="top-content">
                            <div className="col">
                                <div>
                                <CountryFlags countryCode={empInfoRepChildEle!= null ?empInfoRepChildEle.countryCode : null} countryName={null} />
                                {reqInfoAddress != null && reqInfoAddress.country != null ? <>
                                    <p>
                                        Home Country:
                                    </p>
                                    <span>
                                        {reqInfoAddress!= null ? TextCaseFormatter(reqInfoAddress.country) : null}
                                    </span>
                                </> : null}
                                </div>
                            </div>
                            {reqInfoAddress != null && ((reqInfoAddress.addressLine1 != null && reqInfoAddress.addressLine1 !== "") ||
                            (reqInfoAddress.addressLine2 != null && reqInfoAddress.addressLine2 !== "") ||  
                            (reqInfoAddress.city != null && reqInfoAddress.city !== "") || 
                            (reqInfoAddress.stateProvince != null && reqInfoAddress.stateProvince !== "") || 
                            (reqInfoAddress.postalCode != null && reqInfoAddress.postalCode !== "") ) 
                                ?   <div className="col">
                                    <p className="move-details-titles">
                                        Current Office:
                                    </p>
                                    {reqInfoAddress.addressLine1 !== null && reqInfoAddress.addressLine1 !== "" ? (
                                        <>
                                            {TextCaseFormatter(reqInfoAddress.addressLine1)} <br />
                                        </>
                                        ) : null}
                                    {reqInfoAddress.addressLine2 !== null && reqInfoAddress.addressLine2 !== "" ? (
                                        <>
                                            {TextCaseFormatter(reqInfoAddress.addressLine2)}
                                        </>
                                        ) : null}
                                    {reqInfoAddress.city !== null && reqInfoAddress.city !== "" ? (
                                        <>
                                            {TextCaseFormatter(reqInfoAddress.city)} 
                                        </>
                                        ) : null}
                                    {reqInfoAddress.stateProvince !== null && reqInfoAddress.stateProvince !== "" && reqInfoAddress.stateProvince != undefined ? (
                                            <>
                                                , {(reqInfoAddress.stateProvince).length <= 2 ? TextCaseFormatter(reqInfoAddress.stateProvince,"uper-case") : TextCaseFormatter(reqInfoAddress.stateProvince, "sentence-case")} 
                                            </>
                                            ) : null}
                                    {reqInfoAddress.postalCode !== null && reqInfoAddress.postalCode !== "" ? 
                                    '/' + reqInfoAddress.postalCode
                                    : null }
                            </div> : null}
                            <div className="col">
                                <p>Current Job Title:</p>
                                <span>{empInfoRepChildEle!= null 
                                    ? TextCaseFormatter(empInfoRepChildEle.jobTitle) : null}
                                    </span>
                            </div>
                            {empInfoRepChildEle != null && 
                            empInfoRepChildEle.compensationGrade != undefined && 
                            empInfoRepChildEle.compensationGrade != "" && 
                            empInfoRepChildEle.compensationGrade != null ?
                            <div className="col">
                                <p>Pay Grade:</p>
                                <span>{(empInfoRepChildEle != null && 
                                    typeof empInfoRepChildEle.compensationGrade !== 'undefined') 
                                    ? TextCaseFormatter(empInfoRepChildEle.compensationGrade) : null}</span>
                            </div> : null}
                        </div>{/* Current Job - second Row  */}
                        {/** commenting  Current Salary */}
                        {/* <div className="top-content">
                            <div className="col">
                                <p>&nbsp;</p>
                                <span>&nbsp;</span>
                            </div>
                            <div className="col">
                                <p>Current Salary:</p>
                                <span>{(empInfoRepChildEle!= null && 
                                    empInfoRepChildEle.annualizedCurrency !== undefined ) ? (
                                    <CurrencyFormatter 
                                        currencyCode={empInfoRepChildEle.annualizedCurrency} 
                                        amount={empInfoRepChildEle.annualizedAmount} 
                                    />
                                ) : null}</span>
                            </div>
                            <div className="col">
                                <p>&nbsp;</p>
                                <span>&nbsp;</span>
                            </div>
                            <div className="col">
                                <p>&nbsp;</p>
                                <span>&nbsp;</span>
                            </div>
                        </div> */}{/* Current Job - third Row  */}
                        <div className="top-content">
                            <div className="col">
                                <p>&nbsp;</p>
                                <span>&nbsp;</span>
                            </div>
                            <div className="col">
                                <p>
                                    Home Manager:
                                </p>
                                <span>
                                    {empInfoRepChildEle!= null ? TextCaseFormatter(empInfoRepChildEle.manager) : null}
                                </span>
                            </div>
                            <div className="col">
                                <p>
                                    Home Manager Email:
                                </p>
                                <span>
                                    {empInfoRepChildEle!= null ? TextCaseFormatter(empInfoRepChildEle.managerEmail,"lower-case") : null}
                                </span>
                            </div>
                            <div className="col">
                                <p>
                                    Home Manager WWID:
                                </p>
                                <span>
                                    {empInfoRepChildEle!= null ?empInfoRepChildEle.homeManagerWWID : null}
                                </span>
                            </div>
                        </div>
                    </div>
                    <hr className="line" style={{ marginTop: 10 }} />
                    <div className="top">
                        <div className="move-title">
                            <p>New Job</p><a 
                                onClick={() => {
                                    props.navigateTo("emp-job-details", "review-submit");
                                }}
                                className="edit-button"
                            >Edit</a>
                        </div>{/* New Job - first Row  */}
                        <div className="top-content">
                            <div className="col">
                                <div>
                                <CountryFlags countryCode={reqInfoRepChildEle != null 
                                    ? reqInfoRepChildEle.countryCode : null} countryName={null} />
                                {reqInfoRepChildEle != null && reqInfoRepChildEle.Country != null && 
                                (reqInfoRepChildEle.countryCode).toLowerCase() == "na" && 
                                (reqInfoRepChildEle.Country).toLowerCase() == "zimbabwe" ? null :
                                    <>
                                        <p>
                                        Host Country:
                                        </p>
                                        <span>
                                            {reqInfoRepChildEle != null 
                                            ? TextCaseFormatter(reqInfoRepChildEle.Country) : null}
                                        </span>
                                    </>}
                                </div>
                            </div>
                            {/** commenting  Current Salary */}
                            {/* <div className="col">
                                <p>Current Salary:</p>
                                <span>{(empInfoRepChildEle!= null && 
                                    empInfoRepChildEle.annualizedCurrency !== undefined ) ? (
                                    <CurrencyFormatter 
                                        currencyCode={empInfoRepChildEle.annualizedCurrency} 
                                        amount={empInfoRepChildEle.annualizedAmount} 
                                    />
                                ) : null}</span>
                            </div> */}
                            {reqInfoRepChildEle != null && ((reqInfoRepChildEle["Primary_Address_-_Line_1"] != null && reqInfoRepChildEle["Primary_Address_-_Line_1"] !== "") ||  
                            (reqInfoRepChildEle.City != null && reqInfoRepChildEle.City !== "") || 
                            (reqInfoRepChildEle.State != null && reqInfoRepChildEle.State !== "") || 
                            (reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== null && 
                            reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== "") ) ?
                                <div className="col">
                                    <p className="move-details-titles">
                                        New Office:
                                    </p>
                                    {reqInfoRepChildEle["Primary_Address_-_Line_1"] !== null && reqInfoRepChildEle["Primary_Address_-_Line_1"] !== "" ? (
                                        <>
                                            {TextCaseFormatter(reqInfoRepChildEle["Primary_Address_-_Line_1"])} <br />
                                        </>
                                        ) : null}
                                    {reqInfoRepChildEle.City !== null && reqInfoRepChildEle.City !== "" ? (
                                        <>
                                            {TextCaseFormatter(reqInfoRepChildEle.City)} 
                                        </>
                                        ) : null}
                                    {reqInfoRepChildEle.State !== null && reqInfoRepChildEle.State !== "" && reqInfoRepChildEle.State != undefined ? (
                                            <>
                                                , {(reqInfoRepChildEle.State).length <= 2 ? TextCaseFormatter(reqInfoRepChildEle.State,"uper-case") : TextCaseFormatter(reqInfoRepChildEle.State, "sentence-case")} 
                                            </>
                                            ) : null}
                                    {reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== null && reqInfoRepChildEle["Primary_Address_-_Postal_Code"] !== "" ? 
                                    '/' + reqInfoRepChildEle["Primary_Address_-_Postal_Code"]
                                    : null }
                                    {/* <span>
                                        {reqInfoRepChildEle != null ? TextCaseFormatter(reqInfoRepChildEle.Country) : null}
                                    </span> */}
                                </div> : null}
                                <div className="col">
                                    <p className="move-details-titles">
                                        New Job Title:
                                    </p>
                                    <span>
                                        {reqInfoRepChildEle!= null 
                                        ? TextCaseFormatter(reqInfoRepChildEle.Job_Posting_Title) : null}
                                    </span>
                                </div>
                                {(reqInfoRepChildEle!= null && (reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req !== null && 
                                reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req !== "" && 
                                reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req !== undefined) || 
                                (reqInfoRepChildEle.Job_Family_From_Position !== null && 
                                reqInfoRepChildEle.Job_Family_From_Position !== "" && 
                                reqInfoRepChildEle.Job_Family_From_Position !== undefined) ) ?
                                <div className="col">
                                    <p className="move-details-titles">
                                        New Pay Grade:
                                    </p>
                                    <span>
                                    {(reqInfoRepChildEle != null && 
                                        reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req != null 
                                        && reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req != undefined) 
                                        ? TextCaseFormatter(reqInfoRepChildEle.Job_Family_Comp_Grade_From_Job_Req) 
                                        : (reqInfoRepChildEle != null && 
                                        reqInfoRepChildEle.Job_Family_From_Position != null 
                                        && reqInfoRepChildEle.Job_Family_From_Position != undefined 
                                        ? TextCaseFormatter(reqInfoRepChildEle.Job_Family_From_Position) : null)}
                                    </span>
                                </div> : null}
                            <div className="col">
                                <p className="move-details-titles">
                                    Job ID:
                                </p>
                                <span>
                                {reqInfoRepChildEle != null ? reqInfoRepChildEle.Job_Requisition_ID : null}
                                </span>
                            </div>
                        </div>{/* New Job -second row */}
                        <div className="top-content">
                            <div className="col empty-col">
                                <p>&nbsp;</p>
                                <span>&nbsp;</span>
                            </div>
                            <div className="col">
                                <p className="move-details-titles">
                                    New Salary:
                                </p>
                                <span>
                                    {(additionalDetailsData!= null && 
                                    additionalDetailsData.valueInput !== undefined ) ? (
                                        <CurrencyFormatter 
                                            currencyCode={reqInfoRepChildEle.Salary_Currency} 
                                            amount={additionalDetailsData.valueInput} 
                                        />
                                    ) : null }
                                </span>
                            </div>
                            <div className="col empty-col">
                                <p>&nbsp;</p>
                                <span>&nbsp;</span>
                            </div>
                            <div className="col empty-col">
                                <p>&nbsp;</p>
                                <span>&nbsp;</span>
                            </div>
                            <div className="col empty-col">
                                <p>&nbsp;</p>
                                <span>&nbsp;</span>
                            </div>
                        </div>{/* New Job - third row */}
                        <div className="top-content">
                            <div className="col empty-col">
                                <p>&nbsp;</p>
                                <span>&nbsp;</span>
                            </div>
                            <div className="col">
                                <p className="move-details-titles">
                                    Host Manager:
                                </p>
                                <span>
                                    {hiringManagerName != null ? TextCaseFormatter(hiringManagerName) : null}
                                </span>
                            </div>
                            <div className="col">
                                <p className="move-details-titles">
                                    Host Manager Email:
                                </p>
                                <span>
                                    {hiringManagerEmail != null ? TextCaseFormatter(hiringManagerEmail,"lower-case") : null}
                                </span>
                            </div>
                            <div className="col">
                                <p className="move-details-titles">
                                    Host Manager WWID:
                                </p>
                                <span>
                                    {hiringManagerWWID != null ? hiringManagerWWID : null}
                                </span>
                            </div>
                            <div className="col empty-col">
                                <p>&nbsp;</p>
                                <span>&nbsp;</span>
                            </div>
                        </div>
                    </div>
                </div></>) : null }
                <hr className="line" style={{ marginTop: 10 }} />
                <div className="top">
                    <div className="move-title">
                        <p>Additional Move Details</p><a 
                            onClick={() => {
                                props.navigateTo("additional-move-details", "review-submit");
                            }}
                            className="edit-button"
                        >Edit</a>
                    </div>{/* add det - first Row  */}
                    <div className="top-content">
                    {(additionalDetailsData.californiaTier != null && 
                    typeof additionalDetailsData.californiaTier !== 'undefined') ? (
                        <div className="col">
                            <p>California moves:</p>
                            <span>{additionalDetailsData.californiaTier}</span>
                        </div>): null}
                        <div className="col">
                            <p className="move-details-titles">
                                New Base Salary:
                            </p>
                            <span>
                                <CurrencyFormatter 
                                    currencyCode={''} 
                                    amount={additionalDetailsData.valueInput} 
                                />
                            </span>
                        </div>
                        <div className="col">
                            <p className="move-details-titles">
                                Special Instructions:
                            </p>
                            <span>
                            {additionalDetailsData.additionalDetails}
                            </span>
                        </div>
                        {(additionalDetailsData.numberOfFlights != null && 
                            additionalDetailsData.numberOfFlights != "" && 
                            typeof additionalDetailsData.numberOfFlights !== 'undefined') ? (
                            <div className="col">
                                <p className="move-details-titles">
                                    Commuter Flights:
                                </p>
                                <span>
                                    {additionalDetailsData.numberOfFlights}
                                </span>
                        </div>) : <div className="col empty-col">
                            <p>&nbsp;</p>
                            <span>&nbsp;</span>
                        </div>}
                        <div className="col empty-col">
                            <p>&nbsp;</p>
                            <span>&nbsp;</span>
                        </div>
                    </div>
                </div>
                <hr className="line" style={{ marginTop: 10 }} />
                <div className="top">
                    <div className="move-title">
                        <p>Finance Details</p>
                        <span> <a 
                            onClick={() => {
                                props.navigateTo("finance-admin", "review-submit");
                            }}
                            className="edit-button"
                        >Edit</a></span>
                    </div>{/* finance det - first Row  */}
                    <div className="top-content">
                    <div className="col">
                        <section>
                            <p>
                                Funding Finance Partner:
                            </p>
                            <span className="move-details-sub-heading">
                                {fundingFinancePartner != null 
                                    ? TextCaseFormatter(fundingFinancePartner.firstName)  + ' ' 
                                    + TextCaseFormatter(fundingFinancePartner.lastName) : null}
                            </span>
                        </section>
                        <section>
                            <span className="move-details-sub-heading">
                                {fundingFinancePartner != null 
                                    ? TextCaseFormatter(fundingFinancePartner.email, "lower-case") 
                                    : null}
                            </span>
                        </section>
                        <section>
                            <span className="move-details-sub-heading">
                                {fundingFinancePartner != null ? fundingFinancePartner.wwid : null}
                            </span>
                        </section>
                    </div>
                    </div>
                </div>
                <DeletePopup 
                    open={state.popupOpen} 
                    onClose={e => handlePopupState(e, false)} 
                    confirm={deleteReqHandler}
                /> 
                <hr className="line" style={{ marginTop: 35 }} />
                <section className="button-section" style={{ padding: `50px 0px` }}>
                <div className={"flexible"} style={{ justifyContent: 'space-between' }}>
                    <Button
                        title="Go Back"
                        className="job-details-submit"
                        style={{ backgroundColor:"#FFFFFF", border:"1px solid #CA001B", minWidth: 150 }}
                        labelStyle={{color:"#CA001B"}}
                        onClick={() => {
                            props.navigateTo("finance-admin", "review-submit")
                        }}
                    />
                     <Button
                        title={<><Icon name="trash" />
                        Delete this service request</>}
                        className="job-details-submit"
                        style={{ backgroundColor:"#FFFFFF", border:"1px solid #000099", minWidth: 150 }}
                        labelStyle={{color:"#000099"}}
                        onClick={e => handlePopupState(e, true)}
                    />
                    <Button 
                        className="submitButton" 
                        onClick={submitHandler}
                        style={{ backgroundColor:"#CA001B", border:"1px solid #CA001B", minWidth: 150 }}
                        disabled={continueButtondisabled}
                        title="Submit"
                    />
                </div>
                </section>
            </div>
            </SpinnerWrapper>
        </div>
    );
};

export default ReviewAndSubmit;
