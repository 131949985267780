import React, { PureComponent } from 'react';
import HistoryA from '../../components/common/HistoryA';
import './style.scss';
export default class PrivacyPolicy extends PureComponent {
    render() {
        return (
<div className="privacy-main">
<h1>Privacy Policy</h1>
<p><strong >Johnson &amp; Johnson Services, Inc.</strong> cares about your privacy and wants you to be familiar with how we collect, use, and disclose information. This Privacy Policy describes our practices in connection with information that we or our service providers collect through the website or application (hereinafter the “<strong>Service</strong>”) operated and controlled by us from which you are accessing this Privacy Policy. By providing personal information to us or by using the Service, you acknowledge that you have read and understand this Privacy Policy.</p>
<p><strong>USE BY MINORS</strong></p>
<p>The Service is not directed to individuals under the age of <strong>18</strong>, and we request that these individuals not provide personal information through the Service. If your child has submitted Personal Information and you would like to request that such Personal Information be removed, please contact us as explained below under Contacting Us.</p>
<p><strong>INFORMATION COLLECTION</strong></p>
<p>We may ask you to submit personal information in order for you to benefit from certain features (such as newsletter subscriptions, tips/pointers, or order processing) or to participate in a particular activity (such as sweepstakes or other promotions). You will be informed what information is required and what information is optional.</p>
<p>We may combine the information you submit with other information we have collected from you, whether on‑ or offline, including, for example, your purchase history. We may also combine it with information we receive about you from the following other sources: Johnson &amp; Johnson Operating Companies, publicly available information sources (including information from your publicly available social media profiles), and other third parties.<u> </u></p>
<p>If you submit any personal information relating to another individual to us, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.</p>
<p><strong>SENSITIVE INFORMATION</strong></p>
<p>Unless we specifically request or invite it, we ask that you not send us, and you not disclose, any sensitive personal information (e.g., Social Security numbers, information related to racial or ethnic origin, political opinions, religion or philosophical beliefs, health, sex life or sexual orientation, criminal background, or trade union membership, or biometric or genetic data for the purpose of uniquely identifying an individual) on or through the Service or otherwise to us. </p>
<p><strong>AUTOMATIC INFORMATION COLLECTION AND USE</strong></p>
<p>We and our service providers may collect certain information automatically as you navigate around the Service. Please read the <a href="/cookiepolicy" >Cookie Policy</a> for detailed information about the cookies and other tracking technologies used on the Service. The Cookie Policy includes information on how you may disable these technologies. If you do not disable them and continue to use our Service, we will infer your consent to their use.</p>
<p>We and our service providers may also automatically collect and use information in the following ways:</p>
<p><strong>Through your browser</strong>: Certain information is collected by most browsers, such as your Media Access Control (MAC) address, computer type (Windows or Mac), screen resolution, operating system name and version, and Internet browser type and version. We may collect similar information, such as your device type and identifier, if you access the Service through a mobile device. We use this information to ensure that the Service functions properly.</p>
<p><strong>IP address</strong>: Your IP address is a number that is automatically assigned to your computer by your Internet Service Provider. An IP address is identified and logged automatically in our server log files whenever a user visits the Service, along with the time of the visit and the pages visited. Collecting IP addresses is standard practice and is done automatically by many online services. We use IP addresses for purposes such as calculating Service usage levels, diagnosing server problems, and administering the Service. We may also derive your approximate location from your IP address.</p>
<p><strong>Device Information</strong>: We may collect information about your mobile device, such as a unique device identifier, to understand how you use the Service.</p>
<p><strong>HOW WE USE AND DISCLOSE INFORMATION</strong></p>
<p>We use and disclose information you provide to us as described to you at the point of collection. Please see the section entitled “Choices and Access,” below, to learn how you may opt out of certain of our uses and disclosures. </p>
<p className="BodyTextRed5Indent">Where required by applicable law, we will obtain your consent to our use of your personal information at the point of information collection. We may also use information from or about you as necessary to perform a contract, to comply with a legal obligation (for example, due to our pharmacovigilance obligations), or for our legitimate business interests. We may also rely on other legal bases, specifically for:</p>
<ul>
    <li><strong>Providing the functionality of the Service and fulfilling your requests.</strong>
        <ul>
            <li>to provide the functionality of the Service to you and providing you with related customer service;</li>
            <li>to respond to your inquiries and fulfill your requests, such as to send you documents you request or e‑mail alerts;</li>
            <li>to send you important information regarding our relationship with you or regarding the Service, changes to our terms, conditions, and policies and/or other administrative information.</li>
        </ul>
    </li>
</ul>
<p className="BodyTextRed5Indent">We will engage in these activities to manage our contractual relationship with you and/or to comply with a legal obligation.</p>
<ul>
    <li><strong>Accomplishing our business purposes.</strong>
        <ul>
            <li>for data analysis, for example, to improve the efficiency of the Service;</li>
            <li>for audits, to verify that our internal processes function as intended and are compliant with legal, regulatory, or contractual requirements;</li>
            <li>for fraud and security monitoring purposes, for example, to detect and prevent cyberattacks or attempts to commit identity theft;</li>
            <li>for developing new products and services;</li>
            <li>for enhancing, improving or modifying our website or products and services; </li>
            <li>for identifying Service usage trends, for example, understanding which parts of our Service are of most interest to users; and</li>
            <li>for determining the effectiveness of our promotional campaigns, so that we can adapt our campaigns to the needs and interests of our users.</li>
        </ul>
    </li>
</ul>
<p className="BodyTextRed5Indent">We will engage in these activities to manage our contractual relationship with you, to comply with a legal obligation, and/or because we have a legitimate interest.</p>
<ul>
    <li><strong>Analysis of Personal Information to provide personalized services.</strong>
        <ul>
            <li>to better understand you, so that we can personalize our interactions with you and provide you with information and/or offers tailored to your interests;</li>
            <li>to better understand your preferences so that we can deliver content via the Service that we believe will be relevant and interesting to you.</li>
        </ul>
    </li>
</ul>
<p>We will provide personalized services either with your consent or because we have a legitimate interest.</p>
<p>We also disclose information collected through the Service:</p>
<ul>
    <li>to our affiliates for the purposes described in this Privacy Policy. A list of our affiliates is available at <u><HistoryA href="http://www.investor.jnj.com/governance/sec-filings.cfm" >http://www.investor.jnj.com/governance/sec‑filings.cfm</HistoryA></u> (click on the link for Form 10K, Exhibit 21, under “SEC Filings”). <strong>Johnson &amp; Johnson Services, Inc. </strong>is the party responsible for the management of the jointly‑used Personal Information;</li>
    <li>to our third party partners with whom we offer a co‑branded or co‑marketed promotion;</li>
    <li>to our third party service providers who provide services such as website hosting and moderating, mobile application hosting, data analysis, payment processing, order fulfillment, infrastructure provision, IT services, customer service, e‑mail and direct mail delivery services, auditing, and other services, in order to enable them to provide services; and</li>
    <li>as permitted by applicable law, to a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings).</li>
</ul>
<p>In addition, we may use and disclose your information as we believe to be necessary or appropriate: (a) to comply with legal process or applicable law, which may include laws outside your country of residence; (b) as permitted by applicable law to respond to requests from public and government authorities, which may include authorities outside your country of residence; (c) to enforce our terms and conditions; and (d) to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others. We may also use and disclose your information in other ways, after obtaining your consent to do so.</p>
<p>We may use and disclose information we collect automatically as described above, under “Automatic Information Collection and Use.”</p>
<p>In addition, where allowed by applicable law, we may use and disclose information that is not in personally identifiable form for any purpose. If we combine information that is not in personally identifiable form with information that is identifiable (such as combining your name with your geographical location), we will treat the combined information as personal information as long as it is combined.</p>
<p><strong>CROSS‑BORDER TRANSFER</strong></p>
<p>Your personal information may be stored and processed in any country where we have facilities or service providers, and by using our Service or by providing consent to us (where required by law), your information may be transferred to countries outside of your country of residence, including to the United States, which may provide for different data protection rules than in your country. Appropriate contractual and other measures are in place to protect personal information when it is transferred to our affiliates or third parties in other countries.</p>
<p>Some non-European Economic Area (EEA) countries are recognized by the European Commission as providing an adequate level of data protection according to EEA standards (the full list of these countries is available <HistoryA href="http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm" >here</HistoryA>. For transfers from the EEA to countries not considered adequate by the European Commission, we have ensured that adequate measures are in place, including by ensuring that the recipient is bound by [EU Standard Contractual Clauses, EU-US Privacy Shield Certification, or an EU-approved code of conduct or certification], to protect your Personal Information. You may obtain a copy of these measures by contacting our data protection officer in accordance with the “<em>Contacting Us</em>” section below.<strong> </strong> </p>
<p><strong>SECURITY</strong></p>
<p>We seek to use reasonable organizational, technical, and administrative measures designed to protect personal information under our control. Unfortunately, no data transmission over the Internet or data storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you have with us has been compromised), please immediately notify us in accordance with the “<em>Contacting Us</em>” section below.</p>
<p><strong>RETENTION PERIOD</strong></p>
<p>We will retain your personal information for as long as needed or permitted in light of the purpose(s) for which it was obtained. The criteria used to determine our retention periods include: (i) the length of time we have an ongoing relationship with you and provide the Service to you; (ii) whether there is a legal obligation to which we are subject; and (iii) whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitations, litigation, or regulatory investigations).</p>
<p>For example, if we collect your personal information for HR Administration, we will store it for a period of 10 years with a starting point of retention period, e.g., receipt of the request, closing of the contest, receipt of the complaint.</p>
<p>For example, if we collect your personal information for Finance/ Accounting/ Tax purposes, we will store it for a period of 20 years with a starting point of retention period, e.g., receipt of the request, closing of the contest, receipt of the complaint.</p>
<p>For example, if we collect your personal information for Legal purposes, we will store it for a period of 15 years with a starting point of retention period, e.g., receipt of the request, closing of the contest, receipt of the complaint.</p>
<p> </p>
<p>Please note, however, that this is not an exhaustive list of retention periods. Your personal information may be stored for a longer period using the criteria set forth in the first paragraph of this section, especially points (ii) and (iii).</p>
<p><strong>THIRD PARTY SITES AND SERVICES</strong></p>
<p>This Privacy Policy does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including any third party operating any site or online service (including, without limitation, any application) that is available through this Service or to which this Service contains a link. The availability of, or inclusion of a link to, any such site or property on the Service does not imply endorsement of it by us or by our affiliates.</p>
<p><strong>CONTACTING US</strong></p>
<p><strong>Johnson &amp; Johnson Services, Inc.</strong>, located at One Johnson &amp; Johnson Plaza
    <br/> New Brunswick, New Jersey 08933, (732) 524-0400, is the company responsible for collection, use, and disclosure of personal information under this Privacy Policy.</p>
<p>If you have any questions about this Privacy Policy, please contact us via the contact form on our Contact Us page (<HistoryA href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a0758000004NIaL">https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a0758000004NIaL</HistoryA>), or please write to the following address:</p>
<p><HistoryA href="http://JNJ.com">JNJ.com</HistoryA></p>
<p>P.O. Box 767</p>
<p>Neenah, WI 54957</p>
<p>You may also contact our data protection officer responsible for your country or region, if applicable, at <strong><HistoryA href="mailto:emeaprivacy@its.jnj.com" >emeaprivacy@its.jnj.com</HistoryA></strong>. </p>
<p><strong>LODGING A COMPLAINT WITH A REGULATOR</strong></p>
<p>You may lodge a complaint with a supervisory authority competent for your country or region. Please click <HistoryA href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm">here</HistoryA> for contact information for such authorities.<strong> </strong></p>
<p><strong>UPDATES TO THIS PRIVACY POLICY</strong></p>
<p>We may change this Privacy Policy. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy on the Service. Your use of the Service following these changes means that you accept the revised Privacy Policy. We recommend that you regularly review the Privacy Policy when you visit the Service. This policy was last updated on <strong>August 2018</strong>.</p>
</div>
);
    }
}
