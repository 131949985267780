import React from "react";
import { Icon } from "components/common";
import WhichOptionsScreen from "../OptionsScreen";
import WorkingWithRecruiter from "../WorkingWithRecruiter";
import { RouterProps } from "types";
import "./styles.scss";
import ReqOsScreen from "../ReqOS";

const WhichOptions = (props: RouterProps) => {
    const options: Array<{
        id: number;
        title: string;
        description: string;
        shortDesc: string;
        value: string;
    }> = [
        {
            id: 1,
            title: "Repatriation",
            description: "The employee's assignment will be ending.",
            shortDesc: "",
            value: "Repatriation",
        },
        {
            id: 2,
            title: "Extension",
            description:
                "A decision has been made to extend the assignment in the current location.",
            shortDesc: "",
            value: "Extension",
        },
        {
            id: 3,
            title: "Localization",
            description:
                "The global assignee will be localized in the host location rather than return to the home country.",
            shortDesc: "",
            value: "Localization",
        },
        {
            id: 4,
            title: "Sequential Move",
            description:
                "The global assignee is completing one assignment and beginning another assignment in a different location.",
            shortDesc: "(This includes LDP assignments)",
            value: "SequentialMove",
        },
    ];

    const backHandler = (): void =>
        props.history.push({
            state: {
                ...props.location.state,
                "global-assignment": "",
            },
        });

    const clickHandler = (id: number, value: string): void => {
        if (id === 4) {
            props.history.push({
                state: {
                    ...props.location.state,
                    "initiate-move": true,
                    moveOption: value,
                },
            });
        } else if (id === 5) {
            props.history.push({
                state: {
                    ...props.location.state,
                    ldp: true,
                    moveOption: value,
                    countryName: "United States",
                    countryCode: "US",
                    moveType: "Domestic",
                },
            });
        } else {
            props.history.push({
                state: {
                    ...props.location.state,
                    "options-screen": true,
                    moveOption: value,
                },
            });
        }
    };

    const optionsList = options.map(
        (option: { id: number; title: string; description: string; shortDesc: string; value: string }) => (
            <div
                key={option.id}
                className="option vertical flexible jCenter"
                onClick={(): void => clickHandler(option.id, option.value)}
            >
                <h5>{option.title}</h5>
                <p>{option.description}</p>
                <p>{option.shortDesc}</p>
            </div>
        ),
    );

    if (
        props.location.state &&
        props.location.state["options-screen"] === true
    ) {
        return (
            <WhichOptionsScreen
                history={props.history}
                location={props.location}
            />
        );
    } else if (props.location.state && props.location.state["ldp"] === true) {
        return (
            <ReqOsScreen
                history={props.history}
                location={props.location}
                prevPage={"whichOptions"}
            />
        );
    } else if (
        props.location.state &&
        props.location.state["initiate-move"] === true
    ) {
        return (
            <WorkingWithRecruiter
                fromOptions
                history={props.history}
                location={props.location}
            />
        );
    }

    return (
        <section className="which-options-container">
            <div>
                <section className="back-icon" onClick={backHandler}>
                    <Icon name="backIcon" />
                    <p>Go back</p>
                </section>
                <h1 className="section-title">
                    Ok, which of the following options applies to this move?
                </h1>
                <div className="options-container">{optionsList}</div>
            </div>
        </section>
    );
};

export default WhichOptions;
