import React from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { Accordion, Button } from "components/common";
import Stepper from './Stepper';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getTaxMilestones } from "../../../selectors/dataSelectors";

class TaxMilestonesTable extends React.Component {
    state = {
        isMobile: window.innerWidth <= 600,
        openItems: new Set(),
        selectedYear: null
    };

    componentDidMount(): void {
        if (Array.isArray(this.props.taxes) && this.props.taxes.length) {
            this.setState({ selectedYear: this.props.taxes[0].year });
        }

        window.addEventListener('resize', this.updateLayout);
    }

    componentWillMount(): void {
        window.removeEventListener('resize', this.updateLayout);
    }

    componentDidUpdate(prevProps) {
        const oldTaxes = prevProps.taxes;
        const newTaxes = this.props.taxes;
        const taxesCheck = !Array.isArray(oldTaxes) || !oldTaxes.length || oldTaxes.length !== newTaxes.length;

        if (taxesCheck && Array.isArray(newTaxes) && newTaxes.length) {
            this.setState({ selectedYear: this.props.taxes[0].year });
        }
    }

    updateLayout = () => {
        return this.setState({ isMobile: window.innerWidth <= 600 });
    };

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        return this.isSetsEqual(nextState.openItems, this.state.openItems) || (nextState.isMobile !== this.state.isMobile);
    }

    isSetsEqual = (current, next) => {
        return current.size === next.size && [...current].every(value => next.has(value));
    };

    getTaxes = () => {
        const { taxes } = this.props;
        let data = [];
        Object.keys(taxes).forEach(key => {
            const newTaxes = taxes[key];
            Object.keys(newTaxes).forEach(key => {
                data.push(newTaxes[key]);
            });
        });
        return data;
    };
    countryHeading = (index) => {
        return index === 1 ? "Home" : "Host";
    };

    renderWeb() {
        const newData = this.getTaxes();
        return newData.map((row, index) => {
            if (typeof row === 'object') {
                let countryHeading = this.countryHeading(index);

                return (
                    <React.Fragment key={index}>
                        <div className="TaxMilestonesTableItems flexible">
                            <div className="TaxCountryInfo">

                                <div className="TaxInfoType">{countryHeading}:</div>
                                <div className="TaxInfoCountry">{row.countryName}</div>
                            </div>
                            <div className="TaxActivityInfo">
                                <Stepper id={index} data={row.activities}/>
                            </div>
                        </div>
                        {index + 1 !== newData.length && <hr className="TaxMilestonesTableSeparator"/>}
                    </React.Fragment>
                );
            }

            return null;
        });
    }

    getTitleComp = (el, countryHeading) => {
        const activeRow = el.activities.find(el => !!el.active) || {};
        const isOpen = this.state.openItems.has(el.id);
        return (
            <div className='TaxMilestonesMobileItems'>
                <p className="TaxInfoType">{countryHeading}:</p>
                <h5 className="TaxInfoCountry">{el.countryName}</h5>
                {!isOpen && (
                    <React.Fragment>
                        <h6 className="activity-title">{activeRow.statusDescription}</h6>
                    </React.Fragment>
                )}
            </div>
        );
    };

    renderMobile() {
        const newData = this.getTaxes();
        const accordionData = [];
        newData.forEach((el, index) => {
            let countryHeading = this.countryHeading(index);
            if (typeof el === 'object') {
                el.props = {
                    uniqueKey: index,
                    title: this.getTitleComp(el, countryHeading),
                };
                el.content = <Stepper id={index} data={el.activities} isMobile={true}/>;
                accordionData.push(el);
            }
        });
        return (
            <Accordion
                onItemClick={this.mobileAccordionClick}
                className="TaxMilestonesTableAccordion"
                data={accordionData}
            />
        );
    }

    mobileAccordionClick = (e, key) => {
        const { openItems } = this.state;

        if (openItems.has(key)) {
            openItems.delete(key);
        } else {
            openItems.add(key);
        }

        this.setState({ openItems });
    };

    onYearChange = (year) => {
        if (year === this.state.selectedYear) {
            return;
        }

        this.setState({ selectedYear: year });
    };

    onYearChangeMobile = (e) => {
        if (e.target.value === this.state.selectedYear) {
            return;
        }

        this.setState({ selectedYear: e.target.value });
    };

    render() {
        const { taxes } = this.props;

        if (!Array.isArray(taxes) || !taxes.length) {
            return null;
        }

        return (
            <div className="TaxMilestonesTable">
                <div className="TaxMilestonesTableFilters flexible jBetween aCenter">
                    <div className="years flexible">
                        {this.props.taxes.map((tax, i) => {
                            const isSelected = tax.year === this.state.selectedYear;

                            return (
                                <div
                                    className={`year ${isSelected ? 'selected' : ''}`}
                                    key={tax.year}
                                    onClick={() => this.onYearChange(tax.year)}
                                >
                                    {tax.year}
                                </div>
                            );
                        })}
                    </div>
                    <div className="button">
                        <Button
                            title={'Visit Vendor Tax Portal'}
                            submit
                            dataKey="taxmilestone-vendor-tax-portal"
                            onClick={() => window.open('https://www.gadeloitte.com')}
                        />
                    </div>
                    <div className="years-mobile">
                        <Select
                            value={this.state.selectedYear}
                            onChange={this.onYearChangeMobile}
                            className={'years-select'}
                            classes={{ select: 'years-select-text', icon: 'years-select-icon' }}>
                            {this.props.taxes.map(tax => {
                                return (
                                    <MenuItem key={tax.year} value={tax.year}>{tax.year}</MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div className="TaxMilestonesTableHeader flexible">
                    <div>Country</div>
                    <div>Activity</div>
                </div>
                {this.state.isMobile ? this.renderMobile() : this.renderWeb()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    taxes: getTaxMilestones(state)
});

export default connect(mapStateToProps)(TaxMilestonesTable);
