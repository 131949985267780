export default {
    assignee: 
    {
        international: [
            {
                label: 'Explore',
                key: 'explore',
                assetid:'3',
                title:'Congratulations on your upcoming move! Time to start planning…',
                linkTest: 'View My Move Details',
                linkUrl: '/assignment-letter'
            },
            {
                label: 'Get Started',
                key: 'get-started',
                assetid:'3',
                title:'Congratulations on your upcoming move! Time to start planning…',
                linkTest: 'View My Move Details',
                linkUrl: '/assignment-letter'
            },
            {
                label: 'Prep & Plan',
                key: 'prep-plan',
                assetid:'3',
                title:'Congratulations on your upcoming move! Time to start planning…',
                linkTest: 'View My Move Details',
                linkUrl: '/assignment-letter'
            },
            {
                label: 'Arrival',
                key: 'arrival',
                assetid:'5',
                title:'Welcome to your new host country! Time to settle in…',
                linkTest: 'View My Move Details',
                linkUrl: '/assignment-letter'
            },
            {
                label: 'In Host Country',
                key: 'in-host-country',
                assetid:'1',
                title:'We hope you’re settling into life in your new city. ',
                linkTest: 'View My Move Details',
                linkUrl: '/assignment-letter'
            },
            {
                label: 'The Journey Back',
                key: 'journey-back',
                assetid:'4',
                title:'Discover what steps you need to take to start the journey back.'
            },
        ],
        domestic: [
            {
                label: 'Explore',
                key: 'explore',
                assetid:'3',
                title:'Congratulations on your upcoming move! Time to start planning…',
                linkTest: 'View My Move Details',
                linkUrl: '/assignment-letter'
            },
            {
                label: 'Get Started',
                key: 'get-started',
                assetid:'3',
                title:'Congratulations on your upcoming move! Time to start planning…',
                linkTest: 'View My Move Details',
                linkUrl: '/assignment-letter'
            },
            {
                label: 'Prep & Plan',
                key: 'prep-plan',
                assetid:'3',
                title:'Congratulations on your upcoming move! Time to start planning…',
                linkTest: 'View My Move Details',
                linkUrl: '/assignment-letter'
            },
            {
                label: 'Relocate',
                key: 'relocate',
                assetid:'1',
                title:'We hope you’re settling into life in your new city. ',
                linkTest: 'View My Move Details',
                linkUrl: '/assignment-letter'
            }
        ]
    },
    hrmanager: 
    {
        international: [
            {
                label: 'Strategic Planning',
                key: 'explore',
            },
            {
                label: 'On Arrival',
                key: 'arrival',
            },
            {
                label: 'The Journey Back',
                key: 'journey-back',
            },
        ],
        domestic: [
            {
                label: 'Strategic Planning',
                key: 'explore',
            }
        ]
    }
};