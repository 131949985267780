import React, { useState } from "react";
import { Icon, Accordion, SpinnerWrapper } from "components/common";
import { RouterProps } from "types";
import { useDispatch } from "react-redux";
import { moveInitiationService } from "services";
import "./styles.scss";

const CongratulationsText = () => (
    <div className="congratulations-text">
        Congratulations! You've completed the Pre-initiation process. It takes
        about 48 hours to approve a position ID. Once you receive confirmation,
        come back to <span className="move-base-url">move.jnj.com</span> and
        click on "I need to continue an initiation request I already started" to
        complete your relocation initiation.
    </div>
);

const FirstStep = () => (
    <div className="req-os-first-step">
        <div className="blue-box">
            <Icon name="cautionRed" width={40} height={40} />
            <div className="blue-box-texts">
                <p>
                    If you need additional support with the OurSouce Requisition
                    Creation Process, please contact AskGS.
                </p>
                <span className="link-info">
                    <a
                        target="_blank"
                        href="https://jnjgsportal--c.na80.visual.force.com/apex/cl_Index?sfdc.tabName=01r1a0000001VdH#/knowledgedirect/Policies/Contact-Us/fromFooter"
                    >
                        Contact AskGS
                        <Icon
                            name="linkIcon"
                            className="link-btn"
                            width={21}
                            height={21}
                        />
                    </a>
                </span>
            </div>
        </div>
        <div className="bullet-list">
            <div className="title">
                Before you start, make sure you have the following info:
            </div>
            <ul>
                <li>Effective date</li>
                <li>Company code*</li>
                <li>Location*</li>
                <li>Legal entity (a 4-digit code)</li>
                <li>MRC (Management Reporting Company)</li>
                <li>Cost center*</li>
                <li>Union information (if applicable)</li>
            </ul>
            <p>*Codes can be found on: </p>
            <p>1. JJEDS (show more detail)</p>
            <p>
                2. Our Source (Create position, scroll down to Account
                Attributes Information)
            </p>
        </div>
    </div>
);

const SecondStep = () => (
    <div className="req-os-second-step">
        <div className="content">
            <div className="bullet-list">
                <div className="title">If you’re the hiring manager: </div>
                <ul>
                    <li>
                        Use Google Chrome or Internet Explorer 11 to open
                        OurSource.
                    </li>
                    <li>
                        Go to <b>Staff Org & Position Management.</b>
                    </li>
                    <li>
                        Click <b>Create Position.</b>
                    </li>
                    <li>
                        Click + at the bottom left to open a new position
                        request.
                    </li>
                    <li>
                        Select a position type from the top navigation menu.
                    </li>
                    <li>
                        Before submitting, be sure to specify the “Number of
                        Positions to Start Sourcing”
                    </li>
                    <li>Complete and submit the form.</li>
                </ul>
            </div>
            <div className="bullet-list">
                <div className="title">
                    If you’re requesting the Position ID on behalf of a Hiring
                    Manager:
                </div>
                <ul>
                    <li>Download the Create a Position Form.</li>
                    <li>Follow the instructions within the document.</li>
                    <li>
                        Use Google Chrome or Internet Explorer 11 to open the
                        AskGS Portal and click <b>Submit a Request</b>.
                    </li>
                    <li>
                        Under <b>Who Do You Need Assistance From?</b>, select{" "}
                        <b>Human Resources</b>.
                    </li>
                    <li>
                        Under <b>Category</b>, select{" "}
                        <b>Position & Organization Change.</b>
                    </li>
                    <li>Attach and submit the Create Position Form.</li>
                </ul>
            </div>
        </div>
        <p className="bottom-text">
            You can check the status of your request in the left-hand column. It
            should be created within 48 hours.
        </p>
        <button className="submit-button">
            <a href="https://oursource2.jnj.com/jnjflp(bD1lbiZjPTI4MA==)/default.htm">
                {" "}
                Submit and Open OUR SOURCE
            </a>
        </button>
    </div>
);

const ReqOsScreen = (props: RouterProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const backHandler = (): void => {
        if (props.prevPage === "Assignment") {
            props.history.push({
                state: {
                    ...props.location.state,
                    "country-or-other": "",
                },
            });
        } else if (props.prevPage === "whichOptions") {
            props.history.push({
                state: {
                    fromIntro: true,
                    "global-assignment": "yes",
                    preIntEmppDetails: props.location.state.preIntEmppDetails,
                },
            });
        } else {
            props.history.push({
                state: {
                    ...props.location.state,
                    "global-vs-domestic": "",
                },
            });
        }
    };

    const dataSet = {
        "employeeInfo": (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
        "alreadyInAssignment": (props.location.state.alreadyInAssignment !== undefined) ? props.location.state.alreadyInAssignment : false,
        "moveOption": (props.location.state.moveOption !== undefined) ? props.location.state.moveOption : "NotSet",
        "moveType": (props.location.state.moveType !== undefined) ? props.location.state.moveType : "NotSet",
        "payGrade":  (props.location.state.payGrade !== undefined) ? props.location.state.payGrade : "NotSet",
        "countryCode": (props.location.state.countryCode !== undefined) ? props.location.state.countryCode : "",
        "countryName": (props.location.state.countryName !== undefined) ? props.location.state.countryName : "",
    }
    
    const savePreInitDetails = (location) => {
        setIsLoading(true);
        dispatch(moveInitiationService.actions.setPreInitDetails(dataSet)).then(data => {
                setIsLoading(false);
                if(data.success) {
                    if (location == "initiate-move") {
                        let moveType = dataSet.moveType;
                        let initiationId = data.result;
                        let initiationCountryCode = dataSet.countryCode;
                        let relocationType = null;
                        let policySelection = null;
                        let employeeInfo = (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null;
                        props.history.push("/initiate-move",
                            { moveType,policySelection,relocationType,initiationId,initiationCountryCode,employeeInfo },
                        );
                    }
                    else {
                        navigateTo(location);
                    }
                } else {
                    console.log(data.message);
                }
            })
            .catch(err => console.log(err));
    }

    const navigateTo = (location: string) => {
        switch (location) {
            case 'continue':
                savePreInitDetails("submittedSucess");
                break;
            case 'already-have-continue':
                savePreInitDetails("initiate-move");
                break;
            case 'submittedSucess':
                    //props.history.push('/move-dashboard');
                    window.location.replace("https://oursource2.jnj.com/jnjflp(bD1lbiZjPTI4MA==)/default.htm");
                break;
        }
    };

    return (
        <div className="req-os-screen">
            <SpinnerWrapper show={isLoading}>
            <section className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </section>
            <h1>Ok, now it's time to create your Position ID.</h1>
            <div className="top-content">
                <div className="left-info">
                    <p>
                        You'll need to submit a request to OurSource® and get an
                        ID in order to continue your initiation process.
                    </p>
                    <div className="buttons">
                        <button 
                            className="submit-button"
                            onClick={() => {navigateTo("continue")}}
                        >
                            {/* <a href="https://oursource2.jnj.com/jnjflp(bD1lbiZjPTI4MA==)/default.htm">
                                Submit and Open OUR SOURCE
                            </a> */}
                            Submit and Open OUR SOURCE
                        </button>
                        <a>
                            <button 
                                className="continue-button" 
                                onClick={() => {navigateTo("already-have-continue")}}
                            >
                                Already have a Position ID, Continue
                            </button>
                        </a>
                    </div>
                </div>
                <div className="right-info">
                    <div>
                        <Icon name="timeBlue" />
                        <span className="text">How long will this take?</span>
                    </div>
                    <p>
                        Submitting your request to OurSource will take up to 15
                        minutes.
                    </p>
                    <p>
                        Getting approval and a Position ID from the OurSource
                        team will take up to 48 hours.
                    </p>
                </div>
            </div>
            <hr className="line" />
            <div className="three-steps-accordion">
                <div className="accordion-box">
                    <p className="circle-number">1</p>
                    <Accordion
                        data={[
                            {
                                content: <FirstStep />,
                                props: {
                                    uniqueKey: 1,
                                    title: "Things you need before you start",
                                },
                            },
                        ]}
                    />
                </div>
                <div className="accordion-box">
                    <p className="circle-number">2</p>
                    <Accordion
                        data={[
                            {
                                content: <SecondStep />,
                                props: {
                                    uniqueKey: 2,
                                    title: "Create a position within OurSource",
                                },
                            },
                        ]}
                    />
                </div>
                <div className="accordion-box">
                    <p className="circle-number">3</p>
                    <Accordion
                        data={[
                            {
                                content: <CongratulationsText />,
                                props: {
                                    uniqueKey: 3,
                                    title: "What happens next",
                                },
                            },
                        ]}
                        openedItems={[3]}
                    />
                </div>
            </div>
            </SpinnerWrapper>
        </div>
    );
};

export default ReqOsScreen;
