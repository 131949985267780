import React from 'react';
import personWorld from 'components/common/Images/personWorld.svg';
import info from 'components/common/Images/info.svg';
import approval from 'components/common/Images/approval.svg';
import sign from 'components/common/Images/sign.svg';

export const InitMoveQuestionsData = [
    {
        title: 'What if I am having trouble creating a position? ',
        icon:personWorld,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">Contact the Ask GS Service Center (if applicable to host country). 
                </p>
            </div>
        )
    },
    {
        title: 'When will I be notified my employee is immigration compliant?',
        icon:info,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">The employee’s Global Assignment Consultant will notify the home and host manager once the employee is immigration compliant.
                </p>
            </div>
        )
    },
    {
        title: 'How long does this process take?',
        icon:approval,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">The process for initiating talent moves will vary but generally takes 5-7 business days from the time a manager submits the request to the time all relocation services are authorized. 
                </p>
            </div>
        )
    },
    {
        title: 'What is a Global Assignment Letter (GAL)? ',
        icon:sign,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">
                The employee will receive a Global Assignment Letter which will confirm the terms and benefits in that pertain to his/her relocation.  It is required that the employee sign the Global Assignment Letter as acceptance of the terms and conditions of the relocation.
                </p>
            </div>
        )
    }
];