import * as React from 'react';
import cs from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import type {WithStyles, StyleRulesCallback} from '@material-ui/core/styles/withStyles';

type TaskListComponentProps = {
    taskList: Array<Object>,
    title: string,
    content: React.Node
};

type TaskListComponentState = {
    checkedArr: Object,
};

const styles: StyleRulesCallback = theme => ({
    root: {
        width: '100%',
        backgroundColor: '#f4f4f4',
    },
    title: {
        fontSize: 20,
        color: theme.palette.secondary.main,
        padding: '25px 30px',
        borderBottom: '2px solid #e6e6e6'
    },
    listItem: {
        height: 60,
        padding: '0 30px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        '&:checked ~ div': {
            '&:after': {
                opacity: 1,
            },
            '&:before': {
                backgroundColor: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
            }
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        cursor: 'pointer'
    },
    nestedDiv: {
        width: 'calc(100% - 36px)',
        borderBottom: '1px solid #e6e6e6',
        height: 60,
        lineHeight: '60px',
        fontSize: 16,
        fontWeight: 100,
        position: 'relative',
        marginLeft: 15,
        flexGrow: 1,
        display: 'flex',
        '&:before': {
            content: '""',
            display: 'flex',
            width: 20,
            height: 20,
            border: '1px solid #e6e6e6',
            position: 'absolute',
            borderRadius: 1,
            backgroundColor: '#fff',
            top: 19,
            left: -31,
            cursor: 'pointer',
        },
        '&:after': {
            content: '"\\2713"',
            color: '#fff',
            position: 'absolute',
            fontSize: 14,
            left: -27,
            opacity: 0,
            top: 1,
        }
    }
});

class TaskListComponent extends React.PureComponent<TaskListComponentProps & WithStyles, TaskListComponentState>{

    static defaultProps = {
        checkedArr: {}
    };

    state = {
        checkedArr: {}
    };

    componentWillMount(){
        const checkedArr = {};
        if (this.props.taskList) {
            this.props.taskList.forEach((item, index)=> {
                checkedArr[index] = item.checked;
            });
        }
        this.setState({
            checkedArr
        })
    }

    setCheckedItems = (index)=> {
        this.setState({
          checkedArr: {
              ...this.state.checkedArr,
              [index]: !this.state.checkedArr[index],
          }
      })
    };

    render(){
        const {taskList, classes, title, content} = this.props;

        return(
            <div className={cs(classes.root, 'TaskListComponent')}>
               <h2 className={classes.title}>
                   {title}
               </h2>
                <div className={cs(classes.content, 'task-list-items')}>
                    {
                        taskList
                        ? taskList.map((item, index)=>(
                            <div className={cs(classes.listItem, 'list-item')} key={index}>
                                <label htmlFor={index} className={classes.label}>
                                    <input
                                        type="checkbox"
                                        id={index}
                                        onChange={()=> this.setCheckedItems(index)}
                                        disabled
                                        checked
                                        className={classes.input}
                                    />
                                    <div className={classes.nestedDiv}>
                                        <span className="singleLine truncate">{item.title}</span>
                                    </div>
                                </label>
                            </div>
                        ))
                        : content
                    }

                </div>
            </div>
        )
    }
}

export default withStyles(styles)(TaskListComponent)
