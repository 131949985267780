// @flow

import React, { PureComponent } from 'react';
import Options from './Options';

import { Icon } from 'components/common';

import './style.scss';

export type DropDownOption = {
    label: string,
    key: string | number,
}

type DropDownProps = {
    dataKey?: string,
    defaultSelected: string | number,
    data?: Array<DropDownOption>,
    onChange?: Function,
    staticLabel?: string,
    isInFixedContent?: boolean,
    withCloseButton?: boolean,
    optionsClassName?: string,
    optionsStyles?: Object,
    material?: boolean,
    required?: boolean,
    className?: string
};

type DropDownState = {
    selectedOption: DropDownOption,
    isOpen: boolean,
    targetEl: HTMLElement<*>,
};

export default class DropDown extends PureComponent<DropDownProps, DropDownState> {
    constructor(props: DropDownProps) {
        super(props);

        this.state = {
            isOpen: false,
            selectedOption: null,
            targetEl: null,
        };
    }

    componentDidMount() {
        document.addEventListener('click', () => {
            this.state.isOpen && this.setState({isOpen: false});
        })
    }

    componentWillMount() {
        const { defaultSelected } = this.props;
        if(defaultSelected) {
            const selectedOption = this.props.data.find(option => option.key === defaultSelected);
            this.setState({selectedOption});
        }
    }

    componentWillReceiveProps(nextProps: DropDownProps): void {
        if(!(this.props.defaultSelected && nextProps.defaultSelected) || this.props.defaultSelected !== nextProps.defaultSelected) {
            const selectedOption = this.props.data.find(option => option.key === nextProps.defaultSelected);
            this.setState({selectedOption});
        }
    }

    handleOptionChange = (selectedOption: DropDownOption): void => {
        this.setState({
            selectedOption,
            isOpen: false,
        });
        this.props.onChange && this.props.onChange (selectedOption)
    };

    static closeHeaderDropdown(event: SyntheticMouseEvent<*>): void {
        const data = event.currentTarget.dataset;

        if (!data || !data.key) {
            return false;
        }

        if (!['header_view_toggle', 'header_my_moves'].includes(data.key)) {
            return false;
        }

        let selectKey = 'header_view_toggle';

        if (data.key === 'header_view_toggle') {
            selectKey = 'header_my_moves';
        }

        const dropdown = document.querySelector(`[data-key="${selectKey}"]`);

        if (!dropdown) {
            return false;
        }

        let openDropdown = dropdown.getElementsByClassName('js-select-label isOpen');

        if (!openDropdown.length) {
            return false;
        }

        openDropdown = openDropdown[0];

        return openDropdown.click();
    }

    toggleDropdown = (event: SyntheticMouseEvent<*>): void => {
        DropDown.closeHeaderDropdown(event);
        event.nativeEvent.stopImmediatePropagation();
        const { isOpen } = this.state;
        this.setState( {
            isOpen: !isOpen,
            targetEl: event.currentTarget,
        });
    };

    render() {
        const {
            isInFixedContent = false,
            withCloseButton = false,
            optionsStyles,
            staticLabel,
            optionsClassName = '',
            dataKey = '',
            material,
            required,
            className = ''
        } = this.props;

        const { selectedOption } = this.state;

        const label = (selectedOption && selectedOption.label) || 'Select...';

        return (
            <div
                className={`DropDown ${material ? 'material' : ''} ${className} flexible horizontal aCenter jBetween`}
                data-key={dataKey}
                onClick={this.toggleDropdown}
            >
                {
                    material ?
                        <div>
                            { staticLabel && <div className={`${selectedOption ? 'selected' : ''} staticLabel singleLine`}>{staticLabel} {`${required ? '*' : ''}`}</div> }
                            { selectedOption && <div className="label truncate singleLine">{label}</div> }
                        </div>
                        : <div className={`label truncate singleLine ${this.state.isOpen ? 'isOpen' : ''} js-select-label`}>{staticLabel || label}</div>
                }
                {
                    this.state.isOpen ?
                        <Icon name="chevronBlue" style={{ marginLeft: 10 }} width={10}/>
                        :
                        <Icon name="chevronRed" style={{ marginLeft: 10 }} width={10}/>
                }
                {
                    this.state.isOpen && <Options
                        data={this.props.data}
                        selectedOption={this.state.selectedOption}
                        onChange={this.handleOptionChange}
                        anchorElement={this.state.targetEl}
                        isInFixedContent={isInFixedContent}
                        withCloseButton={withCloseButton}
                        styles={optionsStyles}
                        optionsClassName={optionsClassName}
                        toggle={this.toggleDropdown}
                    />
                }
            </div>
        );
    }
}
