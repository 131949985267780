import React, { PureComponent } from "react";
import { MoveStepLayout } from "components/moveInitiationLayouts";
import { Icon } from "components/common";
import { withRouter } from "react-router";
// import { ContactMobility2 } from "components/common";
import FiftyPercent from "../FiftyPercent";
import ShortTerm from "../EligibleMove/shortTerm";
import "./style.scss";

class ReasonSelector extends PureComponent {
    moveReasons = [
        {
            id: 1,
            title: "Accelerated Development",
            description:
                "I would like to develop this employee for the next level in their career.",
            descriptionHighlighte: 
                "The assignment will last between 1 and 5 years.",
            value: "AcceleratedDevelopment",
        },
        {
            id: 2,
            title: "Skill Need",
            description:
                "This employee will transfer skills to the team in the host location, backfill someone " +
                "who is out on parental leave or gain skills to bring back to their home country.",
            descriptionHighlighte: 
                "The assignment will last for at least 3 months but no-more than 5 years.",
            value: "SkillNeeded",
        },
        {
            id: 3,
            title: "Early Career",
            description:
                "This employee is a member of one of our Leadership Development Programs including the " +
                "Human Resources LDP, Finance LDP, Procurement LDP or others.",
            descriptionHighlighte: 
                "These assignments are for a maximum of 12 months",
            value: "EarlyCareer",
        },
        {
            id: 4,
            title: "Project",
            description:
                "This employee will be working on a project in the host location.",
            descriptionHighlighte: 
                "The assignment will last at least 3 months but no-more than 5 years.",
            value: "Project",
        },
        {
            id: 5,
            title: "Business Critical Needs",
            description:
                "This includes a wide variety of situations including the need to stabilize a team after a key employee departure, " + 
                " filling a gap during parental leave, launching a new product or service, and stabilizing a single point of failure.",
            descriptionHighlighte: 
                "These assignments are for employees who are PG41 or above, duration will be greater than 12 months but no-more than 5 years.",
            value: "BusinessCriticalNeeds",
        }
    ];

    navigateTo = (title=null, moveType=null, policySelection=null, value=null) => {
        if (!title) {
            this.props.history.push("/initiate-move", {
                    ...this.props.location.state,
                    "moveType": moveType,
                    "policySelection": policySelection,
                    employeeInfo: (this.props.location.state.employeeInfo !== undefined && this.props.location.state.employeeInfo !== null ) ? this.props.location.state.employeeInfo : null,
            });
        } else {
            this.props.history.push({
                state: {
                    ...this.props.location.state,
                    "strategic-indent": title,
                    "moveType": moveType,
                    "policySelection": policySelection,
                    "strategicIntent": value,
                    employeeInfo: (this.props.location.state.employeeInfo !== undefined && this.props.location.state.employeeInfo !== null ) ? this.props.location.state.employeeInfo : null,
                },
            });
        }
    };
    
    render() {

        const { moveType, policySelection } = this.props.location.state;
        if(this.props.location.state && this.props.location.state["strategic-indent"]){
            return (
                <div>
                    <MoveStepLayout selected={1} />
                    <section className="package-selection-container">
                        {this.props.location.state &&
                        this.props.location.state["strategic-indent"] !== "Early Career" ? (
                            <FiftyPercent
                                history={this.props.history}
                                location={this.props.location}
                            />
                        ) : (
                            <ShortTerm 
                                history={this.props.history}
                                location={this.props.location}
                            />
                        )}
                    </section>
                </div>
            );
        } else {
            return (
                <>
                <MoveStepLayout selected={1} />
                <section className="package-selection-container">
                    <section
                        className="back-icon"
                        onClick={() => this.navigateTo("",moveType,policySelection,"")}
                    >
                        <Icon name="backIcon" />
                        <p>Go back</p>
                    </section>
                    <h1 className={"section-title"}>
                        What is the primary reason you are moving this employee?
                    </h1>
                    <p className="reason-info">
                        Please select the reason that best describes your move. If you have any questions, 
                        please reach out to Mobility Connect.
                    </p>
                    <div className={"reasons flexible wrap jBetween"}>
                        {this.moveReasons.map(reason => (
                            <div
                                key={reason.id}
                                className={"reason"}
                                onClick={() =>
                                    this.navigateTo(reason.title, moveType, policySelection, reason.value)
                                }
                            >
                                <h5>{reason.title}</h5>
                                <p>{reason.description}</p>
                                <p className="des-highlighted">{reason.descriptionHighlighte}</p>
                            </div>
                        ))}
                    </div>
                    {/* <hr className="line" />
                    <div className="contactmobility-wrapper">
                        <ContactMobility2 /> 
                    </div> */}
                    </section>
                </>
               
            )
        }
    }
}

export default withRouter(ReasonSelector);
