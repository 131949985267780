import React, { useState, useEffect } from "react";
import { RadioButton, Asset, Icon } from "components/common";
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import { History } from "history";
import Checkbox from '@material-ui/core/Checkbox';
import GetPolicy from "../../Policy/index2";
import { policyEnum } from "../../../policyMapping";
import { PayGradeDomesticHomeownerOpts } from "../../keyMappings";


const Homeowner = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    const [state, setState] = useState<{
        [key: string]: string;
    }>({
        "pay-grade": "",
    });
    const [isChecked, setIsChecked] = useState(false);
    const clickHandler = (payGrade: string, policy: string): void => {
        props.history.push({
            state: {
                ...props.history.location.state,
                payGrade,
                homeownerOrRenter: "NotSet",
                policy,
                policyTier: policy,
                eligibleTitle: policy ? policyEnum[policy].displayText : "NotSet",
                lumpSumPolicy: "No",
            },
        });
    };
    const checkedHandler = () => {
        if(isChecked) {
            setIsChecked(false);
            props.history.push({
                state: {
                    ...props.history.location.state,
                    lumpSumPolicy: "No"
                },
            });
        } else {
            setIsChecked(true);
            props.history.push({
                state: {
                    ...props.history.location.state,
                    lumpSumPolicy: "Yes",
                    policy: policyEnum.LumpSum.key
                },
            });
        }
        
    }

    useEffect(() => {
        setIsChecked(false);
        if (state["pay-grade"]) {
            props.disableContinueBtn(false);
        } else {
            props.disableContinueBtn(true);
        }
    }, [state["pay-grade"]]);

    return (
          <div className="pay-grade">
            <div className="title">
                What is the pay grade of the candidate?
            </div>
            <div className="options vertical-options">
                <div className="option">
                    <RadioButton
                        checked={state["pay-grade"] === PayGradeDomesticHomeownerOpts["24-"].payGrade}
                        onChange={e => { 
                            setState({
                                ...state,
                                "pay-grade": PayGradeDomesticHomeownerOpts["24-"].payGrade,
                            });
                            clickHandler(PayGradeDomesticHomeownerOpts["24-"].payGrade,"USDomesticHomeownerTier3");
                        }}
                    />
                    <p className="title">24 and below</p>
                </div>
                <div className="option">
                    <RadioButton
                        checked={state["pay-grade"] === PayGradeDomesticHomeownerOpts["25-26-0-3YOW"].payGrade}
                        onChange={e => { 
                            setState({
                                ...state,
                                "pay-grade": PayGradeDomesticHomeownerOpts["25-26-0-3YOW"].payGrade,
                            });
                            clickHandler(PayGradeDomesticHomeownerOpts["25-26-0-3YOW"].payGrade,"USDomesticHomeownerTier3");
                        }}
                    />
                    <p className="title">25 - 26, professional/technical/MBA, 0-3 YOW</p>
                </div>
                <div className="option">
                    <RadioButton
                        checked={state["pay-grade"] === PayGradeDomesticHomeownerOpts["25-26-3+YOE"].payGrade}
                        onChange={e => { 
                            setState({
                                ...state,
                                "pay-grade": PayGradeDomesticHomeownerOpts["25-26-3+YOE"].payGrade,
                            });
                            clickHandler(PayGradeDomesticHomeownerOpts["25-26-3+YOE"].payGrade,"USDomesticHomeownerTier2");
                        }}
                    />
                    <p className="title">25 - 26, professional/technical/MBA, 3+YOE</p>
                </div>
                <div className="option">
                    <RadioButton
                        checked={state["pay-grade"] === PayGradeDomesticHomeownerOpts["30-31"].payGrade}
                        onChange={e => { 
                            setState({
                                ...state,
                                "pay-grade": PayGradeDomesticHomeownerOpts["30-31"].payGrade,
                            });
                            clickHandler(PayGradeDomesticHomeownerOpts["30-31"].payGrade,"USDomesticHomeownerTier2");
                        }}
                    />
                    <p className="title">30 - 31</p>
                </div>
                <div className="option">
                    <RadioButton
                        checked={state["pay-grade"] === PayGradeDomesticHomeownerOpts["40+"].payGrade}
                        onChange={e => { 
                            setState({
                                ...state,
                                "pay-grade": PayGradeDomesticHomeownerOpts["40+"].payGrade,
                            });
                            clickHandler(PayGradeDomesticHomeownerOpts["40+"].payGrade,"USDomesticHomeownerTier1");
                        }}
                    />
                    <p className="title">40 and above</p>
                </div>
            </div>
            {state["pay-grade"] && (
                <div className="download-policy-section">
                    {state["pay-grade"] === PayGradeDomesticHomeownerOpts["24-"].payGrade && (
                        <GetPolicy title={policyEnum.USDomesticHomeownerTier3.displayText}
                            policyName={policyEnum.USDomesticHomeownerTier3.key}
                            disableContinueBtn={props.disableContinueBtn}
                            history={props.history}
                            />
                    )}
                    {state["pay-grade"] === PayGradeDomesticHomeownerOpts["25-26-0-3YOW"].payGrade && (
                        <GetPolicy title={policyEnum.USDomesticHomeownerTier3.displayText}
                            policyName={policyEnum.USDomesticHomeownerTier3.key}
                            disableContinueBtn={props.disableContinueBtn}
                            history={props.history}
                            />
                    )}
                    {state["pay-grade"] === PayGradeDomesticHomeownerOpts["25-26-3+YOE"].payGrade && (
                        <GetPolicy title={policyEnum.USDomesticHomeownerTier2.displayText}
                            policyName={policyEnum.USDomesticHomeownerTier2.key}
                            disableContinueBtn={props.disableContinueBtn}
                            history={props.history}
                            />
                    )}
                    {state["pay-grade"] === PayGradeDomesticHomeownerOpts["30-31"].payGrade && (
                        <GetPolicy title={policyEnum.USDomesticHomeownerTier2.displayText}
                            policyName={policyEnum.USDomesticHomeownerTier2.key}
                            disableContinueBtn={props.disableContinueBtn}
                            history={props.history}
                            />
                    )}                    
                    {state["pay-grade"] === PayGradeDomesticHomeownerOpts["40+"].payGrade && (
                        <GetPolicy title={policyEnum.USDomesticHomeownerTier1.displayText}
                            policyName={policyEnum.USDomesticHomeownerTier1.key}
                            disableContinueBtn={props.disableContinueBtn}
                            history={props.history}
                            />
                    )}
                    <>
                        <p className="lum-sum-policy-header-title" >{policyEnum.LumpSum.title}</p>
                        <p className="lum-sum-policy-checkbox-area" ><Checkbox checked={isChecked} onChange={() => {checkedHandler()}} /> &nbsp;Yes</p>
                        <br />
                    </>
                </div>
            )}
          </div>
    );
};

export default Homeowner;
