export const generalMessages = [
    'Now for those financial details. ',
    'Your finance partner is the finance individual for the department which is paying for the move.'
];

export const partners = [
    {
        label: 'McGrath, Kelly',
        subLabel: 'GLOBAL HEAD OF DIGITAL, TALENT AC…',
        email: 'email@its.jnj.com',
        wwid: '1234567890'
    },
    {
        label: 'Katalvarnia, Kellyn',
        subLabel: 'CUSTOMER CARE ADVISOR',
        email: 'email@its.jnj.com',
        wwid: '1234567890'
    },
    {
        label: 'Kelson, Jeremiah',
        subLabel: 'QC TECHNICIAN',
        email: 'email@its.jnj.com',
        wwid: '1234567890'
    },
    {
        label: 'DellaPeitro, Kyle',
        subLabel: 'COMMUNICATION LEAD',
        email: 'email@its.jnj.com',
        wwid: '1234567890'
    }
];
