// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { contactsServie } from 'services';
import { getUserProfile, getContacts } from 'selectors';

import { Accordion, Tabs} from 'components/sections';
import { HelpComponent } from 'components/common';

import './style.scss';
import _ from 'lodash';

const mapStateToProps = (state) => ({
    contacts: getContacts(state),
    user: getUserProfile(state),
    tabKey: 'int'
});

class ContactUs extends PureComponent {

    state = {
        tabKey : 'int'
    }

    componentDidMount(){
        this.props.user.currentRelocationId && this.props.fetchContacts(this.props.user.currentRelocationId);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.user.currentRelocationId && nextProps.user.currentRelocationId) {
            this.props.fetchContacts(nextProps.user.currentRelocationId);
        }
    }
    tabsOnchange = (tab : {key: string}) => {
        this.setState({
            tabKey: tab.key
        });
    };

    groupByCategory = (av) => {
        return _.chain(av)
        .groupBy("category")
        .value();
    }

    getContactData = () => {
        return this.props.user.currentRelocationId && this.props.contacts.authorizedVendors && this.props.contacts.authorizedVendors.length ? [
            {
                title:"Authorized Vendors",
                body: {
                    description: "",
                    content: this.groupByCategory(this.props.contacts.authorizedVendors)
                }
            },
        ] : null;
    }


    getMoveType(){
        return this.props.user.currentRelocationIsDomestic ? 'dom' : 'int';
    }

    getTabKey(){

        return this.props.user.currentRelocationId ? this.getMoveType() : this.state.tabKey
    }

    render() {
        return (
            <section className="ContactUs">
                <h1 className="header-text" data-key="contact_us_title">Contacts</h1>

                {
                    this.props.user.currentRelocationId ?
                    <HelpComponent
                    title="Your Dedicated Relocation Consultant"
                    mode="contact-us"
                    />
                    :
                    <div className="flexible jBetween marginBot25">
                        <Tabs
                        className="linear-selected"
                        data= {[
                            {
                                label: 'Global Experiences',
                                key: 'int'
                            },
                            {
                                label: 'U.S. Domestic',
                                key: 'dom'
                            },
                            ]}
                        activeTabKey={'int'}
                        onTabChange={this.tabsOnchange}
                        />
                    </div>
                }
                <Accordion domestic={this.props.user && this.props.user.currentRelocationIsDomestic}  mode="contact" activeRelocation = {!!this.props.user.currentRelocationId} activeTabKey={this.getTabKey()} contactData={this.getContactData()}/>
            </section>
        );
    }
}

export default connect(mapStateToProps, contactsServie.actions)(ContactUs);
