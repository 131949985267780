import React from 'react';
import CompletedStep from 'components/common/Images/completedStep.svg';

import './styles.scss'

type StepperIconType = {
    isActive: boolean;
    isComplete: boolean;
}
export default class StepperIcon extends React.PureComponent<StepperIconType> {
    render(){
        const { isActive, isComplete } = this.props;
        
        if (isActive) {
            return <span  className="active"/>

        }

        if (isComplete) {
            return <img src={CompletedStep} alt="" className="complete"/> 
        }

        return <span  className="inactive"/>
    }
}
