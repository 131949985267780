import React, {PureComponent} from 'react';
import './style.scss';

export default class Uploader extends PureComponent {

    constructor(props) {
        super(props);
        this.uploadRef = React.createRef();
    }

    state = {
        value: [],
        base64: [],
        shouldShowLoader: false,
    };

    setValue = (event) => {
        this.setState(
            {value: [...event.target.files],shouldShowLoader: true,},
            this.encodeImageFileAsURL,
        );
    };

    promisifyEncoding = (file) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.onloadend = function () {
                res(reader.result);
            };
            reader.readAsDataURL(file);
        })
    };

    encodeImageFileAsURL() {
        Promise.all(this.state.value.map(file => this.promisifyEncoding(file)))
            .then(base64 => {
                this.setState({ base64 }, ()=> {
                    this.props.onFileChoosen(this.state.value, this.state.base64)
                        .finally(() => this.setState({
                            shouldShowLoader: false
                        }));
                })
            });
    }

    render() {
        return (
            <div className="Uploader">
                <div className="uploader-button" onClick={this.props.onOpen} >
                    <input
                        type="file"
                        onChange={this.setValue}
                        accept={this.props.accept || '.jpg, .jpeg, .png, .pdf'}
                        multiple
                        value=""
                        className="new"
                        name="filefield"
                        ref={this.uploadRef}
                        data-key={ this.props.dataKey }
                    />
                    <span className="title">{this.props.title}</span>
                    {this.state.shouldShowLoader ? <div className="Spinner"/> : null}
                </div>
            </div>
        )
    }
}
