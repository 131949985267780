import React from 'react';
import personWorld from 'components/common/Images/personWorld.svg';
import screen from 'components/common/Images/screen.svg';
import people from 'components/common/Images/people.svg';
import approval from 'components/common/Images/approval.svg';
import moving from 'components/common/Images/moving.svg';

export const IntArivalQuestionsData = [
    {
        title: 'What is the role of Home and Host Managers when managing a global assignee?',
        icon:personWorld,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">The Home and Host Managers both have important roles to play in ensuring successful completion of assignment goals and ensuring a smooth transition for the employee.<br /><br />
The Home Manager is responsible for providing employee development and job goals to the Host Manager. The Home and Host Manager will meet before the employee’s arrival to discuss the strategic intent of the move, targeted development opportunities and job performance goals for the employee. In addition, the Home Manager is responsible for the return of all company assets such as security badges, laptop and other digital assets.<br /><br />
The Host Manager is responsible for setting the stage for a strong employee start, including making workplace arrangements, submitting requests for equipment, and completing onboarding activities. We recommend assigning a host buddy or mentor to the new employee to help further smooth the transition into the new office.
                </p>
            </div>
        )
    },
    {
        title: 'What guidelines should I follow for goal setting?',
        icon:approval,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">The Host Manager is responsible for translating development goals provided by the home manager into meaningful responsibilities that will broaden employee’s skills and capabilities in the new location. Ongoing communication and planning between Home and Host Managers is important to a successful assignment and the transition to a next role.  Ensuring that the following best practices take place will optimally support assignment success for the business and the employee.
                </p>
                <ul className="list">
                    <li>Clearly stated objectives and goals specific to the assignment</li>
                    <li>An understanding of potential post-assignment plans</li>
                    <li>End of assignment planning six months in advance of assignment end date</li>
                </ul>
            </div>
        )
    },
    {
        title: 'How do I ensure that the employee has equipment on their first day?',
        icon:screen,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">Contact your local IT Help Desk for support and assistance with IT related equipment.</p>
            </div>
        )
    },
    {
        title: 'As a manager, what should I be focused on in the first month?',
        icon:people,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">During the first month, it’s important to allow the employee time to acclimate to the new environment. Organize a meeting with your new employee to reflect on the benefits and value of diversity. </p>
                {/* <h4 className="flexible horizontal expl_hr_acc_item_buttons">
                    <Link text="Cultural Facilitation Guide" externalLink='https://jnj.sharepoint.com/sites/leadershipandlearning/Pages/CourseDetail.aspx?CourseUniqueID=1140' />
                    <Link text="GlobeSmart" externalLink='https://jnj.sharepoint.com/sites/leadershipandlearning/Pages/CourseDetail.aspx?CourseUniqueID=1140'/>
                </h4> */}
            </div>
        )
    },
    {
        title: 'Who helps the employee if they have issues or problems with their relocation?',
        icon:moving,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">The employees Global Assignment Consultant will manage the move details and be the main point of contact the employee. The employee will be assigned a dedicated consultant who will provide support, guidance and answer any questions they might have about our policies. The consultant will also initiate and manage approved services such as immigration, tax, and destination services. </p>
            </div>
        )
    }
];
