import React, { PureComponent } from 'react';

import { Icon, Asset } from 'components/common';
import { onHistoryLinkClicked } from '../../utils';

export default class Resources extends PureComponent{

    recourcesData = [
        {
            title: 'Policies',
            icon: 'docs',
            content: [
                {
                    name: 'Benefit Summary: Global Assignees in the U.S. or Overseas',
                    type: 'PDF',
                    assetKey: "global-healthcare",
                    assetId: "global-assignees-in-the-U-S-or-overseas"
                },
                {
                    name: 'Benefit Summary: U.S. Based Assignees working outside the U.S.',
                    type: 'PDF',
                    assetKey: "global-healthcare",
                    assetId: "assignees-working-outside-the-u-s"
                },
                {
                    name: 'Benfit Summary: Assignees from Puerto Rico',
                    type: 'PDF',
                    assetKey: "global-healthcare",
                    assetId: "assignees-from-puerto-rico"
                },
            ]
        },
        {
            title: 'CIGNA Resources',
            icon: 'people',
            content: [
                {
                    name: 'Pre Assignment Assistance',
                    type: 'PDF',
                    assetKey: "global-healthcare",
                    assetId: "pre-assignment-assistance"
                },
                {
                    name: 'Envoy Registration for the Americas',
                    type: 'PDF',
                    assetKey: "global-healthcare",
                    assetId: "envoy_registration_americas"                    
                },
                {
                    name: 'Online Claims Submission',
                    type: 'PDF',
                    assetKey: "global-healthcare",
                    assetId: "online-claims-submission"
                },
                {
                    name: 'HIPAA Joint Notice of Privacy',
                    type: 'PDF',
                    assetKey: "global-healthcare",
                    assetId: "HIPAA_Privacy"
                },
            ]
        }
    ];

    generateRecourseItem = (data) => (
        data.map((item, index) => (
            <div className="resource-item-info flexible jBetween aCenter" key={index}
                onClick={() => { this.handleDownloadClick(item.assetKey, item.assetId, item.fileType, item.fileName)}}>
                <div className="name">{item.name}</div>
                <div className="type flexible aCenter">
                    {item.type}
                    <Icon name="download" width={30}/>
                </div>
            </div>
        ))
    );

    // provide saving metrics by click
    handleDownloadClick = async(assetKey : string, assetId : string, fileType : string, fileName : string) : void => {
        fileType === 'Website' 
            ? onHistoryLinkClicked({
                action:'click-website-link',
                category:'resources',
                label:fileName,
                onClick:() => window.open(fileName)
             })
            : new Asset({assetType:assetKey, assetId:assetId}).execute();
    }

    generateRecources = () => (
        this.recourcesData.map((item, index) => (
            <div className="resource-item flexible vertical" key={index}>
               <div className="title flexible aCenter jStart">
                   <Icon name={item.icon}/>
                   <span>{item.title}</span>
               </div>
                {this.generateRecourseItem(item.content)}
            </div>
        ))
    );

    render() {
        return(
            <div className="Resources">
                {this.generateRecources()}
            </div>
        )
    }
}