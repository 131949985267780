import { createDataReducer } from 'helpers';

const type = 'SITE_OVERVIEW';

const actions = {
    toggleSiteOverview: isOpen => ({
        type,
        payload: isOpen,
    })
};

const reducer = createDataReducer(type, false);

export default {
    type,
    actions,
    reducer
};
