import React, { PureComponent } from 'react';

import { Tabs } from 'components/sections';

import  Resources  from './Resources';
import  ManageYourHealth  from './ManageYourHealth';

import './style.scss';

export default class BenefitInformation extends PureComponent{

    state = {
        tabKey: 'manage-your-health',
    };

    setTabsData = () => (
        [
            {
                label: `Manage Your Health`,
                key: 'manage-your-health'
            },
            {
                label: `Resources`,
                key: 'resources'
            },
        ]
    );

    tabsOnchange = (tab) => this.setState({ tabKey: tab.key });

    generateContent = () => (
        this.state.tabKey === 'resources' ? <Resources/> : <ManageYourHealth/>
    );

    render(){
        return (
            <section className="BenefitInformation">
                <h1 className="header-text" data-key="benefit_info_title">Health Benefits</h1>
                <Tabs
                    className="linear-selected"
                    data={this.setTabsData()}
                    activeTabKey={'manage-your-health'}
                    onTabChange={this.tabsOnchange}
                />
                {this.generateContent()}
            </section>
        )
    }
}