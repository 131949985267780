// @flow

import * as React from 'react';

import {Icon} from 'components/common';

import './styles.scss';

type RightButtonType = {
    name: 'download' | string,
    onClick?: Function,
};

type ListItemProps = {
    header?: React.Node | string,
    middleContent?: React.Node | string,
    rightButtons?: React.Node | Array<RightButtonType>,
    children?: Array<React.Node>,
    className?: string,
    style: Object,
    key: number
};

export default class ListItem extends React.PureComponent<ListItemProps> {

    getButton = (button: RightButtonType): React.Node =>
        <Icon
            name={button.name}
            onClick={button.onClick}
            key={`${button.name}_this.props.key`}
            className={button.name}
        />;

    render() {
        const {
            header,
            middleContent,
            rightButtons,
            children,
            className = '',
            style,
            key,
            onClick
        } = this.props;
        return (
            <div
                className={`ListItem flexible jAround aCenter ${className}`}
                style={style}
                key={key}
                onClick={onClick}
            >
                {
                    header ?
                        React.isValidElement(header) ?
                            header : <div className="list-item-header flexible aCenter">
                                { header }
                            </div>
                        : null
                }
                {
                    children && <div className="list-item-body flexible aCenter jCenter">
                        {children}
                    </div>
                }
                {
                    middleContent ?
                        React.isValidElement(middleContent) ?
                            middleContent : <div className="list-item-middle-content flexible aCenter jEnd">
                                { middleContent }
                            </div>
                        : null
                }
                {
                    rightButtons ?
                        React.isValidElement(rightButtons) ?
                            rightButtons : <div className="list-item-right-buttons flexible aCenter jEnd">
                                {
                                    rightButtons.map(button => this.getButton(button))
                                }
                            </div>
                        : null
                }
            </div>
        );
    }
}
