import React from 'react';

import TextField from '@material-ui/core/TextField';
import { browserDetecter } from 'utils/IESupport.js'

import './TextField.scss';


const numberRegex = /^\d*(\.\d{0,2})?$/;

export default class JnJTextField extends React.PureComponent {
    onChange = event => {
        if(this.props.type === 'number' && browserDetecter() === 'ie'
            && !numberRegex.test(event.target.value)) {
            event.preventDefault();
        } else {
            this.props.onChange && this.props.onChange(event);
        }
    };

    handleKeyDown = event => {
        if(this.props.type === 'number' && event.keyCode === 69)
            event.preventDefault();
    };

    render() {

        const { props } = this;

        const type = (browserDetecter() === 'ie' && props.type === "number") ? "text" : props.type;

        return (
            <div className="TextField">
                <TextField
                    disabled={props.disabled}
                    type={type}
                    label={props.label}
                    value={props.value || ''}
                    onChange={this.onChange}
                    onKeyDown={this.handleKeyDown}
                    InputLabelProps={{
                        shrink: props.value !== undefined,
                    }}
                    fullWidth
                    inputProps={{
                        'data-key': props.dataKey
                    }}
                />
                {
                    (props.showValidationMessages && props.errorMessages) && props.errorMessages.map((message, i) => (
                        <p className="formElement_errorText" key={i}>
                            {message}
                        </p>
                    ))
                }
            </div>
        );
    }

  /*render() {
    const { props } = this;
    const {
        value,
        label
    } = props;

    return (
      <div className="TextField">
          <TextField
            label={label}
          />
          <input
            value={value}
            type={props.type}
            name={props.name}
            onChange={this.onChange}
            placeholder={props.placeholder}
            autoComplete="true"
            {...additionalInputProps}
          />

        </div>
        {(props.errorText && props.showValidationMessages) && (
          <p className="formElement_errorText">
            {props.errorText}
          </p>
        )}
        {props.helperText && (
          <p className="formElement_helperText">
            {props.helperText}
          </p>
        )}

      </div>
    );
  }*/
}
