import React from 'react';
import {RadioButton} from 'components/common';
import moment from 'moment';

import {
    FormsyText,
    FormsyDatePicker,
} from 'components/sections/ValidatableForm';
import { Button } from 'components/common';

import { browserDetecter } from 'utils/IESupport';

import {
    EXCHANGE_METHODS,
    DATE_TIME_FORMAT,
    FORMAT_TO_SHOW
} from './index';
export default class CurrencyConversion extends React.PureComponent {

    state = {
        exchangeDate: new Date(),
        exchangeRate: ''
    };

    handleChange = (type) => {
        this.props.onChange && this.props.onChange({target: {value: type}});
    };

    handleExchangeDateChange = ({target, ...eventRest}) => {
        const value = target ? target.value : Object.values(eventRest).join('')
        this.setState({exchangeDate: value});
    };

    handleExchangeRateChange = ({target, ...eventRest}) => {
        const value = target ? target.value : Object.values(eventRest).join('')
        this.setState({exchangeRate: value});
    };

    convert = () => {
        this.props.handleOverride({
            exchangeDate: this.state.exchangeDate,
            exchangeRate: this.state.exchangeRate
        })
    };

    componentWillMount() {
        const { exchangeRateInfo: { Override } } = this.props;

        if(Override) {
            this.setState({...Override});
        }

    }

    componentWillReceiveProps(nextProps) {
        const { exchangeRateInfo: { Override: prevOverride } } = this.props;
        const { exchangeRateInfo: { Override: nextOverride } } = nextProps;

        if(!prevOverride && nextOverride) {
            this.setState({...nextOverride});
        }
    }

    render() {
        const { type, exchangeRateInfo = {} } = this.props;
        const { InterBank = {}, Override = {} } = exchangeRateInfo;

        const IncurredAmount = InterBank.exchangeRate ? ((this.props.incurredAmount || 0) * InterBank.exchangeRate).toFixed(2) : '';
        const OverridedAmount = Override.exchangeRate ? ((this.props.incurredAmount || 0) * Override.exchangeRate).toFixed(2) : '';
        const isConvertButtonDisabled = !(moment(this.state.exchangeDate).isValid() && this.state.exchangeRate);
        const exchangeDate = moment(InterBank.exchangeDate).format(FORMAT_TO_SHOW);

        return (
            <div className="currency-conversion-root">
                <div className="interbank-exchange-container border-top border-bottom">
                    <div className="exchange-name flexible horizontal">
                        <div className="flexible shrink aStart">
                            <RadioButton
                                checked={ type === EXCHANGE_METHODS.InterBank}
                                onChange={() => this.handleChange('InterBank')}
                            />
                        </div>
                        <div className="flexible vertical exchange-info-block">
                            <p className="exchange-title">Recommended:  Interbank Exchange Rate</p>
                            <p className="exchange-description">Interbank rates are the “official” rates as quoted in media such as The Wall Street Journal.</p>
                        </div>
                    </div>
                    <div className="exchange-info">
                        <div className="exchange-rate-date">
                            <p className="text-with-label">
                                <span className="label">Exchange Date</span>
                                <span className="text">{exchangeDate}</span>
                            </p>
                            <p className="text-with-label">
                                <span className="label">Exchange Rate</span>
                                <span className="text">{InterBank.exchangeRate}</span>
                            </p>
                        </div>
                        <div className="reimbursed-amount">
                            <p className="text-with-label">
                                <span className="label">Amount to be reimbursed</span>
                                <span className="text">{ IncurredAmount } {this.props.reimbursementCurrency}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="override-container border-bottom">
                    <div className="exchange-name flexible horizontal">
                        <div className="flexible shrink aStart">
                            <RadioButton
                                checked={ type === EXCHANGE_METHODS.Override}
                                onChange={() => this.handleChange('Override')}
                            />
                        </div>
                        <div className="flexible vertical exchange-info-block">
                            <p className="exchange-title">Override</p>
                            <p className="exchange-description">You can choose to specify the exchange rate and date. This will be audited during the processing of the expense report.</p>
                        </div>
                    </div>
                    {
                        (type === EXCHANGE_METHODS.Override) &&
                        <div className="exchange-info">
                            <div className="override-date-rate">
                                <div className="override-field">
                                    <FormsyDatePicker
                                        _required
                                        validations={{ isDate: DATE_TIME_FORMAT }}
                                        validationErrors={{ isDate: "Please select correct date" }}
                                        defaultValue={this.state.exchangeDate}
                                        format={DATE_TIME_FORMAT}
                                        formatToShow={FORMAT_TO_SHOW}
                                        onChange={this.handleExchangeDateChange}
                                        label="Exchange Date"
                                        name="echangeDate"
                                    />
                                </div>
                                <div className="override-field">
                                    <FormsyText
                                        _required
                                        validations="_required"
                                        validationErrors={{ _required: 'Please fill' }}
                                        type={browserDetecter() === 'firefox' ? 'text' : 'number'}
                                        name="exchangeRate"
                                        label="Exchange Rate"
                                        value={this.state.exchangeRate}
                                        onChange={this.handleExchangeRateChange}
                                    />
                                </div>
                                <div className="override-field">
                                    <Button
                                        dataKey="calculate-override"
                                        className="override-button"
                                        title="Calculate Expense"
                                        simple
                                        disabled={isConvertButtonDisabled}
                                        onClick={this.convert}
                                    />
                                </div>
                            </div>
                            <div className="reimbursed-amount">
                                <p className="text-with-label">
                                    <span className="label">Amount to be reimbursed</span>
                                    <span className="text">{ OverridedAmount } {this.props.reimbursementCurrency}</span>
                                </p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
