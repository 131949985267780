// @flow

import React, { PureComponent } from 'react';

import { Asset } from 'components/common';
import IconWithTitle from './IconWithTitle';
import './style.scss';

export default class MoMoveOverview extends PureComponent {
    render() {
        return (
            <div className="my-move-overview flexible">
                <div className="left-content flexible jCenter">
                    <Asset
                        assetType="WEBSITE_OVERVIEW"
                        assetId={this.props.assetId}
                        showLoading
                    />
                </div>
                <div className="right-content flexible vertical">
                    <div className="body1 mbottom-15">
                        {this.props.title.split('\n').map((item, i) => {
                            return <p key={i}>{item}</p>;
                        })}
                    </div>
                    <p className="body2">{this.props.description}</p>

                    <div className="managements flexible">
                        {this.props.managements.map(el => {
                            const { title, iconName, iconStyle = {}, textWidth } = el;

                            return <IconWithTitle key={title} title={title} iconName={iconName} {...iconStyle} textWidth={textWidth} />;
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
