import React from "react";
import classNames from "classnames";
import Stepper from "./Stepper";
import stepperData from "./MockData";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserProfile } from "selectors";
import RequestOverview from "./RequestOverview";
import JobDetails from "./JobDetails";
import Finance from "./Finance";
import ReviewAndSubmit from "./ReviewAndSubmit";
import Approvals from "./Approvals";
import SuccessCompletion from "./Success";
import PolicySelection from "./PolicySelection";
import { MoveDetailsProps, MoveDetailsState, MoveDetailsStepper } from "types";
import "./styles.scss";

const mapStateToProps = (state: any) => ({
    userProfile: getUserProfile(state),
});

class MoveDetails extends React.Component<MoveDetailsProps, MoveDetailsState> {
    constructor(props: MoveDetailsProps) {
        super(props);
        this.state = {
            stepper: stepperData,
            activeStepperIndex: 0,
            comp: this.stepMap["request-overview"],
        };
    }

    navigateTo = (to: string, from: string): void => {
        this.setState({
            comp: this.stepMap[to],
        });
        this.generateStepper(to, from);
    };

    stepMap = {
        "request-overview": <RequestOverview navigateTo={this.navigateTo} />,
        "policy-selection": (
            <PolicySelection
                navigateTo={this.navigateTo}
                history={this.props.history}
                location={this.props.location}
            />
        ),
        "job-details": <JobDetails navigateTo={this.navigateTo} />,
        finance: <Finance navigateTo={this.navigateTo} />,
        approvals: <Approvals navigateTo={this.navigateTo} />,
        "review-submit": (
            <ReviewAndSubmit
                navigateTo={this.navigateTo}
                history={this.props.history}
            />
        ),
    } as { [key: string]: JSX.Element };

    generateStepper = (state: string, prevState: string): void => {
        const newStepper: Array<MoveDetailsStepper> = this.state.stepper.map(
            (item: MoveDetailsStepper): MoveDetailsStepper => {
                if (prevState === item.key) {
                    return {
                        ...item,
                        isComplete: true,
                        active: false,
                    };
                } else if (item.key === state) {
                    return {
                        ...item,
                        active: true,
                    };
                } else {
                    return {
                        ...item,
                        active: false,
                    };
                }
            },
        );
        this.setState({
            stepper: newStepper,
            activeStepperIndex: newStepper.findIndex(
                (el: MoveDetailsStepper): boolean => el.active === true,
            ),
        });
    };
    render() {
        const { step } = this.props.match.params;
        return (
            <>
                {step !== "success" && (
                    <div className="MoveDetailContainer">
                        <aside className="StepperContainer">
                            <Stepper
                                steps={this.state.stepper}
                                activeStepIndex={this.state.activeStepperIndex}
                            />
                        </aside>
                        <hr className="vertical-line" />
                        <section
                            className={classNames({
                                ComponentsContainer: true,
                                JobDetails: this.state.activeStepperIndex === 3,
                                Finance: this.state.activeStepperIndex === 4,
                            })}
                        >
                            {this.state.comp}
                        </section>
                    </div>
                )}
                {step === "success" && (
                    <div className="MoveDetailContainer move-details-success">
                        <div className="success-container">
                            <SuccessCompletion {...this.props} />
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps)(MoveDetails));
