import { createDataReducer } from "../../helpers";

const type = 'ERROR';

const actions = {
    toggleErrorModal: (isOpen, title, description) => ({
        type,
        payload: { isOpen, title, description }
    })
};

const reducer = createDataReducer(type, { isOpen: false, title: '', description: '' });

export default {
    type,
    actions,
    reducer
};
