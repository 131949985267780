import { getDataStore } from './index';

export const getRecentAllowance = (state) => getDataStore(state).recentAllowance;

export const getGalAllowances = (state) => {
    const recentAllowance = getRecentAllowance(state);
    return recentAllowance ? recentAllowance.galAllowances : null;
};
export const getAcknowledgementLetter = (state) => {
    const recentAllowance = getRecentAllowance(state);
    return recentAllowance ? recentAllowance.acknowledgementLetter : null;
};

export const getFilteredAllowances = (state) => {
    const allowances = getGalAllowances(state);

    return allowances ? {
        ...allowances,
        categories: Array.isArray(allowances.categories) ? allowances.categories.filter(category => {
            const allowances = category.allowances;
            if(Array.isArray(allowances) && allowances.length) {
                for(const allowance of allowances) {
                    if(allowance.valueType !== "Boolean" || (allowance.value !== 0))
                        return true;
                }
            }
            return false;
        }) : null
    } : null;
};
