import React from "react";
import { Icon, SelectionCard } from "components/common";
import TalentMobilityExec from "../TalentMobilityExec";
import { RouterProps } from "types";
import GlobalVSDomestic from "../GlobalVSDomestic";
import "./styles.scss";

const PayGrade = (props: RouterProps) => {
    const clickHandler = (value: string, payGrade: string): void => {
        props.history.push({
            state: {
                ...props.location.state,
                "pay-grade": value,
                payGrade: payGrade,
            },
        });
    }
    const backHandler = (): void =>
        props.history.push({
            state: {
                ...props.location.state,
                "working-with-recruiter": "",
            },
        });

    if (props.location.state && props.location.state["pay-grade"] === "41-") {
        return (
            <GlobalVSDomestic
                history={props.history}
                location={props.location}
            />
        );
    } else if (
        props.location.state &&
        props.location.state["pay-grade"] === "50+"
    ) {
        return (
            <TalentMobilityExec
                history={props.history}
                location={props.location}
            />
        );
    }

    return (
        <div className="people-leader-container">
            <div className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </div>
            <h1 className="section-title">What is the pay grade of the employee?</h1>
            <section className="CardSection">
                <SelectionCard
                    type="regularSelectionCard"
                    backgroundColor="white"
                    title="Paygrade"
                    content="50 and above"
                    className="pay-grade"
                    onClick={() => clickHandler("50+","PayGrade50")}
                />
                <SelectionCard
                    type="regularSelectionCard"
                    backgroundColor="white"
                    title="Paygrade"
                    content="41 and below"
                    className="pay-grade"
                    onClick={() => clickHandler("41-","PayGrade40")}
                />
            </section>
        </div>
    );
};

export default PayGrade;
