import React, { Component } from 'react';

export default class IFrameComponent extends Component {
    constructor() {
        super();
        this.state = {
            loaded: false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loaded: false
        });
    }

    iframeDidLoad(e) {
        this.setState({
            loaded: true
        });
    }

    componentDidMount() {
        this.iframe.onload = this.iframeDidLoad.bind(this);
    }

    render() {
        const iframeStyle = {
            display: this.state.loaded ? "block" : "none"
        };

        return (
            <div className="flexible vertical aCenter jCenter">
                <iframe title={this.props.src} src={this.props.src} ref={(element) => this.iframe = element} height="800px" style={iframeStyle} />
                {
                    !this.state.loaded && <div className="Spinner"/>
                }
            </div>
        )
    }
}
