import React, { PureComponent } from "react";
import IdleTimer from "react-idle-timer";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userService, siteOverviewService, tasksService } from "services";
import { getIsCompetetive, getUserAuth, getUserProfile } from "selectors";
import { clearSessionStorage } from "utils";
import { Header, Footer, RootExtraContent } from "components/sections";
import {
    Identification,
    ContactUs,
    Login,
    IconsList,
    CookiePolicy,
    PrivacyPolicy,
    About,
    HrMainProfile,
    HrMainProfile2,
    ArticleDetail,
    PageUnavailable,
    AccessDenied,
    InitiateMove,
    MoveDetails,
    PreIntiateMoveIntro2,
    CompetitiveInitiation,
    NonCompetitiveInitiation,
} from "containers";
import Asset from "components/common/Asset";
import ErrorModal from "components/common/Modal/ErrorModal";
import NoMatch from "containers/NoMatch";
import { Callback } from "containers/Login";
import "./App.scss";
import ReactGA from "react-ga";
import {
    ReactPlugin,
    withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import history from "./history";
import competetiveHeader from "./services/uiServices/competetiveHeader";

const mapStateToProps = store => ({
    userAuth: getUserAuth(store),
    user: getUserProfile(store),
    isCompetetive: getIsCompetetive(store),
});

const mapDispatchToProps = {
    getUser: userService.actions.getUser,
    toggleSiteOverview: siteOverviewService.actions.toggleSiteOverview,
    fetchTasks: tasksService.actions.fetchTasks,
    toggleCompetetiveMode: competetiveHeader.actions.toggleCompetetiveMode,
};

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey:
            process.env.REACT_APP_APPLICATION_INSIGHT_INSTRUMENTATION_KEY,
    },
    extensions: [reactPlugin],
    extensionsConfig: {
        [reactPlugin.identifier]: { history },
    },
});
appInsights.loadAppInsights();

export const appInsightsTracking = appInsights;

class App extends PureComponent {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
    }

    componentDidMount() {
        this.toggleCompetetiveMode(history.location);
        history.listen(this.toggleCompetetiveMode);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.userAuth.email && nextProps.userAuth.email) {
            this.props.getUser(nextProps.userAuth.email);
        }

        if (this.isJibeUnassignedUser(nextProps.user)) {
            return;
        }

        if (
            !this.props.user.role &&
            nextProps.user.role &&
            ["Assignee", "HrManager"].includes(nextProps.user.role) &&
            !nextProps.user.isOverviewShowed
        ) {
            this.props.toggleSiteOverview(true);
        }

        // When props change, check if the URL has changed or not
        if (
            this.props.location.pathname !== nextProps.location.pathname ||
            this.props.location.search !== nextProps.location.search
        ) {
            this.sendPageChange(
                nextProps.location.pathname,
                nextProps.location.search,
            );

            if (nextProps.history.action === "PUSH") window.scrollTo(0, 0);
        }

        if (
            !this.props.user.currentRelocationId &&
            nextProps.user.currentRelocationId
        ) {
            this.props.fetchTasks(nextProps.user.currentRelocationId);
        }
    }

    sendPageChange(pathname, search = "") {
        const page = pathname + search;
        const userId =
            this.props.user && this.props.user.email
                ? this.props.user.email
                : "";
        const level =
            this.props.user && this.props.user.role ? this.props.user.role : "";

        ReactGA.set({ page, userId, level });
        ReactGA.pageview(page);

        appInsights.setAuthenticatedUserContext(userId);
        appInsights.trackPageView(document.title, page, { userId, level });
    }

    //automatic user logoff after 15 min idle
    _onIdle(e) {
        if (this.props.userAuth.email) {
            window.localStorage.clear();
            clearSessionStorage();
            userService.userManager.signoutRedirect({
                id_token_hint: userService.userManager.id_token_hint,
            });
            userService.userManager.removeUser();
        }
    }

    isJibeUnassignedUser = userProfile => {
        if (!userProfile) {
            return false;
        }
        return userProfile.isJibeUser && userProfile.isJibeUnassignedUser;
    };

    toggleCompetetiveMode = location => {
        const isCompetetive = location.pathname.includes("/move-details");

        if (isCompetetive === this.props.isCompetetive) {
            return;
        }

        this.props.toggleCompetetiveMode(isCompetetive);
    };

    render() {
        const { user } = this.props;
        const isJibeUnassignedUser = this.isJibeUnassignedUser(user);

        const common = {
            identification: (
                <Route
                    exact
                    path="/identification/:switchrole?"
                    component={Identification}
                />
            ),
            home: <Redirect exact from="/" to="/identification" />,
            otherwise: <Route component={NoMatch} />,
            contactUs: <Route exact path="/contact-us" component={ContactUs} />,
            privacyPolicy: (
                <Route exact path="/privacypolicy" component={PrivacyPolicy} />
            ),
            icons: <Route path="/icons" component={IconsList} />,
            about: <Route path="/about" component={About} />,
            asset: (
                <Route path="/asset/:assetType/:assetId" component={Asset} />
            ),
            // executive: <Route exact path="/executive" component={Executive} />,
            cookiePolicy: (
                <Route exact path="/cookiepolicy" component={CookiePolicy} />
            ),
            loginCallback: (
                <Route exact path="/login/callback" component={Callback} />
            ),
            legalNotice: (
                <Route
                    path="/legalnotice"
                    component={() =>
                        (window.location =
                            "https://www.jnj.com/corporate/legal-notice")
                    }
                />
            ),
            articleDetail: (
                <Route
                    path="/article-detail/:articleIndex?"
                    component={ArticleDetail}
                />
            ),
            pageUnavailable: (
                <Route path="/page-unavailable" component={PageUnavailable} />
            ),
        };

        const Publics = () => {
            return (
                <Switch>
                    <Route exact path="/login" component={Login} />
                    {common.legalNotice}
                    {/* {common.executive} */}
                    {common.cookiePolicy}
                    {common.privacyPolicy}
                    {common.loginCallback}
                    <Redirect
                        from="**"
                        to={"/login?returnUrl=" + window.location.pathname}
                    />
                </Switch>
            );
        };

        const Private = () => {
            const LayoutGroup = {
                Assignee: (
                    <Switch>
                        {/* <Route
                            path="/profile/:move/:step?"
                            component={Profil}
                        />
                        <Route
                            path="/resources/:selectTab?/:selectCategory?"
                            component={Resources}
                        />
                        <Route exact path="/your-moves" component={YourMoves} />
                        <Route
                            exact
                            path="/my-documents/:tabId?"
                            component={MyDocuments}
                        />
                        <Route exact path="/tasks/:mode?" component={Tasks} />
                        <Route
                            exact
                            path="/expense-report"
                            component={() => <ExpenseReport user={user} />}
                        />
                        <Route
                            exact
                            path="/expense-report/:action/:expRepId?"
                            component={CreateEditExpenseReport}
                        />
                        <Route
                            exact
                            path="/expense-report/:action/:expRepId?/line-item/:actionType/:lineItemId?"
                            component={CreateEditLineItem}
                        />
                        <Route
                            exact
                            path="/assignment-letter/:type?"
                            component={AssignmentLetter}
                        />
                        <Route
                            exact
                            path="/benefit-info"
                            component={BenefitInformation}
                        />
                        <Route
                            path="/competitive-initiation/:requisitionContestNumber/:candidateNumber/:requisitionHmgrEmail"
                            exact
                            component={CompetitiveInitiation}
                        />
                        <Route
                            path="/move-dashboard"
                            component={HrMainProfile2}
                        />
                        {!window.location.href.includes("move.jnj.com") && (
                            <Route
                                exact
                                path="/tax-milestones"
                                component={TaxMilestones}
                            />
                        )} */}
                        {common.otherwise}
                        <Route exact path="/not-found" component={NoMatch} />
                    </Switch>
                ),
                HrManager: (
                    <Switch>
                        {/* <Route
                            path="/profile/:move/:step?"
                            component={HrMoveInfoProfile}
                        /> */}
                        <Route
                            path="/profile/:move/:step?"
                            component={NoMatch}
                        />
                        <Route
                            path="/profile/"
                            exact
                            component={HrMainProfile}
                        />
                        <Route
                            path="/competitive-initiation/:requisitionContestNumber/:candidateNumber/:requisitionHmgrEmail"
                            exact
                            component={CompetitiveInitiation}
                        />
                        {/* <Route
                            path="/resources/:selectTab?/:selectCategory?"
                            component={HrResources}
                        /> */}
                        <Route
                            path="/resources/:selectTab?/:selectCategory?"
                            component={NoMatch}
                        />
                        <React.Fragment>
                            <Route
                                path="/move-dashboard"
                                component={HrMainProfile2}
                            />
                            {/* <Route
                                path="/pre-initiate-move/:step?"
                                component={PreIntiateMoveIntro}
                            /> */}
                            <Route
                                path="/pre-initiate-move/:step?"
                                component={PreIntiateMoveIntro2}
                            />
                            <Route
                                path="/non-competitive-initiation/:step?"
                                component={NonCompetitiveInitiation}
                            />
                            <Route
                                path="/initiate-move/:step?"
                                component={InitiateMove}
                            />
                            <Route
                                path="/move-details/:step?"
                                component={MoveDetails}
                            />
                        </React.Fragment>
                        {common.otherwise}
                        <Route exact path="/not-found" component={NoMatch} />
                    </Switch>
                ),
            };

            return (
                <Switch>
                    {common.home}
                    {common.privacyPolicy}
                    {common.cookiePolicy}
                    {common.legalNotice}
                    {common.identification}
                    {[
                        "/about",
                        "/article-detail/:articleIndex?",
                        "/asset/:assetType/:assetId",
                        "/assignment-letter/:type?",
                        "/benefit-info",
                        "/executive",
                        "/expense-report",
                        "/expense-report/:action/:expRepId?",
                        "/expense-report/:action/:expRepId?/line-item/:actionType/:lineItemId?",
                        "/icons",
                        "/tasks",
                    ].map(urlFrom => (
                        <Route exact path={urlFrom} component={NoMatch} />
                    ))}
                    {/* {common.executive}
                    {common.about}
                    {common.articleDetail}
                    {common.asset} */}
                    {common.pageUnavailable}
                    {/* {common.icons} */}
                    {common.contactUs}
                    {LayoutGroup[this.props.user.role] || LayoutGroup.HrManager}
                </Switch>
            );
        };

        if (
            user &&
            user.email &&
            this.props.location.pathname.indexOf("/asset/") >= 0 &&
            !isJibeUnassignedUser
        ) {
            const items = this.props.location.pathname.split("/");
            if (items && items.length > 3) {
                return (
                    <Asset
                        assetType={items[2]}
                        assetId={items[3]}
                        showLoading={true}
                        isLink={false}
                    />
                );
            }
        }

        const route = isJibeUnassignedUser ? (
            <Switch>
                {common.cookiePolicy}
                {common.privacyPolicy}
                <Route path="/" component={AccessDenied} />
            </Switch>
        ) : this.props.userAuth.email ? (
            Private()
        ) : (
            Publics()
        );

        return (
            <IdleTimer
                ref={ref => {
                    this.idleTimer = ref;
                }}
                element={document}
                onIdle={this.onIdle}
                timeout={1000 * 60 * 15}
            >
                <article className="flexible vertical height100vh">
                    <ErrorModal />
                    <RootExtraContent />

                    <Header
                        className="staticSize"
                        logged={!this.props.userAuth.email}
                    />
                    <div id="pending">
                        <div className="Spinner" />
                    </div>
                    <div className="resizeSize flexible vertical">
                        {(this.props.userAuth.isLoadingUser ||
                            this.props.user.isLoading) &&
                        !this.props.location.pathname.includes(
                            "page-unavailable",
                        ) ? (
                            <div className="Spinner" />
                        ) : (
                            route
                        )}
                    </div>
                    <Footer
                        className="staticSize"
                        logged={!this.props.userAuth.email}
                    />
                </article>
            </IdleTimer>
        );
    }
}

export default withAITracking(
    appInsights,
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(App),
    ),
);
