import React, { useState } from "react";
import { Icon, Accordion, SpinnerWrapper } from "components/common";
import { RouterProps } from "types";
import { useDispatch } from "react-redux";
import { moveInitiationService } from "services";
import "./styles.scss";

const CongratulationsText = () => (
    <div className="congratulations-text">
        Congratulations! You've completed the Pre-initiation process. It takes 
        48 hours to approve a Requisition ID. Once you receive confirmation, come 
        back to <span className="move-base-url" >move.jnj.com</span> and click on "I need to continue an initiation 
        request I already started" to complete your relocation initiation.
    </div>
);

const FirstStep = () => (
    <div className="req-wd-first-step">
        <div className="blue-box">
            <Icon name="cautionRed" width={40} height={40} />
            <div className="blue-box-texts">
                <p>
                    If you need additional support with the Workday Requisition 
                    Creation Process, please contact AskGS.
                </p>
                <span className="link-info">
                    <a
                        target="_blank"
                        href="https://jnjgsportal--c.na80.visual.force.com/apex/cl_Index?sfdc.tabName=01r1a0000001VdH#/knowledgedirect/Policies/Contact-Us/fromFooter"
                    >
                        Contact AskGS
                        <Icon
                            name="linkIcon"
                            className="link-btn"
                            width={21}
                            height={21}
                        />
                    </a>
                </span>
            </div>
        </div>
        <div className="bullet-list">
            <div className="title">
                Before you start, make sure you have the following info:
            </div>
            <ul>
                <li>Location*</li>
                <li>Legal entity (a 4-digit code)</li>
                <li>MRC (Management Reporting Company)</li>
                <li>Cost center*</li>
                <li>Pay Grade & Function</li>
            </ul>
        </div>
    </div>
);

const SecondStep = () => (
    <div className="req-wd-second-step">
        <div className="content">
            <div className="bullet-list">
                <div className="title">Launch Workday and follow these steps: </div>
                <ul>
                    <li>
                        Open Workday using Google Chrome or IE11.
                    </li>
                    <li>
                        Click the <b>Hiring Worklet</b> or type in “Create Job Requisition”.
                    </li>
                    <li>
                        Click <b>Create Job Requisition.</b>
                    </li>
                    <li>
                        Complete required fields for Job Creation and click <b>ok to submit.</b>
                    </li>
                    <li>
                        Complete required fields for Recruiting Information.
                    </li>
                </ul>
            </div>
            <div className="bullet-list">
                <ul>
                    <li>Complete required fields for Job Information and click <b>submit to confirm.</b></li>
                    <li>Complete required fields for Change Organization Assignment and click <b>submit to finish.</b></li>
                    <li>Click <b>done</b> to return to leave Create Job Requisition process.</li>
                </ul>
            </div>
        </div>
        <p className="bottom-text">
            You will receive an email notification when the requisition is approved. This normally takes up to 48 business hours. 
            You will be able to retrieve your requisition ID in <b>My Team Management</b> once approved.
        </p>
         <button className="submit-button"><a href ="http://jjworkday.com/"> Submit and Open Workday</a></button>
    </div>
);

const ReqWdScreen = (props: RouterProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [continueButtondisabled, setContinueButtondisabled] = useState(false);
    const disableContinueBtnHandler = (continueButtondisabled: boolean): void =>setContinueButtondisabled(continueButtondisabled);

    const backHandler = (): void => {
        if (props.prevPage === "domestic") {
            props.history.push({
                state: {
                    ...props.location.state,
                    "country-or-other": "",
                    "global-vs-domestic": "",
                    "owm-or-assignment": "",
                },
            });
        } else {
            props.history.push({
                state: {
                    ...props.location.state,
                    "country-or-other": "",
                    "owm-or-assignment": "",
                },
            });
        }
    };

        const dataSet = {
            "employeeInfo": (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
            "alreadyInAssignment": (props.location.state.alreadyInAssignment !== undefined) ? props.location.state.alreadyInAssignment : false,
            "moveOption": (props.location.state.moveOption !== undefined) ? props.location.state.moveOption : "NotSet",
            "moveType": (props.location.state.moveType !== undefined) ? props.location.state.moveType : "NotSet",
            "payGrade":  (props.location.state.payGrade !== undefined) ? props.location.state.payGrade : "NotSet",
        }

    const savePreInitDetails = (location) => {
        setIsLoading(true);
        disableContinueBtnHandler(true);
        dispatch(moveInitiationService.actions.setPreInitDetails(dataSet)).then(data => {
                setIsLoading(false);
                disableContinueBtnHandler(false);
                if(data.success) {
                    if (location == "initiate-move") {
                        let moveType = dataSet.moveType;
                        let initiationId = data.result;
                        let initiationCountryCode = null;
                        let relocationType = null;
                        let policySelection = null;
                        let employeeInfo = (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null;
                        props.history.push("/initiate-move",
                            { moveType,policySelection,relocationType,initiationId,initiationCountryCode,employeeInfo },
                        );
                    } else {
                        navigateTo(location);
                    }
                } else {
                    console.log(data.message);
                }
            })
            .catch(err => console.log(err));
    }

    const navigateTo = (location: string) => {
        switch (location) {
            case 'continue':
                savePreInitDetails("submittedSucess");
                break;
            case 'already-have-continue':
                savePreInitDetails("initiate-move");
                break;
            case 'submittedSucess':
                    window.location.replace("http://jjworkday.com/");
                break;
        }
    };

    return (
        <div className="req-wd-screen">
            <SpinnerWrapper show={isLoading}>
            <section className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </section>
            <h1>Ok, now it's time to create your Requisition ID.</h1>
            <div className="top-content">
                <div className="top-info">
                    <p>
                        You'll need to submit a request in Workday and get an ID in order to continue your initiation process.
                    </p>
                    <div className="buttons">
                        <button 
                            className="submit-button"
                            onClick={() => {navigateTo("continue")}}    
                        >
                            Submit and Open Workday
                        </button>
                        <a>
                            <button 
                                className="continue-button" 
                                disabled={continueButtondisabled}
                                onClick={() => {navigateTo("already-have-continue")}} 
                            >
                                Already have a Requisition ID, Continue
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            {/* <hr className="line" />
            <div className="three-steps-accordion">
                <div className="accordion-box">
                    <p className="circle-number">1</p>
                    <Accordion
                        data={[
                            {
                                content: <FirstStep />,
                                props: {
                                    uniqueKey: 1,
                                    title: "Things you need before you start",
                                },
                            },
                        ]}
                    />
                </div>
                <div className="accordion-box">
                    <p className="circle-number">2</p>
                    <Accordion
                        data={[
                            {
                                content: <SecondStep />,
                                props: {
                                    uniqueKey: 2,
                                    title: "Create a position within Workday",
                                },
                            },
                        ]}
                    />
                </div>
                <div className="accordion-box">
                    <p className="circle-number">3</p>
                    <Accordion
                        data={[
                            {
                                content: <CongratulationsText />,
                                props: {
                                    uniqueKey: 3,
                                    title: "What happens next",
                                },
                            },
                        ]}
                        openedItems={[3]}
                    />
                </div>
            </div> */}
            </SpinnerWrapper>
        </div>
    );
};

export default ReqWdScreen;
