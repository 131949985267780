import React from 'react';

import SelectField from '../elements/SelectField';
import Icon from '@material-ui/core/Icon';

import { withFormsy } from 'formsy-react';
import { ValidationContext } from '../ValidatableForm';
import { getRequiredLabel, getLabel } from '../utils';

const MenuProps = {
  icon: <Icon name="SelectArrow"/>,
  style: {
      maxHeight: '400px'
  }
};

class FormsySelect extends React.PureComponent {

  onValueChange = event => {
    this.props.setValue(event.target.value);
    this.props.onChange && this.props.onChange(event);
    // I'm saving it on the parent decorator so that to update the component of the component I used forceUpdate
    this.forceUpdate();
  };

    componentWillReceiveProps(nextProps) {
        if((this.props.options && this.props.options.length) && (!nextProps.options || !nextProps.options.length)) {
            this.props.setValue(undefined);
        }
    }

  render() {
    const { props } = this;
    const {
      options,
      disabled,
      multiple,
      withCheckbox,
    } = props;

    const errorMessages = this.props.getErrorMessages();
      const label = props._required ? getRequiredLabel(props.label) : getLabel(props.label);
      const value = this.props.getValue() || '';

    return (
        <div className="select-field">
            <ValidationContext.Consumer>
                {(showValidationMessages) => (
                    <SelectField
                        label={label}
                        options={options}
                        value={value}
                        onChange={this.onValueChange}
                        disabled={disabled}
                        multiple={multiple}
                        errorMessages={errorMessages}
                        helperText={props.helperText}
                        MenuProps={MenuProps}
                        withCheckbox={withCheckbox}
                        showValidationMessages={showValidationMessages}
                        dataKey={props.dataKey}
                    />
                )}
            </ValidationContext.Consumer>
        </div>
    );
  }
}


export default withFormsy(FormsySelect);
