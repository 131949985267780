// @flow

import React, { PureComponent } from 'react';
import { onHistoryLinkClicked } from '../../../utils';

import { Icon } from 'components/common';

import './style.scss';

type LinkProps = {
    text: string,
    className: string,
    category?:string,
    action?:string,
    label?:string,
    externalLink?:string,
    onClick: Function,
    style?: Object,
    dataKey: string | number
};

export default class Link extends PureComponent<LinkProps> {
    render() {
        return (
            <span
                data-key={this.props.dataKey}
                className={`Link flexible aCenter jStart ${this.props.className}`}
                onClick={(e) => onHistoryLinkClicked({
                    category: this.props.category ? this.props.category : 'external',
                    action: this.props.action ? this.props.action : ('click-'+this.props.text.toString()),
                    label: this.props.label ? this.props.label : (this.props.externalLink ? this.props.externalLink : ''),
                    onClick: (e) => this.props.externalLink ? window.open(this.props.externalLink) : this.props.onClick(e)
                }, e)}
                style={this.props.style}
            >
                <span>{ this.props.text }</span>
                <Icon name="chevronWhite"/>
            </span>
        );
    }
}

Link.defaultProps = {
    text: '',
    className: '',
    onClick: () => {}
};
