import React, { useState } from "react";
import { policySelectionMessage } from "./mockData";
import { Button, Link, RadioButton, Icon } from "components/common";
import { useInterval } from "hooks/useInterval";
import { useDispatch } from "react-redux";
import PrimaryReasonGlobal from "./PrimaryReasonGlobal";
//import DomesticCategory from "./DomesticCategory";
import { History } from "history";
import { moveInitiationService } from "services";
import "./styles.scss";

const PolicySelection = (props: {
    navigateTo: (to: string, from: string) => void;
    history: History;
    location: Location;
}) => {
    const dispatch = useDispatch();
    const [delay, setDelay] = useState(1000);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        "global-vs-domestic": "global",
        primaryReason: "",
        moveType: "NotSet",
        empCategoryRepresentation: "NotSet",
        empProcurementType: "NotSet",
        homeownerOrRenter: "NotSet",
        payGrade: "NotSet",
        policy: "NotSet",
        strategicIntent: "NotSet",
        halfInHomeCountrySpent: false,
        lengthOfAssignment: "NotSet",
        beginingOrEndAssignment: false,
        endOfAssignmentType: "NotSet",
        localTalentAvailable: false,
        initiationId: props.history.location.state.initiationId,
    });
    const [count, setCount] = useState(0);
    const [continueButtondisabled, setContinueButtondisabled] = useState(true);
    const [currentMessages, setCurrentMessage] = useState([]);
    const empCategoryRepresentation = {
        "ldp": "LeadershipDevelopmentProgram",
        "homeowner": "Homeowner",
        "renter": "Renter"
    }
    const lDPScenario = {
        "procurement": "InProcurement",
        "FLDP": "FLDP",
        "None": "NonProcurNonFLDP"
    }
    const payGradeScenario = {
        "24-": "PayGrade24",
        "0-3YOW": "PayGrade26",
        "3+YOE": "PayGrade26",
        "30-31": "PayGrade30",
        "40+": "PayGrade40",
        "31-": "PayGrade31",
        "25-26": "PayGrade25",
    }
    const paygrade = (props.history.location.state.paygrade in payGradeScenario) ? payGradeScenario[props.history.location.state.paygrade] : "NotSet";

    const stIntentScenario = {
        "accelerated-development": "AcceleratedDevelopment",
        "skill-need": "SkillNeeded",
        "business-critical-need": "BusinessCriticalNeeds",
        "self-initiated": "SelfInitiated"
    }

    const lengthOfAssignment = {
        "5m+": "MoreThanFiveYear",
        ">12m": "LessThanYear"
    }
    
    const dataSet = {
        "initiationId": state.initiationId,
        "empCategoryRepresentation": (props.history.location.state.empCategoryRepresentation != null && typeof props.history.location.state.empCategoryRepresentation !== 'undefined') ? props.history.location.state.empCategoryRepresentation : "NotSet",
        "empProcurementType": (props.history.location.state.empProcurementType != null && typeof props.history.location.state.empProcurementType !== 'undefined') ? props.history.location.state.empProcurementType : "NotSet",
        "homeownerOrRenter": (props.history.location.state.homeownerOrRenter != null && typeof props.history.location.state.homeownerOrRenter !== 'undefined') ? props.history.location.state.homeownerOrRenter : "NotSet",
        "payGrade": paygrade,
        "policyTier": (props.history.location.state.policyTier != null && 
            typeof props.history.location.state.policyTier !== 'undefined') ? 
            props.history.location.state.policyTier : "NotSet",
        "policy": (props.history.location.state.policy != null && typeof props.history.location.state.policy !== 'undefined') ? props.history.location.state.policy : "NotSet",
        "moveType": (props.history.location.state.policyType == "domestic") ? "Domestic" : "Global",
        "strategicIntent": (props.history.location.state.strategicIntent !== null && typeof props.history.location.state.strategicIntent !== 'undefined') ? props.history.location.state.strategicIntent : "NotSet",
        "halfInHomeCountrySpent": false,
        "lengthOfAssignment": (props.history.location.state.lengthOfAssignment != null && typeof props.history.location.state.lengthOfAssignment !== 'undefined') ? props.history.location.state.lengthOfAssignment : "NotSet",
        "beginingOrEndAssignment": false,
        "endOfAssignmentType": "",
        "localTalentAvailable": false,
        "lumpSumPolicy": (props.history.location.state.lumpSumPolicy !== undefined && props.history.location.state.lumpSumPolicy != null) ? props.history.location.state.lumpSumPolicy : "NotSet",
        "competitiveEmployeeInfo": {
            "newBaseSalary": "NotSet",
            "calMoveTier": "Tier1CaliforniaMarket",
            "familySize": "NotSet"
        }
    }
    // console.log("D-Set");
    // console.log(dataSet);
    const continueHandler = (): void => {
        // return;
        setIsLoading(true);
        setContinueButtondisabled(true);
        dispatch(moveInitiationService.actions.setPreInitPackageDetails(dataSet)).then(data => {
            setIsLoading(false);
            setContinueButtondisabled(false);
            if(data.success) {
                props.history.push({
                    state: {
                        ...props.history.location.state,
                        "policyData": dataSet,
                        initiationId: state.initiationId,
                    },
                });
                props.navigateTo("job-details", "policy-selection");
            } else {
                console.log(data.message);
            }
        })
        .catch(err => console.log(err));
    };

    useInterval(() => {
        showMessage();
        setCount(count + 1);
    }, delay);

    const showMessage = (): void => {
        if (currentMessages.length === policySelectionMessage.length) {
            setDelay(null);
            return;
        }

        setCurrentMessage([
            ...currentMessages,
            policySelectionMessage[currentMessages.length],
        ]);
    };
    
    const clickHandler = (policyType: string): void => {
        props.history.push({
            state: {
                ...props.history.location.state,
               policyType,
               moveType: policyType,
               lumpSumPolicy: "NotSet",
            },
        });
        setContinueButtondisabled(true);
        if (policyType === "global"){
            setState({
                ...state,
                "global-vs-domestic": "global",
                moveType: "Global",
            })
        } else if (policyType === "domestic") {
            setState({
                ...state,
                "global-vs-domestic": "domestic",
                moveType: "Domestic",
            })
        }
        
    };

    const generateMessages = (messages: Array<string>): Array<JSX.Element> =>
        messages.map((element: string, index: number) => (
            <div key={element} className={"message"}>
                {element}
                {index === 1 ? (
                    <Link
                        dataKey={"contact-us"}
                        text={"Contact us"}
                        action={"Contact-us"}
                        onClick={() => props.history.push("/contact-us")}
                        className={"finance-contacts"}
                    />
                ) : null}
            </div>
        ));

    const generatedMessages: Array<JSX.Element> = generateMessages(
        currentMessages,
    );

    const disableContinueBtnHandler = (continueButtondisabled: boolean): void =>
        setContinueButtondisabled(continueButtondisabled);

    return (
        <div className="policy-selection">
            <h1>Policy selection</h1>
            <div className="messages">{generatedMessages}</div>
            {delay === null && (
                <>
                    <div className="global-vs-domestic">
                        {/* <div className="title">
                            Is this a move within the United States or is this a
                            move across a global border?
                        </div> */}
                        <div className="options">
                            {/* <div className="option">
                                <RadioButton
                                    checked={
                                        state["global-vs-domestic"] === "domestic"
                                    }
                                    onChange={(): void =>
                                        clickHandler("domestic")
                                    }
                                />
                                <Icon name="moving" width={30} height={30} />

                                <div className="titles">
                                    <p className="title">
                                        Moves within the U.S.
                                    </p>
                                    <p className="sub-title">
                                        The employee is moving from one U.S.
                                        location to another U.S. location
                                    </p>
                                </div>
                            </div> */}
                            <div className="option" style={{marginTop:20}}>
                                <RadioButton
                                    checked
                                    /* ={
                                        setState["global-vs-domestic"] === "global"
                                    } */
                                    /* onChange={(): void =>
                                        clickHandler("global")
                                    } */
                                />
                                <Icon name="world" width={30} height={30} />

                                <div className="titles">
                                    <p className="title">Global Move</p>
                                    <p className="sub-title">
                                        The employee is moving from one country
                                        to another country
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {state["global-vs-domestic"] === "global" && (
                        <PrimaryReasonGlobal
                            disableContinueBtn={disableContinueBtnHandler} history={props.history.location.state}
                        />
                    )}
                    {/* {state["global-vs-domestic"] === "domestic" && (
                        <DomesticCategory
                            disableContinueBtn={disableContinueBtnHandler} history={props.history.location.state}
                        />
                    )} */}
                </>
            )}
            <div className="divider" />
            <div className="flexible jEnd">
                <Button
                    disabled={continueButtondisabled}
                    onClick={continueHandler}
                    title="Continue"
                    className="submit-button"
                    submit={true}
                />
            </div>
        </div>
    );
};

export default PolicySelection;
