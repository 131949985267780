// @flow

import React, { PureComponent } from 'react';
import scroll from 'components/common/Images/scroll.svg';
import people from 'components/common/Images/people.svg';
import people_red from 'components/common/Images/people_red.svg';
import personWorld from 'components/common/Images/personWorld.svg';
import { INFO_CARD_COLORS } from 'consts';
import { QuestionsListCard, MidSectionComponent, InfoCard } from 'components/common';

import './style.scss';

export default class About extends PureComponent {   
    render() {
        return (<div className="About">
            <section className="about-main">
                <h1 className="header-text" data-key="about_title">About Talent Mobility</h1>
                <p className="description-about">
                Whether moving talent within or between countries,  we empower our people to grow as diverse, innovative leaders, accelerating J&J’s ability to transform lives around the world.
                </p>
                <p className="description-about">
                Talent Mobility provides consultation on mobility assignment structuring, co-creates mobility strategies that align closely to business and talent needs, offers employee transition support, and ensures enterprise compliance management.
                </p>

                <div className="question-block flexible">
                    <div className="flexible vertical">
                        <QuestionsListCard
                            key="centCore"
                            icon={scroll}
                            title="Centralized COE"
                            body={(<div>
                                <h1 className="header-text" >Mobility Center of Excellence</h1>
                                <p>
                                    The Mobility Center of Excellence is a centralized, global governance within Johnson & Johnson that oversees strategy, policy, technology, vendor management, and analytics for the Talent Mobility program. 
                                </p><p>
                                    The Mobility Center of Excellence is responsible for ensuring program Duty of Care and Compliance; and for the management of U.S. Domestic Relocation programs and services.
                                </p>
                            </div>)}
                        />

                        <QuestionsListCard
                            key="strategy"
                            icon={people}
                            title="Strategic Support for the Business"
                            body={(<div>
                                <h1 className="header-text" >Advisory Services</h1>
                                <p className="the-talent-mobility-p">
                                The Talent Mobility Advisory Services team delivers the following mobility solutions to enable the global movement of our talent:
                                </p>
                                <h2 >Strategic Mobility Planning</h2>
                                <p className="strategic-mobility-p">
                                Strategic Mobility Planning (SMP) centers around optimizing the use of international mobility to drive the talent strategy of the organization. Talent Mobility Advisory Services partners with the business to set mobility goals integrated within the larger business and talent strategy, to encourage prioritized mobility investment in the right talent segments, to and from key markets, in order to maximize the value of the assignment and return on investment. The aim of SMP is to sharpen mobility choices through the use of mobility analytics and translating this into actionable insights to ensure the business has a robust talent pipeline equipped with meaningful and diverse international experiences. 
                                </p>
                                <h2 >Development Assignment Support</h2>
                                <p className="strategic-mobility-p">
                                Developmental Assignment Support (DAS) is a program with the purpose to enable the development of eligible assignees PG30-40 whose assignments are put in place to accelerate their development. The aim of this program is to create a forum of communication and alignment between Home and Host HR and Line Managers to facilitate the review of goals and progress during the assignment period. In addition, provide coaching for the employee during the assignment to drive the value of the experience.
                                </p>
                                <h2>Executive Mobility & Complex Assignments</h2>
                                <p className="strategic-mobility-p">
                                Mobility Advisory Services is a strategic partner to the Business and provides council on the following areas of Executive Mobility and complex assignments:
                                </p>
                                <strong>VP+ Employee Population</strong>
                                <p className="strategic-mobility-p">Structuring of assignment and moves including conducting due diligence on compliance and benefits</p>
                                <strong>Complex Assignment Structuring</strong>
                                <ul>
                                    <li>Assignments into new markets/countries</li>
                                    <li>Group and/or project moves</li>
                                    <li>Terminations, retirements or separation while on assignment</li>
                                    <li>Localizations D1 level and above – Assignees hired locally in host assignment country</li>
                                    <li>Assignment extension beyond 5 years</li>
                                 </ul>
                            </div>)}
                        />

                        <div className="question-last">
                        <QuestionsListCard
                            key="makeEasy"
                            icon={personWorld}
                            title="Making a Complicated Process Feel Easy"
                            body={(<div>
                                <h1 className="header-text" >Logistical Services</h1>
                                <div className="makeEasyFlexibleBlock flexible">
                                    <div className="manager-service flexible vertical">
                                        <h2>For Managers</h2>
                                        <p className="strategic-mobility-p">
                                        Johnson & Johnson Talent Mobility Services maintains contracts with best-in-class service providers and provides comprehensive logistical services to managers, including:
                                        </p>
                                        <ul>
                                            <li>Policy guidance</li>
                                            <li>Cost estimates</li>
                                            <li>Oversight for assignment approvals</li>
                                            <li>Triage questions</li>
                                            <li>Liaison with internal and external process partners</li>
                                            <li>Alignment with J&J working hours across APAC, EMEA, NALA</li>
                                        </ul>
                                    </div>
                                    <div className="employee-service flexible vertical">
                                        <h2>For Employees</h2>
                                        <p className="strategic-mobility-p">
                                        Moving  can be stressful for employees, so in addition to our support for managers, the Talent Mobility partners with industry leading providers to ensure that employees have a smooth and successful relocation experience. Services may include: 
                                        </p>
                                        <ul>
                                            <li>Dedicated relocation consultant </li>
                                            <li>Tax Services</li>
                                            <li>Immigration Services</li>
                                            <li>Cultural Training</li>
                                            <li>Preferred vendors and service providers for relocation needs</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>)}
                        />
                        </div>
                    </div>
                </div>
            </section>
                
            <MidSectionComponent className="about-midcomp" col1={
                <div className="question-card flexible aStart">
                    <div className="icon-block">
                        <img src={people_red} alt=""/>
                    </div>
                    <div className="text-block flexible vertical grow">
                        <h3>Our Mission</h3>
                        <p>We will deliver a best-in-class employee experience and agile mobility solutions to meet changing business needs, intentionally disrupting the talent mobility space, identifying trends and emerging capability needs, shaping the global workforce strategy for J&J.
                        </p>
                    </div>
                </div>
                }  
            />

            <section className="three-cards flexible">
                <InfoCard
                    backgroundColor={INFO_CARD_COLORS.first}
                    headerIsLink
                    title="Learn About Global Moves"
                    content="We’ll walk you through the steps and the roles of host and home managers during global moves."
                    underLink="View the Process"
                    onClick={()=>window.location.replace('/profile/international/explore')}
                />
                <InfoCard
                    backgroundColor={INFO_CARD_COLORS.second}
                    headerIsLink
                    title="Learn About Moves within the U.S."
                    content="U.S. Domestic relocations are coupled with the job requisition process. "
                    underLink="Explore the Details"
                    onClick={()=>window.location.replace('/profile/domestic/explore')}
                />
                <InfoCard
                    backgroundColor={INFO_CARD_COLORS.third}
                    headerIsLink
                    title="Contact Mobility Connect "
                    content="Reach out any time you have questions or need guidance on the process or details. "
                    underLink="Contact Us"
                    onClick={() =>window.location.replace('/contact-us')}
                />
            </section>
        </div>
        );
    }
}
