// @flow

import { Api } from 'backend';

const ExpenseReportLineItemAPI = {
    setExpenseReportsLineItem: (reportID: Object, relocatoinID): Promise<any> => (
        Api.get({ path: `/expensereport/${relocatoinID}/${reportID}`})
    )
};

export default ExpenseReportLineItemAPI;
