import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import { INFO_CARD_COLORS } from "consts";

// import { InfoCard, QuestionsListCard } from "components/common";

import MainCarousel from "./MainCarousel";

// import personWorldImage from "components/common/Images/personWorld.svg";
// import partnershipImage from "components/common/Images/partnership.svg";

import { getUserProfile } from "selectors";

import "./../style.scss";
// import { Typography } from "@material-ui/core";

const mapStateToProps = state => ({
    userProfile: getUserProfile(state),
});

class HrMainProfile extends PureComponent {
    navigateTo = (location: string) => {
        this.props.history.push(location);
    };

    render() {
        const userProfileShortName = this.props.userProfile.shortName;

        return (
            <div
                /* style={{ paddingBottom: 60 }} */ className="Profil flexible jCenter vertical "
            >
                <MainCarousel
                    userName={userProfileShortName}
                    history={this.props.history}
                />
                {/* <div className="hr-main-content">
                    <div className="question-part">
                        <QuestionsListCard
                            key="hr_main_q1"
                            icon={personWorldImage}
                            title=""
                            body={
                                <div>
                                    <div className="question-part">
                                        <h4>
                                            We prepare you with the right tools
                                        </h4>
                                        <h2>
                                            Moving an employee is a big decision
                                            but we have the tools and
                                            information you need.
                                        </h2>
                                    </div>
                                    <Typography variant="body1">
                                        Whether you are relocating an employee
                                        from one U.S. location to another or
                                        moving someone across country borders,
                                        it’s common for managers to feel
                                        overwhelmed. We're here to make sure you
                                        have the information you need before you
                                        begin. We've made it easy to get
                                        detailed information based on the type
                                        of move and the phase of the journey, so
                                        you know exactly what to do and when.
                                    </Typography>
                                </div>
                            }
                        />
                    </div>
                </div> */}
                {/* <Benefits className="Profil__benefits question-card" />
                <QuotesImage
                    imageSrc={laboratoryPersonImage}
                    title="The employee increased the level of quality in her area of responsibility dramatically, and collaboration cross site improved as well. The feedback from business partners across all departments has been very positive."
                    description="J&J Manager"
                /> */}
                {/* <div className="give-the-gift">
                    <QuestionsListCard
                        icon={partnershipImage}
                        body={
                            <div className="questions-list-card">
                                <div className="question-part">
                                    <h2>Give the gift of career growth</h2>
                                    <p className="questions-list-card__text">
                                        Johnson & Johnson is a global company
                                        and, as such, our employees need to
                                        understand the complex dynamics of doing
                                        business on a global scale.
                                    </p>
                                    <p>
                                        A change in geography offers employees a
                                        tremendous opportunity to better
                                        understand the issues and challenges
                                        faced when conducting business in other
                                        areas of the world, to manage or work
                                        with a new set of stakeholders, and to
                                        increase their cultural awareness. As
                                        business leaders, we are responsible for
                                        developing the future talent to lead our
                                        global businesses.
                                    </p>
                                </div>
                            </div>
                        }
                        urlText={["Learn About Talent Mobility Services"]}
                        urlActions={[
                            () => {
                                this.navigateTo("/about");
                            },
                        ]}
                    />
                </div>
                <section className="three-cards jAround flexible">
                    <InfoCard
                        backgroundColor={INFO_CARD_COLORS.first}
                        headerIsLink
                        title="Global Experiences"
                        content="Learn more about the process and  manager responsibilities when moving someone internationally."
                        underLink="Learn More"
                        onClick={() => {
                            this.navigateTo("/profile/international/explore");
                        }}
                    />
                    {!this.props.userProfile.currentRelocationIsDomestic && (
                        <InfoCard
                            backgroundColor={INFO_CARD_COLORS.second}
                            headerIsLink
                            title="U.S. Domestic Relocations"
                            content="Learn more about the process and manager responsibilities when moving someone within the United States."
                            underLink="Learn More"
                            onClick={() => {
                                this.navigateTo("/profile/domestic/explore");
                            }}
                        />
                    )}
                    <InfoCard
                        backgroundColor={INFO_CARD_COLORS.third}
                        headerIsLink
                        title="Questions?
                        Get Help"
                        content="We’re here to support and guide managers throughout the relocation and move process."
                        underLink="Contact Mobility Connect"
                        onClick={() => {
                            this.navigateTo("/contact-us");
                        }}
                    />
                </section> */}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(HrMainProfile));
