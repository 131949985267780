import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const DialogTitle = withStyles(() => ({
    root: {
        margin: 0,
        padding: 24,
    },
    title: {
        color: 'red',
    }
}))(props => {
    const {children, classes} = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h5" className={classes.title}>{children}</Typography>
        </MuiDialogTitle>
    )
});

const NonJnJEmppErrorModal = (props) => {

    return (
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"md"}
            >
                <DialogTitle id="alert-dialog-title">We can’t seem to find this employee.</DialogTitle>
                <DialogContent>
                <p className="modalDesctextForPreInitMoveNonJnJEmpp">
                    Please contact <a href="mailto:TalentMobil@its.jnj.com?subject=[Pre-Initiation] Talent Mobility Support">talentmobil@its.jnj.com</a> for further support. <br/>
                    As a reminder, this process is intended for internal employees only, <br/>
                    thank you!
                </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
}

export default (NonJnJEmppErrorModal);
