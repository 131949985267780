import React from 'react';
import Formsy, { addValidationRule }  from 'formsy-react';

import Button from '@material-ui/core/Button';
import { omit } from 'utils';

import './ValidatableForm.scss';


import ValidationRules from './ValidationRules';

for(const key in ValidationRules) {
    addValidationRule(key, ValidationRules[key]);
}

export const ValidationContext = React.createContext(false);

export default class ValidatableForm extends React.PureComponent {
    state = {
        canSubmit: false,
        showValidationMessages: false
    };

    disableButton = _ => this.checkFormValidation(false);

    enableButton = _ => this.checkFormValidation(true);

    checkFormValidation(canSubmit) {
        this.setState({ canSubmit });
        this.props.checkFormValidation && this.props.checkFormValidation(canSubmit);
        this.forceUpdate();
    };

    handleSubmit = (e) => {
        this.setState({
            showValidationMessages: true
        }, () => {
            (this.state.canSubmit && this.props.onSubmit) && this.props.onSubmit(e);
        })
    };
    render() {
        const { props } = this;
        const {
            children,
            additionalFooterContent,
        } = props;

        return (
            <ValidationContext.Provider value={this.state.showValidationMessages}>
                <Formsy
                    className="ValidatableForm"
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    onSubmit={this.handleSubmit}
                    {...omit(props, [
                        'onSubmit', 'onValid', 'onInvalid', 'children', 'checkFormValidation',
                        'additionalButtonProps', 'additionalFooterContent',
                    ])}
                >
                    <div className="ValidatableForm_content">
                        { children }
                    </div>

                    <div className="ValidatableForm_footer">
                        {
                            additionalFooterContent
                                ?  additionalFooterContent :
                                <div>
                                    <Button
                                        type="reset"
                                        color="primary"
                                        variant="raised"
                                        children="Reset"
                                        onClick={this.props.onReset}
                                    />

                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="raised"
                                        disabled={!this.state.canSubmit}
                                        children="Submit"
                                    />
                                </div>
                        }

                    </div>
                </Formsy>
            </ValidationContext.Provider>
        );
    }
}
