import React, {useState} from "react";
import { Icon, Asset } from "components/common";
import { RouterProps } from "types";
import { useDispatch } from "react-redux";
import { moveInitiationService } from "services";
import "./styles.scss";

const EligibleMoveBottom = (props: RouterProps & { assetId: string, url: string, policyName: string}) => {
    const dispatch = useDispatch();
    const { moveType, policySelection } = props.location.state;
    const [continueButtondisabled, setContinueButtondisabled] = useState(false);
    const dataSet = {
        "initiationId": (props.location.state.initiationId !== undefined) ? props.location.state.initiationId : '',
        "empCategoryRepresentation": "NotSet",
        "empProcurementType": "NotSet",
        "homeownerOrRenter": "NotSet",
        "payGrade": (props.location.state.payGradeValue !== undefined) ? props.location.state.payGradeValue : "NotSet",
        "policy": (props.policyName !== undefined) ? props.policyName : "NotSet",
        "strategicIntent": (props.location.state.strategicIntent !== undefined) ? props.location.state.strategicIntent : "NotSet",
        "halfInHomeCountrySpent": false,
        "lengthOfAssignment": (props.location.state.lengthOfAssignment !== undefined) ? props.location.state.lengthOfAssignment : "NotSet",
        "beginingOrEndAssignment": false,
        "endOfAssignmentType": "NotSet",
        "localTalentAvailable": false
    }
    //console.log(dataSet);
    const saveNonCompetitivePackageDetails = (location) => {
        //this.setState({isLoading: true});
        setContinueButtondisabled(true);
        dispatch(moveInitiationService.actions.setNonCompetitivePackageDetails(dataSet)).then(data => {
                //setIsLoading(false);
                setContinueButtondisabled(false);
                if(data.success) {
                    navigateTo(location);
                } else {
                    console.log(data.message);
                }
            })
            .catch(err => console.log(err));
    }

    const navigateTo = (location: string) => {
        switch (location) {
            case 'continueInitiation':
                saveNonCompetitivePackageDetails("yesandcontinue");
                break;
            case 'startover':
                    props.history.push("/initiate-move", {
                            ...props.location,
                            "moveType": moveType,
                            "policySelection": policySelection,
                            "relocationType": (props.location.state.relocationType != null && typeof props.location.state.relocationType !== 'undefined') ? props.location.state.relocationType : null,
                            "eligibleTitle": (props.eligibleTitle != null && typeof props.eligibleTitle !== 'undefined') ? props.eligibleTitle : null,
                            "strategicIndent": (props.strategicIndent != null && typeof props.strategicIndent !== 'undefined') ? props.strategicIndent : null,
                            "initiationId": (props.location.state.initiationId !== undefined && typeof props.location.state.initiationId !== 'undefined') ? props.location.state.initiationId : null,
                            "initiationCountryCode": (props.location.state.initiationCountryCode !== undefined && typeof props.location.state.initiationCountryCode !== 'undefined') ? props.location.state.initiationCountryCode : null,
                            employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
                    });
                    break;
            case 'yesandcontinue':
                    props.history.push(props.url, {
                        ...props.location,
                        "moveType": moveType,
                        "policySelection": policySelection,
                        "relocationType": (props.location.state.relocationType != null && typeof props.location.state.relocationType !== 'undefined') ? props.location.state.relocationType : null,
                        "eligibleTitle": (props.eligibleTitle != null && typeof props.eligibleTitle !== 'undefined') ? props.eligibleTitle : null,
                        "strategicIndent": (props.strategicIndent != null && typeof props.strategicIndent !== 'undefined') ? props.strategicIndent : null,
                        "initiationId": (props.location.state.initiationId !== undefined && typeof props.location.state.initiationId !== 'undefined') ? props.location.state.initiationId : null,
                        "initiationCountryCode": (props.location.state.initiationCountryCode !== undefined && typeof props.location.state.initiationCountryCode !== 'undefined') ? props.location.state.initiationCountryCode : null,
                        employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
                    });
                    break;
        }
    };   

    return (
        <section className="download-policy-section">
            {/* <p className="title">What's included?</p>
            <div
                className="horizontal-section download"
                onClick={() => {
                    new Asset({
                        assetType: "global-policy",
                        assetId: props.assetId,
                    }).execute();
                }}
            >
                <Icon name="download" />
                <p>Download Policy for Details</p>
            </div>
            <div className="line" />
            <br /> */}
            <section className="buttons">
                <button
                    className="white-button"
                    onClick={() => {navigateTo("startover")}}
                >
                    <span className="buttonLabel">Start Over</span>
                </button>
                <button 
                    className="red-button"
                    disabled={continueButtondisabled}
                    onClick={() => {navigateTo("continueInitiation")}}
                    >
                    <span className="buttonLabel">Continue Initiation</span>
                </button>
            </section>
        </section>
    );
};

export default EligibleMoveBottom;
