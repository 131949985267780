import React, { Component } from 'react'
import { MidSectionComponent, Link } from 'components/common';

export default class DomPrepPlanStep extends Component {

  render() {
    return (
      <div>
        <MidSectionComponent title = "Have you completed all of your relocation tasks?" className="DomPrepPlanStep" col1={
            <div className="flexible vertical">
            <p>For the efficient initiation and scheduling of relocation services, it is critical that you complete all the required relocation tasks identified by SIRVA, the relocation management company facilitating your move.   Delays in providing the information needed by SIRVA may result in unnecessary complications in the delivery of your relocation services.</p>

            <Link text="Complete Relocation Tasks " onClick={() => window.open('/tasks')}/>
            </div>
            }  
        />
      </div>
    )
  }
}
