// @flow

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from 'components/common';
import { EXTERNAL_LINKS } from 'consts';

type SirvaConnectPartProps = {
    externalLinkAction: Function,
    connectButtonLabel: string,
};
type SirvaConnectPartState = {
    isChecked: boolean
};

export default class SirvaConnectPart extends React.PureComponent<SirvaConnectPartProps, SirvaConnectPartState> {
    state: SirvaConnectPartState = {
        isChecked: false
    };

    toggleCheckbox = (): void => {
        this.setState((prevState: SirvaConnectPartState) => ({
            isChecked: !prevState.isChecked
        }))
    };

    render() {
        return (
            <div className="sirva-connect-part flexible aCenter">
                <div className="sirva-connect-desc">
                    <div className="sirva-connect-with-border">
                        <p className="sirva-connect-text">J&J partners with SIRVA, an industry leading relocation firm. Your request will be handled by SIRVA through their online tool, SIRVA Connect. </p>
                        <div className="sirva-connect-checkbox flexible mbottom-20 mtop-20 aCenter" onClick={this.toggleCheckbox} >
                            <Checkbox checked={this.state.isChecked}/>
                            <p>Do you have a Host Position ID or Job Requisition ID?</p>
                        </div>
                        <p
                            className="sirva-connect-external-link"
                            onClick={this.props.externalLinkAction}
                        >
                            I don’t have a Host Position ID or Job Requisition ID
                        </p>
                        <Button
                            className="sirva-connect-button"
                            title="Continue to SIRVA CONNECT"
                            action='click-ready-to-move'
                            category='profile'
                            label={this.props.connectButtonLabel}
                            externalLink={EXTERNAL_LINKS.SirvaConnect}
                            disabled={!this.state.isChecked}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
