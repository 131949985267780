import React, { useState, useEffect } from "react";
import { RadioButton, Asset, Icon } from "components/common";
import { History } from "history";
import Checkbox from '@material-ui/core/Checkbox';
import GetPolicy from "../../Policy/index2";
import { policyEnum } from "../../../policyMapping";
import { PayGradeDomesticRenterOpts } from "../../keyMappings";

const Renter = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    const [state, setState] = useState<{
        [key: string]: string;
    }>({
        "pay-grade": "",
        "new-hire": "",
        homeownerOrRenter: "NotSet",
        lumpSumPolicy: "No"
    });
    const [isChecked, setIsChecked] = useState(false);
    const clickHandler = (payGrade: string, policy: string): void => {
        props.history.push({
            state: {
                ...props.history.location.state,
                payGrade,
                policy,
                eligibleTitle: policy ? policyEnum[policy].displayText : "NotSet",
                lumpSumPolicy: "No",
            },
        });
    };
    const clickHandler2 = (payGrade: string, newhire: string, policy: string): void => {
        props.history.push({
            state: {
                ...props.history.location.state,
                finalMoveTypeKey: newhire,
                finalMoveTypeTitle: newhire === "yes" ? "Yes" : "No",
                payGrade,
                policy,
                policyTier: policy,
                eligibleTitle: policy ? policyEnum[policy].displayText : "NotSet",
                lumpSumPolicy: "No",
            },
        });
    };
    const checkedHandler = () => {
        if(isChecked) {
            setIsChecked(false);
            props.history.push({
                state: {
                    ...props.history.location.state,
                    lumpSumPolicy: "No"
                },
            });
        } else {
            setIsChecked(true);
            props.history.push({
                state: {
                    ...props.history.location.state,
                    lumpSumPolicy: "Yes",
                    policy: policyEnum.LumpSum.key
                },
            });
        }
        
    }

    useEffect(() => {
        setIsChecked(false);
        if (state["pay-grade"]) {
            props.disableContinueBtn(false);
        } else {
            props.disableContinueBtn(true);
        }
        if(state["pay-grade"] === "25-26" && state["new-hire"] === "")
            props.disableContinueBtn(true);
    }, [state["pay-grade"], state["new-hire"]]);

    return (
        <>
          <div className="pay-grade">
            <div className="title">
                What is the pay grade of the candidate?
            </div>
            <div className="options vertical-options">
                <div className="option">
                    <RadioButton
                        checked={state["pay-grade"] === PayGradeDomesticRenterOpts["24-"].payGrade}
                        onChange={e => { 
                            setState({
                                ...state,
                                "pay-grade": PayGradeDomesticRenterOpts["24-"].payGrade,
                            })
                            clickHandler(PayGradeDomesticRenterOpts["24-"].payGrade,"USDomesticRenterTier3")
                        }}
                    />
                    <p className="title">24 and below</p>
                </div>
                <div className="option">
                    <RadioButton
                        checked={state["pay-grade"] === PayGradeDomesticRenterOpts["25-26"].payGrade}
                        onChange={(): void => 
                            setState({
                                ...state,
                                "pay-grade": PayGradeDomesticRenterOpts["25-26"].payGrade,
                                "new-hire": ""
                            })
                        }
                    />
                    <p className="title">25 - 26</p>
                </div>
                <div className="option">
                    <RadioButton
                        checked={state["pay-grade"] === PayGradeDomesticRenterOpts["30-31"].payGrade}
                        onChange={e => { 
                            setState({
                                ...state,
                                "pay-grade": PayGradeDomesticRenterOpts["30-31"].payGrade,
                            })
                            clickHandler(PayGradeDomesticRenterOpts["30-31"].payGrade,"USDomesticRenterTier2")
                        }}
                    />
                    <p className="title">30 - 31</p>
                </div>
                <div className="option">
                    <RadioButton
                        checked={state["pay-grade"] === "40+"}
                        onChange={e => { 
                            setState({
                                ...state,
                                "pay-grade": "40+",
                            })
                            clickHandler("40+","USDomesticRenterTier1")
                        }}
                    />
                    <p className="title">40 and above</p>
                </div>
            </div>
            {state["pay-grade"] ===  "25-26" && ( 
                <div className="new-hire">
                    <div className="title">
                        New Hire?
                    </div>
                    <div className="options vertical-options">
                        <div className="option">
                            <RadioButton
                                checked={state["new-hire"] === "yes"}
                                onChange={e => { 
                                    setState({
                                        ...state,
                                        "pay-grade": "25-26",
                                        "new-hire": "yes",
                                    })
                                    clickHandler2("25-26","yes","USDomesticRenterTier3")
                                }}
                            />
                            <p className="title">Yes</p>
                        </div>
                        <div className="option">
                            <RadioButton
                                checked={state["new-hire"] === "no"}
                                onChange={e => { 
                                    setState({
                                        ...state,
                                        "pay-grade": "25-26",
                                        "new-hire": "no",
                                    })
                                    clickHandler2("25-26","no","USDomesticRenterTier3")
                                }}
                            />
                            <p className="title">No</p>
                        </div>
                    </div>
                </div>
            )}
            {state["pay-grade"] && (
                <div className="download-policy-section">
                    {/* <p className="title">What's included?</p> */}
                    {state["pay-grade"] === "24-" && (
                        <GetPolicy title={policyEnum.USDomesticRenterTier3.displayText}
                            policyName={policyEnum.USDomesticRenterTier3.key}
                            disableContinueBtn={props.disableContinueBtn}
                            history={props.history}
                            />
                        // <div>
                        //     <p>Download Policy for Renter Package A 
                        //     / {policyEnum.USDomesticRenterTier3.displayText}
                        //     </p>
                        // </div>
                    )}
                    {state["pay-grade"] === "25-26" &&  state["new-hire"] === "yes" &&(
                        <GetPolicy title={policyEnum.USDomesticRenterTier3.displayText}
                            policyName={policyEnum.USDomesticRenterTier3.key}
                            disableContinueBtn={props.disableContinueBtn}
                            history={props.history}
                            />
                        // <div>
                        //     <p>Download Policy for Renter Package B - New Hire 
                        //     / {policyEnum.USDomesticRenterTier3.displayText}
                        //     </p>
                        // </div>
                    )}
                    {state["pay-grade"] === "25-26" &&  state["new-hire"] === "no" &&(
                        <GetPolicy title={policyEnum.USDomesticRenterTier3.displayText}
                            policyName={policyEnum.USDomesticRenterTier3.key}
                            disableContinueBtn={props.disableContinueBtn}
                            history={props.history}
                            />
                        // <div>
                        //     <p>Download Policy for Renter Package B 
                        //     / {policyEnum.USDomesticRenterTier3.displayText}
                        //     </p>
                        // </div>
                    )}
                    {state["pay-grade"] === "30-31" && (
                        <GetPolicy title={policyEnum.USDomesticRenterTier2.displayText}
                            policyName={policyEnum.USDomesticRenterTier2.key}
                            disableContinueBtn={props.disableContinueBtn}
                            history={props.history}
                            />
                        // <div>
                        //     <p>Download Policy for Renter Package C 
                        //         / {policyEnum.USDomesticRenterTier2.displayText}
                        //     </p>
                        // </div>
                    )}   
                    {state["pay-grade"] === "40+" && (
                        <GetPolicy title={policyEnum.USDomesticRenterTier1.displayText}
                            policyName={policyEnum.USDomesticRenterTier1.key}
                            disableContinueBtn={props.disableContinueBtn}
                            history={props.history}
                            />
                        // <div>
                        //     <p>Download Policy for Renter Package D 
                        //         / {policyEnum.USDomesticRenterTier1.displayText}
                        //     </p>
                        // </div>
                    )}
                    {state["pay-grade"] === "25-26" &&  state["new-hire"] !== "" &&(<>
                        <p className="lum-sum-policy-header-title" >{policyEnum.LumpSum.title}</p>
                        <p className="lum-sum-policy-checkbox-area" ><Checkbox checked={isChecked} onChange={() => {checkedHandler()}} /> &nbsp;Yes</p>
                        <br />
                    </>)}
                    {state["pay-grade"] !== "25-26" && (<>
                        <p className="lum-sum-policy-header-title" >{policyEnum.LumpSum.title}</p>
                        <p className="lum-sum-policy-checkbox-area" ><Checkbox checked={isChecked} onChange={() => {checkedHandler()}} /> &nbsp;Yes</p>
                        <br />
                    </>)}
                </div>
            )}
          </div>
        </>
    );
};

export default Renter;
