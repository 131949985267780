import React, { useEffect, useState } from "react";
import { History } from "history";
import { Icon, Button, SpinnerWrapper } from "components/common";
import { connect, useDispatch } from "react-redux";
import { moveInitiationService } from "services";
// import { RouterProps } from "types";
import { getEmpJobDetails } from "selectors";
import EmpJobReview from "./EmployeeJobReview";
import { Input } from "components/common";
import "./style.scss";

const isObj = (obj: object) => obj !== undefined && Object.values(obj).length > 0;

const mapStateToProps = state => ({
empJobDetails: getEmpJobDetails(state),
});

const  JobDetails = (props: {
    navigateTo: (to: string, from: string) => void;
    history: History;
    location: Location;
}) => {
               
    const { state: locationState } = props.history.location;

    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [continueButtondisabled, setContinueButtondisabled] = useState(true);
    const [employeeWWID, setEmployeeWWID] = React.useState(null);
    const [jobID, setJobID] = React.useState(isObj(locationState) ? 
        locationState.jobID !== undefined ? locationState.jobID : "" : "");
    const [validationMessageEmpID, setValidationMessageEmpID] = React.useState("");
    const [validationMessageReqID, setValidationMessageReqID] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [reviewScrn, setReviewScrn] = useState(false);
    const [isReqIdCorrect, setIsReqIdCorrect] = useState(false);

    const validWWIDRegEx = /^\d*$/
    const validReqRegEx = /^[0-9a-z]+$/i
    const invalidReqIdMsg = "Please enter a valid job requisition ID.";

    const changeHandler = () => {
        setIsReqIdCorrect(validReqRegEx.test(jobID));
        if (validReqRegEx.test(jobID)) {
          setIsLoading(true);
          setMessages("");
          setIsError(false);
          setContinueButtondisabled(true);
          dispatch(moveInitiationService.actions.fetchEmpJobDetails(null, jobID))
          .then(names => {
              setIsError(false);
              setIsLoading(false);
              setValidationMessageEmpID("");
              if(names.message == "Success") dispatchResolve(names.result);
              else dispatchError(names.message);
          })
          .catch(err => console.log(err));
        } else {
          var reqIdStatus = validReqRegEx.test(jobID);
          if (!reqIdStatus) {
            setMessages(invalidReqIdMsg);
          }
          setIsError(true);
        }
      };
  
      const dispatchError = (msg) => {
        var msg = msg.split(" | ")
        let wwwIdErrorMsg = "";
        let requisitionIdErrorMsg = "";
        if(Array.isArray(msg) != null) {
          if(msg[0] !== null) {
            if(msg[0].includes('WWID')) {
              wwwIdErrorMsg = msg[0];
              requisitionIdErrorMsg = msg[1];
            } else {
                requisitionIdErrorMsg = msg[0];
            }
          }
        }
        Array.isArray(msg) != null ? 
        setMessages(requisitionIdErrorMsg)
          : setValidationMessageEmpID(msg);
          setIsError(true)
        setIsLoading(false);
        setContinueButtondisabled(false);
      }
  
      const dispatchResolve = (result) => {
        setMessages("")
        setIsError(false)
        setIsLoading(false);
        setContinueButtondisabled(false);
        let dataset = {
          ...locationState,
          employeeWWID,
          jobID,
          "posID": null,
          "eligibleTitle": (locationState.eligibleTitle != null && typeof locationState.eligibleTitle !== 'undefined') ? locationState.eligibleTitle : null,
          "employee": (locationState.employee != null && typeof locationState.employee !== 'undefined') ? locationState.employee : null,
          "category": (locationState.category != null && typeof locationState.category !== 'undefined') ? locationState.category : null,
          "category2": (locationState.category2 != null && typeof locationState.category2 !== 'undefined') ? locationState.category2 : null,
          "relocationType": (locationState.relocationType !== undefined) ? locationState.relocationType : null,
          "moveType": (locationState.moveType !== undefined) ? locationState.moveType : null,
          "policySelection": (locationState.policySelection !== undefined) ? locationState.policySelection : null,
          "strategicIndent": (locationState.strategicIndent  != null && typeof locationState.strategicIndent  !== 'undefined') ? locationState.strategicIndent : null,
          "initiationId": (locationState.initiationId  != null && typeof locationState.initiationId  !== 'undefined') ? locationState.initiationId : null,
          "initiationCountryCode": (locationState.initiationCountryCode  != null && typeof locationState.initiationCountryCode  !== 'undefined') ? locationState.initiationCountryCode : null,
          "jobDetailsBackState": "employee-job-details-review",
          employeeInfo: (locationState.employeeInfo !== undefined && locationState.employeeInfo !== null ) ? locationState.employeeInfo : null,
        }
          // console.log("Dataset: ", dataset)
          props.history.push({
              state: {
                  ...dataset
              }
          })
          setReviewScrn(true)
      }

      useEffect(() => { //  Idle handler
        if(!isObj(locationState)) {
          props.history.replace("/move-dashboard");
      }
      },[locationState])
  
      const setMessages = (reqId) => {
        setValidationMessageReqID(reqId)
      }
  
      const validateIDInputs = (input, type) => {      
       if (type == "ReqID") {
          setIsReqIdCorrect(input.match(validReqRegEx))
        }
      }
  
      const disableContinueBtnHandler = (continueButtondisabled: boolean): void =>
      setContinueButtondisabled(continueButtondisabled);
  
      
  
      const handleInputChange = (propName) => ({ type: string, target, ...eventRest }, ...argsRest) => {
          let value = target ? target.value : Object.values(eventRest).join("");
        //   if (propName === "toggleJ-job-requisition-section") {
        //     open ? setOpen(false): setOpen(true)
        //   }
          if (propName === "jobID") {
            setJobID(value)
          }
          if (propName === "cancel") {
            const { moveType, policySelection } = locationState;
            setJobID("")
            let backLocation = "/initiate-move";
            props.history.push(backLocation,
              {
                ...locationState,
                moveType: moveType,
                policySelection: policySelection
              }
            );
          }
          if (propName === "continue") {
            changeHandler();
          }
      };
      useEffect(() => {
          if(locationState === undefined) {
            props.history.push("/move-dashboard", {
                    "moveType": "",
                    "policySelection": ""
            });
          } else {
            if (jobID !== "") {
              disableContinueBtnHandler(false);
            } else {
              disableContinueBtnHandler(true);
            } 
          }
      },[jobID]);

    const renderMiddleContent = () => {
        // if (!this.state.showContent) {
        //     return null;
        // }
        
        return ( 
            <div className="emp-job-details-wrapper">     
            <section className="input-section">
                <SpinnerWrapper show={isLoading}>
                <div className="inputs">
                  <div className={`Input ${validationMessageReqID ? 'on-error' : ''}`}>
                    <input
                        type="text"
                        required
                        value={jobID}
                        onChange={handleInputChange("jobID")}
                        className={validationMessageReqID ? "text-input-error" : ""}
                    />
                    <span className={`placeholders ${(validationMessageReqID && isError) ? 'has-reqid-error' : ''}`}>Job Requisition ID<span className="required-symbol"> *</span></span>
                    {validationMessageReqID ? <span className="reqid-error-msg">{validationMessageReqID}</span> : null}
                    
                  </div>
                  <div style={{ marginTop: 5 }} />

                  <p className={`${validationMessageReqID ? 'shift-on-error' : ''}`}>Your Requisition ID will be 10 numbers followed by a “W”</p>
                </div>
                </SpinnerWrapper>
                {/* <div className={"divider"} /> */}
            </section>    
            <hr className="line" />
            <section className="button-section">
                <div className={"flexible"} style={{ justifyContent: 'space-between' }}>
                  <Button
                      title={validationMessageReqID && isError? 
                        "Cancel" : "Go Back"}
                      className="job-details-submit"
                      style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", minWidth: 150 }}
                      labelStyle={{color:"#CA001B"}}
                      onClick={() => {
                        if(validationMessageReqID && isError) {
                          setValidationMessageReqID("")
                          setIsError(false);
                        } else {
                          if(!reviewScrn) {
                            props.navigateTo("package-selection", "emp-job-details")
                          } else {
                            setReviewScrn(false);
                          }
                        }
                      }}
                    />
                    <Button
                        onClick={handleInputChange("continue")}
                        style={{ minWidth: 150 }}
                        title="Continue"
                        className="job-details-submit"
                        disabled={continueButtondisabled}
                        submit={true}
                    />
                </div>
            </section>
            </div>
        );
    }

    return (
            <div className={"employee-job-details-container-nc"}>
                {!reviewScrn ? <>
                <section className="header-section">
                    {((validationMessageReqID) && isError) == false ? <h1 className="section-title">
                        Next, let's <span style={{color: "#CC0099"}}>import</span> your job information.
                    </h1> : <h1 className="section-title">
                    <span className="error-heading">Oh no!</span><br /><span className="error-subheading">There seems to be a problem. Please try again.</span>
                    </h1>}
                </section>
                {renderMiddleContent()}</>
                : <EmpJobReview 
                    setReviewScrn={setReviewScrn}
                    history={props.history}
                    navigateTo={props.navigateTo}
                    location={props.location}
                />}
            </div>
        );
    }
// }

export default connect(mapStateToProps)(JobDetails);
