// @flow

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tabs } from "components/sections";

import { withRouter } from "react-router-dom";

import { userService } from "services/";
import { getUserProfile } from "selectors";
// import { USER_ROLES } from "consts";

import "./style.scss";

const mapStateToProps = store => ({
    user: getUserProfile(store),
});

class Footer extends PureComponent<*> {
    static propTypes = {
        className: PropTypes.string,
    };

    handleTabChange = tabInfo => {
        window.open(tabInfo.key, "_blank");
    };

    handleUserRoleChange = () => {
        this.props.history.push("/identification/reset-role");
    };

    getTabs = () => {
        var tabs = [
            {
                label: "Legal Notice",
                key: "https://www.jnj.com/corporate/legal-notice",
            },
            {
                label: "Privacy Policy",
                key: "/privacypolicy",
            },
            {
                label: "US States Privacy",
                key: "https://jnjgs.force.com/s/article-display?language=en_US&name=Privacy-Rights",
            },
            {
                label: "Cookie Policy",
                key: "/cookiepolicy",
            },
        ];

        if (!this.props.user.isJibeUnassignedUser)
            tabs.push({
                label: "Contact Us",
                key: "/contact-us",
            });
        return tabs;
    };

    render() {
        const {
            className,
            location: { pathname },
        } = this.props;
        return (
            <footer className={`Footer ${className}`}>
                <section className="flexible horizontal aCenter jBetween">
                    <aside className="singleLine truncate">
                        <span className="singleLine truncate">
                            © 2020 Johnson & Johnson Services, Inc.
                        </span>
                    </aside>
                    {!pathname.includes("page-unavailable") ? (
                        <div className="flexible horizontal">
                            <Tabs
                                data={this.getTabs()}
                                onTabChange={this.handleTabChange}
                                activeClassName="activeLink"
                                className="withBorder"
                            />
                            {/* {user && user.role && !user.isJibeUnassignedUser ? (
                                <div className='assignee flexible horizontal jCenter aCenter shrink'>
                                    I am a(n):
                                    {user.isJibeUser
                                        ? ('Employee')
                                        : (<div className="currentRole" onClick={this.handleUserRoleChange}>
                                                {user.role === USER_ROLES.hiring_manager ? 'People Leader' : 'Employee'}
                                            </div>
                                        )}
                                </div>) : null} */}
                        </div>
                    ) : null}
                </section>
            </footer>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        {
            setRole: userService.actions.setRole,
        },
    )(Footer),
);
