import React, { useEffect, useState } from "react";
import { Asset, Icon, RadioButton } from "components/common";
import { History } from "history";
import { PayGradeOpts } from "../../keyMappings";
// import { Pay50AboveKnockout } from "../knockoutScreens";
// import GlobalOrDomestic from "../GlobalOrDomestic";
import { LenOfAssignmentOpts } from "../../keyMappings";
// import PolicyDisplay from "../../Policy/PolicyDisplay";
// import shortTerm from "../../../../InitiateMove/PackageSelection/EligibleMove/shortTerm";
import { policyEnum } from "../../../policyMapping";
import GetPolicy from "../../Policy/index2";

const PayGradeSelect = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
  const { state: locationState } = props.history.location;
  const [moveOption, setMoveOption] = useState("");
    
    const clickHandler = (moveOptionKey: string, value: string): void => {
        props.history.push({
            state: {
                ...props.history.location.state,
                payGrade: moveOptionKey,
                payGradeValue: value
            },
        });
        setMoveOption(moveOptionKey);
    };

    useEffect(() => {
        setMoveOption("");
        props.disableContinueBtn(true);
    },[locationState["strategic-indent"]]);
    
    useEffect(() => {
        if(locationState["payGrade"] === "")
            props.disableContinueBtn(true);
    },[locationState["payGrade"], locationState["lengthOfAssignment"]]);

    useEffect(() => {
        if(moveOption !== "") {
            props.disableContinueBtn(false);
        }
    },[moveOption])

  const handlePaygrade = () => {
      switch(moveOption) {
        case "40+": 
            // moveOption === "" && setMoveOption(locationState["payGrade"])
            if(locationState["strategic-indent"] === "Accelerated Development")
              return (<>
              {/* <p className="title">What's included?</p> */}
              <GetPolicy policyName={policyEnum.LongTermTier1.key} 
                title={policyEnum.LongTermTier1.displayText} history={props.history} disableContinueBtn={props.disableContinueBtn} movePolicyType={"global"}/>
                {/* <div className="horizontal-section download" >
                            <p>Download Policy for Long Term Tier 1</p>
                        </div> */}
            </>)
            if(locationState["strategic-indent"] === "Skill Need" || locationState["strategic-indent"] === "Project"){
              if(locationState["lengthOfAssignment"] === "LessThanYear")
                  return (<>
                  {/* <p className="title">What's included?</p> */}
                  <GetPolicy policyName={policyEnum.ShortTerm.key} 
                    title={policyEnum.ShortTerm.displayText} history={props.history} disableContinueBtn={props.disableContinueBtn} movePolicyType={"global"}/>
                    {/* <div className="horizontal-section download" >
                                <p>Download Policy for Short Term</p>
                            </div> */}
                </>)
              else return ( <>
                {/* <p className="title">What's included?</p> */}
                <GetPolicy policyName={policyEnum.LongTermTier1.key} 
                title={policyEnum.LongTermTier1.displayText} history={props.history} disableContinueBtn={props.disableContinueBtn} movePolicyType={"global"}/>
                  {/* <div className="horizontal-section download" >
                              <p>Download Policy for Long Term Tier 1</p>
                          </div> */}
              </>)
            }
            return (<>
                {/* <p className="title">What's included?</p> */}
                <GetPolicy policyName={policyEnum.LongTermTier1.key} 
                title={policyEnum.LongTermTier1.displayText} history={props.history} disableContinueBtn={props.disableContinueBtn} movePolicyType={"global"}/>
                  {/* <div className="horizontal-section download" >
                              <p>Download Policy for Long Term Tier 1</p>
                          </div> */}
              </>)
        case "31-":
        //   moveOption === "" && setMoveOption(locationState["payGrade"])
          if(locationState["strategic-indent"] === "Accelerated Development")
            return (<>
                {/* <p className="title">What's included?</p> */}
                <GetPolicy policyName={policyEnum.LongTermTier2.key} 
                title={policyEnum.LongTermTier2.displayText} history={props.history} disableContinueBtn={props.disableContinueBtn} movePolicyType={"global"}/>
                  {/* <div className="horizontal-section download" >
                              <p>Download Policy for Long Term Tier 2</p>
                          </div> */}
              </>)
          if(locationState["lengthOfAssignment"] === "LessThanYear")
            return (<>
                {/* <p className="title">What's included?</p> */}
                <GetPolicy policyName={policyEnum.ShortTerm.key} 
                title={policyEnum.ShortTerm.displayText} history={props.history} disableContinueBtn={props.disableContinueBtn} movePolicyType={"global"}/>
                  {/* <div className="horizontal-section download" >
                              <p>Download Policy for Short Term</p>
                          </div> */}
              </>)
          else return (<>
            {/* <p className="title">What's included?</p> */}
            <GetPolicy policyName={policyEnum.LongTermTier2.key} 
            title={policyEnum.LongTermTier2.displayText} history={props.history} disableContinueBtn={props.disableContinueBtn} movePolicyType={"global"}/>
              {/* <div className="horizontal-section download" >
                          <p>Download Policy for Long Term Tier 2</p>
                      </div> */}
          </>)
        default:
            return null;
      }
    }

    return (
        <div className="primary-reason-global">
            <div className="top">
                <div className="title">What is the pay grade of the employee?</div>
            </div>
            <div className="options">
                {Object.keys(PayGradeOpts).map(key => {
                    return (
                        <div className="option" key={key}>
                            <RadioButton
                                checked={moveOption === PayGradeOpts[key].value}
                                onChange={(): void =>
                                    clickHandler(PayGradeOpts[key].value, PayGradeOpts[key].payGrade)
                                }
                            />
                            <div className="titles">
                                <p className="sub-title">
                                  Paygrade
                                </p>
                                <p className="title">{PayGradeOpts[key].displayText}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            {handlePaygrade()}
        </div>
    );
};

export default PayGradeSelect;
