import React, { PureComponent } from 'react'
import Pagination from "react-js-pagination";

import { CTAComponent } from 'components/common';
import articles from 'containers/ArticleDetail/Articles';
import "./styles.scss";

export default class Articles extends PureComponent {
    state = { pageNumber: 1 };
    itemsPerPage = 4;

    getArticlesForPage = (articles, pageNumber, count) => {
        const start = (pageNumber-1) * count;
        const filteredArticles = articles.slice(start, start + count);
        return filteredArticles.map((article, index) => ({
            index: index + start,
            article
        }))
    };

    handlePageChange = pageNumber => {this.setState({pageNumber})};

    render() {
        const { moveType } = this.props;
        const articleType = (moveType === 'international') ? 'global' : 'domestic'
        const articlesByMove = articles[articleType];
        const articlesForPage = this.getArticlesForPage(articlesByMove, this.state.pageNumber, this.itemsPerPage);

        const hidePagination = articlesByMove.length <= this.itemsPerPage;
        const hideAdditionalButtons = (Math.ceil(articlesByMove.length/this.itemsPerPage) < 6);

        return (
            <div className="flexible vertical">
                <div className="articles flexible jAround">
                    {
                        articlesForPage.map(( {article, index} ) => (
                            <CTAComponent key={'cta_'+index}
                                onClick={() => this.props.navigateToArticle(index)}
                                image={article.image}
                                title={article.header}
                            />
                        ))
                    }
                </div>
                {   !hidePagination &&
                    <div className="flexible jCenter">
                        <Pagination
                            activePage={this.state.pageNumber}
                            itemsCountPerPage={this.itemsPerPage}
                            totalItemsCount={articles[articleType].length}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                            hideFirstLastPages={hideAdditionalButtons}
                            hideNavigation={hideAdditionalButtons}
                        />
                    </div>
                }
            </div>
        );
    }
}