//@flow
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Icon } from "components/common";
import { INFO_CARD_COLORS } from "consts";
import { InfoKnockOutCard } from "components/common";

import logoImage from "components/common/Images/manager-aspot-photo.png";

import { CAROUSEL_NAMES, CAROUSEL_URLS } from "../constants";

import "./style.scss";

interface CarouselMainProps {
    userName: React.Node;
    onChangeCarousel: (to: string) => void;
    newFlow: boolean;
}

interface CarouselData {
    label: string;
    icon: string;
    navigate: string;
}

const carouselData: CarouselData[] = [
    {
        label:
            "I’d like to learn about talent moves",
        icon: "idea",
        navigate: CAROUSEL_NAMES.LEARN_ABOUT,
    },
    {
        label: "I’m ready to initiate a new move or manage an existing move",
        icon: "moving",
        navigate: CAROUSEL_NAMES.READY_TO_MOVE,
    },
    {
        label: "I need to continue an initiation request I already started",
        icon: "profile",
        navigate: CAROUSEL_NAMES.CONTINUE,
    },
    {
        label: "I need to talk to someone / request a cost estimate",
        icon: "talk",
        navigate: CAROUSEL_NAMES.TALK,
    },
];

class CarouselMain extends React.PureComponent<CarouselMainProps> {
    render() {
        const { userName, onChangeCarousel, newFlow } = this.props;

        const handleRoute = nav => {
            if (nav.startsWith("https://")) {
                window.open(nav, "_blank");
            } else if (nav.startsWith("mailto:")) {
                window.location.replace(nav);
            } else {
                this.props.history.push(nav);
            }
        }

        return (
            <div className={`CarouselMain ${newFlow ? "new-flow" : ""}`}>
                <div className="hr-carousel-main">
                    <img className="image-block" src={logoImage} alt="" />
                    <div className="hero-text-section flexible vertical hr-carousel-main__hero-text-section">
                        <p className="hero-text-section__user-name user-name">
                            Hi {userName},
                        </p>
                        {!newFlow ? (
                            <p className="hero-text">
                                Need to move someone on your team? You’re in the
                                right place!
                            </p>
                        ) : (
                            <p className="hero-text">
                                Need to complete a request or get a quick
                                status? Manage your active moves below…
                            </p>
                        )}
                        {!newFlow ? (
                            <p className="hero-text2">How can we help today?</p>
                        ) : null}
                        {/* {!newFlow ? (
                            <p className="hero-text-link">For Consumer employees, please go to <a className={"hpr-link"} rel="noopener noreferrer" target="_blank" href={"https://move.kenvue.com/"} >move.kenvue.com</a></p>
                        ) : null} */}
                    </div>
                </div>
                {!newFlow ? (
                    <InfoKnockOutCard
                        backgroundColor={INFO_CARD_COLORS.first}
                        headerIsLink
                        // title="Great, this position qualifies for Executive level support"
                        content={<>
                            For US Domestic Relocation initiations in progress prior to February 21st, please contact the SIRVA team at
                            <a href="mailto:mobilityconnect@sirva.com" style={{ color: "#cc0099" }}> Mobility Connect. </a><br /><br />
                            On February 22nd and February 23rd, the move.jnj.com site will be unable to accept any US Domestic Relocation initiations.<br /><br />
                            For US Domestic Relocation new initiations as of February 26th, please go to Workday to create a Job Requisition to initiate a relocation request <a href="https://wd5.myworkday.com/jj/d/home.htmld" style={{ color: "#cc0099" }}>https://wd5.myworkday.com/jj/d/home.htmld.</a>
                        </>}
                    />
                ) : null}
                {!newFlow && (
                    <div className="hr-main-columns">
                        <h3 className="mbottom-30">How can we help today?</h3>
                        <Grid container spacing={24}>
                            {carouselData.map(({ label, navigate, icon }) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    className="flexible"
                                    key={label}
                                >
                                    <Paper
                                        className="CarouselCard withStretch"
                                        style={{ width: "100%" }}
                                        square
                                        elevation={0}
                                        onClick={() => {
                                            switch(icon) {
                                                case "moving": 
                                                    handleRoute(CAROUSEL_URLS.READY_TO_MOVE); 
                                                    onChangeCarousel(navigate);
                                                    break;
                                                case "profile": 
                                                    handleRoute(CAROUSEL_URLS.CONTINUE); 
                                                    onChangeCarousel(navigate);
                                                    break;
                                                case "idea": 
                                                    handleRoute(CAROUSEL_URLS.LEARN_ABOUT); 
                                                    break;
                                                case "talk": 
                                                    handleRoute(CAROUSEL_URLS.TALK); 
                                                    break;
                                                default: break;
                                            }
                                        }}
                                    >
                                        <Icon
                                            className="CarouselCardIcon"
                                            name={icon}
                                            width={40}
                                            height={40}
                                        />
                                        <div className="link-icon-flex">
                                            <Typography
                                                variant="body1"
                                                color="secondary"
                                                className="CarouselItemText"
                                            >
                                                {label}
                                            </Typography>
                                            <Icon
                                                className="LinkIcon"
                                                name="linkIcon"
                                            />
                                        </div>
                                        {/* <Typography
                                            variant="body1"
                                            color="secondary"
                                            className="CarouselItemText"
                                        >
                                            {label}
                                        </Typography>
                                        <Icon
                                            className="LinkIcon"
                                            name="linkIcon"
                                        /> */}
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                )}
            </div>
        );
    }
}

export default CarouselMain;
