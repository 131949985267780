import React from "react";
import { Icon } from "components/common";
import { RouterProps } from "types";
import "./styles.scss";

const RecruiterKnockout = (props: RouterProps) => {
    const backHandler = (): void =>
        props.history.push({
            state: {
                ...props.location.state,
                "working-with-recruiter": "",
            },
        });

    const buttonClickHandler = (): void => props.history.push("/profile");

    return (
        <div className="recruiter-knockout">
            <section className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </section>
            <h1>
                Ok, looks like your journey is already underway!
            </h1>
            <div className="add-info-list">
                <p className="title">
                    Please contact your recruiter who will ensure the relocation is initiated for you.
                </p>
            </div>
            {/* <hr class="line" /> */}
            <button className="submit-button" onClick={buttonClickHandler}>
                Return to Home
            </button>
        </div>
    );
};

export default RecruiterKnockout;
