import React, { useEffect } from "react";
import { INFO_CARD_COLORS } from "consts";
import { InfoKnockOutCard } from "components/common";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { InfoList } from ".";

const useStyles = makeStyles((theme) => ({
    root: {
        display:'initial'
    },
    listItem: {
        padding: 0
    },
    listText: {
        padding: theme.spacing(0, 0, 0, 2)
    }
}));

const GlobalOneWay = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
}) => {

    const classes = useStyles();

    useEffect(() => {
        return () => {
            props.disableContinueBtn(true);
        };
    }, []);

    return (<div className="contact-mobility">
        <InfoKnockOutCard
            backgroundColor={INFO_CARD_COLORS.first}
            headerIsLink
            title="Great, We are here to help!"
            content={<>
                In order to better serve you, please email
                <a href="mailto:mobilityconnect@sirva.com" style={{color: "#cc0099"}}> Mobility Connect </a>
                with the following information:
                <List disablePadding className={classes.root}>
                    {InfoList.map((txt, idx) => (
                        <ListItem key={idx} className={classes.listItem}>
                            <ListItemText className={classes.listText}>{txt}</ListItemText>
                        </ListItem>
                    ))}
                </List>
            </>}
        />
      </div>
    );
};

export default GlobalOneWay;
