// @flow

import React, { PureComponent } from 'react';
import { Tabs } from 'components/sections';
import { withRouter } from 'react-router-dom';
import { Asset, Link } from 'components/common';

import './style.scss';

type EmployeeHeroProps = {
    userName: string,
    imageUrl: string,
    title:string
};

class EmployeeHero extends PureComponent<EmployeeHeroProps> {
   
    componentWillMount() {
        if (this.props.move) {
            const move = this.props.location.pathname.split('/')[2];
            if (move !== this.props.move) {
                this.props.history.push(`/profile/${this.props.move}/explore`);
            }
        }
    }


    handleTabChange = (tabInfo: Object): void => {
        this.props.history.push(`/profile/${tabInfo.key}/explore`);
    };

    render() {
        return(
            <div className="EmployeeHero">
                {
                    this.props.imageUrl 
                    ? (<div className="image-block" style={{ backgroundImage: `url(${this.props.imageUrl})` }}/>)  
                    : (
                    <div className="image-block">
                        <Asset assetType={this.props.assetType} assetId={this.props.assetId} maxWidth={1200} />
                    </div>
                    )
                }
                <div className="hero-text-section flexible jCenter vertical">
                    <p className="user-name">
                        Hi {this.props.userName},
                    </p>
                    <p className="hero-text">
                        {this.props.title}
                    </p>
                    {this.props.linkText &&
                    <Link
                        text={this.props.linkText}
                        action='click-hero'
                        category='profile'
                        label="assignment-letter"
                        onClick={() =>window.location.replace(this.props.linkUrl)}
                    />}
                </div>
                {!this.props.move ? (
                <div className="hero-tabs">
                    <Tabs
                        onTabChange={this.handleTabChange}
                        data = {[
                            {
                                label: "Global Experiences",
                                key: "international",
                            },
                            {
                                label: "Moves within the U.S.",
                                key: "domestic"
                            }]
                        }
                        tabClassName="hero-tab flexible jCenter"
                        activeClassName="active-hero-tab"
                        activeTabKey={this.props.match.params.move}
                    />
                </div>
                ) : null}
            </div>
        );
    }
}

export default withRouter(EmployeeHero);