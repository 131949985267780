import React, { Component } from 'react'
import { Accordion, QuotesImage } from 'components/common';
import { InitMoveStepAccordionData } from './InitMoveStepAccordionData';
import { InitMoveQuestionsData } from './InitMoveQuestionsData';

import './style.scss';

import MobilityConnectBlock from '../../MobilityConnectBlock';
import InitiateAMoveModalWindow from '../MoveInfo/InitiateAMove/InitiateAMoveModalWindow';

import laboratoryPersonImage from 'components/common/Images/laboratory-person.png';
import SectionHeading from 'components/common/SectionHeading';

export default class IntInitMoveStep extends Component {
  state = {
      isPopupOpened: false,
  };

  generateInitMoveStepAccordionData = () => InitMoveStepAccordionData(this.togglePopup).map((item, index) => ({
      props: {
          title: (
              <div className='title flexible horizontal aCenter'>
                  <span className="index flexible jCenter aCenter">{ index + 1 }</span>
                  <p className="title-text">{ item.title }</p>
              </div>
          ),
          uniqueKey: index,
      },
      content: item.content
  }));
  generateInitMoveStepQuestionsData = () => InitMoveQuestionsData.map((item, index) => ({
      props: {
        title: (
            <div className='title flexible horizontal aCenter'>
                <div className="iconblock flexible jCenter aCenter">
                  <img src={item.icon} alt="" />
                </div>
                <p className="title-text">{ item.title }</p>
            </div>
        ),
        uniqueKey: index,
    },
    content:
      (<div className="dom-expl-first-step-info-content">
          { item.content }
      </div>)
  }));

  togglePopup = () => {
    this.setState({
      isPopupOpened: !this.state.isPopupOpened,
    });
  };

  render() {
    const { isPopupOpened } = this.state;

    return (
      <div className="hr-IntInitMoveStep">

        <div className="question-part">
          <SectionHeading
            eyebrow="Get Started"
            title="How to Initiate a Global Move"
            description="You have a strategic plan and you’re ready to initiate a move. Let’s get started…"
            className="section-heading mbottom-40" />
          <div className="dom-expl-first-step">
            <Accordion
                data={this.generateInitMoveStepAccordionData()}
            />
          </div>
        </div>
        <QuotesImage
          imageSrc={laboratoryPersonImage}
          title="The assignee increased the level of quality in her area of responsibility dramatically, and collaboration cross site improved as well. The feedback from business partners across all departments has been very positive."
          description="J&J Manager"
        />

        <div className="flexible dom-expl-question-part">
            <div className="flexible vertical dom-expl-questions">
              <div className="question-part">
                <SectionHeading
                  eyebrow="Initiate a Move"
                  title="Common Questions"
                  className="section-heading mbottom-40" />
                <div className="dom-expl-first-step-questions">
                  <Accordion
                      data={this.generateInitMoveStepQuestionsData()}
                  />
                </div>
              </div>
            </div>
            <div className="flexible vertical dom-expl-mobility">
                <MobilityConnectBlock />
            </div>
        </div>
        <InitiateAMoveModalWindow isOpened={isPopupOpened} onToggle={this.togglePopup} />
      </div>
    );
  }
}
