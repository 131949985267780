import React, { useEffect } from "react";
import { Icon } from "components/common";

const PolicyDisplay = (props: {
  disableContinueBtn: (disableContinueButton: boolean) => void;
  eligibleTitle: string,
  movePolicyType: string,
}) => {

  useEffect(() => {
    return () => {
      props.disableContinueBtn(false);
    };
  }, []);

    return (
        <div className="eligible-container RecruitedSelector download-policy-section">
            <p className="title">What's included?</p>
            <div className="horizontal-section download" >
                <Icon name="linkIcon" className="linkIconCls" />
                <p><a target="_blank" href={props.movePolicyType != undefined && props.movePolicyType === "global"? process.env.REACT_APP_EXT_LINK_INTERNATIONAL_RESOURCES: process.env.REACT_APP_EXT_LINK_US_DOMESTIC_RESOURCES} >Policy for {props.eligibleTitle}</a></p>
            </div>
        </div>
    );
};

export default PolicyDisplay;
