// @flow
import * as React from 'react'
import cs from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import type {WithStyles, StyleRulesCallback} from '@material-ui/core/styles/withStyles';

type JnJContainerProps = {
    static: bool,
    className?: string,
    children: React.Node
};

const styles: StyleRulesCallback = theme => ({
    root: {
        boxSizing: 'border-box',
        margin: '0 auto',
        width: '100%',
        paddingLeft: 25,
        paddingRight: 25,
        [theme.breakpoints.up('sm')]: {
            width: 480,
            paddingLeft: 16,
            paddingRight: 16
        }
        ,
        [theme.breakpoints.up('md')]: {
            width: 768,
                paddingLeft: 28,
                paddingRight: 28
        },
        [theme.breakpoints.up('lg')]: {
            width: 1280,
            paddingLeft: 50,
            paddingRight: 50
        }

    },
});

const JnJContainer = (props: JnJContainerProps & WithStyles) => {
    const {classes} = props;
    return (
        <div className={cs({
            [classes.root]: props.static
        }, props.className)}>
            {props.children}
        </div>
    )
}

JnJContainer.defaultProps = {
    static: false
};

export default withStyles(styles)(JnJContainer);
