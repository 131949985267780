import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type {StyleRulesCallback} from '@material-ui/core/styles/withStyles';
import {Typography} from '@material-ui/core';
import cs from 'classnames';

const styles: StyleRulesCallback = theme => ({
    root: {
        display: 'flex',
        padding: '25px',
        border: '1px solid #D8D8D8',
        alignItems: 'center',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            cursor: 'pointer'
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '50px 35px 90px'
        }
    },
    body: {
        marginLeft: '20px',
        [theme.breakpoints.up('md')]: {
            marginLeft: 0,
            marginTop: '25px'
        }
    },
    iconWrapper: {
        width: '55px',
        textAlign: 'right',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    textItem: {
        lineHeight: '20px',
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
            lineHeight: '25px'
        }
    }
});

const ClickableCard = (props) => {
    const {classes, text, className, children, onClick} = props;

    return (
        <div className={cs(classes.root, className)} onClick={onClick}>
            <div className={classes.iconWrapper}>
                {children}
            </div>
            <div className={classes.body}>
                <Typography variant="body1" className={classes.textItem}>
                    {text}
                </Typography>
            </div>
        </div>
    );
};


export default withStyles(styles)(ClickableCard);
