import React from 'react';

export default (
    <div className="article-info">
        <div className="article-info-item">
            <h2 className="title">Prioritize Your Tasks</h2>
            <p className="description">
            When you’re preparing for a move, everything can feel urgent. This may make it seem like you've got to do a million things at once, but that's not a recipe for success.
            </p>
            <p>
                Start by considering your deadlines and how much time accomplishing a task might take, then sit down and write out that to-do list, organizing it chronologically according to your moving timeline. For instance, if you’re months away from your new job’s start date, cancelling the electric bill or notifying the post office of your change of address can probably come later—but you might have to get your pet’s (or your own!) vaccinations up to date right now to meet import and quarantine requirements. These vary on a country-by-country basis, but if you’re looking to bring a dog with you to the U.S., &nbsp;
                    <a href="https://www.cdc.gov/importation/bringing-an-animal-into-the-united-states/dogs.html" rel="noopener noreferrer" target="_blank" className="link">here are some important guidelines</a> &nbsp;.
            </p>
            <p>
                At this point, you should probably also settle on how (and how much) you plan to move. For instance, are you renting a truck or shipping container for all your furniture, or will you just buy new stuff when you land?
            </p>
            <p>
                Johnson & Johnson will also assign you a Move Specialist, so be sure to fill them in on the details—and challenges—of your move to learn about all of the ways they can support you.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Delegate</h2>
            <p className="description">
                Don’t be afraid to tap your family and friends—or even professional support staff—to accomplish all the items on your to-do list.
            </p>
            <p>
                If you’re going the DIY route with your move, dispatch friends to collect moving boxes and pack up the non-essentials. Have your spouse or partner help you with all of the research and administrative tasks. You also shouldn’t be afraid of seeking out professional help—for instance, to hire a real estate agent to help you find the perfect home in your new location. And if you’re particularly pressed for time, consider hiring a white-glove moving service that can take care of the logistics while you focus on the paperwork
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Prepare for the Essentials</h2>
            <div>
                <p className="description">
                    In the weeks leading up to the big move, think of the things you’ll absolutely need in the first three months of living in your new home. If there will be a lapse in your medical benefits, be sure to visit your dentist, optometrist and any other specialists you typically see before leaving. Get a pair of backup glasses, or stock up on contact lenses. If you’ve got medical prescriptions, fill as many months as your pharmacy will allow before leaving. Consider asking your doctor to prescribe just-in-case travel staples, like a multipurpose antibiotic, too. And of course, don’t forget to stockpile your most cherished local products. You’ll miss them in between care packages from home!
                </p>
                <p>
                    Relocating isn't easy, but there are plenty of steps you can take to make it less difficult. And when you're relocating for the right opportunity, it pays to step out of your comfort zone! So with a little planning and prioritization, you can take some of the stress out of your next big move.
                </p>
            </div>
        </div>
    </div>
)
