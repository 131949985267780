import React, { createContext, useContext, useEffect, useState } from "react";
import { Asset, Icon, RadioButton } from "components/common";
import { History } from "history";
import { globalOrDomesticOpts as moveOpts } from "../keyMappings";
import { sequentialMoveOpts } from "../keyMappings";
import { WorkingWithRecKnockout } from "../knockoutScreens";
import OneWayOrAssignment from "../OneWayOrAssignment";
import SubmitReqWD from "../ReqWD";
import { IsEmpGlobContext } from "../index2";
import { MovOptionContext } from "../WorkingWithRecruiter";

type moveOptionType = string;
export const moveOptionContext = createContext<moveOptionType>('');

const GlobalOrDomestic = (props: {
    navigateTo: (to: string, from: string) => void;
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    const [moveOption, setMoveOption] = useState<moveOptionType>('');
    const IsEmpGlobAssign = useContext(IsEmpGlobContext);
    const movOption = useContext(MovOptionContext);

    const clickHandler = (moveOptionKey: string, value: string, countryName: string, countryCode: string): void => {
      props.history.push({
          state: {
              ...props.history.location.state,
              "global-vs-domestic": value,
              moveType: value,
              countryName: countryName,
              countryCode: countryCode,
          },
      });
      setMoveOption(moveOptionKey);
  };

    return (
        <div className="primary-reason-global">
            <div className="top">
                <div className="title">Is this a move within the United States or is this a move across a global border?</div>
            </div>
            <div className="options">
                {Object.keys(moveOpts).map(key => {
                    return (
                        <div className="option" key={key}>
                            <RadioButton
                                checked={moveOption === moveOpts[key].text}
                                onChange={(): void =>
                                    clickHandler(moveOpts[key].text, moveOpts[key].value, moveOpts[key].countryName, moveOpts[key].countryCode)
                                }
                            />
                            <div className="titles">
                                <p className="title">{moveOpts[key].displayText}</p>
                                <p className="sub-title">
                                    {moveOpts[key].description}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* {moveOption === moveOpts.domestic.text && (
                    <SubmitReqWD 
                        navigateTo={props.navigateTo}
                        history={props.history} 
                        disableContinueBtn={props.disableContinueBtn} 
                    />
                )}
                {moveOption === moveOpts.global.text && (
                    <OneWayOrAssignment 
                        navigateTo={props.navigateTo}
                        history={props.history} 
                        disableContinueBtn={props.disableContinueBtn} 
                    />
                )} */}
            {(IsEmpGlobAssign == 'yes') && (sequentialMoveOpts.empOnAssignment.text === movOption) && (moveOption === moveOpts.domestic.text || moveOption === moveOpts.global.text) &&
                <SubmitReqWD
                    navigateTo={props.navigateTo}
                    history={props.history}
                    disableContinueBtn={props.disableContinueBtn}
                />
            }
            {(IsEmpGlobAssign == 'no') && (sequentialMoveOpts.empOnAssignment.text === movOption) && (moveOption === moveOpts.global.text) && (
                <OneWayOrAssignment
                    navigateTo={props.navigateTo}
                    history={props.history}
                    disableContinueBtn={props.disableContinueBtn}
                />
            )
            }
            {(IsEmpGlobAssign == 'no') && (sequentialMoveOpts.empOnAssignment.text === movOption) && (moveOption === moveOpts.domestic.text) &&
                <SubmitReqWD
                    navigateTo={props.navigateTo}
                    history={props.history}
                    disableContinueBtn={props.disableContinueBtn}
                />
            }
            {(IsEmpGlobAssign == 'yes') && (sequentialMoveOpts.notWorkingWithRec.text === movOption) && (moveOption === moveOpts.global.text) && (
                <OneWayOrAssignment
                    navigateTo={props.navigateTo}
                    history={props.history}
                    disableContinueBtn={props.disableContinueBtn}
                />
            )
            }
            {(IsEmpGlobAssign == 'yes') && (sequentialMoveOpts.notWorkingWithRec.text === movOption) && (moveOption === moveOpts.domestic.text) && (
                <moveOptionContext.Provider value={moveOption}>
                    <SubmitReqWD
                        navigateTo={props.navigateTo}
                        history={props.history}
                        disableContinueBtn={props.disableContinueBtn}
                    />
                </moveOptionContext.Provider>
            )}
            {(IsEmpGlobAssign == 'no') && (sequentialMoveOpts.notWorkingWithRec.text === movOption) && (moveOption === moveOpts.global.text) && (
                <OneWayOrAssignment
                    navigateTo={props.navigateTo}
                    history={props.history}
                    disableContinueBtn={props.disableContinueBtn}
                />
            )
            }
            {(IsEmpGlobAssign == 'no') && (sequentialMoveOpts.notWorkingWithRec.text === movOption) && (moveOption === moveOpts.domestic.text) && (
                <SubmitReqWD
                    navigateTo={props.navigateTo}
                    history={props.history}
                    disableContinueBtn={props.disableContinueBtn}
                />
            )}
        </div>
    );
};

export default GlobalOrDomestic;
