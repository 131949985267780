// @flow

import { Api } from 'backend';

export default {
    getRelocations: (userEmail: string): Promise<any> => Api.get({
        path: `/Sirva/employees/${userEmail}`
    }),
    getRelocationsWithHeaders: (userEmail: string): Promise<any> => Api.get({
        path: `/Sirva/employeesWithHeaders/${userEmail}`
    }),
    setActiveRelocation: (userEmail: string, relocationId: string) => Api.post({
        path: `/User/relocation/${userEmail}`,
        body: `${relocationId}`
    }),
    getCounselor: (relocationId: string): Promise<any> => Api.get({
        path: `/Sirva/relocations/${relocationId}/counselor`
    })
};