import React from "react";
import { Icon } from "components/common";
import { RouterProps } from "types";
import "./styles.scss";

const TalentMobilityExec = (props: RouterProps) => {
    const backHandler = (): void =>
        props.history.push({
            state: {
                ...props.location.state,
                "pay-grade": "",
            },
        });

    const buttonClickHandler = (): void => props.history.push("/profile");

    return (
        <div className="talent-mobility-exec">
            <section className="back-icon" onClick={backHandler}>
                <Icon name="backIcon" />
                <p>Go back</p>
            </section>
            <h1>
                Great, this position qualifies for Executive level support from
                the Talent Mobility Team!
            </h1>
            {/* <div className="add-info-list">
                <p className="title">
                    In order to better serve you, please email <a href="mailto:mobilityconnect@sirva.com?subject=[Executive] Talent Mobility Support">mobilityconnect@sirva.com</a> and include the following information:
                </p>
                <ul>
                    <li>Employee’s Name and WWID</li>
                    <li>Hiring Manager’s Name and WWID</li>
                    <li>Current Country Location</li>
                    <li>Future Country Location</li>
                    <li>Move type</li>
                </ul>
            </div>
            <div className="move-type-puncts">
                <p className="title">Not sure of the move type?</p>
                <div className="boxes">
                    <div className="box">
                        <div className="left">01</div>
                        <div className="right">
                            <div className="top-text">
                                International One Way Move:
                            </div>
                            <div className="mid-content">
                                A Global relocation cross border that is
                                intended to last over 5 years.
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="left">02</div>
                        <div className="right">
                            <div className="top-text">
                                International Assignment:
                            </div>
                            <div className="mid-content">
                                A Global assignment cross border that is
                                intended to last between 3 months and 5 years.
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="left">03</div>
                        <div className="right">
                            <div className="top-text">
                                US Domestic Relocation:
                            </div>
                            <div className="mid-content">
                                A US domestic relocation is generally considered
                                a permanent one way move.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-info-list">
                <p className="label">What's Next</p>
                <p className="textFont">
                    Once we receive your email, we will contact you within 48 hours to guide you through the rest of your journey.</p>
            </div>
            <div className="contact-mobility">
                <p className="title">Contact Talent Mobility</p>
                <p className="middle-text">
                    Please use this following email address to connect with us.
                    Once we receive your email, we will contact you within 48
                    hours to guide you through the rest of your journey.
                </p>
                <div className="email-block">
                    <Icon name="mailBlack" width={20} height={15} />
                    <a href="mailto:TalentMobil@its.jnj.com?subject=[Executive] Talent Mobility Support">
                        TalentMobil@its.jnj.com
                    </a>
                </div>
            </div>
            <hr className="line" /> */}
            <button className="submit-button" onClick={buttonClickHandler}>
                Return to Home
            </button>
        </div>
    );
};

export default TalentMobilityExec;
