import React, { PureComponent } from 'react';
import { onHistoryLinkClicked } from '../../../utils';
import { Icon } from 'components/common';
import {JnJGridRow, JnJGridColumn} from 'components/common/JnJGrid';
import './style.scss';

export default class QuestionsListCard extends PureComponent {
    setButtons = () => {
      const { urlText, urlActions, urlIconClassName = '' } = this.props;

      // provide saving metrics by click
      return (urlText && urlText.length > 0 && urlActions)
        ? (
          <h4 className="flexible horizontal">
            { urlText.map((textItem, index) => <span key={index}
              onClick={(e) => {
                const urlItem = urlActions[index];
                onHistoryLinkClicked({
                    action:'click-question-link',
                    category:'questions',
                    label:typeof urlItem === "string" ? urlItem : textItem,
                    onClick:(e2) => typeof urlItem === "string" ? window.open(urlItem) : urlItem(e2)
                },e);
              }}>{textItem} <Icon name="chevronWhite" className={urlIconClassName}/></span>) }
          </h4>
        )
        : null;
    };

    setFacts = () => {
        const { factLabels, factValues } = this.props;

        return (factLabels)
          ? (
            <JnJGridRow className="facts flexible mtop-50">
                {
                    factLabels.map((item,index) =>(
                        <JnJGridColumn
                            xs={12}
                            md={6}
                            className="fact ptop-10 pbottom-10"
                            key={item}
                        >
                            <span className="fact-label">{item}:</span><br/>
                            <span className="fact-value">{factValues[index]}</span>
                        </JnJGridColumn>
                    ))
                }
            </JnJGridRow>
          )
          : null;
    };

    renderIcon = (icon) => {
      return (
        <div className="icon-block">
          <img src={icon} alt="" />
        </div>
      );
    };

    render() {
        const { icon, title, body, isIconOnRightSide } = this.props;

        return (
            <div className="QuestionListCard">
               <div className="question-card flexible aStart">
                   { !isIconOnRightSide && this.renderIcon(icon) }
                   <div className="text-block flexible vertical grow">
                       { title && <h3>{title}</h3> }
                       { body && <div>{body}</div> }
                       { this.setFacts() }
                       { this.setButtons() }
                   </div>
                   { isIconOnRightSide && this.renderIcon(icon) }
               </div>
            </div>
        );
    }
}
