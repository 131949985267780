import BenefitServiceCenter from './BenefitServiceCenter';

export const BenefitData = [
    {
        title: 'Get Interim Care',
        content: {
            description: 'If you or your covered dependents require medical care before you receive your ID card, you will need to pay at the time of treatment. Maintain your receipts and submit them to CIGNA Global Health Benefits for reimbursement when you receive your ID card.',
            listTitle: 'Once you receive access, the CIGNA website allows you to manage your claim with:',
            list: [
                'Online claim submission through secure mail',
                'Full claim history'
            ],
            link: {
                text: 'CIGNA Envoy',
                url: 'https://www.cignaenvoy.com/'
            }
        }
    },
    {
        title: 'Enroll Your Dependents',
        content: {
            description: 'You will be able to enroll your dependents 7-10 days after your official start date. You must complete enrollment within 60 days of your official start date. It is of the utmost importance that you enroll during this enrollment period. If not completed you will need to wait until the next open enrollment period which is October through November for coverage in the following calendar year.',
            listTitle: 'To add family members to your coverage, contact the Benefit Service Center (BSC) within 60 days of the start of your assignment.',
            additionalContent: BenefitServiceCenter,
            list: [
                'You need the last four digits of your temporary or permanent Social Security Number (SSN) and your birth date to access the BSC. Follow the prompts to create a new password.',
                'Please note: If you do not have a current US SSN the J&J Global Services team will forward to you via email your Temp SSN. If you do not hear from Global Services, please contact your Relocation Services Consultant.'
            ]
        }
    },
    {
        title: 'Get Your Medical ID Cards',
        content: {
            description: 'Medical ID Cards will be sent to you 7-10 business days after your official start date or from the date of enrollment. You can also review your membership ID online.',
            listTitle: 'To access the website, you need the Cigna Global Health Benefits group number and your member number.',
            list: [
                'Use Group Number: 00426A',
                'To get your member number, wait at least 10 days after your assignment start date, then contact Cigna Global Health Benefits Member Services anytime at 1-302-797-3100.'
            ],
            link: {
                text: 'CIGNA Envoy',
                url: 'https://www.cignaenvoy.com/'
            }
        }
    },
    {
        title: 'Change Your Coverage',
        content: {
            description: 'If you have a status change during the year, such as getting married or having or adopting a baby, you can update your coverage on the For Your Benefit™ (FYB) Website.',
            listTitle: 'Log into FYB with your User ID and password. Enrollment must happen within 60 days of the life event.',
            link: {
                text: 'For Your Benefit™',
                url: ''
            }
        }
    },
    {
        title: 'Find Doctors, Submit Claims & More',
        content: {
            description: 'If you or your covered dependents require medical care before you receive your ID card, you will need to pay at the time of treatment. Maintain your receipts and submit them to CIGNA Global Health Benefits for reimbursement when you receive your ID card.',
            listTitle: 'Find Doctors, Submit Claims & More',
            list: [
                'Online claim submission through secure mail',
                'Searchable directory for local health care needs',
                'Full claim history available',
                'Country-specific health information',
                'Health and well-being assessments and health improvement programs'
            ],
            link: {
                text: 'CIGNA Envoy',
                url: 'https://www.cignaenvoy.com/'
            }
        }
    },
];
