
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

export default function registercookietest() {
    if (isLocalhost) {
        console.log('third party cookies validated frame is not supported on localhost');
    }
    else {
        /*window.addEventListener("message", receiveMessage, false);
        addcookietestframe();*/
    }
}

//this functions maybe will use in future

// function receiveMessage(evt) {
//     console.log('third party cookies are not supported');
//     window.browserThirdPartyCookieSupported = false;
//     window.removeEventListener("message", receiveMessage);
//
//     if (evt.data === 'MM:3PC:movejnjunsupported') {
//         console.log('third party cookies are not supported');
//         window.browserThirdPartyCookieSupported = false;
//         window.removeEventListener("message", receiveMessage);
//     } else if (evt.data === 'MM:3PC:movejnjsupported') {
//         console.log('third party cookies are supported');
//         window.browserThirdPartyCookieSupported = true;
//         window.removeEventListener("message", receiveMessage);
//     }
// };

// function addcookietestframe() {
//     var cookietestframe = document.createElement('iframe');
//     cookietestframe.setAttribute('src', process.env.REACT_APP_AUTH_SERVER_URL + "start.html");
//     cookietestframe.setAttribute('style', "display:none");
//     document.body.appendChild(cookietestframe);
//
//     console.log('third party cookies validated frame was added');
// }
