//@flow

import React from 'react';
import './style.scss';

export type SectionHeadingProps = {
    className?: string,
    eyebrow?: string,
    title: string,
    description?: string
}

const SectionHeading = (props: SectionHeadingProps) => (
    <div className={props.className}>
        {props.eyebrow && <span className="section-eyebrow">{props.eyebrow}</span>}
        <h2 className="section-title">{props.title}</h2>        
        {props.description && <p className="section-heading-description">{props.description}</p>}
    </div>
);

export default SectionHeading;