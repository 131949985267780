import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserProfile } from 'selectors';
import { MidSectionComponent, Link } from 'components/common';

const mapStateToProps = (state) => ({
  userProfile: getUserProfile(state),
});

class IntArrivalStep extends Component {

  render() {
    return (
      <div>
        {this.props.userProfile.isOneWayCurrentRelocation 
        ? (<MidSectionComponent title = "About Your Benefits" className="IntArrivalStep" col1={
            <div className="flexible vertical AboutYourBenefits">
            <p>You will be enrolled in the host country benefits plan for items such as Social Security, Retirement,<br /> Pension, Medical. Your home benefits will be discontinued. </p>
            </div>
            }  
        />)
        : (<MidSectionComponent title = "Prepare Your Health Benefits" className="IntArrivalStep" hideDivider="true" col1={
            <div className="flexible vertical">
            <p className="step-number" >Step 1</p>
            <h3>Enroll Your Dependents</h3>
            <p>You will be able to enroll your dependents 7-10 days after your official start date. You must complete enrollment within 60 days of your official start date or you and your dependents may experience a gap in coverage.</p>
            <Link text="Benefits Service Center Contact" externalLink='http://jjworkday.com/'/>
            </div>
            }

            col2={
            <div className="flexible vertical  pad-left">
            <p className="step-number" >Step 2</p>
            <h3>Get Interim Care</h3>
            <p>If you or your covered dependents require medical care before you receive your ID card, you will need to pay at the time of treatment. Maintain your receipts and submit them to Cigna Global Health Benefits for reimbursement when you receive your ID card.</p>
            </div>
            }

            col3={
            <div className="flexible vertical  pad-left">
            <p className="step-number" >Step 3</p>
            <h3>Obtain Medical ID Cards</h3>
            <p>Medical ID Cards will be sent to you 7-10 business days after your official start date or from the date of enrollment. You can also review your membership ID online.</p>
            <Link text="Additional Details" externalLink='http://jjworkday.com/'/>
            </div>
            }

            col4={
              <div className="flexible vertical  pad-left">
              <p className="step-number" >Step 4</p>
              <h3>Find Local Doctors</h3>
              <p>CIGNA Global Health Benefits provides access to broad worldwide networks of doctors and hospitals in more than 205 countries and jurisdictions totaling more than 147,000 health care professionals worldwide, and 550,000 in the United States.</p>
              <Link text="CIGNA Envoy" onClick={() => window.location.replace('/benefit-info')}/>
              </div>
              }
        />)}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, null)(IntArrivalStep));
