// @flow

import React, { PureComponent } from 'react';
import { Asset } from 'components/common';
import CN from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import type {WithStyles, StyleRulesCallback} from '@material-ui/core/styles/withStyles';

type InfoContentProps = {
    assetType: string,
    assetId: string,
    title: string,
    description: string,
};

const styles: StyleRulesCallback = theme => ({
    overviewInfoContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
        }
    },
    titleWithDescription: {
        marginRight: '70px',
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
            marginTop: 20,
        }
    },
    infoContentAsset: {
        [theme.breakpoints.down('md')]: {
            height: '100%',
            '& img': {
                height: '100%',
                width: 'auto'
            }
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            '& img': {
                width: '70%'
            }
        },
    },
    flex: {
        flex: 1
    }
});

class OverviewInfoContent extends PureComponent<InfoContentProps & WithStyles> {
    render () {
        const { classes } = this.props;
        return (
            <div className={`${CN(classes.overviewInfoContent, 'overview-info-content')} flexible`}>
                <div className={CN(classes.titleWithDescription, classes.flex, 'title-with-description')}>
                    <div className="body1 mbottom-25">
                        {this.props.title.split('\n').map((item, i) => {
                            return <p key={i}>{item}</p>
                        })}
                    </div>
                    <p className="body2">{this.props.description}</p>
                </div>
                <div className={`flexible jCenter ${CN(classes.infoContentAsset, classes.flex, 'info-content-asset')}`}>
                    <Asset
                        assetType={this.props.assetType}
                        assetId={this.props.assetId}
                        showLoading
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(OverviewInfoContent);
