// @flow

import React, { PureComponent } from 'react';
import TabItem from './TabItem';

import './styles.scss';

type TabsProps = {
    data: Array<Object>,
    tabClassName?: string,
    tabStyles?: Object,
    activeClassName?: string,
    className?: "linear-selected" | string,
    onTabChange: Function,
    activeTabKey: string,
};

type TabsState = {
    activeTabKey: string,
};

export default class Tabs extends PureComponent<TabsProps, TabsState> {
    constructor(props: TabsProps) {
        super(props);

        this.state = {
            activeTabKey: '',
        };
    }

    componentWillMount() {
        const { activeTabKey } = this.props;
        activeTabKey && this.setState({
            activeTabKey
        });
    }

    onTabChanged = (tabInfo: Object) => {
        const { onTabChange } = this.props;
        this.setState({
            activeTabKey: tabInfo.key,
        });

        onTabChange && onTabChange(tabInfo);

    };

    render() {
        const {
            data,
            className,
            tabStyles,
            tabClassName = '',
            activeClassName = '',
        } = this.props;
        return (
            <div className={`Tabs flexible horizontal jBetween ${className || ''}`}>
                {
                    data.map(tabInfo => {
                        const isActive = this.state.activeTabKey === tabInfo.key;
                        return (
                            <TabItem
                                key={tabInfo.key}
                                tabKey={tabInfo.key}
                                label={tabInfo.label}
                                styles={tabStyles}
                                className={`${tabClassName} ${isActive ? activeClassName : ''}`}
                                isActive={isActive}
                                onClick={this.onTabChanged}
                            />
                        );
                    })
                }
            </div>
        );
    }
}

// this should be removed later
Tabs.defaultProps = {
    data: [
        {
            label: "Global Experiences",
            key: "experiences",
        },
        {
            label: "Moves within the U.S.",
            key: "moves"
        },
        {
            label: "Menu 1",
            key: "menu1"
        },
        {
            label: "Menu 2",
            key: "menu2"
        },
    ],
    activeTabKey: 'menu1',
};
