import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CurrenciesAPI } from 'api';

import { SelectField }from "components/sections/ValidatableForm/elements";

import { getUserProfile, getExpenseReportLineItem } from 'selectors';

const mapStateToProps = (store) =>
    ({
        expenseReportLineItem: getExpenseReportLineItem(store),
        profile: getUserProfile(store)
    });

class Currencies extends PureComponent {
    state = {
        currencies:[],
        incurredCurrency:"",
        reimbursementCurrency: ""
    };
    componentDidMount(){
        CurrenciesAPI
            .getCurrencies()
            .then(response => {
                if(response.result && response.result.length){
                    const currencies = response.result.map(currency => ({
                        label:`${currency.description} (${currency.code})`,value:currency.code
                    }));
                    this.setState({ currencies })
                }
            })
    }
    handleReimbursementCurrencyChange = ({ target }) => {
        this.setState({
            reimbursementCurrency: target.value,
        });
        if(this.props.onCurrenciesUpdate){
            this.props.onCurrenciesUpdate({
                incurredCurrency: this.getIncurredCurrency(),
                reimbursementCurrency : target.value,
            })
        }
    };

    handleIncurredCurrencyChange = ({ target }) => {
        this.setState({
            incurredCurrency: target.value,
        });
        if(this.props.onCurrenciesUpdate){
            this.props.onCurrenciesUpdate({
                reimbursementCurrency: this.getReimbursementCurrency(),
                incurredCurrency: target.value,
            })
        }
    };
    getIncurredCurrency = () => this.state.incurredCurrency
        ||  this.props.expenseReportLineItem.incurredCurrency
        || "USD";

    getReimbursementCurrency = () => this.state.reimbursementCurrency || this.props.reimbursementCurrency
        ||this.props.expenseReportLineItem.reimbursementCurrency
        || "USD";

    selectReimbussedCurrency = () => (
        this.state.currencies.filter((currency) => {
            if(this.props.reimbursementCurrency === currency.value) {
                return currency
            } else {
                return false;
            }
        })
    );

    renderGlobal(){
        const { allowAllReimbursed } = this.props;
        return (<div className="currency flexible horizontal aStart">
            <div className="name flexible grow currency--item currency--item__dynamic">
                <SelectField
                    className="currency--dropdown currency--dropdown__incurred"
                    label="Incurred Currency:"
                    name="incurredCurrency"
                    value={this.getIncurredCurrency()}
                    onChange={this.handleIncurredCurrencyChange}
                    options={this.state.currencies}
                />
            </div>
            <div className="amount flexible grow">
                <div className="flexible horizontal grow currency--item currency--item__dynamic">
                    <SelectField
                        className="currency--dropdown currency--dropdown__reimbursed"

                        value={this.getReimbursementCurrency()}
                        label="Reimbursed Currency:"
                        name="reimbursementCurrency"
                        onChange={this.handleReimbursementCurrencyChange}
                        options={allowAllReimbursed ? this.state.currencies : this.selectReimbussedCurrency()}
                    />
                </div>
            </div>
        </div>)
    }
    renderLabel(){
        return <div className="currency flexible horizontal aStart">
            <div
                data-key="Incurred_currency"
                className="currency--item currency--item__static reimbursed-currency flexible vertical "
            >
                <span className="currency--label">Incurred Currency</span>
                <text className="currency--value">{this.getIncurredCurrency()}</text>
            </div>
            <div
                data-key="reimbursed_currency"
                className="currency--item currency--item__static flexible vertical "
            >
                <span className="currency--label">Reimbursed Currency</span>
                <text className="currency--value">{this.getReimbursementCurrency()}</text>
            </div>
        </div>
    }
    render(){
        if(this.props.profile.currentRelocationIsDomestic){
            return this.renderLabel()
        }else{
            if(this.props.isLabelOnly){
                return this.renderLabel()
            }
            return this.renderGlobal();
        }
    }
}

export default connect(mapStateToProps)(Currencies);
