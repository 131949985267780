export const policyEnum = {
    Commuter: { displayText: "J&J Commuter", key: "Commuter" },
    LongTermTier1: { displayText: "J&J Long Term Tier I", key: "LongTermTier1" },
    LongTermTier2: { displayText: "J&J Long Term Tier II", key: "LongTermTier2" },
    OneWayMoveTier1: {
        displayText: "One-Way Employee - Tier I",
        key: "OneWayMoveTier1",
    },
    OneWayMoveTier2: {
        displayText: "One-Way Employee - Tier II",
        key: "OneWayMoveTier2",
    },
    OneWayMoveTier3: {
        displayText: "One-Way Employee - Tier III",
        key: "OneWayMoveTier3",
    },
    ShortTerm: { displayText: "J&J Short Term", key: "ShortTerm" },
    HomeownerPackageA: {
        displayText: "Homeowner Package A",
        key: "HomeownerPackageA",
    },
    HomeownerPackageB: {
        displayText: "Homeowner Package B",
        key: "HomeownerPackageB",
    },
    HomeownerPackageCManager: {
        displayText: "Homeowner Package C - Manager",
        key: "HomeownerPackageCManager",
    },
    HomeownerPackageCNonManager: {
        displayText: "Homeowner Package C - Non Manager",
        key: "HomeownerPackageCNonManager",
    },
    HomeownerPackageD: {
        displayText: "Homeowner Package D",
        key: "HomeownerPackageD",
    },
    LDPFinalMovePackageB: {
        displayText: "LDP Final Move Package B",
        key: "LDPFinalMovePackageB",
    },
    LDPFinalMovePackageBNewHire: {
        displayText: "LDP Final Move Package B - New Hire",
        key: "LDPFinalMovePackageBNewHire",
    },
    LDPNewHirePackageA: {
        displayText: "LDP New Hire Package A",
        key: "LDPNewHirePackageA",
    },
    LDPRotationalPackageA: {
        displayText: "LDP Rotational Package A",
        key: "LDPRotationalPackageA",
    },
    RenterPackageA: { displayText: "Renter Package A", key: "RenterPackageA" },
    RenterPackageB: { displayText: "Renter Package B", key: "RenterPackageB" },
    RenterPackageBNewHire: {
        displayText: "Renter Package B - New Hire",
        key: "RenterPackageBNewHire",
    },
    RenterPackageC: { displayText: "Renter Package C", key: "RenterPackageC" },
    RenterPackageD: { displayText: "Renter Package D", key: "RenterPackageD" },
    USDomesticHomeownerTier1: {
        displayText: "J&J US Domestic Homeowner Tier 1",
        key: "USDomesticHomeownerTier1",
    },
    USDomesticHomeownerTier2: {
        displayText: "J&J US Domestic Homeowner Tier 2",
        key: "USDomesticHomeownerTier2",
    },
    USDomesticHomeownerTier3: {
        displayText: "J&J US Domestic Homeowner Tier 3",
        key: "USDomesticHomeownerTier3",
    },
    USDomesticRenterTier1: {
        displayText: "J&J US Domestic Renter Tier 1",
        key: "USDomesticRenterTier1",
    },
    USDomesticRenterTier2: {
        displayText: "J&J US Domestic Renter Tier 2",
        key: "USDomesticRenterTier2",
    },
    USDomesticRenterTier3: {
        displayText: "J&J US Domestic Renter Tier 3",
        key: "USDomesticRenterTier3",
    },
    USDomesticLDPFinalMove: {
        displayText: "J&J US Domestic LDP Final Move",
        key: "USDomesticLDPFinalMove",
    },
    USDomesticLDPNewHire: {
        displayText: "J&J US Domestic LDP New Hire",
        key: "USDomesticLDPNewHire",
    },
    USDomesticLDPRotational: {
        displayText: "J&J US Domestic LDP Rotational",
        key: "USDomesticLDPRotational",
    },
    LumpSum: {
        displayText: "J&J US Domestic Lump Sum",
        title: "Is Lump Sum applicable in lieu of a full relocation policy?",
        key: "LumpSum",
    },
};
