import React, { useState, useEffect } from "react";
import { RadioButton, Asset, Icon } from "components/common";
import MBA from "./MBA";
import FinalMove from "./FinalMove";
import { History } from "history";
import Checkbox from '@material-ui/core/Checkbox';
import GetPolicy from "../../../Policy";
import { policyEnum } from "../../../../policyMapping";

const FirstRotation = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    const { state: locationState } = props.history.location;
    const [state, setState] = useState<{
        [key: string]: string;
    }>({
        "first-rotation": "",
    });
    const [isChecked, setIsChecked] = useState(false);

    const checkedHandler = () => {
        //setIsChecked(!isChecked);
        if(isChecked) {
            setIsChecked(false);
            props.history.push({
                state: {
                    ...props.history.location.state,
                    "lumpSumPolicy": "No",
                    policy: policyEnum.USDomesticLDPNewHire.key
                },
            });
        } else {
            setIsChecked(true);
            props.history.push({
                state: {
                    ...props.history.location.state,
                    "lumpSumPolicy": "Yes",
                    policy: policyEnum.LumpSum.key
                },
            });
        }
        
    }
    const clickHandler = (firstrotation: string, policy: string): void => {
        props.history.push({
            state: {
                ...props.history.location.state,
                policy,
                policyTier: policy,
                lumpSumPolicy: "NotSet",
                rotationTypeAKey: firstrotation,
                eligibleTitle: policy ? policyEnum[policy].displayText : "NotSet",
                rotationTypeATitle: firstrotation === "yes" ? "Yes" : "No",
                homeownerOrRenter: "NotSet"
            },
        });
    };

    // useEffect(() => {   //  back handler
    //     props.history.push({
    //         state: {
    //             ...locationState,
    //             policy: "NotSet",
    //             rotationTypeAKey: "NotSet",
    //             rotationTypeATitle: "NotSet",
    //         }
    //     })
    // }, [locationState["policyType"], locationState["empProcurementType"]]);

    useEffect(() => {
        if (state["first-rotation"] === "yes") {
            props.disableContinueBtn(false);
            setIsChecked(false);
            props.history.push({
                state: {
                    ...props.history.location.state,
                    "lumpSumPolicy": "No"
                },
            });
        } else {
            props.disableContinueBtn(true);
            setIsChecked(false);
            props.history.push({
                state: {
                    ...props.history.location.state,
                    "lumpSumPolicy": "NotSet"
                },
            });
        }
    }, [state["first-rotation"]]);

    return (
        <>
            <div className="top">
                <div className="title">Rotation?</div>
            </div>
            <div className="options vertical-options">
                <div className="option">
                    <RadioButton
                        checked={state["first-rotation"] === "yes"}
                        onChange={e => { 
                            setState({
                                ...state,
                                ["first-rotation"]: "yes",
                            })
                            clickHandler("yes","USDomesticLDPNewHire")
                        }}
                    />
                    <p className="title">Yes</p>
                </div>
                <div className="option">
                    <RadioButton
                        checked={state["first-rotation"] === "no"}
                        onChange={e => { 
                            setState({
                                ...state,
                                ["first-rotation"]: "no",
                            })
                            clickHandler("no",null)
                        }}
                    />
                    <p className="title">No</p>
                </div>
            </div>
            {state["first-rotation"] === "yes" && (
                <div className="download-policy-section">
                    <p className="title">What's included?</p>
                    <div className="horizontal-section download">
                        <Icon name="linkIcon" />
                        <p><a target="_blank" href={process.env.REACT_APP_EXT_LINK_US_DOMESTIC_RESOURCES} >Policy for {policyEnum.USDomesticLDPNewHire.displayText}</a></p>
                    </div>
                    <>
                        <p className="lum-sum-policy-header-title" >{policyEnum.LumpSum.title}</p>
                        <p className="lum-sum-policy-checkbox-area" ><Checkbox checked={isChecked} onChange={() => {checkedHandler()}} /> &nbsp;Yes</p>
                        <br />
                    </>
                </div>
            )}
            {state["first-rotation"] === "no" && (
                <div className="first-rotation">
                    <FinalMove disableContinueBtn={props.disableContinueBtn} history={props.history} />
                </div>
            )}
        </>
    );
};

export default FirstRotation;
