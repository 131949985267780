// @flow

import { Api } from 'backend';

const ExpenseReportItemAPI = {
    creatingExpenseReportItem: ({ reportId,relocationId }: Object, lineItemData): Promise<any> => (
        Api.put({
            path: `/expensereport/${relocationId}/${reportId}/lineitems`,
            body: lineItemData,
        })
    ),
    deletingExpenseReportItem: (reportID: Object, lineItemId, reloId): Promise<any> => (
        Api.delete({
            path: `/expensereport/${reloId}/${reportID}/lineitems/${lineItemId}`,
        })
    ),
    gettingExpenseReportItem: (reportID: Object, relocationID): Promise<any> => (
        Api.get({
            path: `/expensereport/${relocationID}/${reportID}/lineitems`,
        })
    )
};

export default ExpenseReportItemAPI;
