// @flow

import React from 'react';
import { ClickableCard, Icon } from 'components/common';

import './style.scss';

type CardData = {
    action: Function,
    icon: string,
    primaryText: string,
    secondaryText: string,
};

type CardsProps = {
    cards: Array<CardData>
};

export default class Cards extends React.PureComponent<CardsProps> {
    render() {
        return (
            <div className="cards-container">
                {
                    this.props.cards.map((card: CardData, i: number) => (
                        <div className="card-wrapper" key={i}>
                            <ClickableCard
                                className="move-card"
                                onClick={card.action}
                                text={<p className="card-text">
                                    <p className="card-secondary-text">{card.secondaryText}</p>
                                    <p className="card-primary-text">{card.primaryText}</p>
                                </p>}
                            >
                                <Icon name={card.icon} width={40} height={40}/>
                            </ClickableCard>
                        </div>
                    ))
                }
            </div>
        );
    }
}
