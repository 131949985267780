// @flow

import * as React from 'react';
import './style.scss';

type TitleWithDescriptionProps = {
    title: string,
    subtitle: string,
    description?: string | React.Node,
    additionalContent?: React.Node,
    styles: Object,
    classNames: string,
}

export default class TitleWithDescription extends React.PureComponent<TitleWithDescriptionProps> {

    static defaultProps = {
        styles: {},
        classNames: ''
    };

    render() {
        const { description } = this.props;
        return (
            <div
                className={`title-with-description flexible vertical jCenter ${this.props.classNames}`}
                style={{...this.props.styles, width: '100%'}}
            >
                <p className='twd-subtitle'>{this.props.subtitle}</p>
                <p className="twd-title body1">{this.props.title}</p>
                { description ?  (typeof description === 'string') ?
                    <p className="twd-description twd-description-text">{description}</p>
                    : <div className="twd-description twd-description-content">{description}</div>
                    : null
                }
                { this.props.additionalContent && <div className="twd-additional-content">{this.props.additionalContent}</div> }
            </div>
        );
    }
}
