// @flow

import * as React from 'react';
import './styles.scss';

type ListProps = {
    className?: string,
    rowHeight?: number,
    height?: number
};

export class List extends React.PureComponent<ListProps> {
    render() {
        const {
            className = '',
            children,
            height
        } = this.props;
        return (
            <div className={`List ${className}`}
                style={{
                    ...(this.props.style || {}),
                    height: height || 'auto'
                }}
            >
                { React.Children.map(children, listItem => (
                    <listItem.type
                        {...listItem.props }
                        style={{
                            ...( listItem.props.style || {} ),
                            height: this.props.rowHeight
                        }}
                    />
                )) }
            </div>
        );
    }
}

export {default as ListItem} from './ListItem';
