import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    SmartFrame,
    TaskCard,
    HelpComponent
} from 'components/common';

import { getUserProfile, getTasks} from 'selectors';

import { tasksService } from 'services/';
import { TasksAPI } from 'api';

import '../../App.scss';
import './style.scss';

const mapStateToProps = ( state ) => ({
    userProfile: getUserProfile(state),
    tasks: getTasks(state),
});

const mapDispatchToProps = {
    fetchTasks: tasksService.actions.fetchTasks
};

class TasksPage extends PureComponent {
    constructor(props) {
        super(props);
        this.onClickTaskCard = this.onClickTaskCard.bind(this);
        this.state = {
            taskId:0,
            title:'',
            linkUrl:'',
            errorText: ''
        };

        if (this.props.userProfile && this.props.userProfile.currentRelocationId)
            this.loadTasks(this.props);
    }

    componentDidMount() {
         if (this.props.userProfile && this.props.userProfile.currentRelocationId)
            this.loadTasks(this.props);
    }
    componentWillReceiveProps(nextProps) {
        if (!this.props.userProfile.currentRelocationId && nextProps.userProfile.currentRelocationId)
            this.loadTasks(nextProps);
    }

    loadTasks(properties) {
        const self= this;
        properties
            .fetchTasks(properties.userProfile.currentRelocationId)
            .then((resp) => self.showFirstTask(resp.result) )
            .catch(e => self.processErrorResponse(e, properties));
    }

    processErrorResponse = (error:any, properties: any):void => {
        console.log('Loading task.Error (currentRelocationId=' + properties.userProfile.currentRelocationId + '; error='+error+')');
        this.setState({
            showBankInfoMessage:false,
            taskId:0,
            title:'',
            linkUrl:'',
            errorText: error.message
        });
    }

    generateTasks = () => (this.props.tasks ?
        this.props.tasks.map(task => {
                return (
                    <TaskCard key={"tk_"+task.launchTaskURL}
                        taskId={task.instanceId}
                        title={task.taskName}
                        dueDate={task.dueDate}
                        linkUrl = {task.launchTaskURL}
                        onSelect={this.onClickTaskCard}
                    />
                )
            }
        ) : null
    )

    showFirstTask(tasks) {
        var task = null;
        if (this.props.match && this.props.match.params.mode === 'editBankInfo') {
            console.log('Try to open BankInfo task');
            const bankInfoTasks = tasks && tasks.length > 0 ? tasks.filter(t => t.taskType === 'BankInfo') : null;
            if (bankInfoTasks && bankInfoTasks.length > 0) {
                task = bankInfoTasks[0];
                console.log('BankInfo Task was found -' + task.taskName);
            }
            else {
                this.setState({
                    showBankInfoMessage:true,
                    taskId:0,
                    title:'BankInfo Task has already completed, please do another task',
                    dueDate:null,
                    linkUrl:''
                });
                return;
            }
        }

        if(tasks && tasks.length > 0) {
            if (!task)
                task = tasks[0];
            this.selectTask(task.instanceId, task.taskName, task.dueDate, task.launchTaskURL);
        }
    }

    onClickTaskCard(taskId, title, dueDate, linkUrl) {
        this.selectTask(taskId, title, dueDate, linkUrl);
    }

    selectTask = (taskId, title, dueDate, linkUrl) => {
        this.setState({
            showBankInfoMessage:false,
            taskId:taskId,
            title:title,
            dueDate:dueDate,
            linkUrl:linkUrl
        });
    }

    generateCompletedBankInformationTask = () => {
        return (
            <section className="bank-info-task">
                <h1 className="header-text" data-key="bi_title">Bank Information task</h1>
                <div>
                    <p className="description-bi">
                        ACH bank account editing has been successfully completed.<br/>
                        If you need to add new ACH account please contact to your move consultant
                    </p>
                    <HelpComponent
                        title="Your Dedicated Relocation Consultant"
                    />
                </div>
            </section>
        )
    };

    render() {
        return (
            <div className="Tasks">
                <div className="titles">
                    <h4>Hi {this.props.userProfile.shortName},</h4>
                    <h3>{
                        this.state.errorText ? 'Sorry, error was detected when tasks were loading from Sirva' : (
                        this.props.tasks &&  this.props.tasks.length > 0
                        ? 'Please complete your relocation tasks'
                        : (this.props.tasks &&  this.props.tasks.length === 0 ? 'You have completed all of your relocation tasks!': 'Please wait until your tasks will be loaded...')
                        )
                    }</h3>
                    <p style={{ marginTop: 10 }}>You may experience a delay up to 60 seconds when accessing your relocation tasks. Hang tight!</p>
                </div>
                { this.state.errorText ? <p>{this.state.errorText}</p> : (
                <div className="flexible">
                    { this.generateTasks() }
                </div>
                )}
                <div className="selectedTaskFrame">
                {
                    this.state.showBankInfoMessage
                    ? this.generateCompletedBankInformationTask()
                    : <SmartFrame title={this.state.title} url={TasksAPI.getSirvaFrameUrl(this.state.linkUrl)} />
                }
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TasksPage));
