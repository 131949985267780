import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { withRouter } from 'react-router-dom';
import { EXTERNAL_LINKS } from 'consts';
import { Icon, Button, QuestionsListCard } from 'components/common';
import Checkbox from '@material-ui/core/Checkbox';

import './style.scss';
import Asset from '../../../../../../../components/common/Asset';

class InitiateAMoveModalWindow extends Component {

  constructor(props) {
    super(props);
    this.openInitiationChecklist = this.openInitiationChecklist.bind(this);
    this.state = {
      isChecked: false,
    };
  }

  openInitiationChecklist = () => {
    const move = this.props.location.pathname.split('/')[2];
    if (move)
      new Asset({assetType:'move-initiation-checklist', assetId:move}).execute()
  }

  toggleCheckbox = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  renderPopup() {
    const { isChecked } = this.state;
    const { isOpened, onToggle } = this.props;

    const buttonProperties = (isChecked)
      ? {
        href: EXTERNAL_LINKS.SirvaConnect,
      }
      : {};

    if (!isOpened) {
      return null;
    }

    return (
      <div className="initiate-a-move-modal-window__container">
        <div className="initiate-a-move-modal-window__background" />
        <div className="initiate-a-move-modal-window">
          <h4 className="initiate-a-move-modal-window__header">
            Initiating a Move
            <div onClick={onToggle}>
              <Icon name="close" width={20} height={20} />
            </div>
          </h4>
          <p className="initiate-a-move-modal-window__description">
            Before you can initiate a move, there are a few things that need to be done and materials you need to gather.
          </p>
          <div className="initiate-a-move-modal-window__checkbox" onClick={this.toggleCheckbox} >
            <Checkbox checked={isChecked} onClick={this.toggleCheckbox} />
            <p>Do you have a Host Position ID or Job Requisition ID?</p>
          </div>
          <QuestionsListCard
            key="request"
            body={(
              <div className="question-parts">
                <div className="question-part__list-container">
                  <strong>Do you have the following ready?</strong>
                  <div className="question-part__list-container__lists">
                    <ul className="question-parts__list">
                      <li>Employee name</li>
                      <li>Job details (new salary, pay grade, job title, manager)</li>
                      <li>Funding/Finance contact</li>
                      <span className="question-parts__link" onClick={this.openInitiationChecklist}>View Initiation Checklist</span>
                    </ul>
                    <ul className="question-parts__list">
                      <li>Home/host HR and manager</li>
                      <li>Assignment guide</li>
                    </ul>
                  </div>
                  <a {...buttonProperties}>
                    <Button
                      className="question-parts__button"
                      title="Continue to SIRVA CONNECT"
                      disabled={!isChecked}
                    />
                  </a>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    );
  }

  reRender = () => {
    const popupRoot = document.querySelector('#popup_root');
    if (popupRoot) {
      ReactDOM.render(this.renderPopup(), popupRoot);
    }
  };

  componentDidUpdate() {
    this.reRender();
  }

  componentDidMount() {
    this.reRender();
  }

  componentWillUnmount() {
    const popupRoot = document.querySelector('#popup_root');
    if (popupRoot) {
      ReactDOM.unmountComponentAtNode(popupRoot);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(InitiateAMoveModalWindow);
