import React, { PureComponent } from 'react';
import './style.scss';

export default class LinkButton extends PureComponent {
    render() {
        const { className = '', text, icon, onClick } = this.props;
        return (
          <div className={`${className} link-button`} onClick={onClick}>
              <p className='link-button__text'>
                  { text }
              </p>
              <img className='link-button__image' src={icon} alt=""/>
          </div>
        );
    }
}
