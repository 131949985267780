import React from 'react';

import './style.scss';

export default class SpinnerWrapper extends React.PureComponent {
    render() {
        const { minHeight } = this.props;
        const styles = this.props.minHeight ? { minHeight } : {}
        return (
            <div
                className="spinner-wrappar"
                style={styles}
            >
                { this.props.show &&
                    <div className="spinner-root">
                        <div className="Spinner" />
                    </div>
                }
                {this.props.children}
            </div>
        );
    }
}
