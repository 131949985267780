// @flow
import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
import type {WithWidth} from '@material-ui/core/withWidth/withWidth';
import type {Breakpoint} from '@material-ui/core/styles/createBreakpoints';

type JnJGridRowProps = {
    className?: string,
    children: React.Node,
    style?: Object
} & WithWidth;

const getSpacing = (width?: Breakpoint) => {
    switch(width) {
        case 'xs':
            return 16;
        case 'sm':
        case 'md':
            return 24;
        default:
            return 32;
    }
}

const JnJGridRow = (props: JnJGridRowProps) => {
    const spacing = getSpacing(props.width);
    return (
        <Grid container className={props.className} spacing={spacing} style={{
            ...props.style,
            marginRight: -(spacing/2),
            marginLeft: -(spacing/2)
        }}>
            {props.children}
        </Grid>
    );
}

export default withWidth()(JnJGridRow);
