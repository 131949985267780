// @flow

import { Api } from 'backend';

const UserAPI = {
    setRole: (user: Object): Promise<any> => Api.post({
        path: `/User/role`,
        body: user
    }),
    getUser: (userID: string): Promise<any> => Api.get({path: `/User/email/${userID}`})
};

export default UserAPI;