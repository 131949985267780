import React, {useState} from "react";
import { Icon } from "components/common";
import ContactMobility3 from "components/common/ContactMobility3";
import { RouterProps } from "types";
import { Asset } from "components/common";
import { useDispatch } from "react-redux";
import { moveInitiationService } from "services";

import "./index.scss";

const JobPolicy = (props: RouterProps) => {
    const dispatch = useDispatch();
    const [continueButtondisabled, setContinueButtondisabled] = useState(false);
    const dataSet = {
        "initiationId": (props.location.state.initiationId !== undefined) ? props.location.state.initiationId : '',
        "empCategoryRepresentation": "NotSet",
        "empProcurementType": "NotSet",
        "homeownerOrRenter": "NotSet",
        "payGrade": (props.location.state.payGradeValue !== undefined) ? props.location.state.payGradeValue : "NotSet",
        "policy": (props.policyName !== undefined) ? props.policyName : "Commuter",
        "strategicIntent": (props.location.state.strategicIntent !== undefined) ? props.location.state.strategicIntent : "NotSet",
        "halfInHomeCountrySpent": false,
        "lengthOfAssignment": (props.location.state.lengthOfAssignment !== undefined) ? props.location.state.lengthOfAssignment : "NotSet",
        "beginingOrEndAssignment": false,
        "endOfAssignmentType": "NotSet",
        "localTalentAvailable": false
    }
    //console.log(dataSet);
    const saveNonCompetitivePackageDetails = (location) => {
        //this.setState({isLoading: true});
        setContinueButtondisabled(true);
        dispatch(moveInitiationService.actions.setNonCompetitivePackageDetails(dataSet)).then(data => {
                //setIsLoading(false);
                setContinueButtondisabled(false);
                if(data.success) {
                    navigateTo(location);
                } else {
                    console.log(data.message);
                }
            })
            .catch(err => console.log(err));
    }

    const navigateTo = (location: string) => {
        switch (location) {
            case 'continueInitiation':
                saveNonCompetitivePackageDetails("yesandcontinue");
                break;
            case 'startover':
                    props.history.push("/initiate-move", {
                        ...props.location,
                        "moveType": props.location.state.moveType,
                        "policySelection": props.location.state.policySelection,
                        "relocationType": (props.location.state.relocationType != null && typeof props.location.state.relocationType !== 'undefined') ? props.location.state.relocationType : null,
                        "eligibleTitle": (props.eligibleTitle != null && typeof props.eligibleTitle !== 'undefined') ? props.eligibleTitle : "Commuter",
                        "strategicIndent": (props.location.state["strategic-indent"] != null && typeof props.location.state["strategic-indent"] !== 'undefined') ? props.location.state["strategic-indent"] : null,
                        "initiationId": (props.location.state.initiationId !== undefined && typeof props.location.state.initiationId !== 'undefined') ? props.location.state.initiationId : null,
                        "initiationCountryCode": (props.location.state.initiationCountryCode !== undefined && typeof props.location.state.initiationCountryCode !== 'undefined') ? props.location.state.initiationCountryCode : null,
                        employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
                    });
                    break;
            case 'yesandcontinue':
                    props.history.push("/initiate-move/employee-job-details", {
                        ...props.location,
                        "moveType": props.location.state.moveType,
                        "policySelection": props.location.state.policySelection,
                        "relocationType": (props.location.state.relocationType != null && typeof props.location.state.relocationType !== 'undefined') ? props.location.state.relocationType : null,
                        "eligibleTitle": (props.eligibleTitle != null && typeof props.eligibleTitle !== 'undefined') ? props.eligibleTitle : "Commuter",
                        "strategicIndent": (props.location.state["strategic-indent"] != null && typeof props.location.state["strategic-indent"] !== 'undefined') ? props.location.state["strategic-indent"] : null,
                        "initiationId": (props.location.state.initiationId !== undefined && typeof props.location.state.initiationId !== 'undefined') ? props.location.state.initiationId : null,
                        "initiationCountryCode": (props.location.state.initiationCountryCode !== undefined && typeof props.location.state.initiationCountryCode !== 'undefined') ? props.location.state.initiationCountryCode : null,
                        employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
                    });
                    break;
        }
    };  

    return (
        <div className="job-policy-container">
            <div className="job-policy">
                <section className="text-section">
                    <div
                        className="back-icon"
                        onClick={() => {
                            props.history.push({
                                state: {
                                    ...props.location.state,
                                    "fifty-percent": "",
                                },
                            });
                        }}
                    >
                        <Icon name="backIcon" />
                        <p>Go back</p>
                    </div>
                    <h1 className="section-title">
                        We’ve matched your job to a policy!
                    </h1>
                    <p className="title">
                        Your move is eligible for: <span>Commuter</span>
                    </p>
                    <section className="horizontal-section">
                        <article>
                            <p className="grey-text">Type of move:</p>
                            <p>Global</p>
                        </article>
                        <article>
                            <p className="grey-text">Strategic intent:</p>
                            <p>
                                {props.location.state["strategic-indent"] || "N/A"}
                            </p>
                        </article>
                        <article>
                            <p className="grey-text">About the assignment:</p>
                            <p>
                                Employee spend at least 50% of their time in their
                                home country.
                            </p>
                        </article>
                    </section>
                    {/* <section className="info-section">
                        <p className="title">What’s it going to cost?</p>
                        <p className="communter-assignment-text">
                            A Commuter Assignment is defined as an assignment for which the employee does not move from the home country to the 
                            host location but instead makes regular visits and spends approximately 50% of the working time in the host country. 
                            A Commuter Assignment is unaccompanied and does not entail a relocation of the family; it is essential that 
                            the employee keeps his/her main residence in the home country.
                        </p>
                        <p className="communter-assignment-help-text">
                            Please contact Mobility Connect for more details on the costs. Please utilize the phone number within your region.
                        </p>
                    </section> */}
                </section>
                {/* <div className="contactmobility-wrapper">
                    <ContactMobility3 />
                </div> */}
                <section className="download-policy-section">
                    {/* <p className="title">What's included?</p>
                    <div
                        className="horizontal-section download"
                        onClick={() => {
                            new Asset({
                                assetType: "global-policy",
                                assetId: "commuter",
                            }).execute();
                        }}
                    >
                        <Icon name="download" />
                        <p>Download Policy for Details</p>
                    </div>
                    <div className="line" />
                    <br /> */}
                    <section className="buttons">
                        <button
                            className="white-button"
                            onClick={() => {navigateTo("startover")}}
                        >
                            <span className="buttonLabel">Start Over</span>
                        </button>
                        <button 
                            className="red-button"
                            disabled={continueButtondisabled}
                            onClick={() => {navigateTo("continueInitiation")}}
                        >
                            <span className="buttonLabel">Continue Initiation</span>
                        </button>
                    </section>
                </section>
            </div>
        </div>
    );
}

export default JobPolicy;
