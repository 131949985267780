import React, { PureComponent } from 'react';
import StepperIcon from './StepperIcon';
import './style.scss';

interface IProps {
    readonly selected: number;
}

class StepLayout extends PureComponent<IProps> {
    public steps = [
        {
            id: 1,
            name: 'Package Selection',
            separatorMargin: 57,
            isActive: false,
            isComplted: false,
        }, {
            id: 2,
            name: 'Employee & Job Details',
            separatorMargin: 45,
            isActive: false,
            isComplted: false,
        }, {
            id: 3,
            name: 'Additional Move Details',
            separatorMargin: 44,
            isActive: false,
            isComplted: false,
        }, {
            id: 4,
            name: 'Finance & Admin',
            separatorMargin: 62,
            isActive: false,
            isComplted: false,
        }, {
            id: 5,
            name: 'Review & Submit',
            isActive: false,
            isComplted: false,
        }
    ];

    public render() {
        const { selected } = this.props;

        let index = 0;
        {this.steps.forEach(el => {     
            if(el.id === this.props.selected) {
                this.steps[index].isActive = true;
                this.steps[index].isComplted = false;
            }
            if(el.id < this.props.selected) {
                this.steps[index].isActive = false;
                this.steps[index].isComplted = true;
            }
            if(el.id > this.props.selected) {
                this.steps[index].isActive = false;
                this.steps[index].isComplted = false;
            }
            index = index + 1
        })}
        const selectedStep = this.steps.find(el => el.id === this.props.selected);
        return (
            <React.Fragment>
                <div className={'move-stepper-web'}>
                    <div className={'flexible jBetween aCenter move-stepper'}>
                        {this.steps.map(el => {
                            return (
                                <div
                                    key={el.id}
                                    className={`flexible aCenter move-step ${el.id === selected ? 'selected' : ''}`}
                                    style={{ paddingRight: el.separatorMargin || 0 }}
                                >
                                    <StepperIcon
                                            isActive={el.isActive}
                                            isComplete={el.isComplted}
                                            index={el.id}
                                    />
                                    <div className={'move-step-title'}>{el.name}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={'move-stepper-mobile move-step flexible jBetween'}>
                    <div className="move-step-title">{selectedStep && selectedStep.name}</div>
                    <div className={'flexible'}>
                        {this.steps.map(el => {
                            return (
                                <div
                                    className={`move-step-id flexible jCenter aCenter ${el.id === selected ? 'selected' : ''}`}
                                    key={el.id}>
                                    {el.id}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default StepLayout;

