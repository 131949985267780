import moment from 'moment';

const getReloDatePath = (move, relo) => {
        
    const dtFormat = "YYYY-MM-DDThh:mm:ssZ";
    const start = relo.assignmentHeader.estimatedStartDate ? moment(relo.assignmentHeader.estimatedStartDate, dtFormat) : null;
    const end = relo.assignmentHeader.estimatedEndDate ? moment(relo.assignmentHeader.estimatedEndDate, dtFormat) : null;
    const today = moment();
    const thirtyDaysAfterStart = relo.assignmentHeader.estimatedStartDate ? moment(relo.assignmentHeader.estimatedStartDate, dtFormat).add(30,'days') : null;
    const threeMonthsBeforeEnd = relo.assignmentHeader.estimatedEndDate ? moment(relo.assignmentHeader.estimatedEndDate, dtFormat).subtract(3,'months') : null;
    if (!start)
        return '/explore';
    if (start.isAfter(today)){
        return '/prep-plan';
    } else if (today.isAfter(start) && today.isBefore(thirtyDaysAfterStart)){
        return move === 'international' ? '/arrival' : '/relocate';
    } else if (today.isAfter(thirtyDaysAfterStart) && threeMonthsBeforeEnd && today.isBefore(threeMonthsBeforeEnd)){
        return move === 'international' ? '/in-host-country' : '/relocate';
    } else if (threeMonthsBeforeEnd && today.isAfter(threeMonthsBeforeEnd)){
        return move === 'international' ? '/journey-back' : '/relocate';
    } else if (today.isAfter(start) && move !== 'international' && !end){
        return  '/relocate';
    } else {
        return '/explore';
    }
};

export default getReloDatePath;
