import React, { PureComponent } from 'react';

import cigna from 'components/common/Images/Cigna.png';

import  Accordion  from 'components/common/Accordion';
import BenefitInfoContent from './BenefitInfoContent';
import { BenefitData } from './BenefitData';

import './style.scss';

export default class ManageYourHealth extends PureComponent{

    getTitleComp = (title, index) => (
        <div className='title flexible horizontal aCenter'>
            <span className="index flexible jCenter aCenter">{ index }</span>
            <p className="title-text">{ title }</p>
        </div>
    );

    generateBenefitData = () => BenefitData.map((benefit, index) => ({
        props: {
            title: this.getTitleComp(benefit.title, index + 1),
            uniqueKey: index,
        },
        content: benefit.content ? <BenefitInfoContent {...benefit.content} /> : null
    }));

    render() {
        return(
            <div className="ManageYourHealth">
                <div className="text-block flexible aStart">
                    <div className="text">
                        <p>Johnson & Johnson partners with CIGNA Global Health Benefits to ensure assignees and their families receive healthcare coverage while on a global assignment. </p>
                        <p>CIGNA Global Health Benefits provides access to broad worldwide networks of doctors and hospitals in more than 205 countries and jurisdictions totaling more than 147,000 health care professionals worldwide, and 550,000 in the United States. </p>
                    </div>
                    <div className="image" style={{ backgroundImage: `url(${cigna})` }}/>
                </div>
                <div className="BenefitInfo">
                    <Accordion
                        data={this.generateBenefitData()}
                    />
                </div>
            </div>
        )
    }
}