import React, { useEffect, useState } from "react";
import "./styles.scss";
import Select from "./Select";
import { History } from "history";
import { Icon, Button, SpinnerWrapper, Selects, Input } from "components/common";
import Tooltip from "@material-ui/core/Tooltip";
import { Prompt } from "react-router";
import { getCaliforniaTiers } from "selectors";
import { connect } from "react-redux";
import { moveInitiationService } from "services";
import { useDispatch } from "react-redux";
import { makeStyles, useMediaQuery } from "@material-ui/core";

const isObj = (obj: any) => obj !== undefined && Object.values(obj).length > 0;

const isObjParam = (obj: object, key: string) => isObj(obj) 
    ? obj[key] != null && typeof obj[key] !== undefined  
        ? obj[key] : null 
            : null;

const toolTipStyle = makeStyles(theme => ({
    toolTip: {
        backgroundColor: theme.palette.background.default,
    },
    popper: {
        opacity: 1,
        pointerEvents: `all`,
        '& span.Icon': {
            cursor: 'pointer'
        },
        '& h4': {
            fontSize: 28,
        },
        '& div.tier': {
            marginTop: 20,
            fontSize: 14,
            fontWeight: 3,

            '&:first-of-type': {
                marginTop: 32,
            },

            '& span': {
                fontWeight: 900,
                display: `block`
            }
        }
    }
}))

const mapStateToProps = store => ({
    californiaTiers: getCaliforniaTiers(store),
});
const mapDispatchToProps = {
    fetchCaliforniaTiers: moveInitiationService.actions.fetchCaliforniaTiers,
};
const  AdditionalDetails = (props: {
    californiaTiers? : object;
    navigateTo: (to: string, from: string) => void;
    history: History;
    location: Location;
} & { fetchCaliforniaTiers }) => {
               
    const { state: locationState } = props.history.location;

    const calMoveTierArr = {
        "Tier 1: California Market": "Tier1CaliforniaMarket", 
        "Tier 2: Critical Skills Position": "Tier2CriticalSkillsPosition", 
        "Tier 3: Business Critical Role": "Tier3BusinessCriticalRole"
    }

    const californiaPositionTire = {
        "ca": "california",
        "california": "california" 
    }

    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        defaultValue: null,
        startDate: null,
        endDate: null,
        buttonStatus:"disabled",
        disableButton:"disable",
        relocationState: isObjParam(locationState, "eligibleTitle"),
        startLabel: '',
        endLabel: '',
        californiaTier: isObjParam(locationState, "californiaTier"),
        californiaTiers: [],
        valueInput: isObjParam(locationState, "valueInput"),
        openTooltip: false,
        numberOfFlights: isObj(locationState) ? (locationState.numberOfFlights != null && 
            typeof locationState.numberOfFlights !== 'undefined') ? 
                locationState.numberOfFlights : '' : '',
        additionalDetails: isObjParam(locationState, "additionalDetails"),
        isReqGoingCaliforniaState: isObj(locationState) ? (locationState.reqInfoRepChildEle != null && 
            typeof locationState.reqInfoRepChildEle.State !== 'undefined') ? 
                locationState.reqInfoRepChildEle.State : null : null,
        closeModal: '',
        options: [0,1,2,3,4,5],
        jobDetailsBackState: isObjParam(locationState, "jobDetailsBackState"),
        isLoading: false,
        calMoveTier: calMoveTierArr,
        isCaliforniaState: null,
    });

    useEffect(() => { //  Idle handler
        if(!isObj(locationState)) {
            props.history.replace("/move-dashboard");
        }
    }, [locationState]);

    useEffect(() => { 
        if(props.californiaTiers !== null) {
            setState(prevState => ({
                ...prevState,
                californiaTiers: Object.values(props.californiaTiers),
            }));
        }
    }, [props.californiaTiers]);

    const handleInputChange = (propName, handler) => ({ type: string, target, ...eventRest }, ...argsRest) => {
        let value = target ? target.value : Object.values(eventRest).join("");
        const {relocationState} = state;

        if (propName === "numberOfFlights") {
            if (relocationState == "Commuter"){
                 setState(prevState =>({...prevState, numberOfFlights: value }));
            }else
            {
                setState(prevState =>({...prevState, numberOfFlights: null }))
            }
        }
        if (propName === "additionalDetails") {
            setState(prevState =>({...prevState, additionalDetails: value }));
        }
        if (propName === "valueInput") {
            setState(prevState =>({...prevState, valueInput: value }));
         }
         if (propName === "californiaTier") {
            setState(prevState =>({...prevState, californiaTier: value }));
         }
    };
    
    const [continueButtondisabled, setContinueButtondisabled] = useState(true);
    // const [isLoading, setIsLoading] = useState(false);
    const {buttonStatus,valueInput, relocationState,californiaTier, disableButton,
         startDate, endDate, numberOfFlights, additionalDetails, closeModal,startLabel, endLabel, 
         options, jobDetailsBackState, isLoading,isCaliforniaState, isReqGoingCaliforniaState} = state;

    useEffect(() => {
        if(valueInput !== null && valueInput !== undefined && valueInput !== ""){
            continueButtondisabled && setContinueButtondisabled(false)
        } else {
            !continueButtondisabled && setContinueButtondisabled(true)
        }
    },[valueInput])

    useEffect(() => {
        props.fetchCaliforniaTiers();
        if(locationState !== undefined) {
            // moveInitiationService.actions.fetchCaliforniaTiers();
            if (relocationState == "Commuter"){
                if(numberOfFlights !== ''){
                   setState(prevState => ({ ...prevState, buttonStatus: "", disableButton: ""}));
                }
            }else{
                if(numberOfFlights !== ''){
                   setState(prevState => ({ ...prevState, buttonStatus: "", disableButton: ""}));
                }
            }
   
           let positionLocation = null;
           let goingToCity = (locationState.reqInfoRepChildEle.City != null && 
               typeof locationState.reqInfoRepChildEle.City !== 'undefined') ? 
                               (locationState.reqInfoRepChildEle.City).toLowerCase() : null;
           let goingToState = (locationState.reqInfoRepChildEle.State != null && 
               typeof locationState.reqInfoRepChildEle.State !== 'undefined') ? 
                                   (locationState.reqInfoRepChildEle.State).toLowerCase() : null;
           let goingToCountry = (locationState.reqInfoRepChildEle.Country != null && 
               typeof locationState.reqInfoRepChildEle.Country !== 'undefined') ? 
                                   (locationState.reqInfoRepChildEle.Country).toLowerCase() : null;
   
           if(positionLocation === null && goingToCity !== null && 
               californiaPositionTire[goingToCity] === "california") {
               positionLocation = "california";
           }else if(positionLocation === null && goingToState !== null && 
               californiaPositionTire[goingToState] === "california") {
               positionLocation = "california";
           }else if(positionLocation === null && goingToCountry !== null && 
               californiaPositionTire[goingToCountry] === "california") {
               positionLocation = "california";
           }
           setState(prevState => ({ ...prevState, isCaliforniaState:positionLocation}));
        }
    }, []);

    // const handleInputTextChange = ({
    //     target,
    // }: React.ChangeEvent<HTMLSelectElement>) => {
    // setState(prevState =>({...prevState, valueInput: target.value }));
    // };
  
    //   const disableContinueBtnHandler = (continueButtondisabled: boolean): void =>
    //   setContinueButtondisabled(continueButtondisabled);

      const renderCaliforniaTooltip = () => {
        return (
            <React.Fragment>
                <Icon
                    name={"closeGray"}
                    width={15}
                    className={"flexible jEnd"}
                    onClick={toggleTooltip}
                />
                <h4>About California Tiers</h4>
                <div className={"tier"}>
                    <span>Tier 1 (California Market)</span>
                    For standard relocations into/within California.
                </div>
                <div className={"tier"}>
                    <span>Tier 2 (Critical Skills Position)</span>
                    Positions with unique skills needed within an occupation
                    which typically make the skills niche with a limited talent
                    supply and competition for talent is very competitive.
                </div>
                <div className={"tier"}>
                    <span>Tier 3 (Business Critical Role) </span>
                    Positions absolutely essential to the achievement of
                    long-term strategic business priorities through their
                    strategic impact and/or contribution of unique capabilities.
                </div>
            </React.Fragment>
        );
    }
    const toggleTooltip = () => {
        setState(prevState =>({...prevState, openTooltip: !prevState.openTooltip }));
    };
    useEffect(() => {
        enableContinue();
    }, [relocationState, isCaliforniaState]);
    
    const enableContinue = () => {
         if (relocationState == "Commuter" && isCaliforniaState == "california"){
             if(numberOfFlights !== '' && californiaTier !== '' && numberOfFlights !== null && californiaTier !== null){
                setState(prevState =>({...prevState, buttonStatus: "", disableButton: "" }));
             }
         } else { 
             if (relocationState == "Commuter" && isCaliforniaState !== "california"){
                if (numberOfFlights !== '' && numberOfFlights !== null){
                    setState(prevState =>({...prevState, buttonStatus: "", disableButton: "" }));
                 }
              } else {
                if (relocationState !== "Commuter" && isCaliforniaState == "california"){
                    if (californiaTier !== '' && californiaTier !== null){
                        setState(prevState =>({...prevState, buttonStatus: "", disableButton: "" }));
                    }
                } else {
                    setState(prevState =>({...prevState, buttonStatus: "", disableButton: "" }));
                }
            }
        }  
    }
    const additionalDetailsChange = ({target}: React.ChangeEvent<HTMLSelectElement>) => {
        setState(prevState =>({...prevState, additionalDetails: target.value }));
    }
   
    // const closeModal = () => {
    //     setState(prevState =>({...prevState, closeModal: "close" }));
    // }

    // const handlePageRedirect = (e) => {
    //     e.preventDefault();
    //     props.history.push('/contact-us');
    // }
    
    const handleDropdownChange = ({
        target,
    }: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
        setState(prevState =>({...prevState, californiaTier: target.value }));
    };
    
    const saveAdditionalMoveDetails = (location: string) => {
        setState(prevState =>({...prevState, isLoading: true, buttonStatus: "disabled", disableButton: "disable" }));
        
        // setState({isLoading: true, buttonStatus: "disabled", disableButton: "disable"});
        let { additionalDetails, californiaTier, valueInput, numberOfFlights, calMoveTier} = state;
        let californiaTierValue = (calMoveTier[californiaTier] !== undefined) ? 
        calMoveTier[californiaTier] : californiaTier;
       
        dispatch(moveInitiationService.actions.setMoveAdditionalMoveDetails({
            "initiationId": (locationState.initiationId !== undefined) ? locationState.initiationId : '',
            "californiaTier": (californiaTierValue !== null) ? californiaTierValue : "NotSet",
            "newSalary": (valueInput !== null) ? valueInput : null,
            "specialInstruction": (additionalDetails !== null) ? additionalDetails : null,
            "commuterFlightsPerMonth": (numberOfFlights !== '' && numberOfFlights !== undefined) ? numberOfFlights : 0, 
        }))
        .then(data => {
        setState(prevState =>({...prevState, isLoading: false, buttonStatus: "", disableButton: "" }));
            if(data.success) {  //  finance
                // navigateTo(location);
            } else {
                console.log(data.message);
            }
        })
        .catch(console.log)
        .finally(() => {
            if(location !== undefined && location !== '')
                props.history.push(location);
        })
    }

    const toolTipClasses = toolTipStyle();
    const scrnLg = useMediaQuery('(min-width:1280px)', { noSsr: true });

    const renderMiddleContent = () => {
        
        return (      
            <>
                <h1 style={{lineHeight: "44px"}}>Ok, we need to collect a little more information about this move.</h1>
                <SpinnerWrapper show={isLoading}>
                {/* <div className="eligible-container RecruitedSelector"> */}
                <Prompt
                    message={location =>
                        location.pathname.startsWith("/contact-us")
                        ? `You are about to leave this page, your changes will not be saved!`
                        : true
                    }
                />
                <section className="text-section" style={{ paddingTop: 35 }}>
                    {isCaliforniaState == "california" ? (   
                    <h5 className={"california-title"}>
                        For California moves, we need to know which tier should be
                        used.
                    </h5>) : null}
                {isCaliforniaState == "california" ? (         
                <div className={"flexible aCenter"} style={{ paddingBottom: 35 }}>
                    <Select
                        style={{ width: 250 }}
                        loading={!state.californiaTiers.length}
                        label={"Select applicable CA tier"}
                        onChange={handleDropdownChange}
                        value={californiaTier}
                        name={"californiaTier"}
                        options={state.californiaTiers}
                    />
                    <Tooltip
                        title={renderCaliforniaTooltip()}
                        PopperProps={{ disablePortal: true }}
                        open={state.openTooltip}
                        placement={"right"}
                        classes={{
                            popper: toolTipClasses.popper,
                            tooltip: toolTipClasses.toolTip
                        }}
                    >
                        <div
                            style={{ marginLeft: 14, paddingTop: 20 }}
                            onTouchStart={toggleTooltip}
                            onMouseEnter={toggleTooltip}
                        >
                            <Icon name={"infoBlue"} />
                        </div>
                    </Tooltip>
                </div>
                 ) : null}
                {relocationState == "Commuter" ? (

                <p className="title">
                    Please indicate the number of flights per month for this commuter assignment.
                </p>
                ) : null}
                {relocationState == "Commuter" ? ( 
                <section className="option-select-container" style={{ paddingBottom: 50 }}>
                <Selects
                    style={{ width: 250 }}
                    type="options"
                    label={"Select an option"}
                    value={numberOfFlights}
                    onChange={handleInputChange("numberOfFlights",null)}
                    options={options}
                    name={"leadership"}
                />
                </section>
                ) : null}
                <div style={{ marginTop: 20 }} />
                <h5 className={"base-title"}>
                    New Base Salary
                </h5>
                <div className="Input">
                    <Input 
                        name="valueInput"
                        value={valueInput} 
                        maxLength={10}
                        style={{ borderBottom: "2px solid black" , width: 150}}
                        onChange={event => {
                            setState(prevState =>({...prevState, 
                            valueInput: event.target.value.replace(/\D/,'') }))
                    }}/>
                 </div>
                 <div style={{ paddingTop: 55 }} />

                 <p className="title">
                        Would you like to add any additional details or special instructions?
                    </p>
                    <section className="input-select-container" style={{ maxWidth: 500 }} >
                        <Input 
                            placeholder="Enter additional details if needed"
                            maxlength="500"
                            value={additionalDetails}
                            onChange={additionalDetailsChange}
                            style={{ borderBottom: "2px solid black"}}
                        />
                    </section>

                </section>
                <hr className="line" style={{ marginTop: 35 }} />
                <section className="button-section"><div className={"flexible"}  style={{ justifyContent: 'space-between' }}>
                    <Button
                        title="Go Back"
                        className="job-details-submit"
                        style={{backgroundColor:"#FFFFFF", border:"1px solid #CA001B", minWidth: 150 }}
                        labelStyle={{color:"#CA001B"}}
                        onClick={() => {
                            props.navigateTo("emp-job-details", "additional-move-details")
                        }}
                    />
                    <Button style={{ minWidth: 150 }}
                        onClick={() => {
                            saveAdditionalMoveDetails('');
                            props.history.push({
                                state: {
                                    ...props.history.location.state,
                                    startDate, endDate, numberOfFlights, additionalDetails, californiaTier, valueInput
                                }
                            });
                            props.navigateTo("finance-admin", "additional-move-details")
                        }}
                        title="Continue"
                        className="job-details-submit"
                        disabled={continueButtondisabled}
                        submit={true}
                    />
                </div>
                </section>
                {/* </div> */}
                </SpinnerWrapper>
            </>
        );
    }

    return (
            <div className={"employee-job-details-container-nc"} style={{ 
                width: scrnLg ? 773 : 'initial'
               }}>
                {renderMiddleContent()}
            </div>
        );
    }
// }

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalDetails);