import React from 'react';
import { Asset } from "components/common";

import './styles.scss';

export default class AccessDenied extends React.PureComponent {
    render() {
        return (
            <div className="access-denied">
                <div className="flexible jBetween access-denied-wrapper">
                    <div className="access-denied-content flexible jCenter">
                        <div>
                            <h1>You don't have access, yet!</h1>
                            <p>
                                You will be granted access to move.jnj.com once we have received your signed relocation offer.
                                We can't wait to have you join us and be a part of your journey. If you have any questions or
                                concerns, please reach out to your relocation specialist.
                            </p>
                        </div>
                    </div>
                    <div className="access-denied-img">
                        <Asset assetType="ACCESS_DENIED_PAGE" assetId={'cover-photo'}/>
                    </div>
                </div>
            </div>
        );
    }
}
