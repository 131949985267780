import * as React from "react";
import { SelectionCard } from "components/common";
import { Icon } from "components/common";
import { RouterProps } from "types";
import HomeownerPackageCNonManager from "../EligibleMove/HomeownerPackageCNonManager";
import RenterPackageC from "../EligibleMove/RenterPackageC";
import "./style.scss";

const Fldp = (props: RouterProps) => {
    const employeeType = [
        {
            id: 1,
            key: "homeowner",
            title: "Homeowner",
            value: "Homeowner"
        },
        {
            id: 2,
            key: "renter",
            title: "Renter",
            value: "Renter"
        }
    ];
    const clickHandler = (key: string, title: string, value: string): void =>
    props.history.push({
        state: {
            ...props.location.state,
            "employeeTypeKey": key,
            "employeeTypeTitle": title,
            "homeownerOrRenter": value,
        },
    });

    const backHandler = (moveType,policySelection): void =>
                    props.history.push({
                        state: {
                            "moveType": moveType,
                            "policySelection": policySelection,
                            "procurementKey": "",
                            "policyType": "LDP",
                            "reasonSelect": (props.location.state.reasonSelect !== undefined) ? props.location.state.reasonSelect : null,
                            "empCategoryRepresentation": (props.location.state.empCategoryRepresentation !== undefined) ? props.location.state.empCategoryRepresentation : null,
                            "initiationId": (props.location.state.initiationId !== undefined) ? props.location.state.initiationId : null,
                            "initiationCountryCode": (props.location.state.initiationCountryCode !== undefined) ? props.location.state.initiationCountryCode : null,
                            employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
                        },
                    });
    if (
        props.location.state &&
        props.location.state["employeeTypeKey"] &&
        props.location.state["employeeTypeKey"] === "homeowner"
    ) {
        return <HomeownerPackageCNonManager history={props.history} location={props.location} backState={"employeeTypeKey"}/>;
        
    } else if (
        props.location.state &&
        props.location.state["employeeTypeKey"] &&
        props.location.state["employeeTypeKey"] === "renter"
    ) {
        return <RenterPackageC history={props.history} location={props.location} backState={"employeeTypeKey"}/>;
    } else {
        const { moveType, policySelection } = props.location.state;
        return (
            <article className="domestic-scenario-wrapper">
                <div className="back-icon" onClick={() => backHandler(moveType,policySelection)} >
                    <Icon name="backIcon" />
                    <p>Go back</p>
                </div>
                <h1 className="section-title">
                    Is the employee a homeowner or renter?
                </h1>
                <div className={"reasons flexible wrap jBetween"}>
                        {employeeType.map(data => (
                            <div
                                key={data.id}
                                className={"reason"}
                                onClick={() =>
                                    clickHandler(data.key, data.title, data.value)
                                }
                            >
                                <p className="reason-info">{data.title}</p>
                            </div>
                        ))}
                    </div>
            </article>
        );
    }
};

export default Fldp;
