import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserProfile } from 'selectors';
import { MidSectionComponent, Link } from 'components/common';

const mapStateToProps = (state) => ({
  userProfile: getUserProfile(state),
});

class DomRelocateStep extends Component {

  render() {
    return (
      <div>
        {this.props.userProfile.isOneWayCurrentRelocation 
        ? (<MidSectionComponent title = "About Your Benefits" className="DomRelocateStep" col1={
            <div className="flexible vertical">
            <p>You will be enrolled in the host country benefits plan for items such as Social Security, Retirement, Pension, Medical. Your home benefits will be discontinued. </p>
            </div>
            }  
        />)
        : (<MidSectionComponent title = "Have you completed all of your relocation tasks?" className="DomRelocateStep" col1={
          <div className="flexible vertical">
          <p>For the efficient initiation and scheduling of relocation services, it is critical that you complete all the required relocation tasks identified by SIRVA, the relocation management company facilitating your move.   Delays in providing the information needed by SIRVA may result in unnecessary complications in the delivery of your relocation services.</p>

          <Link text="Complete Relocation Tasks " onClick={() => window.open('/tasks')}/>
          </div>
          }  
      />)}        
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, null)(DomRelocateStep));