import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import {
    userService,
    relocationsService,
    assignmentHeaderService,
    taxMilestonesService,
} from "services";
import { DropDown } from "components/common";
import { USER_ROLES } from "consts";
import { clearSessionStorage, getReloDatePath } from "utils";
import JAJLogo from "components/common/Images/jnj-logo.svg";

import {
    getUserProfile,
    getUserAuth,
    getRelocations,
    getAssignmentHeader,
    getTasks,
    getIsCompetetive,
} from "selectors";
import { international, domestic } from "./moveData";

import "./style.scss";

import { FeedbackAPI } from "api";
import { FastFeedback } from "components/sections/";

const mapStateToProps = state => ({
    user: getUserProfile(state),
    auth: getUserAuth(state),
    relocations: getRelocations(state),
    assignmentHeader: getAssignmentHeader(state),
    tasks: getTasks(state),
    isCompetetive: getIsCompetetive(state),
});

const mapDispatchToProps = {
    getRelocationsWithHeaders:
        relocationsService.actions.getRelocationsWithHeaders,
    getAssignmentHeader: assignmentHeaderService.actions.getAssignmentHeader,
    getTaxMilestones: taxMilestonesService.actions.getTaxMilestones,
    ...userService.actions,
};

const moveType = {
    global: "Global",
    domestic: "Domestic",
};

class Header extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    state = {
        isOpen: false,
        move: null,
        displayFastFeedback: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            move: null,
            isHr: null,
        };

        this.loadUserState(this.props);
    }

    componentDidMount() {
        this.fetchFastFeedbackResult();
        this.fetchUserRelocations(this.props.user);
        this.loadUserState(this.props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.user.currentRelocationId && !nextState.move) {
            this.setState({
                move: nextProps.user.currentRelocationIsDomestic
                    ? "domestic"
                    : "international",
            });
        }
        return true;
    }

    componentWillReceiveProps(nextProps) {
        if (
            (this.props.user.currentRelocationId !==
                nextProps.user.currentRelocationId ||
                this.props.user.role !== nextProps.user.role) &&
            nextProps.user.role
        ) {
            this.setState({
                move: nextProps.user.currentRelocationIsDomestic
                    ? "domestic"
                    : "international",
            });
            this.loadFastFeedFlag(nextProps.user.currentRelocationId);
            this.loadUserState(nextProps);
        }
        if (!this.props.user.email && nextProps.user.email) {
            this.fetchUserRelocations(nextProps.user);
        }

        const { assignmentHeader } = nextProps;

        if (assignmentHeader !== this.props.assignmentHeader) {
            if (
                assignmentHeader.homeCountry &&
                assignmentHeader.homeCountry.isoCode &&
                assignmentHeader.hostCountry &&
                assignmentHeader.hostCountry.isoCode
            ) {
                this.props.getTaxMilestones(
                    assignmentHeader.homeCountry.isoCode,
                    assignmentHeader.hostCountry.isoCode,
                );
            }
        }
    }

    fetchUserRelocations = userAccount => {
        if (userAccount.email) {
            this.props.getRelocationsWithHeaders(userAccount.email);
        }
    };

    fetchFastFeedbackResult() {
        if (this.props.user.currentRelocationId) {
            FeedbackAPI.displayFeedback(
                this.props.user.currentRelocationId,
            ).then(data => {
                this.setState({
                    displayFastFeedback: data.result,
                });
            });
        }
    }

    loadUserState(properties) {
        if (
            properties.user &&
            (properties.user.currentRelocationId || properties.user.role)
        ) {
            const isHrRole = properties.user.role === USER_ROLES.hiring_manager;
            this.setState({
                move: properties.user.currentRelocationIsDomestic
                    ? "domestic"
                    : "international",
                isHr: isHrRole,
            });
        }
    }

    loadFastFeedFlag(currentRelocationId) {
        if (!currentRelocationId) return null;
        const url = window.location.href;
        if (url.includes("?feedback=true")) {
            this.setState({
                displayFastFeedback: true,
            });
        } else {
            // TODO: this should be moved to the action and the data should be stored in the reducer
            FeedbackAPI.displayFeedback(currentRelocationId).then(data => {
                this.setState({
                    displayFastFeedback: data.result,
                });
            });
        }
    }

    navbarToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    dropDownOnchange = info => {
        this.setState({ isOpen: !this.state.isOpen });
        if (this.state.isHr && !info.skipIsHr) {
            this.props.history.push(`/profile/${info.key}/explore`);
        } else {
            this.props.history.push(`/${info.key}`);
        }
    };

    onViewDropDownChange = info => {
        const role =
            info.key === "peopleLeader"
                ? USER_ROLES.hiring_manager
                : USER_ROLES.employee;

        this.setState({ isOpen: false });

        const pendingState = document.getElementById("pending");

        pendingState.classList.add("show");

        this.props
            .setRole({
                email: this.props.user.email,
                role,
            })
            .then(data => {
                if (role === USER_ROLES.hiring_manager) {
                    this.props.history.push("/profile");
                } else {
                    if (this.props.user.currentRelocationId) {
                        this.redirectToStep(
                            this.props.user.currentRelocationId,
                        );
                    } else {
                        this.props.history.push("/your-moves");
                    }
                }

                pendingState.classList.remove("show");
            });
    };

    redirectToStep(relocationId) {
        this.props.getAssignmentHeader(relocationId).then(res => {
            if (res && res.result) {
                const userProfile = res.result;

                if (
                    userProfile.homeCountry &&
                    userProfile.homeCountry.isoCode &&
                    userProfile.hostCountry &&
                    userProfile.hostCountry.isoCode
                ) {
                    this.props.getTaxMilestones(
                        userProfile.homeCountry.isoCode,
                        userProfile.hostCountry.isoCode,
                    );
                }
                const ah = { assignmentHeader: res.result };
                const moveType = this.props.user.currentRelocationIsDomestic
                    ? "domestic"
                    : "international";
                this.props.history.push(
                    "/profile/" + moveType + getReloDatePath(moveType, ah),
                );
            } else {
                this.props.history.push("/profile/international/explore");
            }
        });
    }

    signOut = () => {
        window.localStorage.clear();
        clearSessionStorage();
        //window.sessionStorage.clear();
        userService.userManager.signoutRedirect({
            id_token_hint: userService.userManager.id_token_hint,
        });
        userService.userManager.removeUser();
    };

    toggleHeader = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    getEmployeeMenuItems = () => {
        let moves = international;
        if (this.state.move === "domestic") {
            moves = domestic;

            if (this.props.tasks && this.props.tasks.length) {
                moves = moves.map(el => {
                    if (el.key !== "tasks") {
                        return el;
                    }

                    el.count = this.props.tasks.length;

                    return el;
                });
            }
        }

        if (this.props.user.isOneWayCurrentRelocation) {
            moves = international.filter(move => move.key !== "benefit-info");
        }
        if (
            Array.isArray(this.props.relocations) &&
            this.props.relocations.length <= 1
        )
            moves = moves.filter(move => move.key !== "your-moves");

        return moves;
    };

    render() {
        const {
            className,
            location: { pathname },
            user,
        } = this.props;
        const filterRoutes = ["page-unavailable", "identification/reset-role"];
        const filterHeader = filterRoutes.some(el => pathname.includes(el));

        if (this.props.isCompetetive) {
            return (
                <header className={`Header ${className}`}>
                    <section className={"flexible horizontal jBetween aCenter"}>
                        <aside className={`flexible horizontal aCenter`}>
                            <NavLink to="/">
                                <img src={JAJLogo} alt="" />
                                <span>TALENT MOBILITY</span>
                            </NavLink>
                        </aside>
                        <React.Fragment>
                            <span
                                className={`navbar flexible vertical ${
                                    this.state.isOpen ? "close" : ""
                                } ${!this.props.logged ? "" : "hide"}`}
                                onClick={this.navbarToggle}
                            >
                                <span className="line" />
                                <span className="line" />
                                <span className="line" />
                            </span>
                            <nav
                                className={`flexible grow competetiveContainer ${
                                    this.state.isOpen ? "" : "close"
                                }`}
                            >
                                <ul className="flexible horizontal aCenter">
                                    <li>
                                        <div className={"competetiveItem"}>
                                            <span>Services:</span> Full
                                            Relocation
                                        </div>
                                    </li>
                                    <li>
                                        {this.props.location.state !==
                                            undefined &&
                                        this.props.location.state
                                            .empCompetitiveInit !== undefined &&
                                        (this.props.location.state
                                            .empCompetitiveInit.candidate
                                            .firstName !== null ||
                                            this.props.location.state
                                                .empCompetitiveInit.candidate
                                                .lastName !== null) ? (
                                            <div className={"competetiveItem"}>
                                                <span>Employee:</span>{" "}
                                                {
                                                    this.props.location.state
                                                        .empCompetitiveInit
                                                        .candidate.firstName
                                                }{" "}
                                                {
                                                    this.props.location.state
                                                        .empCompetitiveInit
                                                        .candidate.lastName
                                                }
                                            </div>
                                        ) : null}
                                    </li>
                                    <li>
                                        {this.props.location.state !==
                                            undefined &&
                                        (!(
                                            "moveType" in
                                            this.props.location.state
                                        ) ||
                                            this.props.location.state
                                                .moveType === undefined ||
                                            this.props.location.state
                                                .moveType === " " ||
                                            this.props.location.state
                                                .moveType === null) ? null : (
                                            <div className={"competetiveItem"}>
                                                {this.props.location.state !==
                                                undefined ? (
                                                    <>
                                                        <span>
                                                            Global Move:
                                                        </span>{" "}
                                                        {
                                                            moveType[
                                                                this.props
                                                                    .location
                                                                    .state
                                                                    .moveType
                                                            ]
                                                        }
                                                    </>
                                                ) : null}
                                            </div>
                                        )}
                                    </li>
                                    <li>
                                        <NavLink
                                            className={"competetiveBack"}
                                            to={"/"}
                                            exact={true}
                                        >
                                            Back to Home
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                        </React.Fragment>
                    </section>
                </header>
            );
        }

        return (
            <header className={`Header ${className}`}>
                <section className="flexible horizontal jBetween aCenter">
                    <aside
                        className={`flexible horizontal aCenter ${
                            this.props.logged ? "" : "grow"
                        }`}
                    >
                        <NavLink to="/">
                            <img src={JAJLogo} alt="" />
                            <span>TALENT MOBILITY</span>
                        </NavLink>

                        {/* {!filterHeader && user && user.role && !this.props.isJibeUnassignedUser && !user.isJibeUser ? (
                            <DropDown
                                data={changeViewMenu}
                                dataKey="header_view_toggle"
                                isInFixedContent
                                withCloseButton
                                optionsClassName="view_toggle"
                                optionsStyles={{
                                    top: '77px',
                                    left: 'inherit'
                                }}
                                defaultSelected={this.props.user.role === USER_ROLES.hiring_manager ? 'peopleLeader' : 'employee'}
                                onChange={this.onViewDropDownChange}
                            />
                        ) : null} */}
                    </aside>
                    {!filterHeader ? (
                        <React.Fragment>
                            <span
                                className={`navbar flexible vertical ${
                                    this.state.isOpen ? "close" : ""
                                } ${!this.props.logged ? "" : "hide"}`}
                                onClick={this.navbarToggle}
                            >
                                <span className="line" />
                                <span className="line" />
                                <span className="line" />
                            </span>
                            <nav
                                className={`${
                                    this.state.isOpen ? "" : "close"
                                }`}
                            >
                                {user.email && this.state.isHr ? (
                                    <ul className="flexible horizontal aCenter">
                                        {/* <li>
                                            <DropDown
                                                data={hrmenu}
                                                dataKey="header_my_moves"
                                                onChange={this.dropDownOnchange}
                                                staticLabel="About Moves"
                                                isInFixedContent
                                                withCloseButton
                                                optionsClassName="moves"
                                                optionsStyles={{
                                                    top: '77px',
                                                    left: 'inherit',
                                                }}
                                            />
                                        </li> */}
                                        {window.location.search.includes(
                                            "newHeader=true",
                                        ) ? (
                                            <li>
                                                <NavLink
                                                    data-key="header_initiate_a_move"
                                                    to="/initiate-move"
                                                    activeClassName="active"
                                                    onClick={() =>
                                                        this.toggleHeader()
                                                    }
                                                >
                                                    Initiate a Move
                                                </NavLink>
                                            </li>
                                        ) : null}
                                        {/* <li>
                                            <NavLink
                                                data-key="header_resources"
                                                to="/resources"
                                                activeClassName="active"
                                                onClick={this.toggleHeader}
                                            >Tools & Resources</NavLink>
                                        </li> 
                                        <li>
                                            <NavLink
                                                data-key="header_contact_list"
                                                to="/contact-us"
                                                activeClassName="active"
                                                onClick={this.toggleHeader}
                                            >Help</NavLink>
                                        </li> */}
                                        <li
                                            data-key="header_log_out"
                                            to="/Logout"
                                            className="flexible horizontal aCenter log_out"
                                        >
                                            <span onClick={this.signOut}>
                                                Log Out
                                            </span>
                                        </li>
                                        {/* {user && user.role && !this.props.isJibeUnassignedUser && !user.isJibeUser ? (
                                            <li className="view_selector">
                                                <hr/>
                                                <DropDown
                                                    data={changeViewMenu}
                                                    dataKey="header_view_toggle"
                                                    isInFixedContent
                                                    withCloseButton
                                                    optionsClassName="view_toggle"
                                                    optionsStyles={{
                                                        top: '77px',
                                                        left: 'inherit'
                                                    }}
                                                    defaultSelected={this.props.user.role === USER_ROLES.hiring_manager ? 'peopleLeader' : 'employee'}
                                                    onChange={this.onViewDropDownChange}
                                                />
                                            </li>
                                        ) : null} */}
                                    </ul>
                                ) : user.email && !user.isJibeUnassignedUser ? (
                                    <ul className="flexible horizontal aCenter">
                                        {user.currentRelocationId ? (
                                            <li>
                                                <DropDown
                                                    data={this.getEmployeeMenuItems()}
                                                    dataKey="header_my_moves"
                                                    onChange={
                                                        this.dropDownOnchange
                                                    }
                                                    staticLabel="My Move"
                                                    isInFixedContent
                                                    withCloseButton
                                                    optionsClassName="moves"
                                                    optionsStyles={{
                                                        top: "77px",
                                                        left: "inherit",
                                                    }}
                                                />
                                            </li>
                                        ) : null}
                                        {/* <li>
                                                    <NavLink
                                                        data-key="header_contact_list"
                                                        to="/contact-us"
                                                        activeClassName="active"
                                                        onClick={this.toggleHeader}
                                                    >Help</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        data-key="header_resources"
                                                        to="/resources"
                                                        activeClassName="active"
                                                        onClick={this.toggleHeader}
                                                    >Resources</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        data-key="header_talent_mobility"
                                                        to="/about"
                                                        activeClassName="active"
                                                        onClick={this.toggleHeader}
                                                    >About Talent Mobility</NavLink>
                                                </li> */}
                                        <li
                                            data-key="header_log_out"
                                            to="/Logout"
                                            className="flexible horizontal aCenter log_out"
                                        >
                                            <span onClick={this.signOut}>
                                                Log Out
                                            </span>
                                        </li>
                                        {/* {user && user.role && !this.props.isJibeUnassignedUser && !user.isJibeUser ? (
                                                    <li className="view_selector">
                                                        <hr/>
                                                        <DropDown
                                                            data={changeViewMenu}
                                                            dataKey="header_view_toggle"
                                                            isInFixedContent
                                                            withCloseButton
                                                            optionsClassName="view_toggle"
                                                            optionsStyles={{
                                                                top: '77px',
                                                                left: 'inherit'
                                                            }}
                                                            defaultSelected={user.role === USER_ROLES.hiring_manager ? 'peopleLeader' : 'employee'}
                                                            onChange={this.onViewDropDownChange}
                                                        />
                                                    </li>
                                                ) : null} */}
                                    </ul>
                                ) : user.email && user.isJibeUnassignedUser ? (
                                    <ul className="flexible horizontal aCenter">
                                        <li
                                            data-key="header_log_out"
                                            to="/Logout"
                                            className="flexible horizontal aCenter"
                                        >
                                            <span onClick={this.signOut}>
                                                Log Out
                                            </span>
                                        </li>
                                    </ul>
                                ) : null}
                            </nav>
                        </React.Fragment>
                    ) : (
                        <nav className={`${this.state.isOpen ? "" : "close"}`}>
                            <ul className="flexible horizontal aCenter">
                                <li
                                    data-key="header_log_out"
                                    to="/Logout"
                                    className="flexible horizontal aCenter log_out"
                                    style={{ border: "none" }}
                                >
                                    <span onClick={this.signOut}>Log Out</span>
                                </li>
                            </ul>
                        </nav>
                    )}
                </section>
                {this.state.displayFastFeedback &&
                user.email &&
                user.role &&
                user.role === "Assignee" &&
                user.currentRelocationId ? (
                    <FastFeedback />
                ) : null}
            </header>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Header),
);
