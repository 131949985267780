// @flow

import React, { PureComponent } from 'react';

import { Icon } from 'components/common';

type IconWithTitleProps = {
    title: string,
    iconName: string,
    width?: number;
    height?: number;
    textWidth?: number;
    marginTop?: number;
};

export default class IconWithTitle extends PureComponent<IconWithTitleProps> {
    render() {
        return (
            <div className="icon-with-title flexible vertical">
                <Icon
                    name={this.props.iconName}
                    width={this.props.width || 30}
                    height={this.props.height || 30}
                />
                <p style={{ maxWidth: this.props.textWidth || 'auto', marginTop: this.props.marginTop }}>{this.props.title}</p>
            </div>
        );
    }
}
