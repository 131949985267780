import React, {useContext, useEffect, useState } from "react";
// import { policySelectionMessage } from "./mockData";
import { Button, Link, RadioButton, Icon } from "components/common";
// import { useInterval } from "hooks/useInterval";
// import { useDispatch } from "react-redux";
import MoveOptionGlobal from "./GlobalAssignment";
import WorkingWithRecruiter from "./WorkingWithRecruiter";
import { History } from "history";
import { global } from "./keyMappings";
// import { moveInitiationService } from "services";
import "./styles.scss";
import { locationFields } from "../../../types";
import CandidateSelection from "../CandidateSelection";
import Lightbulb from "components/common/Images/Lightbulb.svg";
import NoMoving from "components/common/Images/NoMoving.svg";
import { createContext } from "react";

type IsEmpGlobContextType = string;
export const IsEmpGlobContext = createContext<IsEmpGlobContextType>('');

const PreInitiation = (props: {
    navigateTo: (to: string, from: string) => void;
    history: History;
    location: locationFields;
    step: string;
}) => {
    const { state: locationState } = props.history.location;
    const [state, setState] = useState({
        "global-vs-domestic": "",
        primaryReason: "",
        moveType: "NotSet",
        empCategoryRepresentation: "NotSet",
        empProcurementType: "NotSet",
        homeownerOrRenter: "NotSet",
        payGrade: "NotSet",
        policy: "NotSet",
        strategicIntent: "NotSet",
        halfInHomeCountrySpent: false,
        lengthOfAssignment: "NotSet",
        beginingOrEndAssignment: false,
        endOfAssignmentType: "NotSet",
        localTalentAvailable: false,
        // initiationId: props.history.location.state.initiationId,
    });
    // const [count, setCount] = useState(0);
    const [continueButtondisabled, setContinueButtondisabled] = useState(true);
    const [currentMessages, setCurrentMessage] = useState([]);
    const [isCandidateSelected, setIsCandidateSelected] = useState(false);
    const [IsEmpGlobAssign, setIsEmpGlobAssign] = useState<IsEmpGlobContextType>('');

    const clickHandler = (value: string, alreadyInAssignment: boolean): void => {
        let newState;
        if (value == 'yes') {
            setIsEmpGlobAssign(value);
            newState = {
                ...state,
                ...locationState,
                "global-assignment": value,
                alreadyInAssignment: alreadyInAssignment,
                moveOption: global.sequentialMove.value,
            }
        } else {
            setIsEmpGlobAssign(value);
            newState = {
                ...state,
                ...locationState,
                "global-assignment": value,
                alreadyInAssignment: alreadyInAssignment,
            }
        }
        setState(_ => ({
            ...newState
        }))
        props.history.push({
            state: newState
        });
    }

    useEffect(() => {
        if(isCandidateSelected === true) {
            if(locationState !== undefined) {
                const { preIntEmppDetails, employeeInfo } = locationState;
                // console.log("mount location state: ", preIntEmppDetails, employeeInfo)
                if(!preIntEmppDetails && !employeeInfo) {
                    props.history.push("/move-dashboard");
                }
            } else {
                // console.log("goes here")
                props.history.push("/move-dashboard");
            }
        }
    }, [isCandidateSelected]);

    const generateMessages = (messages: Array<string>): Array<JSX.Element> =>
        messages.map((element: string, index: number) => (
            <div key={element} className={"message"}>
                {element}
                {index === 1 ? (
                    <Link
                        dataKey={"contact-us"}
                        text={"Contact us"}
                        action={"Contact-us"}
                        onClick={() => props.history.push("/contact-us")}
                        className={"finance-contacts"}
                    />
                ) : null}
            </div>
        ));

    const generatedMessages: Array<JSX.Element> = generateMessages(
        currentMessages,
    );

    const disableContinueBtnHandler = (continueButtondisabled: boolean): void =>
        setContinueButtondisabled(continueButtondisabled);

    return (
        <div className="policy-selection">
            {isCandidateSelected === true ? (
                <>
                    <h1>Move Pre Initiation</h1>
                    <div className="messages">{generatedMessages}</div>
                    <div className="global-vs-domestic">
                        <div className="title">
                        What type of move ?
                        </div>
                        <div className="options">
                            <div className="option">
                                <RadioButton
                                    checked={
                                        state["global-assignment"] === "yes"
                                    }
                                    onChange={(): void =>
                                        clickHandler("yes", true)
                                    }
                                />
                                {/* <Icon name="Lightbulb" width={30} height={30} /> */}
                                <div style={{ display: 'flex', width: 57, justifyContent: 'space-between' }}>
                                    {/* <div className="icon-block">
                                        <img src={Lightbulb} height="26" width="26" alt="YES" />
                                    </div> */}
                                    <div className="titles">
                                        <p className="title">
                                            International
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="option">
                                <RadioButton
                                    checked={
                                        state["global-assignment"] === "no"
                                    }
                                    onChange={(): void =>
                                        clickHandler("no", false)
                                    }
                                />
                                <div style={{ display: 'flex', width: 97, justifyContent: 'space-between' }}>
                                    {/* <div className="icon-block">
                                        <img src={NoMoving} height="20" width="20" alt="NO" />
                                    </div> */}
                                    <div className="titles">
                                        <p className="title">US Domestic</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {state["global-assignment"] === "yes" && (
                        <MoveOptionGlobal navigateTo={props.navigateTo}
                            disableContinueBtn={disableContinueBtnHandler} history={props.history}
                        />
                    )} */}
                    {state["global-assignment"] === "yes" && (
                        <IsEmpGlobContext.Provider value={IsEmpGlobAssign}>
                            <WorkingWithRecruiter navigateTo={props.navigateTo}
                                disableContinueBtn={disableContinueBtnHandler} history={props.history}
                            />
                        </IsEmpGlobContext.Provider>
                    )}
                    {state["global-assignment"] === "no" && (
                        <IsEmpGlobContext.Provider value={IsEmpGlobAssign}>
                            <WorkingWithRecruiter navigateTo={props.navigateTo}
                                disableContinueBtn={disableContinueBtnHandler} history={props.history}
                            />
                        </IsEmpGlobContext.Provider>
                    )}
                </>
            ) : <CandidateSelection 
                setCandidateSelected={setIsCandidateSelected}
                history={props.history}
                location={props.location}
                navigateTo={props.navigateTo}     
            />}
        </div>
    );
};

export default PreInitiation;
