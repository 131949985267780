import React, { Component } from 'react';
import { IFrameComponent } from 'components/common';
import './style.scss';

type SmartFrameProps = {
    url: string,
    title?: string
};

export default class SmartFrame extends Component<SmartFrameProps> {
    constructor(props) {
        super(props);

        this.selectWindow(this.props);
    }
    componentDidMount() {
        this.selectWindow(this.props);
    }
    componentWillReceiveProps(nextProps) {
        this.selectWindow(nextProps);
    }
    componentWillUnmount() {
        this.closeSelectedWindow();
    }

    useNewTabInsteadPopup() {
        return process.env.REACT_APP_SMARTFRAME_USE_TAB_INSTEAD_POPUP;
    }

    selectWindow(properties) {
        if (properties && properties.url) {
            if (!window.smartFrameConfig || (window.smartFrameConfig && window.smartFrameConfig.url !== properties.url)) {
                this.closeSelectedWindow();
                this.showSelectedWindow(properties.title, properties.url);
            }
        }
    }

    canUseIframe = () => {
        window.browserThirdPartyCookieSupported = false;

        if (!window.browserThirdPartyCookieValidated) {
            window.browserThirdPartyCookieValidated = true;

            if (!window.browserThirdPartyCookieSupported) {
                if (this.useNewTabInsteadPopup())
                    alert('Your task will be opened up in a new tab window.');
                else
                    alert('Your task will be opened up in a new popup window. Please allow it in your browser.');
            }
        }

        return window.browserThirdPartyCookieSupported;
    }

    showSelectedFrame = (title, linkUrl) => {
        if (!linkUrl)
            return null;

        if (!this.canUseIframe())
            return null;

        return  title ? (<div>
            <h3 className="smartframe-title">{title}</h3>
            <IFrameComponent src={linkUrl} />
        </div>) : (<IFrameComponent src={linkUrl} />);
    }

    closeSelectedWindow = () => {
        if (window.smartFrameConfig && window.smartFrameConfig.pointer) {
            window.smartFrameConfig.pointer.close();
            window.smartFrameConfig = {
                pointer: null,
                url:null
            }
        }
    }

    showSelectedWindow = (title, linkUrl) => {
        if (!linkUrl)
            return null;

        if (this.canUseIframe())
            return null;

        console.log('External task url:' + linkUrl);

        const link = document.createElement('a');
        link.href = linkUrl;
        link.style.display = 'none';
        link.target = '_blank';
        link.setAttribute('download', title);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.smartFrameConfig = {
            pointer: null,
            url:linkUrl
        }

        setTimeout(() => {
            window.smartFrameConfig = {
                pointer: null,
                url:null
            }
        }, 2000);
    }

    render() {
        return this.showSelectedFrame(this.props.title, this.props.url);
    }
}
