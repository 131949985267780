// @flow
import React from 'react';
import { Icon, Link } from 'components/common';
import './styles.scss';

export default class TaxMilestonesFooter extends React.PureComponent {
    render(): React.ReactNode {
        return (
            <div className="footer">
                <Icon name="info"/>
                <div className="content">
                    <h4>Want more detailed tax information?</h4>
                    <p>Our tax vendor provides tax orientations and tax compliance services to help you plan, prepare
                        and comply with home and host taxes.</p>
                    <Link text="Portal Login" className="link" />
                </div>
            </div>
        );
    }
}
