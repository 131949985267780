import { CreateActionCreator, createDataReducer } from "helpers";
import { MoveInitiationAPI } from "api";
import { combineReducers } from "redux";

const type = "MOVE_INITIATION";
const leaderShipProgramsType = "LEADERSHIP_PROGRAMS";
const CaliforniaTiersType = "CALIFORNIA_TIERS";
const FinancePartnerType = "FINANCE_PARTNER";
const MoveDashboardType = "MOVE_DASHBOARD";
const EmployeeAndJobDetailsType = "MOVE_EMP_JOB_DETAILS";
const SaveEmployeeAndJobDetailsType = "MOVE_EMP_JOB_DETAILS_SAVE";
const EmployeeAndJobDetailsPosType = "MOVE_EMP_POS_JOB_DETAILS";
const EmployeeCompetitiveInitiationPosType = "MOVE_EMP_COM_INIT_DETAILS";
const SaveEmployeeAdditionalDetailsType = "MOVE_EMP_ADDITIONAL_DETAILS_SAVE";
const SaveEmployeeFinanceDetailsType = "MOVE_EMP_FINANCE_DETAILS_SAVE";
const SaveEmployeeSirvaType = "MOVE_EMP_DETAILS_SIRVA_SAVE";
const SaveNonCompetitivePackageDetailsType = "MOVE_NON_COMPETITIVE_PACKAGE_SAVE";
const SavePreInitDetailsType = "MOVE_PRE_INIT_SAVE";
const SaveCompPreInitDetailsType = "MOVE_COMP_PRE_INIT_SAVE";
const SaveCompPreInitPkgDetailsType = "MOVE_COMP_PRE_INIT_PKG_SAVE";
const SaveCompFinanceDetailsType = "MOVE_COMP_PRE_INIT_FINANCE_SAVE";
const SaveCompApprovalDetailsType = "MOVE_COMP_PRE_INIT_APPROVAL_SAVE";
const MoveJobCardDeleteType = "MOVE_JOB_CARD_DELETE";
const EmployeeSearchByCategoryType = "EMPLOYEE_SEARCH_BY_CATEGORY";
const SavePreInitEmppInfoDetailsType = "MOVE_PRE_INIT_EMPP_INFO_SAVE";
const MoveUpdateOldEmployeeDetailsType = "MOVE_UPDATE_OLD_EMPP_RECORDS";

const actions = {
    fetchLeaderShipPrograms: () =>
        CreateActionCreator.read({
            type: leaderShipProgramsType,
            apiCall: MoveInitiationAPI.getLeaderShipPrograms,
            args: [],
        }),
    fetchCaliforniaTiers: () =>
        CreateActionCreator.read({
            type: CaliforniaTiersType,
            apiCall: MoveInitiationAPI.getCaliforniaTiers,
            args: [],
        }),
    fetchFinancePartner: partner =>
        CreateActionCreator.read({
            type: FinancePartnerType,
            apiCall: MoveInitiationAPI.getFinancePartner,
            args: [partner],
        }),
    fetchMoveDashboardMemberList: () =>
        CreateActionCreator.read({
            type: MoveDashboardType,
            apiCall: MoveInitiationAPI.getMoveDashboardMemberList,
            args: [],
        }), 
    fetchEmpJobDetails: (employeeWWID, jobID) =>
        CreateActionCreator.read({
            type: EmployeeAndJobDetailsType,
            apiCall: MoveInitiationAPI.getEmpJobDetails,
            args: [employeeWWID, jobID],
        }),
    fetchEmpJobDetailsPosId: (employeeWWID, posID) =>
        CreateActionCreator.read({
            type: EmployeeAndJobDetailsPosType,
            apiCall: MoveInitiationAPI.getEmpPosJobDetails,
            args: [employeeWWID, posID],
        }),
    setMoveEmppJobDetails: (data) =>
        CreateActionCreator.read({
            type: SaveEmployeeAndJobDetailsType,
            apiCall: MoveInitiationAPI.setMoveEmppJobDetails,
            args: [data],
        }),
    fetchEmpCompetitiveInit: (reqContestNumber,candidateNumber) =>
        CreateActionCreator.read({
            type: EmployeeCompetitiveInitiationPosType,
            apiCall: MoveInitiationAPI.getEmpCompetitiveInit,
            args: [reqContestNumber,candidateNumber],
        }),
    setMoveAdditionalMoveDetails: (data) =>
        CreateActionCreator.read({
            type: SaveEmployeeAdditionalDetailsType,
            apiCall: MoveInitiationAPI.setMoveAdditionalMoveDetails,
            args: [data],
        }),
    setMoveFinanceMoveDetails: (data) =>
        CreateActionCreator.read({
            type: SaveEmployeeFinanceDetailsType,
            apiCall: MoveInitiationAPI.setMoveFinanceMoveDetails,
            args: [data],
        }),
    setMoveEmppSirvaDetails: (data) =>
        CreateActionCreator.read({
            type: SaveEmployeeSirvaType,
            apiCall: MoveInitiationAPI.setMoveEmppSirvaDetails,
            args: [data],
        }),
    setNonCompetitivePackageDetails: (data) =>
        CreateActionCreator.read({
            type: SaveNonCompetitivePackageDetailsType,
            apiCall: MoveInitiationAPI.setNonCompetitivePackageDetails,
            args: [data],
        }),
    setPreInitDetails: (data) =>
        CreateActionCreator.read({
            type: SavePreInitDetailsType,
            apiCall: MoveInitiationAPI.setPreInitDetails,
            args: [data],
        }),
    setCompPreInitDetails: (data) =>
        CreateActionCreator.read({
            type: SaveCompPreInitDetailsType,
            apiCall: MoveInitiationAPI.setCompPreInitDetails,
            args: [data],
        }),
    setPreInitPackageDetails: (data) =>
        CreateActionCreator.read({
            type: SaveCompPreInitPkgDetailsType,
            apiCall: MoveInitiationAPI.setPreInitPackageDetails,
            args: [data],
        }),
    setCompFinanceDetails: (data) =>
        CreateActionCreator.read({
            type: SaveCompFinanceDetailsType,
            apiCall: MoveInitiationAPI.setCompFinanceDetails,
            args: [data],
        }),
    setCompApprovalDetails: (data) =>
        CreateActionCreator.read({
            type: SaveCompApprovalDetailsType,
            apiCall: MoveInitiationAPI.setCompApprovalDetails,
            args: [data],
        }),
    deleteMoveJobCard: (data) =>
        CreateActionCreator.read({
            type: MoveJobCardDeleteType,
            apiCall: MoveInitiationAPI.deleteMoveJobCard,
            args: [data],
        }),
    fetchEmployeeByCategory: (category,keyword) =>
        CreateActionCreator.read({
            type: EmployeeSearchByCategoryType,
            apiCall: MoveInitiationAPI.getEmployeeByCategory,
            args: [category,keyword],
        }),
    setPreInitEmppInfoDetails: (data) =>
        CreateActionCreator.read({
            type: SavePreInitEmppInfoDetailsType,
            apiCall: MoveInitiationAPI.setPreInitEmppInfoDetails,
            args: [data],
        }),
    updateOldEmployeeDetails: (data) =>
        CreateActionCreator.read({
            type: MoveUpdateOldEmployeeDetailsType,
            apiCall: MoveInitiationAPI.updateOldEmployeeDetails,
            args: [data],
        }),
};

const leaderShipProgramsReducer = createDataReducer(leaderShipProgramsType);
const CaliforniaTiersReducer = createDataReducer(CaliforniaTiersType);
const financePartnerReducer = createDataReducer(CaliforniaTiersType);
const moveDashboardReducer = createDataReducer(MoveDashboardType);
const empJobDetReducer = createDataReducer(EmployeeAndJobDetailsType);
const empJobDetPosReducer = createDataReducer(EmployeeAndJobDetailsPosType);
const empCompetitiveInitReducer = createDataReducer(EmployeeCompetitiveInitiationPosType);
const employeeByCategoryReducer = createDataReducer(EmployeeSearchByCategoryType);

const reducer = combineReducers({
    leaderShipPrograms: leaderShipProgramsReducer,
    californiaTiers: CaliforniaTiersReducer,
    financePartner: financePartnerReducer,
    movedashboard: moveDashboardReducer,
    empJobDetails: empJobDetReducer,
    empPosJobDetails: empJobDetPosReducer,
    empCompetitiveInitDetails: empCompetitiveInitReducer,
    employeeByCategory: employeeByCategoryReducer,
});

export default {
    type,
    actions,
    reducer,
};
