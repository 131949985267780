import React from 'react';
import StepperIcon from './StepperIcon';

import './styles.scss'

type TableRowType = {
    active: boolean;
    description: string;
    isComplete: boolean;
}

class TableRow extends React.PureComponent<TableRowType>{

    render(){
        const {description, active, isComplete} = this.props;
        const iconClasses = ['RowContainer'];

        if (active) {
            iconClasses.push('active');
        }

        if (isComplete) {
            iconClasses.push('complete');
        }
        return(
            <div className={iconClasses.join(' ')}>
                <StepperIcon
                    style={{ margin: '0 10px 0 0' }}
                    isActive={active}
                    isComplete={isComplete}
                />
                <div className="step-descriptionContainer">
                    <p className="step-description">{description}</p>
                </div>
            </div>
        )
    }
}
export default TableRow;
