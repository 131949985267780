// @flow
import React from 'react'
import Typography  from '@material-ui/core/Typography';
import {
    IconRaw,
    CollapsedItem,
    Link,
    Asset
} from 'components/common';

import { EXTERNAL_LINKS } from 'consts';

const defaultRAEventProps = {
    action:'click-question-link',
    category:'questions',
};

const IntExploreStepCommonQuestions = () => {
  return (
    <div className="common-questions">
        {/* item 1 */}
        <CollapsedItem title="What percentage of JnJ employees are relocating every year?"
            icon={<IconRaw name="personWorld"/>}>
            <Typography variant="body1">
                An international assignment is an important factor in an employee’s career development and progression and must align to Johnson & Johnson business and talent strategies. Some international opportunities are available through “by nomination” programs while others may arise through job postings or through self initiation. For individuals interested in global experiences, it is important this interest be communicated to their manager. Additionally, employees should keep their Workday profile up to date, including countries/regions and cross functional areas of interest.
            </Typography>
        </CollapsedItem>

        {/* item 2 */}
        <CollapsedItem title="How does an employee qualify for a global experience?"
            icon={<IconRaw name="clipboard"/>}>
            <Typography variant="body1">
                An international assignment is an important factor in an employee’s career development and progression and must align to Johnson & Johnson business and talent strategies. Some international opportunities are available through “by nomination” programs while others may arise through job postings or through self initiation. For individuals interested in global experiences, it is important this interest be communicated to their manager. Additionally, employees should keep their Workday profile up to date, including countries/regions and cross functional areas of interest.
            </Typography>
            <br/>
            <Link text="Global HR Portal"
                externalLink="https://jnj.sharepoint.com/sites/hrportal/English/EnglishVar/Pages/default.aspx"
                {...defaultRAEventProps}
            />
        </CollapsedItem>

        {/* item 3 */}
        <CollapsedItem title="How do I know if someone is eligible for immigration?"
            icon={<IconRaw name="infoFile"/>}>
            <Typography variant="body1">
                Prior to extending an offer to an employee, it is important to determine if the individual is eligible to work in the desired country. Immigration requirements and  timelines vary significantly by country, and the education and other personal characteristics of an employee as well as the position details can affect the eligibility for a country visa and work permit. On occasion, certifications and additional documentation may be required post initial application submission, affecting the date on which an employee can begin their new role. As immigration regulations are subject to change, it is best to plan early, well in advance of the need to be present in the country. No employee is to start work prior to authorization by the Immigration Services Provider. This is a legal pre-requisite and failure to comply exposes both the company and employee to sanctions and/or penalties.
                <br/>
                <br/>
                For individuals looking to move to the United States, managers should complete a U.S. Visa pre-assessment. For individuals looking to move to a country other than the U.S., managers should complete a general sponsorship assessment. No start date guarantees should be made to either the employee or business management about start dates until such time as the immigration pre-assessments are completed.
            </Typography>

            <br/>
            <div className="line-separated-list">
                <Link text="Immigration Timelines" className="line-separated-list-item"
                    onClick={() => new Asset({assetType:'global-immigration', assetId:'immigration-complexity-matrix'}).execute()}
                    {...defaultRAEventProps}
                />
                {/* <Link text="Global/US Immigration Initiation Form" className="line-separated-list-item"
                    onClick={() => new Asset({assetType:'immigration', assetId:'deloitte_immigration_service_initiation'}).execute()}
                    {...defaultRAEventProps}
                /> */}
                <Link text="Immigration Initiation Form" className="line-separated-list-item"
                    onClick={() => new Asset({assetType:'immigration-service', assetId:'immigration-service-initiation-form'}).execute()}
                    {...defaultRAEventProps}
                />
            </div>
        </CollapsedItem>

        {/* item 4 */}
        <CollapsedItem title="How much does a global move cost?"
            icon={<IconRaw name="sign"/>}>
            <Typography variant="body1">
                The price of a global move varies significantly based on a number of factors, such as the duration of assignment, pay grade of the employee and size of the family. Use our Global Cost Estimate tool to get a preliminary estimate based on your specific business scenario.
            </Typography>
            <br/>
            <Link text="Global Cost Estimator"
                externalLink={EXTERNAL_LINKS.GlobalCostEstimator}
                {...defaultRAEventProps}
            />
        </CollapsedItem>
    </div>
  )
}

export default IntExploreStepCommonQuestions;
