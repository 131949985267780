import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  MidSectionComponent,
  QuestionsListCard,
  Asset,
  Accordion,
  TextWithImage,
  SectionHeading,
} from 'components/common';
import {JnJContainer, JnJGridRow, JnJGridColumn} from 'components/common/JnJGrid';
import IntExploreStepCommonQuestions from './IntExploreStepCommonQuestions';
import Typography from '@material-ui/core/Typography';


import MobilityConnectBlock from '../../MobilityConnectBlock';

import { STEPS } from './constants';

import globus from 'components/common/Images/world.svg';

import './style.scss';

class IntExploreStep extends Component {
  render() {
    return (
      <div className="int-explore-step">
          <TextWithImage
              subtitle="Start Your Planning"
              assetType="hr_top_content_image"
              assetId="international_explore"
              title="Planning a Global Experience"
              content="Global experiences develop leaders with the diverse thinking necessary to drive growth and innovation for the future success of Johnson & Johnson."
          />
          <div className="question-part basics">
              <SectionHeading
                eyebrow="The Basics"
                title="Understanding the Types of Global Experiences"
                className="section-heading mbottom-40" />
              <div className="question-block flexible">
                  <div className="flexible vertical">
                      <QuestionsListCard
                          key={1}
                          icon={globus}
                          body={
                            <div>
                              Johnson & Johnson, like most global companies, supports different types of international experiences. These can and do vary depending on business and talent development objectives. Assignments range in length from short- to long-term to even indefinite transfers, and may be within the employee’s home region or to a different region of the world.
                              <div className="flexible facts vertical">
                                <div>
                                  <div className="fact">
                                    <span className="fact-label">Long Term:</span>
                                    <br />
                                    <span className="fact-value">12+ months</span>
                                  </div>
                                  <div className="fact">
                                    <span className="fact-label">Short Term:</span>
                                    <br />
                                    <span className="fact-value">3 to less than 12 months</span>
                                  </div>
                                </div>
                                <div>
                                  <div className="fact">
                                    <span className="fact-label">One Way Move:</span>
                                    <br />
                                    <span className="fact-value">5+ years</span>
                                  </div>
                                  <div className="fact">
                                    <span className="fact-label">Business Traveler:</span>
                                    <br />
                                    <span className="fact-value">Less than 3 months</span>
                                  </div>
                                </div>
                                <div className="fact">
                                  <span className="fact-label">Commuter:</span>
                                  <br />
                                  <span className="fact-value">Approximately 50% or greater of working time spent in host country</span>
                                </div>
                              </div>
                            </div>
                          }
                          urlText={["View Policies", "View Office Locations"]}
                          urlActions={[() => this.props.history.push('/resources/managerInt/Assignment') /*TODO: this should redirect to local policies url*/,
                              () => new Asset({assetType:'int-planning', assetId:'country-experience-locations'}).execute()]}
                      />
                    <SectionHeading
                      eyebrow="Before you initiate a move"
                      title="Identify the Strategic Intent for the Talent Move"
                      className="section-heading mbottom-40" />
                    <Accordion data={STEPS} />
                  </div>
              </div>
          </div>

        <MidSectionComponent
            className='illustration-bg mtop-80'
            col1={
            <div className="flexible vertical">
                <h5 className="inverse mbottom-20">Did you know?</h5>
                <h4 className="inverse mbottom-10">Moves Create Value</h4>
                <Typography variant="body2" color="textSecondary">99% of managers report satisfaction with their assignee's ability to transfer new skills and capabilities to others.</Typography>
            </div>
            }
        />

        <JnJContainer static className="mbottom-40">
            <JnJGridRow>
                <JnJGridColumn xs={12} md={8}>
                    <SectionHeading
                    eyebrow="What managers need to know before they get started"
                    title="Common Questions"
                    className="section-heading"
                    />
                    <IntExploreStepCommonQuestions/>
                </JnJGridColumn>
                <JnJGridColumn  xs={12} md={4}>
                    <MobilityConnectBlock />
                </JnJGridColumn>
            </JnJGridRow>
        </JnJContainer>
      </div>
    );
  }
}

export default withRouter(IntExploreStep);
