import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import TitleWithIcon from './TileWithIcon';
import { Accordion } from 'components/common';
import AllowanceItem from './AllowanceItem';
import DomesticHomeSale from './DomesticHomeSale';
import './styles.scss';

import {
    getFilteredAllowances,
    getAcknowledgementLetter,
    getUserProfile
} from 'selectors/index';

import { allowancesService } from 'services';

class Allowances extends PureComponent{
    state = {};
    componentDidMount(){
        if(this.props.user.email){
            this.fetchData(this.props);
        }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.user.email) {
            if(!this.props.user.email || (this.props.user.currentRelocationId !== nextProps.user.currentRelocationId)){
                this.fetchData(nextProps);
            }
        }
    }
    fetchData = (props) => {
        const { type, user } = props;
        if (type === 'domestic') {
            this.props.fetchAcknowledgementLetter(user.currentRelocationId);
        } else {
            this.props.fetchRecentAllowance(user.currentRelocationId);
        }
    };

    generateAllowanceData = (allowances) => {
        if(Array.isArray(allowances) && allowances.length) {
            return allowances.map((allowance, index) => ({
                props: {
                    allowanceData: allowance,
                    uniqueKey: index,
                },
                content: allowance.description ?
                    typeof allowance.description === 'string' ?
                        <div className="allowance-description" dangerouslySetInnerHTML={{__html: allowance.description}}/>
                        : allowance.description
                    : '',
            }))
        }
        return null;
    };

    getDataKey = (name) => {
        const convertedName = name.replace(/\s+/g,' ').trim().toLowerCase();
        const wordsArray = convertedName.split(' ');
        return wordsArray.join('_');
    };

    generateCategoryWithAllowance = (category, allowanceData) => (
        <div
            className="allowance-category"
            key={category.allowanceCategoryId}
            data-key={this.getDataKey(category.name)}
        >
            <TitleWithIcon
                title={category.name}
                picture={category.picture}
            />
            <div className="allowances-list">
                <Accordion
                    ItemRenderer={AllowanceItem}
                    data={allowanceData}
                />
            </div>
        </div>
    );

    generateGlobalAllowances = (allowances) => {
        if(allowances && allowances.categories) {
            return allowances.categories.map(category => {
                const allowanceData = this.generateAllowanceData(category.allowances);
                return this.generateCategoryWithAllowance(category, allowanceData);
            })
        }
        return null;
    };

    generateDomesticAllowances = (data) => {
        if(data.categories) {
            return data.categories.map(category => {
                const allowances = [...category.allowances];
                switch (category.name) {
                    case 'Housing':
                        data.homeSale && allowances.unshift({
                            name: 'Home Sale Details',
                            description: (<DomesticHomeSale data={{ ...data.homeSale }} />)
                        });
                        break;
                    case 'Moving':
                        data.lumpSum && allowances.push({
                            name: 'Lump Sum',
                            description: data.lumpSum.description
                        });
                        break;
                    default:
                        data.subsidy && allowances.push({
                            name: 'Subsidy',
                            description: data.subsidy.description
                        })
                }

                const allowanceData = this.generateAllowanceData(allowances);
                return this.generateCategoryWithAllowance(category, allowanceData);
            });
        }
    };

    render() {
        const { type } = this.props;
        const isDomestic = (type === 'domestic');

        const data = isDomestic ? this.props.acknowledgementLetter : this.props.galAllowances;
        return (
            this.props.user.email && data ?
                <div className="Allowances flexible vertical">
                    {   isDomestic ?
                        data.isAllowancesExists ?
                            this.generateDomesticAllowances(data)
                            : <p> {data.errorMessage} </p>
                            : this.generateGlobalAllowances(data)
                    }
                </div>
                : <div className="flexible jCenter"><div className="Spinner" /></div>
        );
    }
}

export default connect((state) => ({
    user: getUserProfile(state),
    galAllowances: getFilteredAllowances(state),
    acknowledgementLetter: getAcknowledgementLetter(state)
}), ({ ...allowancesService.actions }))(Allowances);
