import React, { PureComponent } from 'react';
import { DatePicker as MUIDatePickcer, InlineDatePicker } from "material-ui-pickers";
import { Icon } from "components/common";
import { detect } from 'detect-browser';
import './styles.scss';

export default class DatePicker extends PureComponent {

    handleChange = (date) => {
        // date here is Moment date object
        this.props.onChange && this.props.onChange(date);
    };

    render() {

        const inputAdormentProps = (detect().name === 'ie') ? {
            style: {
                position: "absolute",
                right: 0,
                bottom: 5
            }
        } : {};

        const {
            errorMessages,
            helperText,
            defaultValue,
            label,
            showValidationMessages,
            formatToShow,
            inline,
            nativeProps
        } = this.props;

        const DPComponent = inline ? InlineDatePicker : MUIDatePickcer;

        return (
            <div className="DatePicker">
                <DPComponent
                    format={formatToShow}
                    label={label}
                    value={defaultValue}
                    onChange={this.handleChange}
                    disableOpenOnEnter
                    animateYearScrolling={false}
                    keyboard
                    keyboardIcon={<Icon name="calendarIcon" />}
                    autoOk
                    clearable={false}
                    InputAdornmentProps={inputAdormentProps}
                    {...nativeProps}
                />
                { (errorMessages && showValidationMessages) && (errorMessages.map(( message, i ) => (
                        <p className="formElement_errorText" key={i}>
                            {message}
                        </p>
                    ))
                )}
                {helperText && (
                    <p className="formElement_helperText">
                        {helperText}
                    </p>
                )}
            </div>
        );
    }
}
