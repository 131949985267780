import React, { PureComponent } from 'react';

import { Icons } from '../../components/common/Icons/icon';
import { Icon } from 'components/common'

import './style.scss';

export default class IconsList extends PureComponent {

    generateIcons = () => (
        Object.keys(Icons).map((name, index)=>(
            <div className="icon-block flexible vertical aCenter jAround" key={index}>
                <Icon name={name}/>
                <span>{name}</span>
            </div>
        ))
    );

    render() {
        return (
            <div className="IconsList">
                <h1>Icons</h1>
                <div className="icon-list flexible jAround">
                    {this.generateIcons()}
                </div>
            </div>
        )
    }
}