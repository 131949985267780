import React from 'react';

import './styles.scss';

export default class HeaderWithImage extends React.PureComponent {
    render (){
        const { props } = this;
        return (
            <div className="header-with-image">
                <h1 className="title">
                    {props.title}
                </h1>
                <div className="image">{props.image}</div>
            </div>
        );
    }
}