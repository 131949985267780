import React from 'react';
import { withFormsy } from 'formsy-react';

import TextArea from '../elements/TextArea';
import { ValidationContext } from '../ValidatableForm';
import { getRequiredLabel, getLabel } from '../utils';

class FormsyTextArea extends React.Component {

    changeValue = event => {
        this.props.setValue(event.target.value);
        this.props.onChange && this.props.onChange(event);
    };

    render() {
        const { props } = this;
        const label = props._required ? getRequiredLabel(props.label) : getLabel(props.label);

        return (
            <ValidationContext.Consumer>
                {(showValidationMessages) => (
                    <TextArea
                        disabled={props.disabled}
                        helperText={props.helperText}
                        label={label}
                        rowsCount={props.rowsCount}
                        onChange={this.changeValue}
                        value={props.getValue()}
                        errorMessages={props.getErrorMessages()}
                        showValidationMessages={showValidationMessages}
                        dataKey={props.dataKey}
                    />
                )}
            </ValidationContext.Consumer>
        );
    }
}

export default withFormsy(FormsyTextArea);
