import React from 'react';
import {Link} from 'components/common';
import personWorld from 'components/common/Images/personWorld.svg';
import clipboard from 'components/common/Images/clipboard.svg';
import moving from 'components/common/Images/moving.svg';
import house from 'components/common/Images/house.svg';

export const ExplQuestionsData = [
    {
        title: 'What are the top 10 U.S. locations for relocation? ',
        icon:personWorld,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">Johnson & Johnson’s Top U.S. Destination Cities are:
                </p>
                <ul className="list">
                    <li>Raritan/Somerville, NJ</li>
                    <li>New Brunswick, NJ</li>
                    <li>Fort Washington/Spring House, PA</li>
                    <li>Titusville, NJ</li>
                    <li>Skillman, NJ</li>
                    <li>Cincinnati, OH</li>
                    <li>Jacksonville, FL</li>
                    <li>Raynham, MA</li>
                    <li>Irvine, CA</li>
                    <li>West Chester, PA</li>
                </ul>
            </div>
        )
    },
    {
        title: 'How do I know if a candidate or employee is eligible for a U.S. Domestic relocation?',
        icon:clipboard,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">U.S. Domestic relocation is for individuals currently employed or New Hire at a U.S. location. As a hiring manager, you will determine if your position will offer relocation with the compensation package prior to posting the job. Hiring Managers are strongly encouraged to consult with their respective Finance partners to ensure funding for relocation is feasible and approved prior to authorizing relocation in the job requisition.  Recruiters will then leverage this information when identifying candidates and scheduling interviews during the standard job application process. 
                </p>
            </div>
        )
    },
    {
        title: 'How does Johnson & Johnson determine which relocation package is offered?',
        icon:moving,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">Relocation packages are based on employee pay-grade level and homeowner/renter status. To learn how relocation packages differ for homeowners and renters, please read our Homeowner & Renter Packages Summary. 
                </p>
                <h4 className="flexible horizontal expl_hr_acc_item_buttons">
                    <Link text="Relocation Package Summary" onClick={()=>window.location.replace('/resources/managerDom/DomesticPolicies')} />
                </h4>
            </div>
        )
    },
    {
        title: 'Can employees relocate to another U.S. city temporarily? ',
        icon:house,
        content: (
            <div>
                <p className="expl_hr_acc_item_part_2">
                U.S. Domestic relocations are consider permanent in nature. U.S. domestic business moves less than 12 months in length are considered business travel and may be managed directly by the business.  Should the business want support for talent moves of 12 months or less, please continue to follow the process steps described herein.
                </p>
            </div>
        )
    }
];
