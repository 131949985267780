import React from 'react';
import TextWithLabel from '../TextWithLabel';

export default ({ data }) => (
    <div className="home-sale-details">
        <div className="details-row flexible horizontal">
            <div className="details-col">
                <TextWithLabel
                    label="Original Purchase Price:"
                    text={`$${data.homeSaleOriginalPurchasePrice}`}
                />
            </div>
            <div className="details-col">
                <TextWithLabel
                    label="Amount Owed on Loan:"
                    text={`$${data.homeSaleAmountOwed}`}
                />
            </div>
        </div>
        <div className="details-row flexible horizontal">
            <p>{data.description}</p>
        </div>
        <div className="details-row flexible horizontal">
            <div className="details-col">
                <TextWithLabel
                    label="BMA Results from:"
                    text={`$${data.homeSaleBMA1Agency}`}
                />
            </div>
            <div className="details-col">
                <TextWithLabel
                    label="Recommended List Price:"
                    text={`$${data.homeSaleBMA1SuggestedListPrice}`}
                />
            </div>
            <div className="details-col">
                <TextWithLabel
                    label="Probably Sales Price:"
                    text={`$${data.homeSaleBMA1LikelySalesPrice}`}
                />
            </div>
        </div>
        <div className="details-row flexible horizontal">
            <div className="details-col">
                <TextWithLabel
                    label="BMA Results from:"
                    text={`$${data.homeSaleBMA2Agency}`}
                />
            </div>
            <div className="details-col">
                <TextWithLabel
                    label="Recommended List Price:"
                    text={`$${data.homeSaleBMA2SuggestedListPrice}`}
                />
            </div>
            <div className="details-col">
                <TextWithLabel
                    label="Probably Sales Price:"
                    text={`$${data.homeSaleBMA2LikelySalesPrice}`}
                />
            </div>
        </div>
        <div className="details-row flexible horizontal">
            <div className="details-col">
                <TextWithLabel
                    label="Maximum List Price:"
                    text={`$${data.homeSaleMaxListPrice}`}
                />
            </div>
            <div className="details-col">
                <TextWithLabel
                    label="Estimated Potential Loss:"
                    text={`$${data.homeSaleEstimatedPotentialLoss}`}
                />
            </div>
            <div className="details-col">
                <TextWithLabel
                    label="Potential Buyout Offer:"
                    text={`$${data.potentialBuyoutOffer}`}
                />
            </div>
        </div>
        <div className="details-row flexible horizontal">
            <p>{data.comments}</p>
        </div>
    </div>
);