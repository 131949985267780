import React from 'react';

import './style.scss';
import Radio from '@material-ui/core/Radio';


export default function(props){
    const className=`radio-button ${props.className || ''}`;
    return (
        <div className='radio-button-root'>
            <Radio
                style={{cursor:'none'}}
                {...props}
                className={className}
            />
        </div>
    );
}
