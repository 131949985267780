import * as React from "react";
import { Icon } from "components/common";
import { EligibleMoveTopProps } from "types";
import "./styles.scss";

const EligibleMoveTop = (props: EligibleMoveTopProps) => {

    const backHandler = (): void => {
        props.history.push({
            state: {
                        ...props.location.state,
                        [props.backState]: "",
                    }
        });
    }
    
    return (
        <>
            <div
                className="back-icon"
                onClick={backHandler}
            >
                <Icon name="backIcon" />
                <p>Go back</p>
            </div>
            <h1 className="section-title">
                We’ve matched your job to a policy!
            </h1>
        </>
    );
};

export default EligibleMoveTop;
