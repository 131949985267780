// @flow

import { Api } from 'backend';

export default {
    getRecentAllowance: (relocationId: string): Promise<any> => Api.get({
        path: `/sirva/relocations/${relocationId}/policy`,
    }),
    getAcknowledgementLetter: (relocationId: string): Promise<any> => Api.get({
        path: `/sirva/relocations/${relocationId}/acknowledgementlettervisualization`
    })
}