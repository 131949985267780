import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { moveInitiationService } from "services";
import { ApprovalAutoComplete } from "components/common";
import { MoveDetailsFinanceState } from "types";
import { generalMessages } from "./mockData";
import { OptionType } from "../AutoComplete";
import "./style.scss";

const mapDispatchToProps = {
    fetchFinancePartner: moveInitiationService.actions.fetchFinancePartner,
    setCompApprovalDetails: moveInitiationService.actions.setCompApprovalDetails,
};

class Approvals extends PureComponent<
    RouteComponentProps & {
        fetchFinancePartner: (partner: string) => Promise<any>;
        navigateTo: (to: string, from: string) => void;
        setCompApprovalDetails: (data: object) => Promise<any>;
    },
    MoveDetailsFinanceState
> {
    interval: any;
    state = {
        currentMessage: -1,
        showContent: false,
        partnersOne: [],
        partnersTwo: [],
        isLoadingFieldOne: false,
        isLoadingFieldTwo: false,
        selectedFirstManager: null,
        selectedSecondManager: null,
		disableStyle: "disabled", 
        disableButton: true,
        isLoading: false,
        manager: (this.props.history.location.state.manager != null && typeof this.props.history.location.state.manager !== 'undefined') ? 
                this.props.history.location.state.manager : null,
        derivedSecondManager: (this.props.history.location.state.derivedSecondManager != null && typeof this.props.history.location.state.derivedSecondManager !== 'undefined') ? 
                this.props.history.location.state.derivedSecondManager : null,
    };

    //MESSAGES
    componentDidMount(): void {
        this.interval = setInterval(this.showMessage, 1000);
    }

    showMessage = () => {
        if (this.state.currentMessage === generalMessages.length - 1) {
            clearInterval(this.interval);
            this.interval = null;
            return this.setState({ showContent: true });
        }

        this.setState({ currentMessage: this.state.currentMessage + 1 });
    };

    clearAutoComplete = () => [];

    handleClickFirstManager = (manager: OptionType) => {
        this.setState({
            manager,
            isLoadingFieldOne: false,
        });
    }    

    handleClickSecondManager = (derivedSecondManager: OptionType) =>
        this.setState({
            derivedSecondManager,
            isLoadingFieldTwo: false,
        });


    onManagerChange = (value: string) => {
        if (value === "") {
            const clearSecond = {
                label: "clear",
                subLabel: "",
                displayName: "",
                firstName: "",
                jobTitle: "",
                lastName: "",
                email: "",
                wwid: "",
            };
            this.setState({
                manager: null,
                derivedSecondManager: clearSecond,
                disableStyle: "disabled",
            });
        } else {
            if (value.length > 2) {
                this.setState({
                    isLoadingFieldOne: true,
                });
                let secondManager = undefined;
                this.props
                        .fetchFinancePartner(value)
                        .then(partnersOne => {
                            if( partnersOne.result.length > 0) {
                                // if(partnersOne.result[0].manager !== undefined && partnersOne.result[0].manager.length > 0) {
                                //     secondManager = partnersOne.result[0].manager;    
                                // }
                                // if(partnersOne.result[0].manager == undefined) {
                                //     const managerTwodata = {
                                //         subLabel: "",
                                //         displayName: "Jhon Doe",
                                //         firstName: "Jhon",
                                //         jobTitle: "HR 2",
                                //         lastName: "Doe",
                                //         email: "jhon@its.jnjcom",
                                //         wwid: "WWW1234565",
                                //     };
                                //     secondManager = managerTwodata;    
                                // }
                                this.setState({
                                    partnersOne: partnersOne.result,
                                    derivedSecondManager: secondManager,
                                    isLoadingFieldOne: false,
                                });
                            }
                        })
                        .catch(err => console.log(err));
            } else {
                if (this.state.partnersOne.length) {
                    this.setState({
                        partnersOne: [],
                    });
                }
            }
        }
    };

    onSecondManagerChange = value => {
        if (value === "") {
            this.setState({
                derivedSecondManager: null,
            });
        } else {
            if (value.length > 2) {
                this.setState({
                    isLoadingFieldTwo: true,
                });
                this.props
                        .fetchFinancePartner(value)
                        .then(partnersTwo => {
                            if( partnersTwo.result.length > 0) {
                                this.setState({
                                    partnersTwo: partnersTwo.result,
                                    isLoadingFieldTwo: false,
                                });
                            }    
                        })
                        .catch(err => console.log(err));
            } else {
                if (this.state.partnersTwo.length) {
                    this.setState({
                        partnersTwo: [],
                    });
                }
            }
        }
    };
	
	componentDidUpdate = () => {
        this.enableContinueBtn();        
    } 
    
    enableContinueBtn = () => {
        const {manager, derivedSecondManager} = this.state;
        if( (manager !== null && manager !== undefined ) && (derivedSecondManager !== null && derivedSecondManager !== undefined )){
            this.setState({disableStyle: "", disableButton: false})
        } else {
            this.setState({disableStyle: "disabled", disableButton: true})  
        }
   }

    saveApprovals = () => {
        this.setState({
            isLoading: true,
            disableStyle: "disabled", 
            disableButton: true,
        })
        this.props.history.push({
            state: {
                ...this.props.location.state,
                "manager": this.state.manager,
                "derivedSecondManager": this.state.derivedSecondManager
            },
        })
        const dataSet = {
            "initiationId": this.props.location.state.initiationId,
            "hirinManager": {
              "firstName": this.state.manager.firstName,
              "lastName": this.state.manager.lastName,
              "displayName": this.state.manager.displayName,
              "jobTitle": this.state.manager.jobTitle,
              "email": this.state.manager.email,
              "wwid": this.state.manager.wwid
            },
            "hirinManagerLevel2": {
              "firstName": this.state.derivedSecondManager.firstName,
              "lastName": this.state.derivedSecondManager.lastName,
              "displayName": this.state.derivedSecondManager.displayName,
              "jobTitle": this.state.derivedSecondManager.jobTitle,
              "email": this.state.derivedSecondManager.email,
              "wwid": this.state.derivedSecondManager.wwid
            }
          };

        this.props
        .setCompApprovalDetails(dataSet)
        .then(data => {
            this.setState({
                isLoading: false,
                disableStyle: "", 
                disableButton: false
            })
            this.props.history.push({
                state: {
                    ...this.props.location.state,
                    "approvalsData": dataSet
                },
            })
            this.props.navigateTo(
                "review-submit",
                "approvals",
            )
        })
        .catch(e => {
            console.log(e)
        });
        
    }
   
    render() {
        const { manager, derivedSecondManager, partnersOne, partnersTwo, isLoadingFieldOne, isLoadingFieldTwo, disableButton, disableStyle } = this.state;
        const messages = generalMessages.filter(
            (_, i) => i <= this.state.currentMessage,
        );

        return (
            <div className="approvalsContainer">
                <h1>Approvals</h1>
                <div className="messages">
                    {messages.map((el, i) => (
                        <div key={el} className={"message"}>
                            {el}
                        </div>
                    ))}
                </div>
                {this.state.showContent && (
                    <React.Fragment>
                        <div className="partnersOne" >
                            <ApprovalAutoComplete
                                title={"Approver #1: Hiring Manager"}
                                placeholder={"Enter name"}
                                inputTitle={"Hiring Manager Name"}
                                clearAutoComplete={this.clearAutoComplete}
                                handleClick={this.handleClickFirstManager}
                                suggestions={partnersOne}
                                style={{ marginTop: 38 }}
                                onChange={this.onManagerChange}
                                isLoading={isLoadingFieldOne}
                                value={manager}
                            />
                        </div>
                        <div className="partnersTwo" >
                            <ApprovalAutoComplete
                                title={"Approver #2: Hiring Manager's Manager"}
                                placeholder={"Enter name"}
                                inputTitle={"Hiring Manager's Manager Name"}
                                clearAutoComplete={this.clearAutoComplete}
                                suggestions={partnersTwo}
                                secondManagerData={derivedSecondManager}
                                style={{ marginTop: 38 }}
                                onChange={this.onSecondManagerChange}
                                handleClick={this.handleClickSecondManager}
                                isLoading={isLoadingFieldTwo}
                                value={null}
                            />
                        </div>    
                        <div className="line" />
                        <br />
                        <section className="buttonSection">
                            <button
                                className={"getStarted " + disableStyle}
                                disabled={disableButton}
                                onClick={() =>
                                    this.saveApprovals()
                                }
                            >
                                <span className="buttonLabel">Continue</span>
                            </button>
                        </section>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
export default withRouter(
    connect(
        null,
        mapDispatchToProps,
    )(Approvals),
);
