// @flow

import React from 'react';
import TitleWithDescription from '../TitleWithDescription';

type AdditionalContentPropsType = {
    action: Function,
    label: string,
};

type ReqListDataType = Array<string>;

type DescriptionPartProps = {
    title: string,
    subtitle: string,
    requirmentsList: Array<ReqListDataType>,
    additionalContentProps: AdditionalContentPropsType
};

export default class DescriptionPart extends React.PureComponent<DescriptionPartProps> {

    renderRequirmentsList = (reqListData: Array<ReqListDataType>): React.Node => (
        <div className="initiation-req-list flexible horizontal jBetween">
            {reqListData.map((reqList: ReqListDataType, index: number) => (
                <ul className="initiation-req-section" key={index}>
                    {reqList.map((reqItem: string, index: number) => (
                        <li key={index}>{reqItem}</li>
                    ))}
                </ul>
            ))}
        </div>
    );

    renderAdditionalContent = (props: AdditionalContentPropsType): React.Node => (
        <div className="flexible jEnd">
            <span
                className="view-init-checklist-link"
                onClick={props.action}
            >
                {props.label}
            </span>
        </div>
    );

    render() {
        return (
            <div className="initiation-desc-part flexible aCenter">
                <TitleWithDescription
                    classNames="padding-left"
                    title={this.props.title}
                    subtitle={this.props.subtitle}
                    description={this.renderRequirmentsList(this.props.requirmentsList)}
                    additionalContent={this.renderAdditionalContent(this.props.additionalContentProps)}
                />
            </div>
        )
    }
}
