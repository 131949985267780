import React, { useState, useEffect } from "react";
import { RadioButton, Asset, Icon } from "components/common";
import { History } from "history";

const SkillNeed = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {
    const [state, setState] = useState<{
        [key: string]: string;
    }>({
        "assignment-duration": "5y",
        "pay-grade": "",
        lengthOfAssignment: null,
        paygrade: null,
        policy: null,
    });
    const clickHandler = (assignmentduration: string, payGrade: string, lengthOfAssignment: string, policy: string): void => {
        props.history.push({
            state: {
                ...props.history.location.state,
                assignmentduration,
                paygrade: payGrade,
                lengthOfAssignment,policy,
                policyTier: policy
            },
        });
    };
    useEffect(() => {
        if (state["pay-grade"]) {
            props.disableContinueBtn(false);
        } else {
            props.disableContinueBtn(true);
        }
    }, [state["pay-grade"]]);

    useEffect(() => {
        return () => {
            props.disableContinueBtn(true);
        };
    }, []);

    return (
        <>
            {state["assignment-duration"] === "5y" && (
                <div className="pay-grade">
                    <div className="title">
                        What is the pay grade of the candidate?
                    </div>
                    <div className="options vertical-options">
                        <div className="option">
                            <RadioButton
                                checked={state["pay-grade"] === "40+"}
                                onChange={(): void => {
                                    setState({
                                        ...state,
                                        "pay-grade": "40+",
                                    })
                                    clickHandler("5y","40+","MoreThanFiveYear","OneWayMoveTier1")
                                }}
                            />
                            <p className="title">40 and above</p>
                        </div>
                        <div className="option">
                            <RadioButton
                                checked={state["pay-grade"] === "31-"}
                                onChange={(): void => {
                                    setState({
                                        ...state,
                                        "pay-grade": "31-",
                                    })
                                    clickHandler("5y","31-","MoreThanFiveYear","OneWayMoveTier2")
                                }}
                            />
                            <p className="title">31 and below</p>
                        </div>
                    </div>
                </div>
            )}
            {state["pay-grade"] && (
                <div className="download-policy-section">
                    <p className="title">What's included?</p>
                    {state["pay-grade"] === "40+" && (
                        <div className="horizontal-section download">
                            <Icon name="linkIcon" />
                            <p><a target="_blank" href={process.env.REACT_APP_EXT_LINK_INTERNATIONAL_RESOURCES} >Policy for J&J One-Way Employee - Tier I</a></p>
                        </div>
                    )}
                    {state["pay-grade"] === "31-" && (
                        <div className="horizontal-section download">
                            <Icon name="linkIcon" />
                            <p><a target="_blank" href={process.env.REACT_APP_EXT_LINK_INTERNATIONAL_RESOURCES} >Policy for J&J One-Way Employee - Tier II</a></p>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default SkillNeed;
