import React, { PureComponent } from "react";
import { Icon, SpinnerWrapper } from "components/common";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getUserProfile } from "selectors";
import { IUserProfile } from "utils/types";
import AdditionalMoveDetails from "./AdditionalMoveDetails";
import EmployeeJobDetails from "./EmployeeJobDetails";
import ReasonSelector from "./PackageSelection/ReasonSelector";
import FinanceAndAdmin from "./FinanceAndAdmin";
import DomesticReasonSelector from "./PackageSelection/DomesticReasonSelector";
import EmployeeJobDetailsReview from "./EmployeeJobDetailsReview";
import EmployeePositionDetailsReview from "./EmployeePositionDetailsReview";
import ReviewAndSubmit from "./ReviewAndSubmit";
import InitiateMoveSuccess from "./InitiateMoveSuccess";
import { moveInitiationService } from "services";
import "./style.scss";

const mapStateToProps = (state: any) => ({
    userProfile: getUserProfile(state),
});
const mapDispatchToProps = {
    updateOldEmployeeDetails:
        moveInitiationService.actions.updateOldEmployeeDetails,
};

interface IProps extends RouteComponentProps {
    userProfile: IUserProfile;
}

class GetStarted extends PureComponent<IProps> {
    browserCheck = null;

    state = {
        isLoading: false,
        employeeInfoRecords: null,
    };

    componentDidMount() {
        const { step } = this.props.match.params;
        if (!step) {
            return;
        }

        window.addEventListener("beforeunload", this.checkBeforeUnload);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.match.params.step !== this.props.match.params.step &&
            this.props.match.params.step
        ) {
            this.browserCheck = window.addEventListener(
                "beforeunload",
                this.checkBeforeUnload,
            );
        } else {
            window.removeEventListener("beforeunload", this.checkBeforeUnload);
            this.browserCheck = null;
        }
    }

    checkBeforeUnload = e => {
        const confirmationMessage = "Are You Sure You Want to Exit?";
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    };

    navigateTo = (
        location = null,
        moveType = null,
        policySelection = null,
        employeeInfoRe = null,
    ) => {
        //if(moveType !== null && policySelection !== null) {
        //if(moveType === "Domestic" && policySelection !== "ShortTerm") {
        if (moveType !== null) {
            if (moveType.toLowerCase() === "domestic") {
                location = "/initiate-move/domestic-package-selection";
            }
            let employeeInfo = null;
            if (employeeInfoRe === null) {
                employeeInfo =
                    this.props.location.state.employeeInfo !== undefined &&
                    this.props.location.state.employeeInfo !== null
                        ? this.props.location.state.employeeInfo
                        : null;
            } else {
                employeeInfo = employeeInfoRe;
            }
            this.props.history.push(location, {
                moveType: moveType,
                policySelection: policySelection,
                relocationType:
                    this.props.location.state.relocationType !== undefined
                        ? this.props.location.state.relocationType
                        : null,
                initiationId:
                    this.props.location.state.initiationId !== undefined
                        ? this.props.location.state.initiationId
                        : null,
                initiationCountryCode:
                    this.props.location.state.initiationCountryCode !==
                    undefined
                        ? this.props.location.state.initiationCountryCode
                        : null,
                employeeInfo: employeeInfo,
            });
        } else {
            this.props.history.push(location, { ...this.props.location.state });
        }
    };

    updateOldEmployeeDetailsRecord = () => {
        this.setState({ isLoading: true });
        let initiationId =
            this.props.location.state.initiationId !== undefined
                ? this.props.location.state.initiationId
                : null;
        this.props
            .updateOldEmployeeDetails(initiationId)
            .then(data => {
                this.setState({ isLoading: false });
                if (data.success) {
                    let employeeInfo = data.result;
                    this.setState({ isLoading: false });
                    this.setState({ employeeInfoRecords: employeeInfo });
                } else {
                    console.log(data.message);
                }
            })
            .catch(err => console.log(err));
    };

    renderInitial() {
        const { shortName } = this.props.userProfile;
        const { isLoading, employeeInfoRecords } = this.state;
        if (this.props.location.state === undefined) {
            this.props.history.push("/move-dashboard", {
                moveType: "",
                policySelection: "",
            });
        } else {
            var empInfoRecord =
                this.props.location.state.employeeInfo !== undefined &&
                this.props.location.state.employeeInfo !== null
                    ? this.props.location.state.employeeInfo
                    : null;
            var empInfoReport =
                empInfoRecord != null &&
                empInfoRecord.hasOwnProperty("empNJobDet")
                    ? empInfoRecord.empNJobDet
                    : null;
            if (empInfoReport === null && employeeInfoRecords === null) {
                //this.updateOldEmployeeDetailsRecord();
            }
        }

        return (
            <section className="GetStartedContainer">
                <SpinnerWrapper show={isLoading}>
                    <div className="get-started-intro-screen">
                        <h1>Ok {shortName}, let’s complete the initiation</h1>
                        <div className="top-content">
                            <div className="left-info">
                                <p>
                                    We're about to ask you a few questions to
                                    enable you to complete the move initiation.
                                </p>
                                <p className="general-info">
                                    If you're looking for general information
                                    about the move process, please contact
                                    Mobility Connect.
                                </p>
                                <button
                                    className="submit-button"
                                    onClick={() => {
                                        this.navigateTo(
                                            "/initiate-move/package-selection",
                                            this.props.location.state.moveType,
                                            this.props.location.state
                                                .policySelection,
                                            employeeInfoRecords,
                                        );
                                    }}
                                >
                                    Let's go!
                                </button>
                            </div>
                            <div className="right-info">
                                <div>
                                    <Icon name="timeBlue" />
                                    <span className="text">
                                        How long will this take you?
                                    </span>
                                </div>
                                <p>This will take about 10 minutes.</p>
                            </div>
                        </div>
                        {/* <hr className="line" />
                    <div className="contactmobility-wrapper">
                        <ContactMobility2 /> 
                    </div> */}
                    </div>
                </SpinnerWrapper>
            </section>
        );
    }

    render() {
        const { step } = this.props.match.params;
        if (!step) {
            return this.renderInitial();
        }
        if (!step && this.props.location.state === undefined) {
            this.props.history.push("/move-dashboard", {
                moveType: "",
                policySelection: "",
            });
        }
        const stepMap = {
            "package-selection": <ReasonSelector />,
            "additional-move-details": <AdditionalMoveDetails />,
            "employee-job-details": <EmployeeJobDetails />,
            "finance-admin": <FinanceAndAdmin />,
            "domestic-package-selection": <DomesticReasonSelector />,
            "employee-job-details-review": <EmployeeJobDetailsReview />,
            "employee-position-details-review": (
                <EmployeePositionDetailsReview />
            ),
            "review-submit": <ReviewAndSubmit />,
            success: <InitiateMoveSuccess />,
        };
        return stepMap[step] || this.renderInitial();
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(GetStarted),
);
