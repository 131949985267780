import React from 'react';
import HistoryA from '../../../../components/common/HistoryA'

export default (
    <div className="article-info">
        <div className="article-info-item">
            <h2 className="title">Research State-by-State Differences in the Cost of Living</h2>
            <p className="description">
                Did you know that the real value of the dollar—what’s sometimes referred to as its “purchasing power”—differs significantly from one state to the next? It’s true, and a lot of that reflects underlying differences in the price of housing. In&nbsp;
                <HistoryA href="https://www.usatoday.com/story/money/economy/2018/05/10/cost-of-living-value-of-dollar-in-every-state/34567549/" target="_blank" className="link">Hawaii</HistoryA>, for instance, elevated housing costs mean that one dollar spent on rent is only worth 61 cents, whereas in Arkansas that same dollar has the equivalent of $1.58 in value.
                <br/><br/>So, before making the decision to relocate, analyze your budget and salary, and set aside some time to
                <HistoryA href="https://www.usnews.com/news/best-states/rankings/opportunity/affordability" target="_blank" className="link">research the relative affordability of the state you plan to move to</HistoryA>.
                If you’re worried about moving to a state where the cost is higher, bear in mind that those differences are sometimes reflected in higher salaries.&nbsp;
                <HistoryA href="http://time.com/money/5177566/average-income-every-state-real-value/" target="_blank" className="link">Click here</HistoryA>.&nbsp;
                for a complete breakdown of the cost of living and average wage in every state.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Establish Connections in the Area—Even Before You Arrive</h2>
            <p className="description">
                If you went to college in the U.S., scroll through your Facebook and LinkedIn contacts (you can use the search function to make this easier) to see if anyone you know now lives in the area. Many universities also publish directories, which could be a resource to help you reconnect with people and establish a social network in a new place.
                You might also look to see if there’s a chapter of your school’s alumni network in your new city. And if you have hobbies, poke around on&nbsp;
                <HistoryA href="https://www.meetup.com/" target="_blank" className="link">Meetup</HistoryA>&nbsp;
                in the new city to see if there are opportunities to get involved. Whenever you’re moving and leaving behind so many familiar faces—and especially when you’re doing it all alone—that can feel lonely. But this way, you’ll have exciting events to look forward to attending when you arrive.
            </p>
        </div>
        <div className="article-info-item">
            <h2 className="title">Plan What to Do with Your Ca</h2>
            <p className="description">
                Imagine you’ve found the perfect opportunity in the right location. You're all set to make the move and you can’t wait to take in the sights and sounds of a new city. But there’s one problem: The lease on your car won't be up for another two years.
                <br/><br/>The first thing to realize is that many urban areas have adequate public transportation—and that in cities like New York, owning a car is the exception to the rule. So, do some research to determine if you’ll truly need a car in your new location.
                <br/><br/>If you decide the car is a necessity, another option—depending on distance—is simply to make it a road trip and drive to your new home. That way, you’ll be able to move some things with you, which should help cut down on your overall shipping costs. Just make sure you take the vehicle in for a tune-up first. Alternately, if you’re considering shipping your car, there are&nbsp;
                <HistoryA href="https://www.amerifreight.net/car-shipping-auto-transport-quotes-rates-cost-calculator" target="_blank" className="link">free auto transport rate calculators like this one</HistoryA>&nbsp;
                that can help you get a handle on pricing.
                <br/><br/>Relocating to a new city is an exciting opportunity—and you shouldn’t let anything stand in your way. But there are a lot of pieces to keep track of, so keep these tips in mind as you prepare.
            </p>
        </div>
    </div>
)