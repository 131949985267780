export const LDPScenario = {
    InProcurement: {
        id: 1,
        key: "in-procurement",
        title: "The employee is in procurement.",
        value: "InProcurement",
    },
    FLDP: {
        id: 2,
        key: "FLDP",
        title:
            "The employee is MBA Finance Leadership Development Program (FLDP).",
        value: "FLDP",
    },
    NonProcurNonFLDP: {
        id: 3,
        key: "not-in-procurement",
        title: "The employee is NOT in procurement and is NOT an MBA FLDP.",
        value: "NonProcurNonFLDP",
    },
};
export const PayGradeOpts = {
    "40+": {
        text: "50-and-above",
        displayText: "40 and above",
        value: "40+",
        payGrade: "PayGrade41",
    },
    "31-": {
        text: "31-and-below",
        displayText: "31 and below",
        value: "31-",
        payGrade: "PayGrade30",
    },
};
export const PayGradeDomesticHomeownerOpts = {
    "24-": {
        text: "24-and-below",
        displayText: "24 and below",
        payGrade: "24-",
        procurementTitle: "24 and below",
        payGradeValue: "PayGrade24",
    },
    "25-26-0-3YOW": {
        text: "25-26",
        displayText: "25-26, professional /technical / MBA,0-3 YOW",
        payGrade: "25-26-0-3YOW",
        procurementTitle: "25-26, professional /technical / MBA,0-3 YOW",
        payGradeValue: "PayGrade26",
    },
    "25-26-3+YOE": {
        text: "25-26",
        displayText: "25-26, professional /technical /MBA, 3+YOE",
        payGrade: "25-26-3+YOE",
        procurementTitle: "25-26, professional /technical /MBA, 3+YOE",
        payGradeValue: "PayGrade26",
    },
    "30-31": {
        text: "30-31",
        displayText: "30-31",
        payGrade: "30-31",
        procurementTitle: "30-31",
        payGradeValue: "PayGrade31",
    },
    "40+": {
        text: "40+",
        displayText: "40 and above",
        payGrade: "40+",
        procurementTitle: "40 and above",
        payGradeValue: "PayGrade40",
    },
};
export const PayGradeDomesticRenterOpts = {
    "24-": {
        text: "24-and-below",
        displayText: "24 and below",
        payGrade: "24-",
        procurementTitle: "24 and below",
        payGradeValue: "PayGrade24",
    },
    "25-26": {
        text: "25-26",
        displayText: "25-26",
        payGrade: "25-26",
        procurementTitle: "25-26",
        payGradeValue: "PayGrade26",
    },
    "30-31": {
        text: "30-31",
        displayText: "30-31",
        payGrade: "30-31",
        procurementTitle: "30-31",
        payGradeValue: "PayGrade31",
    },
    "40+": {
        text: "40+",
        displayText: "40 and above",
        payGrade: "40+",
        procurementTitle: "40 and above",
        payGradeValue: "PayGrade40",
    },
};
export const LenOfAssignmentOpts = {
    LessThanYear: {
        text: "less-than-year",
        displayText: "12 months or less",
        value: "12m",
        key: "LessThanYear",
    },
    OneToFourYear: {
        text: "one-to-four-year",
        displayText: "1 - 5 years",
        value: "4y",
        key: "OneToFourYear",
    },
};
export const domesticOpts = {
    LDP: {
        id: 1,
        key: "LDP",
        title: "Leadership Development Program (LDP)",
        value: "LeadershipDevelopmentProgram",
        description: "", // "*always for new hires*",
    },
    homeowner: {
        id: 2,
        key: "homeowner",
        title: "Homeowner",
        value: "Homeowner",
        description: "",
    },
    renter: {
        id: 3,
        key: "renter",
        title: "Renter",
        value: "Renter",
        description: "",
    },
};

export const globalOpts = {
    AcceleratedDevelopment: {
        id: 1,
        key: "accelerated-development",
        title: "Accelerated Development",
        description:
            "I would like to develop this employee for the next level in their career.",
        descriptionHighlighted:
            "The assignment will last between 1 and 5 years.",
        value: "AcceleratedDevelopment",
    },
    SkillNeeded: {
        id: 2,
        key: "skill-need",
        title: "Skill Need",
        description:
            "This employee will transfer skills to the team in the host location, backfill someone " +
            "who is out on parental leave or gain skills to bring back to their home country.",
        descriptionHighlighted:
            "The assignment will last for at least 3 months but no-more than 5 years.",
        value: "SkillNeeded",
    },
    EarlyCareer: {
        id: 3,
        key: "early-career",
        title: "Early Career",
        description:
            "This employee is a member of one of our Leadership Development Programs including the " +
            "Human Resources LDP, Finance LDP, Procurement LDP or others.",
        descriptionHighlighted:
            "These assignments are for a maximum of 12 months",
        value: "EarlyCareer",
    },
    Project: {
        id: 4,
        key: "project",
        title: "Project",
        description:
            "This employee will be working on a project in the host location.",
        descriptionHighlighted:
            "The assignment will last at least 3 months but no-more than 5 years.",
        value: "Project",
    },
    BusinessCriticalNeeds: {
        id: 5,
        key: "business-critical-need",
        title: "Business Critical Needs",
        description:
            "This includes a wide variety of situations including the need to stabilize a team after a key employee departure, " +
            " filling a gap during parental leave, launching a new product or service, and stabilizing a single point of failure.",
        descriptionHighlighted:
            "These assignments are for employees who are PG41 or above, duration will be greater than 12 months but no-more than 5 years.",
        value: "BusinessCriticalNeeds",
    },
};

export const packageOpts = {
    Domestic: domesticOpts,
    Global: globalOpts,
};
