import React from 'react';

import {Popup} from 'components/sections';
import { Button, SpinnerWrapper } from 'components/common';
import { TextField } from 'components/sections/ValidatableForm/elements';

export default class ValidatorPopup extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state={
            fileNames: {},
            currentIndex: 0,
            showSpinner: false,
            validationMessage: '',
        }
    }

    onNameChange = (e) => {
        let { value }= e.target;
        const originalFile = this.props.files[this.state.currentIndex];
        const originalFileName = originalFile && originalFile.name;
        const fileExtension = this.getFileExtension(originalFileName);
        const allowedCharCount = 25 - (fileExtension.length + 1); // + 1 is because of considering dot

        if(value.length > allowedCharCount) {
            value = value.slice(0, allowedCharCount);
        }

        const stateObject = {};
        const regexObj = new RegExp(/[/\\?%*:|"><. ]/);
        if(regexObj.test(value)){
            stateObject.validationMessage = "Invalid file name. Please don't use / \\:*?\"<>| symbols"
        } else {
            stateObject.validationMessage = '';
        }

        this.setState({
            fileNames: {
                ...this.state.fileNames,
                [this.state.currentIndex]: value
            },
            ...stateObject
        })
    };

    onSave = () => {
        this.setState({showSpinner: true});
        const { currentIndex } = this.state;
        const originalFile = this.props.files[currentIndex];
        const originalFileName = originalFile && originalFile.name;
        const currentFileName = this.state.fileNames[currentIndex];

        const finalFileName = `${currentFileName}.${this.getFileExtension(originalFileName)}`;

        this.props.onSave(originalFile, finalFileName)
            .then(_ => {
                if(currentIndex === this.props.files.length-1){
                    this.props.onClose()
                } else {
                    this.setState({
                        showSpinner: false,
                        currentIndex: (currentIndex + 1)
                    });
                }
            })
            .catch(e => {
                if(e.response && e.response.data) {
                    this.setState({
                        showSpinner: false,
                        validationMessage: e.response.data.message
                    })
                }
            })
        ;
    };

    getFileExtension = (fileName) => {
        if(fileName && typeof fileName === 'string') {
            return fileName.split('.').pop();
        }
        return '';
    };

    renderBody = () => {
        const originalFile = this.props.files[this.state.currentIndex];
        const currentFileName = this.state.fileNames[this.state.currentIndex];

        const originalFileName = originalFile ? originalFile.name : '';
        const fileExtension = `.${this.getFileExtension(originalFileName)}`;
        const allowedCharCount = 25 - (fileExtension.length);

        const disableSave = !!this.state.validationMessage || !currentFileName;

        return (
            <div className="validator-popup-body">
                <div className="validator-popup-file-form">
                    <p className="original-file">
                        <span>Original file:</span>
                        {originalFileName}
                    </p>

                    <div className="file-name-section flexible">
                        <div className="file-name-input">
                            <TextField
                                value={currentFileName}
                                name="name"
                                label={<span className="file-name-label">Enter a new file name</span>}
                                onChange={this.onNameChange}
                            />
                            <p className="char-counts">{(currentFileName || '').length}/{allowedCharCount} characters</p>
                        </div>
                        <span className="file-extension">{fileExtension}</span>
                    </div>
                    <p className='validation-message'>{this.state.validationMessage}</p>
                </div>

                <div className="flexible jBetween action-buttons">
                    <Button
                        className="cancel-button"
                        title="Cancel"
                        simple
                        onClick={this.props.onClose}
                    />
                    <Button
                        className="save-button"
                        title="Save"
                        submit
                        disabled={disableSave}
                        onClick={this.onSave}
                    />
                </div>
            </div>
        );
    };

    renderTitle = () => {
        const currentFileIndex = this.state.currentIndex+1;
        const fileCounts = this.props.files.length;

        return (
            <div className="flexible vertical validator-popup-header">
                <p className="validator-popup-subtitle">
                    {`${currentFileIndex} of ${fileCounts} receipt file name issues`}
                </p>
                <h3 className="validator-popup-title">{this.props.title}</h3>
            </div>
        );
    };

    render() {
        return (
            <Popup
                className='validator-popup'
                title={this.renderTitle()}
                onClose={this.props.onClose}
            >
                <SpinnerWrapper show={this.state.showSpinner}>
                    {this.renderBody()}
                </SpinnerWrapper>
            </Popup>
        );
    }
}
