import React, { PureComponent } from 'react';
import { Asset } from 'components/common';
import './style.scss';

export default class TextWithImage extends PureComponent{
    render(){
        return (
            <div className="TextWithImage">
                <section className="flexible">
                    {
                        this.props.imagePath 
                        ? (<div className="image-block" style={{ backgroundImage: `url(${this.props.imagePath})` }}/>)  
                        : (
                        <div className="image-block">
                            <Asset assetType={this.props.assetType} assetId={this.props.assetId} maxWidth={600} />
                        </div>
                        )
                    }
                    <div className="text-block flexible vertical aStart jCenter">
                        <h4>{this.props.subtitle}</h4>
                        <h2>{this.props.title}</h2>
                        <p>{this.props.content}</p>
                    </div>
                </section>
            </div>
        )
    }
}