import React, { PureComponent } from 'react';

export default class TextsWithUrl extends PureComponent{
    render(){
        return(
            <div className="TextsWithUrl">
                <div className="text-block">
                    {
                        this.props.textsWithUrlData.title &&
                        <h4>{this.props.textsWithUrlData.title}</h4>
                    }
                    <div className="text-list">
                        <p className="truncate fourLines">{this.props.textsWithUrlData.body}</p>
                    </div>
                    <span>{this.props.textsWithUrlData.urlPathname} >></span>
                </div>
            </div>
        )
    }
}