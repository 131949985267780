import React, { useState } from "react";
import { RadioButton } from "components/common";
import AcceleratedDevelopment from "./AcceleratedDepelopment";
import SkillNeed from "./SkillNeed";
import SelfInitiated from "./SelfInitiated";
import BusinessCriticalNeeds from "./BusinessCriticalNeeds";
import { History } from "history";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state: any) => ({
    //
});

const PrimaryReasonGlobal = (props: {
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
    location: Location;
}) => {
    const [primaryReason, setPrimaryReason] = useState("");
    const clickHandler = (primaryReason: string, strategicIntentReason: string): void => {
        props.history.push({
            state: {
                ...props.history.location.state,
                initiationId: props.history.location.state.initiationId,
                primaryReason,
                strategicIntent: strategicIntentReason,
                empCategoryRepresentation: "NotSet",
                empProcurementType: "NotSet",
                homeownerOrRenter: "NotSet",
                payGrade: "NotSet",
                policy: "NotSet",
                lengthOfAssignment: "NotSet",
                endOfAssignmentType: "NotSet"
            },
        });
        if (primaryReason === "accelerated-development"){
            setPrimaryReason("accelerated-development")
        } else if (primaryReason === "skill-need") {
            setPrimaryReason("skill-need")
        } else if (primaryReason === "business-critical-need") {
            setPrimaryReason("business-critical-need")
        } else if (primaryReason === "self-initiated") {
            setPrimaryReason("self-initiated")
        }
    };
    
    return (
        <div className="primary-reason-global">
            <div className="top">
                <div className="title">
                    What is the primary reason you are moving this person?
                </div>
                <div className="sub-title">
                    Please select the reason that best describes your move.
                </div>
            </div>

            <div className="options">
                <div className="option">
                    <RadioButton
                        checked={primaryReason === "accelerated-development"}
                        onChange={(): void =>
                            clickHandler("accelerated-development","AcceleratedDevelopment")
                        }
                    />
                    <div className="titles">
                        <p className="title">Accelerated Development</p>
                        <p className="sub-title">
                            For situations where the business has decided to
                            develop this person for the next level in their
                            career.
                        </p>
                    </div>
                </div>
                <div className="option">
                    <RadioButton
                        checked={primaryReason === "skill-need"}
                        onChange={(): void => clickHandler("skill-need","SkillNeeded")}
                    />
                    <div className="titles">
                        <p className="title">Skill Need</p>
                        <p className="sub-title">
                            For situations where the business has identified the
                            need for the person to transfer skills to the team
                            in the new location.
                        </p>
                    </div>
                </div>
                <div className="option">
                    <RadioButton
                        checked={primaryReason === "business-critical-need"}
                        onChange={(): void =>
                            clickHandler("business-critical-need","BusinessCriticalNeeds")
                        }
                    />
                    <div className="titles">
                        <p className="title">Business Critical Need</p>
                        <p className="sub-title">
                            This includes a wide variety of situations
                            including: the need to stabilize a team after a key
                            employee departure, launching a new product or
                            service, stabilizing a single point of failure.
                        </p>
                    </div>
                </div>
                <div className="option">
                    <RadioButton
                        checked={primaryReason === "self-initiated"}
                        onChange={(): void =>
                            clickHandler("self-initiated","SelfInitiated")
                        }
                    />
                    <div className="titles">
                        <p className="title">Self-Initiated</p>
                        <p className="sub-title">
                            Where the employee has requested{" "}
                            <b>
                                to work in another J&J office in a different
                                country for personal reasons
                            </b>
                        </p>
                        {/* <div className="sub-title">
                            For one of two situations: <br />
                            <p>
                                1) Where the employee move has arisen as the
                                result of a self-initiated application for a
                                posted internal J&J position{" "}
                                <b>
                                    (and Accelerated Development, Skill Need or
                                    Critical Business Need do not apply)
                                </b>
                            </p>
                            <p>
                                2) Where the employee has requested{" "}
                                <b>
                                    to work in another J&J office in a different
                                    country for personal reasons
                                </b>
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>
            {primaryReason === "accelerated-development" && (
                <div className="accelerated-development">
                    <AcceleratedDevelopment
                        disableContinueBtn={props.disableContinueBtn} history={props.history}
                    />
                </div>
            )}
            {primaryReason === "skill-need" && (
                <div className="skill-need">
                    <SkillNeed disableContinueBtn={props.disableContinueBtn} history={props.history} />
                </div>
            )}
            {primaryReason === "business-critical-need" && (
                <div className="business-critical-need">
                    <BusinessCriticalNeeds
                        disableContinueBtn={props.disableContinueBtn} history={props.history}
                    />
                </div>
            )}
            {primaryReason === "self-initiated" && (
                <div className="self-initiated">
                    <SelfInitiated
                        disableContinueBtn={props.disableContinueBtn} history={props.history}
                    />
                </div>
            )}
        </div>
    );
};

export default withRouter(connect(mapStateToProps,null)(PrimaryReasonGlobal));
