import React from 'react';
import CompletedStep from 'components/common/Images/completedStep.svg';

type StepperIconType = {
    isActive: boolean;
    isComplete: boolean;
    index: Number;
}
export default class StepperIcon extends React.PureComponent<StepperIconType> {
    render(){
        const { isActive, isComplete, index } = this.props;
        if (isActive) {
            return <div className='move-step-id flexible jCenter aCenter selected'>{index}</div>
        }
        if (isComplete) {
            return <img src={CompletedStep} alt="" className="complete"/> 
        }
        return <div className='move-step-id flexible jCenter aCenter'>{index}</div>
    }
}
