import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    expenseReportServise,
    relocationsService,
    expenseReportLineItemService,
} from 'services';

import { SHORT_DATE_FORMAT } from '../../consts';

import {
    withRouter,
} from 'react-router-dom';

import {
    Button,
    Table,
    SmartFrame,
    HelpComponent,
    Asset,
    DropDown,
    SpinnerWrapper
} from 'components/common';

import { Tabs, Popup } from 'components/sections';
import {
    getUserProfile,
    getExpenseReports,
    getRelocations,
    getExpenseReportsValidation
} from 'selectors';
import { reportStatus, gettableData, getTableDataForMobile } from './report-status';
import { TasksAPI } from 'api';

import './style.scss';

const mapStateToProps = (state) => ({
    expenseReports: getExpenseReports(state),
    user: getUserProfile(state),
    relocations: getRelocations(state),
    expenseReportsValidation: getExpenseReportsValidation(state),
});


class ExpenseReport extends PureComponent {
    interval = null;

    state = {
        tabKey: 'draft',
        editorOpened: false,
        tabItemCount: {},
        anotherUser: true,
        isReadyForDelete: false,
        reportID: null,
        isReadyForRender: false,
        activePagintationItem: 0,
        isFetching: false
    };

    constructor(props) {
        super(props);

        this.onEditReport = this.onEditReport.bind(this);
    }

    getFillteredExpenseReportsData = () => {
        const { currentRelocationId } = this.props.user;

        if (currentRelocationId) {
            this.props.getExpenseReports(currentRelocationId).then((data) => {
                this.setState({ isFetching: true }, () => {
                    let hasPending = false;

                    const groupped_reports = {};
                    data && (data.result || []).forEach((expense) => {
                        if (groupped_reports[expense.status]) {
                            groupped_reports[expense.status]++;
                        } else {
                            groupped_reports[expense.status] = 1;
                        }

                        if (expense.status === 'Pending Submission') {
                            hasPending = true;
                        }
                    });

                    if (hasPending) {
                        this.setExpenseFetchInterval();

                        if (!this.interval) {
                            this.interval = setInterval(this.setExpenseFetchInterval, 10000);
                        }
                    } else {
                        if (this.interval) {
                            clearInterval(this.interval);
                            this.interval = null;
                        }
                    }

                    this.setState({
                        tabItemCount: groupped_reports,
                        isFetching: false
                    });
                });
            });
        }
    };

    setExpenseFetchInterval = () => {
        if (this.state.isFetching) {
            return;
        }

        this.getFillteredExpenseReportsData();
    };

    componentDidMount() {
        const { currentRelocationId } = this.props.user;
        if (currentRelocationId) {
            this.props.getValidationExpenseReports(currentRelocationId).then(() => {
                this.setState({ isReadyForRender: true });
                if (this.props.expenseReportsValidation) {
                    this.setState({ anotherUser: false }, () => {
                        !this.state.anotherUser && this.getFillteredExpenseReportsData();
                    });
                    if (!(this.props.relocations && this.props.relocations.length)) {
                        this.props.getRelocationsWithHeaders(this.props.user.email);
                    }
                    this.props.clearReportStorage();

                }
            });
        }

    }

    createNewReport = () => {
        this.props.history.push('/expense-report/create');
    };

    goToYourMoves = () => {
        this.props.history.push('/your-moves');
    };

    selectTableRow = (selected) => {
        this.setState({ selected });
    };

    isReadyForDeleting = (reportID) => {
        this.setState({ isReadyForDelete: true, reportID });
    };

    onPopupClose = () => this.setState({ isReadyForDelete: false });

    deleteExpenseReport = () => {
        this.props.deleteExpenseReports(this.props.user.currentRelocationId, this.state.reportID).then(() => {
            this.onPopupClose();
            this.getFillteredExpenseReportsData();
        });
    };

    downloadExpenseReport = ({ reportId, reportName }) => {
        new Asset({
            customDownloader: () => this.props.downloadingExpenseReports(
                this.props.user.currentRelocationId,
                reportId
            ),
            customFileName: `${reportId}-${reportName}`
        }).execute();
    };

    generateTableRows = () => this.getTableFilteredData().map((report) => (
        {
            id: report.reportId,
            onClick: () => this.selectTableRow(report.reportId),
            body: gettableData(report, this.state.tabKey, this.isReadyForDeleting, this.downloadExpenseReport),
        }
    ));

    getTableFilteredData = () => {
        if (this.state.tabKey === 'all') {
            return this.props.expenseReports.payload || {};
        } else {
            return this.props.expenseReports.payload.filter((item) => {
                if (this.state.tabKey.toLowerCase() === 'approved' &&
                    (item.status === 'Paid' || item.status === 'Pre-Paid')) {
                    return item;
                } else if (this.state.tabKey.toLowerCase() === 'draft' &&
                    (item.status === 'Draft' || item.status === 'Ready to Submit')) {
                    return item;
                } else if (item.status.toLowerCase() === this.state.tabKey.toLowerCase()) {
                    return item;
                } else {
                    return null;
                }
            });
        }
    };

    setTabsData = () => (
        [
            {
                label: `Open Reports ${(this.state.tabItemCount.Draft || 0 + this.state.tabItemCount['Ready to Submit'] || 0) + (this.state.tabItemCount['Ready to Submit'] || 0) || ''}`,
                key: 'draft',
            },
            {
                label: `Submitted ${this.state.tabItemCount.Submitted || ''}`,
                key: 'submitted'
            },
            {
                label: `Approved ${this.state.tabItemCount.Paid || ''}`,
                key: 'approved'
            },
            {
                label: `All ${(this.props.expenseReports.payload || []).length}`,
                key: 'all'
            },
        ]
    );

    onEditReport = () => {
        if (this.props.user.currentRelocationIsDomestic) {
            this.props.history.push(`/tasks/editBankInfo`);
        } else {
            this.setState({ editorOpened: true }, () => {
                setTimeout(() => this.setState({ editorOpened: false }), 2000);
            });
        }
    };

    generateTableCols = () => (reportStatus[this.state.tabKey]);

    tabsOnchange = (tab) => {
        this.setState({
            tabKey: tab.key,
            editorOpened: false
        });
    };

    generatePopupBody = () => (
        <div className="expense-popup-body">
            <Button
                dataKey="edit_bank_account_button"
                title="Cancel"
                simple
                onClick={this.onPopupClose}
                style={{ marginRight: 12 }}
            />
            <Button
                dataKey="edit_bank_account_button"
                title="Delete"
                submit
                onClick={this.deleteExpenseReport}
            />
        </div>
    );

    generateExpenseReportContentForMobile = () => this.getTableFilteredData().map((report, index) => {
        if ((this.state.activePagintationItem * 10) < index && index < (this.state.activePagintationItem * 10 + 10)) {
            return getTableDataForMobile(report, this.state.tabKey, this.isReadyForDeleting, this.downloadExpenseReport);
        } else {
            return null;
        }
    });

    generatePaginationNumber = () => {
        const paginationData = [];
        if (this.getTableFilteredData().length > 9) {
            for (let i = 1; i <= (Math.ceil(this.getTableFilteredData().length / 10)); ++i) {
                paginationData.push(
                    {
                        key: `${i >= 10 ? i : `0${i}`}`,
                        value: i - 1,
                    }
                );
            }
        }

        return !!paginationData.length && paginationData.map((item) => {
            if ((item.value === this.state.activePagintationItem) || (item.value === this.state.activePagintationItem - 1) || (item.value === this.state.activePagintationItem + 1)) {
                return <span
                    key={item.value}
                    className={`${this.state.activePagintationItem === item.value ? 'active' : ''}`}
                    onClick={() => this.setState({ activePagintationItem: item.value })}
                >
                            {item.key}
                        </span>;
            } else {
                return null;
            }
        });
    };

    generatePagination = () => {
        return <div className="expense-report-pagination flexible aCenter jBetween">
            <div className="pagination-item-info">
                {
                    this.getTableFilteredData().length > 0 ?
                        `Items ${(this.state.activePagintationItem * 10) + 1}-${this.getTableFilteredData().length < ((this.state.activePagintationItem * 10) + 10) ? this.getTableFilteredData().length : (this.state.activePagintationItem * 10) + 10} of ${this.getTableFilteredData().length}` :
                        'Items 0'
                }

            </div>
            <div className="pagination-counts">
                {this.generatePaginationNumber()}
            </div>
        </div>;
    };

    isEditorOpened = () => {
        const { expenseReports, user } = this.props;
        const { editorOpened } = this.state;

        return (
            <div className="expense-reports-table">
                {editorOpened && (
                    <SmartFrame url={TasksAPI.getSirvaEditExpenceReportFrameUrl(user.currentRelocationId)}/>
                )}
                <SpinnerWrapper
                    show={expenseReports.isLoading}
                    minHeight={200}
                >
                    <div className="expense-report-table-mobile flexible aCenter">
                        <div className="flexible jBetween aCenter grow">
                            <span>Report</span>
                            <span>Amount</span>
                        </div>
                    </div>
                    {this.generateExpenseReportContentForMobile()}
                    <Table
                        rowHeight={100}
                        cols={this.generateTableCols()}
                        rows={this.generateTableRows()}
                    />
                    {
                        this.state.isReadyForDelete ?
                            <Popup
                                title="Are you sure you want to delete this expense report"
                                body={this.generatePopupBody()}
                                closePopup={this.onPopupClose}
                            />
                            : null
                    }
                    {
                        this.generatePagination()
                    }
                </SpinnerWrapper>
            </div>
        );
    };

    generateExpenseReportTableContent = () => (
        <div className="expense-report-body flexible jBetween">
            <div className="flexible vertical">
                <DropDown
                    data={this.setTabsData()}
                    dataKey="expense_report_mobile"
                    onChange={this.tabsOnchange}
                    defaultSelected="draft"
                    optionsClassName="expense-report-mobile-select"
                />
                <Tabs
                    className="linear-selected"
                    data={this.setTabsData()}
                    activeTabKey={'draft'}
                    onTabChange={this.tabsOnchange}
                />
            </div>
            <div className="flexible horizontal create-edit-buttons">
                <Button
                    dataKey="edit_bank_account_button"
                    title="Add/Edit Bank Details"
                    simple
                    onClick={this.onEditReport}
                />
                <Button
                    dataKey="create_new_report_button"
                    title="Create New Report"
                    submit
                    onClick={this.createNewReport}
                />
            </div>
        </div>
    );

    generateDescription = () => (
        <p className="description-expense">
            This Expense Tracking tool is customized based on your policy and it will only allow you to submit expenses
            that J&J allows.
            This tool is only for tracking expenses related to your move or relocation.
            For J&J business expenses, please continue to use Concur.
        </p>
    );

    getMoveCountryImageAsset = () => {
        const { currentRelocationId } = this.props.user;
        if (this.props.relocations && this.props.relocations.length > 0) {
            const apropriateAddress = this.props.relocations.filter((item) => {
                if (item.relocationId === currentRelocationId) {
                    return item.assignmentHeader;
                } else {
                    return null;
                }
            });
            return apropriateAddress && apropriateAddress.length > 0 ? (
                <Asset assetType="COUNTRY_FLAG" assetId={apropriateAddress[0].assignmentHeader.hostCountry.isoCode}
                       maxWidth={200}/>) : null;
        }
    };

    getCountryName = () => {
        const { currentRelocationId } = this.props.user;
        const relocation = this.props.relocations && this.props.relocations.length > 0 &&
            this.props.relocations.find(item => item.relocationId === currentRelocationId);
        return relocation ? (
            this.props.user.currentRelocationIsDomestic ?
                relocation.addresses[0].stateDescription : (relocation.assignmentHeader ? relocation.assignmentHeader.hostCountry.name : '')
        ) : '';
    };

    generateMoveInfoContent = () => {
        const activeRelocation = (this.props.relocations && this.props.relocations.length > 0) ?
            this.props.relocations.find(relo => relo.relocationId === this.props.user.currentRelocationId) || {}
            : {};
        const assignmentHeader = activeRelocation.assignmentHeader || {};
        return (
            <div className="move-content flexible aCenter jBetween horizontal">
                <div className="country flexible horizontal aCenter" data-key="expense_report_country">
                    <div className="image-block">
                        {this.getMoveCountryImageAsset()}
                    </div>
                    <div className="text flexible vertical">
                        <span>For your move to:</span>
                        <text>
                            <b className="relocation-name">{this.props.relocations && this.props.relocations.length > 0 ? this.getCountryName() : ''}
                            </b> {
                            `${assignmentHeader.estimatedStartDate ? moment(assignmentHeader.estimatedStartDate).format(SHORT_DATE_FORMAT) : ''}
                                ${assignmentHeader.estimatedEndDate ? ' - ' + moment(assignmentHeader.estimatedEndDate).format(SHORT_DATE_FORMAT) : ''}`
                        }
                        </text>
                    </div>
                </div>
                {
                    this.props.relocations && this.props.relocations.length > 1 ?
                        <div
                            className="remove-move"
                            onClick={this.goToYourMoves}
                            data-key="remove_move_button"
                        >
                            <b className="flexible horizontal aCenter">Add expenses to a different move</b>
                        </div>
                        : null
                }

            </div>
        );
    };

    generateExpenseReportData = () => {
        if (!this.state.anotherUser) {
            let moveContent = this.generateMoveInfoContent();
            let description = this.generateDescription();
            let expenseTableContent = this.generateExpenseReportTableContent();
            let isEditor = this.isEditorOpened();
            return [moveContent, description, expenseTableContent, isEditor];
        } else {
            return (
                <div>
                    <p className="description-expense">
                        Your authorized policy does not require expense tracking.
                        If you believe this is an error and you need to have access to the expense management service,
                        please contact your move consultant.
                    </p>
                    <HelpComponent
                        title="Your Dedicated Relocation Consultant"
                    />
                </div>
            );
        }
    };

    generateExpenseReportPage = () => {
        if (this.state.isReadyForRender) {
            return <section className="ExpenseReport">
                <h1 className="header-text"
                    data-key="expense_report_title">{this.state.anotherUser ? 'Expense Tracking' : 'Expense Report'}</h1>
                {this.generateExpenseReportData()}
            </section>;
        } else {
            return <div className="Spinner"/>;
        }
    };

    render = () => this.generateExpenseReportPage();
}

export default withRouter(connect(mapStateToProps, {
    ...expenseReportLineItemService.actions,
    ...expenseReportServise.actions,
    ...relocationsService.actions,
})(ExpenseReport));
