// @flow

import { Api } from 'backend';

const ExpenseReportReceipts = {
    gettingExpenseReportReceipt: (reportID: Object, relocationID): Promise<any> => (
        Api.get({
            path: `/expensereport/${relocationID}/${reportID}/receipts`,
        })
    ),
    creatingExpenseReportReceipt: (reportID: Object, receiptsData, relocationID): Promise<any> => (
        Api.post({
            path: `/expensereport/${relocationID}/${reportID}/receipts`,
            body: receiptsData,
        })
    ),
    downloadExpenseReportReceipt: (reportID: Object, relocationID, receiptID): Promise<any> => (
        Api.get({
            path: `/expensereport/${relocationID}/${reportID}/receipts/${receiptID}`,            
        })
    ),
    deletingExpenseReportReceipt: (reportID: Object, receiptID, relocationID): Promise<any> => (
        Api.put({
            path: `/expensereport/${relocationID}/${reportID}/receipts/${receiptID}`,
        })
    ),
};

export default ExpenseReportReceipts;