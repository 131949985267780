// @flow

import { Api } from 'backend';

export default {
    getReceipts: (relocationId: string, expenseReportId: number): Promise<any> => Api.get({
        path: `/expensereport/${relocationId}/${expenseReportId}/receipts`
    }),
    uploadReceipts: (relocationId: string, expenseReportId: number, files: any, callback): Promise<any> => Api.post({
        path: `/expensereport/${relocationId}/${expenseReportId}/receipts`,
        additionalOptions:{
            data: files,
            callback
        },
        headers:{
            'Content-Type':'multipart/form-data'
        }
    }),
    deleteReceipt: (relocationId: string, expenseReportId: number, receiptId: number): Promise<any> => Api.delete({
        path: `/expensereport/${relocationId}/${expenseReportId}/receipts/${receiptId}`,
    }),
    downloadReceipt: (relocationId: string, expenseReportId: number, receiptId: number): Promise<any> => Api.get({
        path: `/expensereport/${relocationId}/${expenseReportId}/receipts/${receiptId}`,
        additionalOptions: {
            responseType: 'blob',
        }
    })
};
