// @flow

import * as React from 'react';
import Slider from "react-slick";

import { Button, Icon } from 'components/common';
import './style.scss';

type SiteOverviewProps = {
    isOpen: boolean,
    sliderAdditionalProps? : Object,
    onClose?: Function,
    children?: React.Node,
    className?: string,
    buttonTitles?: Array<string>,
};
type SiteOverviewState = {
    slideIndex: number,
};

export default class SiteOverview extends React.PureComponent<SiteOverviewProps, SiteOverviewState> {

    static defaultProps: SiteOverviewProps = {
        sliderAdditionalProps: {},
        isOpen: false,
    };

    state: SiteOverviewState = {
        slideIndex: 0,
    };

    sliderRef: Object;

    appendCustomDots = (dots: Array<React.Element<'li'>>): React.Element<'div'> => (
        <div>
            <ul className="dots-container flexible jEnd aCenter">
                {dots}
            </ul>
            <Button
                submit
                className="next-button"
                title={this.props.buttonTitles ? this.props.buttonTitles[this.state.slideIndex] : 'Next'}
                onClick={this.slideToNext}
            />
        </div>
    );

    slideToNext = (): void => {
        if(this.state.slideIndex === (this.props.children || []).length-1) {
            this.props.onClose && this.props.onClose();
            return;
        }
        this.sliderRef.slickNext();
    };

    appendCustomPaging = (): React.Element<'div'> => <div className="custom-dot" />;

    createSliderRef = (ref: Object): void => { this.sliderRef = ref};

    onBeforeSlide = (slideIndex: number ): void => {
        this.setState({ slideIndex });
    };

    render() {
        return this.props.isOpen ? (<div className={`site-overview flexible${this.props.className || ''}`}>
                <div className="close-button">
                    <Icon
                        name="closeWhite"
                        onClick={this.props.onClose}
                    />
                </div>
                <div className="site-overview-content flexible">
                    <Slider
                        dots
                        infinite
                        spee={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                        arrows={false}
                        appendDots={this.appendCustomDots}
                        customPaging={this.appendCustomPaging}
                        initialSlide={this.state.slideIndex}
                        ref={this.createSliderRef}
                        afterChange={this.onBeforeSlide}
                        { ...this.props.sliderAdditionalProps }
                    >
                        {this.props.children}
                    </Slider>
                </div>
            </div>) : null;
    }
}
