//@flow
import * as React from 'react';
import Grid from '@material-ui/core/Grid';

type JnJGridColumnProps = {
    children: React.Node,
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

const JnJGridColumn = (props:JnJGridColumnProps) => {

    return(
        <Grid
            item
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            xl={props.xl}
            className={`jnj-grid-column ${props.className ? props.className : '' }`}
        >
            {props.children}
        </Grid>
    );
}

export default JnJGridColumn;
