import React from 'react';
import TableRow from './TableRow';

class VerticalLinearStepper extends React.PureComponent {
    componentDidMount(): void {
        this.fixVerticalLineStyling();
    }

    fixVerticalLineStyling() {
        if (this.props.data.length <= 1) {
            return false;
        }

        const { isMobile = false } = this.props;
        const div = document.getElementById(`stepper-${this.props.id}`);
        const tableItems = div.getElementsByClassName('TaxMilestonesTableItem');
        const lineItem = div.getElementsByClassName('line')[0];
        const firstChild = tableItems[0];
        const lastChild = tableItems[this.props.data.length - 1];
        const lineBottomSize = (lastChild.clientHeight / 2) - (isMobile ? 10 : 0);

        lineItem.style.top = `${(firstChild.clientHeight / 2)}px`;
        lineItem.style.bottom = `${lineBottomSize}px`;
    }

    render() {
        const { data } = this.props;
        
        return (
            <div className="TaxMilestonesTableStepper" id={`stepper-${this.props.id}`}>
                {data.length > 1 ? <div className="line"/> : null}
                {data.map((el, i) => (
                    <div key={el.id} className="TaxMilestonesTableItem">
                        <TableRow {...el} lastChild={i + 1 === data.length} firstChild={i === 0}/>
                    </div>
                ))}
            </div>
        );
    }
}

export default VerticalLinearStepper;
