// @flow

import { Api } from 'backend';

const ContactsAPI = {
    getContacts: (relocationID: String): Promise<any> => (
        Api.get({
            path: `/sirva/relocations/${relocationID}/contacts`,
        })
    )
};

export default ContactsAPI;