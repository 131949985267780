// @flow

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Asset, List, ListItem } from 'components/common';
import { Tabs } from 'components/sections';
import { assignmentHeaderService, siteOverviewService, errorService, taxMilestonesService } from 'services';
import { getAssignmentHeader, getUserProfile } from 'selectors';
import './style.scss';
import { resources } from './resources.js';
import Accordion from 'components/sections/Accordion';
import { onHistoryLinkClicked } from '../../utils';

const tabData = [
    {
        label: "Global Experiences",
        key: 'int',
    },
    {
        label: "U.S. Domestic",
        key: 'dom',
    },
    {
        label: "Training Materials",
        key: 'train',
    }
];


const mapStateToProps = (state) => ({
    assignmentHeader: getAssignmentHeader(state),
    userProfile: getUserProfile(state),
});

const mapDispatchToProps = {
    getAssignmentHeader: assignmentHeaderService.actions.getAssignmentHeader,
    getTaxMilestones: taxMilestonesService.actions.getTaxMilestones,
    toggleSiteOverview: siteOverviewService.actions.toggleSiteOverview,
    toggleErrorModal: errorService.actions.toggleErrorModal
};

class Resources extends PureComponent<*> {

    constructor(props) {
        super(props);
        this.categoryItem = [];
        const autoSelectTab = props.match.params.selectTab;
        this.state = {
            tabKey: autoSelectTab ? autoSelectTab : 'int',
            selectTab: autoSelectTab,
            selectCategory: props.match.params.selectCategory
        };
    }

    componentWillMount() {
        if (!this.props.assignmentHeader && this.props.userProfile.currentRelocationId) {
            const { userProfile } = this.props;
            this.props.getAssignmentHeader(userProfile.currentRelocationId);

            if (userProfile.homeCountry && userProfile.homeCountry.isoCode && userProfile.hostCountry && userProfile.hostCountry.isoCode) {
                this.props.getTaxMilestones(userProfile.homeCountry.isoCode, userProfile.hostCountry.isoCode);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.assignmentHeader && !nextProps.assignmentHeader &&
            !this.props.userProfile.currentRelocationId && nextProps.userProfile.currentRelocationId) {
            this.props.getAssignmentHeader(nextProps.userProfile.currentRelocationId);

            const { userProfile } = nextProps;

            if (userProfile.homeCountry && userProfile.homeCountry.isoCode && userProfile.hostCountry && userProfile.hostCountry.isoCode) {
                this.props.getTaxMilestones(userProfile.homeCountry.isoCode, userProfile.hostCountry.isoCode);
            }
        }
        if (!this.props.selectCategory && nextProps.match.params.selectCategory)
            this.setState({
                tabKey: nextProps.match.params.selectTab,
                selectTab: nextProps.match.params.selectTab,
                selectCategory: nextProps.match.params.selectCategory
            });
    }

    componentDidMount() {
        if (this.state.selectCategory)
            this.focusCategory();
    }

    componentDidUpdate() {
        if (this.state.selectCategory && this.state.active)
            this.focusCategory();
    }

    focusCategory() {
        if (this.state.selectCategory) {
            const itemDiv = this.categoryItem[this.state.selectCategory];
            if (itemDiv) {
                this.setState({
                    selectTab: '',
                    selectCategory: ''
                }, () => setTimeout(() => { //without timeout cursor will be scrolled to 0,0
                    itemDiv.focus();
                    itemDiv.scrollIntoView(true);
                }, 1000));
            }
        }
    }

    genLineItems = (items: Array<*> = []) => {
        return (items.map(doc => (
                doc.content ?
                    <Accordion data={doc} mode="resource" key={doc.documentId}
                               handleDownloadClick={this.handleDownloadClick}/>
                    :
                    <ListItem
                        className="pointer"
                        onClick={() => this.handleItemClick(doc)}
                        key={doc.documentId}
                        header={
                            <div className="document-name-section">
                                <p className="document-name">{doc.description}</p>
                            </div>

                        }
                        middleContent={<div className="document-category">{doc.fileType}</div>}
                        rightButtons={[{
                            name: doc.fileType === 'Webpage' || doc.fileType === 'Website' ? 'chevronRed' : 'download',
                            onClick: () => {
                                this.handleDownloadClick(doc.assetKey, doc.assetId, doc.fileType, doc.fileName);
                            }
                        }]}
                    />


            ))
        );
    };

    prepareLineItemsByTitle(cat: Object) {
        if (this.props.assignmentHeader && this.props.userProfile) {
            if (cat.title === 'Healthcare' && this.props.assignmentHeader.homeCountry) {
                const homeCountryInfo = this.props.assignmentHeader.homeCountry;
                return cat.items.filter(item => {
                    if (item.assetId.indexOf('assigne') > -1) {
                        if (homeCountryInfo.isoCode === 'USA') {
                            return item.assetId === 'assignees-working-outside-the-u-s';
                        } else if (homeCountryInfo.name && homeCountryInfo.name.indexOf('Puerto') > -1) {
                            return item.assetId === 'assignees-from-puerto-rico';
                        } else
                            return item.assetId === 'global-assignees-in-the-U-S-or-overseas';
                    }
                    return true;
                });
            }
        }

        return cat.items;
    }

    generateDocumentsList = (categories: Array<Object>) => {
        return categories.map((cat, index) => (
            <div className="category-header" key={cat.key ? cat.key : `${cat.title}_${index}`}
                 ref={(catDiv) => {
                     if (cat.key) this.categoryItem[cat.key] = catDiv;
                 }}>
                <div className="flexible horizontal aCenter">
                    <img alt={cat.title} src={cat.icon}/><h2>{cat.title}</h2>
                </div>
                <List className="flexible vertical">
                    {this.genLineItems(this.prepareLineItemsByTitle(cat))}
                </List>
            </div>
        ));
    };

    tabsOnchange = (tab: { key: string }) => {
        this.setState({
            tabKey: tab.key
        });
    };

    handleItemClick = (doc: Object) => {
        if (doc.documentId === 56) {
            this.props.toggleSiteOverview(true);
        }
    };

    // provide saving metrics by click
    handleDownloadClick = async (assetKey: string, assetId: string, fileType: string, fileName: string): void => {
        if (fileType === 'PDF' && (!assetKey || !assetId)) {
            return this.props.toggleErrorModal(true, 'Sorry. It’s not you. It’s us.', 'Document was not found.');
        }

        fileType === 'Website' || fileType === 'Webpage'
            ? onHistoryLinkClicked({
                action: 'click-website-link',
                category: 'resources',
                label: fileName,
                onClick: () => window.open(fileName)
            })
            : new Asset({ assetType: assetKey, assetId: assetId }).execute();
    };

    render() {
        const defaultResource = resources[this.state.tabKey] || resources.int;
        return (
            <section className="Resources">
                <h1 className="header-text" data-key="contact_us_title">Resources</h1>
                <div className="flexible vertical">
                    <Tabs
                        className="linear-selected"
                        data={tabData}
                        activeTabKey={this.state.tabKey}
                        onTabChange={this.tabsOnchange}
                    />

                    {this.generateDocumentsList(defaultResource)}
                </div>
            </section>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Resources));
