import React, {useState} from "react";
import { Icon, Asset } from "components/common";
import { RouterProps } from "types";
import { useDispatch } from "react-redux";
import { moveInitiationService } from "services";
import Checkbox from '@material-ui/core/Checkbox';
import "./styles.scss";

const EligibleMoveBottom = (props: RouterProps & { assetId: string,assetType: string, url: string, policyName: string}) => {
    const dispatch = useDispatch();
    const { moveType, policySelection } = props.location.state;
    const [continueButtondisabled, setContinueButtondisabled] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [lumSumPolicy, setLumSumPolicy] = useState("NotSet");
    
    const dataSet = {
        "initiationId": (props.location.state.initiationId !== undefined) ? props.location.state.initiationId : '',
        "empCategoryRepresentation": (props.location.state.empCategoryRepresentation !== undefined) ? props.location.state.empCategoryRepresentation : "NotSet",
        "empProcurementType":  (props.location.state.empProcurementType !== undefined) ? props.location.state.empProcurementType : "NotSet",
        "homeownerOrRenter": (props.location.state.homeownerOrRenter !== undefined) ? props.location.state.homeownerOrRenter : "NotSet",
        "payGrade": (props.location.state.payGradeValue !== undefined) ? props.location.state.payGradeValue : "NotSet",
        "policy": (props.policyName !== undefined) ? props.policyName : "NotSet",
        "strategicIntent": "NotSet",
        "halfInHomeCountrySpent": false,
        "lengthOfAssignment": "NotSet",
        "beginingOrEndAssignment": false,
        "endOfAssignmentType": "NotSet",
        "localTalentAvailable": false,
        "lumpSumPolicy": (props.lumSumFieldEnable !== undefined && props.lumSumFieldEnable != null) ? (lumSumPolicy == "NotSet" )? "No": lumSumPolicy : "NotSet",
    }
    const saveNonCompetitivePackageDetails = (location) => {
        //this.setState({isLoading: true});
        setContinueButtondisabled(true);
        dispatch(moveInitiationService.actions.setNonCompetitivePackageDetails(dataSet)).then(data => {
                //setIsLoading(false);
                setContinueButtondisabled(false);
                if(data.success) {
                    navigateTo(location);
                } else {
                    console.log(data.message);
                }
            })
            .catch(err => console.log(err));
    }

    const navigateTo = (location: string) => {
        switch (location) {
            case 'continueInitiation':
                saveNonCompetitivePackageDetails("yesandcontinue");
                break;
            case 'startover':
                props.history.push("/initiate-move", {
                    ...props.location,
                    "moveType": moveType,
                    "policySelection": policySelection,
                    "eligibleTitle": (props.eligibleTitle != null && typeof props.eligibleTitle !== 'undefined') ? props.eligibleTitle : null,
                    "employee": (props.employee != null && typeof props.employee !== 'undefined') ? props.employee : null,
                    "category": (props.category != null && typeof props.category !== 'undefined') ? props.category : null,
                    "category2": (props.category2 != null && typeof props.category2 !== 'undefined') ? props.category2 : null,
                    "relocationType": (props.location.state.relocationType != null && typeof props.location.state.relocationType !== 'undefined') ? props.location.state.relocationType : null,
                    "initiationId": (props.location.state.initiationId !== undefined && typeof props.location.state.initiationId !== 'undefined') ? props.location.state.initiationId : null,
                    "initiationCountryCode": (props.location.state.initiationCountryCode !== undefined && typeof props.location.state.initiationCountryCode !== 'undefined') ? props.location.state.initiationCountryCode : null,
                    employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
                });
                break;
            case 'yesandcontinue':
                    props.history.push(props.url, {
                        ...props.location,
                        "moveType": moveType,
                        "policySelection": policySelection,
                        "eligibleTitle": (props.eligibleTitle != null && typeof props.eligibleTitle !== 'undefined') ? props.eligibleTitle : null,
                        "employee": (props.employee != null && typeof props.employee !== 'undefined') ? props.employee : null,
                        "category": (props.category != null && typeof props.category !== 'undefined') ? props.category : null,
                        "category2": (props.category2 != null && typeof props.category2 !== 'undefined') ? props.category2 : null,
                        "relocationType": (props.location.state.relocationType != null && typeof props.location.state.relocationType !== 'undefined') ? props.location.state.relocationType : null,
                        "initiationId": (props.location.state.initiationId !== undefined && typeof props.location.state.initiationId !== 'undefined') ? props.location.state.initiationId : null,
                        "initiationCountryCode": (props.location.state.initiationCountryCode !== undefined && typeof props.location.state.initiationCountryCode !== 'undefined') ? props.location.state.initiationCountryCode : null,
                        employeeInfo: (props.location.state.employeeInfo !== undefined && props.location.state.employeeInfo !== null ) ? props.location.state.employeeInfo : null,
                    });
                    break;
        }
    };

    const checkedHandler = () => {
        setIsChecked(!isChecked);
        setLumSumPolicy("No");
        if(isChecked) {
            setLumSumPolicy("No"); 
        } else {
            setLumSumPolicy("Yes");
        }
    }

    return (
        <section className="download-policy-section">
            {/* <p className="title">What's included?</p>
            <div
                className="horizontal-section download"
                onClick={() => {
                    new Asset({
                        assetType: props.assetType,
                        assetId: props.assetId,
                    }).execute();
                }}
            >
                <Icon name="download" />
                <p>Download Policy for Details</p>
            </div>
            <div className="line" />
            <br />
            {props.lumSumFieldEnable !== undefined && props.lumSumFieldEnable != null ? 
                <>
                    <p className="lum-sum-policy-header-title" >Interested to learn if your move qualifies for a Lump Sum Policy instead?</p>
                    <p className="lum-sum-policy-checkbox-area" ><Checkbox checked={isChecked} onChange={() => {checkedHandler()}} /> &nbsp;Check the box for this information to be included with your SIRVA consultation!</p>
                    <br />
                </>
            : null } */}
            <section className="buttons">
                <button
                    className="white-button"
                    onClick={() => {navigateTo("startover")}}
                >
                    <span className="buttonLabel">Start Over</span>
                </button>
                <button 
                    className="red-button"
                    disabled={continueButtondisabled}
                    onClick={() => {navigateTo("continueInitiation")}}
                    >
                    <span className="buttonLabel">Continue Initiation</span>
                </button>
            </section>
        </section>
    );
};

export default EligibleMoveBottom;
