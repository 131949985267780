// @flow

import React, { PureComponent } from 'react';
import { AssetsAPI } from 'api';
import { onHistoryLinkClicked } from '../../../utils';
import { store } from "../../../index";
import { errorService } from "../../../services/uiServices";

type AssetProps = {
    assetType?: string, //Type of asset
    assetId?: string, //Asset ID
    isLink?: boolean, //Is it a hyperlink or is it a html element (image or text or smth..)
    showLoading?: boolean, //show spinner control while loading
    linkClassName?:string, //CSS class for hyperlink
    linkText?:string,//text for hyperlink in HTML element <a>.
    customFileName?:string,//fixed file name of downloaded asset
    customDownloader?: Promise<any>, //custom function for downloading any files
    maxWidth?:number//max windth of rendering image
};

var assetsCache = {};

var getHashOfAssetProps = (asset:AssetProps): string =>
    (asset.assetType ? asset.assetType : '') +
    (asset.assetId ? asset.assetId : '') +
    (asset.isLink ? 't' : 'f') +
    (asset.customDownloader ? 'cd' : '');

var getFromCache = (asset:AssetProps):any => {
    if (asset.customDownloader || asset.isLink)
        return null;
    var key = getHashOfAssetProps(asset);
    var res = assetsCache[key];
    return res ? res : null;
}

var addToCache = (assetPropsKey:string, data:any):void => {
    assetsCache[assetPropsKey] = data;
}

export default class Asset extends PureComponent<AssetProps> {

    constructor(props) {
        super(props);

        this.onLinkClick = this.onLinkClick.bind(this);
        this.processResponse = this.processResponse.bind(this);
        this.processErrorResponse = this.processErrorResponse.bind(this);
        this.mounted = false;
        this.state = {
            loadingState:-1,
            downloadedAssetUrl: '',
            downloadedFileName: '',
            downloadedAssetType: '',
            downloadedAssetBlob: null,
            errorText:null
        };
    }

    componentDidMount (){
        this.mounted = true;
        if (!this.props.isLink && this.state.loadingState === -1) {
            this.loadResource(this.props, false, true);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.isLink) {
            if (this.props.customDownloader !== nextProps.customDownloader ||
                this.props.assetType !== nextProps.assetType ||
                this.props.assetId !== nextProps.assetId) {
                this.setState({loadingState:-1, downloadedAssetUrl:'', downloadedFileName:'', downloadedAssetType:'', downloadedAssetBlob: null,
                    errorText:null, currentPropsKey: getHashOfAssetProps(nextProps)}, () =>
                    this.loadResource(nextProps, false, true)
                );
            }
        }
    }

    loadResource = (compProps:any, autoOpen:bool, updateState:bool, onLoadedCallBack:any):void => {
        if (this.state.loadingState === -1 && this.mounted ) {
            const _self = this;

            const propsKey = getHashOfAssetProps(compProps);
            const resFromCache = getFromCache(compProps);
            if (resFromCache) {
                console.debug('Asset.loadResource from cache (assetType=' + compProps.assetType + '; assetId=' + compProps.assetId + '; customDownloader='+(compProps.customDownloader ? 'yes' : 'no')+')');
                _self.setLoadedAsset(null, resFromCache.url, resFromCache.assetType, resFromCache.filename, autoOpen, updateState, compProps.isLink, propsKey, onLoadedCallBack);
            }
            else if (compProps.assetType && compProps.assetId) {
                console.debug('Asset.loadResource (assetType=' + compProps.assetType + '; assetId=' + compProps.assetId + ')');

                if(updateState) {
                    this.setState({loadingState:0, currentPropsKey: propsKey});
                }
                AssetsAPI
                    .downloadAsset(compProps.assetType, compProps.assetId, compProps.maxWidth, () => {})
                    .then(resp => _self.processResponse(resp, autoOpen, updateState, compProps.isLink, compProps.customFileName, propsKey, onLoadedCallBack))
                    .catch(e => _self.processErrorResponse(e, updateState, compProps.assetType, compProps.assetId, propsKey, onLoadedCallBack));
            }
            else if (compProps.customDownloader){
                console.debug('Asset.loadResource by customDownloader');

                if (updateState) {
                    this.setState({loadingState:0, currentPropsKey: propsKey});
                }
                compProps
                    .customDownloader()
                    .then(resp => _self.processResponse(resp, autoOpen, updateState, compProps.isLink, compProps.customFileName, propsKey, onLoadedCallBack))
                    .catch(e => _self.processErrorResponse(e, updateState, 'custom', '', propsKey, onLoadedCallBack));
            }
        }
    }

    processErrorResponse = (error:any, updateState:bool, assetType:string, assetId:string, propsKey:string, onLoadedCallBack:any):void => {
        console.error('Asset.Error (assetType=' + assetType + '; assetId=' + assetId + '; error='+error+')');
        const res = {
            loadingState: 1,
            downloadedAssetUrl: null,
            downloadedFileName: null,
            downloadedAssetType:null,
            downloadedAssetBlob: null,
            currentPropsKey:null,
            // errorText:error.message
        };
        if (updateState && this.mounted && this.state.currentPropsKey === propsKey) {
            this.setState(res);
        }
        if (onLoadedCallBack) {
            onLoadedCallBack(res);
        }
        return store.dispatch(errorService.actions.toggleErrorModal(true, 'Sorry. It’s not you. It’s us.', 'File not found'));
    }

    processResponse = (resp:any, autoOpen:bool, updateState:bool, isLink:bool, customFileName:string, propsKey:string, onLoadedCallBack:any):void => {
        const assetType = resp.type;
        if(resp && resp.type && (resp.type.indexOf('text')>=0 || resp.type.indexOf('json')>=0 || resp.type.indexOf('html')>=0)) {
            const _self = this;
            var reader = new FileReader();
            reader.onload = function() {
                if (!isLink) {
                    addToCache(propsKey, {url:reader.result, filename:'', assettype:assetType});
                }
                _self.setLoadedAsset(resp, reader.result, assetType, '', autoOpen, updateState, isLink, propsKey, onLoadedCallBack);
            }
            reader.readAsText(resp);
            return;
        }

        const url = this.responseToUrl(resp);
        const fileName = customFileName
                ? customFileName
                : (resp.filename ? resp.filename : '');

        if (!isLink) {
            addToCache(propsKey, {url, fileName, assetType});
        }
        this.setLoadedAsset(resp, url, assetType, fileName, autoOpen, updateState, isLink, propsKey, onLoadedCallBack);
    };

    setLoadedAsset = (resp: any, url:string, assetType:string, fileName:string, autoOpen:bool, updateState:bool, isLink:bool, propsKey:string, onLoadedCallBack:any):void => {
        // console.info('Asset.setLoadedAsset (url=' + url +'; assetType=' + assetType + '; filename=' + fileName + '; autoOpen='+autoOpen+'; isLink='+isLink+')');
        const res = {
            loadingState: 1,
            downloadedAssetUrl: url,
            downloadedFileName: fileName,
            downloadedAssetType: assetType,
            downloadedAssetBlob: isLink ? resp : null,
            currentPropsKey: propsKey,
            errorText:null
        };
        if (updateState && this.mounted && (!this.state.currentPropsKey || this.state.currentPropsKey === propsKey)) {
            this.setState(res);
        }
        if (isLink && autoOpen) {
            this.doDownload(resp, url, fileName);
        }
        if (onLoadedCallBack) {
            onLoadedCallBack(res);
        }
    };

    responseToUrl = (resp: any): string => {
        var urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(resp);
    }

    doDownload = (resp: any, url: string, fileName: string) => {
        console.debug('Asset.doDownload');

        onHistoryLinkClicked({
            category: 'asset-link-click',
            action: fileName,
            label: url
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(resp, fileName);
            return;
        }

        const link = document.createElement('a');
        link.href = url;
        link.style.display = 'none';
        link.target = '_blank';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    execute = (): void => {
        this.mounted = true;
        this.props.isLink = true;

        const spinnerRoot = document.createElement('div');
        spinnerRoot.className = 'PopupSpinner';

        const spinner = document.createElement('div');
        spinner.className = 'Spinner';
        spinnerRoot.appendChild(spinner);
        document.body.appendChild(spinnerRoot);

        this.loadResource(this.props, true, false, (res) => {
            document.body.removeChild(spinnerRoot);
            if (res.errorText) {
                alert(res.errorText);
            }
        });

    }

    onLinkClick = (): void => {
        if (!this.state.downloadedAssetUrl)
            this.loadResource(this.props, true, true);
        else
            this.doDownload(this.state.downloadedAssetBlob, this.state.downloadedAssetUrl, this.state.downloadedFileName);
    }

    render() {
        if (this.props.isLink && this.state.loadingState === 0) {
            return (<div className="PopupSpinner"><div className="Spinner"/></div>);
        }
        if (!this.props.isLink && this.state.downloadedAssetUrl && this.state.downloadedAssetType) {
            if (this.state.downloadedAssetType.indexOf('text')>=0 || this.state.downloadedAssetType.indexOf('html')>=0 || this.state.downloadedAssetType.indexOf('json')>=0) {
                return (<div>{this.state.downloadedAssetUrl}</div>);
            }
            else if (this.state.downloadedAssetType.indexOf('image')>=0 || this.state.downloadedAssetType.indexOf('img')>=0 || this.state.downloadedAssetType.indexOf('png')>=0 || this.state.downloadedAssetType.indexOf('gif')>=0) {
                return (<img src = {this.state.downloadedAssetUrl} alt={this.state.downloadedFileName} width='100%' />);
            }
            else if (this.state.downloadedAssetType.indexOf('application')>=0) {
                this.doDownload(this.state.downloadedAssetBlob, this.state.downloadedAssetUrl, this.state.downloadedFileName);
                this.setState({
                    loadingState: 1,
                    downloadedAssetUrl: null,
                    downloadedFileName: null,
                    downloadedAssetType:null,
                    downloadedAssetBlob: null,
                    errorText:''
                });
            }
            else
                return (<a href={this.state.downloadedAssetUrl} download={this.state.downloadedFileName} >Download</a>)
        }
        if (this.props.isLink && this.props.linkText) {
            return (<span className={this.props.linkClassName} onClick={this.onLinkClick} >{this.props.linkText}</span>);
        }
        if (this.props.showLoading && this.state.loadingState !== 1) {
            return  <div className="Spinner"/>;
        }
        if (this.props.showLoading && this.state.loadingState === 1 && this.state.errorText) {
            if (this.props.isLink)
                alert(this.state.errorText);
            else
                return  (<div>{this.state.errorText}</div>);
        }
        return null;
    }
}
