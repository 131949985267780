// flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getUserProfile } from 'selectors';
import HeaderWithImage from './HeaderWithImage';
import ArticleContent from './ArticleContent';

import articles from './Articles';

import './styles.scss';

type ArticleDetailProps = {};

class ArticleDetail extends React.PureComponent<ArticleDetailProps> {

    handleArticleChange = (index) => {
        this.props.history.push(`/article-detail/${index}`);
    };

    render() {
        const { userProfile, match: { params } } = this.props;
        const articleIndex = Number(params.articleIndex || 0);
        const reloType = userProfile.email ? userProfile.currentRelocationIsDomestic ? 'domestic' : 'global' : null;
        const content = reloType ? articles[reloType][articleIndex] : null;

        const {
            header,
            image,
            ...rest
        } = content || {};
        return (reloType && content) ? (
            <div className="ArticleDetail">
                <HeaderWithImage
                    title={header}
                    image={image}
                />
                <ArticleContent
                    content={rest}
                    articles={articles[reloType]}
                    changeArticle={this.handleArticleChange}
                    currentArticleIndex={articleIndex}
                />
            </div>
        ) : <div className="flexible horizontal jCenter"><div className="Spinner"/> </div>;
    }
}

export default withRouter(connect((state) => ({ userProfile: getUserProfile(state) }))(ArticleDetail));