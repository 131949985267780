import React, { useEffect } from "react";
import { History } from "history";
import PolicyDisplay from "./PolicyDisplay2";

const GetPolicy = (props: {
    title: string,
    policyName: string,
    movePolicyType?: string,
    disableContinueBtn: (disableContinueButton: boolean) => void;
    history: History;
}) => {

    const { history: { location: { state: locationState } }, title, policyName, movePolicyType } = props;
    
  useEffect(() => {
    let change = false;
    let newProps = { ...locationState };
    if((locationState["policy"] !== policyName 
    && locationState["lumpSumPolicy"] !== "Yes" )
    || locationState["eligibleTitle"] !== title) {
      change = true;
      newProps = {
        ...newProps,
        eligibleTitle: title,
        policy: policyName
      }
    }
    if(locationState["policyTier"] !== policyName &&
    locationState["lumpSumPolicy"] !== "Yes") {
      change = true;
      newProps = {
        ...newProps,
        policyTier: policyName
      } 
    }
    if(change) {
      props.history.push({
        state: newProps
      });
    }
    props.disableContinueBtn(false);
    // return () => props.disableContinueBtn(true)
  },[locationState]);

    return <PolicyDisplay 
      disableContinueBtn={props.disableContinueBtn} 
      // history={props.history}
      eligibleTitle={title}
      movePolicyType={movePolicyType}
    />
};

export default GetPolicy;