import React from 'react';
import moment from 'moment';
import { Link } from "components/common";
import './styles.scss';
import { getAssignmentHeader, getRelocations } from "../../../selectors/dataSelectors";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class TaxMilestonesHeader extends React.PureComponent {
    formatDate(date) {
        return moment(date).format('MMM D, YYYY');
    }

    render() {
        const { relocations, assignmentHeader } = this.props;

        if (!this.props.assignmentHeader) {
            return null;
        }

        return (
            <div className="TaxMilestonesHeader">
                <div className="move-info">
                    <div className="move-info-country-container">
                        <img src={`data:image/png;base64, ${assignmentHeader.hostCountry.flagPicture}`}
                             alt={assignmentHeader.hostCountry.name}/>
                        <div>
                            <h4>For your move to:</h4>
                            <div className="move-info-country-date">
                                <strong>{assignmentHeader.hostCountry.name} </strong>
                                <p>{this.formatDate(assignmentHeader.estimatedStartDate)} - {this.formatDate(assignmentHeader.estimatedEndDate)}</p>

                            </div>
                        </div>
                    </div>
                    <div>
                        {Array.isArray(relocations) && relocations.length > 1 ? (
                            <Link
                                text="See Tax Returns for a Different Move"
                                action="tax-returns-for-a-different-move"
                                onClick={() => this.props.history.push('/your-moves')}
                            />
                        ) : null}
                    </div>
                </div>
                <hr/>
                <p className="text">
                    Complying with the various tax requirements in both the home and host countries is one of the more
                    complex areas of an international assignment or transfer. Johnson & Johnson partners with a leading
                    tax
                    vendor to provide tax orientations and tax compliance services to help you plan, prepare and comply.
                </p>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    assignmentHeader: getAssignmentHeader(state),
    relocations: getRelocations(state),
});

export default withRouter(connect(mapStateToProps)(TaxMilestonesHeader));
