import * as React from "react";
import { EligibleMove } from "components/moveInitiationLayouts";
import { RouterProps } from "types";
import "./styles.scss";

const LongTermTier1 = (props: RouterProps) => {
    const rows = [
        [
            "Pre-Assignment",
            "70%",
            "50% of costs the same quarter of employee’s start date and 50% in subsequent quarter",
            "Charges as services incurred",
        ],
        [
            "On Assignment",
            "125%",
            "Each year; evenly budgeted each quarter of the assignment",
            "Charges as services incurred per year",
        ],
        [
            "Post Assignment",
            "50%",
            "In the year assignment ends",
            "Charges as services incurred post assignment",
        ],
        [
            "Tax on assignment",
            "200%",
            "100% at close of country tax year",
            "Charges as services incurred per year",
        ],
        [
            "Tax Post Assignment",
            "150%",
            "Each year after assignment ends for up to 3 years",
            "Each year post assignment for up to 3 years",
        ],
    ];

    const cols = [
        { title: "" },
        { title: "% of salary*" },
        { title: "Budget Allocation" },
        { title: "Timing of actual charges" },
    ];
    const {backState} = props;
    return (
        <EligibleMove
            eligibleTitle="Long Term Tier 1"
            policyName="LongTermTier1"
            moveType="Global"
            strategicIndent={props.location.state["strategic-indent"] || "N/A"}
            assignmentLength="1-5 years"
            payGrade="40 and above"
            backState={backState}
            cols={cols}
            rows={rows}
            url="/initiate-move/employee-job-details"
            assetId="long-term-tier-1"
            {...props}
        />
    );
};

export default LongTermTier1;
