import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RelocationsAPI } from 'api';
import { Icon } from 'components/common';
import { getUserProfile } from 'selectors';

import './style.scss';

const mapStateToProps = ( state ) => ({ 
    userProfile: getUserProfile(state)
});

class HelpComponent extends PureComponent {

    constructor(props) {
        super(props);
        
        this.state={
            email:"",
            phone:"",
            name:"",
            image:"",
        };

        if (this.props.userProfile && this.props.userProfile.currentRelocationId) {
            this.loadCounselorInfo(this.props);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.userProfile.currentRelocationId && nextProps.userProfile.currentRelocationId) {
            this.loadCounselorInfo(nextProps);
        }
    }

    loadCounselorInfo(props) {
        RelocationsAPI
                .getCounselor(props.userProfile.currentRelocationId)
                .then(data=>this.setState({
                    name:data.result.name,
                    email:data.result.email,
                    phone:data.result.phone,
                    image:data.result.image
                }))
                .catch(e => console.log(e));
    }
   
    renderSwitch(param) {
        switch(param) {
          case 'profile':
            return (
                <div className="HelpComponent profile"  >
                    <div className="help-component-block">
                        <div className="info-block flexible horizontal">
                            <div className="image-block"
                                 style={{ backgroundImage: `url(data:image/png;base64,${this.state.image})` }}
                            /> 
                            
                            <div className="text-block flexible vertical">
                                <h2>{this.props.title}</h2>
                                <p className="intro">
                                    Meet Your Dedicated<br/>
                                    Relocation Consultant:
                                </p>
                                <span className="name">{this.state.name}</span>
                            </div>
                        </div>
                        <div className="email-block flexible jBetween">
                            <a href={'sip:' + this.state.email}>
                                <span className="chat flexible aCenter">
                                    <Icon name="skype" width={18} height={18} style={{ marginRight: 10 }}/> Chat
                                </span>
                            </a>
                            <hr/>
                            <a href={'tel:' + this.state.phone}>
                                <span className="phone flexible aCenter">
                                    <Icon name="phone" width={20} height={20}/> {this.state.phone}
                                </span>     
                            </a>
                            <hr/>
                            <a href={'mailto:'+this.state.email} ><span className="email flexible aCenter singleLine truncate">
                                <Icon name="mailBlack" width={20} height={15} style={{ marginRight: 10 }}/> Email</span></a>
                        </div>
                    </div>
                </div>
            );
          case 'feedback':
            return (
                <div className="HelpComponent feedback aCenter flexible jCenter"  >
                    <div className="help-component-block">
                        <div className="info-block">
                            <p className="flexible vertical aCenter">Just checking in... <span/></p>
                            <div className="image-block"
                                style={{ backgroundImage: `url(data:image/png;base64,${this.state.image})` }}
                            /> 
                            <div className="text-block flexible vertical">
                                <span className="name">{this.state.name}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
          default:
            return (
                <div className="HelpComponent"  >
                    <div className="help-component-block flexible">
                        <div className="info-block flexible horizontal">
                            <div className="image-block contact-us"
                                 style={{ backgroundImage: `url(data:image/png;base64,${this.state.image})` }}
                            /> 
                            
                            <div className="text-block contact-us flexible vertical">
                                <h2 className={this.props.mode}>{this.props.title}</h2>
                                <span className="name contact-us">{this.state.name}</span>
                                <p className="contact-us-description">Please contact your consultant for all general inquiries, policy guidance,
                                 travel and coordination of relocation services associated to your move.</p>
                            </div>
                        </div>
                        <div className="email-block contact-us flexible vertical">
                            <a href={'sip:' + this.state.email}>
                                <span className="chat flexible aCenter">
                                    <Icon name="skype" width={18} height={18} style={{ marginRight: 10 }}/> Chat Now
                                </span>
                            </a>
                            <hr/>
                            <a href={'tel:' + this.state.phone}>
                                <span className="phone flexible aCenter">
                                    <Icon name="phone" width={20} height={20}/> {this.state.phone}
                                </span>     
                            </a>
                            <hr/>
                            <a href={'mailto:'+this.state.email} ><span className="email flexible aCenter singleLine truncate">
                                <Icon name="mailBlack" width={20} height={15} style={{ marginRight: 10 }}/> {this.state.email}</span></a>
                        </div>
                    </div>
                </div>
            
            );
        }
    }


    render() {
        return (this.renderSwitch(this.props.mode))
    }
}

export default connect(mapStateToProps, {})(HelpComponent);