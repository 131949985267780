import { combineReducers } from "redux";
import {
    userService,
    expenseReportServise,
    documentsService,
    relocationsService,
    expenseReportLineItemService,
    expenseReportItem,
    tasksService,
    expenseReportReceipts,
    expenseReportCategoriesService,
    receiptsService,
    contactsServie,
    allowancesService,
    assignmentHeaderService,
    siteOverviewService,
    errorService,
    competetiveHeaderService,
    taxMilestonesService,
    moveInitiationService,
} from "services";

const dataStore = combineReducers({
    user: userService.reducer,
    documents: documentsService.reducer,
    expenseReportLineItem: expenseReportLineItemService.reducer,
    relocations: relocationsService.reducer,
    expenseReportItem: expenseReportItem.reducer,
    expenseReports: expenseReportServise.reducer,
    expenseReportsValidation: expenseReportServise.validationReducer,
    tasks: tasksService.reducer,
    expenseReportsReceipts: expenseReportReceipts.reducer,
    expenseReportCategories: expenseReportCategoriesService.reducer,
    receipts: receiptsService.reducer,
    contacts: contactsServie.reducer,
    recentAllowance: allowancesService.reducer,
    assignmentHeader: assignmentHeaderService.reducer,
    taxMilestones: taxMilestonesService.reducer,
    moveInitiation: moveInitiationService.reducer,
});

const uiStore = combineReducers({
    siteOverviewIsOpen: siteOverviewService.reducer,
    error: errorService.reducer,
    competetiveHeader: competetiveHeaderService.reducer,
});

export default combineReducers({
    dataStore,
    uiStore,
});
